import { isIntegerString } from './isIntegerString'

export const pageErrorMessage = { message: 'Can be of format offset,limit' }

export function isPage(value: any): boolean {
  if (typeof value !== 'string' || value.length > 9) {
    return false
  }
  const parts = value.split(',')
  if (
    parts.length !== 2 ||
    !isIntegerString(parts[0]) ||
    !isIntegerString(parts[1]) ||
    +parts[0] + +parts[1] > 100000
  ) {
    return false
  }
  return true
}
