import { hermes } from '@byll/hermes'
import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InventoryItemTopBar } from './InventoryItemTopBar'
import { makeObservable, observable, runInAction } from 'mobx'
import { InventoryItemForm } from './InventoryItemForm'
import { ColumnSpinner } from 'components/SideBarLayout/components/ColumnSpinner'

interface Props {
  id: string
  folder: IInventoryFolder
  navigate: (url: string) => void
  setEdit: (edit: boolean) => void
}

@observer
export class InventoryItemEdit extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private item: IInventoryItem | null = null
  @observable private error: string | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    try {
      const item = await hermes.getOnceNew<IInventoryItem>(
        `/api/${this.context.instance.id}/inventory/items/${this.props.id}`,
      )
      runInAction(() => (this.item = item))
    } catch (e) {
      runInAction(
        () => (this.error = 'Der Gegenstand konnte leider nicht geladen werden.'),
      )
    }
  }

  render() {
    if (!this.item) {
      return <ColumnSpinner />
    }
    return (
      <>
        <InventoryItemTopBar folder={this.props.folder} item={this.item} />

        <InventoryItemForm
          item={this.item}
          navigate={this.props.navigate}
          compoundId={this.props.folder.compoundId}
          setEdit={this.props.setEdit}
        />
      </>
    )
  }
}
