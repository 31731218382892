export interface IImageSection {
  x: number
  y: number
  width: number
  height: number
}

export function getImageSection(
  sourceWidth: number,
  sourceHeight: number,
  targetWidth: number,
  targetHeight: number,
): IImageSection | null {
  if (sourceWidth < 1 || sourceHeight < 1 || targetWidth < 1 || targetHeight < 1) {
    return null
  }
  const xScale = targetWidth / sourceWidth
  if (targetHeight < sourceHeight * xScale) {
    // Image is cut top / bottom
    const width = Math.round(targetWidth / xScale)
    const height = Math.round(targetHeight / xScale)
    if (width < 1 || height < 1) {
      return null
    }
    return { x: 0, y: Math.floor((sourceHeight - height) / 2), width, height }
  }

  // Image is cut left / right
  const yScale = targetHeight / sourceHeight
  const width = Math.round(targetWidth / yScale)
  const height = Math.round(targetHeight / yScale)
  if (width < 1 || height < 1) {
    return null
  }
  return { x: Math.floor((sourceWidth - width) / 2), y: 0, width, height }
}
