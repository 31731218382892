import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from 'components/Form/Model'
import { getHeight } from '../helpers/getHeight'

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  name: string
  model: Model<any>
  readOnly: boolean
  className?: string
  style?: any
  children?: Element
  minRows?: number
  maxRows?: number
  rows?: number
}

@observer
export class PaperInputTextarea extends React.Component<Props, {}> {
  private readonly cache = {
    value: '148f182a-0281-41a6-97cf-d9870f50a675',
    className: '',
    height: 0,
  }
  private get className(): string {
    return `resize-none block w-full text-base focus:bg-white border-0 px-0 py-[2px] focus:ring-0 ${
      this.props.readOnly ? 'bg-white' : 'bg-gray-100'
    }`
  }
  private textarea: React.RefObject<HTMLTextAreaElement> = React.createRef()
  @observable width: number | null = null
  @observable lineHeight: number = 24
  @observable xPadding: number = 4

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.getTextareaWidthAndLineHeight()
  }

  @action
  private getTextareaWidthAndLineHeight = () => {
    const textarea = this.textarea.current
    const rect = textarea?.getBoundingClientRect()
    this.width = rect?.width || null
    if (textarea) {
      const styles = getComputedStyle(textarea)
      this.lineHeight = parseInt(styles.lineHeight)
      this.xPadding = parseInt(styles.paddingTop) + parseInt(styles.paddingBottom)
    }
  }

  @action
  private onChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    if (!(event.target instanceof HTMLTextAreaElement)) {
      return
    }
    if (!this.width) {
      return
    }
    const target: HTMLTextAreaElement = event.target
    const value: string = target.value
    const height = getHeight(
      this.cache,
      this.className,
      value,
      this.width,
      this.lineHeight,
    )

    if (this.props.maxRows) {
      // Discard event if it would make the input wider than max height
      if (Math.round(height / this.lineHeight) > this.props.maxRows) {
        return
      }
    } else {
      // Discard event if it would make the text bigger than the input field
      const rect = target.getBoundingClientRect()
      if (height > rect.height) {
        return
      }
    }

    this.props.model.values[this.props.name] = value
    this.props.onChange?.(event)
  }

  render() {
    const {
      name,
      model,
      label,
      className,
      style,
      children,
      minRows,
      maxRows,
      rows,
      ...attributes
    } = this.props

    if (this.props.readOnly) {
      attributes.placeholder = ''
    }
    const textOrPlaceholder = model.values[name] || attributes.placeholder || ''
    return (
      <div className={className}>
        <textarea
          type='text'
          className={this.className}
          style={{
            height:
              maxRows &&
              this.width &&
              getHeight(
                this.cache,
                this.className,
                textOrPlaceholder,
                this.width,
                this.lineHeight,
                minRows,
              ),
            minHeight: minRows && minRows * this.lineHeight + this.xPadding,
            ...style,
          }}
          {...attributes}
          name={name}
          onChange={this.onChange}
          value={model.values[name]}
          autoComplete='off'
          ref={this.textarea}
          rows={rows}
        />
        {children}
      </div>
    )
  }
}
