export function toDataUrl(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = function () {
      if (typeof this.result !== 'string') {
        throw new Error(
          'toDataUrl fileReader result should be a string because it was requested with .readAsDataURL()',
        )
      }
      resolve(this.result)
    }
    fileReader.readAsDataURL(blob)
  })
}
