import { IMealScanResult } from 'contracts/scan/interfaces/IMealScanResult'
import * as React from 'react'

interface Props {
  item: IMealScanResult
  highlight?: boolean
}

export const SpecialMealPermissions: React.FC<Props> = ({ item, highlight }) => (
  <>
    {item.extra.hasSchonkost && (
      <span
        className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
          highlight ? 'bg-pink-500 text-white' : 'bg-gray-100 text-gray-600'
        }`}
      >
        Schonkost
      </span>
    )}
    {item.extra.hasRamadan && (
      <span
        className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
          highlight ? 'bg-pink-500 text-white' : 'bg-gray-100 text-gray-600'
        }`}
      >
        Ramadan
      </span>
    )}
    {item.extra.foodMustBeBroughtToRoom && (
      <span
        className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
          highlight ? 'bg-pink-500 text-white' : 'bg-gray-100 text-gray-600'
        }`}
      >
        Zimmerlieferung
      </span>
    )}
    {item.extra.lunchPackage1 && (
      <span
        className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
          highlight ? 'bg-pink-500 text-white' : 'bg-gray-100 text-gray-600'
        }`}
      >
        Lunchpaket 1
      </span>
    )}
    {item.extra.lunchPackage3 && (
      <span
        className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
          highlight ? 'bg-pink-500 text-white' : 'bg-gray-100 text-gray-600'
        }`}
      >
        Lunchpaket 3
      </span>
    )}
  </>
)
