import * as React from 'react'

interface Props {
  value: number
  className?: string
}

export const ProgressBar: React.FC<Props> = (props) => (
  <div className='w-full bg-gray-200 h-3 mb-6 rounded-md overflow-hidden'>
    <div
      className={props.className ? `${props.className} h-3` : 'bg-blue-400 h-3'}
      style={{ width: props.value + '%' }}
    />
  </div>
)
