import { Tooltip } from 'components/Tooltip'
import { uniqueId } from 'helpers/uniqueId'
import * as React from 'react'
import styles from './styles.module.scss'

interface Props {
  onClick?: (event) => void
  icon: string
  style?
  tooltip?:
    | string
    | {
        text: string | JSX.Element
        offset?: number
        position?: 'left' | 'top' | 'right' | 'bottom'
      }
  color?: 'white' | 'primary' | 'danger' | 'warning' | 'transparent' | 'border'
  classNameContainer?: string
  [key: string]: any
}

const stylesColorMap = {
  border:
    'text-white-500 hover:bg-indigo-500 focus:bg-indigo-500 border border-white shadow-md',
  transparent: 'text-white-500 hover:bg-indigo-500 focus:bg-indigo-500',
  white:
    'bg-white text-gray-500 hover:bg-indigo-500 hover:text-white focus:bg-indigo-500 focus:text-white focus:ring-indigo-500 shadow-md',
  primary: 'bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-indigo-500 shadow-md',
  danger: 'bg-red-500 text-white hover:bg-red-600 focus:ring-red-500 shadow-md',
  warning: 'bg-yellow-500 text-white hover:bg-yellow-600 focus:ring-red-500 shadow-md',
}

export class RoundIcon extends React.Component<Props, { isOpen }> {
  private readonly id = uniqueId('tooltip-r-')
  state = { isOpen: false }

  private open = () => {
    this.setState({ isOpen: true })
  }

  private close = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { onClick, icon, classNameContainer, style, tooltip, color, hover, ...rest } =
      this.props
    return (
      <button
        id={this.id}
        className={`${styles.round} ${stylesColorMap[color || 'white']} ${
          classNameContainer || ''
        } text-center rounded-full text-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 has-tooltip`}
        style={style}
        onClick={onClick}
        onMouseEnter={this.open}
        onMouseLeave={this.close}
        {...rest}
      >
        <span key={icon}>
          <i className={icon} aria-hidden='false' />
        </span>
        {tooltip && typeof tooltip === 'string' && <Tooltip>{tooltip}</Tooltip>}
        {tooltip && typeof tooltip !== 'string' && (
          <Tooltip offset={tooltip.offset} position={tooltip.position}>
            {tooltip.text}
          </Tooltip>
        )}
      </button>
    )
  }
}
