import { Button } from 'components/Form/components/Button'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { IEmployeeCommActiveUser } from 'contracts/employeeComm/interfaces/IEmployeeCommActiveUser'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { DocumentDropzone } from 'modules/Documents/components/DocumentDropzone'
import { SalarySlipDropzoneView } from 'modules/Documents/components/SalarySlipDropzoneView'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { SalarySlipUploadRow } from './SalarySlipUploadRow'
import { SalarySlipErrorRow } from './SalarySlipErrorRow'
import { sleep } from 'helpers/sleep'
import { Spinner } from 'components/Spinner'

interface Props {
  company: InputSelectOption
  month: string
  employees: IEmployeeCommActiveUser[]
  onClose: () => void
  topicId: string
}

const fileRx = /^lobn_(?:[0-9]+)_(?:[0-9]+)_(?:[0-9]+)_([0-9]+)(?:_[0-9]+)?\.pdf$/

@observer
export class NewSalarySlipUpload extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private upload: (IDocumentMetadata & { file: Blob })[] = []
  @observable private error: (IDocumentMetadata & { file: Blob })[] = []
  @observable private loading = false
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private onUpload = (doc: IDocumentMetadata & { file: Blob }) => {
    const match = doc.name.toLowerCase().match(fileRx)
    doc.userId = null
    if (match) {
      const pn = +match[1]
      for (const employee of this.props.employees) {
        const employeePn = +(employee.personnelNumber || '')
        if (employeePn && employeePn === pn) {
          doc.uploadUser = `${employee.firstName} ${employee.lastName}` // This field is used for the assigned employee
          doc.userId = employee.id
          doc.category = this.props.topicId
          break
        }
      }
    }
    if (doc.userId) {
      this.upload.push(doc)
    } else {
      this.error.push(doc)
    }
  }

  private onFinish = async () => {
    runInAction(() => (this.loading = true))
    await sleep(500)
    this.props.onClose()
  }

  render() {
    if (this.loading) {
      return (
        <div className='h-[200px]'>
          <Spinner />
        </div>
      )
    }

    return (
      <div className='min-h-[200px] pt-4'>
        {this.upload.length > 0 &&
          this.upload.map((doc) => <SalarySlipUploadRow doc={doc} key={doc.id} />)}
        {this.error.length > 0 &&
          this.error.map((doc) => <SalarySlipErrorRow doc={doc} key={doc.id} />)}
        {this.upload.length === 0 && this.error.length === 0 && (
          <DocumentDropzone
            scope='salary slip'
            onSelect={this.onUpload}
            view={SalarySlipDropzoneView}
            accept='application/pdf,.pdf'
            multiple
            maxMb={2}
          />
        )}

        <div className='text-right py-4 mt-5 sticky bottom-0 bg-white border-t border-gray-300 -mx-6 px-6 -mb-4'>
          <Button onClick={this.props.onClose} color='secondary' outline className='mr-3'>
            Abbrechen
          </Button>
          <Button
            color='primary'
            disabled={this.upload.length === 0}
            onClick={this.onFinish}
          >
            Fertigstellen
          </Button>
        </div>
      </div>
    )
  }
}
