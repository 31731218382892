import { Collection, hermes } from '@byll/hermes'
import { XCircleIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputDate } from 'components/Form/components/InputDate'
import { InputNationality } from 'components/Form/components/InputNationality'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { Spinner } from 'components/Spinner'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { lfgUnterbringungsgrundOptions } from 'contracts/residents/constants/lfgUnterbringungsgrundOptions'
import { getEmptyResident } from 'contracts/residents/helpers/getEmptyResident'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { isStammCompound } from 'helpers/isStamm'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { isPastDate } from 'modules/Reporting/helpers/isPastDate'
import { familyRelationOptions } from 'modules/Residents/components/CaseRecordForm/constants/familyRelationOptions'
import { lfgAufenthaltsstatusOptions } from 'modules/Residents/components/CaseRecordForm/constants/lfgAufenthaltsstatusOptions'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { z } from 'zod'

const salutations: InputSelectOption[] = [
  { value: null, label: '' },
  { value: 'female', label: 'Frau' },
  { value: 'male', label: 'Herr' },
  { value: 'divers', label: 'Divers' },
]

interface Props {
  residents: Collection<IResidentSearchResult> // Used to copy data from hv
  family: IFamily
  setOpen: (open: boolean) => void
  familyId: string
  location
  navigate
}

@observer
export class CreateResidentMask extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private saving = false
  @observable private error: string | null = null
  private readonly model: Model<IResident & { responsibilityCompoundId: string | null }>
  private readonly requiredFields: any = {}

  constructor(props: Props, context: AppContextProps) {
    super(props)

    this.requiredFields = {
      responsibilityCompoundId: z.string(),
      lfgInitialerCheckIn: z.string().refine(isPastDate),
      firstName: z.string().min(1),
      lastName: z.string().min(1),
    }
    // Required fields for LfG-B
    if (context.permissions.host_lfgb) {
      this.requiredFields = {
        responsibilityCompoundId: z.string(),
        lfgInitialerCheckIn: z.string().refine(isPastDate),
        sex: z.string(),
        firstName: z.string().min(1),
        lastName: z.string().min(1),
        dateOfBirth: z.string(),
        familyRelation: z.string().min(1),
        nationalityId: z.string(),
        cityOfBirth: z.string().min(1),
      }
    }

    this.model = new Model(
      {
        ...getEmptyResident(context.instance.id),
        lfgInitialerCheckIn: dayjs().format('YYYY-MM-DD'),
        responsibilityCompoundId: context.defaults.responsibilityCompoundId,
      },
      z.object(this.requiredFields),
    )
    if (
      context.permissions.menu_resident_register === 1 &&
      !isStammCompound(this.model.values.responsibilityCompoundId || '')
    ) {
      this.model.values.responsibilityCompoundId = null
    }
    // Pre-fill some data with info from Haushaltsvorstand
    const hv =
      props.residents.resources?.find((r) => r.id === props.family.hv)?.data ?? null
    if (hv) {
      this.model.values.lastName = hv.lastName
      this.model.values.nationalityId = hv.data.nationalityId ?? null
      this.model.values.lfgUnterbringungsgrund = hv.data.lfgUnterbringungsgrund ?? null
      this.model.values.lfgAufenthaltsstatus = hv.data.lfgAufenthaltsstatus ?? null
      this.model.values.cityOfBirth = hv.data.cityOfBirth ?? ''
    }
    makeObservable(this)
  }

  private create = async () => {
    runInAction(() => {
      this.model.values.firstName = this.model.values.firstName.trim()
      this.model.values.lastName = this.model.values.lastName.trim()
      this.model.values.creationUserId = this.context.user.id
      this.model.values.familyId = this.props.familyId
    })
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    try {
      runInAction(() => (this.saving = true))
      const { id } = await hermes.create(`/api/${this.context.instance.id}/residents`, {
        ...this.model.values,
        id: undefined,
      })
      const to = this.props.location.pathname.substring(11).split('/')
      to.shift()
      const url = `/residents/${toJbpId(+id).toLowerCase()}/${to.join('/')}`
      this.props.navigate(url)
      this.dismiss()
    } catch (e) {
      runInAction(() => {
        this.saving = false
        this.error = 'Der Bewohner konnte nicht angelegt werden'
      })
    }
  }

  private dismiss = () => this.props.setOpen(false)

  render() {
    if (this.saving) {
      return (
        <div className='relative p-4 mb-3 mt-6'>
          <Spinner />
        </div>
      )
    }

    if (!this.context.permissions.menu_resident_register) {
      return (
        <div className='rounded-md bg-red-50 p-4 mb-3 mt-6'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
            </div>
            <div className='ml-3'>
              Sie haben nicht die nötige Berechtigung, um neue Personen zu erfassen. Bitte
              wenden Sie sich an einen Administrator. Alternativ können Sie{' '}
              <i>bereits erfasste Personen</i> (s. Tab oben) zu dieser Familie hinzufügen.
            </div>
          </div>
        </div>
      )
    }

    const host_lfgb = this.context.permissions.host_lfgb
    return (
      <div>
        {/* Error message */}
        {this.error && (
          <div className='rounded-md bg-red-50 p-4 mb-3 mt-6'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
              </div>
              <div className='ml-3'>{this.error}</div>
            </div>
          </div>
        )}

        {/* Sex, title, date of birth, id */}
        <div className='grid grid-cols-3 gap-4 mt-6 mb-3' id={this.model.id}>
          <InputCompound
            className='col-span-2'
            model={this.model}
            name='responsibilityCompoundId'
            label={
              this.requiredFields.responsibilityCompoundId
                ? 'Zuständigkeit *'
                : 'Zuständigkeit'
            }
            onlyStamm={this.context.permissions.menu_resident_register === 1}
            saveResponsibility
          />
          <InputDate
            model={this.model}
            name='lfgInitialerCheckIn'
            label={
              this.requiredFields.lfgInitialerCheckIn ? 'Zuständig ab *' : 'Zuständig ab'
            }
            maxLength={10}
          />
          <InputSelect
            model={this.model}
            name='sex'
            label={this.requiredFields.sex ? 'Anrede *' : 'Anrede'}
            options={salutations}
          />
          <InputText
            model={this.model}
            name='firstName'
            label={this.requiredFields.firstName ? 'Vorname *' : 'Vorname'}
            maxLength={255}
          />
          <InputText
            model={this.model}
            name='lastName'
            label={this.requiredFields.lastName ? 'Nachname *' : 'Nachname'}
            maxLength={255}
          />
          <InputDate
            model={this.model}
            name='dateOfBirth'
            label={this.requiredFields.dateOfBirth ? 'Geburtsdatum *' : 'Geburtsdatum'}
            maxLength={10}
          />
          <InputNationality
            model={this.model}
            name='nationalityId'
            label={this.requiredFields.nationalityId ? 'Nationalität *' : 'Nationalität'}
          />
          {host_lfgb && (
            <InputText
              model={this.model}
              name='cityOfBirth'
              label={this.requiredFields.cityOfBirth ? 'Geburtsort *' : 'Geburtsort'}
              maxLength={255}
            />
          )}
          <InputSelect
            model={this.model}
            name='familyRelation'
            label={
              this.requiredFields.familyRelation ? 'Relation zum HV *' : 'Relation zum HV'
            }
            options={familyRelationOptions}
          />
          {host_lfgb && (
            <InputSelect
              model={this.model}
              name='lfgUnterbringungsgrund'
              label='Unterbringungsgrund'
              options={lfgUnterbringungsgrundOptions}
            />
          )}
          {host_lfgb && (
            <InputSelect
              model={this.model}
              name='lfgAufenthaltsstatus'
              label='Aufenthaltsstatus'
              options={lfgAufenthaltsstatusOptions}
            />
          )}
        </div>

        {/* Cancel & submit buttons */}
        <div className='flex mt-6'>
          <Button color='secondary' outline onClick={this.dismiss} className='mr-2'>
            Abbrechen
          </Button>
          <Button color='primary' onClick={this.create}>
            Erstellen
          </Button>
        </div>
      </div>
    )
  }
}
