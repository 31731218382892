import { classNames } from 'helpers/classNames'
import * as React from 'react'

interface Props {
  className?: string
}

export const CaseRecordCaption: React.FC<Props> = (props) => (
  <div
    className={classNames(
      'border-b-2 border-indigo-300 col-span-3 text-sm overflow-hidden h-8',
      props.className,
    )}
  >
    <span
      className='bg-indigo-300 text-white py-2 px-3 relative top-2'
      style={{ borderRadius: '6px 6px 0 0' }}
    >
      {props.children}
    </span>
  </div>
)
