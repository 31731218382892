import { observer } from 'mobx-react'
import * as React from 'react'
import background from './images/hands.jpeg'
import { InputText } from '../../components/Form/components/InputText'
import { InputCheckbox } from '../../components/Form/components/InputCheckbox'
import { makeObservable, observable, runInAction } from 'mobx'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { AuthValidator } from 'contracts/users/validators/AuthValidator'
import { IAuth } from 'contracts/users/interfaces/IAuth'
import { ExclamationIcon } from '@heroicons/react/solid'
import axios from 'axios'
import { AppStatus } from 'services/connection/helpers/getAppStatus'
import { box } from 'services/box'
import { ForgotPassword } from './components/ForgotPassword'
import { hasLdap } from 'contracts/users/helpers/hasLdap'
import { sleep } from 'helpers/sleep'
import * as uuid from 'uuid'
import {
  isAwumInstance,
  isLfgInstance,
  isStjaInstance,
} from 'contracts/general/helpers/instanceIds'

interface Props {
  appStatus: AppStatus
}

@observer
export class Login extends React.Component<Props, {}> {
  private readonly hasStaySignedInCheckbox: boolean
  private readonly hasLdapLogin: boolean
  private readonly model = new Model<IAuth>(
    { login: '', password: '', staySignedIn: false },
    AuthValidator,
  )
  @observable private loading = false

  @observable
  private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.hasStaySignedInCheckbox =
      !isLfgInstance(props.appStatus.instanceId) &&
      !isAwumInstance(props.appStatus.instanceId) &&
      !isStjaInstance(props.appStatus.instanceId)
    this.hasLdapLogin = hasLdap(props.appStatus.instanceId)
  }

  private login = async (event) => {
    event.preventDefault()
    runInAction(() => {
      // Some password managers set the value of the password field without triggering
      // react events. So we just pick the field contents even if react didn't receive them before.
      this.model.values.login = (
        (document.getElementById('login') as any)?.value || this.model.values.login
      ).trim()
      this.model.values.password =
        (document.getElementById('password') as any)?.value || this.model.values.password
    })
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      runInAction(() => (this.error = 'Bitte füllen Sie die Felder aus'))
      return
    }
    try {
      runInAction(() => (this.loading = true))
      await axios.post(
        `/api/${this.props.appStatus.instanceId}/sessions`,
        this.model.values,
      )
      console.log('login successful, wait...')
      await sleep(100)
      console.log('assign(`/cache/...`)')
      // Force reload of index.html to get new session
      // .reload() doesn't work because it doesn't clear the cache
      // .reload(true) clears everything that's cached - including the big js junks - don't do that.
      window.location.assign(`/cache/${uuid.v4()}`)
    } catch (e: any) {
      let message = 'Bei der Anmeldung ist ein Fehler aufgetreten'
      if (e.response?.data?.id === 'NotAuthorizedError') {
        if (e.response.data.message === 'Invalid credentials') {
          message = 'Die Zugangsdaten stimmen nicht überein'
        } else if (e.response.data.message === 'Account deactivated') {
          message = 'Ihr Account wurde deaktiviert'
        } else if (e.response.data.message === 'Invalid phone number') {
          message = 'Die Telefonnummer ist ungültig'
        }
      }
      // Check type of error
      runInAction(() => {
        this.loading = false
        this.error = message
      })
    }
  }

  private forgotPassword = async (event) => {
    event.preventDefault()
    if (this.hasLdapLogin) {
      void box.alert(
        'Passwort vergessen',
        'Inuv ist auf diesem Server so eingestellt, dass Sie sich mit Ihrem Windows Passwort anmelden können. Die Passwortverwaltung liegt daher nicht bei Inuv, sondern bei Ihrem Windows Administrator. Wenden Sie sich deshalb bitte an Ihre IT Abteilung, falls Sie Ihr Windows Passwort vergessen haben.',
      )
      return
    }

    const promise = box.custom(
      <ForgotPassword
        hasLdapLogin={this.hasLdapLogin}
        onClose={(login?: string) => promise.close(login)}
        login={this.model.values.login}
        instanceId={this.props.appStatus.instanceId}
      />,
    )
    const val = await promise
    if (val) {
      runInAction(() => (this.model.values.login = val))
    }
  }

  render() {
    return (
      <div className='min-h-full flex bg-white'>
        <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <h1 className='text-xl font-bold text-indigo-600'>
                <i className='fa fa-sign-in-alt' /> Inuv
              </h1>
              <h2 className='mt-2 text-3xl font-bold text-gray-900'>
                Melden Sie sich an
              </h2>
            </div>

            {this.error && (
              <div className='bg-red-50 border-l-4 p-4 mt-7 border-red-400'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <ExclamationIcon
                      className='h-5 w-5 text-red-400'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='ml-3'>
                    <h3 className='text-sm font-medium text-red-700'>{this.error}</h3>
                  </div>
                </div>
              </div>
            )}

            <div className='mt-8'>
              <div className='mt-6'>
                <form
                  action='#'
                  id={this.model.id}
                  method='POST'
                  className='space-y-6'
                  onSubmit={this.login}
                >
                  <div>
                    <label htmlFor='login' className='block text-sm text-gray-700'>
                      {this.hasLdapLogin ? 'Benutzername' : 'E-Mail oder Handy'}
                    </label>
                    <div className='mt-1'>
                      <InputText
                        id='login'
                        name='login'
                        autoComplete={this.hasLdapLogin ? undefined : 'email'}
                        model={this.model}
                        disabled={this.loading}
                        placeholder={this.hasLdapLogin ? 'Vorname.Nachname' : undefined}
                      />
                    </div>
                  </div>

                  <div className='space-y-1'>
                    <label htmlFor='password' className='block text-sm text-gray-700'>
                      Passwort
                    </label>
                    <div className='mt-1'>
                      <InputText
                        id='password'
                        name='password'
                        type='password'
                        autoComplete='current-password'
                        model={this.model}
                        disabled={this.loading}
                        placeholder={this.hasLdapLogin ? 'Windows Passwort' : undefined}
                      />
                    </div>
                  </div>

                  <div className='flex items-center justify-between'>
                    {this.hasStaySignedInCheckbox && (
                      <InputCheckbox
                        name='staySignedIn'
                        model={this.model}
                        label='Angemeldet bleiben'
                        disabled={this.loading}
                      />
                    )}

                    <div className='text-sm'>
                      <a
                        href='/'
                        className='text-indigo-600 hover:text-indigo-500'
                        onClick={this.forgotPassword}
                      >
                        Passwort vergessen?
                      </a>
                    </div>
                  </div>

                  <div>
                    <Button type='submit' block disabled={this.loading}>
                      {this.loading && (
                        <span className='mr-1'>
                          <i className='fas fa-spinner fa-spin' />
                        </span>
                      )}
                      Login
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden lg:block relative w-0 flex-1'>
          <img
            className='absolute inset-0 h-full w-full object-cover'
            src={background}
            alt=''
          />
        </div>
      </div>
    )
  }
}
