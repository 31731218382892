import { IHealth } from 'contracts/residents/interfaces/IHealth'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  health?: IHealth | null
}

export const QuarantineTile: React.FC<Props> = observer((props) => (
  <div
    className={`${
      props.health ? 'from-red-600 to-red-400' : 'from-green-600 to-green-400'
    } min-h-[100px] bg-gradient-to-r text-white rounded-md shadow-md p-4 w-full`}
  >
    <div
      className={`${
        props.health ? 'text-red-500' : 'text-green-500'
      } text-2xl absolute rounded-md bg-white w-12 h-12 text-center pt-2`}
    >
      <i className='fas fa-user-shield' aria-hidden='true' />
    </div>
    <p className='ml-16 truncate font-bold text-xl text-white'>Quarantäne</p>
    <p className='ml-16 truncate font-medium text-sm text-white'>
      {props.health
        ? `bis ${dayjs(props.health.intakeUntilDate).format('DD.MM.YYYY')}`
        : 'Keine laufende Quarantäne'}
    </p>
  </div>
))
