import * as React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.scss'

interface Props {
  label: string
  to: string
  pathname?: string
}

export class TitleBarTab extends React.Component<Props, {}> {
  render() {
    return (
      <NavLink
        to={this.props.to}
        className={(navData) =>
          navData.isActive ? `${styles.active} ${styles.titleBarTab}` : styles.titleBarTab
        }
      >
        <span className={styles.tabLabel}>{this.props.label}</span>
      </NavLink>
    )
  }
}
