import { hermes } from '@byll/hermes'
import { Menu, Transition } from '@headlessui/react'
import { getRoomLockReasons } from 'contracts/accommodations/helpers/getRoomLockReasons'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IRoomLock } from 'contracts/accommodations/interfaces/IRoomLock'
import { Dayjs } from 'dayjs'
import { isStammBuilding } from 'helpers/isStamm'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Fragment } from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { RoomLockAlert } from './RoomLockAlert'
import { dayjs } from 'helpers/dayjs'

interface Props {
  room: IRoom
  askNotes?: boolean
  lock: IRoomLock | null
  onChange?: (lock: string | null) => void
  at?: Dayjs // Timestamp when lock should be evaluated, it not set, current time is used and lock is editable
}

@observer
export class RoomLockDropdown extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly reasons: any[]

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.reasons = [null, ...getRoomLockReasons(context.instance.id, context.permissions)]
  }

  private setLock = async (reason: string | null) => {
    if (this.props.at) {
      alert(
        'Diese Ansicht zeigt einen Zeitpunkt in der Zukunft oder Vergangenheit. Die Zimmersperre kann nur in einer Ansicht geändert werden, die den gegenwärtigen Zustand der Zimmersperren widerspiegelt.',
      )
      return
    }

    let notes = ''
    if (reason && this.props.askNotes) {
      const promise = box.custom(
        <RoomLockAlert reason={reason} onClose={(lock) => promise.close(lock)} />,
        { closable: true, context: this.context },
      )
      const lock = await promise
      if (!lock) {
        return
      }
      notes = lock.notes
      reason = lock.reason
    }
    try {
      const oldReason = this.props.lock?.reason || null
      await hermes.update(
        `/api/${this.context.instance.id}/accommodations/rooms/${this.props.room.id}`,
        {
          lock: !reason
            ? null
            : {
                beginAt: dayjs().startOf('minute').toISOString(),
                beginBy: `${this.context.user.lastName}, ${this.context.user.firstName}`,
                endAt: null,
                endBy: '',
                reason,
                notes,
              },
        },
      )
      if (oldReason !== reason && this.props.onChange) {
        this.props.onChange(reason)
      }
    } catch (_e) {
      box.alert('Sperren', 'Das Sperren oder Entsperren ist fehlgeschlagen.')
    }
  }

  private onOpen = (event) => {
    if (this.props.lock === null) {
      // Menu is opened to set a lock
      if (this.context.permissions.asset_roomLock === 0) {
        void box.alert(
          'Berechtigung',
          'Ihre Berechtigungsstufe erlaubt es nicht, ein Zimmer zu sperren.',
        )
        event.stopPropagation()
        return
      }
      if (
        this.context.permissions.asset_roomLock === 1 &&
        !isStammBuilding(this.props.room.buildingId)
      ) {
        void box.alert(
          'Berechtigung',
          'Ihre Berechtigungsstufe erlaubt es nicht, ein Zimmer außerhalb Ihrer Stammgebäude zu sperren.',
        )
        event.stopPropagation()
        return
      }
    } else {
      // Menu is opened to remove a lock
      if (this.context.permissions.asset_removeRoomLock === 0) {
        void box.alert(
          'Berechtigung',
          'Ihre Berechtigungsstufe erlaubt es nicht, ein Zimmer zu entsperren.',
        )
        event.stopPropagation()
        return
      }
      if (
        this.context.permissions.asset_removeRoomLock === 1 &&
        !isStammBuilding(this.props.room.buildingId)
      ) {
        void box.alert(
          'Berechtigung',
          'Ihre Berechtigungsstufe erlaubt es nicht, ein Zimmer außerhalb Ihrer Stammgebäude zu entsperren.',
        )
        event.stopPropagation()
        return
      }
    }
  }

  render() {
    return (
      <Menu
        as='span'
        className='relative inline-block text-left'
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <Menu.Button onClickCapture={this.onOpen}>
            {this.props.lock ? (
              <span key='lock' className='text-red-500'>
                <i className='fas fa-lock' />
              </span>
            ) : (
              <span key='unlock'>
                <i className='fas fa-unlock' />
              </span>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='z-10 origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1'>
              {this.reasons.map((r) => (
                <Menu.Item key={r || '-'}>
                  <span
                    onClick={(e) => {
                      this.setLock(r)
                    }}
                    className={`cursor-pointer block px-4 py-2 text-sm ${
                      this.props.lock?.reason === r
                        ? 'bg-blue-500 text-white'
                        : 'hover:bg-gray-100'
                    }`}
                  >
                    {r || 'Offen'}
                  </span>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
