import * as React from 'react'
import { Model } from '../../../../../../../../../../components/Form/Model'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { isTime } from 'contracts/general/helpers/isTime'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { InputDate } from 'components/Form/components/InputDate'
import { Button } from 'components/Form/components/Button'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { z } from 'zod'
import { Collection, hermes } from '@byll/hermes'
import { dayjs } from 'helpers/dayjs'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { InputDocument } from 'components/Form/components/InputDocument'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { Message } from 'components/Message'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { Tooltip } from 'components/Tooltip'
import { ExternalResidenceFormStep2Creating } from './components/ExternalResidenceFormStep2Creating'
import { isLfgInstance, isMucInstance } from 'contracts/general/helpers/instanceIds'

export interface ExternalResidenceModel {
  isNew: boolean
  beginDate: string | null
  beginTime: string
  endDate: string | null
  endTime: string
  comment: string
  hasEnd: boolean
  reason: string
  documentId: string | null
}

interface Props {
  resident: IResident
  booking: IBooking
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  bookings?: IBooking[]
}

@observer
export class ExternalResidenceForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disabled: boolean
  private readonly model: Model<ExternalResidenceModel & { family: boolean }>
  private readonly reasons: InputSelectOption[]
  @observable private step: 'saving' | 'batch create' | null = null
  @observable private error: string | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    if (this.props.booking.id && context.permissions.resident_patchBookings === 0) {
      this.error = 'Sie können diese Meldung nur ansehen, aber nicht ändern.'
    }
    if (!this.props.booking.id && context.permissions.resident_externalCheckInOut === 0) {
      this.error = 'Sie können diese Meldung nur ansehen, aber nicht ändern.'
    }
    if (isLfgInstance(context.instance.id)) {
      this.reasons = [
        { value: 'Krankenhausaufenthalt', label: 'Krankenhausaufenthalt' },
        { value: 'Genehmigte Ortsabwesenheit', label: 'Genehmigte Ortsabwesenheit' },
        { value: 'JVA Aufenthalt', label: 'JVA Aufenthalt' },
        { value: '', label: 'Andere' },
      ]
    } else if (isMucInstance(context.instance.id)) {
      this.reasons = [
        { value: 'Krankenhausaufenthalt', label: 'Krankenhausaufenthalt' },
        { value: 'Urlaub', label: 'Urlaub' },
        { value: 'JVA Aufenthalt', label: 'JVA Aufenthalt' },
        { value: '', label: 'Andere' },
      ]
    } else {
      this.reasons = [
        { value: 'Krankenhausaufenthalt', label: 'Krankenhausaufenthalt' },
        { value: 'Urlaub', label: 'Urlaub' },
        { value: 'Vermisst', label: 'Vermisst' },
        { value: 'JVA Aufenthalt', label: 'JVA Aufenthalt' },
        { value: '', label: 'Andere' },
      ]
    }
    this.disabled = !!this.error
    const beginAt = dayjs(props.booking.beginAt)
    const endAt = props.booking.endAt ? dayjs(props.booking.endAt) : null
    const validator = z.object({
      beginDate: z.string(),
      beginTime: z.string().refine(isTime),
      endDate: z
        .union([z.string(), z.null()])
        .refine((val) => !this.model.values.hasEnd || val !== null),
      endTime: z.string().refine((val) => !this.model.values.hasEnd || isTime(val)),
    })
    this.model = new Model(
      {
        isNew: !props.booking.id,
        beginDate: beginAt.format('YYYY-MM-DD'),
        beginTime: beginAt.format('HH:mm'),
        endDate: endAt?.format('YYYY-MM-DD') || null,
        endTime: endAt?.format('HH:mm') || '',
        comment: props.booking.comment,
        hasEnd: !!endAt,
        reason: props.booking.reason,
        documentId: props.booking.documentId || null,
        family: false,
      },
      validator,
    )
    makeObservable(this)
  }

  @action
  private toggleHasEnd = () => {
    this.model.values.hasEnd = !this.model.values.hasEnd
    this.model.values.endDate = null
    this.model.values.endTime = ''
  }

  private onSubmit = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    try {
      const data: Partial<IBooking> = {
        compoundId: null,
        instanceId: this.props.booking.instanceId,
        type: this.props.booking.type,
        residentId: this.props.booking.residentId,
        roomId: this.props.booking.roomId,
        reason: this.model.values.reason,
        comment: this.model.values.comment,
        beginAt: dayjs(
          `${this.model.values.beginDate} ${this.model.values.beginTime}`,
        ).toISOString(),
        endAt: this.model.values.hasEnd
          ? dayjs(
              `${this.model.values.endDate} ${this.model.values.endTime}`,
            ).toISOString()
          : null,
        documentId: this.model.values.documentId,
        extra: {},
      }

      if (data.endAt && dayjs(data.beginAt).isSameOrAfter(data.endAt)) {
        throw new Error('Der Anfangszeitpunkt muss vor dem Endzeitpunkt liegen.')
      }

      runInAction(() => (this.step = 'saving'))
      if (this.props.booking.id) {
        delete data.instanceId
        delete data.type
        delete data.residentId
        delete data.roomId
        delete data.compoundId
        await hermes.patch(
          `/api/${this.context.instance.id}/bookings/${this.props.booking.id}`,
          data,
        )
      } else {
        if (this.model.values.family) {
          runInAction(() => (this.step = 'batch create'))
          return
        } else {
          await hermes.create(`/api/${this.context.instance.id}/bookings`, data)
        }
      }
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.error = e?.message || 'Die Buchung konnte nicht gespeichert werden.'
        this.step = null
      })
    }
  }

  @action
  private goBack = () => {
    this.error = null
    this.step = null
  }

  render() {
    const disabled = this.disabled || !!this.step
    const offerFamilyCheckbox =
      this.model.values.isNew &&
      this.props.members.resources &&
      this.props.members.resources.length > 1

    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Abwesenheit
            </Dialog.Title>
          </div>
        </div>

        {this.step !== 'batch create' && (
          <>
            {this.error && (
              <Message color='danger' className='mt-6'>
                {this.error}
              </Message>
            )}

            <div className='relative grid grid-cols-4 gap-4 mt-4' id={this.model.id}>
              <InputSelect
                disabled={disabled}
                model={this.model}
                name='reason'
                label='Grund'
                className='col-span-4'
                options={this.reasons}
              />
              <InputDate
                disabled={disabled}
                model={this.model}
                name='beginDate'
                label='Ab'
                placeholder='DD.MM.JJJJ'
              />
              <InputText
                disabled={disabled}
                model={this.model}
                name='beginTime'
                tooltip='Uhrzeit benötigt'
                placeholder='hh:mm'
              />
              <div className='relative'>
                <InputDate
                  disabled={disabled || !this.model.values.hasEnd}
                  model={this.model}
                  name='endDate'
                  label='      Bis'
                  placeholder='DD.MM.JJJJ'
                />
                <input
                  type='checkbox'
                  disabled={disabled}
                  checked={this.model.values.hasEnd}
                  onChange={this.toggleHasEnd}
                  style={{
                    position: 'absolute',
                    top: -8,
                    left: 12,
                    zIndex: 1,
                    transform: 'scale(0.9)',
                    borderRadius: '4px',
                  }}
                  className='border-gray-300 text-indigo-600'
                />
              </div>
              <InputText
                disabled={disabled || !this.model.values.hasEnd}
                tooltip='Uhrzeit benötigt'
                model={this.model}
                name='endTime'
                placeholder='hh:mm'
              />
              <InputTextarea
                disabled={disabled}
                model={this.model}
                name='comment'
                label='Notiz'
                rows={3}
                className='col-span-4'
              />
              <InputDocument
                scope='booking'
                disabled={disabled}
                model={this.model}
                name='documentId'
                label={
                  this.model.values.reason === 'Krankenhausaufenthalt'
                    ? 'Liegebescheinigung'
                    : 'Anhang'
                }
                className='col-span-4'
                preview
              />

              {offerFamilyCheckbox && (
                <div className='col-span-4 flex'>
                  <InputCheckbox
                    className='flex-content'
                    disabled={disabled}
                    model={this.model}
                    name='family'
                    label='Alle Familienmitglieder'
                  />
                  <div className='flex-content ml-2 mr-auto text-blue-500 has-tooltip'>
                    <i className='fas fa-question-circle' />
                    <Tooltip>
                      Abwesenheit für alle
                      <br />
                      Familienmitglieder festlegen
                    </Tooltip>
                  </div>
                </div>
              )}

              <div className='col-span-4 text-right'>
                <Button color='secondary' outline onClick={() => this.props.onClose()}>
                  {disabled ? 'Schließen' : 'Abbrechen'}
                </Button>
                {!disabled && (
                  <Button color='primary' className='ml-2' onClick={this.onSubmit}>
                    {this.props.booking.id ? 'Speichern' : 'Abwesenheit erstellen'}
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
        {this.step === 'batch create' && (
          <ExternalResidenceFormStep2Creating
            resident={this.props.resident}
            members={this.props.members}
            onClose={this.props.onClose}
            model={this.model}
            goBack={this.goBack}
          />
        )}
        {this.step === 'saving' && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
