import { action, makeObservable, observable } from 'mobx'
import * as React from 'react'
import { ToggleContext, ToggleContextProps } from './models/ToggleContext'

interface Props {}

export class SideBarLayout extends React.Component<Props, {}> {
  @observable private contextProps: ToggleContextProps

  constructor(props: Props) {
    super(props)
    this.contextProps = {
      isOpen: false,
      toggle: action(
        (isOpen?: boolean) =>
          (this.contextProps.isOpen =
            typeof isOpen === 'boolean' ? isOpen : !this.contextProps.isOpen),
      ),
    }
    makeObservable(this)
  }

  render() {
    return (
      <ToggleContext.Provider value={this.contextProps}>
        <div className='bg-gray-100 min-h-full'>{this.props.children}</div>
      </ToggleContext.Provider>
    )
  }
}
