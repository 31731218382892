import * as React from 'react'
import { IGroupField } from '../../../contracts/groups/interfaces/IGroupField'
import { InputText } from '../../../components/Form/components/InputText'
import { Model } from '../../../components/Form/Model'
import { InputSelect } from '../../../components/Form/components/InputSelect'
import { observer } from 'mobx-react'
import { RoundIcon } from '../../../components/RoundIcon'
import { IDialogField } from './NewGroupDialog'

interface Props {
  field: IDialogField
  onDelete: Function
}

const types = [
  {
    value: '',
    label: 'Bitte wählen...',
  },
  {
    value: 'text',
    label: 'Textfeld',
  },
  {
    value: 'checkbox',
    label: 'Checkbox',
  },
  {
    value: 'select',
    label: 'Dropdown',
  },
  {
    value: 'date',
    label: 'Datum',
  },
]

@observer
export class GroupField extends React.Component<Props, {}> {
  private readonly model: Model<IGroupField>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.field)
  }

  render() {
    return (
      <div>
        <div className='flex gap-3 my-5 items-center'>
          <RoundIcon
            color='danger'
            icon='fas fa-minus fa-xs'
            onClick={() => this.props.onDelete(this.props.field.id)}
            tooltip={{ text: 'Feld entfernen', offset: -80 }}
            style={{
              width: 16,
              height: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
          <InputText className='flex-[0_0_200px]' name={'label'} model={this.model} />
          <InputSelect
            className='flex-auto'
            name={'type'}
            model={this.model}
            options={types}
          />
        </div>
        {this.model.values.type === 'select' && (
          <InputText
            name={'options'}
            model={this.model}
            label={'Auswahlmöglichkeiten'}
            placeholder='Normal, Vegetarisch, Vegan'
            className='mb-5 ml-7'
            maxLength={500}
          />
        )}
      </div>
    )
  }
}
