import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { UnsyncedCollection } from '../modules/FindRecord/models/UnsyncedCollection'

interface Props {
  residents: UnsyncedCollection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  orderBy?: string
}

@observer
export class SearchCaption extends React.Component<Props, {}> {
  private sort = action(() => {
    if (!this.props.orderBy) {
      return
    }
    const [col, direction] = (this.props.residents.query.sort || '').split(',')
    if (col === this.props.orderBy) {
      this.props.residents.query.sort = (this.props.orderBy +
        (direction === 'asc' ? ',desc' : ',asc')) as any
    } else {
      this.props.residents.query.sort = (this.props.orderBy + ',asc') as any
    }
  })

  render() {
    let icon: string | null = null
    if (this.props.orderBy) {
      icon = 'fas fa-sort text-indigo-300'
      if (
        `${this.props.orderBy},desc` === this.props.residents.metadata?.sort &&
        `${this.props.orderBy},desc` === this.props.residents.query.sort
      ) {
        icon = 'fas fa-caret-down'
      } else if (
        `${this.props.orderBy},asc` === this.props.residents.metadata?.sort &&
        `${this.props.orderBy},asc` === this.props.residents.query.sort
      ) {
        icon = 'fas fa-caret-up'
      } else if (
        `${this.props.orderBy},asc` === this.props.residents.query.sort ||
        `${this.props.orderBy},desc` === this.props.residents.query.sort
      ) {
        icon = 'fas fa-sync fa-spin'
      }
    }

    return (
      <span
        className={`${
          this.props.orderBy ? 'cursor-pointer ' : ''
        }inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800`}
        onClick={this.sort}
      >
        {this.props.children}
        {icon && (
          <span className='ml-2' key={icon}>
            <i className={icon} />
          </span>
        )}
      </span>
    )
  }
}
