import * as React from 'react'
import { Card } from 'components/Card'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { hermes } from '@byll/hermes'
import { observer } from 'mobx-react'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { IResidentCount } from 'contracts/dashboard/interfaces/IResidentCount'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { isStammCompound } from 'helpers/isStamm'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import * as qs from 'qs'
import { DashboardResidentStatsCompounds } from './components/DashboardResidentStatsCompounds'
import { storage } from 'services/storage'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { box } from 'services/box'
import { DashboardResidentStatsCompoundSelectionDialog } from './components/DashboardResidentStatsCompoundSelectionDialog'

ChartJS.register(ArcElement, Tooltip)

interface Props {}

@observer
export class DashboardResidentStats extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private data: any | null = null
  private readonly model: Model<{ compoundIds: string; field: string }>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    const stored =
      storage.get(`dashboard_residentStats_compoundIds.${context.user.id}`) ||
      context.defaults.responsibilityCompoundId ||
      ''
    let compoundIds: string[] =
      typeof stored === 'string'
        ? stored
            .split(',')
            .filter(
              (id) =>
                isIntegerString(id) &&
                !!hermes.getFromStore(
                  `/api/${context.instance.id}/accommodations/compounds/${id}`,
                  false,
                ),
            )
        : []
    if (context.permissions.dashboard_residentCounts === 1) {
      compoundIds = compoundIds.filter((id) => isStammCompound(id))
    }
    this.model = new Model({ compoundIds: compoundIds.join(','), field: 'nationality' })
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => `${this.model.values.compoundIds}/${this.model.values.field}`,
        this.load,
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private load = async () => {
    if (!this.model.values.compoundIds) {
      runInAction(() => (this.data = null))
      return
    }
    storage.set(
      `dashboard_residentStats_compoundIds.${this.context.user.id}`,
      this.model.values.compoundIds,
    )
    const counts = await hermes.indexOnceNew<IResidentCount>(
      `/api/${this.context.instance.id}/dashboard/residentCounts${qs.stringify(
        this.model.values,
        { allowDots: true, addQueryPrefix: true, skipNulls: true },
      )}`,
    )
    runInAction(() => {
      this.data = {
        // Beachte Leerzeichen! (Sonst sieht das im Popover gedrückt aus)
        labels: counts.map((c) => ' ' + c.label),
        datasets: [
          {
            label: '# of Votes',
            data: counts.map((c) => c.count),
            backgroundColor: counts.map((c) => c.color),
            borderColor: ['#FFFFFF'],
            borderWidth: 4,
          },
        ],
      }
    })
  }

  private openCompoundSelectionDialog = () => {
    const promise = box.custom(
      <DashboardResidentStatsCompoundSelectionDialog
        model={this.model}
        name='compoundIds'
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context },
    )
  }

  render() {
    return (
      <Card>
        <div className='p-4'>
          <div className='font-bold text-xl mb-2'>Bewohnerstatistik</div>
          <DashboardResidentStatsCompounds
            model={this.model}
            name='compoundIds'
            onSelect={this.openCompoundSelectionDialog}
          />
          {this.data && (
            <>
              <div className='text-gray-700 text-base overflow-hidden max-h-[66px]'>
                {this.data.labels.map((label, i) => (
                  <span
                    key={i}
                    className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-normal text-gray-700 mr-2 mb-2'
                  >
                    <i
                      className='fas fa-circle'
                      style={{ color: this.data.datasets[0].backgroundColor[i] }}
                    />{' '}
                    {label}
                  </span>
                ))}
              </div>
              <div className='py-4 mx-auto max-w-[280px]'>
                <Doughnut data={this.data} />
              </div>
            </>
          )}
        </div>
      </Card>
    )
  }
}
