import { Collection } from '@byll/hermes'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { IGroupParticipantFilter } from '../../../contracts/groups/interfaces/IGroupParticipantFilter'
import { IExtendedGroupParticipantEntry } from 'contracts/groups/interfaces/IExtendedGroupParticipantEntry'

interface Props {
  entries: Collection<
    IExtendedGroupParticipantEntry,
    { sort: string },
    IGroupParticipantFilter
  >
  orderBy: string
}

@observer
export class SearchCaption extends React.Component<Props, {}> {
  private sort = action(() => {
    const parts = this.props.entries.query.sort?.split(',') ?? []
    if (parts[0] !== this.props.orderBy) {
      this.props.entries.query.sort = `${this.props.orderBy},asc`
    } else {
      this.props.entries.query.sort = `${this.props.orderBy},${
        parts[1] === 'asc' ? 'desc' : 'asc'
      }`
    }
  })

  render() {
    const m = this.props.entries.metadata?.sort?.split(',') ?? []
    const q = this.props.entries.query.sort?.split(',') ?? []

    let icon: string = 'fas fa-sort'
    if (this.props.orderBy === m[0] && this.props.orderBy === q[0] && q[1] === m[1]) {
      icon = q[1] === 'asc' ? 'fas fa-caret-up' : 'fas fa-caret-down'
    } else if (this.props.orderBy === q[0]) {
      icon = 'fas fa-sync fa-spin'
    }

    return (
      <span
        className={`${this.props.orderBy ? 'cursor-pointer ' : ''}`}
        onClick={this.sort}
      >
        {this.props.children}
        {icon && (
          <span className='ml-2' key={icon}>
            <i className={icon} />
          </span>
        )}
      </span>
    )
  }
}
