import { TopBar } from 'components/SideBarLayout/components/TopBar'
import * as React from 'react'
import { useLocation } from 'react-router'
import { IReport } from '..'

interface Props {
  reports: IReport[]
}

export const ReportingTopBar: React.FC<Props> = (props) => {
  const match = useLocation().pathname.split('/')[2] ?? ''

  return (
    <TopBar>
      <div style={{ flex: '0 0 64px', lineHeight: '64px' }} className='px-2 text-lg'>
        <i className='fas fa-th-list text-gray-400' />
        <span className='ml-3 text-gray-500'>
          {props.reports.find((report) => report.key === match)?.label ??
            'Wählen Sie einen Report aus'}
        </span>
      </div>
    </TopBar>
  )
}
