import { IFamily } from '../interfaces/IFamily'

/**
 * Only allow (and enforce) type 'E' (Einzelperson) for families with one member.
 * @param family
 * @param memberCount
 * @returns
 */
export function getFamilyType(
  family: { type: IFamily['type'] },
  memberCount: number,
): IFamily['type'] {
  if (memberCount === 1) {
    return 'E'
  }
  if (family.type === 'E') {
    return null
  }
  return family.type
}
