import { BuildingSelectSidebar } from 'components/BuildingSelectSideBar/index'
import { Model } from 'components/Form/Model'
import { SideBarLayout } from 'components/SideBarLayout'
import { ITransferPlanningsFilter } from 'contracts/transfer/interfaces/ITransferPlanningsFilter'
import dayjs from 'dayjs'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { TransferPlanningContent } from './components/TransferPlanningContent'

interface Props {}

@observer
export class TransferPlanning extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable selectedBuildings = new Map<string, boolean>() //key: buildingId, value: selected/not selected
  private readonly model: Model<ITransferPlanningsFilter>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({
      begin: dayjs().format('YYYY-MM-DD'),
      end: dayjs().add(7, 'day').format('YYYY-MM-DD'),
      transportationId: null,
      targetId: null,
      createdBy: null,
      status: null,
    })
  }

  @action
  private changeHandler = () => {
    if (this.selectedBuildings.get('§')) {
      this.selectedBuildings.delete('§')
    } else {
      this.selectedBuildings.set('§', true)
    }
  }

  render() {
    if (this.context.permissions.menu_residentTransferPlanningList_show === 0) {
      return <Forbidden />
    }
    return (
      <SideBarLayout>
        <BuildingSelectSidebar
          label='Transferplanung'
          icon='fas fa-exchange-alt'
          selectedBuildings={this.selectedBuildings}
        >
          {/*this.context.permissions.menu_residentTransferPlanningList_show === 2 && (
            <label
              className={classNames(
                this.selectedBuildings.has('§')
                  ? 'bg-gray-100 text-gray-900 border-2 border-indigo-500'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group relative flex items-center px-2 py-2 text-sm font-medium rounded-md truncate cursor-pointer',
              )}
            >
              <input
                className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                type='checkbox'
                checked={!!this.selectedBuildings.get('§')}
                onChange={this.changeHandler}
              />
              <span className='ml-3'>Verlegung nach §42a</span>
            </label>
              )*/}
        </BuildingSelectSidebar>
        <TransferPlanningContent
          key={`${this.selectedBuildings.size}-${this.model.values.begin}-${
            this.model.values.end
          }-${this.model.values.transportationId}-${this.model.values.targetId}-${
            this.model.values.createdBy
          }-${this.model.values.status}-${
            this.selectedBuildings.size === 1
              ? Array.from(this.selectedBuildings.keys())[0]
              : 'all'
          }}`}
          selectedBuildings={this.selectedBuildings}
          model={this.model}
        />
      </SideBarLayout>
    )
  }
}
