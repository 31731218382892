export interface ResidentField {
  key: string
  label: string
  type?: 'enum' | 'number' | 'date' | 'nationality' | 'country' | 'user' | 'boolean'
  enum?: { [key: string]: string }
  reverseEnum?: { [key: string]: string }
}

export const residentFields: ResidentField[] = [
  { key: 'mId', label: 'MID (LABEA)' },
  { key: 'bvNr', label: 'BV Nr.' },
  { key: 'tId', label: 'T-ID' },
  {
    key: 'category',
    label: 'Gruppe',
    type: 'enum',
    enum: { child: 'Kind', umf: 'UMF', adult: 'Erwachsener' },
  },
  { key: 'familyId', label: 'Familiennummer' },
  { key: 'registrationDate', label: 'Registrierungsdatum', type: 'date' },
  { key: 'firstName', label: 'Vorname' },
  { key: 'lastName', label: 'Nachname' },
  { key: 'aliasName', label: 'Aliasname' },
  { key: 'dateOfBirth', label: 'Geburtsdatum', type: 'date' },
  { key: 'nationalityId', label: 'Staatsangehörigkeit', type: 'nationality' },
  { key: 'nativeLanguage', label: 'Muttersprache' },
  { key: 'countryOfBirthId', label: 'Herkunftsland', type: 'country' },
  { key: 'cityOfBirth', label: 'Geburtsort' },
  { key: 'religion', label: 'Religion' },
  {
    key: 'sex',
    label: 'Anrede',
    type: 'enum',
    enum: { male: 'Herr', female: 'Frau', divers: 'Divers' },
  },
  { key: 'title', label: 'Titel' },
  { key: 'familyRelation', label: 'Familienrelation zu HV' },
  { key: 'roleInFamily', label: 'Rolle in der Familie' },
  { key: 'lfgSozialarbeiter', label: 'Sozialarbeiter', type: 'user' },
  { key: 'lfgSchutzbeduerftig', label: 'Schutzbedürftig' },
  { key: 'lfgDetailSchutzbedarf', label: 'Detail zum Schutzbedarf' },
  { key: 'lfgUnterbringungsgrund', label: 'Unterbringungsgrund' },
  { key: 'lfgAufenthaltsstatus', label: 'Aufenthaltsstatus' },
  { key: 'residentStatus', label: 'Bewohnerstatus' },
  {
    key: 'lfgAufenthaltsstatusValidTill',
    label: 'Aufenthaltsstatus gültig bis',
    type: 'date',
  },
  { key: 'phone', label: 'Telefonnummer' },
  { key: 'email', label: 'E-Mail' },
  { key: 'contactBevorzugterKanal', label: 'Bevorzugter Kanal' },
  { key: 'briefkasten', label: 'Briefkasten' },
  { key: 'vulnerableGruppen', label: 'Vulnerable Gruppen' },
  { key: 'kaId', label: 'KA-ID' },
  { key: 'wimNr', label: 'WIM-Nr.' },
  { key: 'pickUpLocation', label: 'Aufgriffsort' },
  { key: 'legalAufenthaltsstatus', label: 'Aufenthaltstitel' },
  { key: 'legalAufenthaltsstatusSeit', label: 'Aufenthaltstitel seit', type: 'date' },
  { key: 'legalAufenthaltsstatusComment', label: 'Aufenthaltstitel Notizen' },
  { key: 'maritalStatus', label: 'Familienstand' },
  { key: 'comment', label: 'Allgemeine Notizen' },
  { key: 'hasParentData', label: 'Elterndaten wurden ermittelt' },
  { key: 'parentData', label: 'Elterndaten' },
  { key: 'parentDataDate', label: 'Elterndaten Versanddatum' },
  { key: 'guardianName', label: 'Vormund Name' },
  { key: 'guardianAgency', label: 'Vormund Träger' },
  { key: 'guardianPhone', label: 'Vormund Telefon' },
  { key: 'guardianFax', label: 'Vormund Fax' },
  { key: 'guardianEmail', label: 'Vormund E-Mail' },
  { key: 'rguDate', label: 'RGU Datum', type: 'date' },
  { key: 'rguResult', label: 'RGU Ergebnis' },
  { key: 'gvhCheckDate', label: 'Gesundheitscheck Datum', type: 'date' },
  { key: 'gvhCheckResult', label: 'Gesundheitscheck Ergebnis' },
  { key: 'alterseinschaetzungDate', label: 'AE Datum', type: 'date' },
  { key: 'alterseinschaetzungResult', label: 'AE Ergebnis' },
  {
    key: 'alterseinschaetzungNewDateOfBirth',
    label: 'AE Neues Geburtsdatum',
    type: 'date',
  },
  { key: 'alterseinschaetzungIsLawsuit', label: 'AE Widerspruch/Klage' },
  {
    key: 'alterseinschaetzungLawsuitResult',
    label: 'AE Widerspruch/Klage Status',
    type: 'enum',
    enum: { ongoing: 'Laufend', successful: 'Erfolgreich', unsuccessful: 'Ohne Erfolg' },
  },
  { key: 'languageIds', label: 'Sprachen' },

  {
    key: 'inobhutnahmeState',
    label: 'Inobhutnahme Status',
    type: 'enum',
    enum: {
      'bundesweite-verteilung': 'Bundesweite Verteilung',
      'verlegungshindernis-pruefung': 'Verlegungshindernis Prüfung',
      verlegungshindernis: 'Verlegungshindernis',
      'uebernahme-nach-fristablauf': 'Übernahme nach Fristablauf',
      'zuweisung-nach-koenigssteiner': 'Zuweisung nach Königssteiner Schlüssel',
    },
  },
  { key: 'inobhutnahmeBeginDate', label: 'Inobhutnahme ab Datum', type: 'date' },
  { key: 'inobhutnahmeEndDate', label: 'Inobhutnahme bis Datum', type: 'date' },
  {
    key: 'p41State',
    label: 'Antrag § 41 SGB VIII',
    type: 'enum',
    enum: { yes: 'Ja', no: 'Nein' },
  },
  { key: 'p41Comment', label: 'Antrag § 41 SGB VIII Notiz' },
  { key: 'p41ProposalDate', label: 'Antragsdatum § 41 SGB VIII', type: 'date' },
  { key: 'p41ApprovalState', label: 'Antragsstatus § 41 SGB VIII' },
  { key: 'p41ApprovalDate', label: 'Antrag § 41 SGB VIII genehmigt am', type: 'date' },
  { key: 'p41ApprovalUserName', label: 'Antrag § 41 SGB VIII genehmigt von' },
  { key: 'hasAsylumRequest', label: 'Asylbegehren geäußert' },
  { key: 'asylumRequestDate', label: 'Asylbegehren geäußert am', type: 'date' },
  {
    key: 'asylumApplicationSubmissionDate',
    label: 'Asylantrag gestellt am',
    type: 'date',
  },
  { key: 'hasClothingAllowance', label: 'Kleidergeld ausgehändigt' },
  { key: 'clothingAllowanceDate', label: 'Kleidergeld Aushändigungsdatum', type: 'date' },
  { key: 'clothingAllowanceAmount', label: 'Kleidergeld Betrag' },
  { key: 'clearingReportDate', label: 'Clearingbericht Datum', type: 'date' },
  { key: 'clearingReport', label: 'Clearingbericht' },
  {
    key: 'federalTransferCharacteristics',
    label: 'Besonderheiten bei bundesweiter Verlegung',
  },
  {
    key: 'kindeswohlgefaehrdungCheckbox',
    label: 'Inobhutnahme Verlegungshindernis Kindeswohlgefaehrdung',
  },
  {
    key: 'kindeswohlgefaehrdungText',
    label: 'Inobhutnahme Verlegungshindernis Kindeswohlgefaehrdung Text',
  },
  {
    key: 'gesundheitszustandCheckbox',
    label: 'Inobhutnahme Verlegungshindernis Gesundheitszustand',
  },
  {
    key: 'verwandschaftszusammenfuehrungCheckbox',
    label: 'Inobhutnahme Verlegungshindernis Verwandschaftszusammenfuehrung',
  },
  {
    key: 'verwandschaftszusammenfuehrungText',
    label: 'Inobhutnahme Verlegungshindernis Verwandschaftszusammenfuehrung Text',
  },
  {
    key: 'gemeinsameInobhutnahmeCheckbox',
    label: 'Inobhutnahme Verlegungshindernis gemeinsame Inobhutnahme',
  },
  {
    key: 'allgemeinerAnmerkungstext',
    label: 'Inobhutnahme Verlegungshindernis allgemeiner Anmerkungstext',
  },
  { key: 'awumZuweisungsdatum', label: 'Zuweisungsdatum', type: 'date' },
  { key: 'awumAsylbLGLeistungsanspruch', label: 'AsylbLG Leistungsanspruch' },
  {
    key: 'awumAsylbLGLeistungsanspruchSeit',
    label: 'AsylbLG Leistungsanspruch seit',
    type: 'date',
  },
  { key: 'awumOrdnungsmerkmal', label: 'OM (EWO)' },
  { key: 'ethnicity', label: 'Volkszugehörigkeit' },
  { key: 'leaBamfId', label: 'BAMF ID' },
  { key: 'leaGnaCheckbox', label: 'GNA' },
  { key: 'leaGnaText', label: 'GNA Text' },
  { key: 'leaSchuleCheckbox', label: 'Besucht Schule' },
  { key: 'leaSchuleText', label: 'Schule Text' },
  { key: 'leaSpecialNeedsCheckbox', label: 'Besondere Bedürfnisse' },
  { key: 'leaSpecialNeedsText', label: 'Besondere Bedürfnisse Text' },
  { key: 'quarantine', label: 'Quarantäne' },
  { key: 'leaRamadan', label: 'Ramadan' },
  { key: 'leaBarcodeScanAlarmText', label: 'Ausweisscan Alarmtext' },
  { key: 'migVisId', label: 'MigVIS ID' },
  { key: 'leaInactive', label: 'Bewohner inaktiv' },
  { key: 'leaInactiveReason', label: 'Bewohner inaktiv Grund' },
  { key: 'kommFuBelegungshinweise', label: 'kommFu Belegungshinweise' },
  { key: 'azrNr', label: 'AZR-Nummer' },
  { key: 'hasSchonkost', label: 'Schonkost' },
  { key: 'foodMustBeBroughtToRoom', label: 'Berechtigt für Essen auf Zimmer' },
  { key: 'lunchPackage', label: 'Berechtigung für Lunchpaket' },
  { key: 'isChild', label: 'Kind', type: 'boolean' },
  { key: 'isAdult', label: 'Erwachsener', type: 'boolean' },
  { key: 'kriegsfluchtUkr', label: 'Kriegsflucht-UKR' },
  { key: 'personalCharacteristicsIsSchwangerschaft', label: 'Schwangere Person' },
  { key: 'personalCharacteristicsIsEinzelzimmer', label: 'Einzelzimmerberechtigung' },
  { key: 'personalCharacteristicsIsRollstuhl', label: 'Rollstuhlfahrer' },
  {
    key: 'personalCharacteristicsIsBarrierefreiheit',
    label: 'Eingeschränkte Barrierefreiheit benötigt',
  },
]

for (const field of residentFields) {
  if (!field.enum) {
    continue
  }
  field.reverseEnum = {}
  for (const key of Object.keys(field.enum)) {
    field.reverseEnum[field.enum[key]] = key
  }
}
