import * as React from 'react'
import styles from './styles.module.scss'
import { IFullOrder } from 'contracts/inventory/interfaces/IFullOrder'
import { toLocaleNumber } from 'contracts/general/helpers/toLocaleNumber'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'

interface Props {
  order: IFullOrder
  instanceId: string
  readOnly?: boolean
}

export const OrderSheet: React.FC<Props> = ({ order, instanceId, readOnly }) => {
  React.useEffect(setReadyFlagWhenRenderingComplete, [])

  return (
    <div
      className={styles.page}
      style={
        readOnly ? { paddingTop: 0, paddingBottom: 0, minHeight: '1060px' } : undefined
      }
    >
      <div className='border border-black p-4'>
        <div className='flex text-lg'>
          <div className='flex-auto'>
            {order.type === 'order' ? 'Bestellschein' : 'Inventur'} #{order.orderNumber}
          </div>
          <div className='flex-content font-bold'>{order.number}</div>
        </div>
        <div className='truncate'>{order.compound.label}</div>
      </div>

      {order.notes && (
        <div className='border border-black p-4 mt-6'>
          <div>Bemerkung:</div>
          <div>{order.notes}</div>
        </div>
      )}

      <table className='table-fixed border-collapse border border-black text-left mt-6 overflow-hidden w-full'>
        <thead>
          <tr className={styles.tableRow}>
            <th className='p-2 border border-black w-[200px]'>Artikel</th>
            <th className='p-2 border border-black'>Notiz</th>
            <th className='p-2 border border-black text-right w-[100px]'>
              {order.type === 'inventory' ? 'Anzahl \n neu' : 'Anzahl'}
            </th>
            {order.type === 'inventory' && (
              <th className='p-2 border border-black text-right w-[100px]'>
                Anzahl bisher
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {order.items
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((item) => (
              <tr className={styles.tableRow} key={item.inventoryItemId}>
                <td className='p-2 border border-black break-words'>{item.label}</td>
                <td className='p-2 border border-black overflow-hidden text-ellipsis'>
                  {item.notes}
                </td>
                <td className='p-2 border border-black truncate text-right'>{`${toLocaleNumber(
                  item.count,
                )} ${item.unit}`}</td>
                {order.type === 'inventory' && (
                  <td
                    className={`p-2 border border-black truncate text-right ${
                      item.oldCount !== item.count ? 'text-red-500' : ''
                    }`}
                  >
                    {item.oldCount ? `${toLocaleNumber(item.oldCount)} ${item.unit}` : ''}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <div className='text-lg border border-black mt-6 flex'>
        <div className='flex-[0_0_160px] px-4 pt-1 pb-2 border-r border-black'>
          <span className='text-sm'>Datum</span>
          <br />
          {dayjs(order.createdAt).format('DD.MM.YYYY')}
        </div>
        <div className='flex-auto px-4 pt-1'>
          <span className='text-sm'>Unterschrift</span>
          <br />
        </div>
      </div>
    </div>
  )
}
