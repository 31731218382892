import { hermes } from '@byll/hermes'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { InputInventoryItem } from 'components/Form/components/InputInventoryItem'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { IFullOrder } from 'contracts/inventory/interfaces/IFullOrder'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { IOrderItem } from 'contracts/inventory/interfaces/IOrderItem'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { InventoryOrderItem } from './InventoryOrderItem'
import { SortConfirmDialog } from './SortConfirmDialog'

interface Props {
  navigate: (url: string) => void
  order: IFullOrder
  unsetEdit?: () => void
}

@observer
export class InventoryOrderForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly addModel = new Model<{ inventoryItemId: string | null }>({
    inventoryItemId: null,
  })
  private readonly model: Model<IFullOrder>
  @observable private saving = false

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.order)
    makeObservable(this)
  }

  private mapOrderItem = (item: IOrderItem) => (
    <InventoryOrderItem
      key={item.inventoryItemId}
      item={item}
      onDelete={this.onDelete}
      order={this.props.order}
    />
  )

  @action
  private onAdd = (item: IInventoryItem | null) => {
    if (!item) {
      return
    }
    this.addModel.values.inventoryItemId = null
    // Check if already on the list
    for (const row of this.props.order.items) {
      if (row.inventoryItemId === item.id) {
        void box.alert(
          'Bestellschein',
          'Dieses Produkt ist bereits auf der Liste. Bitte erhöhen Sie die Anzahl, anstatt das Produkt ein weiteres Mal hinzuzufügen.',
        )
        return
      }
    }
    // Add to list
    this.props.order.items.push({
      id: '',
      orderId: this.props.order.id,
      inventoryItemId: item.id,
      imageId: item.imageId,
      label: item.label,
      oldCount: null,
      count: null as any,
      unit: item.unit,
      packageSize: item.packageSize || '1',
      notes: '',
      deletedAt: null,
    })
  }

  @action
  private onDelete = (item: IOrderItem) => {
    for (let i = this.props.order.items.length - 1; i >= 0; i--) {
      if (this.props.order.items[i].inventoryItemId === item.inventoryItemId) {
        this.props.order.items.splice(i, 1)
      }
    }
  }

  private onSave = async () => {
    if (this.props.order.items.length === 0) {
      void box.alert(
        this.props.order.type === 'order' ? 'Bestellschein' : 'Inventur',
        'Bitte fügen Sie mindestens ein Produkt hinzu.',
      )
      return
    }
    if (this.props.order.type === 'order' && !this.props.order.number.trim()) {
      void box.alert('Bestellschein', 'Bitte tragen Sie eine Bedarfsnummer ein.')
      return
    }
    if (this.props.order.items.filter((i) => !i.count).length > 0) {
      void box.alert(
        'Bestellschein',
        this.props.order.type === 'order'
          ? 'Bitte geben Sie für jedes Produkt eine Bestellmenge an.'
          : 'Bitte geben Sie für jedes Produkt eine Menge an.',
      )
      return
    }
    if (
      this.props.order.type === 'inventory' &&
      this.props.order.items.filter((i) => i.count !== i.oldCount && !i.notes.trim())
        .length > 0
    ) {
      void box.alert(
        'Inventur',
        'Bitte geben Sie für jeden Gegenstand, dessen Menge sich geändert hat, eine Begründung im Kommentarfeld an.',
      )
      return
    }
    if (!this.checkIfItemsAreSorted() && !(await this.sortItems())) {
      return
    }
    runInAction(() => {
      this.saving = true
    })
    const items = this.props.order.items.map((o) => ({
      inventoryItemId: o.inventoryItemId,
      oldCount: null,
      count: o.count,
      packageSize: o.packageSize,
      notes: o.notes,
    }))
    try {
      if (this.props.order.id) {
        await hermes.update(
          `/api/${this.context.instance.id}/inventory/orders/${this.props.order.id}`,
          {
            number: this.props.order.number,
            notes: this.props.order.notes,
            items,
          },
        )
        this.props.unsetEdit?.()
      } else {
        const response = await hermes.create(
          `/api/${this.context.instance.id}/inventory/orders`,
          {
            type: this.props.order.type,
            number: this.props.order.number,
            compoundId: this.props.order.compoundId,
            notes: this.props.order.notes,
            items,
          },
        )
        this.props.navigate(
          this.props.order.type === 'order'
            ? `/inventory/orders/${response.id}`
            : `/inventory/corrections/${response.id}`,
        )
      }
    } catch (e) {
      void box.alert('Bestellschein', 'Beim Speichern ist ein Fehler aufgetreten.')
    }
    runInAction(() => (this.saving = false))
  }

  private checkIfItemsAreSorted = (): boolean => {
    const items = this.props.order.items
    for (let i = 1; i < items.length; i++) {
      if (items[i].label.localeCompare(items[i - 1].label) === -1) {
        return false
      }
    }
    return true
  }

  private sortItems = async (): Promise<boolean> => {
    const promise = box.custom(
      <SortConfirmDialog onClose={(response: boolean) => promise.close(response)} />,
    )
    return promise
  }

  private onCancel = () => {
    if (this.props.order.id) {
      this.props.unsetEdit?.()
    } else {
      this.props.navigate('/inventory/orders')
    }
  }

  render() {
    return (
      <>
        <div className='flex flex-col relative'>
          <div className='flex'>
            {this.props.order.type === 'order' && (
              <InputText
                className='flex-[0_0_120px] mr-4'
                model={this.model}
                name='number'
                label='Bedarfsnummer'
              />
            )}
            <InputText
              className='flex-auto'
              model={this.model}
              name='notes'
              label='Bemerkungen'
            />
          </div>

          <div className='my-4 overflow-x-auto'>
            <div className='py-2 align-middle inline-block min-w-full'>
              <div className='overflow-hidden border border-gray-300 rounded-lg shadow-sm'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Gegenstand
                      </th>
                      <th
                        scope='col'
                        className='max-w-20 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                      >
                        Bemerkung
                      </th>
                      <th
                        scope='col'
                        className='max-w-20 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                      >
                        Anzahl
                      </th>
                      {this.props.order.type === 'inventory' && (
                        <th
                          scope='col'
                          className='max-w-20 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                        >
                          &nbsp;
                        </th>
                      )}
                      {this.context.permissions.inventory_acceptOrders &&
                        this.props.order.type === 'order' && (
                          <th
                            scope='col'
                            className='max-w-20 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                          >
                            Packung
                          </th>
                        )}
                      {this.context.permissions.inventory_acceptOrders &&
                        this.props.order.type === 'order' && (
                          <th
                            scope='col'
                            className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Packungen
                          </th>
                        )}
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {this.props.order.items.map(this.mapOrderItem)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div>
            <InputInventoryItem
              model={this.addModel}
              isOrderable={this.props.order.type === 'order'}
              name='inventoryItemId'
              label='Neuer Eintrag'
              placeholder='Gegenstand hinzufügen'
              onChoose={this.onAdd}
              compoundId={this.props.order.compoundId}
              key={this.props.order.compoundId}
            />
          </div>
          {this.saving && <DialogOverlaySpinner className='-m-4' opaque sticky />}
        </div>
        <div
          className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200 flex -mx-5 mt-6 -mb-5'
          style={{ zIndex: 1 }}
        >
          <div className='flex-auto'>&nbsp;</div>
          <div className='flex-content'>
            <Button color='secondary' onClick={this.onCancel} disabled={this.saving}>
              Abbrechen
            </Button>
            <Button
              color='primary'
              className='ml-2'
              onClick={this.onSave}
              disabled={this.saving}
            >
              {!this.props.order.id
                ? this.props.order.type === 'order'
                  ? 'Bestellung anlegen'
                  : 'Inventur abschließen'
                : 'Speichern'}
            </Button>
          </div>
        </div>
      </>
    )
  }
}
