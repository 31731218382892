import { hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { Tooltip } from 'components/Tooltip'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import styles from '../styles.module.scss'

interface Props {
  model: Model<any>
  name: string
  onSelect: () => void
}

export const DashboardResidentStatsCompounds: React.FC<Props> = observer(
  ({ model, name, onSelect }) => {
    const context = React.useContext(AppContext)
    const compounds =
      model.values[name]
        ?.split(',')
        .map((compoundId) =>
          hermes.getFromStore(
            `/api/${context.instance.id}/accommodations/compounds/${compoundId}`,
          ),
        )
        .filter(Boolean) ?? []
    return (
      <div className={`text-gray-500 mb-4 mt-3 ${styles.truncateLineClamp}`}>
        <span className='mr-1 has-tooltip cursor-pointer' onClick={onSelect}>
          <span>
            <i className='fas fa-plus-circle' />
          </span>
          <Tooltip position='right'>Gelände auswählen</Tooltip>
        </span>
        {compounds.map((compound) => compound.label).join(', ') || 'Gelände auswählen'}
      </div>
    )
  },
)
