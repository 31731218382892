import { Resource, hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { RoundIcon } from 'components/RoundIcon'
import { Tooltip } from 'components/Tooltip'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { ITodoMessage } from 'contracts/todos/interfaces/ITodoMessage'
import { IUser } from 'contracts/users/interfaces/IUser'
import dayjs from 'dayjs'
import { download } from 'helpers/download'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { getFileExtensionIcon } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/helpers/getFileExtensionIcon'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {
  message: ITodoMessage
}

@observer
export class TicketMessage extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly createdByUser: Resource<IUser>
  @observable private document: IDocumentMetadata | null = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.createdByUser = new Resource(
      `/api/${context.instance.id}/users/${props.message.createdBy}`,
    )
  }

  componentDidMount(): void {
    this.loadDocument()
    this.disposers.push(this.createdByUser.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadDocument = async () => {
    if (!this.props.message.documentId) {
      return
    }
    const document = await hermes.getOnceNew<IDocumentMetadata>(
      `/api/${this.context.instance.id}/documents/metadata/${this.props.message.documentId}`,
    )
    runInAction(() => (this.document = document))
  }

  private openDocumentInNewTab = (id: string) => {
    window.open(`/documents/${id}`, '_blank')
  }

  private onDownload = () => {
    download(
      `/api/${this.context.instance.id}/documents/files/${this.props.message.documentId}`,
    )
  }

  render() {
    if (!this.createdByUser.data) {
      return null
    }

    return (
      <div className='flex'>
        <img
          className='flex-content relative h-6 w-6 inline-block rounded-full ring-2 ring-white'
          src={getResidentImageSrc(
            this.context.instance.id,
            this.createdByUser.data.imageId,
            this.createdByUser.data.sex,
            'portrait',
          )}
          alt=''
        />
        <div>
          <div
            className={`text-xs ${
              this.props.message.type === 'system-message'
                ? 'text-gray-600'
                : 'text-indigo-600'
            } ml-4 pt-1`}
          >
            {this.props.message.label}
            <span className='ml-2 text-xs text-gray-400'>
              {dayjs(this.props.message.createdAt).format('DD.MM.YYYY HH:mm')}
            </span>
          </div>
          {this.document && (
            <div className='ml-4 mt-2'>
              <div className='flex items-center'>
                <img
                  className='h-5'
                  src={getFileExtensionIcon(this.document.name)}
                  alt='Datei'
                />
                <span
                  onClick={this.onDownload}
                  className='ml-2 text-xs cursor-pointer has-tooltip'
                >
                  {this.document.name}
                  <Tooltip position='right'>Herunterladen</Tooltip>
                </span>
                <RoundIcon
                  classNameContainer='ml-2'
                  tooltip={{ text: 'In neuem Tab öffnen', position: 'right' }}
                  icon='fas fa-external-link-alt'
                  color='white'
                  onClick={(event) => {
                    event.stopPropagation()
                    this.openDocumentInNewTab(this.document!.id)
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
