import * as React from 'react'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { HealthTable } from './components/HealthTable'
import { Collection } from '@byll/hermes'
import { IHealth } from 'contracts/residents/interfaces/IHealth'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Spinner } from 'components/Spinner'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { observer } from 'mobx-react'

export interface Table {
  type:
    | 'Medikament'
    | 'Impfung'
    | 'Krankenschein'
    | 'Zahnschein'
    | 'Untersuchung'
    | 'Corona'
    | 'Quarantäne'
  columns: { label: string; width?: number }[]
}

const healthTables: Table[] = [
  {
    type: 'Medikament',
    columns: [
      { label: 'Medikamente' },
      { label: 'Einnahme' },
      { label: 'Von', width: 120 },
      { label: 'Bis', width: 120 },
    ],
  },
  {
    type: 'Impfung',
    columns: [{ label: 'Impfungen' }, { label: 'Datum', width: 120 }],
  },
  {
    type: 'Krankenschein',
    columns: [
      { label: 'Krankenscheine' },
      { label: 'Ausgabedatum', width: 120 },
      { label: 'Quartal', width: 120 },
    ],
  },
  {
    type: 'Zahnschein',
    columns: [
      { label: 'Zahnscheine' },
      { label: 'Ausgabedatum', width: 120 },
      { label: 'Quartal', width: 120 },
    ],
  },
  {
    type: 'Untersuchung',
    columns: [{ label: 'Ärztliche Untersuchung' }, { label: 'Datum', width: 120 }],
  },
  {
    type: 'Corona',
    columns: [
      { label: 'Corona' },
      { label: 'Von', width: 120 },
      { label: 'Bis', width: 120 },
    ],
  },
  {
    type: 'Quarantäne',
    columns: [
      { label: 'Quarantäne' },
      { label: 'Von', width: 120 },
      { label: 'Bis', width: 120 },
    ],
  },
]

interface Props {
  resident: IResident
}

@observer
export class HealthTab extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly health: Collection<IHealth>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.health = new Collection(
      `/api/${context.instance.id}/residents/${this.props.resident.id}/health`,
    )
  }

  componentDidMount() {
    this.health.init()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    this.health.dispose()
  }

  render() {
    if (this.health.error && this.health.error.id !== RequestPendingError.id) {
      return (
        <div className='bg-gray-100 min-h-full flex overflow-hidden'>
          <LoadingError
            title='Gesundheitsdaten konnten nicht geladen werden'
            subtitle='Bitte wenden Sie sich an einen Administrator'
          />
        </div>
      )
    }

    if (!this.health.resources) {
      return (
        <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
          <Spinner delay />
        </div>
      )
    }

    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div
          className='hidden lg:block pr-12 pt-4 text-right'
          style={{ flex: '0 0 200px' }}
        >
          <span className='text-gray-900 text-lg'>Gesundheit</span>
          <br />
          <span className='text-sm text-gray-400'>Medikamente, Impfungen, etc.</span>
        </div>
        <div className='flex-auto pt-4 relative min-w-0 flex flex-col gap-4 lg:pr-4'>
          <div className='-mx-8'>
            {healthTables.map((t) => (
              <HealthTable
                key={t.type}
                table={t}
                health={this.health}
                residentId={this.props.resident.id}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}
