import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Wait } from '../../components/Wait'
import { getStundenzettelForEmployee } from './helpers/getStundenzettelForEmployee'
import axios from 'axios'
import { IStundenzettelData } from 'contracts/workplan/interfaces/IStundenzettelData'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'

interface Props {
  instanceId: string
  month: string
  employeeIds: string[]
}

@observer
export class Stundenzettel extends React.Component<Props, {}> {
  @observable.ref private error: string | null = null
  @observable.ref private employees: IStundenzettelData[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    try {
      const response = await axios.get<{ resources: IStundenzettelData[] }>(
        `/api/${this.props.instanceId}/pdf/data?type=stundenzettel&month=${
          this.props.month
        }&employeeIds=${encodeURIComponent(this.props.employeeIds.join(','))}`,
      )
      runInAction(() => (this.employees = response.data.resources))
      setReadyFlagWhenRenderingComplete()
    } catch (e) {
      runInAction(() => (this.error = e instanceof Error ? e.message : JSON.stringify(e)))
    }
  }

  render() {
    if (this.error) {
      return (
        <div style={{ color: 'red' }}>
          {this.error}
          <Wait />
        </div>
      )
    }
    if (!this.employees || this.employees.length === 0) {
      return <Wait />
    }

    const employees: JSX.Element[] = []
    for (let i = 0; i < this.employees.length; i++) {
      const likelyPageGuess = 2
      let pages = getStundenzettelForEmployee(
        this.employees[i],
        likelyPageGuess,
        this.props.instanceId,
      )
      if (pages.length !== likelyPageGuess) {
        pages = getStundenzettelForEmployee(
          this.employees[i],
          pages.length,
          this.props.instanceId,
        )
      }
      employees.push(<React.Fragment key={i}>{pages}</React.Fragment>)
    }
    return employees
  }
}
