import * as React from 'react'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { IUserDefaults } from 'contracts/users/interfaces/IUserDefaults'
import { AppContext } from 'services/connection/models/AppContext'
import { UnsyncedCollection } from '../../models/UnsyncedCollection'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { box } from 'services/box'
import { Tooltip } from 'components/Tooltip'
import { action, runInAction } from 'mobx'
import { AddToGroupDialog } from './components/AddToGroupDialog'
import { FindRecordHelpDialog } from './components/FindRecordHelpDialog'
import { SelectColumns } from './components/SelectColumns'
import { SearchHistory } from './components/SearchHistory'
import { SearchResultDownloadButton } from './components/SearchResultDownloadButton'

interface Props {
  model: Model<IResidentSearchResultsFilter>
  config: IUserDefaults
  residents: UnsyncedCollection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  togglePinModel: { editModel: boolean }
}

@observer
export class FindRecordSearchBar extends React.Component<Props, {}> {
  static contextType = AppContext

  private addToGroup = () => {
    const promise = box.custom(
      <AddToGroupDialog
        onClose={() => promise.close()}
        filter={this.props.model.values}
      />,
      { context: this.context },
    )
  }

  private showHelpDialog = () => {
    const promise = box.custom(
      <FindRecordHelpDialog onClose={() => promise.close()} />,
      {},
    )
  }

  @action
  private togglePin = () => {
    runInAction(
      () => (this.props.togglePinModel.editModel = !this.props.togglePinModel.editModel),
    )
  }

  render() {
    return (
      <div className='bg-gray-100 shadow-md flex-grow-0 border-b border-gray-300 p-4 md:p-6 grid grid-cols-[1fr_auto] gap-4 md:gap-6'>
        <div className='relative'>
          <InputText
            model={this.props.model}
            name='searchString'
            placeholder='Suche'
            style={{ paddingLeft: 32 }}
          />
          <i
            className='fas fa-search absolute text-gray-500'
            style={{ top: 11, left: 11 }}
          />
        </div>
        <div className='inline-flex' role='group'>
          {this.props.config && (
            <>
              <Button
                onClick={this.showHelpDialog}
                type='button'
                className='has-tooltip border-r border-transparent hover:border-indigo-700'
                style={{
                  width: 39,
                  borderRadius: '6px 0 0 6px',
                  padding: '9px 0',
                }}
              >
                <Tooltip position='bottom'>Hilfe</Tooltip>
                <i className='fas fa-question' />
              </Button>
              <Button
                onClick={this.togglePin}
                type='button'
                className='has-tooltip border-r border-transparent hover:border-indigo-700'
                style={{
                  width: 39,
                  borderRadius:
                    !this.context.permissions.groups_participants &&
                    !this.context.permissions.resident_downloadSearchResults &&
                    this.context.permissions.menu_resident_search === 1
                      ? '0 6px 6px 0'
                      : '0',
                  padding: '9px 0',
                }}
                color={this.props.togglePinModel.editModel ? 'success' : 'primary'}
              >
                <Tooltip position='bottom'>Filter pinnen</Tooltip>
                <i className='fas fa-thumbtack' />
              </Button>
              {this.context.permissions.groups_participants && (
                <Button
                  onClick={this.addToGroup}
                  type='button'
                  className='has-tooltip border-r border-transparent hover:border-indigo-700'
                  style={{
                    width: 39,
                    borderRadius:
                      this.context.permissions.resident_downloadSearchResults ||
                      this.context.permissions.menu_resident_search !== 1
                        ? '0'
                        : '0 6px 6px 0',
                    padding: '9px 0',
                  }}
                >
                  <Tooltip position='bottom'>Zu Gruppe hinzufügen</Tooltip>
                  <i className='fas fa-share' />
                </Button>
              )}
              {this.context.permissions.resident_downloadSearchResults && (
                <SearchResultDownloadButton
                  model={this.props.model}
                  residents={this.props.residents}
                />
              )}
              <SearchHistory />
              {this.context.permissions.menu_resident_search !== 1 && (
                <SelectColumns config={this.props.config} model={this.props.model} />
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}
