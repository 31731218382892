import { IPermissions } from '../../users/interfaces/IPermissions'

export function getRoomLockReasons(
  _instanceId: string,
  permissions: IPermissions,
): string[] {
  if (permissions.host_awum) {
    return [
      'Nicht passgenaue Belegung',
      'Bauliche Maßnahmen',
      'Schädlingsbefall',
      'Reinigung',
      'Sonstiges',
    ]
  } else {
    return [
      'Nicht passgenaue Belegung',
      'Bauliche Maßnahmen LFG',
      'Bauliche Maßnahmen extern',
      'Fehlende Erstausstattung',
      'Quarantäne',
      'Schädlingsbefall',
      'Reinigung',
    ]
  }
}
