import { IStundenzettelData } from 'contracts/workplan/interfaces/IStundenzettelData'
import * as React from 'react'
import styles from '../styles.module.scss'

interface Props {
  data: IStundenzettelData
}

export const StundenzettelTotal: React.FC<Props> = (props) => {
  let total = 0
  for (const day of props.data.days) {
    for (const real of day.real) {
      if (real.shiftId < 0 && real.shiftId !== -4 && real.shiftId !== -7) {
        continue
      }
      total += real.workHoursTotal + real.onCallHoursTotal
    }
  }

  return (
    <div className={styles.row}>
      <div className={styles.colDate} />
      <div className={styles.colLabel} />
      <div className={'font-bold ' + styles.colBreak}>Summe</div>
      <div className={'font-bold ' + styles.colHours}>{`${total
        .toFixed(2)
        .replace('.', ',')} h`}</div>
    </div>
  )
}
