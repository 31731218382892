import { storage } from 'services/storage'
import { IUser } from 'contracts/users/interfaces/IUser'
import { IInactiveListFilterModel } from 'contracts/inactiveList/interfaces/IInactiveListFilterModel'
import { InactiveListFilterValidator } from 'contracts/inactiveList/validators/InactiveListFilterValidator'

export function getInactiveListStorageModel(user: IUser): IInactiveListFilterModel {
  const settings: IInactiveListFilterModel = storage.get(`inactiveList.${user.id}`)
  const result = InactiveListFilterValidator.safeParse(settings)

  let model: IInactiveListFilterModel
  if (settings && result.success) {
    model = {
      compoundId: settings.compoundId,
      residentCheckedIn: settings.residentCheckedIn,
      residentCheckedOut: settings.residentCheckedOut,
      residentInactive: settings.residentInactive,
      residentWasScanned: settings.residentWasScanned,
      residentWasNotScanned: settings.residentWasNotScanned,
      interval: settings.interval,
      sort: 'residents."lastName" ASC',
    }
  } else {
    model = {
      compoundId: null,
      residentCheckedIn: 'yes',
      residentCheckedOut: 'yes',
      residentInactive: 'yes',
      residentWasScanned: 'yes',
      residentWasNotScanned: 'yes',
      interval: '72h',
      sort: 'residents."lastName" ASC',
    }
  }
  return model
}
