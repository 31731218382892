import { IPermissions } from './../../../contracts/users/interfaces/IPermissions'
import { IUser } from './../../../contracts/users/interfaces/IUser'
import { Collection } from '@byll/hermes'
import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { when } from 'mobx'
import { storage } from 'services/storage'
import { isStammCompound } from 'helpers/isStamm'
import { Model } from 'components/Form/Model'

export function preselectLastUsedScanningStation(
  model: Model<{ scanningStationId: string | null }>,
  stations: Collection<IScanningStation>,
  user: IUser,
  permissions: IPermissions,
) {
  return when(
    () => !!stations.resources,
    () => {
      const lastScanningStationId = storage.get(`scanning-station.canteen.${user.id}`)
      for (const station of stations.resources ?? []) {
        if (station.id !== lastScanningStationId) {
          continue
        }
        if (!station.data) {
          continue
        }
        if (
          permissions.resident_barcodeVisitTrackings === 1 &&
          !isStammCompound(station.data.compoundId)
        ) {
          continue
        }
        model.values.scanningStationId = lastScanningStationId
        return
      }
    },
  )
}
