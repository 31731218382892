import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { useCollection } from 'helpers/useCollection'
import { IMigration } from 'contracts/migrations/interfaces/IMigration'
import { Route, Routes } from 'react-router'
import { MigrationDetail } from './components/MigrationDetail'
import { MigrationList } from './components/MigrationList'

interface Props {}

export const Migrations: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  const migrations = useCollection<IMigration>(
    `/api/${context.instance.id}/imports`,
    { observeQuery: true, readOnly: true },
    { page: '0,100', status: '' },
  )

  if (!context.permissions.menu_migrations) {
    return <Forbidden />
  }

  return (
    <Routes>
      <Route path=':id' element={<MigrationDetail />} />
      <Route path='*' element={<MigrationList migrations={migrations} />} />
    </Routes>
  )
})
