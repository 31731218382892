import { Dialog } from '@headlessui/react'
import { CheckIcon, ExclamationIcon, UserIcon } from '@heroicons/react/outline'
import axios from 'axios'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { isPhoneNumber } from 'contracts/general/helpers/isPhoneNumber'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { z } from 'zod'

const LoginValidator = z.object({
  login: z.union([z.string().email(), z.string().refine(isPhoneNumber)]),
})
const StringValidator = z.object({
  login: z
    .string()
    .min(1)
    .refine((val) => val.replace(/ /g, '') === val),
})

const captions = {
  loading: 'Neues Passwort anfordern',
  error: 'Versand fehlgeschlagen',
  success: 'Passwort versendet',
}

const texts = {
  loading:
    'Geben Sie Ihren Benutzernamen ein, um in den nächsten Minuten ein neues Passwort per E-Mail oder SMS zu erhalten.',
  error:
    'Beim Anfordern eines neuen Passworts ist leider ein Problem aufgetreten. Bitte wenden Sie sich an einen Administrator.',
  success:
    'Falls eine E-Mail Adresse oder Handynummer für Sie hinterlegt ist, wird nun ein neues Passwort versendet.',
}

interface Props {
  onClose: (login?: string) => void
  login: string
  instanceId: string
  hasLdapLogin: boolean
}

@observer
export class ForgotPassword extends React.Component<Props, {}> {
  private readonly model: Model<{ login: string }>
  @observable private view: 'loading' | 'error' | 'success' | null = null

  constructor(props: Props) {
    super(props)
    this.model = new Model(
      { login: props.login },
      props.hasLdapLogin ? StringValidator : LoginValidator,
    )
    makeObservable(this)
  }

  private onSubmit = async (event) => {
    event.preventDefault()
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    try {
      runInAction(() => (this.view = 'loading'))
      await axios.post(`/api/${this.props.instanceId}/users/credentials`, {
        login: this.model.values.login.trim(),
      })
      runInAction(() => (this.view = 'success'))
    } catch (_e) {
      runInAction(() => (this.view = 'error'))
    }
  }

  render() {
    return (
      <form onSubmit={this.onSubmit} id={this.model.id}>
        <div>
          {(this.view === null || this.view === 'loading') && (
            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100'>
              <UserIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />
            </div>
          )}
          {this.view === 'error' && (
            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100'>
              <ExclamationIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
            </div>
          )}
          {this.view === 'success' && (
            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
              <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
            </div>
          )}
          <div className='mt-3 text-center sm:mt-5'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {captions[this.view || 'loading']}
            </Dialog.Title>
            <div className='mt-4'>
              <p className='text-md text-gray-500'>{texts[this.view || 'loading']}</p>
            </div>
            {(this.view === null || this.view === 'loading') && (
              <InputText
                className='mt-6'
                model={this.model}
                name='login'
                label={this.props.hasLdapLogin ? 'Benutzername' : 'E-Mail oder Handy'}
                disabled={this.view === 'loading'}
                placeholder={this.props.hasLdapLogin ? 'Vorname.Nachname' : undefined}
              />
            )}
          </div>
        </div>

        <div
          className={`mt-5 sm:mt-6 sm:grid sm:gap-3 sm:grid-flow-row-dense ${
            this.view === null || this.view === 'loading'
              ? 'sm:grid-cols-2'
              : 'sm:grid-cols-1'
          }`}
        >
          {(this.view === null || this.view === 'loading') && (
            <button
              type='submit'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm'
              disabled={this.view === 'loading'}
            >
              {this.view === 'loading' && (
                <span className='mr-1'>
                  <i className='fas fa-spinner fa-spin' />
                </span>
              )}
              Passwort anfordern
            </button>
          )}
          <button
            type='button'
            onClick={() =>
              this.props.onClose(
                this.view === 'success' ? this.model.values.login : undefined,
              )
            }
            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
          >
            {this.view === null || this.view === 'loading' ? 'Abbrechen' : 'Schließen'}
          </button>
        </div>
      </form>
    )
  }
}
