import { formatDecimal } from './formatDecimal'
import { Decimal } from 'decimal.js-light'

export function isUpdateRequired(
  postfix: string,
  noThousandsSeparators: boolean,
  formattedValue: string,
  modelValue: string | null,
  value: string | null,
): boolean {
  const newFormattedValue = formatDecimal('-', postfix, noThousandsSeparators)

  // Do not override a minus
  if (modelValue === null && formattedValue === newFormattedValue) {
    return false
  }

  // Do not overrinde when the received value is identical to the formattedValue (regardless of decimal places)
  // Only exception: postfix changed
  const modelValueIsNumericallyCorrect: boolean =
    modelValue === value ||
    (value !== null && modelValue !== null && new Decimal(value).equals(modelValue))
  const oldPostfix: string = formattedValue.split(' ')[1]
  const newPostfix: string = newFormattedValue.split(' ')[1]

  if (oldPostfix === newPostfix && modelValueIsNumericallyCorrect) {
    return false
  }

  return true
}
