import { Collection, Resource } from '@byll/hermes'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { getResidentImageSrc } from '../../../../helpers/getResidentImageSrc'
import { Age } from 'components/Age'
import { dayjs } from 'helpers/dayjs'
import { Link } from 'react-router-dom'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { bookingTypes } from 'modules/Residents/helpers/bookingTypes'
import { SearchIcon } from '@heroicons/react/outline'
import { LoadMoreButton } from 'components/LoadMoreButton'
import { Spinner } from 'components/Spinner'

interface Props {
  residents: Collection<IResidentSearchResult, any, any>
}

@observer
export class ResidentsList extends React.Component<Props, {}> {
  static contextType = AppContext

  private mapResident = (res: Resource<IResidentSearchResult>) => {
    if (!res.data) {
      return null
    }
    const resident = res.data
    const bookingType = bookingTypes[resident.data.accommodation?.type || ''] || {
      label: '',
      color: '',
      icon: SearchIcon,
    }

    return (
      <tr key={resident.id}>
        <td className='px-6 py-4 whitespace-nowrap'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <img
                className='h-10 w-10 rounded-full'
                src={getResidentImageSrc(
                  this.context.instance.id,
                  resident.imageId,
                  resident.sex,
                  'thumbnail',
                )}
                alt=''
              />
            </div>
            <div className='ml-4'>
              <div className='text-sm font-medium'>
                <Link
                  to={`/residents/${toJbpId(+resident.id).toLowerCase()}/overview`}
                  className='text-gray-900 hover:text-blue-500 underline'
                >
                  {`${resident.lastName.toUpperCase()}, ${resident.firstName}`}
                </Link>
              </div>
              <div className='text-sm text-gray-500'>
                <Age sex={resident.sex} dateOfBirth={resident.dateOfBirth} />
              </div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>
          {resident.dateOfBirth ? dayjs(resident.dateOfBirth).format('DD.MM.YYYY') : ''}
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
          {resident.data.accommodation ? (
            <div className='flex'>
              <div className='flex-content mr-1'>
                <bookingType.icon
                  className={`h-4 w-4 ${bookingType.color} rounded-full`}
                  style={{ padding: 1 }}
                  aria-hidden='true'
                />
              </div>
              <div className='flex-auto overflow-hidden'>
                {resident.data.accommodation.label}
              </div>
            </div>
          ) : (
            <div>Nicht eingebucht</div>
          )}
        </td>
      </tr>
    )
  }

  render() {
    if (
      !this.props.residents.resources ||
      (this.props.residents.metadata?.dateBegin || null) !==
        this.props.residents.query.dateBegin
    ) {
      return <Spinner delay />
    }
    return (
      <div className='flex flex-col'>
        <div className='-mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Name
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Geburtsdatum
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Unterbringung
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {this.props.residents.resources.map(this.mapResident)}
                </tbody>
              </table>
            </div>
            <LoadMoreButton
              collection={this.props.residents}
              incrementBy={50}
              className='mt-4 mb-2 ml-6'
              pluralText={`Sie sehen alle ${
                (this.props.residents.resources?.length || 0) < 5
                  ? ''
                  : this.props.residents.resources?.length
              } Bewohner${
                this.props.residents.metadata.dateBegin
                  ? `, die Sie am ${dayjs(this.props.residents.metadata.dateBegin).format(
                      'DD.MM.YYYY',
                    )} in Inuv angelegt haben.`
                  : ', die Sie in Inuv angelegt haben.'
              }`}
              singularText={`Sie sehen alle Bewohner${
                this.props.residents.metadata.dateBegin
                  ? `, die Sie am ${dayjs(this.props.residents.metadata.dateBegin).format(
                      'DD.MM.YYYY',
                    )} in Inuv angelegt haben.`
                  : ', die Sie in Inuv angelegt haben.'
              }`}
            />
          </div>
        </div>
      </div>
    )
  }
}
