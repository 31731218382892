import { Model } from 'components/Form/Model'
import { reaction } from 'mobx'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'

export function resetBuildingWhenOtherCompoundIsSelected(
  model: Model<IOccupancyFilter>,
): Disposer {
  return reaction(
    () => model.values.compoundId,
    () => (model.values.buildingId = 'none'),
  )
}
