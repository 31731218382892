import { RoundIcon } from 'components/RoundIcon'
import { getRoomLockAt } from 'contracts/accommodations/helpers/getRoomLockAt'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import { getOccupancyColor } from 'modules/ResidentsOccupancy/helpers/getOccupancyColor'
import * as React from 'react'
import { EquipmentIcon } from '../EquipmentIcon'
import { RoomTileLockDialogToggle } from '../OccupancyRoomList/components/RoomTileLockDialogToggle'

interface Props {
  room: IRoom
  occupancy: IOccupancy
  dragMode: boolean
  at?: Dayjs
  allowUnassign?: boolean // Assigned to room plan
  onUnassign: (room: IRoom) => void
}

export const SidebarRoomTile: React.FC<Props> = observer(
  ({ at, room, occupancy, dragMode, allowUnassign, onUnassign }) => {
    const lock = getRoomLockAt(room.locks, at)

    return (
      <div
        className={dragMode ? 'cursor-move' : ''}
        data-template-movable={`room-${room.id}`}
      >
        <div
          className={`relative group text-sm p-3 text-white flex min-w-0 rounded-md ${
            dragMode ? 'bg-blue-500 pointer-events-none' : 'bg-gray-800'
          }`}
        >
          <div className='flex-content mr-2 truncate max-w-[100px]'>{room.label}</div>
          <div className={lock && !dragMode ? 'flex-content' : 'flex-auto'}>
            {!dragMode && <RoomTileLockDialogToggle room={room} at={at} lock={lock} />}
          </div>
          {lock && !dragMode && (
            <div className='text-red-500 truncate flex-[1_10_auto] ml-2 min-w-0'>
              {lock.reason
                .split(' ')
                .map((w) => w.substring(0, 1))
                .join('')
                .toUpperCase()}
            </div>
          )}
          <div className='flex-[0_1_auto] truncate'>
            {room.equipment.split(',').map((e) =>
              EquipmentIcon[e] ? (
                <span key={e} className='mx-1'>
                  {EquipmentIcon[e]}
                </span>
              ) : null,
            )}
            <span
              className={`${getOccupancyColor(
                +occupancy.occupied,
                +occupancy.capacity,
                lock !== null,
              )} inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white`}
            >{`${occupancy.occupied} / ${room.capacity}`}</span>
          </div>
          {!dragMode && allowUnassign && (
            <div className='hidden group-hover:block'>
              <RoundIcon
                color='danger'
                icon='fa fa-times'
                onClick={() => onUnassign(room)}
                tooltip={{ position: 'left', text: 'Zuordnung aufheben' }}
                style={{ position: 'absolute', top: 7, right: 7 }}
              />
            </div>
          )}
        </div>
      </div>
    )
  },
)
