import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { z } from 'zod'
import { isPastDate } from '../helpers/isPastDate'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { box } from 'services/box'
import dayjs from 'dayjs'
import { IPeriod } from 'contracts/report/interfaces/IPeriod'
import { InputPeriod } from 'components/Form/components/InputPeriod'
import { hermes } from '@byll/hermes'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { isStammCompound } from 'helpers/isStamm'

interface Props {}

@observer
export class MealReport extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{
    compoundIds: string
    begin: string
    end: string
    aggregate: IPeriod
  }>
  @observable private loading: boolean = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    const validator = z.object({
      compoundIds: z.string().min(1),
      begin: z.string(),
      end: z.string(),
    })
    const today = dayjs()
    this.model = new Model(
      {
        compoundIds: '',
        begin: today.startOf('month').format('YYYY-MM-DD'),
        end: today.format('YYYY-MM-DD'),
        aggregate: 'day',
      },
      validator,
    )
  }

  private downloadReport = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (!isPastDate(this.model.values.begin) || !isPastDate(this.model.values.end)) {
      box.alert(
        'Datum ungültig',
        'Start- und Enddatum dürfen nicht in der Zukunft liegen.',
      )
      return
    }
    if (this.model.values.begin > this.model.values.end) {
      box.alert('Datum ungültig', 'Das Enddatum darf nicht vor dem Startdatum liegen.')
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'meal-report',
      this.context.instance.id,
      {
        compoundIds: this.model.values.compoundIds.split(','),
        begin: this.model.values.begin,
        end: this.model.values.end,
        aggregate: this.model.values.aggregate,
      },
      'Essensausgabe.xlsx',
    )
    runInAction(() => (this.loading = false))
  }

  @action
  private selectAll = () => {
    const compounds = hermes.indexFromStore<ICompound>(
      `/api/${this.context.instance.id}/accommodations/compounds`,
    )
    this.model.values.compoundIds =
      compounds
        ?.map((c) => c.id)
        .filter((id) =>
          this.context.permissions.report_meals > 1 ? true : isStammCompound(id),
        )
        .join(',') || ''
  }

  @action
  private selectNone = () => {
    this.model.values.compoundIds = ''
  }

  render() {
    if (this.context.permissions.report_meals === 0) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Essensausgabe'
        subtitle='Wählen Sie ein Gelände aus, für das der Essensausgabeplan erstellt werden soll.'
      >
        <div className='max-w-xl'>
          <div className='text-gray-400 text-xs mb-4'>
            <span
              onClick={this.selectAll}
              className='pl-2 pr-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100'
              style={{ borderRadius: '4px 0 0 4px' }}
            >
              Alle auswählen
            </span>
            <span
              onClick={this.selectNone}
              className='pl-1 pr-2 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer'
              style={{ borderRadius: '0 4px 4px 0' }}
            >
              Alle abwählen
            </span>
          </div>

          <div
            id={this.model.id}
            className='grid grid-cols-1 sm:grid-cols-[110px_110px_1fr] gap-4'
          >
            <InputCompound
              label='Gelände'
              name='compoundIds'
              className='sm:col-span-3'
              multiple
              model={this.model}
              onlyStamm={this.context.permissions.report_meals === 1}
              at={
                this.model.values.end ? dayjs(this.model.values.end).toISOString() : null
              }
            />
            <InputDate label='von' name='begin' model={this.model} />
            <InputDate label='bis' name='end' model={this.model} />
            <InputPeriod model={this.model} name='aggregate' />
          </div>
          <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
            Download
          </Button>
        </div>
      </CardColumnCard>
    )
  }
}
