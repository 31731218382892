import { Collection } from '@byll/hermes'
import { Spinner } from 'components/Spinner'
import { IOrderSearchResult } from 'contracts/inventory/interfaces/IOrderSearchResult'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  compoundId: string
  orders: Collection<IOrderSearchResult, any, any>
  navigate: (url: string) => void
  selectedId: string | null
}

@observer
export class OrdersLog extends React.Component<Props, {}> {
  render() {
    if (!this.props.orders.resources) {
      return <Spinner />
    }
    const type: 'order' | 'inventory' =
      this.props.orders.query?.type === 'order' ? 'order' : 'inventory'
    return (
      <div>
        <div
          className={`truncate px-3 cursor-pointer rounded-md my-2 mx-4 hover:bg-slate-50 bg-gray-100 text-gray-600 py-[6px] ${
            this.props.selectedId === 'new'
              ? 'border-2 border-indigo-500'
              : 'border border-gray-200'
          }`}
          onClick={() =>
            this.props.navigate(
              type === 'order' ? `/inventory/orders/new` : `/inventory/corrections/new`,
            )
          }
        >
          <i className='fas fa-plus mr-2' />{' '}
          {type === 'order' ? 'Neue Bestellung' : 'Neue Inventur'}
        </div>

        {this.props.orders.resources.map((o, i) => {
          if (!o.data) {
            return null
          }
          return (
            <div
              key={i}
              className={`truncate px-3 cursor-pointer rounded-md my-2 mx-4 hover:bg-slate-50 bg-gray-100 text-gray-600 py-[6px] ${
                this.props.selectedId === o.id
                  ? 'border-2 border-indigo-500'
                  : 'border border-gray-200'
              }`}
              onClick={() =>
                this.props.navigate(
                  type === 'order' ? `orders/${o.data?.id}` : `corrections/${o.data?.id}`,
                )
              }
            >
              {type === 'order' && o.data.approvedAt && (
                <span>
                  <i className='fas fa-check-circle text-green-500 mr-1' />
                </span>
              )}
              {type === 'order' && o.data.rejectedAt && (
                <span>
                  <i className='fas fa-times-circle text-red-500 mr-1' />
                </span>
              )}
              {type === 'order' && !o.data.approvedAt && !o.data.rejectedAt && (
                <span>
                  <i className='fas fa-circle text-gray-300 mr-1' />
                </span>
              )}
              {`${type === 'order' ? 'Bestellung' : 'Inventur'} ${
                o.data.orderNumber
              } - ${dayjs(o.data.createdAt).format('DD.MM.YY')}`}
              {type === 'order' && o.data.deliveredAt && (
                <span>
                  <i className='fas fa-box-open text-indigo-500 ml-1' />
                </span>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}
