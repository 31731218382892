/**
 * Function to test if an object is a plain object, i.e. is constructed
 * by the built-in Object constructor and inherits directly from Object.prototype
 * or null. Some built-in objects pass the test, e.g. Math which is a plain object
 * and some host or exotic objects may pass also.
 *
 * @param {} obj - value to test
 * @returns {Boolean} true if passes tests, false otherwise
 */
export function isPlainObject(obj: any) {
  // Basic check for type object that's not null
  if (typeof obj === 'object' && obj !== null) {
    // If Object.getPrototypeOf supported, use it
    if (typeof (Object.getPrototypeOf as any) === 'function') {
      let proto = Object.getPrototypeOf(obj)
      return proto === Object.prototype || proto === null
    }

    // Otherwise, use internal class
    // This should be reliable as if getPrototypeOf not supported, is pre-ES5
    return Object.prototype.toString.call(obj) === '[object Object]'
  }

  // Not an object
  return false
}
