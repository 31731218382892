import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { string, z } from 'zod'
import { hermes } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IResidentCard } from 'contracts/residents/interfaces/IResidentCard'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { RoundIcon } from 'components/RoundIcon'
import { isValidCard } from '../../../../../../../../../../../contracts/residents/helpers/isValidCard'
import { PrintIcon } from '../../OverviewDocuments/components/PrintIcon'
import { download } from 'helpers/download'
import { InputText } from 'components/Form/components/InputText'
import { ICardTemplate } from 'contracts/general/interfaces/ICardTemplate'
import { types } from 'modules/Pdf/templates/ResidentIdCards/types'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Pdf } from 'components/Pdf'
import axios from 'axios'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { Message } from 'components/Message'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { isStammCompound } from 'helpers/isStamm'
import { dayjs } from 'helpers/dayjs'
import { InputDate } from 'components/Form/components/InputDate'
const decRx = /^[0-9]{15,17}$/

interface Props {
  onClose: () => void
  card: IResidentCard
  resident: IResidentSearchResult
  changed: { hasUnsavedChanges: boolean }
  templates: ICardTemplate[]
}

@observer
export class CardDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private readonly model: Model<
    IResidentCard & { residentIsPresent: boolean }
  >
  @observable private loading = false
  @observable private disabled = false
  @observable private error: string | null = null
  @observable.ref private pdf: Blob | null = null
  @observable private enterCode = false
  private readonly templateOptions: InputSelectOption[]
  private readonly templates = new Map<string, ICardTemplate>()
  private cancelDownload: (() => void) | null = null
  private unmounted = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    const validator = z.object({
      templateId: string(),
      compoundId: string(),
      token: context.permissions.resident_id_card_register_code
        ? z.string().refine((val) => !val || decRx.test(val))
        : z.string(),
    })
    this.enterCode =
      !props.card.token && context.permissions.resident_id_card_register_code
    this.model = new Model(
      {
        ...props.card,
        templateId: props.card.templateId || props.templates[0]?.id || null,
        residentIsPresent: context.permissions.host_lfgb,
      },
      validator,
    )
    this.templateOptions = []
    for (const t of props.templates) {
      this.templateOptions.push({ value: t.id, label: t.label })
      this.templates.set(t.id, t)
    }

    // Use previously selected id card template if available
    const stored = localStorage.getItem(`${context.user.id}.card-template`)
    if (stored && props.templates.find((c) => c.id === stored)) {
      this.model.values.templateId = stored
    }

    makeObservable(this)
  }

  componentDidMount() {
    if (this.props.card.documentId) {
      runInAction(() => (this.loading = true))
      void this.loadPreview(this.props.card.documentId).then(
        action(() => (this.loading = false)),
      )
    }
  }

  componentWillUnmount() {
    this.unmounted = true
    this.cancelDownload?.()
  }

  private loadPreview = async (id: string) => {
    try {
      const source = axios.CancelToken.source()
      this.cancelDownload = () => source.cancel('Canceled by user')
      const response = await axios.get(
        `/api/${this.context.instance.id}/documents/files/${id}`,
        { cancelToken: source.token, responseType: 'arraybuffer' },
      )
      this.cancelDownload = null
      if (this.unmounted) {
        return
      }
      if (this.model.values.documentId === id) {
        runInAction(() => (this.pdf = new Blob([response.data])))
      }
    } catch (_e) {
      /* */
    }
  }

  private onCreate = async () => {
    runInAction(() => (this.model.values.token = this.model.values.token.trim()))
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    try {
      runInAction(() => (this.loading = true))
      const card = await hermes.create(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/cards`,
        {
          compoundId: this.model.values.compoundId,
          templateId: this.model.values.templateId,
          token: this.model.values.token.trim(),
          notes: this.model.values.notes.trim(),
          residentIsPresent: this.model.values.residentIsPresent,
          validTill: this.model.values.validTill,
        },
      )
      const result = await hermes.create(
        `/api/${this.context.instance.id}/reports`,
        {
          type: 'resident-id-cards',
          options: { templateId: this.model.values.templateId, cardIds: [card.id] },
        },
        { timeout: 30000 },
      )
      if (this.unmounted) {
        return
      } // Do not create card if user closed dialog
      await hermes.patch(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/cards/${card.id}`,
        {
          documentId: result.token,
        },
      )
      runInAction(() => {
        this.enterCode =
          !this.model.values.token.trim() &&
          this.context.permissions.resident_id_card_register_code
        this.model.values.id = card.id
        this.model.values.documentId = result.token
        this.error = null
      })
      await this.loadPreview(result.token)
      runInAction(() => {
        this.loading = false
        this.disabled = true
      })
    } catch (e: any) {
      runInAction(() => {
        this.loading = false
        if (e.id === ConflictError.id) {
          this.error = e.message
        } else {
          this.error =
            'Beim Anlegen des Ausweises ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.'
        }
      })
    }
    runInAction(() => {
      this.props.changed.hasUnsavedChanges = false
    })
  }

  private onUpdate = async () => {
    if (
      this.context.permissions.resident_id_card_register_code &&
      !decRx.test(this.model.values.token)
    ) {
      runInAction(() => (this.error = 'Bitte scannen Sie den Code ein.'))
      return
    }

    try {
      runInAction(() => (this.loading = true))
      await hermes.patch(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/cards/${this.model.values.id}`,
        {
          validTill: this.model.values.validTill,
          notes: this.model.values.notes.trim(),
          token: this.model.values.token,
        },
      )
    } catch (e: any) {
      runInAction(() => {
        this.loading = false
        if (e.id === ConflictError.id) {
          this.error = e.message
        } else {
          this.error =
            'Beim Speichern des Ausweises ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.'
        }
      })
      return
    }
    this.props.changed.hasUnsavedChanges = false
    this.props.onClose()
  }

  private onDownload = () => {
    download(
      `/api/${this.context.instance.id}/documents/files/${this.model.values.documentId}`,
    )
  }

  @action
  private setCardValid = (valid: string | null) => {
    this.model.values.validTill = valid
  }

  private saveCardTemplateSelection = () => {
    localStorage.setItem(
      `${this.context.user.id}.card-template`,
      this.model.values.templateId || '',
    )
  }

  render() {
    const template = this.templates.get(this.model.values.templateId || '') || null
    const Card = types[template?.type || '']
    const isNew = !this.model.values.id
    const isValid = isValidCard(this.model.values)
    const width = Math.floor((template?.width ?? 40) * 40)
    const height = Math.floor((template?.height ?? 40) * 40)
    const scale = width <= 500 ? 1 : 500 / width
    const pages = template?.pages || 1
    const forbidden =
      this.context.permissions.resident_id_cards < 2 ||
      (this.context.permissions.resident_id_cards === 2 &&
        !isStammCompound(this.model.values.compoundId || ''))

    return (
      <div id={this.model.id}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {isNew ? 'Bewohnerausweis erstellen' : 'Bewohnerausweis'}
              </Dialog.Title>
            </div>
          </div>
        </div>

        <div className='mx-6 mt-6 flex gap-4'>
          <InputSelect
            className='flex-[1_0_50px]'
            disabled={this.loading || this.disabled || !isNew}
            model={this.model}
            name='templateId'
            label='Ausweistyp'
            options={this.templateOptions}
            onChange={this.saveCardTemplateSelection}
          />
          <InputCompound
            className='flex-[1_0_50px]'
            disabled={this.loading || this.disabled || !isNew}
            model={this.model}
            name='compoundId'
            label='Gelände'
            onlyStamm={this.context.permissions.resident_id_cards <= 2}
            at={isNew ? null : '2000-01-01T00:00:00.000'}
          />
          {!this.enterCode && (
            <div className='flex flex-content'>
              <Button
                disabled={this.loading || this.disabled || forbidden}
                color={isValid ? 'success' : 'danger'}
                outline={isValid}
                style={{ borderRadius: '6px 0 0 6px' }}
                onClick={() =>
                  this.setCardValid(dayjs().subtract(1, 'day').format('YYYY-MM-DD'))
                }
                className='w-full'
              >
                Ungültig
              </Button>
              <Button
                disabled={this.loading || this.disabled || forbidden}
                color={isValid ? 'success' : 'danger'}
                outline={!isValid}
                style={{ borderRadius: '0 6px 6px 0' }}
                onClick={() => this.setCardValid(null)}
                className='w-full'
              >
                Gültig
              </Button>
            </div>
          )}
          {this.enterCode && this.context.permissions.resident_id_card_register_code && (
            <InputText
              disabled={this.loading || forbidden}
              model={this.model}
              name='token'
              label='Code'
            />
          )}
        </div>
        <div className='flex gap-4 mx-6 mb-6 mt-4 items-center'>
          <InputText
            disabled={this.loading || this.disabled || forbidden}
            className='flex-auto'
            model={this.model}
            name='notes'
            label='Notiz'
          />
          <InputDate
            className='flex-[0_1_118px] -mr-2'
            model={this.model}
            name='validTill'
            label='Gültig bis'
          />
          <RoundIcon
            icon='fa fa-undo'
            tooltip='Datum zurücksetzen'
            onClick={() => this.setCardValid(null)}
          />
        </div>
        <div
          className='bg-gray-100 p-6 border-t border-gray-200 flex relative'
          style={{ height: Math.floor(height * scale) * pages + 48 }}
        >
          {this.error && (
            <Message color='danger' className='border border-red-500 flex-auto'>
              {this.error}
            </Message>
          )}

          {!this.error && Card && template && !this.model.values.documentId && (
            <div
              style={{
                height: height * pages,
                width,
                transform: `scale(${scale})`,
                transformOrigin: 'left top',
              }}
              className='bg-white shadow mx-auto relative'
            >
              <Card
                instanceId={this.context.instance.id}
                resident={this.props.resident}
              />
            </div>
          )}

          {!this.error && !!this.model.values.documentId && this.pdf && template && (
            <div className='mx-auto -mb-4 relative'>
              <Pdf file={this.pdf} width={width * scale} height={height * scale} />
              <RoundIcon
                icon='fas fa-download'
                tooltip='Herunterladen'
                style={{ position: 'absolute', top: 24, right: -15 }}
                color='primary'
                onClick={this.onDownload}
              />
              <PrintIcon
                url={`/api/${this.context.instance.id}/documents/files/${this.model.values.documentId}`}
                style={{ position: 'absolute', top: 60, right: -15 }}
              />
            </div>
          )}
          {this.loading && <DialogOverlaySpinner opaque />}
        </div>

        <div
          className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200 flex'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <div className='flex-auto' style={{ paddingTop: 9 }}>
            {isNew && (
              <InputCheckbox
                model={this.model}
                name='residentIsPresent'
                label='Bewohner ist anwesend'
              />
            )}
          </div>
          <div className='flex-content'>
            <Button
              color='secondary'
              outline={!this.disabled}
              onClick={this.props.onClose}
            >
              {forbidden
                ? 'Schließen'
                : this.disabled && !this.enterCode
                ? 'Fertig'
                : 'Abbrechen'}
            </Button>
            {(!this.disabled || this.enterCode) && !forbidden && (
              <Button
                color='primary'
                className='ml-2'
                onClick={isNew ? this.onCreate : this.onUpdate}
                disabled={this.loading}
              >
                {isNew ? 'Weiter' : this.enterCode ? 'Code registrieren' : 'Speichern'}
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}
