import { HomeIcon } from '@heroicons/react/outline'
import { TopBar } from 'components/SideBarLayout/components/TopBar'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import * as React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  bundle: IInventoryBundle
  item?: IInventoryItem | null
}

export const InventoryBundleTopBar: React.FC<Props> = (props) => (
  <TopBar>
    <ol className='bg-white px-2 flex'>
      <li className='flex'>
        <div className='flex items-center'>
          <Link to={`/inventory/items`} className={`text-gray-400 hover:text-gray-500`}>
            <HomeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
            <span className='sr-only'>Home</span>
          </Link>
        </div>
      </li>

      <li className='flex'>
        <div className='flex items-center'>
          <svg
            className='flex-shrink-0 h-5 w-5 text-gray-300'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
            aria-hidden='true'
          >
            <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
          </svg>
          <span className='text-gray-600'>{props.bundle.label}</span>
        </div>
      </li>
      {props.item && (
        <li className='flex'>
          <div className='flex items-center'>
            <svg
              className='flex-shrink-0 h-5 w-5 text-gray-300'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 20 20'
              aria-hidden='true'
            >
              <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
            </svg>
            <span className='text-gray-600'>{props.item.label}</span>
          </div>
        </li>
      )}
    </ol>
  </TopBar>
)
