import { Collection, hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { InputDate } from 'components/Form/components/InputDate'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { Tooltip } from 'components/Tooltip'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { ConflictError, NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { ResponsibilityEndModel } from '../../../ResponsibilityEndForm'
import { ResponsibilityAddressForm } from '../../../ResponsibilityEndForm/components/ResponsibilityAddressForm'

interface Props {
  model: Model<ResponsibilityEndModel>
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  setStep: (step: 'form' | 'creating') => void
  bookings?: IBooking[]
}

const responsibilityEndReasonsAll: InputSelectOption[] = [
  { value: '', label: '' },
  { value: 'Unerlaubter Auszug', label: 'Unerlaubter Auszug' },
  { value: 'Erlaubter Auszug', label: 'Erlaubter Auszug' },
  /*{ value: 'Abschiebung', label: 'Abschiebung' },
  { value: 'Freiwillige Ausreise', label: 'Freiwillige Ausreise' },
  { value: 'Hausverbot', label: 'Hausverbot' },
  { value: 'In Obhutnahme', label: 'In Obhutnahme' },
  { value: 'Interne Verlegung', label: 'Interne Verlegung' },
  { value: 'JVA Aufenthalt', label: 'JVA Aufenthalt' },
  { value: 'Krankenhaus', label: 'Krankenhaus' },
  { value: 'Sofortverlegung', label: 'Sofortverlegung' },
  { value: 'Transfer', label: 'Transfer' },
  { value: 'Überstellung', label: 'Überstellung' },
  { value: 'Vermisst', label: 'Vermisst' },
  { value: 'Verstorben', label: 'Verstorben' },
  { value: 'Volljährigkeit', label: 'Volljährigkeit' },*/
]

@observer
export class EndOfStayStep1Form extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disabled: boolean
  private readonly disposers: Disposer[] = []
  @observable private patching = false
  @observable private error: string | null = null
  private readonly responsibilityEndReasons: InputSelectOption[]

  constructor(props: Props, context: AppContextProps) {
    super(props)
    if (
      props.model.values.isNew &&
      !context.permissions.resident_setInfoBookingEndOfStay
    ) {
      this.error =
        'Sie haben nicht die nötige Berechtigung zum Beenden eines Aufenthaltes. Bitte wenden Sie sich an einen Administrator.'
    }
    if (!props.model.values.isNew && context.permissions.resident_patchBookings === 0) {
      this.error = 'Sie können diese Meldung nur ansehen, aber nicht ändern.'
    }
    this.disabled = !!this.error
    this.responsibilityEndReasons = responsibilityEndReasonsAll
    makeObservable(this)
  }

  componentDidMount() {}

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onSubmit = async () => {
    if (this.patching) {
      return
    }
    if (!this.props.model.isValid()) {
      this.props.model.setFocusToLeftTopmostInvalidField()
      return
    }

    if (
      !this.props.model.values.reason ||
      !responsibilityEndReasonsAll.find((r) => r.value === this.props.model.values.reason)
    ) {
      runInAction(() => {
        this.error = 'Bitte wählen Sie einen Grund aus.'
      })
      return
    }

    // Create new booking
    if (this.props.model.values.isNew) {
      this.props.setStep('creating')
      return
    }

    // Save existing booking
    runInAction(() => (this.patching = true))
    const begin = dayjs(
      `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
    )
    const data: Partial<IBooking> = {
      comment: this.props.model.values.comment,
      beginAt: begin.toISOString(),
      endAt: begin.add(1, 'minute').toISOString(),
      extra: {
        ...this.props.model.values.extra,
        infoReason: this.props.model.values.reason,
      },
    }
    try {
      await hermes.patch(
        `/api/${this.context.instance.id}/bookings/${this.props.model.values.id}`,
        data,
      )
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.patching = false
        if (e.id === ConflictError.id) {
          this.error = e.message
        } else if (e.id === NotAuthorizedError.id) {
          this.error = 'Sie haben nicht die nötige Berechtigung für diesen Vorgang.'
        } else {
          this.error = 'Die Änderungen konnten nicht gespeichert werden.'
        }
      })
    }
  }

  render() {
    const disabled = this.disabled || this.patching
    const model = this.props.model
    const offerFamilyCheckbox =
      model.values.isNew &&
      this.props.members.resources &&
      this.props.members.resources.length > 1

    return (
      <div className='min-h-[242px] flex flex-col'>
        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        <div className='flex-content relative grid grid-cols-4 gap-4 my-4' id={model.id}>
          <InputDate
            disabled={disabled}
            model={model}
            name='beginDate'
            label='Ab Datum'
            placeholder='DD.MM.JJJJ'
          />
          <InputText
            disabled={disabled}
            model={model}
            name='beginTime'
            label='Uhrzeit'
            tooltip='Uhrzeit benötigt'
            placeholder='hh:mm'
          />
          <InputSelect
            disabled={disabled}
            model={model}
            name='reason'
            label='Grund'
            className='col-span-2'
            options={this.responsibilityEndReasons}
          />

          {(model.values.reason === 'Erlaubter Auszug' ||
            model.values.reason === 'Sofortverlegung' ||
            model.values.reason === 'Volljährigkeit') && (
            <ResponsibilityAddressForm
              address={model.values.extra.address}
              disabled={disabled}
            />
          )}

          <InputTextarea
            disabled={disabled}
            model={model}
            name='comment'
            label='Notiz'
            rows={offerFamilyCheckbox ? 3 : 5}
            className='col-span-4'
          />

          {offerFamilyCheckbox && (
            <div className='col-span-4 flex'>
              <InputCheckbox
                className='flex-content'
                disabled={disabled}
                model={model}
                name='family'
                label='Alle Familienmitglieder'
              />
              <div className='flex-content ml-2 mr-auto text-blue-500 has-tooltip'>
                <i className='fas fa-question-circle' />
                <Tooltip>
                  {this.context.permissions.resident_automatic_setResponsibility
                    ? 'Aufenthalt'
                    : 'Zuständigkeit'}{' '}
                  für alle
                  <br />
                  Familienmitglieder beenden
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        <div className='my-auto flex-content text-right'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            {disabled ? 'Schließen' : 'Abbrechen'}
          </Button>
          {!disabled && (
            <Button color='success' className='ml-2' onClick={this.onSubmit}>
              {!model.values.isNew
                ? 'Speichern'
                : this.context.permissions.resident_automatic_setResponsibility
                ? 'Aufenthalt beenden'
                : 'Zuständigkeit beenden'}
            </Button>
          )}
        </div>

        {this.patching && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}
