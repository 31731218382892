import * as React from 'react'
import { InputText } from 'components/Form/components/InputText'
import { InputDate } from 'components/Form/components/InputDate'
import { InputNationality } from 'components/Form/components/InputNationality'
import { InputSelect } from 'components/Form/components/InputSelect'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { Model } from 'components/Form/Model'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { sexOptions } from '../constants/sexOptions'
import { familyRelationOptions } from '../constants/familyRelationOptions'
import { InputCountry } from 'components/Form/components/InputCountry'
import { maritalStatusOptions } from '../constants/maritalStatusOptions'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { CaseRecordCaption } from './CaseRecordCaption'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { aufenthaltsstatusOptions } from '../constants/aufenthaltsstatusOptions'
import { leistungsanspruchOptions } from '../constants/leistungsanspruchOptions'
import { action, makeObservable } from 'mobx'
interface Props {
  model: Model<IResident>
  requiredFields?: any
  readOnly: boolean
}

export class CaseRecordFormAwum extends React.Component<Props, {}> {
  private jbpIdModel: Model<{ jbpId: string }>

  constructor(props: Props) {
    super(props)
    this.jbpIdModel = new Model({
      jbpId: props.model.values.id ? toJbpId(+props.model.values.id) : 'Automatisch',
    })
    makeObservable(this)
  }

  @action private setFalse = (name: string) => (this.props.model.values[name] = false)

  render() {
    return (
      <div className='grid grid-cols-3 gap-5 flex-initial relative'>
        {/* Row 1 */}
        <InputSelect
          model={this.props.model}
          name='sex'
          label={this.props.requiredFields?.sex ? 'Anrede *' : 'Anrede'}
          options={sexOptions}
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='title'
          label='Titel'
          disabled={this.props.readOnly}
        />
        <InputDate
          model={this.props.model}
          name='dateOfBirth'
          label={
            this.props.requiredFields?.dateOfBirth ? 'Geburtsdatum *' : 'Geburtsdatum'
          }
          disabled={this.props.readOnly}
        />

        {/* Row 2 */}
        <InputText
          model={this.props.model}
          name='firstName'
          label='Vorname *'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='lastName'
          label='Nachname *'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='aliasName'
          label='Alias'
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Identifikationsnummern</CaseRecordCaption>
        {/* Row 3 */}
        <InputText model={this.jbpIdModel} name='jbpId' label='JBP-ID' disabled />
        {/* Unique constraint on db */}
        <InputDecimal
          model={this.props.model}
          name='wimNr'
          label='WIM-Nr.'
          precision={8}
          scale={0}
          notNegative
          noThousandsSeparators
          disabled={this.props.readOnly}
        />
        {/* Unique constraint on db */}
        <InputDecimal
          model={this.props.model}
          name='mId'
          label='MID (LABEA)'
          precision={10}
          scale={0}
          notNegative
          noThousandsSeparators
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Herkunft</CaseRecordCaption>
        {/* Row 5 */}
        <InputCountry
          model={this.props.model}
          name='countryOfBirthId'
          label='Herkunftsland'
          disabled={this.props.readOnly}
        />
        <InputNationality
          model={this.props.model}
          name='nationalityId'
          label='Staatsangehörigkeit'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='ethnicity'
          label='Volkszugehörigkeit'
          disabled={this.props.readOnly}
        />

        {/* Row 6 */}
        <InputText
          model={this.props.model}
          name='cityOfBirth'
          label='Geburtsort'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='nativeLanguage'
          label='Muttersprache'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='religion'
          label='Religion'
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Rechtliches</CaseRecordCaption>
        <InputSelect
          model={this.props.model}
          name='legalAufenthaltsstatus'
          label='Aufenthaltstitel'
          options={aufenthaltsstatusOptions}
          disabled={this.props.readOnly}
        />
        <InputDate
          model={this.props.model}
          name='legalAufenthaltsstatusSeit'
          label='Aufenthaltstitel seit'
          disabled={this.props.readOnly}
        />
        <InputDate
          model={this.props.model}
          name='awumZuweisungsdatum'
          label='Zuweisungsdatum'
          disabled={this.props.readOnly}
        />
        <InputSelect
          model={this.props.model}
          name='awumAsylbLGLeistungsanspruch'
          label='AsylbLG Leistungsansp.'
          options={leistungsanspruchOptions}
          disabled={this.props.readOnly}
        />
        <InputDate
          model={this.props.model}
          name='awumAsylbLGLeistungsanspruchSeit'
          label='Leistungsanspruch seit'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='awumOrdnungsmerkmal'
          label='OM (EWO)'
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Familie</CaseRecordCaption>
        <InputSelect
          model={this.props.model}
          name='familyRelation'
          label={
            this.props.requiredFields?.familyRelation
              ? 'Relation zum HV *'
              : 'Relation zum HV'
          }
          options={familyRelationOptions}
          disabled={this.props.readOnly}
        />
        <InputSelect
          model={this.props.model}
          name='maritalStatus'
          label='Familienstand'
          className='col-span-2'
          options={maritalStatusOptions}
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Besonderheiten</CaseRecordCaption>
        {/* Row 8 */}
        <InputTextarea
          rows={4}
          model={this.props.model}
          name='legalAufenthaltsstatusComment'
          label='Aufenthaltstitel Notizen'
          disabled={this.props.readOnly}
        />
        <InputTextarea
          rows={4}
          className='col-span-2'
          model={this.props.model}
          name='comment'
          label='Allgemeine Notizen'
          disabled={this.props.readOnly}
        />

        <div
          className={`relative flex col-span-3 gap-x-5 gap-y-3 flex-wrap w-full shadow-sm text-sm border border-gray-300 rounded-md px-3 py-2 ${
            this.props.readOnly ? 'mb-6' : ''
          }`}
        >
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='isChild'
            label='Kind'
            onChange={() => this.setFalse('isAdult')}
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='isAdult'
            label='Erwachsener'
            onChange={() => this.setFalse('isChild')}
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='kriegsfluchtUkr'
            label='Kriegsflucht-UKR'
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='personalCharacteristicsIsSchwangerschaft'
            label='Schwangere Person'
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='personalCharacteristicsIsEinzelzimmer'
            label='Einzelzimmerberechtigung'
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='personalCharacteristicsIsRollstuhl'
            label='Rollstuhlfahrer'
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='personalCharacteristicsIsBarrierefreiheit'
            label='Eingeschränkte Barrierefreiheit benötigt'
            disabled={this.props.readOnly}
          />
        </div>

        {this.props.children}
      </div>
    )
  }
}
