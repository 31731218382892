import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { Age } from 'components/Age'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { hermes } from '@byll/hermes'
import { observer } from 'mobx-react'
import { IGroupField } from '../../../contracts/groups/interfaces/IGroupField'
import { InputCheckbox } from '../../../components/Form/components/InputCheckbox'
import { InputSelect } from '../../../components/Form/components/InputSelect'
import { InputText } from '../../../components/Form/components/InputText'
import { InputTextDate } from 'components/Form/components/InputTextDate'
import { IExtendedGroupParticipantEntry } from 'contracts/groups/interfaces/IExtendedGroupParticipantEntry'
import { IGroup } from 'contracts/groups/interfaces/IGroup'
import { bookingTypes } from 'modules/Residents/helpers/bookingTypes'
import { SearchIcon } from '@heroicons/react/outline'
import { action } from 'mobx'
import { Link } from 'react-router-dom'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'

interface Props {
  group: IGroup
  entry: IExtendedGroupParticipantEntry
  selected: Set<string>
}

export const GroupParticipantInputs = {
  checkbox: InputCheckbox,
  date: InputTextDate,
  select: InputSelect,
  text: InputText,
}

const toggle = action(
  (resident: IExtendedGroupParticipantEntry, selected: Set<string>) => {
    if (selected.has(resident.id)) {
      selected.delete(resident.id)
    } else {
      selected.add(resident.id)
    }
  },
)

const unset = action(
  (resident: IExtendedGroupParticipantEntry, selected: Set<string>) => {
    selected.delete(resident.id)
  },
)

@observer
export class ParticipantEntry extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<any>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.entry)
  }

  componentWillUnmount(): void {
    unset(this.props.entry, this.props.selected)
  }

  private deleteItem = async () => {
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/groups/${this.props.group.id}-${this.props.group.version}/participants/${this.props.entry.id}`,
        { immediately: true },
      )
    } catch (e) {
      window.alert('Leider ist das Löschen fehlgeschlagen')
    }
  }

  private mapOptions = (options: string[]) => {
    if (options.length === 0) {
      return
    }
    return [
      { value: '', label: 'Bitte wählen' },
      ...options.map((o) => ({ value: o, label: o })),
    ]
  }

  render() {
    const resident = this.props.entry
    const bookingType = bookingTypes[resident.accommodation?.type || ''] || {
      label: '',
      color: '',
      icon: SearchIcon,
    }

    return (
      <tr key={resident.id} className='group'>
        <td className='px-6 py-4 whitespace-nowrap relative flex'>
          <div className='flex-[0_0_30px] pt-2'>
            <input
              type='checkbox'
              className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded cursor-pointer relative -top-[1px]'
              checked={this.props.selected.has(resident.id)}
              onChange={() => toggle(resident, this.props.selected)}
            />
          </div>
          <div className='relative'>
            <div className='flex items-center'>
              <div className='flex-shrink-0 h-10 w-10'>
                <img
                  className='h-10 w-10 rounded-full'
                  src={getResidentImageSrc(
                    this.context.instance.id,
                    resident.imageId,
                    resident.sex,
                    'thumbnail',
                  )}
                  alt=''
                />
              </div>
              <div className='ml-4'>
                <div className='text-sm font-medium text-gray-900'>
                  <Link
                    className='hover:text-blue-500'
                    to={`/residents/${toJbpId(
                      +resident.residentId,
                    ).toLowerCase()}/overview`}
                    target='_blank'
                  >{`${resident.lastName.toUpperCase()}, ${resident.firstName}`}</Link>
                  &nbsp;&nbsp;
                  <Age sex={resident.sex} dateOfBirth={resident.dateOfBirth} />
                </div>
                <div className='text-sm text-gray-400'>{resident.nationality || '-'}</div>
              </div>
            </div>
            {/*this.context.permissions.groups_participants && (
              <RoundIcon classNameContainer='hidden group-hover:block' tooltip={{ text: 'Eintrag löschen', position: 'right' }} style={{ position: 'absolute', top: 6, left: 5 }} icon='fas fa-trash' color='danger' onClick={this.deleteItem} />
            )*/}
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
          {resident.accommodation ? (
            <div className='flex'>
              <div className='flex-content mr-1'>
                <bookingType.icon
                  className={`h-4 w-4 ${bookingType.color} rounded-full`}
                  style={{ padding: 1 }}
                  aria-hidden='true'
                />
              </div>
              <div className='flex-auto overflow-hidden'>
                {resident.accommodation.label}
              </div>
            </div>
          ) : (
            <div>Nicht eingebucht</div>
          )}
        </td>
        {this.props.group.fields.map((field: IGroupField) => {
          const FieldComponent = GroupParticipantInputs[field.type ?? 'text']
          return (
            <td key={field.id} className='pr-6 py-4 whitespace-nowrap text-sm w-[190px]'>
              <FieldComponent
                name={field.id}
                model={this.model}
                label={field.label}
                options={this.mapOptions(field.options) as any}
                disabled={!this.context.permissions.groups_participants}
              />
            </td>
          )
        })}
      </tr>
    )
  }
}
