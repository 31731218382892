import { isIntegerString } from '../general/helpers/isIntegerString'
import { isMonth } from '../general/helpers/isMonth'
import { z } from 'zod'

export const ArbeitsplanFilterValidator = z.object({
  month: z.string().refine(isMonth),
  userId: z.string().refine(isIntegerString),
  initiatorId: z.string().refine(isIntegerString).optional(),
  arbeitsplanDataId: z.string().uuid().optional(),
})
