import { Collection, hermes, Resource } from '@byll/hermes'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Spinner } from 'components/Spinner'
import { EmployeeEntry } from './EmployeeEntry'
import { IWorkplanTimeSheet } from 'contracts/workplan/interfaces/IWorkplanTimeSheet'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { toast } from 'react-toastify'

interface Props {
  month: string
}

@observer
export class EmployeeList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly employees: Collection<IWorkplanTimeSheet>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.employees = new Collection(
      `/api/${context.instance.id}/workplan/signatures/${context.user.id}-${props.month}/employees`,
      { mountedAt: new Date().toISOString() },
    )
  }

  componentDidMount() {
    this.disposers.push(this.employees.init({ readOnly: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private mapEmployee = (res: Resource<IWorkplanTimeSheet>) => {
    if (!res.data) {
      return null
    }

    return (
      <EmployeeEntry
        month={this.props.month}
        employee={res.data}
        key={res.id}
        onDelete={this.onDelete}
      />
    )
  }

  private onDelete = async (employee: IWorkplanTimeSheet) => {
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/workplan/signatures/${this.context.user.id}-${this.props.month}/employees/${employee.id}`,
      )
      toast.success(
        `${employee.firstName} ${employee.lastName} wurde von dieser Liste entfernt.`,
      )
    } catch (_e) {
      toast.error(
        `${employee.firstName} ${employee.lastName} konnte nicht von dieser Liste entfernt werden.`,
      )
    }
  }

  render() {
    if (!this.employees.resources) {
      return <Spinner />
    }
    return (
      <div className='flex flex-col pb-6'>
        <div className='-my-2 overflow-x-auto'>
          <div className='py-2 align-middle inline-block min-w-full'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Mitarbeiter
                      {/*<SearchCaption entries={this.props.employees} orderBy='receiver.name'>Name</SearchCaption>*/}
                    </th>
                    <th
                      scope='col'
                      className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                    >
                      Firma
                    </th>
                    <th
                      scope='col'
                      className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                    >
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {this.employees.resources.map(this.mapEmployee)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
