import { Collection, hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Callout } from 'components/Callout'
import { Model } from 'components/Form/Model'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentDocumentationSearchResult } from 'contracts/residents/interfaces/IResidentDocumentationSearchResult'
import { IDocumentationSearchFilter } from 'contracts/residents/interfaces/IDocumentationSearchFilter'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InputText } from 'components/Form/components/InputText'
import { Button } from 'components/Form/components/Button'
import { action, makeObservable } from 'mobx'
import { Spinner } from 'components/Spinner'
import { dayjs } from 'helpers/dayjs'
import { ChatAltIcon } from '@heroicons/react/outline'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { RoundIcon } from 'components/RoundIcon'
import { box } from 'services/box'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { LoadMoreButton } from 'components/LoadMoreButton'
import { Tooltip } from 'components/Tooltip'
import { IRole } from 'contracts/users/interfaces/IRole'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { DocumentationExport } from './components/DocumentationExport/DocumentationExport'
import { deleteTodo } from './components/DocumentationDialog/helpers/deleteTodo'
import { Route, Routes, useParams } from 'react-router-dom'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { DocumentationDialog } from './components/DocumentationDialog'
import { isStammCompound } from 'helpers/isStamm'

interface Props {
  resident: IResident
  navigate: (url: string) => void
}

const sexColors = {
  male: 'bg-blue-500 text-white border-blue-500',
  female: 'bg-pink-500 text-white border-pink-500',
}

export const CATEGORY_KU_ABRECHNUNG = 'KÜ/ Abrechnung'

export const documentationTopicOptions: InputMultiSelectOption[] = [
  'Abmahnungen',
  'Abmahnung 1',
  'Abmahnung 2',
  'Arbeitsmarktintegration',
  'Aufenthalt',
  'Eingliederungshilfe',
  'Erstgespräch',
  'Gesundheit',
  'Hausverbot',
  'Jugendamt',
  'Kita',
  'Krisenintervention/ besonderes Vorkommnis',
  CATEGORY_KU_ABRECHNUNG,
  'Leistungsbezug',
  'Schulden',
  'Schule',
  'Schwangerschaft/ Geburt',
  'Sonstiges',
  'Sprach-& Integrationskurse',
  'Strafsachen',
  'Wachschutzbericht',
  'Wohnungsmarkt',
  'Zusammenleben',
].map((t) => ({ id: t, label: t }))

@observer
export class DocumentationTab extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IDocumentationSearchFilter>
  private readonly roles: Collection<IRole, { userId: string }>
  private readonly documentations: Collection<
    IResidentDocumentationSearchResult,
    { count: number }
  >
  private readonly residentSearchResults: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      page: '0,40',
      residentId: null,
      familyId: props.resident.familyId,
      topics: '',
      searchString: '',
      onlyAttachments: false,
      onlyOpenTodo: false,
    })
    this.roles = new Collection(`/api/${context.instance.id}/users/roles`, {
      userId: context.user.id,
      group: 'doku',
    })
    this.documentations = new Collection(
      `/api/${context.instance.id}/residents/${props.resident.id}/documentationSearchResults`,
      this.model.values,
    )
    this.residentSearchResults = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      {
        familyId: props.resident.familyId,
        fields: 'bookings,accommodation,lastScanAtDe',
        sort: 'dateOfBirth,asc',
      },
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.roles.init({ readOnly: true }))
    this.disposers.push(this.documentations.init({ readOnly: true, observeQuery: true }))
    this.disposers.push(this.residentSearchResults.init({ readOnly: true }))
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private showDownloadDialog = () => {
    if (this.context.permissions.resident_documentation_anyRole < 1) {
      void box.alert(
        'Keine Berechtigung',
        'Um Dokumentationen zu exportieren, benötigen Sie folgende Berechtigung: "Bewohner -> Sichtbarkeit der Bewohnerdokumentation -> Immer sichtbar".',
        { color: 'danger' },
      )
      return
    }
    const promise = box.custom(
      <DocumentationExport
        onClose={() => promise.close()}
        resident={this.props.resident}
      />,
      { closable: true, context: this.context },
    )
  }

  @action private setToFamily = () => {
    this.model.values.familyId = this.props.resident.familyId
    this.model.values.residentId = null
  }

  @action private setToResident = () => {
    this.model.values.familyId = null
    this.model.values.residentId = this.props.resident.id
  }

  @action
  private addDocumentation = () => {
    this.props.navigate(
      `/residents/${toJbpId(+this.props.resident.id).toLowerCase()}/documentation/new`,
    )
  }

  private openDocumentation = (documentation: IResidentDocumentationSearchResult) => {
    this.props.navigate(
      `/residents/${toJbpId(+this.props.resident.id).toLowerCase()}/documentation/${
        documentation.id
      }`,
    )
  }

  private closeDocumentation = () => {
    this.props.navigate(
      `/residents/${toJbpId(+this.props.resident.id).toLowerCase()}/documentation`,
    )
  }

  private deleteDocumentation = async (
    documentation: IResidentDocumentationSearchResult,
  ) => {
    if (
      this.context.permissions.resident_documentation === 1 &&
      !isStammCompound(documentation.compound.id)
    ) {
      await box.alert(
        'Berechtigung fehlt',
        'Diese Dokumentation wurde außerhalb Ihrer Zuständigkeit erstellt. Sie haben Leserechte, aber keine Löschrechte für diesen Eintrag.',
        { color: 'danger' },
      )
      return
    }
    const confirmed = await box.alert(
      'Eintrag löschen',
      'Möchten Sie diesen Eintrag wirklich unwiderruflich löschen?',
      { confirm: 'Ja, jetzt löschen', cancel: 'Abbrechen', color: 'danger' },
    )
    if (!confirmed) {
      return
    }
    try {
      const todoId = documentation.todoId
      await hermes.delete(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/documentationSearchResults/${documentation.id}`,
        { immediately: true },
      )
      if (todoId) {
        await deleteTodo(
          { id: todoId, listId: this.context.defaults.dokuTodoListId },
          this.context.instance.id,
        )
      }
    } catch (_e) {
      box.alert(
        'Löschen fehlgeschlagen',
        'Der Eintrag konnte nicht gelöscht werden. Bitte wenden Sie sich an einen Adminitrator.',
      )
    }
  }

  private documentationMapper = (
    res: Resource<IResidentDocumentationSearchResult>,
    i: number,
  ) => {
    const documentation = res.data
    if (!documentation) {
      return null
    }
    return (
      <li
        key={documentation.id}
        className='group rounded-md hover:bg-gray-100 cursor-pointer'
        onClick={() => this.openDocumentation(documentation)}
      >
        <div className='relative pb-4'>
          {this.documentations.resources &&
          i !== this.documentations.resources.length - 1 ? (
            <span
              className='absolute top-5 left-8 -ml-px h-full w-0.5 bg-gray-200'
              aria-hidden='true'
            />
          ) : null}
          <div className='relative flex items-start space-x-3 p-3'>
            <>
              <div className='relative'>
                <img
                  className='h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white'
                  src={getResidentImageSrc(
                    this.context.instance.id,
                    documentation.createdBy.imageId,
                    documentation.createdBy.sex,
                    'thumbnail',
                  )}
                  alt=''
                />

                <span className='absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px'>
                  <ChatAltIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                </span>
              </div>
              <div className='min-w-0 flex-1'>
                <div>
                  <div className='text-sm'>
                    <span className='font-medium text-gray-900'>
                      {`${documentation.createdBy.firstName} ${documentation.createdBy.lastName}`}
                    </span>
                    <span className='font-medium text-gray-500'>
                      &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;{documentation.compound.label}
                    </span>
                    {documentation.documentIds.length > 0 && (
                      <span className='ml-2 text-gray-500'>
                        <i className='fa fa-paperclip fa-sm' />
                        &nbsp;<small>{documentation.documentIds.length}</small>
                      </span>
                    )}
                  </div>
                  <p
                    className='mt-0.5 text-sm text-gray-500'
                    style={{ lineHeight: '26px' }}
                  >
                    <span className='mr-1'>
                      {dayjs(documentation.date).format('DD.MM.YYYY')}
                    </span>
                    {documentation.todoId && !documentation.todoDoneDate && (
                      <span className='mx-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-500 text-white border border-yellow-500'>
                        Offene Aufgabe
                      </span>
                    )}
                    {documentation.topics.map((t) => (
                      <span
                        key={t}
                        className='mx-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 border border-gray-500'
                      >
                        {t}
                      </span>
                    ))}
                    {documentation.residents
                      .filter((r) => r.checked)
                      .map((r) => (
                        <span
                          key={r.id}
                          className={`mx-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${
                            sexColors[r.sex || ''] ||
                            'bg-gray-500 border-gray-500 text-white'
                          }`}
                        >
                          {`${r.lastName.toUpperCase()}, ${r.firstName}`}
                        </span>
                      ))}
                  </p>
                </div>
                <div className='mt-1 text-sm text-gray-700'>
                  <p>
                    {documentation.notes.substring(0, 130) +
                      (documentation.notes.length > 130 ? '...' : '')}
                  </p>
                </div>
              </div>
            </>
          </div>
          <RoundIcon
            classNameContainer='hidden group-hover:block'
            tooltip={{ text: 'Eintrag löschen', position: 'left' }}
            style={{ position: 'absolute', top: 5, right: 5 }}
            icon='fas fa-trash'
            color='danger'
            onClick={(event) => {
              event.stopPropagation()
              this.deleteDocumentation(documentation)
            }}
          />
        </div>
      </li>
    )
  }

  render() {
    const disabled = !this.documentations.resources
    const filtered =
      !!this.model.values.searchString?.trim() ||
      !!this.model.values.topics ||
      this.model.values.onlyAttachments ||
      this.model.values.onlyOpenTodo

    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div
          className='hidden lg:block pr-12 pt-4 text-right'
          style={{ flex: '0 0 200px' }}
        >
          <span className='text-gray-900 text-lg'>Dokumentation</span>
          <br />
          <span className='text-sm text-gray-400'>
            Beobachtungen, Betreuung und Beratung
          </span>
        </div>
        <div className='flex-auto pt-4 relative min-w-0 flex flex-col gap-4 lg:pr-4'>
          <div className='flex gap-4'>
            <div className='flex-auto relative'>
              <InputText
                disabled={disabled}
                model={this.model}
                name='searchString'
                placeholder='Suche'
                style={{ paddingLeft: 32 }}
              />
              <i
                className='fas fa-search absolute text-gray-500'
                style={{ top: 11, left: 11 }}
              />
            </div>
            <div className='flex-content'>
              <Button
                color='secondary'
                className='has-tooltip'
                outline={!this.model.values.familyId}
                style={{ borderRadius: '6px 0 0 6px' }}
                onClick={this.setToFamily}
              >
                Familie
                <Tooltip>
                  Dokumentation der gesamten
                  <br />
                  Familie anzeigen
                </Tooltip>
              </Button>
              <Button
                className='has-tooltip'
                color='secondary'
                outline={!!this.model.values.familyId}
                style={{ borderRadius: '0 6px 6px 0' }}
                onClick={this.setToResident}
              >
                Bewohner
                <Tooltip>
                  Dokumentation des ausgewälten
                  <br />
                  Bewohners anzeigen
                </Tooltip>
              </Button>
            </div>
            <Button
              color='primary'
              onClick={this.addDocumentation}
              className='flex-content'
            >
              Neuer Eintrag
            </Button>
            <Button
              color='primary'
              onClick={this.showDownloadDialog}
              className='w-[38px]'
              style={{ padding: '9px 0' }}
            >
              <i className='fas fa-download' />
            </Button>
          </div>
          <InputMultiSelect
            options={documentationTopicOptions}
            disabled={disabled}
            model={this.model}
            name='topics'
            placeholder='Nach Themen filtern...'
          />
          <div className='flex'>
            <div className='flex-content'>
              <InputCheckbox
                name='onlyAttachments'
                disabled={disabled}
                model={this.model}
                label='Nur mit Anhängen'
              />
            </div>
            <div className='flex-content ml-6'>
              <InputCheckbox
                name='onlyOpenTodo'
                disabled={disabled}
                model={this.model}
                label='Nur mit offenen Aufgaben'
              />
            </div>
          </div>

          {/* Loader */}
          {!this.documentations.resources && (
            <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
              <Spinner delay />
            </div>
          )}

          {/* Empty message */}
          {this.documentations.resources?.length === 0 && !filtered && (
            <div className='flex-auto rounded-md bg-gray-100 border border-gray-300 flex overflow-hidden mb-4'>
              <Callout
                icon='fas fa-search'
                title='Bisher keine Dokumentation hinterlegt'
                subtitle='Hinterlegen Sie den ersten Eintrag mit dem Button oben'
              />
            </div>
          )}

          {/* No results message */}
          {this.documentations.resources?.length === 0 && filtered && (
            <div className='flex-auto rounded-md bg-gray-100 border border-gray-300 flex overflow-hidden mb-4'>
              <Callout
                icon='fas fa-search'
                title='Keine Suchergebnisse'
                subtitle='Hinterlegen Sie neue Einträge mit dem Button oben'
              />
            </div>
          )}

          {/* Search results / list */}
          {this.documentations.resources &&
            this.documentations.resources.length > 0 &&
            this.documentations.metadata && (
              <div className='flex-auto min-h-[180px] relative overflow-hidden mt-4'>
                <div className='flow-root'>
                  <ul>{this.documentations.resources.map(this.documentationMapper)}</ul>
                  {this.documentations.metadata.count > 2 && (
                    <div className='text-center text-sm'>
                      {/* eslint-disable */}
                      <LoadMoreButton
                        collection={this.documentations}
                        incrementBy={40}
                        pluralText='Sie sehen alle ${count} Einträge'
                      />
                      {/* eslint-enable */}
                    </div>
                  )}
                </div>
              </div>
            )}

          {/* Dialog */}
          <Routes>
            <Route
              path=':documentationId'
              element={
                <RenderDocumentationDialog
                  resident={this.props.resident}
                  roles={this.roles}
                  onClose={this.closeDocumentation}
                  residentSearchResults={this.residentSearchResults}
                />
              }
            />
          </Routes>
        </div>
      </div>
    )
  }
}

interface DialogProps {
  resident: IResident
  residentSearchResults: Collection<IResidentSearchResult>
  roles: Collection<IRole>
  onClose: () => void
}

export const RenderDocumentationDialog: React.FC<DialogProps> = observer((props) => {
  const params = useParams<{ documentationId: string }>()
  if (!props.residentSearchResults.resources) {
    return null
  }
  return (
    <DocumentationDialog
      key={params.documentationId}
      documentationId={params.documentationId || ''}
      resident={props.resident}
      residentSearchResults={props.residentSearchResults}
      roles={props.roles}
      onClose={props.onClose}
    />
  )
})
