/**
 * This is a modified version of https://github.com/yuanqing/autosize-input
 * It is adjusted for measurement only, so that the actual width can be specified
 * during regular react rendering.
 */

const GHOST_ELEMENT_ID = '__autosizeInputGhost'
export interface IWidthCache {
  className: string
  value: string
  width: number
}

// Create `ghostElement`, with inline styles to hide it and ensure that the text is all
// on a single line.
function createGhostElement() {
  const ghostElement = document.createElement('div')
  ghostElement.id = GHOST_ELEMENT_ID
  ghostElement.style.cssText =
    'display:inline-block;width:initial;height:0;overflow:hidden;position:absolute;' +
    'top:0;left:0;visibility:hidden;white-space:nowrap;'
  document.body.appendChild(ghostElement)
  return ghostElement
}

export function getInputWidth(
  cache: IWidthCache,
  className: string,
  value: string,
): number {
  // Shortcut for empty fields
  if (value === '') {
    return 2
  }
  if (cache.className !== className || cache.value !== value) {
    cache.className = className
    cache.value = value
    cache.width = getGhostWidth(className, value)
  }
  return cache.width
}

function getGhostWidth(className: string, value: string): number {
  // Check if the `ghostElement` exists. If no, create it.
  const ghostElement = document.getElementById(GHOST_ELEMENT_ID) || createGhostElement()
  // Copy all width-affecting styles to the `ghostElement`.
  ghostElement.innerText = value
  if (ghostElement.className !== className) {
    ghostElement.className = className
  }
  // Copy the width of `ghostElement` to `element`.
  const width: string | null = window.getComputedStyle(ghostElement).width
  // Check if width is a correct number
  if (!width || !width.endsWith('px')) {
    throw new Error(`Got invalid text width: ${width}`)
  }
  let num = parseInt(width.slice(0, -2), 10) + 2
  if (isNaN(num)) {
    throw new Error(`Got invalid text width: ${width}`)
  }
  return Math.ceil(num)
}
