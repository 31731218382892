import * as React from 'react'
import styles from '../styles.module.scss'

export const StundenzettelCaptions: React.FC = () => (
  <div className='pt-[40px]'>
    <div className={styles.row}>
      <div className={'font-bold bg-gray-200 ' + styles.colDate}>Datum</div>
      <div className={'font-bold bg-gray-200 ' + styles.colLabel}>Bezeichnung</div>
      <div className={'font-bold bg-gray-200 ' + styles.colBreak}>Pause</div>
      <div className={'font-bold bg-gray-200 ' + styles.colHours}>Arbeitszeit</div>
    </div>
  </div>
)
