import * as React from 'react'
import { fromJbpId } from 'contracts/residents/helpers/fromJbpId'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { CaseRecordMenu } from './components/CaseRecordMenu'
import { CaseRecordBody } from './components/CaseRecordBody'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { TitleBar } from 'components/TitleBar'
import { Resource } from '@byll/hermes'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { NotFoundError } from 'contracts/errors/HermesErrors'
import styles from './styles.module.scss'
import { Spinner } from 'components/Spinner'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { NotFound } from '../../../../components/Callout/components/NotFound'
import { LoadingError } from '../../../../components/Callout/components/LoadingError'
import { storage } from 'services/storage'
import { dayjs } from 'helpers/dayjs'
import { LastSeenValidator } from 'modules/Residents/validators/LastSeenValidator'

interface Props {
  hash: string
  fromSearch: boolean
}

@observer
export class CaseRecord extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private resident: Resource<IResident>
  // Custom disposer in addition to .disposers so that residentDisposer
  // can be used for individual updates / dispose cycles throughout the lifecycle.
  private residentDisposer: Disposer | null = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.resident = new Resource(
      `/api/${context.instance.id}/residents/${fromJbpId(props.hash)}`,
    )
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.props.hash,
        (hash) => {
          const resident = new Resource<IResident>(
            `/api/${this.context.instance.id}/residents/${fromJbpId(hash)}`,
          )
          const disposer = resident.init()
          this.residentDisposer?.()
          runInAction(() => {
            this.residentDisposer = disposer
            this.resident = resident
          })
        },
        { fireImmediately: true },
      ),
    )
    this.setLastSeen()
  }

  private setLastSeen = () => {
    const validated = LastSeenValidator.safeParse(
      storage.get(`lastSeen.${this.context.user.id}`),
    )
    if (validated.success) {
      // Remove current entry (if available from earlier visits)
      for (let i = validated.data.length - 1; i >= 0; i--) {
        if (validated.data[i].id === this.resident.id) {
          validated.data.splice(i, 1)
        }
      }
      // Add current entry on top
      validated.data.unshift({
        id: this.resident.id,
        seenAt: dayjs().toISOString(),
      })
      // Cut down to max 25 entries
      if (validated.data.length > 25) {
        validated.data = validated.data.slice(0, 25)
      }
      // Save in local storage
      storage.set(`lastSeen.${this.context.user.id}`, validated.data)
    } else {
      storage.set(`lastSeen.${this.context.user.id}`, [
        {
          id: this.resident.id,
          seenAt: dayjs().toISOString(),
        },
      ])
    }
  }

  componentWillUnmount() {
    this.residentDisposer?.()
    dispose(this.disposers)
    // Search reads this entry. If resident record unmount happend less than 200ms before search mount,
    // it is assumed that user navigated from resident record to search. In this case search will start
    // with the last search request and fire it right off. (Only if case record was initially called from search)
    storage.set('residents.record', {
      id: this.resident.id,
      unmountedAt: new Date().toISOString(),
      fromSearch: this.props.fromSearch,
    })
  }

  render() {
    // Module permissions are checked in Route render function that renders this component.
    // This way the permissions can be turned on/off live and tree can be loaded on mount.

    if (this.resident.error?.id === NotFoundError.id) {
      return (
        <div className='pt-14 bg-gray-100 min-h-full flex overflow-hidden'>
          <NotFound
            title='Bewohner konnte nicht gefunden werden'
            subtitle={`JBP-ID ${this.props.hash.toUpperCase()} nicht in der Datenbank vorhanden`}
          />
        </div>
      )
    }

    if (this.resident.error && this.resident.error.id !== RequestPendingError.id) {
      return (
        <div className='pt-14 bg-gray-100 min-h-full flex overflow-hidden'>
          <LoadingError
            title='Bewohner konnte nicht geladen werden'
            subtitle='Bitte wenden Sie sich an einen Administrator'
          />
        </div>
      )
    }

    if (!this.resident.data) {
      return <Spinner className='pt-14 bg-gray-100 min-h-full' delay />
    }

    return (
      <div className='pt-14 bg-gray-100 min-h-full flex' style={{ minWidth: 992 }}>
        {/* Menu. Resident is subscribed but data could be still loading */}
        <CaseRecordMenu
          familyId={this.resident.data.familyId}
          resident={this.resident.data}
          key={`m-${this.resident.data.familyId}`}
          fromSearch={this.props.fromSearch}
        />

        {/* Spacer */}
        <div className={styles.spacer}>
          <div className='top-14 sticky'>
            <TitleBar />
          </div>
        </div>

        {/* Tabs and Content */}
        {this.resident.data && (
          <CaseRecordBody
            key={`c-${this.resident.id}-${this.resident.data.familyId}`}
            resident={this.resident.data}
          />
        )}

        {/* Invalid url. Subscription not requested because url cannot be converted to residentId: number */}
        {/*!this.residentSubscription && <CaseRecordError type='invalid url' />*/}

        {/* Subscription error. Person not found or timeout */}
        {/*error && <CaseRecordError type={error} />*/}

        {/* Spacer */}
        <div className={styles.spacer}>
          <div className='top-14 sticky'>
            <TitleBar />
          </div>
        </div>
      </div>
    )
  }
}
