import { hermes } from '@byll/hermes'
import { HomeIcon } from '@heroicons/react/outline'
import { Callout } from 'components/Callout'
import { Message } from 'components/Message'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { TopBar } from 'components/SideBarLayout/components/TopBar'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { isStammBuilding } from 'helpers/isStamm'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {}

interface DisplayCompound {
  id: string
  label: string
  buildings: IBuilding[]
}

@observer
export class AccountStammBuildings extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private compounds: DisplayCompound[] | null = null
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.load()
  }

  private load = async () => {
    try {
      const rows =
        hermes.indexFromStore<ICompound>(
          `/api/${this.context.instance.id}/accommodations/compounds`,
        ) ?? []
      const compounds: DisplayCompound[] = []
      const compoundMap = new Map<string, DisplayCompound>()
      for (const row of rows) {
        const compound: DisplayCompound = {
          id: row.id,
          label: row.label,
          buildings: [],
        }
        compoundMap.set(compound.id, compound)
        compounds.push(compound)
      }

      const buildings = await hermes.indexOnceNew<IBuilding>(
        `/api/${this.context.instance.id}/accommodations/buildings`,
      )
      buildings.sort((a, b) => a.label.localeCompare(b.label))
      for (const building of buildings) {
        if (compoundMap.has(building.compoundId) && isStammBuilding(building.id)) {
          compoundMap.get(building.compoundId)!.buildings.push(building)
        }
      }
      setTimeout(
        action(() => {
          this.compounds = compounds.filter((c) => c.buildings.length > 0)
          if (this.compounds.length === 0) {
            this.error = 'Es liegen keine passenden Gebäude vor.'
            this.compounds = null
          }
        }),
        200,
      )
    } catch (_e) {
      runInAction(() => (this.error = 'Die Gebäudeliste konnte nicht geladen werden.'))
    }
  }

  render() {
    return (
      <div className='md:pl-80 flex flex-col flex-1 min-h-screen'>
        <TopBar>
          <ol className='bg-white px-2 flex'>
            <li className='flex'>
              <div className='flex items-center text-gray-600'>
                <HomeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
                <span className='ml-2'>{`${this.context.user.firstName} ${this.context.user.lastName}`}</span>
              </div>
            </li>
            <li className='flex'>
              <div className='flex items-center'>
                <svg
                  className='flex-shrink-0 h-5 w-5 text-gray-300'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                >
                  <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                </svg>
                Unterkünfte
              </div>
            </li>
          </ol>
        </TopBar>
        <CardColumn>
          <CardColumnCard
            title='Stammgebäude'
            subtitle='Unterkünfte in Ihrem Verantwortungsbereich'
            className='min-h-[380px]'
          >
            {this.error && <Message color='danger'>{this.error}</Message>}
            {this.compounds?.length === 0 && (
              <div className='absolute rounded-md bg-gray-100 border border-gray-300 bottom-0 right-0 left-0 top-6 flex overflow-hidden'>
                <Callout icon='fas fa-home' title='Keine Stammgebäude zugeordnet' />
              </div>
            )}
            <div className='grid grid-cols-2 gap-2'>
              {this.compounds?.map((c) => (
                <div
                  key={c.id}
                  className='p-4 rounded-md shadow-md bg-slate-100 border border-slate-300'
                >
                  <div>{c.label}</div>
                  <div className='border-b border-gray-300 my-2 -mx-4' />
                  <div>
                    {c.buildings.map((b) => (
                      <div key={b.id} className='text-gray-600'>
                        {b.label}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </CardColumnCard>
        </CardColumn>
      </div>
    )
  }
}
