import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'

export class Scheckkarte extends React.Component<ResidentCardProps, {}> {
  render() {
    return (
      <div
        className={styles.page}
        style={this.props.batch ? {} : { pageBreakAfter: 'always' }}
      >
        <div className='flex'>
          <div style={{ position: 'absolute', top: 8, left: 8, width: 144 }}>
            <img
              src={getResidentImageSrc(
                this.props.instanceId,
                this.props.resident.imageId,
                this.props.resident.sex,
                'portrait',
              )}
              alt='Bewohnerbild'
            />
            <div
              style={{
                fontWeight: 'bolder',
                fontSize: 10,
                marginTop: 8,
                paddingBottom: 0,
              }}
            >
              <div>{this.props.resident.data.accommodation?.building?.street}</div>
              <div>
                {this.props.resident.data.accommodation?.building?.zip +
                  ' ' +
                  this.props.resident.data.accommodation?.building?.city}
              </div>
            </div>
          </div>
          <div>
            <div className='absolute pl-2' style={{ top: 8, left: 152 }}>
              <div style={{ height: 30, fontSize: 30 }}>
                <b>Hausausweis</b>
              </div>
              <div style={{ marginTop: 8, fontSize: 10 }}>
                <b>{this.props.resident.data.accommodation?.building?.label || ''}</b>
              </div>
            </div>

            <div
              className='absolute flex flex-col overflow-hidden pl-2'
              style={{ left: 152, top: 55, right: 8, fontSize: 12, lineHeight: '12px' }}
            >
              <div className='flex' style={{ marginTop: 16 }}>
                <div className='flex-content overflow-hidden mr-1' style={{ width: 138 }}>
                  <div style={{ fontSize: 8, marginRight: 40 }}>Name/Surname/Nom</div>
                  <div>{this.props.resident.lastName.toUpperCase()}&nbsp;</div>
                </div>
                <div className='flex-auto'>
                  <div style={{ fontSize: 8 }}>ID</div>
                  <div>{toJbpId(+this.props.resident.id)}</div>
                </div>
              </div>
              <div>
                <div style={{ fontSize: 8, marginTop: 8 }}>
                  Vorname/Given Names/Prénoms
                </div>
                <div>{this.props.resident.firstName}&nbsp;</div>
              </div>
              <div>
                <div style={{ fontSize: 8, marginTop: 8 }}>
                  Geburtsdatum/Date of Birth/Date de naissance
                </div>
                <div>
                  {this.props.resident.dateOfBirth
                    ? dayjs(this.props.resident.dateOfBirth).format('DD.MM.YYYY')
                    : ' -'}
                  &nbsp;
                </div>
              </div>
              <div>
                <div style={{ fontSize: 8, marginTop: 8 }}>
                  Staatsangehörigkeit/Nationality/Nationalité
                </div>
                <div>
                  {this.props.resident.data.nationality?.toUpperCase() || ' -'}&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
