import { observable, runInAction } from 'mobx'
import * as React from 'react'

export const numberOfPrompts = observable.box<number>(0)

interface Props {}

export const PreventRouteChange: React.FC<Props> = () => {
  React.useEffect(() => {
    runInAction(() => numberOfPrompts.set(numberOfPrompts.get() + 1))
    return () => {
      runInAction(() => runInAction(() => numberOfPrompts.set(numberOfPrompts.get() - 1)))
    }
  }, [])
  return null
}
