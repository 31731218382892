import * as React from 'react'
import { Collection, hermes } from '@byll/hermes'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import { FolderIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { Message } from 'components/Message'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Button } from 'components/Form/components/Button'
import folderIcon from '../images/folder.png'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  document: IDocumentMetadata
  folders: Collection<IDocumentFolder>
  hasReponsibilityInStammcompound: boolean
  onClose: () => void
}

@observer
export class MoveDocumentDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null
  @observable private loading: boolean = false
  @observable private selectedFolder: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private onSave = async () => {
    try {
      if (!this.selectedFolder) {
        this.error = 'Bitte wählen Sie einen Ordner aus.'
        return
      }
      runInAction(() => (this.loading = true))
      await hermes.patch(
        `/api/${this.context.instance.id}/documents/metadata/${this.props.document.id}`,
        {
          folderId: this.selectedFolder,
        },
      )
      this.props.onClose()
    } catch (_e) {
      this.error =
        'Beim Verschieben des Dokuments ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Systemadministrator.'
    }
  }

  render() {
    if (!this.props.folders.resources) {
      return null
    }
    return (
      <div>
        <div className='sm:flex sm:items-start'>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-indigo-100`}
          >
            <FolderIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />
          </div>
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-auto'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Dokument verschieben
            </Dialog.Title>
            <div className='mt-3 text-sm text-gray-500 relative overflow-scroll'>
              {this.error && (
                <Message color='danger' className='mb-2'>
                  {this.error}
                </Message>
              )}
              {!this.error && (
                <Message color='primary' className='mb-2'>
                  Wählen Sie einen Ordner aus, in den das Dokument verschoben werden soll.
                </Message>
              )}
              {this.props.folders.resources &&
                this.props.folders.resources.filter(
                  (f) => f.id !== this.props.document.folderId,
                ).length === 0 && (
                  <div className='mt-4'>Es existieren keine weiteren Ordner.</div>
                )}
              {this.props.folders.resources &&
                this.props.folders.resources.map((folder) => {
                  if (!folder.data || this.props.document.folderId === folder.id) {
                    return null
                  }
                  const hasCreatePermission =
                    folder.data.visible &&
                    (folder.data.uploadDocuments === 2 ||
                      (folder.data.uploadDocuments === 1 &&
                        this.props.hasReponsibilityInStammcompound))
                  const disabled = !folder.data.visible || !hasCreatePermission
                  return (
                    <div
                      key={folder.id}
                      className={`${
                        this.selectedFolder === folder.id
                          ? 'border-2 border-indigo-500 bg-gray-100 p-1.5'
                          : `${
                              disabled ? 'cursor-not-allowed' : 'hover:bg-indigo-100'
                            } bg-gray-100 p-2`
                      } mt-4 h-[41px] max-w-[408px] truncate rounded-md transition-height duration-500 ease-in-out relative`}
                    >
                      <div
                        className={`flex items-center ${
                          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        onClick={action(() => {
                          if (!disabled) {
                            this.selectedFolder = folder.id
                          }
                        })}
                      >
                        <div className='flex-content'>
                          <img src={folderIcon} style={{ height: 25 }} alt='Ordner' />
                        </div>
                        <span className='px-2'>{folder.data.label}</span>
                        {!folder.data.visible && (
                          <span className='text-red-500 ml-2'>
                            <i className='fas fa-lock' />
                          </span>
                        )}
                        {folder.data.visible && !hasCreatePermission && (
                          <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-500 text-white'>
                            Keine Uploadberechtigung
                          </span>
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div className='p-4 float-right'>
          <Button color='secondary' outline onClick={() => this.props.onClose()}>
            Abbrechen
          </Button>
          <Button className='ml-4' onClick={this.onSave} loading={this.loading}>
            Verschieben
          </Button>
        </div>
      </div>
    )
  }
}
