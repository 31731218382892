import { observer } from 'mobx-react'
import * as React from 'react'
import { IImageSection } from '../helpers/getImageSection'
import { getMinDimensions } from '../helpers/getMinDimensions'
import { getSectionFromStyle } from '../helpers/getSectionFromStyle'
import { getStyleFromSection } from '../helpers/getStyleFromSection'

interface Props {
  image: {
    id: string
    src: string
    width: number
    height: number
    section: IImageSection
  }
  width: number
  height: number
  onSelect: (src: string, width: number, height: number, section?: IImageSection) => void // new image id
  onCancel: () => void
}

@observer
export class EditImage extends React.Component<Props, {}> {
  private elem = React.createRef<HTMLImageElement>()
  private readonly style: { left: number; top: number; width: number; height: number }
  private readonly original: { width: number; height: number }
  private readonly target: { width: number; height: number }
  private offset: { x: number; y: number } | null = null

  constructor(props) {
    super(props)
    const cache = props.image
    this.original = { width: props.image.width, height: props.image.height }
    this.target = { width: props.width, height: props.height }
    this.style = getStyleFromSection(cache.section, this.original, this.target)
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('mouseup', this.onMouseUp)
    if (this.elem.current) {
      this.elem.current.parentElement!.addEventListener('wheel', this.onWheel)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)
    if (this.elem.current) {
      this.elem.current.parentElement!.removeEventListener('wheel', this.onWheel)
    }
  }

  private onMouseDown = (e) => {
    if (!this.elem.current) {
      return
    }
    const elemRect = this.elem.current.getBoundingClientRect()
    this.offset = {
      x: e.nativeEvent.pageX - elemRect.left,
      y: e.nativeEvent.pageY - elemRect.top,
    }
    e.preventDefault()
  }

  private onMouseUp = (e) => {
    this.onMouseMove(e)
    this.offset = null
  }

  private onMouseMove = (e) => {
    if (!this.offset || !this.elem.current) {
      return
    }
    const parentRect = this.elem.current.parentElement!.getBoundingClientRect()
    let left = e.pageX - this.offset.x - parentRect.left
    let top = e.pageY - this.offset.y - parentRect.top
    if (left + this.style.width < this.target.width) {
      left = this.target.width - this.style.width
    }
    if (left > 0) {
      left = 0
    }
    if (top + this.style.height < this.target.height) {
      top = this.target.height - this.style.height
    }
    if (top > 0) {
      top = 0
    }

    this.style.left = left
    this.style.top = top
    this.elem.current.style.left = this.style.left + 'px'
    this.elem.current.style.top = this.style.top + 'px'
  }

  private onPlus = () => this.zoom(1.1)
  private onMinus = () => this.zoom(0.9)
  private onWheel = (e) => {
    e.preventDefault()
    this.zoom(e.deltaY < 0 ? 1.05 : 0.95)
  }

  private zoom = (factor: number) => {
    if (!this.elem.current) {
      return
    }
    if (this.style.width > this.target.width * 10 && factor > 1) {
      return
    }
    let left =
      (this.style.left - this.target.width * 0.5) * factor + 0.5 * this.target.width
    let top =
      (this.style.top - this.target.height * 0.5) * factor + 0.5 * this.target.height
    let width = this.style.width * factor
    let height = this.style.height * factor
    if (left > 0) {
      left = 0
    }
    if (top > 0) {
      top = 0
    }
    const { minWidth, minHeight } = getMinDimensions(this.original, this.target)
    if (height < minHeight) {
      height = minHeight
      width = minWidth
    }
    if (height + top < this.target.height) {
      top = this.target.height - height
    }
    if (width + left < this.target.width) {
      left = this.target.width - width
    }

    this.style.left = left
    this.style.top = top
    this.style.width = width
    this.style.height = height
    this.elem.current.style.left = this.style.left + 'px'
    this.elem.current.style.width = this.style.width + 'px'
    this.elem.current.style.top = this.style.top + 'px'
    this.elem.current.style.height = this.style.height + 'px'
  }

  private onSelect = () => {
    const section = getSectionFromStyle(this.style, this.original, this.target)
    this.props.onSelect(
      this.props.image.src,
      this.props.image.width,
      this.props.image.height,
      section,
    )
  }

  render() {
    return (
      <div className='absolute top-0 left-0 right-0 bottom-0'>
        <img
          alt=''
          style={{ ...(this.style as any) }}
          src={this.props.image.src}
          onMouseDown={this.onMouseDown}
          ref={this.elem}
        />
        <div
          className='absolute flex'
          style={{ bottom: 10, height: 30, width: this.props.width, left: 0 }}
        >
          <div className='flex-content mr-auto'>
            <button
              onClick={this.onMinus}
              className='bg-white border-r border-gray-100 hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-l-full text-sm px-2'
              style={{ height: 30, lineHeight: '30px', marginLeft: 10 }}
            >
              <i className='fas fa-minus' />
            </button>
            <button
              onClick={this.onPlus}
              className='bg-white hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-r-full text-sm px-2'
              style={{ height: 30, lineHeight: '30px' }}
            >
              <i className='fas fa-plus' />
            </button>
          </div>

          <button
            onClick={this.props.onCancel}
            className='bg-white hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-full text-sm px-2'
            style={{ height: 30, lineHeight: '30px', marginRight: 10 }}
          >
            Abbrechen
          </button>
          <button
            onClick={this.onSelect}
            className='bg-white hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-full text-sm px-2'
            style={{ height: 30, lineHeight: '30px', marginRight: 10 }}
          >
            Speichern
          </button>
        </div>
      </div>
    )
  }
}
