import { Model } from 'components/Form/Model'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'
import { observer } from 'mobx-react'
import * as React from 'react'
import { RoomBuildingFilter } from './filters/RoomBuildingFilter'
import { RoomFloorFilter } from './filters/RoomFloorFilter'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { IOccupancyMetadata } from 'contracts/occupancies/interfaces/IOccupancyMetadata'
import { AppContext } from 'services/connection/models/AppContext'
import { RoomLockedFilter } from './filters/RoomLockedFilter'
import { RoomOccupiedFilter } from './filters/RoomOccupiedFilter'
import { RoomEquipmentFilter } from './filters/RoomEquipmentFilter'
import { RoomTypeOfUseFilter } from './filters/RoomTypeOfUseFilter'
import { Spinner } from 'components/Spinner'
import { OccupancyRoomList } from './OccupancyRoomList'
import { PlannedTransfers } from './PlannedTransfers'
import { ResidentOccupancySearchStats } from './filters/ResidentOccupancySearchStats'
import { BuildingFloorPlan } from './FloorPlan/BuildingFloorPlan'
import { BuildingFloorPlanPlaceholder } from './FloorPlan/BuildingFloorPlanPlaceholder'

interface Props {
  at: string
  compoundId: string
  filter: Model<IOccupancyFilter>
  occupancies: UnsyncedCollection<IOccupancy, IOccupancyMetadata, IOccupancyFilter>
}

export const OccupancyViews: React.FC<Props> = observer(
  ({ at, compoundId, filter, occupancies }) => {
    const context = React.useContext(AppContext)
    const isTileView = filter.values.view.startsWith('tile')
    const isLoading =
      !occupancies.resources ||
      !occupancies.metadata ||
      occupancies.metadata.compoundId !== occupancies.query.compoundId ||
      occupancies.metadata.buildingId !== occupancies.query.buildingId ||
      occupancies.metadata.buildingId === 'none'
    let view: 'tile' | 'tile+transfer' | 'plan' = filter.values.view
    if (
      view === 'tile+transfer' &&
      !context.permissions.resident_accommodation_plannedTransfers
    ) {
      view = 'tile'
    }

    return (
      <>
        {/* Dropdown filters */}
        <div className='px-4 md:px-6 py-3 flex text-sm text-gray-500 flex-wrap bg-gray-100'>
          <RoomBuildingFilter key={compoundId} model={filter} />
          <RoomFloorFilter
            key={`${filter.values.buildingId}-${view}`}
            model={filter}
            onlyOneFloor={!isTileView}
          />
          {isTileView && <RoomLockedFilter model={filter} />}
          {isTileView && <RoomOccupiedFilter model={filter} />}
          {isTileView && <RoomEquipmentFilter model={filter} />}
          {isTileView && <RoomTypeOfUseFilter model={filter} />}
          {view === 'tile' && <ResidentOccupancySearchStats results={occupancies} />}
        </div>

        {/* Tile view */}
        {view === 'tile' && (
          <div
            className='flex-auto bg-gray-100 relative text-sm'
            style={{ lineHeight: '30px' }}
          >
            {isLoading && <Spinner />}
            {!isLoading && (
              <OccupancyRoomList
                occupancies={occupancies}
                key={`${occupancies.query.compoundId}/${occupancies.query.buildingId}`}
              />
            )}
          </div>
        )}

        {/* Transfer view */}
        {view === 'tile+transfer' && (
          <div className='flex-auto relative bg-gray-100'>
            <div
              className='absolute top-0 left-0 right-[300px] bottom-0 text-sm'
              style={{ lineHeight: '30px' }}
            >
              {isLoading && <Spinner />}
              {!isLoading && (
                <OccupancyRoomList
                  occupancies={occupancies}
                  key={`${occupancies.query.compoundId}/${occupancies.query.buildingId}`}
                />
              )}
            </div>
            <PlannedTransfers buildingId={occupancies.query.buildingId} />
          </div>
        )}

        {/* Building plan view */}
        {view === 'plan' && (
          <div className='flex-auto bg-gray-100 relative'>
            {filter.values.buildingId &&
            filter.values.buildingId !== 'none' &&
            filter.values.floorIds ? (
              <BuildingFloorPlan
                key={`${compoundId}/${filter.values.buildingId}/${filter.values.floorIds}/${at}`}
                compoundId={compoundId}
                buildingId={filter.values.buildingId}
                floorId={filter.values.floorIds}
                at={at}
              />
            ) : (
              <BuildingFloorPlanPlaceholder />
            )}
          </div>
        )}
      </>
    )
  },
)
