import * as React from 'react'
import { Dialog } from 'components/Dialog'
import { observer } from 'mobx-react'
import { makeObservable, observable, runInAction } from 'mobx'
import { Collection, hermes, Resource } from '@byll/hermes'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { Age } from 'components/Age'
import { sleep } from 'helpers/sleep'
import { Spinner } from 'components/Spinner'
import { XIcon } from '@heroicons/react/outline'
import { InputText } from 'components/Form/components/InputText'
import { Button } from 'components/Form/components/Button'
import { Dialog as UIDialog } from '@headlessui/react'
import { Message } from 'components/Message'

interface Props {
  familyIds: Set<string> | null // already linked family ids
  onClose: (open: boolean) => void
}

@observer
export class AddFamilyDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private saving = false
  @observable private error: string | null = null
  private readonly residents: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly model: Model<{
    searchString: string
    page: string
    fields: string
    responsibleCompoundId: string | null
  }>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      searchString: '',
      page: '0,5',
      fields: 'nationality,familyId',
      responsibleCompoundId: context.permissions.family_linking === 3 ? null : '0',
    })
    this.residents = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      this.model.values,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.residents.init({ readOnly: true, observeQuery: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private searchResultMapper = (res: Resource<IResidentSearchResult>) => {
    const resident = res.data
    if (!resident) {
      return null
    }
    const thumbnail = getResidentImageSrc(
      this.context.instance.id,
      resident.imageId,
      resident.sex,
      'thumbnail',
    )
    return (
      <tr key={resident.id}>
        <td className='px-6 py-4 whitespace-nowrap'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <img className='h-10 w-10 rounded-full' src={thumbnail} alt='' />
            </div>
            <div className='ml-4'>
              <div
                className='text-sm font-medium text-gray-900 truncate'
                style={{ maxWidth: 280 }}
              >
                {`${resident.lastName.toUpperCase()}, ${resident.firstName}`}
                {resident.data.nationality && (
                  <span className='text-gray-400'>
                    &nbsp;&nbsp;·&nbsp;&nbsp;{resident.data.nationality || '-'}
                  </span>
                )}
              </div>
              {!this.props.familyIds?.has(resident.data.familyId || '') && (
                <a
                  onClick={(e) => {
                    e.preventDefault()
                    this.addFamily(resident.data.familyId || '')
                  }}
                  className='text-sm text-indigo-500 hover:underline cursor-pointer'
                  href={`/residents/${toJbpId(+resident.id).toLowerCase()}/overview`}
                >
                  Familie verknüpfen
                </a>
              )}
              {this.props.familyIds?.has(resident.data.familyId || '') && (
                <span className='px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white'>
                  Bereits verknüpft
                </span>
              )}
            </div>
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap'>
          <div className='text-sm text-gray-900'>
            <Age sex={resident.sex} dateOfBirth={resident.dateOfBirth} />
          </div>
          <div className='text-sm text-gray-500'>&nbsp;</div>{' '}
          {/* There could be another info */}
        </td>
      </tr>
    )
  }

  private addFamily = async (familyId: string) => {
    if (!this.props.familyIds) {
      return
    }
    try {
      runInAction(() => (this.saving = true))
      await hermes.create(`/api/${this.context.instance.id}/familyGroups`, {
        familyIds: [...Array.from(this.props.familyIds.values()), familyId],
      })
      // Wait for residents collection in menu to be updated, so that new resident is already
      // available on navigation. This way, the dialog closes smoothly without remount
      // (otherwise: loading spinner -> remount of menu -> remount of dialog). Of course
      // this only works statistically, because resource might not have updated within 400ms.
      // But it is only a nice to have that is not necessary for functionality.
      // It only takes care of smooth dialog transition.
      await sleep(400)
      this.dismiss()
    } catch (e) {
      runInAction(() => {
        this.saving = false
        this.error = 'Die Familie konnte nicht verknüpft werden.'
      })
    }
  }

  private dismiss = () => this.props.onClose(false)

  render() {
    const emptySearchRequest = this.model.values.searchString.length === 0
    return (
      <Dialog open={true} setOpen={this.props.onClose} size='md'>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.dismiss}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <UIDialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                Familie verknüpfen
              </UIDialog.Title>
            </div>
          </div>
        </div>

        <div className='px-6 pb-6 min-h-[574px]'>
          {this.saving && (
            <div className='relative p-4 mb-3 mt-6' style={{ minHeight: 535 }}>
              <Spinner />
            </div>
          )}

          {!this.saving && (
            <>
              {/* Error message */}
              {this.error && (
                <Message color='danger' className='mt-6'>
                  {this.error}
                </Message>
              )}

              {/* Search bar */}
              <div className='mt-6 mb-3 relative' id={this.model.id}>
                <InputText
                  model={this.model}
                  name='searchString'
                  placeholder='Name oder ID des Haushaltsvorstandes'
                  style={{ paddingLeft: 32 }}
                />
                <i
                  className='fas fa-search absolute text-gray-500'
                  style={{ top: 11, left: 11 }}
                />
              </div>

              {/* Search has not started yet */}
              {emptySearchRequest && (
                <Message color='primary' className='mt-6'>
                  Suchen Sie nach dem Haushaltsvorstand einer Familie, um die Familie zu
                  diesem erweiterten Familienverbund hinzufügen.
                </Message>
              )}

              {/* Search results */}
              {!emptySearchRequest &&
                this.residents.resources &&
                this.residents.resources.length > 0 && (
                  <div className='flex flex-col mt-6'>
                    <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                      <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                        <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                          <table className='min-w-full divide-y divide-gray-200'>
                            <thead className='bg-gray-50'>
                              <tr>
                                <th
                                  scope='col'
                                  className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                >
                                  Name
                                </th>
                                <th
                                  scope='col'
                                  className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                >
                                  Alter
                                </th>
                              </tr>
                            </thead>
                            <tbody className='bg-white divide-y divide-gray-200'>
                              {this.residents.resources.map(this.searchResultMapper)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {/* Cancel & submit buttons */}
              <div className='flex mt-6'>
                <Button color='secondary' outline onClick={this.dismiss} className='mr-2'>
                  Abbrechen
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    )
  }
}
