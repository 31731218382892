import { hermes } from '@byll/hermes'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { Message } from 'components/Message'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import { IDocumentFolderPermission } from 'contracts/general/interfaces/IDocumentFolderPermission'
import { action, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { FolderPermissionRow } from './FolderPermissionRow'
import * as uuid from 'uuid'
import { box } from 'services/box'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { IRole } from 'contracts/users/interfaces/IRole'
import { Forbidden } from 'modules/ErrorPages/Forbidden'

interface Props {
  onClose: () => void
  folder: IDocumentFolder
}

@observer
export class FolderPermissions extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading = true
  @observable private error: string | null = null
  @observable private folderPermissions: IDocumentFolderPermission[] = []
  @observable private preventRouteChange = false
  private roles: InputSelectOption[] = []
  private disposer: Disposer | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.load()
  }

  componentWillUnmount(): void {
    this.disposer?.()
  }

  private load = async () => {
    runInAction(() => (this.loading = true))
    try {
      const [folderPermissions, roles] = await Promise.all([
        hermes.indexOnceNew<IDocumentFolderPermission>(
          `/api/${this.context.instance.id}/documents/folderPermissions?folderId=${this.props.folder.id}`,
        ),
        hermes.indexOnceNew<IRole>(`/api/${this.context.instance.id}/users/roles`),
      ])
      runInAction(() => {
        this.folderPermissions = folderPermissions
        this.roles = roles.map((role) => ({ label: role.label, value: role.id }))
        this.roles.unshift({ label: 'Bitte wählen...', value: null })
        this.error = null
        this.loading = false
      })
      this.disposer?.()
      this.disposer = reaction(
        () => toJS(this.folderPermissions),
        () => {
          this.preventRouteChange = true
          this.disposer?.()
        },
      )
    } catch (e) {
      runInAction(() => {
        this.folderPermissions = []
        this.error = 'Die Ordnerberechtigungen konnten nicht geladen werden.'
        this.loading = false
      })
    }
  }

  private onSubmit = async () => {
    const roleIds = new Set<string>()
    for (const folderPermission of this.folderPermissions) {
      if (!folderPermission.roleId) {
        box.alert('Rolle fehlt', 'Bitte wählen Sie für alle Rechte eine Rolle aus.')
        return
      }
      if (roleIds.has(folderPermission.roleId)) {
        box.alert(
          'Rolle doppelt',
          `Die Rolle ${this.roles.find((r) => r.value === folderPermission.roleId)
            ?.label} wird in mehreren Berechtigungen verwendet. Bitte löschen Sie Doppelungen vor dem Speichern.`,
        )
        return
      }
      roleIds.add(folderPermission.roleId)
    }
    try {
      runInAction(() => {
        this.loading = true
        this.preventRouteChange = false
      })
      await hermes.create(
        `/api/${this.context.instance.id}/documents/folderPermissions`,
        {
          folderId: this.props.folder.id,
          folderPermissions: this.folderPermissions,
        },
      )
      this.props.onClose()
    } catch (_e) {
      runInAction(() => {
        this.error = 'Die Ordnerberechtigungen konnten nicht gespeichert werden.'
        this.loading = false
      })
    }
  }

  private onDelete = (folderPermission: IDocumentFolderPermission) => {
    this.folderPermissions = this.folderPermissions.filter(
      (fp) => fp.id !== folderPermission.id,
    )
  }

  @action
  private addRow = () => {
    this.folderPermissions.push({
      id: uuid.v4(),
      roleId: null as any,
      folderId: this.props.folder.id,
      visible: true,
      downloadDocuments: 0,
      uploadDocuments: 0,
      deleteDocuments: 0,
    })
  }

  render() {
    if (!this.context.permissions.resident_changeDocumentPermissions) {
      return (
        <div className='h-[300px] -mt-[50px] relative pointer-events-none'>
          <Forbidden />
        </div>
      )
    }

    return (
      <>
        {this.error && (
          <Message color='danger' className='my-4 mx-6'>
            {this.error}
          </Message>
        )}
        {!this.error && (
          <Message color='primary' className='my-4 mx-6'>
            Für den Zugriff auf diesen Ordner gelten für jede Rolle unterschiedliche
            Berechtigungen, die Sie hier einstellen können.
          </Message>
        )}

        <div className='mx-6 mb-4'>
          {this.folderPermissions.map((fp) => (
            <FolderPermissionRow
              key={fp.id}
              folderPermission={fp}
              roles={this.roles}
              onDelete={this.onDelete}
            />
          ))}
          <div
            className='hover:bg-indigo-100 bg-gray-100 mt-3 px-4 py-2 rounded-md overflow-hidden relative cursor-pointer'
            onClick={this.addRow}
          >
            <span className='text-lg'>
              <i className='fas fa-plus text-gray-500 mr-2' />
            </span>
            Berechtigung hinzufügen
          </div>
        </div>

        <div
          className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button
            disabled={this.loading}
            color='secondary'
            outline
            onClick={this.props.onClose}
          >
            {'Abbrechen'}
          </Button>
          <Button
            disabled={this.loading}
            color='primary'
            className='ml-2'
            onClick={this.onSubmit}
          >
            Speichern
          </Button>
        </div>
        {this.preventRouteChange && <PreventRouteChange />}
        {this.loading && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
