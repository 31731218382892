import { InputSelectOption } from 'components/Form/components/InputSelect'

export const leistungsanspruchOptions: InputSelectOption[] = [
  { value: '', label: '' },
  { value: 'AsylbLG §2', label: 'AsylbLG §2' },
  { value: 'AsylbLG §3', label: 'AsylbLG §3' },
  { value: 'AsylbLG §2 (Selbstzahler)', label: 'AsylbLG §2 (Selbstzahler)' },
  { value: 'AsylbLG §3 (Selbstzahler)', label: 'AsylbLG §3 (Selbstzahler)' },
  { value: 'Statuswechsler', label: 'Statuswechsler' },
  { value: 'unbestimmt', label: 'unbestimmt' },
]
