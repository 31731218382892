import { Callout } from 'components/Callout'
import * as React from 'react'

export class NoCompanySelected extends React.Component<{}, {}> {
  render() {
    return (
      <div className='mx-4 mt-[72px] flex-auto mb-4 flex flex-col'>
        <div className='my-auto flex-content'>
          <Callout
            icon='fa fa-building'
            iconColor='#6b7280'
            title='Bitte wählen Sie eine Firma aus dem Menü'
          />
        </div>
      </div>
    )
  }
}
