import { getFileExtension } from 'modules/Documents/components/DocumentDropzone/helpers/getFileExtension'
import iconAny from '../images/any.png'
import iconAnyBig from '../images/any@2x.png'
import iconDoc from '../images/doc.png'
import iconDocBig from '../images/doc@2x.png'
import iconImg from '../images/img.png'
import iconImgBig from '../images/img@2x.png'
import iconPdf from '../images/pdf.png'
import iconPdfBig from '../images/pdf@2x.png'
import iconXls from '../images/xls.png'
import iconXlsBig from '../images/xls@2x.png'

export function getFileExtensionIcon(name: string, big = false): string {
  const ext = getFileExtension(name)
  switch (ext) {
    case 'bmp':
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'tif':
    case 'tiff':
      return big ? iconImgBig : iconImg
    case 'pdf':
      return big ? iconPdfBig : iconPdf
    case 'txt':
    case 'docx':
    case 'odt':
      return big ? iconDocBig : iconDoc
    case 'csv':
    case 'ods':
    case 'xlsx':
      return big ? iconXlsBig : iconXls
    default:
      return big ? iconAnyBig : iconAny
  }
}
