import * as React from 'react'
import styles from '../styles.module.scss'

interface Props {
  subtotal
}

export const StundenzettelSubtotal: React.FC<Props> = (props) => (
  <div className={styles.row}>
    <div className={styles.colDate} style={{ paddingLeft: 0 }} />
    <div className={styles.colLabel} />
    <div className={styles.colBreak}>Übertrag</div>
    <div className={styles.colHours}>{`${props.subtotal
      .toFixed(2)
      .replace('.', ',')} h`}</div>
  </div>
)
