import * as React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { AppConnecting } from './components/AppConnecting'
import { AppNotConnected } from './components/AppNotConnected'
import { AppStatus } from 'services/connection/helpers/getAppStatus'
import { box } from 'services/box'
import { env } from 'env'

const STATUS_NOT_AUTHENTICATED = 401

interface Props {
  appStatus: AppStatus
}

@observer
export class AppReconnect extends React.Component<Props, {}> {
  @observable private step: 'retry' | 'retry-with-min-duration-on-error' | 'error' =
    'retry'

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    box.close()
  }

  private onConnectionAvailable = () => {
    window.location.reload()
  }

  @action private onConnectionError = (error: any) => {
    if (!(error instanceof Error)) {
      this.step = 'error'
      return
    }

    try {
      // Logged out
      if ((error as any)?.response?.status === STATUS_NOT_AUTHENTICATED) {
        if (env.NODE_ENV === 'development') {
          // Clear session cookie in dev environment
          document.cookie = 'isession=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        }
        window.location.reload()
        return
      }
    } catch (_e) {
      /* Ignore */
    }

    this.step = 'error'
  }

  @action private onReconnect = () => {
    this.step = 'retry-with-min-duration-on-error'
  }

  render() {
    if (this.step === 'retry') {
      return (
        <AppConnecting
          onSuccess={this.onConnectionAvailable}
          onError={this.onConnectionError}
          appStatus={this.props.appStatus}
        />
      )
    } else if (this.step === 'retry-with-min-duration-on-error') {
      return (
        <AppConnecting
          onSuccess={this.onConnectionAvailable}
          onError={this.onConnectionError}
          minDurationOnError={1500}
          appStatus={this.props.appStatus}
        />
      )
    }
    return <AppNotConnected onClick={this.onReconnect} />
  }
}
