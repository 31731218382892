import { ICompanyExportLohnart } from './../interfaces/ICompanyExportLohnart'

export function hasCompanyExportLohnartScope(
  type: ICompanyExportLohnart['type'],
): boolean {
  return (
    type === 'stundenlohn' ||
    type === 'schichtzulage' ||
    type === 'bereitschaft' ||
    type === 'krankstunden bereitschaft' ||
    type === 'krankstunden schicht'
  )
}
