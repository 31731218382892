import * as React from 'react'
import { SideBar } from 'components/SideBarLayout/components/SideBar'
import { HomeIcon, KeyIcon, UserCircleIcon } from '@heroicons/react/outline'
import styles from '../styles.module.scss'
import { NavLink } from 'react-router-dom'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react-lite'

interface Props {}

const subNavigation = [
  { name: 'Profil', href: '/account/profile', icon: UserCircleIcon, current: true },
  { name: 'Sicherheit', href: '/account/security', icon: KeyIcon, current: false },
  { name: 'Unterkünfte', href: '/account/buildings', icon: HomeIcon, current: false },
  // { name: 'Mitteilungen', href: '/account/notifications', icon: BellIcon, current: false },
  // { name: 'Tarif', href: '#', icon: CreditCardIcon, current: false },
  // { name: 'Integrationen', href: '/account/integrations', icon: ViewGridAddIcon, current: false },
]

export const AccountSideBar: React.FC<Props> = observer(() => {
  const context = React.useContext(AppContext)
  return (
    <SideBar>
      <div className={styles.menu}>
        <div className='overflow-hidden rounded-full m-auto mt-9 w-[160px] h-[160px] relative'>
          <img
            className='w-[160px] absolute left-0 -top-[23px]'
            src={getUserImageSrc(
              context.instance.id,
              context.user.imageId,
              context.user.sex,
              'portrait',
            )}
            alt=''
          />
        </div>
      </div>
      <div className='p-3'>
        {subNavigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={(navData) =>
              navData.isActive
                ? 'bg-indigo-100 text-indigo-500 group flex items-center px-2 py-2 text-base font-medium rounded-md'
                : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-500 group flex items-center px-2 py-2 text-base font-medium rounded-md'
            }
          >
            <item.icon
              className='group-hover:text-indigo-500 mr-4 flex-shrink-0 h-6 w-6'
              aria-hidden='true'
            />
            {item.name}
          </NavLink>
        ))}
      </div>
    </SideBar>
  )
})
