import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Button } from 'components/Form/components/Button'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputGroups } from 'components/Form/components/InputGroups'
import { InputText } from 'components/Form/components/InputText'
import { InputTextDate } from 'components/Form/components/InputTextDate'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { RoundIcon } from 'components/RoundIcon'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { isStammCompound } from 'helpers/isStamm'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { GroupField } from 'modules/Groups/components/GroupField'
import { IDialogField } from 'modules/Groups/components/NewGroupDialog'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import * as uuid from 'uuid'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { hermes } from '@byll/hermes'
import * as qs from 'qs'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { IGroupField } from 'contracts/groups/interfaces/IGroupField'
import { IGroup } from 'contracts/groups/interfaces/IGroup'
import { dayjs } from 'helpers/dayjs'
import { toast } from 'react-toastify'
import { Item } from './components/Item'

const MAX_ALLOWED_GROUP_SIZE = 5000

interface Props {
  filter: IResidentSearchResultsFilter
  onClose: (id?: string) => void
}

@observer
export class AddToGroupDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<{
    compoundId: string | null
    groupId: string | null
    label: string
    deletedAfterYmd: string | null
  }> // label is only used if new group is created
  @observable private fields: IDialogField[] = []
  @observable private error: string | null = null
  @observable private loading = false
  @observable.ref private searchResults: IResidentSearchResult[] | null = null
  @observable private readonly selected = new Set<string>()
  private readonly disposers: Disposer[] = []
  private dialogRef: any

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      compoundId: context.defaults.responsibilityCompoundId,
      groupId: null,
      label: '',
      deletedAfterYmd: null,
    })
    if (
      context.permissions.menu_groups === 1 &&
      !isStammCompound(this.model.values.compoundId || '')
    ) {
      this.model.values.compoundId = null
    }
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.load()
    this.disposers.push(
      reaction(
        () => this.model.values.compoundId,
        () => (this.model.values.groupId = null),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
    this.dialogRef = null
  }

  private load = async () => {
    try {
      runInAction(() => (this.loading = true))
      const results = await hermes.indexOnceNew<IResidentSearchResult>(
        `/api/${this.context.instance.id}/residentSearchResults${qs.stringify(
          { ...this.props.filter, page: `0,${MAX_ALLOWED_GROUP_SIZE}` },
          { allowDots: true, addQueryPrefix: true, skipNulls: true },
        )}`,
      )
      runInAction(() => {
        this.searchResults = results
        this.loading = false
        if (results.length >= MAX_ALLOWED_GROUP_SIZE) {
          this.error = `Diese Suchanfrage enthält mehr als ${MAX_ALLOWED_GROUP_SIZE} Ergebnisse. Bitte verfeinern Sie die Suchanfrage und versuchen Sie es erneut.`
          this.searchResults = null
          return
        }
        if (results.length === 0) {
          this.error = `Diese Suchanfrage enthält keine Ergebnisse. Bitte verfeinern Sie die Suchanfrage und versuchen Sie es erneut.`
          this.searchResults = null
          return
        }
        // Preselect all results
        for (const result of results) {
          this.selected.add(result.id)
        }
      })
    } catch (_e) {
      runInAction(() => {
        this.loading = false
        this.error = 'Die Suchergebnisse konnten nicht geladen werden.'
      })
    }
  }

  private save = async () => {
    if (!this.model.values.compoundId || !this.model.values.groupId) {
      this.setError('Bitte wählen Sie eine Gruppe aus.')
      return
    }

    if (this.selected.size === 0) {
      this.setError('Bitte wählen Sie mindestens einen Bewohner aus.')
      return
    }

    if (this.model.values.groupId === 'new') {
      const fields: IGroupField[] = []
      for (const field of this.fields) {
        if (field.type === 'select') {
          // Trim, filter and deduplicate options
          const options = Array.from(
            new Set(
              (field.options || '')
                .split(',')
                .map((o) => o.substring(0, 255).trim())
                .filter(Boolean),
            ).values(),
          )
          fields.push({ ...(field as any), label: field.label.trim(), options })
        } else {
          fields.push({ ...(field as any), label: field.label.trim(), options: [] })
        }
      }

      const entry: Omit<IGroup, 'id' | 'version' | 'createdAt' | 'createdBy'> = {
        compoundId: this.model.values.compoundId,
        label: this.model.values.label.trim(),
        deletedAt: this.model.values.deletedAfterYmd
          ? dayjs(this.model.values.deletedAfterYmd).toISOString()
          : null,
        fields,
      }
      let error: string | null = null
      if (!entry.label) {
        error = 'Bitte geben Sie einen Namen für die neue Gruppe ein.'
      }
      if (
        fields.some(
          (f) => !f.label || !f.type || (f.type === 'select' && f.options.length === 0),
        )
      ) {
        error = 'Bitte füllen Sie alle Felder aus'
      }
      if (error) {
        this.setError(error)
        return
      }

      // Create group
      try {
        runInAction(() => (this.loading = true))
        const response = await hermes.create(
          `/api/${this.context.instance.id}/groups`,
          entry,
        )
        runInAction(() => {
          this.model.values.groupId = response.id
        })
      } catch (_e) {
        this.setError('Die Gruppe konnte nicht erstellt werden')
        return
      }
    }

    // Add residents to group
    try {
      runInAction(() => (this.loading = true))
      await hermes.update(
        `/api/${this.context.instance.id}/groups/${this.model.values.groupId}/participants/batch`,
        { residents: Array.from(this.selected.values()) },
      )
      toast.success('Die Bewohner wurden zu Ihrer Gruppe hinzugefügt.')
      this.props.onClose()
    } catch (_e) {
      this.setError('Die Bewohner konnten nicht zu dieser Gruppe hinzugefügt werden')
      return
    }
  }

  @action setError = (error: string | null) => {
    this.error = error
    this.loading = false
    this.scrollToTop()
  }

  private mapFields = (field: IDialogField) => (
    <GroupField key={field.id} field={field} onDelete={this.removeField} />
  )
  @action private addNewField = () => {
    this.fields.push({
      id: uuid.v4(),
      type: '',
      label: '',
      options: '',
    })
  }
  @action private removeField = (id: string) => {
    this.fields.splice(
      this.fields.findIndex((f) => f.id === id),
      1,
    )
  }
  @action toggleSelectAll = () => {
    if (this.selected.size === this.searchResults?.length) {
      this.selected.clear()
    } else {
      for (const result of this.searchResults || []) {
        this.selected.add(result.id)
      }
    }
  }

  private setRef = (ref: HTMLDivElement | null) => {
    if (!ref) {
      return
    }
    this.dialogRef = ref.parentElement?.parentElement?.parentElement ?? null
  }

  private scrollToTop = () => {
    if (this.dialogRef) {
      try {
        this.dialogRef.scrollTo({ top: 0, behavior: 'smooth' }) // .scrollTop = this.dialogRef.scrollHeight
      } catch (_e) {
        /* */
      }
    }
  }

  @action private toggleAll = () => {
    if (this.searchResults?.length === this.selected.size) {
      this.selected.clear()
    } else {
      for (const result of this.searchResults || []) {
        this.selected.add(result.id)
      }
    }
  }

  render() {
    const single = !!this.props.filter.residentIds

    return (
      <>
        <div className='absolute top-1 right-0 pt-4 pr-6' ref={this.setRef}>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='pb-4 pt-1 -mx-6 px-6'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {single
                  ? 'Bewohner zu einer Gruppe hinzufügen'
                  : 'Suchergebnisse zu einer Gruppe hinzufügen'}
              </Dialog.Title>
            </div>
          </div>
        </div>

        {/* Select or create group */}
        <div
          id={this.model.id}
          className='-mx-6 pt-2 pb-6 px-6 border-t border-b border-gray-200'
        >
          {this.error && (
            <Message color='danger' className='mb-6 mt-4'>
              {this.error}
            </Message>
          )}

          {(this.searchResults || this.loading) && (
            <div className='flex gap-4 mt-4'>
              <InputCompound
                label='Gelände'
                className={'flex-auto'}
                name={'compoundId'}
                model={this.model}
                onlyStamm={this.context.permissions.menu_groups === 1}
                saveResponsibility
              />
              <InputGroups
                label='Gruppe'
                preselect
                new={this.context.permissions.groups_create}
                className={'flex-[0_0_190px]'}
                name={'groupId'}
                model={this.model}
                compoundId={this.model.values.compoundId}
                key={this.model.values.compoundId}
              />
            </div>
          )}

          {this.model.values.groupId === 'new' && (
            <div>
              <div id={this.model.id} className='flex gap-4 my-5'>
                <InputText
                  name={'label'}
                  model={this.model}
                  label={'Gruppenname'}
                  className={'flex-auto'}
                />
                <InputTextDate
                  blurAction='clear-field-if-invalid-date'
                  name='deletedAfterYmd'
                  model={this.model}
                  label='Autmatisch archivieren am'
                  className='flex-[0_0_190px]'
                />
              </div>
              <div>{this.fields.map(this.mapFields)}</div>

              <div>
                <RoundIcon
                  icon='fa fa-plus'
                  onClick={this.addNewField}
                  style={{ marginRight: 7 }}
                />
                Neues Feld hinzufügen
              </div>
            </div>
          )}
        </div>

        {/* Select residents from search */}
        {this.model.values.groupId && (
          <div className='bg-gray-100 -mx-6 px-6 py-4 text-sm'>
            {!single && (
              <div className='px-3'>
                <input
                  type='checkbox'
                  className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded cursor-pointer relative -top-[1px]'
                  checked={this.searchResults?.length === this.selected.size}
                  onChange={this.toggleAll}
                />
                &nbsp;&nbsp;
                <span className='text-gray-800'>Alle</span>
              </div>
            )}

            {this.searchResults &&
              this.searchResults.map((r) => (
                <Item key={r.id} resident={r} selected={this.selected} />
              ))}
          </div>
        )}

        <div className='flex py-4 -mx-6 -mb-4 px-6 sticky bottom-0 bg-white border-t border-gray-200 text-right'>
          <Button
            color='secondary'
            className='ml-auto'
            outline
            onClick={() => this.props.onClose()}
          >
            {this.searchResults ? 'Abbrechen' : 'Schließen'}
          </Button>
          {this.searchResults && (
            <Button color='primary' className='ml-2' onClick={this.save}>
              Zu Gruppe hinzufügen
            </Button>
          )}
        </div>

        {this.loading && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
