import { Spinner } from 'components/Spinner'
import * as React from 'react'
import styles from '../styles.module.scss'

interface Props {
  percentage?: number | null // null: spinning progressbar, undefined: no progress bar
  width?: number
  height?: number
}

export const PdfLoading: React.FC<Props> = (props) => (
  <div
    className={`${styles.page} ${!props.width ? styles.empty : ''}`}
    style={props.width ? { width: props.width, height: props.height } : undefined}
  >
    <div className={styles.spinner}>
      {props.percentage !== undefined && <Spinner delay />}
    </div>
    {props.children}
  </div>
)
