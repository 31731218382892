import { hermes } from '@byll/hermes'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IScan } from 'contracts/residents/interfaces/IScan'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  resident: IResident
  date: string
}

@observer
export class LastScanList extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref scans: IScan[] | null = null
  @observable showMore = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    hermes
      .indexOnceNew<IScan>(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/scans?date=${this.props.date}`,
      )
      .then(action((data) => (this.scans = data)))
  }

  @action setShowMore = () => (this.showMore = true)

  private mapScan = (scan: IScan, i) => {
    if (!this.showMore && i > 2) {
      return null
    }
    return (
      <React.Fragment key={i}>
        <div className='truncate'>{dayjs(scan.scannedAt).format('HH:mm')}</div>
        <div className='truncate'>{scan.type}</div>
        <div className='truncate'>{`${scan.scannedBy.lastName}, ${scan.scannedBy.firstName}`}</div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className='mt-2 grid grid-cols-[60px_80px_1fr] gap-y-2'>
        {this.scans && this.scans.length > 0 && (
          <>
            <div className='truncate font-bold'>Zeit</div>
            <div className='truncate font-bold'>Vorgang</div>
            <div className='truncate font-bold'>Gescannt von</div>
            {this.scans.map(this.mapScan)}
            {!this.showMore && this.scans.length > 3 && (
              <div className='col-span-3'>
                <div
                  onClick={this.setShowMore}
                  className='inline-block pt-2 text-gray-500 hover:text-black underline text-sm cursor-pointer'
                >
                  Mehr anzeigen...
                </div>
              </div>
            )}
          </>
        )}
        {this.scans && this.scans.length === 0 && (
          <div className='col-span-3 text-gray-500'>
            Am {dayjs(this.props.date).format('DD.MM.YYYY')} gibt es keine Scans.
          </div>
        )}
      </div>
    )
  }
}
