import { ViewGridAddIcon } from '@heroicons/react/outline'
import { TopBar } from 'components/SideBarLayout/components/TopBar'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {}

export class AccountIntegrations extends React.Component<Props, {}> {
  static contextType = AppContext

  render() {
    return (
      <div className='md:pl-80 flex flex-col flex-1 min-h-screen'>
        <TopBar>
          <ol className='bg-white px-2 flex'>
            <li className='flex'>
              <div className='flex items-center text-gray-600'>
                <ViewGridAddIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
                <span className='ml-2'>{`${this.context.user.firstName} ${this.context.user.lastName}`}</span>
              </div>
            </li>
            <li className='flex'>
              <div className='flex items-center'>
                <svg
                  className='flex-shrink-0 h-5 w-5 text-gray-300'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                >
                  <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                </svg>
                Integrationen
              </div>
            </li>
          </ol>
        </TopBar>
        <div className='flex flex-auto mt-20'>
          <div className='flex-auto lg:flex-[0_0_708px] xl:flex-[0_0_908px] mx-4 sm:mx-6 lg:mx-auto flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
            <div className='hidden xl:block pr-12 pt-4 text-right flex-[0_0_200px]'>
              <span className='text-gray-900 text-lg'>Ausweise</span>
              <br />
              <span className='text-sm text-gray-400'>
                Kurzprofil, Scans und Ausweisdokumente
              </span>
            </div>

            <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
              Test
            </div>
          </div>
        </div>
      </div>
    )
  }
}
