import * as React from 'react'
import { observer } from 'mobx-react'
import { action, computed, makeObservable, observable } from 'mobx'
import debounce from 'lodash/debounce'
import { SyncState } from './helpers/getSyncState'
import { uniqueId } from 'helpers/uniqueId'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Button } from '../Button'
import { PreventRouteChange } from 'components/PreventRouteChange'

interface Props {
  onClose: () => void
  syncState: SyncState
  className?: string
  style?
  [key: string]: any
}

const captions = {
  patchError: `Nicht gespeichert`,
  patching: `Speichern`,
  patchOnBlur: `Nicht gespeichert`,
  noChanges: `Gespeichert`,
  patched: `Gespeichert`,
}

/*const popoverMessages = {
  patchError: `Beim Speichern Ihrer Änderungen ist ein Fehler aufgetreten.`,
  patching: `Ihre Änderungen werden gerade gespeichert`,
  patchOnBlur: `Ihre Änderungen werden automatisch gespeichert, sobald Sie das Feld verlassen.`,
  noChanges: `Sie haben aktuell keine Änderungen vorgenommen.`,
  patched: `Inuv hat Ihre Änderungen automatisch gespeichert.`,
}*/

@observer
export class LastSavedButton extends React.Component<Props, {}> {
  id = uniqueId('save-button-')
  disposers: Disposer[] = []
  setSyncStateDelayed: { (nextSyncState: SyncState): any; cancel: () => void } | null =
    null
  @observable hasMouseOver = false
  @observable syncStateDelayed: SyncState = 'noChanges'

  constructor(props: Props) {
    super(props)
    this.syncStateDelayed = props.syncState
    makeObservable(this)
  }

  componentDidMount() {
    this.setSyncStateDelayed = debounce(
      action((syncState: SyncState) => (this.syncStateDelayed = syncState)),
      400,
      { trailing: true },
    )
    this.disposers.push(() => {
      this.setSyncStateDelayed?.cancel()
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.syncState === this.props.syncState) {
      return
    }
    this.updateSyncState(this.props.syncState)
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @computed get color(): 'danger' | 'secondary' | 'success' | undefined {
    if (this.syncStateDelayed === 'patchError') {
      return 'danger'
    }
    if (this.syncStateDelayed === 'patching') {
      return 'secondary'
    }
    if (this.syncStateDelayed === 'patchOnBlur') {
      return undefined
    }
    return 'success'
  }

  onClose = () => {
    if (this.syncStateDelayed !== 'patched' && this.props.syncState !== 'noChanges') {
      return
    }
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  @action updateSyncState = (nextSyncState: SyncState) => {
    this.setSyncStateDelayed?.cancel()
    switch (nextSyncState) {
      case 'patching':
      case 'patchError':
      case 'patchOnBlur':
        this.syncStateDelayed = nextSyncState
        break
      case 'noChanges':
      case 'patched':
        if (this.syncStateDelayed === 'patching') {
          this.setSyncStateDelayed?.(nextSyncState)
        } else {
          this.syncStateDelayed = nextSyncState
        }
    }
  }

  @action onMouseOver = () => {
    this.hasMouseOver = true
  }

  @action onMouseOut = () => {
    this.hasMouseOver = false
  }

  render() {
    const { onClose, syncState, className, style, formModelStore, ...rest } = this.props

    const hasUnsavedChanges =
      this.props.syncState !== 'noChanges' && this.props.syncState !== 'patched'

    return (
      <Button
        id={this.id}
        onClick={this.onClose}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        color={this.color}
        className={this.props.className}
        loading={this.syncStateDelayed === 'patching'}
        outline={
          this.syncStateDelayed === 'patching' || this.syncStateDelayed === 'patchOnBlur'
        }
        style={this.props.style}
        {...rest}
      >
        <span className='last-saved-button-caption'>
          {captions[this.syncStateDelayed]}
        </span>
        &nbsp;
        {this.hasMouseOver &&
          (this.syncStateDelayed === 'noChanges' ||
            this.syncStateDelayed === 'patched') &&
          `· ${`Jetzt schließen`}`}
        {(this.syncStateDelayed === 'noChanges' || this.syncStateDelayed === 'patched') &&
          !this.hasMouseOver && (
            <span>
              <i className='fa fa-check fa-sm' />
            </span>
          )}
        {/*<Tooltip
        fade={false}
        color={'primary'}
        isOpen={this.hasMouseOver}
        target={this.id}
        placement='bottom'
      >
        {popoverMessages[this.syncStateDelayed]}
      </Tooltip>*/}
        {hasUnsavedChanges && <PreventRouteChange />}
      </Button>
    )
  }
}
