import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  selected: Set<string>
  id: string
  className?: string
  disabled?: boolean
  onChange?: (id: string, selected: boolean) => void
}

const onToggle = action((selected: Set<string>, id: string) => {
  if (selected.has(id)) {
    selected.delete(id)
  } else {
    selected.add(id)
  }
})

/**
 * This componenent uses a Set<> to determine checked-state.
 * selected.has(id) => checked. Otherwise unchecked. Set must be observable.
 * @param props
 * @returns
 */
export const InputListItemCheckbox: React.FC<Props> = observer(
  ({ selected, id, className, disabled, onChange }) => (
    <input
      type='checkbox'
      disabled={disabled}
      checked={selected.has(id)}
      onChange={() => {
        onToggle(selected, id)
        onChange?.(id, selected.has(id))
      }}
      className={`h-4 w-4 focus:ring-indigo-500 border-gray-300 rounded ${
        disabled ? 'text-gray-500 bg-gray-100' : 'text-indigo-600'
      } ${className || ''}`}
    />
  ),
)
