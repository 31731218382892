import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { getBuildingData } from '../Hausausweis/getBuildingData'
import { encodeBarcode } from 'modules/Pdf/helpers/encodeBarcode'

export class LEA extends React.Component<ResidentCardProps, {}> {
  render() {
    return (
      <div style={this.props.batch ? {} : { pageBreakAfter: 'always' }}>
        <div className={styles.page}>
          <div className='absolute top-2 left-2 right-2 pl-2' style={{ fontSize: 24 }}>
            Bewohnerausweis
          </div>
          <img
            src={getResidentImageSrc(
              this.props.instanceId,
              this.props.resident.imageId,
              this.props.resident.sex,
              'portrait',
            )}
            alt='Bewohnerbild'
            style={{
              position: 'absolute',
              top: 56,
              left: 16,
              width: 132,
              zIndex: 1,
              borderRadius: 2,
            }}
          />
          <div
            className='absolute flex flex-col overflow-hidden'
            style={{ left: 156, top: 55, right: 8, fontSize: 12, lineHeight: '12px' }}
          >
            <div>
              <div style={{ fontSize: 8 }}>
                Herkunftsland/Country of origin/Pays d'origine
              </div>
              <div style={{ fontSize: 14 }}>
                {this.props.resident.data.countryOfBirth}&nbsp;
              </div>
            </div>
            <div style={{ marginTop: 8 }}>
              <div style={{ fontSize: 8 }}>
                Geburtsdatum/Date of birth/Date de naissance
              </div>
              <div style={{ fontSize: 14 }}>
                {this.props.resident.dateOfBirth
                  ? dayjs(this.props.resident.dateOfBirth).format('DD.MM.YYYY')
                  : '-'}
                &nbsp;
              </div>
            </div>
          </div>
          <div
            className='absolute w-full'
            style={{
              left: 0,
              top: 120,
              right: 8,
              fontSize: 12,
              lineHeight: '12px',
              zIndex: 0,
              height: 110,
              backgroundColor: '#305F91',
              color: 'white',
            }}
          >
            <div
              className='absolute'
              style={{ left: 156, marginTop: 24, fontSize: 14, lineHeight: '15px' }}
            >
              <div>{this.props.resident.lastName.toUpperCase()}&nbsp;</div>
              <div className='mt-2'>{this.props.resident.firstName}&nbsp;</div>
            </div>
          </div>
        </div>
        <div className={styles.page}>
          <div
            className='w-full h-2/3'
            style={{ backgroundColor: '#305F91', color: 'white' }}
          >
            <div className='absolute top-4 left-4'>
              <div>
                <div style={{ fontSize: 8 }}>Unterkunft/Residence/l'hébergement</div>
                <div style={{ fontSize: 14 }}>
                  {getBuildingData(this.props.resident) || 'Nicht eingecheckt'}&nbsp;
                </div>
              </div>
              <div>
                <div style={{ fontSize: 8 }}>
                  Ausstellungsdatum/Date of issue/date de délivrance
                </div>
                <div style={{ fontSize: 14 }}>
                  {dayjs(new Date()).format('DD.MM.YYYY')}&nbsp;
                </div>
              </div>
              <div className='flex mt-2'>
                <div className='flex-content overflow-hidden' style={{ width: 120 }}>
                  <div style={{ fontSize: 8 }}>MigVIS ID</div>
                  <div style={{ fontSize: 14 }}>
                    {this.props.resident.data.migVisId || '-'}&nbsp;
                  </div>
                </div>
                <div className='flex-auto'>
                  <div style={{ fontSize: 8 }}>JBP ID</div>
                  <div style={{ fontSize: 14 }}>{toJbpId(+this.props.resident.id)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center h-1/3'>
            <div
              style={{
                fontFamily: 'Barcode',
                fontSize: 40,
                marginTop: -11,
                paddingBottom: 0,
                transform: 'scaleY(-2.3)',
              }}
            >
              {encodeBarcode(this.props.resident.token || 'EXAMPLE')}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
