import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import * as React from 'react'
import { observer } from 'mobx-react'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { InputDate } from 'components/Form/components/InputDate'
import { InputText } from 'components/Form/components/InputText'
import { action, makeObservable, reaction } from 'mobx'
import { dayjs } from 'helpers/dayjs'
import { InputAsyncMultiSelect } from 'components/Form/components/InputAsyncMultiSelect'
import { AppContext } from 'services/connection/models/AppContext'
import { RoundIcon } from 'components/RoundIcon'
import { Button } from 'components/Form/components/Button'
import { hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'

interface Props {
  todo: Model<
    ITodoSearchResult & {
      isDone: boolean
      assigneeIds: string[]
      watcherIds: string[]
      hasTodo: boolean
    }
  > // id: '' -> new item
}

@observer
export class DocumentationDialogTodo extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => this.props.todo.values.id,
        () => this.setReadConfirmation(),
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private setReadConfirmation = async () => {
    if (this.props.todo.values.id && !this.props.todo.values.readConfirmation) {
      await hermes.create(
        `/api/${this.context.instance.id}/todos/${this.props.todo.values.id}/todoReadConfirmations`,
        {},
      )
    }
  }

  @action
  private toggleTodo = () => {
    if (this.props.todo.values.isDone) {
      this.props.todo.values.isDone = false
      this.props.todo.values.doneDate = null
    } else {
      this.props.todo.values.isDone = true
      this.props.todo.values.doneDate = dayjs().format('YYYY-MM-DD')
    }
  }

  @action private toggleVisibility = () =>
    (this.props.todo.values.hasTodo = !this.props.todo.values.hasTodo)

  render() {
    const model = this.props.todo

    if (!model.values.hasTodo) {
      return (
        <div>
          {/* Title */}
          <div className='text-gray-400 uppercase text-xs mt-6 -mb-3'>Aufgabe</div>
          <div className='bg-white shadow-md h-[70px] cursor-pointer mt-4 pt-4 pl-2.5'>
            <Button color='secondary' outline onClick={this.toggleVisibility}>
              Aufgabe hinzufügen
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div className='rounded-sm shadow bg-white flex mt-6 relative' id={model.id}>
        <div className='flex-[0_0_268px] pt-11 pl-11 leading-7'>
          <div>Aufgabe</div>
          <div className='text-gray-400 text-xs'>
            Es gibt noch etwas
            <br />
            zu erledigen?
          </div>
        </div>
        <div className='flex-auto py-11 pr-6'>
          <div className='flex flex-col gap-4'>
            <div className='flex'>
              <div className='flex-content' style={{ paddingTop: 6 }}>
                <input
                  type='checkbox'
                  checked={model.values.isDone}
                  className={`rounded-full ${
                    model.values.isDone ? 'border-0' : 'border border-gray-300'
                  } h-5 w-5 text-green-500 mr-2`}
                  onChange={this.toggleTodo}
                />
              </div>
              <InputDate
                disabled={!model.values.isDone}
                className='flex-[0_0_100px]'
                model={model}
                name='doneDate'
                label='Erledigt am'
              />
              <InputDate
                className='flex-[0_0_100px] ml-4'
                model={model}
                name='dueDate'
                label='Fällig am'
              />
              <InputText
                className='flex-auto ml-4'
                model={model}
                name='label'
                label='Betreff'
              />
            </div>
            <InputTextarea
              model={model}
              name='notes'
              label='Aufgabenbeschreibung'
              rows={3}
            />
            <InputTextarea
              model={model}
              name='gateMessage'
              label='Bearbeitungsstand'
              rows={3}
            />
            <InputAsyncMultiSelect
              model={model}
              name='assigneeIds'
              label='Zu erledigen von'
              options={`/api/${this.context.instance.id}/usersAndRoles`}
            />
          </div>
        </div>

        <RoundIcon
          icon='fas fa-times'
          color='danger'
          style={{ position: 'absolute', top: -15, right: -15 }}
          tooltip='Aufgabe löschen'
          onClick={this.toggleVisibility}
        />
      </div>
    )
  }
}
