import * as React from 'react'
import { EmployeeCommunicationSidebar } from './components/EmployeeCommunicationSidebar'
import { Model } from 'components/Form/Model'
import { Callout } from 'components/Callout'
import { useLocation, useNavigate } from 'react-router-dom'
import { EmployeeCommunicationContent } from './components/EmployeeCommunicationContent'
const rx = /^\/employee\/communication\/([0-9]+)(?:\/([0-9]+))?$/

interface Props {}

export const EmployeeCommunication: React.FC<Props> = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const match = location.pathname.match(rx)
  const topicId = match?.[1] || undefined
  const threadId = match?.[2] || null
  const [filter] = React.useState(
    () =>
      new Model<{ companyId: string; page: string }>({ companyId: '', page: '0,100' }),
  )

  function onSelect(id: string) {
    if (!topicId) {
      return
    }
    navigate(`/employee/communication/${topicId}/${id}`)
  }

  return (
    <div className='min-h-screen bg-gray-100 flex pt-14'>
      {/* Side bar */}
      <div className='flex-[0_0_324px] relative'>
        <div className='absolute top-6 left-6 right-0 bottom-6 bg-white rounded-md shadow p-4 overflow-x-hidden overflow-y-auto'>
          <EmployeeCommunicationSidebar
            filter={filter}
            selected={topicId}
            navigate={navigate}
          />
        </div>
      </div>

      {/* Main content */}
      {topicId && (
        <EmployeeCommunicationContent
          key={topicId}
          topicId={topicId}
          threadId={threadId}
          onSelect={onSelect}
        />
      )}

      {/* Placeholder */}
      {!topicId && (
        <div className='flex-auto relative'>
          <div className='absolute top-6 left-6 right-6 bottom-6 bg-white rounded-md shadow overflow-x-hidden overflow-y-auto flex flex-col'>
            <Callout
              title='Gehaltszettel Vorschau'
              subtitle='Wählen Sie einen Monat aus.'
              icon='fas fa-file-invoice-dollar'
              className='mt-10'
            />
          </div>
        </div>
      )}
    </div>
  )
}
