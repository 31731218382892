import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import image1 from './images/image1.png'
import image2 from './images/image2.png'
import image3 from './images/image3.png'
import image4 from './images/image4.png'
import image5 from './images/image5.png'
import image6 from './images/image6.png'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { Wait } from 'modules/Pdf/components/Wait'
import axios from 'axios'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'

export const LfgbKenntnisnahmeHausordnung2022: React.FC<DocumentTemplateProps> = (
  props,
) => {
  const [residentSearchResult, setResidentSearchResult] =
    React.useState<IResidentSearchResult | null>(null)
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)
  React.useEffect(() => {
    const load = async () => {
      const response = await axios.get<any>(
        `/api/${props.resident.instanceId}/residentSearchResults?residentIds=${props.resident.id}&fields=bookings`,
      )
      if (
        !Array.isArray(response.data.resources) ||
        response.data.resources.length !== 1
      ) {
        throw new Error('Not found')
      }
      const stay = response.data.resources[0]?.data.bookings?.find(
        (b) => b.timeline === 'present' && b.type === 'internal-residence',
      )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cp = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setCompound(cp.data)
        setBuilding(bl.data)
      }
      setResidentSearchResult(response.data.resources[0])
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!residentSearchResult) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  const stay = residentSearchResult?.data?.bookings?.find(
    (b) => b.timeline === 'present' && b.type === 'internal-residence',
  )
  return (
    <>
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm mb-3'}`}>
        {/* Header */}
        <div className='absolute top-[40px] left-[100px] text-lg'>
          Landesbetrieb für Gebäudebewirtschaftung Berlin
          <br />
          Betriebsteil B (LfG-B)
          <br />
          {buildingLabel}
        </div>
        <img className={styles.logo} src={logo} alt='' />

        {/* Title */}
        <div className='text-2xl text-center mt-20'>
          1. Bestätigung der Kenntnisnahme der Hausordnung
        </div>
        <div className='italic'>
          Wichtige visualisierte Inhalte siehe Rückseite (zusammen mit der passenden
          Übersetzung zu verwenden)
        </div>

        {/* Text */}
        <div className='mt-12' style={{ fontSize: '16px' }}>
          <p>
            Mit meiner Unterschrift bestätige ich, {props.resident.firstName}{' '}
            {props.resident.lastName}, dass ich die in der Unterkunft{' '}
            {(stay?.label || '').split('/').shift()!.trim()} gültige Hausordnung erhalten,
            gelesen und zur Kenntnis genommen habe.
          </p>
          <p className='mt-8'>
            Ein*e Mitarbeiter*in hat mir die wichtigsten Punkte persönlich und
            verständlich erklärt. Ich habe verstanden, dass eine Nichteinhaltung der
            Hausordnung zu einer Ausweisung aus der Unterkunft führen kann.
          </p>
          <p className='mt-8'>
            Folgendes gilt für Erziehungsberechtigte mit minderjährigen Kindern und für
            Schwangere:
          </p>
          <div className='flex'>
            <div className='flex-[0_0_50px] text-lg -mt-1'>☐</div>
            <div className='flex-auto'>
              Darüber hinaus bestätige ich den Erhalt und die Kenntnisnahme des Dokuments
              der „Elterliche Sorge und Aufsichtspflicht“.
            </div>
          </div>
        </div>

        {/* Signature */}
        <div
          className='grid gap-16 grid-cols-3 mt-16 text-center'
          style={{ fontSize: '16px' }}
        >
          <div>
            <div className='border-b border-black truncate'>
              {dayjs().format('DD.MM.YYYY')}
            </div>
            <div>Datum</div>
          </div>

          <div>
            <div className='border-b border-black truncate'>&nbsp;</div>
            <div>Haushaltsvorstand</div>
          </div>

          <div>
            <div className='border-b border-black truncate'>&nbsp;</div>
            <div>{`${props.user.lastName}, ${props.user.firstName}`}</div>
          </div>
        </div>

        <div className='border-b border-black w-full mt-12' />

        {/* Title */}
        <div className='text-2xl text-center mt-20'>
          2. Bestätigung der Kenntnisnahme der Brandschutzordnung Teil B (objektbezogene
          Brandschutzbelehrung)
        </div>
        <div className='text-base mt-4'>
          Mit meiner Unterschrift bestätige ich, dass ich wichtige Hinweise zur
          Brandschutzordnung in der Unterkunft zur Kenntnis genommen und verstanden habe
          (z.B. Rauchverbot im Zimmer, keine unerlaubten Geräte benutzen, Fluchtwege,
          Sammelplatz).
        </div>

        {/* Signature */}
        <div
          className='grid gap-16 grid-cols-3 mt-16 text-center'
          style={{ fontSize: '16px' }}
        >
          <div>
            <div className='border-b border-black truncate'>
              {dayjs().format('DD.MM.YYYY')}
            </div>
            <div>Datum</div>
          </div>

          <div>
            <div className='border-b border-black truncate'>&nbsp;</div>
            <div>Haushaltsvorstand</div>
          </div>

          <div>
            <div className='border-b border-black truncate'>&nbsp;</div>
            <div>{`${props.user.lastName}, ${props.user.firstName}`}</div>
          </div>
        </div>

        {/* Footer */}
        <div
          className='flex justify-between w-[640px] text-xs text-gray-500 absolute'
          style={{ bottom: 40, fontSize: 9 }}
        >
          <div>Stand 09.08.2022</div>
          <div className='text-right'>
            Brunnenstraße 110 C, 13355 Berlin
            <br />
            Tel. +49 30 213 099 101
            <br />
            info@lfg-b.de
          </div>
        </div>
      </div>

      {/* Page 2 */}
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        {/* Table */}
        <div className='grid grid-cols-5 text-base border-2 border-black'>
          <div className='col-span-2 font-bold text-center border-r border-b-2 border-black bg-gray-300'>
            Schwerpunkt der Hausordnung
          </div>
          <div className='font-bold text-center border-r border-black border-b-2 bg-gray-300'>
            Quelle
          </div>
          <div className='col-span-2 font-bold text-center border-b-2 border-black bg-gray-300'>
            Pictogramm
          </div>

          <div className='col-span-2 px-1 border-r border-b border-black'>
            <p>
              1. Voraussetzung für den berechtigten Aufenthalt der BW in einer Unterkunft
              ist eine gültige Kostenübernahme-erklärung oder Zuweisung des LAF.
            </p>
            <p>
              8. Bei unentschuldigtem Fehlen kann BW abgemeldet werden, LAF behält sich
              vor, die Person in einer anderen Unterkunft unterzubringen.
            </p>
          </div>
          <div className='border-r border-b border-black flex items-center justify-center'>
            §3 Punkte 1&8
          </div>
          <div className='col-span-2 border-b border-black flex items-center justify-center'>
            <img className={styles.image} src={image1} alt='' />
          </div>

          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Genuss von Alkohol und Drogen außerhalb der Wohnungen
          </div>
          <div className='border-r border-b border-black flex items-center justify-center'>
            §5 Punkt 1
          </div>
          <div className='col-span-2 row-span-2 border-b border-black flex items-center justify-center'>
            <img className={styles.image} src={image2} alt='' />
          </div>
          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Rauchverbot in Häusern und Räumen
          </div>
          <div className='border-r border-b border-black flex items-center justify-center'>
            §5 Punkt 2
          </div>

          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Zimmer reinigen
          </div>
          <div className='border-r border-b border-black flex items-center justify-center'>
            §5 Punkt 3
          </div>
          <div className='col-span-2 row-span-3 border-b border-black flex items-center justify-center'>
            <img className={styles.image} src={image3} alt='' />
          </div>
          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Gemeinschaftsräume sauber und ordentlich hinterlassen
          </div>
          <div className='border-r border-b border-black flex items-center justify-center'>
            §5 Punkt 4
          </div>
          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Müll in den Abfalltonnen auf dem Gelände getrennt entsorgen
          </div>
          <div className='border-r border-b border-black flex items-center justify-center'>
            §5 Punkt 10
          </div>

          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Ruhezeit zwischen 22:00 und 06:00 Uhr ist einzuhalten
          </div>
          <div className='border-r border-b border-black flex items-center justify-center'>
            §6 Punkt 2
          </div>
          <div className='col-span-2 border-b border-black flex items-center justify-center'>
            <img className={styles.image} src={image4} alt='' />
          </div>

          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Die Nutzung eigener Koch- und Heizgeräte ist verboten
          </div>
          <div className='border-r border-b border-black flex items-center justify-center'>
            §8 Punkt 4
          </div>
          <div className='col-span-2 border-b border-black flex items-center justify-center'>
            <img className={styles.image} src={image5} alt='' />
          </div>

          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Verstöße gegen die Hausordnung haben Abmahnungen zur Folge (auch Hausverbot)
          </div>
          <div className='row-span-2 border-r border-b border-black flex items-center justify-center'>
            §11 Punkt 3
          </div>
          <div className='col-span-2 row-span-2 border-b border-black flex items-center justify-center'>
            <img className={styles.image} src={image6} alt='' />
          </div>
          <div className='col-span-2 px-1 border-r border-b border-black flex items-center'>
            Gewaltandrohung und -anwendung, illegaler Drogenhandel, Waffenbesitz und
            schwere Straftaten führen zum Hausverbot
          </div>
        </div>
        {/* Footer */}
        <div
          className='text-xs absolute'
          style={{ bottom: 40, right: '100px', fontSize: 9 }}
        >
          Seite 2 von 2
        </div>
      </div>
    </>
  )
}
