export function isRetina() {
  const mediaQuery =
    '(-webkit-min-device-pixel-ratio: 1.5),\n' +
    '(min--moz-device-pixel-ratio: 1.5),\n' +
    '(-o-min-device-pixel-ratio: 3/2),\n' +
    '(min-resolution: 1.5dppx)'
  if (window.devicePixelRatio > 1) {
    return true
  }
  if (window.matchMedia && window.matchMedia(mediaQuery).matches) {
    return true
  }
  return false
}
