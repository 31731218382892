import female from '../images/female.png'
import male from '../images/male.png'
import thumbFemale from '../../../Residents/images/female.svg'
import thumbMale from '../../../Residents/images/male.svg'

export function getUserImageSrc(
  instanceId: string,
  imageId: string | null,
  sex: 'female' | 'male' | 'divers' | null,
  field: 'portrait' | 'original' | 'thumbnail',
): string {
  if (imageId) {
    return `/api/${instanceId}/images/${field.substring(0, 1)}${imageId}`
  }
  if (field === 'thumbnail') {
    return sex === 'female' ? thumbFemale : thumbMale
  }
  return sex === 'female' ? female : male
}
