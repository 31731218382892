import { usePrompt } from 'helpers/usePrompt'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { numberOfPrompts } from '.'

interface Props {}

export const DisplayPromptIfMoreThan0PreventRouteChangeRequests: React.FC<Props> =
  observer(() => {
    if (numberOfPrompts.get() > 0) {
      return <Prompt />
    }
    return null
  })

const Prompt: React.FC<Props> = () => {
  usePrompt(
    'Die Daten wurden noch nicht gespeichert. Möchten Sie diese Ansicht wirklich verlassen und alle Änderungen verwerfen?',
  )
  return null
}
