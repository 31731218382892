import * as React from 'react'
import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { SideBar } from 'components/SideBarLayout/components/SideBar'
import { Spinner } from 'components/Spinner'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { observer } from 'mobx-react'
import { BuildingSelect } from './components/BuildingSelect'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { action, makeObservable } from 'mobx'

interface Props {
  selectedBuildings: Map<string | null, boolean>
  filter?: (building: IBuilding) => boolean
  label: string
  icon?: string
}

@observer
export class BuildingSelectSidebar extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly buildings: Collection<IBuilding>
  private disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.buildings = new Collection(
      `/api/${context.instance.id}/accommodations/buildings`,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.buildings.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private mapBuildings = (b: Resource<IBuilding>) => {
    if (!b.data) {
      return null
    }
    if (!this.props.filter || this.props.filter(b.data)) {
      return (
        <BuildingSelect
          key={b.id}
          selected={this.props.selectedBuildings}
          building={b.data}
        />
      )
    } else {
      return null
    }
  }

  @action
  private selectAll = () => {
    this.props.selectedBuildings.clear()
    for (const b of this.buildings?.resources || []) {
      if (!this.props.filter || (b.data && this.props.filter(b.data))) {
        this.props.selectedBuildings.set(b.id, true)
      }
    }
  }

  @action
  private selectNone = () => {
    this.props.selectedBuildings.clear()
  }

  render() {
    return (
      <SideBar>
        <div
          style={{ flex: '0 0 64px', lineHeight: '64px' }}
          className='pl-6 pr-4 flex text-lg bg-white'
        >
          <div className='flex-content'>
            {this.props.icon && <i className={`${this.props.icon} text-gray-400`} />}
            <span className='ml-3 text-gray-500'>{this.props.label}</span>
          </div>
        </div>
        <div className='px-6'>
          {!this.buildings.resources && <Spinner />}
          {this.buildings?.resources?.length === 0 && (
            <div className='text-sm font-medium text-gray-900'>
              Es sind keine Gebäude vorhanden
            </div>
          )}
          {!!this.buildings?.resources?.length && (
            <div className='ml-[10px]'>
              <span
                onClick={this.selectAll}
                className='pl-2 pr-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100'
                style={{ borderRadius: '4px 0 0 4px' }}
              >
                Alle auswählen
              </span>
              <span
                onClick={this.selectNone}
                className='pl-2 pr-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100'
                style={{ borderRadius: '0 4px 4px 0' }}
              >
                Alle abwählen
              </span>
            </div>
          )}
          <div className='flex flex-col overflow-y-auto' style={{ top: 186 }}>
            <nav className='flex-1 pb-4 space-y-1'>
              {this.props.children}
              {this.buildings && this.buildings.resources?.map(this.mapBuildings)}
            </nav>
          </div>
        </div>
      </SideBar>
    )
  }
}
