import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { getFileExtension } from './helpers/getFileExtension'
import { supportedResidentDocumentTypes } from 'contracts/residents/helpers/supportedResidentDocumentTypes'
import { byteMagnitude } from 'contracts/general/helpers/getDiskSizeLabel'
import * as uuid from 'uuid'
import { useCallback, useContext, useEffect, useState } from 'react'
import {
  DocumentDropzoneViewError,
  getDocumentDropzoneViewState,
} from './helpers/getDocumentDropzoneViewState'
import { isSupportedFiletype } from './helpers/isSupportedFiletype'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { AppContext } from 'services/connection/models/AppContext'

const FILE_TYPE_NOT_SUPPORTED = 'FILE_TYPE_NOT_SUPPORTED'
const FILE_SIZE_LIMIT_EXCEEDED = 'FILE_SIZE_LIMIT_EXCEEDED'
const MULTIPLE_FILES_NOT_SUPPORTED = 'MULTIPLE_FILES_NOT_SUPPORTED'
export const MAX_FILE_SIZE_IN_MB = 10

export interface DocumentDropzoneViewProps {
  state:
    | 'WAITING_FOR_FILES'
    | 'DRAG_ACCEPT'
    | 'DRAG_REJECT'
    | 'MULTIPLE_FILES_NOT_SUPPORTED'
    | 'FILE_TYPE_NOT_SUPPORTED'
    | 'FILE_SIZE_LIMIT_EXCEEDED'
    | 'UNSPECIFIED_ERROR'
}

interface Props {
  scope: 'resident' | 'cost coverage' | 'salary slip'
  onSelect: (doc: IDocumentMetadata & { file: Blob }) => void
  view: React.FunctionComponent<DocumentDropzoneViewProps>
  className?: string
  accept?: string
  outline?: boolean
  multiple?: boolean
  maxMb?: number // max file size in MB (default MAX_FILE_SIZE_IN_MB)
}

const accept = {
  resident: supportedResidentDocumentTypes,
}

export const DocumentDropzone: React.FC<Props> = (props) => {
  const context = useContext(AppContext)
  const [error, setError] = useState<DocumentDropzoneViewError | null>(null)
  const { scope, onSelect } = props
  const onDrop = useCallback(
    (files: File[]) => {
      const docs: (IDocumentMetadata & { file: Blob })[] = []
      try {
        if (props.multiple !== true && files.length !== 1) {
          throw new Error(MULTIPLE_FILES_NOT_SUPPORTED)
        }
        for (const file of files) {
          const type = getFileExtension(file.name)
          if (!isSupportedFiletype(type, props.accept || accept[scope])) {
            throw new Error(FILE_TYPE_NOT_SUPPORTED)
          }
          if (props.maxMb && file.size > props.maxMb * byteMagnitude * byteMagnitude) {
            throw new Error(FILE_SIZE_LIMIT_EXCEEDED)
          }
          if (
            !props.maxMb &&
            file.size > MAX_FILE_SIZE_IN_MB * byteMagnitude * byteMagnitude
          ) {
            throw new Error(FILE_SIZE_LIMIT_EXCEEDED)
          }

          // Create list of DocumentTransfer objects
          const doc: IDocumentMetadata & { file: Blob } = {
            id: uuid.v4(),
            folderId: '',
            category: '',
            name: file.name,
            size: String(file.size),
            userId: context.user.id,
            residentId: null,
            familyId: null,
            documentationId: null,
            notes: '',
            createdAt: new Date().toISOString(),
            uploadUser: `${context.user.firstName} ${context.user.lastName}`,
            file,
            validTillDate: null,
          }
          docs.push(doc)
        }
      } catch (e: any) {
        switch (e.message) {
          case FILE_TYPE_NOT_SUPPORTED:
            setError('FILE_TYPE_NOT_SUPPORTED')
            break
          case FILE_SIZE_LIMIT_EXCEEDED:
            setError('FILE_SIZE_LIMIT_EXCEEDED')
            break
          case MULTIPLE_FILES_NOT_SUPPORTED:
            setError('MULTIPLE_FILES_NOT_SUPPORTED')
            break
          default:
            setError('UNSPECIFIED_ERROR')
        }
        return
      }

      // Callbacks
      for (const doc of docs) {
        onSelect(doc)
      }
    },
    // eslint-disable-next-line
    [onSelect],
  )
  const View = props.view
  const dropzoneState = useDropzone({
    onDrop,
    accept: props.accept || accept[props.scope],
  })
  const state = getDocumentDropzoneViewState(dropzoneState, error)
  useEffect(() => {
    if (
      [
        'FILE_TYPE_NOT_SUPPORTED',
        'FILE_SIZE_LIMIT_EXCEEDED',
        'MULTIPLE_FILES_NOT_SUPPORTED',
        'UNSPECIFIED_ERROR',
      ].indexOf(state) === -1
    ) {
      return
    }
    const timer = setTimeout(() => setError(null), 2500)
    return () => {
      clearTimeout(timer)
      setError(null)
    }
  }, [state])

  return (
    <div
      {...dropzoneState.getRootProps()}
      className={`${props.outline === false ? 'outline-0' : 'outline-primary'} br2`}
    >
      <input {...dropzoneState.getInputProps()} />
      <View state={state} />
    </div>
  )
}
