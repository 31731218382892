import { z } from 'zod'

export const JugendamtValidator = z.object({
  id: z.string().min(1).max(255),
  instanceId: z.string().min(1).max(255),
  label: z.string().min(1).max(255),
  street: z.string().min(1).max(255),
  zip: z.string().min(1).max(255),
  city: z.string().min(1).max(255),
  federalState: z.string().min(1).max(255),
  contactPerson: z.string().optional(),
  comment: z.string().optional(),
})
