import * as React from 'react'
import { ICostCoverageInvoice } from 'contracts/costCoverages/interfaces/ICostCoverageInvoice'
import { CostCoverageInvoiceLfgb } from './templates/CostCoverageInvoiceLfgb'

interface Props {
  invoice: ICostCoverageInvoice
  instanceId: string
  readOnly?: boolean
}

export const CostCoverageInvoice: React.FC<Props> = ({
  instanceId,
  invoice,
  children,
  readOnly,
}) => {
  // Other templates can be selected here
  return (
    <CostCoverageInvoiceLfgb invoice={invoice} readOnly={readOnly}>
      {children}
    </CostCoverageInvoiceLfgb>
  )
}
