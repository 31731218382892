import defaultImg from '../images/default.jpeg'

export function getInventoryImageSrc(
  imageId: string | null,
  instanceId: string,
  size: 'portrait' | 'thumbnail' = 'portrait',
): string {
  if (imageId) {
    return `/api/${instanceId}/images/${size.substring(0, 1)}${imageId}`
  }
  return defaultImg
}
