import { hermes } from '@byll/hermes'
import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { IVisitScanResult } from 'contracts/scan/interfaces/IVisitScanResult'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { CompoundVisitorCount } from './CompoundVisitorCount'
import { VisitorTile } from './VisitorTile'

interface Props {
  station: IScanningStation
  scans: IVisitScanResult[]
}

@observer
export class LastScans extends React.Component<Props, {}> {
  static contextType = AppContext

  componentDidMount() {
    void this.loadLastScans()
  }

  private loadLastScans = async () => {
    const data = await hermes.indexOnceNew<IVisitScanResult & { id: string }>(
      `/api/${this.context.instance.id}/scan/visits?scanningStationId=${this.props.station.id}`,
    )
    runInAction(() => {
      ;(this.props.scans as any).replace(data)
    })
  }

  private mapScan = (scan: IVisitScanResult) => <VisitorTile key={scan.id} scan={scan} />

  render() {
    return (
      <div className='absolute top-0 left-0 right-0 bottom-0 p-4 overflow-x-hidden overflow-y-auto flex flex-col gap-4'>
        {this.props.scans && this.props.scans.length > 0 && (
          <div className='text-base'>
            Aktivität
            <CompoundVisitorCount compoundId={this.props.station.compoundId} />
          </div>
        )}
        {this.props.scans.map(this.mapScan)}
        {this.props.scans.length === 0 && (
          <div className='mt-8 text-slate-500 text-center'>
            <i className='fas fa-id-card' style={{ fontSize: 100 }} />
            <div className='text-xl mt-2'>
              Bisher keine
              <br />
              Ausweise gescannt
            </div>
          </div>
        )}
      </div>
    )
  }
}
