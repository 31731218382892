import { Button } from 'components/Form/components/Button'
import * as React from 'react'
import {
  DocumentDropzoneViewProps as Props,
  MAX_FILE_SIZE_IN_MB,
} from '../DocumentDropzone'
import styles from './styles.module.scss'

const messages = {
  FILE_TYPE_NOT_SUPPORTED: `Der Dateityp ist nicht erlaubt`,
  FILE_SIZE_LIMIT_EXCEEDED:
    `Die Datei überschreitet die Begrenzung von ` + MAX_FILE_SIZE_IN_MB + ' MB',
  UNSPECIFIED_ERROR: `Beim Upload ist ein Fehler aufgetreten`,
  MULTIPLE_FILES_NOT_SUPPORTED: 'Bitte wählen Sie nur eine einzelne Datei aus',
}

export const SalarySlipDropzoneView: React.FunctionComponent<Props> = ({
  state,
}: Props) => {
  if (state === 'WAITING_FOR_FILES' || state === 'DRAG_ACCEPT') {
    return (
      <div className={styles.dropzone}>
        <div
          className={
            `mt-3 mb-3 border-2 rounded-full text-center border-indigo-500 ` +
            `${styles.canAccept} ${
              state === 'DRAG_ACCEPT' ? styles.accept : styles.pulse
            }`
          }
          style={{ padding: '18px 0', width: 64, height: 64 }}
        >
          <span
            className={state === 'DRAG_ACCEPT' ? 'text-green-500' : 'text-indigo-500'}
          >
            <i className={`fa-fw fa fa-arrow-up`} style={{ fontSize: 20 }} />
          </span>
        </div>
        {state !== 'DRAG_ACCEPT' && (
          <div className='text-gray-500'>Ziehen Sie die Gehaltszettel hier hin oder</div>
        )}
        {state === 'DRAG_ACCEPT' && <div className='gray-light'>&nbsp;</div>}
        <div className='flex mb-auto mt-3 items-center'>
          <Button color={state === 'DRAG_ACCEPT' ? 'success' : 'primary'}>
            {state === 'DRAG_ACCEPT'
              ? `Sie können die Datei jetzt loslassen`
              : `Öffnen Sie eine Datei`}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.dropzone}>
      <div
        className={`mt-3 mb-3 border-2 rounded-full text-center border-red-500`}
        style={{ padding: '18px 0', width: 64, height: 64 }}
      >
        <span className='text-red-500'>
          <i className='fa-fw fas fa-exclamation' style={{ fontSize: 20 }} />
        </span>
      </div>
      <div className='text-red-500 mb-auto mt-3'>
        <h2 className='text-center text-lg'>
          {state === 'DRAG_REJECT' ? `Ungültiger Dateityp` : `Fehler`}
        </h2>
        <p className='mb-0 text-center'>
          {state === 'DRAG_REJECT'
            ? `Bitte laden Sie ein PDF Dokument hoch`
            : messages[state]}
        </p>
      </div>
    </div>
  )
}
