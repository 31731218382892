import * as React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { getInactiveListStorageModel } from './helpers/getInactiveListStorageModel'
import { storage } from '../../services/storage'
import { LeaInactiveListView } from './components/LeaInactiveListView'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Button } from 'components/Form/components/Button'
import { InputCompound } from 'components/Form/components/InputCompound'
import { Model } from 'components/Form/Model'
import { IInactiveListFilterModel } from 'contracts/inactiveList/interfaces/IInactiveListFilterModel'
import { InputSelect } from 'components/Form/components/InputSelect'

interface Props {}

@observer
export class LeaInactiveList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IInactiveListFilterModel>
  @observable downloading = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.model = new Model(getInactiveListStorageModel(context.user))
  }

  componentDidMount(): void {
    // Save model changes
    reaction(
      () => toJS(this.model.values),
      () => storage.set(`inactiveList.${this.context.user.id}`, this.model.values),
      { fireImmediately: true },
    )
  }

  @action toggleResidentCheckedIn = () =>
    this.model.values.residentCheckedIn === 'yes'
      ? (this.model.values.residentCheckedIn = 'no')
      : (this.model.values.residentCheckedIn = 'yes')
  @action toggleResidentCheckedOut = () =>
    this.model.values.residentCheckedOut === 'yes'
      ? (this.model.values.residentCheckedOut = 'no')
      : (this.model.values.residentCheckedOut = 'yes')
  @action toggleResidentInactive = () =>
    this.model.values.residentInactive === 'yes'
      ? (this.model.values.residentInactive = 'no')
      : (this.model.values.residentInactive = 'yes')
  @action toggleResidentWasScanned = () =>
    this.model.values.residentWasScanned === 'yes'
      ? (this.model.values.residentWasScanned = 'no')
      : (this.model.values.residentWasScanned = 'yes')
  @action toggleResidentWasNotScanned = () =>
    this.model.values.residentWasNotScanned === 'yes'
      ? (this.model.values.residentWasNotScanned = 'no')
      : (this.model.values.residentWasNotScanned = 'yes')

  createInactiveListReport = async () => {
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport(
      'inactive-resident-list',
      this.context.instance.id,
      this.model.values,
    )
    runInAction(() => (this.downloading = false))
  }

  render() {
    if (!this.context.permissions.resident_leaInactive) {
      return <Forbidden />
    }
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full'>
        {/* Section heading */}
        <div className='grid grid-cols-1 sm:grid-cols-[auto_auto_38px] lg:grid-cols-[auto_auto_auto_1fr_auto_auto_auto] relative my-7 bg-white sm:rounded-lg shadow p-4 gap-4'>
          <div className='hidden lg:block text-lg leading-[38px] font-medium text-gray-900'>
            Scanliste
          </div>

          <div>
            <InputCompound model={this.model} name='compoundId' />
          </div>

          <div className='flex col-span-1 sm:col-span-2 lg:col-span-1'>
            <Button
              onClick={this.toggleResidentCheckedOut}
              color={
                this.model.values.residentCheckedOut === 'yes' ? 'primary' : 'secondary'
              }
              style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
              outline={this.model.values.residentCheckedOut === 'no'}
              className='flex-auto'
            >
              Ehemalige
            </Button>
            <Button
              onClick={this.toggleResidentCheckedIn}
              color={
                this.model.values.residentCheckedIn === 'yes' ? 'primary' : 'secondary'
              }
              style={{
                borderRadius: !this.context.permissions
                  .resident_automatic_setResponsibility
                  ? '0'
                  : '0 6px 6px 0',
              }}
              outline={this.model.values.residentCheckedIn === 'no'}
              className='flex-auto'
            >
              Derzeitige
            </Button>
            {!this.context.permissions.resident_automatic_setResponsibility && (
              <Button
                onClick={this.toggleResidentInactive}
                color={
                  this.model.values.residentInactive === 'yes' ? 'primary' : 'secondary'
                }
                style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                outline={this.model.values.residentInactive === 'no'}
                className='flex-auto'
              >
                Inaktiv Ausgecheckt
              </Button>
            )}
          </div>

          <div className='hidden lg:block' />

          <div>
            <InputSelect
              model={this.model}
              name='interval'
              options={[
                { value: '24h', label: '24 Stunden' },
                { value: '48h', label: '48 Stunden' },
                { value: '72h', label: '72 Stunden' },
                { value: '6d', label: '6 Tage' },
              ]}
            />
          </div>

          <div className='flex'>
            <Button
              onClick={this.toggleResidentWasNotScanned}
              color={
                this.model.values.residentWasNotScanned === 'yes'
                  ? 'primary'
                  : 'secondary'
              }
              style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
              outline={this.model.values.residentWasNotScanned === 'no'}
              className='flex-auto'
            >
              Nicht gescannt
            </Button>
            <Button
              onClick={this.toggleResidentWasScanned}
              color={
                this.model.values.residentWasScanned === 'yes' ? 'primary' : 'secondary'
              }
              style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
              outline={this.model.values.residentWasScanned === 'no'}
              className='flex-auto'
            >
              Gescannt
            </Button>
          </div>

          {/* Download */}
          <div className='relative'>
            {this.downloading && (
              <Button color='secondary' className='w-[38px]' style={{ padding: '9px 0' }}>
                <i className='fa fa-sync fa-spin' />
              </Button>
            )}
            {!this.downloading && (
              <Button
                color='secondary'
                onClick={this.createInactiveListReport}
                className='w-[38px]'
                style={{ padding: '9px 0' }}
              >
                <i className='fa fa-download' />
              </Button>
            )}
          </div>
        </div>

        {/* List */}
        {this.model.values.compoundId && <LeaInactiveListView model={this.model} />}
        {!this.model.values.compoundId && (
          <div className='flex flex-col min-h-screen' style={{ minWidth: 1024 }}>
            <div className='flex-content m-auto text-center text-gray-500'>
              <i className='fa fa-building' style={{ fontSize: 100 }} />
              <br />
              <span className='text-2xl'>
                Bitte wählen Sie
                <br />
                ein Gelände
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
}
