import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  selected: Set<string>
  resident: IResidentSearchResult
}

const toggle = action((resident: IResidentSearchResult, selected: Set<string>) => {
  if (selected.has(resident.id)) {
    selected.delete(resident.id)
  } else {
    selected.add(resident.id)
  }
})

export const Item: React.FC<Props> = observer(({ selected, resident }) => (
  <div className='bg-white rounded-md shadow p-3 my-2'>
    <input
      type='checkbox'
      className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded cursor-pointer relative -top-[1px]'
      checked={selected.has(resident.id)}
      onChange={() => toggle(resident, selected)}
    />
    &nbsp;&nbsp;
    {`${resident.lastName}, ${resident.firstName}`}
  </div>
))
