import { hermes } from '@byll/hermes'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { ITransferPlanningSearchResult } from 'contracts/transfer/interfaces/ITransferPlanningSearchResult'
import { transferStatus } from 'contracts/transfer/interfaces/transferStatusEnum'
import dayjs from 'dayjs'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  transfer: ITransferPlanningSearchResult
  statusMap: Map<transferStatus, string>
}

export class TransferPlanningEntry extends React.Component<Props, {}> {
  static contextType = AppContext

  private deleteTransfer = async () => {
    if (this.context.permissions.residentTransferPlanningList_deletePersons === 0) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/transfer/plannings/${this.props.transfer.id}`,
      )
    } catch (e: any) {
      if (e.id === ConflictError.id) {
        box.alert('Fehler', e.message)
      } else {
        box.alert(
          'Fehler',
          'Beim Löschen der Planung ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
        )
      }
    }
  }

  render() {
    return (
      <tr>
        <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
          {this.props.transfer.date
            ? dayjs(this.props.transfer.date).format('DD.MM.YYYY')
            : ''}
        </td>
        <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
          <Link
            to={`/residents/${toJbpId(
              +this.props.transfer.resident.id,
            ).toLowerCase()}/overview`}
            className='text-indigo-600 hover:text-indigo-900'
          >
            {this.props.transfer.resident.lastName},{' '}
            {this.props.transfer.resident.firstName}
          </Link>
        </td>
        <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
          {this.props.transfer.createdBy.lastName},{' '}
          {this.props.transfer.createdBy.firstName}
        </td>
        <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
          {this.props.transfer.resident.accommodation.label}
        </td>
        <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
          {this.props.transfer.transportation?.label || ''}
        </td>
        <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
          {this.props.transfer.target.label}
        </td>
        <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
          <img src={this.props.statusMap.get(this.props.transfer.status)} alt='' />
        </td>
        {this.context.permissions.residentTransferPlanningList_deletePersons > 0 && (
          <td>
            <span
              className='m-1 text-gray-600 cursor-pointer'
              onClick={this.deleteTransfer}
            >
              <i className='fas fa-times' />
            </span>
          </td>
        )}
      </tr>
    )
  }
}
