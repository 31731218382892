import * as React from 'react'
import { Card } from 'components/Card'
import { ProgressBar } from './components/ProgressBar'
import { hermes } from '@byll/hermes'
import { observer } from 'mobx-react'
import { makeObservable, observable, runInAction } from 'mobx'
import { IServerStat } from 'contracts/dashboard/interfaces/IServerStat'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Tooltip } from 'components/Tooltip'

interface Props {}

@observer
export class DashboardServerStats extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private stats: IServerStat[] | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    const stats = await hermes.indexOnceNew<IServerStat>(
      `/api/${this.context.instance.id}/dashboard/serverStats`,
    )
    runInAction(() => (this.stats = stats))
  }

  render() {
    return (
      <Card>
        <div className='p-4'>
          <div className='font-bold text-xl mb-3'>Systeminformationen</div>
          {this.stats && (
            <div>
              {this.stats.map((s) => (
                <React.Fragment key={s.id}>
                  <div className='text-gray-700'>{s.id}</div>
                  <div className='flex'>
                    <div className='flex-auto'>
                      <ProgressBar value={s.percentage} className={s.color} />
                    </div>
                    <div
                      style={{ flex: '0 0 60px', marginTop: -5 }}
                      className='text-gray-700 text-right'
                    >
                      <span className='has-tooltip'>
                        {s.label}
                        <Tooltip position='left'>{s.tooltip}</Tooltip>
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </Card>
    )
  }
}
