import { isISODate } from 'contracts/general/helpers/isISODate'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { z } from 'zod'

export const LastSeenValidator = z.array(
  z.object({
    id: z.string().refine(isIntegerString),
    seenAt: z.string().refine(isISODate),
  }),
)
