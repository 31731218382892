import * as React from 'react'

export interface IVisitScanError {
  type: 'error'
  icon: string
  message: string
}

interface Props {
  error: IVisitScanError
}

export const ScanError: React.FC<Props> = ({ error }) => {
  const [white, setWhite] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => setWhite(true), 100)
  }, [])

  return (
    <div
      className={`${
        white ? 'bg-white' : 'bg-red-500'
      } transition-colors rounded-md shadow-md p-4 flex-auto flex flex-col`}
    >
      <div className='m-auto tc'>
        <div className='relative inline-block text-center max-w-xl'>
          <div className='mb-2 text-red-500 font-bold' style={{ fontSize: '40px' }}>
            {error.message}
          </div>
          <div className='text-red-500' style={{ fontSize: '300px' }}>
            <i className={error.icon} aria-hidden='true' />
          </div>
        </div>
      </div>
    </div>
  )
}
