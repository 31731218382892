import * as React from 'react'

interface Props {}

export const CardColumn: React.FC<Props> = (props) => (
  <div className='flex flex-auto mt-20 mb-6'>
    <div className='flex-auto lg:flex-[0_0_708px] xl:flex-[0_0_908px] mx-4 sm:mx-6 lg:mx-auto flex flex-col'>
      {props.children}
    </div>
  </div>
)
