import { ResponsibilityEndForm } from '../components/ResponsibilityEndForm'
import { ResponsibilityBeginForm } from '../components/ResponsibilityBeginForm'
import { InternalResidenceForm } from '../components/InternalResidenceForm'
import { InternalReservationForm } from '../components/InternalReservationForm'
import { ExternalResidenceForm } from '../components/ExternalResidenceForm'
import { EndOfBillingForm } from '../components/InfoBookingForm/EndOfBilling'
import {
  INFO_BOOKING_REASON_END_OF_STAY,
  INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE,
  INFO_BOOKING_REASON_AWUM_GEPLANTE_GEBUEHRENAENDERUNG,
} from 'contracts/residents/interfaces/IBooking'
import { EndOfStayForm } from '../components/InfoBookingForm/EndOfStay'

export const accommodationForms = {
  'internal-residence': InternalResidenceForm,
  'internal-reservation': InternalReservationForm,
  'external-residence': ExternalResidenceForm,
  'responsibility-begin': ResponsibilityBeginForm,
  'responsibility-end': ResponsibilityEndForm,
  [`info-${INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE}`]: EndOfBillingForm,
  [`info-${INFO_BOOKING_REASON_END_OF_STAY}`]: EndOfStayForm,
  [`[exclude]-info-${INFO_BOOKING_REASON_AWUM_GEPLANTE_GEBUEHRENAENDERUNG}`]:
    EndOfBillingForm,
}
