import { InputSelectOption } from 'components/Form/components/InputSelect'

export const lfgAufenthaltsstatusOptions: InputSelectOption[] = [
  { value: null, label: '' },
  { value: 'Aufenthaltsgestattung', label: 'Aufenthaltsgestattung' },
  { value: 'Aufenthaltserlaubnis', label: 'Aufenthaltserlaubnis' },
  { value: 'Duldung', label: 'Duldung' },
  { value: 'Fiktionsbescheinigung', label: 'Fiktionsbescheinigung' },
  {
    value: 'Freizügigkeit EWR und Gleichgestellte',
    label: 'Freizügigkeit EWR und Gleichgestellte',
  },
  { value: 'Grenzübertrittsbescheinigung', label: 'Grenzübertrittsbescheinigung' },
  { value: 'Keine Angaben', label: 'Keine Angaben' },
  { value: 'Niederlassungserlaubnis', label: 'Niederlassungserlaubnis' },
  { value: 'Sonstiges', label: 'Sonstiges' },
  { value: 'Spätaussiedler', label: 'Spätaussiedler' },
  { value: 'Trifft nicht zu', label: 'Trifft nicht zu' },
  { value: 'Visum', label: 'Visum' },
]
