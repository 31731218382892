interface ReturnsPromise {
  (...args: any): Promise<any>
}

/**
 * withTimeout cannot cancel function fkt after timeout, but it throws
 * a timeout error. This only works with functions that return promises
 * and interrupt their own control flow while waiting for another promise.
 * @param fkt
 * @param timeout milliseconds
 */
export function withTimeout<F extends ReturnsPromise>(fkt: F, timeout: number): F {
  return ((...args: any[]) =>
    new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error('timeout'))
      }, timeout)
      fkt(...args)
        .then((data) => {
          clearTimeout(timer)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })) as any
}
