import * as React from 'react'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { dayjs } from 'helpers/dayjs'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'
import { box } from 'services/box'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'

export interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  label?: string
  className?: string
  inputClassName?: string
  blurAction?: 'dialog-if-invalid-date' | 'clear-field-if-invalid-date' // defaults to 'dialog-if-invalid-date'
}

/**
 * There is no date picker in this component. It is just a text input.
 */

@observer
export class InputTextDate extends React.Component<Props, {}> {
  private id: string
  @observable private value: string = ''
  private disposer: Disposer | null = null

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('input-')
    this.value = ymdToGermanDate(props.model.values[props.name])
    makeObservable(this)
  }

  componentDidMount() {
    this.disposer = reaction(
      () => this.props.model.values[this.props.name],
      (value) => {
        const ymd = germanDateToYmd(this.value)
        if (value === ymd) {
          return
        }
        this.value = ymdToGermanDate(value)
      },
    )
  }

  componentWillUnmount() {
    this.disposer?.()
  }

  @action private onChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (!(event.target instanceof HTMLInputElement)) {
      return
    }
    const target: HTMLInputElement = event.target
    this.value = target.value.replace(/[^0-9.]/g, '')
    const ymd = germanDateToYmd(this.value)
    if (this.props.model.values[this.props.name] === ymd) {
      return
    }
    this.props.model.values[this.props.name] = ymd
    this.props.onChange?.(event)
  }

  private onBlur = async (e) => {
    if (this.value !== '' && this.props.model.values[this.props.name] === null) {
      if (
        this.props.blurAction !== 'clear-field-if-invalid-date' &&
        (await box.alert(
          'Ungültiges Datum',
          `Möchten Sie das Datum "${this.value}" korrigieren oder verwerfen?`,
          { color: 'danger', cancel: 'Verwerfen', confirm: 'Korrigieren' },
        ))
      ) {
        setTimeout(() => document.getElementById(this.id)?.focus(), 0)
        return
      }
    }
    runInAction(
      () => (this.value = ymdToGermanDate(this.props.model.values[this.props.name])),
    )
    this.props.onBlur?.(e)
  }

  render() {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const {
      name,
      model,
      label,
      className,
      inputClassName,
      blurAction,
      children,
      ...attributes
    } = this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    if (inputClassName) {
      innerClassName += ` ${inputClassName}`
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7 }}
          >
            {label}
          </label>
        )}
        <input
          type='text'
          className={innerClassName}
          maxLength={15}
          placeholder='TT.MM.JJJJ'
          {...attributes}
          name={name}
          onChange={this.onChange}
          onBlur={this.onBlur}
          value={this.value}
          id={this.id}
        />
        {children}
      </div>
    )
  }
}

function ymdToGermanDate(ymd: string | null) {
  const date = dayjs(ymd || '', 'YYYY-M-D')
  if (!date.isValid()) {
    return ''
  }
  return date.format('DD.MM.YYYY')
}

export function germanDateToYmd(germanDate: string): string | null {
  const date = dayjs(germanDate, ['D.M.YYYY', 'D.M.YY'])
  if (!date.isValid()) {
    return null
  }
  return date.format('YYYY-MM-DD')
}
