import { observer } from 'mobx-react'
import * as React from 'react'
import { MenuAlt2Icon } from '@heroicons/react/outline'
import { ToggleContext } from 'components/SideBarLayout/models/ToggleContext'

interface Props {}

@observer
export class TransferPlanningTopBar extends React.Component<Props, {}> {
  static contextType = ToggleContext

  render() {
    return (
      <div className='mt-14 flex-content flex bg-white shadow'>
        <button
          type='button'
          className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
          onClick={this.context.toggle}
        >
          <span className='sr-only'>Open sidebar</span>
          <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
        </button>
        <div className='flex-1 px-4 flex'>{this.props.children}</div>
      </div>
    )
  }
}
