import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { KeySortableHandle } from './SortableHandle'
import { IUserDefaults } from 'contracts/users/interfaces/IUserDefaults'
import { Button } from 'components/Form/components/Button'

const SortableItem = observer(
  SortableElement(({ item, model, onToggle, config }) => {
    const addWidth = action(() => {
      const elem = document.getElementById(`field-${item.variable}`)
      const width = elem?.getClientRects()[0].width
      if (!width) return
      if (width >= 500) return
      item.width = width + 20
      // Trigger hermes patch by changing reference on level 1 of the object
      config.residentSearch = {
        ...config.residentSearch,
      }
    })

    const reduceWidth = action(() => {
      const elem = document.getElementById(`field-${item.variable}`)
      const width = elem?.getClientRects()[0].width
      if (!width) return
      if (width <= 80) return
      item.width = width - 20
      // Trigger hermes patch by changing reference on level 1 of the object
      config.residentSearch = {
        ...config.residentSearch,
      }
    })

    const resetWidth = action(() => {
      item.width = undefined
      // Trigger hermes patch by changing reference on level 1 of the object
      config.residentSearch = {
        ...config.residentSearch,
      }
    })

    const checked = `,${model.values.fields},`.indexOf(`,${item.variable},`) !== -1

    return (
      <li className='z-20 flex items-center group relative'>
        <KeySortableHandle />
        <input
          type='checkbox'
          checked={checked}
          onChange={() => onToggle(item.variable)}
          className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mx-2'
        />
        <div className='truncate'>{item.label}</div>
        {checked && (
          <div className='hidden group-hover:flex ml-auto px-1 text-gray-500'>
            <Button
              className='z-50'
              small
              onClick={reduceWidth}
              color='secondary'
              outline
              style={{ borderRadius: '6px 0 0 6px', padding: '2px 0', width: 22 }}
            >
              -
            </Button>
            <Button
              color='secondary'
              outline
              className='z-50'
              small
              onClick={addWidth}
              style={{
                borderRadius: '0',
                borderLeft: 'none',
                borderRight: 'none',
                padding: '2px 0',
                width: 22,
              }}
            >
              +
            </Button>
            <Button
              className='z-50'
              color='secondary'
              outline
              small
              onClick={resetWidth}
              style={{ borderRadius: '0 6px 6px 0', padding: '2px 5px' }}
            >
              Auto
            </Button>
          </div>
        )}
      </li>
    )
  }),
)

const SortableList = observer(
  SortableContainer(({ items, model, onToggle, config }) => {
    return (
      <ul>
        {items.map((item, index) => (
          <SortableItem
            key={index}
            index={index}
            model={model}
            onToggle={onToggle}
            item={item}
            config={config}
          />
        ))}
      </ul>
    )
  }),
)

interface Props {
  model: Model<IResidentSearchResultsFilter>
  config: IUserDefaults
  onToggle: (key: string) => void
}

@observer
export class SortableKeysList extends React.Component<Props, {}> {
  @action onSortEnd = ({ oldIndex, newIndex }) => {
    const newArray = arrayMoveImmutable(
      this.props.config.residentSearch.order,
      oldIndex,
      newIndex,
    )
    runInAction(
      () =>
        (this.props.config.residentSearch = {
          ...this.props.config.residentSearch,
          order: newArray,
        }),
    )
  }

  render() {
    return (
      <SortableList
        key={this.props.config.residentSearch.fields.split(',').length}
        items={this.props.config.residentSearch.order}
        model={this.props.model}
        config={this.props.config}
        onSortEnd={this.onSortEnd}
        onToggle={this.props.onToggle}
      />
    )
  }
}
