import { Model } from 'components/Form/Model'
import { IResidentDocumentationSearchResult } from 'contracts/residents/interfaces/IResidentDocumentationSearchResult'
import * as React from 'react'
import { observer } from 'mobx-react'
import { Collection, hermes, Resource } from '@byll/hermes'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { AttachmentTile } from 'components/Attachment/AttachmentTile'
import { IAttachment } from 'contracts/general/interfaces/IAttachment'
import { download } from 'helpers/download'
import { AttachmentUpload } from 'components/Attachment/AttachmentUpload'
import { action, makeObservable, runInAction } from 'mobx'

interface Props {
  model: Model<IResidentDocumentationSearchResult>
  documents: Collection<IDocumentMetadata>
  readOnly?: boolean // only download, no delete or upload.
}

@observer
export class DocumentationDialogAttachments extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly metadata: any

  constructor(props: Props, context: AppContextProps) {
    super(props)
    if (props.model.values.id) {
      this.metadata = { documentationId: props.model.values.id }
    }
    makeObservable(this)
  }

  @action
  private onUploaded = async (attachment: IAttachment) => {
    // For new documentations (which do not have an id yet)
    if (!this.props.model.values.id && this.props.documents.resources) {
      // Store in uninitialized index
      const resource = new Resource<any>(
        `/api/${this.context.instance.id}/documents/metadata/${attachment.id}`,
      )
      resource.error = null
      resource.data = attachment
      this.props.documents.resources.push(resource)
    }
  }

  private onDelete = async (attachment: IAttachment) => {
    if (this.props.readOnly) {
      window.alert('Sie haben keine Berechtigung, diesen Anhang zu löschen.')
      return
    }
    if (!window.confirm('Möchten Sie diesen Anhang wirklich unwiderruflich löschen?')) {
      return
    }
    if (!this.props.model.values.id) {
      // Remove from uninitialized index
      runInAction(() => {
        if (!this.props.documents.resources) {
          return
        }
        for (let i = this.props.documents.resources.length - 1; i >= 0; i--) {
          if (this.props.documents.resources[i].id === attachment.id) {
            this.props.documents.resources.splice(i, 1)
          }
        }
      })
    } else {
      // Delete (immediate) from hermes index
      try {
        await hermes.delete(
          `/api/${this.context.instance.id}/documents/files/${attachment.id}`,
        )
      } catch (_e) {
        alert('Das Löschen ist fehlgeschlagen.')
      }
    }
  }

  private onDownload = (attachment: IAttachment) => {
    download(`/api/${this.context.instance.id}/documents/files/${attachment.id}`)
  }

  private onPreview = (attachment: IAttachment) => {
    window.open(`/documents/${attachment.id}`, '_blank')
  }

  render() {
    return (
      <div>
        {/* Title */}
        <div className='text-gray-400 uppercase text-xs mt-6 -mb-3'>Anhänge</div>

        {/* File list */}
        {this.props.documents.resources?.map((a) =>
          a.data ? (
            <AttachmentTile
              key={a.id}
              attachment={a.data}
              onDelete={this.onDelete}
              onDownload={this.onDownload}
              onPreview={this.onPreview}
            />
          ) : null,
        )}

        {/* Select file */}
        <AttachmentUpload
          disabled={this.props.readOnly}
          metadata={this.metadata}
          onUploaded={this.onUploaded}
          scope='resident documentation'
        >
          Klicken Sie <span className='text-blue-500 hover:underline'>hier</span>, um eine
          Datei an diese Dokumentation anzuhängen.
        </AttachmentUpload>
      </div>
    )
  }
}
