import { IMigration } from 'contracts/migrations/interfaces/IMigration'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  migration: IMigration
}

export const MigrationProgressBar: React.FC<Props> = observer(({ migration }) => {
  if (migration.status !== 'Import läuft' || typeof migration.progress !== 'number') {
    return null
  }
  return (
    <div
      className='absolute left-0 h-1 bg-green-500 bottom-0 transition-width duration-1000'
      style={{ width: `${migration.progress}%` }}
    />
  )
})
