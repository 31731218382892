import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { InputDate } from 'components/Form/components/InputDate'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { InputText } from 'components/Form/components/InputText'
import { z } from 'zod'
import { isTime } from 'contracts/general/helpers/isTime'
import { dayjs } from 'helpers/dayjs'
import { isPastDate } from '../helpers/isPastDate'
import { Message } from 'components/Message'
import { InputBuildingListFoldable } from 'components/Form/components/InputBuildingListFoldable'

interface Props {
  type:
    | 'accommodation-bookings'
    | 'accommodation-bookings-2'
    | 'accommodation-bookings-3'
    | 'bewohnerliste_rpt'
}

const titleDict = {
  'accommodation-bookings': 'Belegungsplan',
  'accommodation-bookings-2': 'Belegungsplan 2',
  'accommodation-bookings-3': 'Belegungsplan Neu',
  bewohnerliste_rpt: 'Bewohnerliste RPT',
}

const fileNameDict = {
  'accommodation-bookings': 'Belegungsplan.xlsx',
  'accommodation-bookings-2': 'Belegungsplan.xlsx',
  'accommodation-bookings-3': 'Belegungsplan.xlsx',
  bewohnerliste_rpt: 'Bewohnerliste-RPT.xlsx',
}

@observer
export class AccommodationBookings extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null
  private readonly model = new Model<{
    oldVersion: boolean
    date: string
    time: string
    buildingIds: string
  }>(
    { oldVersion: false, date: null, time: '', buildingIds: null },
    z.object({
      date: z.string().refine(isPastDate),
      time: z.string().refine(isTime),
      buildingIds: z.string(),
    }),
  )
  @observable private loading: boolean = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private downloadReport = async () => {
    if (this.model.values.oldVersion && !this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (!this.model.values.buildingIds) {
      runInAction(() => (this.error = 'Es wurden keine Gebäude ausgewählt.'))
      return
    }
    runInAction(() => {
      this.loading = true
      this.error = null
    })
    const time = this.model.values.time.split(':')
    let timestamp
    this.model.values.oldVersion && !!this.model.values.date
      ? (timestamp = dayjs(this.model.values.date).add(+time[0], 'h').add(+time[1], 'm'))
      : (timestamp = dayjs(new Date()))
    await createAndDownloadReport(
      this.props.type,
      this.context.instance.id,
      {
        buildingIds: this.model.values.buildingIds,
        timestamp,
      },
      fileNameDict[this.props.type],
    )
    runInAction(() => (this.loading = false))
  }

  @action
  private toggleVersion = () => {
    this.model.values.oldVersion = !this.model.values.oldVersion
  }

  render() {
    if (!this.context.permissions.report_belegungsplan3) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title={titleDict[this.props.type]}
        subtitle='Wählen Sie die Gebäude aus, für die der Belegungsplan erstellt werden soll.'
      >
        <div id={this.model.id}>
          <InputBuildingListFoldable
            className='max-h-[800px] overflow-auto'
            name='buildingIds'
            model={this.model}
          />
          <div className='mt-6'>
            <button
              className={`text-sm ${
                this.model.values.oldVersion ? 'text-blue-500 underline' : 'text-gray-500'
              } hover:underline hover:text-blue-500`}
              onClick={this.toggleVersion}
            >
              {this.model.values.oldVersion
                ? 'Aktuelle Version herunterladen'
                : 'Vergangene Version herunterladen'}
            </button>
            {this.model.values.oldVersion && (
              <div className='mt-4 w-36'>
                <InputDate
                  label='Datum'
                  name='date'
                  model={this.model}
                  tooltip={(e) =>
                    e ? 'Das Datum darf nicht in der Zukunft liegen' : null
                  }
                />
                <InputText
                  className='mt-2'
                  label='Uhrzeit'
                  name='time'
                  model={this.model}
                  placeholder='HH:mm'
                />
              </div>
            )}
          </div>
        </div>
        {this.error && (
          <Message className='my-6' color='danger'>
            {this.error}
          </Message>
        )}
        <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
