import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { InputText } from 'components/Form/components/InputText'

const rxAgeBracket = /^ *([0-9]{1,2}) *(?:- *([0-9]{1,2}))? *$/

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

@observer
export class SelectAgeBracket extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private set0to5 = () => (this.props.model.values.ageBracket = '0-5')
  @action private set6to11 = () => (this.props.model.values.ageBracket = '6-11')
  @action private set12to15 = () => (this.props.model.values.ageBracket = '12-15')
  @action private set16to17 = () => (this.props.model.values.ageBracket = '16-17')

  @action private reset = () => {
    this.props.model.values.ageBracket = ''
    this.props.model.values.onlyHv = null
  }

  @action private setOnlyHv = () => {
    this.props.model.values.onlyHv = this.props.model.values.onlyHv ? null : 'yes'
  }

  render() {
    const ageBracket = (this.props.model.values.ageBracket || '').trim()
    const match = ageBracket.match(rxAgeBracket)
    let label = 'Alter'
    let color: string = 'text-gray-500'
    if (match) {
      let min = Math.min(+match[1], match[2] !== undefined ? +match[2] : +match[1])
      let max = Math.max(+match[1], match[2] !== undefined ? +match[2] : +match[1])
      label = min !== max ? `${min}-${max} Jahre alt` : `${min} Jahre alt`
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='grid grid-cols-2 gap-4'>
              <Button
                onClick={this.set0to5}
                color={ageBracket === '0-5' ? 'primary' : 'secondary'}
                outline={ageBracket !== '0-5'}
              >
                0 - 5
              </Button>
              <Button
                onClick={this.set6to11}
                color={ageBracket === '6-11' ? 'primary' : 'secondary'}
                outline={ageBracket !== '6-11'}
              >
                6 - 11
              </Button>
              <Button
                onClick={this.set12to15}
                color={ageBracket === '12-15' ? 'primary' : 'secondary'}
                outline={ageBracket !== '12-15'}
              >
                12 - 15
              </Button>
              <Button
                onClick={this.set16to17}
                color={ageBracket === '16-17' ? 'primary' : 'secondary'}
                outline={ageBracket !== '16-17'}
              >
                16 - 17
              </Button>
              <InputText
                className='col-span-2'
                hasError={!!ageBracket && !match}
                model={this.props.model}
                name='ageBracket'
                label='Altersgruppe'
                placeholder='z. B. 5 oder 5-10'
              />

              <div className='col-span-2'>
                <input
                  type='checkbox'
                  checked={this.props.model.values.onlyHv === 'yes'}
                  onChange={this.setOnlyHv}
                  className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                />{' '}
                &nbsp;Nur Haushaltsvorstände
              </div>
              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
