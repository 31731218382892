import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
import { encodeBarcode } from 'modules/Pdf/helpers/encodeBarcode'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { getResidentSexDe } from 'contracts/residents/helpers/getResidentSexDe'
import { getEarliestBookingInCurrentResponsibility } from 'modules/Residents/helpers/getEarliestBookingInCurrentResponsibility'
import logo from './vvslogo.jpg'

export class ScheckkarteBarcodeVVS extends React.Component<ResidentCardProps, {}> {
  render() {
    return (
      <>
        <div
          className={styles.page}
          style={this.props.batch ? {} : { pageBreakAfter: 'always' }}
        >
          <span style={{ fontSize: 18, position: 'absolute', left: 133, top: 8 }}>
            <b>Hausausweis</b>
          </span>
          <img
            src={getResidentImageSrc(
              this.props.instanceId,
              this.props.resident.imageId,
              this.props.resident.sex,
              'portrait',
            )}
            alt='Bewohnerbild'
            style={{ position: 'absolute', top: 11, left: 11, width: 112 }}
          />
          <span style={{ fontSize: 12, position: 'absolute', left: 133, top: 30 }}>
            {this.props.resident.data.accommodation?.building?.residentCardText || ''}
          </span>

          <div
            className='absolute grid grid-cols-2 gap-1.5 overflow-hidden'
            style={{
              left: 133,
              top: 50,
              right: 8,
              fontSize: 11,
              lineHeight: '12px',
              gridTemplateColumns: '80px auto',
            }}
          >
            {/* Vorname / JBP-ID */}
            <div>
              <div className='truncate' style={{ minWidth: 62 }}>
                {this.props.resident.firstName}&nbsp;
              </div>
              <div style={{ fontSize: 9 }}>Vorname</div>
            </div>
            <div>
              <div className='truncate'>{toJbpId(+this.props.resident.id)}&nbsp;</div>
              <div style={{ fontSize: 9 }}>JBP-ID</div>
            </div>

            {/* Nachname / T-ID */}
            <div>
              <div className='truncate' style={{ minWidth: 62 }}>
                {this.props.resident.lastName}&nbsp;
              </div>
              <div style={{ fontSize: 9 }}>Nachname</div>
            </div>
            <div>
              <div className='truncate'>
                {getResidentSexDe(this.props.resident.sex)}&nbsp;
              </div>
              <div style={{ fontSize: 9 }}>Geschlecht</div>
            </div>

            {/* Date of birth / nationality */}
            <div>
              <div>
                {this.props.resident.dateOfBirth
                  ? dayjs(this.props.resident.dateOfBirth).format('DD.MM.YYYY')
                  : '-'}
                &nbsp;
              </div>
              <div style={{ fontSize: 9 }}>Geburtsdatum</div>
            </div>
            <div className='truncate'>
              <div className='truncate'>
                {this.props.resident.data.countryOfBirth || '-'}
                &nbsp;
              </div>
              <div style={{ fontSize: 9 }}>Herkunftsland</div>
            </div>
          </div>

          <div
            className='text-center absolute'
            style={{
              fontFamily: 'Barcode',
              fontSize: 40,
              marginTop: -11,
              paddingBottom: 0,
              transform: 'scaleY(-2.3)',
              width: 340,
              bottom: 30,
            }}
          >
            {encodeBarcode(this.props.resident.token || 'EXAMPLE')}
          </div>
        </div>
        {!this.props.batch && (
          <div
            className={styles.page}
            style={this.props.batch ? {} : { pageBreakAfter: 'always' }}
          >
            <div className='mx-auto mt-2 w-fit' style={{ fontSize: 18 }}>
              <b>Hausausweis</b>
            </div>
            <div className='mx-auto w-fit' style={{ fontSize: 12 }}>
              {this.props.resident.data.accommodation?.building?.residentCardText || ''}
            </div>
            <div className='grid grid-cols-2 gap-4 px-4 pt-4' style={{ fontSize: 11 }}>
              <div className='flex flex-col gap-3'>
                <div className='mr-auto'>
                  <div className='truncate'>
                    {this.props.resident.data.accommodation?.building?.street}&nbsp;
                  </div>
                  <div className='truncate'>
                    {this.props.resident.data.accommodation?.building?.zip}{' '}
                    {this.props.resident.data.accommodation?.building?.city}&nbsp;
                  </div>
                  <div style={{ fontSize: 9 }}>Anschrift</div>
                </div>

                <div>
                  <div className='truncate' style={{ minWidth: 62 }}>
                    {this.props.resident.data.accommodation?.building?.label}&nbsp;
                  </div>
                  <div style={{ fontSize: 9 }}>Gebäude</div>
                </div>

                <div className='flex gap-2'>
                  <div style={{ maxWidth: 100 }}>
                    <div className='truncate'>
                      {this.props.resident.data.accommodation?.room?.label}&nbsp;
                    </div>
                    <div style={{ fontSize: 9 }}>Zimmer</div>
                  </div>
                  <div>
                    <div className='truncate'>
                      {this.props.resident.data.bookings
                        ? dayjs(
                            getEarliestBookingInCurrentResponsibility(
                              this.props.resident.data.bookings,
                            )?.beginAt,
                          ).format('DD.MM.YYYY')
                        : ''}
                      &nbsp;
                    </div>
                    <div style={{ fontSize: 9 }}>Eintrittsdatum</div>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div className='truncate' style={{ minWidth: 62 }}>
                    {this.props.resident.data.bvNr}&nbsp;
                  </div>
                  <div style={{ fontSize: 9 }}>BV-Nummer</div>
                </div>
                <div className='mt-2'>
                  <div className='truncate' style={{ minWidth: 62 }}>
                    {this.props.resident.data.wimNr}&nbsp;
                  </div>
                  <div style={{ fontSize: 9 }}>ID-Nummer</div>
                </div>
              </div>
            </div>
            <div className='absolute flex w-[150px]' style={{ bottom: 6, right: 4 }}>
              <img className='h-10' src={logo} alt='VVS Logo' />
              <span style={{ lineHeight: '0.6rem', fontSize: '0.5rem' }}>
                Ausweis berechtigt zur Fahrt mit VVS-Verkehrsmitteln <br />
                (2. Kl.) ausschließlich im Landkreis Ludwigsburg
              </span>
            </div>
          </div>
        )}
      </>
    )
  }
}
