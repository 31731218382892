import { z } from 'zod'
import { isIntegerString } from '../../general/helpers/isIntegerString'

export const TransferTargetValidator = z.object({
  id: z.string().refine(isIntegerString),
  instanceId: z.string().refine(isIntegerString),
  label: z.string().min(1).max(255),
  street: z.string(),
  zip: z.string(),
  city: z.string(),
  federalState: z.string(),
  contactPerson: z.string(),
  comment: z.string(),
})
