import { hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { IFederalTransfer } from 'contracts/residents/interfaces/IFederalTransfer'
import { IFederalTransfersDate } from 'contracts/residents/interfaces/IFederalTransfersDate'
import dayjs from 'dayjs'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { FederalTransfersList } from './components/FederalTransfersList'

interface Props {}

@observer
export class FederalTransfers extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IFederalTransfer>
  @observable private federalTransfersDate: IFederalTransfersDate | null = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({
      notificationDate: dayjs().format('YYYY-MM-DD'),
      residentId: null,
    })
  }

  componentDidMount() {
    this.loadFederalTransfersDate()
    this.disposers.push(
      reaction(
        () => toJS(this.model.values),
        () => this.loadFederalTransfersDate(),
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadFederalTransfersDate = async () => {
    const federalTransfersDate = await hermes.getOnceNew<IFederalTransfersDate>(
      `/api/${this.context.instance.id}/federalTransfersDates/${this.model.values.notificationDate}`,
    )
    runInAction(() => {
      this.federalTransfersDate = federalTransfersDate
    })
  }

  private setLockedAt = async (lockedAt: string | null) => {
    const res = await hermes.patch(
      `/api/${this.context.instance.id}/federalTransfersDates/${this.model.values.notificationDate}`,
      { lockedAt },
    )
    runInAction(() => (this.federalTransfersDate = res))
  }

  private download = async () => {
    if (!this.federalTransfersDate?.notificationDate) {
      box.alert('Leere Liste', 'Es befinden sich keine Verlegungen auf Liste.')
      return
    }
    try {
      createAndDownloadReport('federal-transfers', this.context.instance.id, {
        federalTransfersDate: this.federalTransfersDate.notificationDate,
      })
    } catch (_e) {
      box.alert(
        'Fehler',
        'Beim Herunterladen der Datei ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder wenden Sie sich an einen Systemadministrator.',
      )
    }
  }

  render() {
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full'>
        {/* Section heading */}
        <div className='relative my-7 pb-5 sm:pb-0 bg-white sm:rounded-lg shadow p-6'>
          <div className='flex items-center pb-6 gap-4'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Bundesweite Verlegung
            </h3>
            <InputDate
              className='ml-8'
              model={this.model}
              name='notificationDate'
              label='Meldungsdatum'
            />
            <Button onClick={this.download}>Download</Button>
            {this.context.permissions.menu_residentFederalTransfers > 1 &&
              (!this.federalTransfersDate || !this.federalTransfersDate.lockedAt) && (
                <Button onClick={() => this.setLockedAt(dayjs().toISOString())}>
                  Abschließen
                </Button>
              )}
            {this.context.permissions.menu_residentFederalTransfers === 3 &&
              this.federalTransfersDate?.lockedAt && (
                <Button color='warning' onClick={() => this.setLockedAt(null)}>
                  Abschluss rückgängig machen
                </Button>
              )}
          </div>
        </div>

        {/* Table */}
        <FederalTransfersList
          model={this.model}
          lockedAt={this.federalTransfersDate?.lockedAt}
        />
      </div>
    )
  }
}
