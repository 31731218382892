import { hermes } from '@byll/hermes'
import { Age } from 'components/Age'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputResident } from 'components/Form/components/InputResident'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { SideBar } from 'components/SideBarLayout/components/SideBar'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { ValidationError } from 'contracts/errors/HermesErrors'
import { IResidentWithResponsibleCompoundId } from 'contracts/inventory/interfaces/IResidentWithResponsibleCompoundId'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { getCurrentResponsibilityCompoundId } from 'contracts/residents/helpers/getCurrentResponsibilityCompoundId'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { IView } from '..'
import { getResidentScope } from '../helpers/getResidentScope'

interface Props {
  view: Model<IView>
}

@observer
export class InventoryScanSidebar extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model = new Model<{
    token: string
    residentId: string | null
    mode: 'search' | 'scan'
  }>({ token: '', residentId: null, mode: 'scan' })
  @observable private resident: IResidentWithResponsibleCompoundId | null = null
  @observable private familyMembers: IResidentWithResponsibleCompoundId[] = []
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', (event) => this.handleKeydown(event))
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', (event) => this.handleKeydown(event))
  }

  @action
  private handleKeydown = (event) => {
    if (this.model.values.mode !== 'scan') {
      return
    }
    if (/^[a-zA-Z0-9]$/.test(event.key)) {
      document.getElementById('tokenInput')?.focus()
    }
  }

  @action
  private onChangeCompound = () => {
    this.props.view.values.residentId = null
    this.props.view.values.responsibleCompoundId = null
    this.props.view.values.scope = null
    this.resident = null
  }

  private loadResident = async (e: React.KeyboardEvent) => {
    if (e.keyCode !== 13) {
      return
    }
    try {
      //change token because '.' is not allowed as id in a get request
      const newToken = this.model.values.token
        .replace(/\./g, '-')
        .concat(`-${this.props.view.values.compoundId}`)
      const response: {
        members: IResidentWithResponsibleCompoundId[]
        selected: string
      } = await hermes.getOnceNew(
        `/api/${this.context.instance.id}/inventory/residentScan/${newToken}`,
      )
      runInAction(() => {
        this.error = null
        this.familyMembers = response.members
        this.resident = response.members.find((m) => m.id === response.selected)!
        this.props.view.values.residentId = response.selected
        this.props.view.values.responsibleCompoundId = this.resident.responsibleCompoundId
        this.model.values.token = ''
        this.props.view.values.scope = getResidentScope(this.resident)
      })
    } catch (e: any) {
      if (e.id === ValidationError.id) {
        runInAction(() => {
          this.props.view.values.residentId = null
          this.props.view.values.scope = null
          this.error = 'Es existiert kein Bewohner mit dieser Ausweisnummer'
        })
      } else if (e.message === 'Card is not valid') {
        runInAction(() => {
          this.props.view.values.residentId = null
          this.props.view.values.scope = null
          this.error = 'Dieser Ausweis ist ungültig'
        })
      }
    }
  }

  private compoundFilter = (compound: ICompound) => compound.hasInventory

  @action private selectMember = (member: IResidentWithResponsibleCompoundId) => {
    this.error = null
    this.resident = member
    this.props.view.values.residentId = member.id
    this.props.view.values.responsibleCompoundId = member.responsibleCompoundId
    this.model.values.token = ''
    this.props.view.values.scope = getResidentScope(member)
  }

  private mapMember = (member: IResidentWithResponsibleCompoundId) => (
    <div
      onClick={() => this.selectMember(member)}
      key={member.id}
      className={`px-3 py-2 my-2 rounded-md cursor-pointer ${
        member.id === this.resident?.id ? 'border-2 border-blue-500' : 'border-none'
      } bg-gray-200`}
    >
      <div className='flex'>
        <div className='mr1 flex-auto truncate'>{`${member.lastName.toUpperCase()}, ${
          member.firstName
        }`}</div>
        <div className='flex-content'>
          <Age dateOfBirth={member.dateOfBirth} sex={member.sex} />
        </div>
      </div>
    </div>
  )

  @action private setModeScan = () => {
    this.model.values.residentId = null
    this.model.values.token = ''
    this.model.values.mode = 'scan'
  }
  @action private setModeSearch = () => {
    this.model.values.residentId = null
    this.model.values.token = ''
    this.model.values.mode = 'search'
  }

  private fetchFamily = async (residentId: string | null) => {
    if (!residentId) {
      return
    }
    runInAction(() => {
      this.model.values.residentId = null
      this.props.view.values.responsibleCompoundId = null
    })
    const resident = await hermes.getOnceNew<any>(
      `/api/${this.context.instance.id}/residents/${residentId}`,
    )
    const residents = await hermes.indexOnceNew<IResidentWithResponsibleCompoundId>(
      `/api/${this.context.instance.id}/residentSearchResults?familyId=${resident.familyId}&fields=bookings&sort=dateOfBirth,asc`,
    )
    for (const resident of residents) {
      resident.responsibleCompoundId = getCurrentResponsibilityCompoundId(
        resident.data.bookings,
      )
    }
    runInAction(() => {
      this.error = null
      this.familyMembers = residents
      this.resident = resident
      this.props.view.values.residentId = resident.id
      this.props.view.values.responsibleCompoundId =
        residents.find((r) => r.id === resident.id)?.responsibleCompoundId || null
      this.model.values.token = ''
      this.props.view.values.scope = getResidentScope(resident)
    })
  }

  private getFilter = (compoundId: string): IResidentSearchResultsFilter => ({
    responsibleCompoundId: compoundId,
    responsibleScope: 'active',
    page: '0,6',
  })

  render() {
    const portrait = getResidentImageSrc(
      this.context.instance.id,
      this.resident?.imageId ?? null,
      this.resident?.sex ?? null,
      'portrait',
    )
    return (
      <SideBar>
        <div className='absolute bg-red top-0 md:top-14 left-0 right-0 bottom-0 overflow-x-hidden overflow-y-auto'>
          {/* Caption and compound selection */}
          <div style={{ height: 130 }} className='z-10 sticky top-0 bg-white'>
            <div style={{ height: 64, lineHeight: '64px' }} className='px-6 text-lg'>
              <i className='fas fa-dolly-flatbed text-gray-400' />
              <span className='ml-3 text-gray-500'>Hygienemittelausgabe</span>
            </div>
            <div
              style={{ height: 66, paddingTop: 13 }}
              className='px-6 bg-gray-100 border-gray-200 border-t border-b'
              id='user-search-input'
            >
              <InputCompound
                autoSelectIfOnlyOneOption
                saveResponsibility
                model={this.props.view}
                name='compoundId'
                onChange={this.onChangeCompound}
                placeholder='Gelände'
                filter={this.compoundFilter}
                onlyStamm={this.context.permissions.menu_inventoryScan === 1}
              />
            </div>
          </div>

          {this.props.view.values.compoundId && (
            <div className='px-6'>
              <div className='text-gray-500' style={{ lineHeight: '56px' }}>
                Bewohner&nbsp;&nbsp;
                <span
                  onClick={this.setModeScan}
                  className={`cursor-pointer border border-blue-500 px-2 py-1 ${
                    this.model.values.mode === 'scan'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-600 bg-white'
                  }`}
                  style={{ borderRadius: '6px 0 0 6px' }}
                >
                  scannen
                </span>
                <span
                  onClick={this.setModeSearch}
                  className={`cursor-pointer border border-blue-500 px-2 py-1 ${
                    this.model.values.mode === 'search'
                      ? 'text-white bg-blue-500'
                      : 'text-gray-600 bg-white'
                  }`}
                  style={{ borderRadius: '0 6px 6px 0' }}
                >
                  suchen
                </span>
              </div>
              {this.model.values.mode === 'scan' && (
                <InputText
                  id='tokenInput'
                  model={this.model}
                  name='token'
                  placeholder='Barcode'
                  onKeyUp={this.loadResident}
                />
              )}
              {this.model.values.mode === 'search' && (
                <div style={{ lineHeight: 'initial' }}>
                  <InputResident
                    model={this.model}
                    name='residentId'
                    onChoose={this.fetchFamily}
                    filter={this.getFilter(this.props.view.values.compoundId)}
                  />
                </div>
              )}
            </div>
          )}

          {this.resident && !this.error && (
            <div className='p-6'>
              <img
                className='rounded-md'
                width={300}
                height={300}
                src={portrait}
                alt=''
              />
              <div className='mt-6 relative'>
                {this.familyMembers.map(this.mapMember)}
              </div>
            </div>
          )}

          {this.error && <div className='p-6 text-sm text-gray-500'>{this.error}</div>}
        </div>
      </SideBar>
    )
  }
}
