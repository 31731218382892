import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { Dayjs } from 'dayjs'
import { flags } from 'helpers/countries/flags'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { dayjs } from 'helpers/dayjs'
import { Age } from 'components/Age'
import { Link } from 'react-router-dom'

function getTimes(beginAt: string, endAt: string | null): string {
  const begin = dayjs(beginAt)
  if (!endAt) {
    return `ab ${begin.format('DD.MM.YYYY HH:mm')}`
  }
  return `${begin.format('DD.MM.YYYY HH:mm')} - ${dayjs(endAt).format(
    'DD.MM.YYYY HH:mm',
  )}`
}

interface Props {
  room: IRoom
  occupancy: IOccupancy
  at?: Dayjs
  onClose: () => void
}

@observer
export class RoomDetailsDialog extends React.Component<Props, {}> {
  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start mb-4'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {this.props.room.label}
            </Dialog.Title>
          </div>
        </div>

        {this.props.occupancy.beds.map((b) => (
          <div key={b.id} className='truncate'>
            {b.bed && (
              <span
                className={`mr-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  b.bed > +this.props.room.capacity || b.lock !== null
                    ? 'bg-red-500'
                    : 'bg-gray-500'
                } text-white`}
              >
                {b.lock !== null && (
                  <span className='mr-1'>
                    <i className='fas fa-lock' />
                  </span>
                )}
                {b.bed}
              </span>
            )}
            {flags[b.resident?.flag || ''] && (
              <img
                alt={b.resident!.nationality}
                src={flags[b.resident?.flag || ''] || ''}
                className='w-5 h-5 mr-1 inline-flex rounded-full'
              />
            )}
            {b.resident?.isBaby && (
              <span className='mr-1 text-gray-500'>
                <i className='fas fa-baby-carriage' />
              </span>
            )}
            {b.type === 'internal-reservation' && (
              <span className='mr-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border border-indigo-500 text-indigo-500'>
                Reserviert
              </span>
            )}
            {b.type === 'internal-reservation' && !b.resident && (
              <span className='text-gray-400'>·&nbsp;&nbsp;{b.comment}</span>
            )}
            {b.resident && (
              <span>
                <Age dateOfBirth={b.resident.dateOfBirth} sex={b.resident.sex as any} />
                <Link
                  className='text-gray-900 mx-2 underline hover:text-blue-500'
                  to={`/residents/${toJbpId(+b.resident.id).toLowerCase()}/accommodation`}
                  target='_blank'
                >
                  {b.resident.name}
                </Link>
                <span className='text-gray-400'>{getTimes(b.beginAt, b.endAt)}</span>
              </span>
            )}
            {b.type === 'empty-bed' && b.lock && (
              <span className='text-gray-400'>
                {b.lock.reason}
                {b.lock.notes && <>&nbsp;&nbsp;·&nbsp;&nbsp;{b.lock.notes}</>}
              </span>
            )}
            {b.type === 'empty-bed' && !b.lock && (
              <span className='text-gray-400'>Leeres Bett</span>
            )}
          </div>
        ))}
      </>
    )
  }
}
