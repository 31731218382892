import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Model } from 'components/Form/Model'
import { action } from 'mobx'
import { IResidentCostsFilterValidator } from 'contracts/costCoverages/interfaces/IResidentCostsFilterValidator'
import { observer } from 'mobx-react'

interface Props {
  model: Model<IResidentCostsFilterValidator>
}

const items = [
  { value: 'all', label: 'Alle' },
  { value: 'not billed', label: 'Entwurf' },
  { value: 'billable', label: 'Abrechenbar' },
  { value: 'billed', label: 'Abgerechnet' },
  { value: 'missing resident signature', label: 'UKN fehlt' },
  { value: 'invalid', label: 'Ungültige KÜ' },
  { value: 'changes', label: 'Nach Abrechnung verändert' },
]

export const CostCoverageScopeDropdown: React.FC<Props> = observer((props) => (
  <Menu
    as='span'
    className='relative inline-block text-left'
    onClick={(e) => e.stopPropagation()}
  >
    <div>
      <Menu.Button className='whitespace-nowrap text-white bg-gradient-to-r from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500 py-[9px] px-[17px] text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2'>
        {items.filter((i) => i.value === props.model.values.costCoverageScope)[0]?.label}{' '}
        <i className='fas fa-caret-down' />
      </Menu.Button>
    </div>

    <Transition
      as={React.Fragment}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <Menu.Items className='z-10 origin-top-right absolute left-0 mt-2 w-52 rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none'>
        <div className='py-1'>
          {items.map((r) => (
            <Menu.Item key={r.value}>
              {({ active }) => (
                <span
                  className={`${
                    active && 'bg-blue-500 text-white'
                  } cursor-pointer block px-4 py-2 text-sm`}
                  onClick={action(() => {
                    props.model.values.costCoverageScope = r.value as any
                  })}
                >
                  {r.label}
                </span>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
))
