import { IStundenzettelData } from 'contracts/workplan/interfaces/IStundenzettelData'
import * as React from 'react'
import styles from '../styles.module.scss'

interface Props {
  data: IStundenzettelData
}

export const StundenzettelSignature: React.FC<Props> = (props) => (
  <div className={styles.signature}>
    <div style={{ margin: '40px 0 110px 0' }}>
      Hiermit bestätige ich die Richtigkeit meiner oben aufgeführten Arbeitszeiten.
      <br />
      <b>Wichtig:</b> Bitte wenden Sie sich umgehend an die Personalabteilung,
      <br />
      falls dieser Stundenzettel unrichtige Angaben enthält.
    </div>

    <div className='flex'>
      <div style={{ flex: '0 0 200px', borderTop: '1px dashed black' }}>Ort, Datum</div>
      <div
        className='truncate'
        style={{
          flex: '0 0 350px',
          borderTop: '1px dashed black',
          marginLeft: 100,
          marginRight: 'auto',
          maxWidth: 350,
        }}
      >
        {props.data.employeeName}
      </div>
    </div>
  </div>
)
