import { hermes } from '@byll/hermes'
import { ITodoPatch } from 'contracts/todos/interfaces/ITodoPatch'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'

export async function updateTodo(
  todo: ITodoSearchResult & {
    isDone: boolean
    assigneeIds: string[]
    watcherIds: string[]
    hasTodo: boolean
  },
  compoundId: string,
  instanceId: string,
) {
  const patchable: ITodoPatch = {
    compoundId,
    priority: todo.priority,
    dueDate: todo.dueDate,
    doneDate: todo.isDone ? todo.doneDate : null,
    label: todo.label,
    notes: todo.notes,
    gateMessage: todo.gateMessage,
    gateMessageValidTill: todo.gateMessageValidTill,
    assigneeIds: todo.assigneeIds,
    watcherIds: todo.watcherIds,
  }
  await hermes.patch(
    `/api/${instanceId}/todoLists/${todo.listId}/todos/${todo.id}`,
    patchable,
  )
}
