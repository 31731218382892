import { Card } from 'components/Card'
import { Button } from 'components/Form/components/Button'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { InventoryBundleTopBar } from './InventoryBundleTopBar'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'

interface Props {
  bundle: IInventoryBundle
  setEdit: (edit: boolean) => void
}

@observer
export class InventoryBundlePreview extends React.Component<Props, {}> {
  static contextType = AppContext

  render() {
    const bundle = this.props.bundle
    return (
      <>
        <InventoryBundleTopBar bundle={bundle} />

        <CardColumn>
          <Card>
            <div className='p-6'>
              <div className='text-xl text-gray-500'>{bundle.label}</div>
              <div className='mt-4 grid max-w-2xl grid-cols-1 sm:grid-cols-2 gap-5'>
                <img
                  className='rounded-md w-full sm:w-min'
                  width={300}
                  height={300}
                  src={getInventoryImageSrc(bundle.imageId, this.context.instance.id)}
                  alt=''
                />
                <div className='gap-0 grid grid-cols-2'>&nbsp;</div>
              </div>
              <div className='mt-4 flex flex-col gap-4 sm:flex-row justify-start'>
                {this.context.permissions.inventory_manageItems && (
                  <Button onClick={() => this.props.setEdit(true)}>
                    Paket bearbeiten
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </CardColumn>
      </>
    )
  }
}
