import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IUserDefaults } from 'contracts/users/interfaces/IUserDefaults'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { SortableKeysList } from './SortableKeysList'
interface Props {
  model: Model<IResidentSearchResultsFilter>
  config: IUserDefaults
}

@observer
export class SelectColumns extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setToList = () => {
    // Set both, model and default (for next search)
    this.props.model.values.view = 'list'
    this.props.model.values.sort = null
    this.props.config.residentSearch = {
      ...this.props.config.residentSearch,
      view: 'list',
    }
  }
  @action private setToTile = () => {
    // Set both, model and default (for next search)
    this.props.model.values.view = 'tile'
    this.props.model.values.sort = null
    this.props.config.residentSearch = {
      ...this.props.config.residentSearch,
      view: 'tile',
    }
  }
  @action private toggle = (key: string) => {
    const keys = this.props.model.values.fields?.split(',') ?? []
    if (keys.indexOf(key) === -1) {
      keys.push(key)
    } else {
      keys.splice(keys.indexOf(key), 1)
    }
    this.props.config.residentSearch = {
      ...this.props.config.residentSearch,
      fields: keys.join(','),
    }
    this.props.model.values.sort = null
    this.props.model.values.fields = keys.join(',')
  }

  render() {
    const view = this.props.model.values.view
    return (
      <Menu as='div' className='relative'>
        <div>
          <Menu.Button
            style={{ width: 38, borderRadius: '0 6px 6px 0', padding: '9px 0' }}
            className='border-r border-transparent hover:border-indigo-700 text-white bg-gradient-to-r from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500 py-[9px] px-[17px]  text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2'
          >
            <i className='fas fa-th' />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className='origin-top-left absolute right-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-[260px] overflow-auto'
            style={{ maxHeight: '50vh' }}
          >
            <div className='flex'>
              <Button
                onClick={this.setToList}
                color={view === 'list' ? 'primary' : 'secondary'}
                outline={view !== 'list'}
                className='flex-auto'
                style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
              >
                Liste
              </Button>
              <Button
                onClick={this.setToTile}
                color={view === 'tile' ? 'primary' : 'secondary'}
                outline={view !== 'tile'}
                className='flex-auto'
                style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
              >
                Kacheln
              </Button>
            </div>
            {view === 'list' && (
              <div className='mt-2'>
                <SortableKeysList
                  model={this.props.model}
                  config={this.props.config}
                  onToggle={this.toggle}
                />
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
