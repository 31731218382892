import { Component } from 'react'
import { observer } from 'mobx-react'
import { InputCompound } from '../../components/Form/components/InputCompound'
import { AppContext, AppContextProps } from '../../services/connection/models/AppContext'
import { Model } from '../../components/Form/Model'
import { InputGroups } from '../../components/Form/components/InputGroups'
import { Button } from '../../components/Form/components/Button'
import { isStammCompound } from '../../helpers/isStamm'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { ParticipantsList } from './components/ParticipantsList'
import { box } from '../../services/box'
import { NewGroupDialog } from './components/NewGroupDialog'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { createAndDownloadReport } from '../../helpers/createAndDownloadReport'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { Callout } from 'components/Callout'
import { hermes } from '@byll/hermes'
import { IGroup } from 'contracts/groups/interfaces/IGroup'
import { Tooltip } from 'components/Tooltip'
import { toast } from 'react-toastify'
import { EditGroupDialog } from './components/EditGroupDialog'
import { storage } from 'services/storage'

interface Props {}

@observer
export class Groups extends Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ compoundId: string | null; groupId: string | null }>
  private readonly disposers: Disposer[] = []
  @observable private downloading = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      compoundId: context.defaults.responsibilityCompoundId,
      groupId: null,
    })
    if (
      context.permissions.menu_groups === 1 &&
      !isStammCompound(this.model.values.compoundId || '')
    ) {
      this.model.values.compoundId = null
    }
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.model.values.compoundId,
        () => (this.model.values.groupId = null),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private createGroup = async () => {
    if (!this.context.permissions.groups_create) {
      void box.alert(
        'Gruppe erstellen',
        'Sie haben nicht die nötige Berechtigung, um Gruppen zu erstellen.',
        { color: 'danger' },
      )
      return
    }
    if (!this.model.values.compoundId) {
      void box.alert('Gruppe erstellen', 'Bitte wählen Sie zunächst eine Unterkunft aus.')
      return
    }
    const promise = box.custom(
      <NewGroupDialog
        onClose={(id?: string) => promise.close(id)}
        compoundId={this.model.values.compoundId}
      />,
      { context: this.context },
    )

    const id = await promise
    if (id) {
      runInAction(() => (this.model.values.groupId = id))
      storage.set(`resident-group-${this.context.user.id}`, id)
    }
  }

  private download = async () => {
    const group = this.model.values.groupId
      ? hermes.getFromStore<IGroup>(
          `/api/${this.context.instance.id}/groups/${this.model.values.groupId}`,
          false,
        )
      : null
    if (!group) {
      void box.alert('Download', 'Bitte wählen Sie zunächst eine Gruppe aus.')
      return
    }
    runInAction(() => (this.downloading = true))
    try {
      await createAndDownloadReport(
        'gruppe',
        this.context.instance.id,
        { compoundId: this.model.values.compoundId, groupId: this.model.values.groupId },
        `${group.label.replace(/[^A-Za-z0-9äöüÄÖÜß _-]/g, '') || 'Gruppe'}.xlsx`,
      )
    } catch (_e) {
      /* */
    }
    runInAction(() => (this.downloading = false))
  }

  private deleteGroup = async () => {
    const group = this.model.values.groupId
      ? hermes.getFromStore<IGroup>(
          `/api/${this.context.instance.id}/groups/${this.model.values.groupId}`,
          false,
        )
      : null
    if (!group) {
      return
    }

    // Check permissions
    if (
      this.context.permissions.groups_delete === 0 ||
      (this.context.permissions.groups_delete === 1 &&
        this.context.user.id !== group.createdBy)
    ) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um diese Gruppe vollständig zu löschen.',
        { color: 'danger' },
      )
      return
    }

    if (
      await box.alert(
        'Gruppe löschen',
        'Möchten Sie diese Gruppe wirklich unwiderbringlich löschen?',
        { confirm: 'Ja, löschen', cancel: 'Abbrechen' },
      )
    ) {
      try {
        await hermes.delete(`/api/${this.context.instance.id}/groups/${group.id}`)
        runInAction(() => (this.model.values.groupId = null))
        toast.success('Gruppe erfolgreich gelöscht.')
      } catch (e) {
        void box.alert(
          'Gruppe löschen',
          'Beim Löschen der Gruppe ist ein Fehler aufgetreten.',
          { color: 'danger' },
        )
      }
    }
  }

  private archiveGroup = async () => {
    const group = this.model.values.groupId
      ? hermes.getFromStore<IGroup>(
          `/api/${this.context.instance.id}/groups/${this.model.values.groupId}`,
          false,
        )
      : null
    if (!group) {
      return
    }

    // Check permissions
    if (
      this.context.permissions.groups_archive === 0 ||
      (this.context.permissions.groups_archive === 1 &&
        this.context.user.id !== group.createdBy)
    ) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um diese Gruppe zu archivieren.',
        { color: 'danger' },
      )
      return
    }

    if (
      await box.alert(
        'Gruppe archivieren',
        'Möchten Sie diese Gruppe wirklich archivieren?',
        { confirm: 'Ja, archivieren', cancel: 'Abbrechen' },
      )
    ) {
      try {
        await hermes.patch(`/api/${this.context.instance.id}/groups/${group.id}`, {
          deletedAt: new Date().toISOString(),
        })
        toast.success('Gruppe erfolgreich archiviert.')
      } catch (e) {
        void box.alert(
          'Gruppe archivieren',
          'Beim Archivieren der Gruppe ist ein Fehler aufgetreten.',
          { color: 'danger' },
        )
      }
    }
  }

  private restoreGroup = async () => {
    const group = this.model.values.groupId
      ? hermes.getFromStore<IGroup>(
          `/api/${this.context.instance.id}/groups/${this.model.values.groupId}`,
          false,
        )
      : null
    if (!group) {
      return
    }

    // Check permissions
    if (
      this.context.permissions.groups_archive === 0 ||
      (this.context.permissions.groups_archive === 1 &&
        this.context.user.id !== group.createdBy)
    ) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um diese Gruppe wiederherzustellen.',
        { color: 'danger' },
      )
      return
    }

    if (
      await box.alert(
        'Gruppe wiederherstellen',
        'Möchten Sie diese Gruppe wirklich wiederherstellen?',
        { confirm: 'Ja, wiederherstellen', cancel: 'Abbrechen' },
      )
    ) {
      try {
        await hermes.patch(`/api/${this.context.instance.id}/groups/${group.id}`, {
          deletedAt: null,
        })
        toast.success('Gruppe erfolgreich wiederhergestellt.')
      } catch (e) {
        void box.alert(
          'Gruppe wiederherstellen',
          'Beim Wiederherstellen der Gruppe ist ein Fehler aufgetreten.',
          { color: 'danger' },
        )
      }
    }
  }

  private editGroup = async () => {
    const group = this.model.values.groupId
      ? hermes.getFromStore<IGroup>(
          `/api/${this.context.instance.id}/groups/${this.model.values.groupId}`,
          false,
        )
      : null
    if (!group) {
      return
    }

    // Check permissions
    if (
      this.context.permissions.groups_edit === 0 ||
      (this.context.permissions.groups_edit === 1 &&
        this.context.user.id !== group.createdBy)
    ) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um diese Gruppe zu bearbeiten.',
        { color: 'danger' },
      )
      return
    }

    const promise = box.custom(
      <EditGroupDialog onClose={() => promise.close()} group={group} />,
      { context: this.context },
    )
  }

  render() {
    if (this.context.permissions.menu_groups === 0) {
      return <Forbidden />
    }

    const group = this.model.values.groupId
      ? hermes.getFromStore<IGroup>(
          `/api/${this.context.instance.id}/groups/${this.model.values.groupId}`,
          false,
        )
      : null

    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full flex flex-col'>
        <div className='relative my-7 bg-white sm:rounded-lg shadow p-6 flex-content flex gap-4'>
          <InputCompound
            className={'flex-1'}
            name={'compoundId'}
            model={this.model}
            onlyStamm={this.context.permissions.menu_groups === 1}
            saveResponsibility
          />
          <InputGroups
            preselect
            archived
            className={'flex-1'}
            name={'groupId'}
            model={this.model}
            compoundId={this.model.values.compoundId}
            key={this.model.values.compoundId}
          />
          {group && (
            <div className='inline-flex' role='group'>
              {group.label.startsWith('[ARCHIV]') && (
                <>
                  <Button
                    onClick={this.restoreGroup}
                    type='button'
                    color='warning'
                    className='border-r border-transparent hover:border-warning-700 has-tooltip'
                    style={{ width: 39, borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                  >
                    <i className='fas fa-redo' />
                    <Tooltip>Gruppe wiederherstellen</Tooltip>
                  </Button>
                  <Button
                    onClick={this.deleteGroup}
                    type='button'
                    color='danger'
                    className='border-r border-transparent hover:border-danger-700 has-tooltip'
                    style={{ width: 39, borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                  >
                    <i className='fas fa-trash' />
                    <Tooltip>Gruppe vollständig löschen</Tooltip>
                  </Button>
                </>
              )}
              {!group.label.startsWith('[ARCHIV]') && (
                <>
                  <Button
                    onClick={this.archiveGroup}
                    type='button'
                    className='border-r border-transparent hover:border-indigo-700 has-tooltip'
                    style={{ width: 39, borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                  >
                    <i className='fas fa-archive' />
                    <Tooltip>Gruppe archivieren</Tooltip>
                  </Button>
                  <Button
                    onClick={this.editGroup}
                    type='button'
                    className='border-r border-transparent hover:border-indigo-700 has-tooltip'
                    style={{ width: 39, borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                  >
                    <i className='fas fa-pencil-alt' />
                    <Tooltip>Gruppe bearbeiten</Tooltip>
                  </Button>
                </>
              )}
            </div>
          )}

          <div className='inline-flex' role='group'>
            <Button
              onClick={this.createGroup}
              type='button'
              className='border-r border-transparent hover:border-indigo-700 has-tooltip'
              style={{ width: 39, borderRadius: '6px 0 0 6px', padding: '9px 0' }}
            >
              <i className='fas fa-plus' />
              <Tooltip>Neue Gruppe</Tooltip>
            </Button>
            <Button
              onClick={this.download}
              type='button'
              className='border-r border-transparent hover:border-indigo-700 has-tooltip'
              style={{ width: 39, borderRadius: '0 6px 6px 0', padding: '9px 0' }}
            >
              {this.downloading ? (
                <i className='fas fa-sync fa-spin' />
              ) : (
                <i className='fas fa-download' />
              )}
              <Tooltip position='left'>Download Teilnehmerliste</Tooltip>
            </Button>
          </div>
        </div>

        {!group && (
          <div className='bg-white rounded-md shadow-md p-4 flex-auto flex flex-col mb-6'>
            <Callout
              icon='fas fa-th-list'
              title='Bewohnergruppen'
              subtitle='Bitte wählen Sie eine Gruppe'
            />
          </div>
        )}

        {group && <ParticipantsList key={`${group.id}.${group.version}`} group={group} />}
      </div>
    )
  }
}
