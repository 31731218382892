import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { DocumentDropzone } from 'modules/Documents/components/DocumentDropzone'
import { ResidentDropzoneView } from 'modules/Documents/components/ResidentDropzoneView'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { isPreviewImage } from '../helpers/isPreviewImage'
import { ImagePreview } from './ImagePreview'
import { PdfPreview } from './PdfPreview'
import { OtherPreview } from './OtherPreview'
import { dayjs } from 'helpers/dayjs'
import { getDiskSizeLabel } from 'contracts/general/helpers/getDiskSizeLabel'
import { Button } from 'components/Form/components/Button'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import { DialogOverlayArea } from 'components/Dialog/components/DialogOverlaySpinner/components/DialogOverlayArea'
import { CircularUploadProgress } from './CircularUploadProgress'
import { Message } from 'components/Message'
import { AppContext } from 'services/connection/models/AppContext'
import axios, { AxiosProgressEvent } from 'axios'
import { sleep } from 'helpers/sleep'
import { RoundIcon } from 'components/RoundIcon'
import { download } from 'helpers/download'
import { Spinner } from 'components/Spinner'
import { PrintIcon } from './PrintIcon'
import { IResident } from 'contracts/residents/interfaces/IResident'

interface Props {
  onClose: () => void
  folder: IDocumentFolder
  document: IDocumentMetadata & { file?: Blob; hasUnsavedChanges?: boolean } // New document: document.id = ''
  resident: IResident
  filter?: any // object (will be converted to additional query params during search )
}

@observer
export class DocumentDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private readonly model: Model<
    IDocumentMetadata & { file?: Blob; hasUnsavedChanges?: boolean }
  >
  @observable private upload: { percentage: number } | null = null
  @observable error: string | null = null
  private cancelUpload: (() => void) | null = null
  private cancelDownload: (() => void) | null = null

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.document)
    makeObservable(this)
  }

  componentDidMount() {
    if (
      this.model.values.id &&
      (isPreviewImage(this.model.values.name) ||
        this.model.values.name.toLowerCase().endsWith('.pdf'))
    ) {
      // Download saved document that can be previewed
      void this.loadPreview(this.model.values.id)
    }
  }

  componentWillUnmount() {
    this.cancelUpload?.()
    this.cancelDownload?.()
  }

  private loadPreview = async (id: string) => {
    try {
      const source = axios.CancelToken.source()
      this.cancelDownload = () => source.cancel('Canceled by user')
      const response = await axios.get(
        `/api/${this.context.instance.id}/documents/files/${id}`,
        { cancelToken: source.token, responseType: 'arraybuffer' },
      )
      this.cancelDownload = null
      if (this.model.values.id === id) {
        runInAction(() => (this.model.values.file = new Blob([response.data])))
      }
    } catch (_e) {
      /* */
    }
  }

  @action
  private onSelectFile = (doc: IDocumentMetadata & { file: Blob }) => {
    this.model.values.file = doc.file
    this.model.values.name = doc.name
    this.model.values.size = doc.size
    this.model.values.createdAt = doc.createdAt
    this.model.values.hasUnsavedChanges = true
  }

  private onSubmit = async () => {
    if (!this.model.values.file) {
      alert('Bitte wählen Sie vor dem Upload eine Datei aus.')
      return
    }

    const form = new FormData()
    form.append('folderId', this.props.folder.id)
    form.append('name', this.model.values.name)
    if (this.model.values.residentId) {
      form.append('residentId', this.model.values.residentId)
    }
    if (this.model.values.familyId) {
      form.append('familyId', this.model.values.familyId)
    }
    form.append('file', this.model.values.file)

    const source = axios.CancelToken.source()
    this.cancelUpload = () => source.cancel('Canceled by user')
    const uploadPromise = axios.post(
      `/api/${this.context.instance.id}/documents/files`,
      form,
      {
        cancelToken: source.token,
        onUploadProgress: action((event: AxiosProgressEvent) => {
          if (event.total && event.loaded / event.total <= 1 && this.upload) {
            // Start at 5% to visualize pending upload for files that are scheduled to upload.
            this.upload.percentage = 0.05 + 0.95 * (event.loaded / event.total)
          }
        }),
      },
    )
    try {
      runInAction(() => {
        this.upload = { percentage: 0.05 }
        this.error = null
      })
      await uploadPromise
      this.cancelUpload = null
      runInAction(() => {
        if (this.upload) {
          this.upload.percentage = 1
        }
      })
      await sleep(300)
      if (typeof this.props.document.hasUnsavedChanges === 'boolean') {
        runInAction(() => (this.props.document.hasUnsavedChanges = false))
      }
      this.props.onClose()
    } catch (e) {
      if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
        // Already handeled
      } else {
        runInAction(() => {
          this.upload = null
          this.error = 'Beim Upload ist ein Fehler aufgetreten'
        })
      }
    }
  }

  @action
  private setFamily = () => {
    this.model.values.familyId = this.props.resident.familyId
    this.model.values.residentId = null
  }

  @action
  private setResident = () => {
    this.model.values.familyId = null
    this.model.values.residentId = this.props.resident.id
  }

  private onDownload = () => {
    download(`/api/${this.context.instance.id}/documents/files/${this.model.values.id}`)
  }

  render() {
    const isNew = !this.props.document.id
    const isFamilyDoc = !!this.model.values.familyId
    let preview: JSX.Element | null = null
    if (isPreviewImage(this.model.values.name)) {
      if (this.model.values.file) {
        preview = (
          <ImagePreview
            id={this.model.values.id}
            name={this.model.values.name}
            cache={this.model.values.file}
          >
            {!isNew && (
              <RoundIcon
                icon='fas fa-download'
                tooltip='Herunterladen'
                style={{ position: 'absolute', top: 16, right: 16 }}
                color='primary'
                onClick={this.onDownload}
              />
            )}
          </ImagePreview>
        )
      } else {
        preview = <Spinner delay className='shadow bg-white relative flex-auto' />
      }
    } else if (this.model.values.name.toLowerCase().endsWith('.pdf')) {
      if (this.model.values.file) {
        preview = (
          <PdfPreview cache={this.model.values.file}>
            {!isNew && (
              <RoundIcon
                icon='fas fa-download'
                tooltip='Herunterladen'
                style={{ position: 'absolute', top: 16, right: 16 }}
                color='primary'
                onClick={this.onDownload}
              />
            )}
            {!isNew && (
              <PrintIcon
                url={`/api/${this.context.instance.id}/documents/files/${this.model.values.id}`}
                style={{ position: 'absolute', top: 54, right: 16 }}
              />
            )}
          </PdfPreview>
        )
      } else {
        preview = <Spinner delay className='shadow bg-white relative flex-auto' />
      }
    } else {
      preview = (
        <OtherPreview
          id={this.model.values.id}
          name={this.model.values.name}
          cache={this.model.values.file}
        >
          {!isNew && (
            <RoundIcon
              icon='fas fa-download'
              tooltip='Herunterladen'
              style={{ position: 'absolute', top: 16, right: 16 }}
              color='primary'
              onClick={this.onDownload}
            />
          )}
        </OtherPreview>
      )
    }

    return (
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {isFamilyDoc ? 'Familiendokument' : 'Bewohnerdokument'}
              </Dialog.Title>
              {isNew && (
                <div className='truncate text-gray-400 text-sm mt-1'>
                  <i className='far fa-folder' /> {this.props.folder.label}
                </div>
              )}
              {!isNew && (
                <div className='truncate text-gray-400 text-sm mt-1'>
                  Upload von {this.model.values.uploadUser}
                  &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                  {dayjs(this.model.values.createdAt).format('DD.MM.YYYY')}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='bg-gray-100 p-6'>
          {this.error && (
            <Message
              color='danger'
              className='mb-4 border border-red-500 sticky top-0 z-10'
            >
              {this.error}
            </Message>
          )}
          {isNew && !this.model.values.file && (
            <DocumentDropzone
              scope='resident'
              onSelect={this.onSelectFile}
              view={ResidentDropzoneView}
            />
          )}

          {(!isNew || this.model.values.file) && this.model && (
            <>
              <div className='text-gray-400 text-sm mb-1 truncate'>
                {this.model.values.name}&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                {getDiskSizeLabel(this.model.values.size)}
              </div>
              <div className='min-h-[280px] text-center flex flex-col'>{preview}</div>
            </>
          )}
        </div>

        <div
          className='flex py-4 px-6 sticky bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <div className='flex-auto'>
            <Button
              disabled={!isNew}
              color='secondary'
              onClick={this.setResident}
              outline={isFamilyDoc}
              style={{ borderRadius: '6px 0 0 6px' }}
            >
              Bewohner
            </Button>
            <Button
              disabled={!isNew}
              color='secondary'
              onClick={this.setFamily}
              outline={!isFamilyDoc}
              style={{ borderRadius: '0 6px 6px 0' }}
            >
              Familie
            </Button>
          </div>
          <div className='flex-content'>
            <Button
              disabled={!!this.upload}
              color='secondary'
              outline={isNew}
              onClick={this.props.onClose}
            >
              {isNew ? 'Abbrechen' : 'Schließen'}
            </Button>
            {isNew && (
              <Button
                disabled={!!this.upload}
                color='primary'
                className='ml-2'
                onClick={this.onSubmit}
              >
                Upload
              </Button>
            )}
          </div>
        </div>

        {this.upload && !this.error && (
          <DialogOverlayArea opaque>
            <CircularUploadProgress onCancel={this.props.onClose} upload={this.upload} />
          </DialogOverlayArea>
        )}
      </div>
    )
  }
}
