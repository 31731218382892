import { Spinner } from 'components/Spinner'
import { IMigration } from 'contracts/migrations/interfaces/IMigration'
import * as React from 'react'
import { getGridColumns } from './helpers/getGridColumns'
import axios from 'axios'
import { AppContext } from 'services/connection/models/AppContext'
import { IImportData } from 'contracts/migrations/interfaces/IImportData'
import { runInAction } from 'mobx'
import { InputListItemCheckbox } from 'components/Form/components/InputCheckbox/InputListItemCheckbox'

interface Props {
  migration: IMigration
  selected: Set<string>
  disabled?: boolean
}

export const MigrationGrid: React.FC<Props> = ({ migration, selected, disabled }) => {
  const context = React.useContext(AppContext)
  const [data, setData] = React.useState<IImportData | null>(null)
  const [error, setError] = React.useState<string | null>(null)
  React.useEffect(() => {
    if (!migration) {
      return
    }
    axios
      .get<IImportData>(`/api/${context.instance.id}/imports/${migration.id}/data`, {
        withCredentials: true,
      })
      .then((response) => {
        runInAction(() => {
          selected.clear()
          for (let i = 0; i < response.data.rows.length; i++) {
            if (response.data.rows[i].selected) {
              selected.add(String(i))
            }
          }
        })
        setData(response.data)
      })
      .catch(() => setError('Der Import konnte nicht geladen werden.'))
    // eslint-disable-next-line
  }, [])

  if (error) {
    return <div className='p-4 text-red-500'>{error}</div>
  }

  if (!data) {
    return <Spinner delay />
  }

  if (data.columns.length === 0) {
    return <div className='p-4 text-gray-500'>Bisher keine Spalten zugeordnet.</div>
  }

  function toggleSelection(id: string, selected: boolean) {
    void axios.patch<IImportData>(
      `/api/${context.instance.id}/imports/${migration.id}/selectedRows/${id}`,
      { selected },
      {
        withCredentials: true,
      },
    )
  }

  return (
    <div className='p-4 min-w-max'>
      <div
        className='grid text-sm divide-x divide-y'
        style={{ gridTemplateColumns: getGridColumns(data.columns.length) }}
      >
        {/* Captions */}
        {data.columns.map((c, j) => (
          <div key={j} className='truncate max-w-[200px] p-2'>
            <div>{c.from}</div>
            <div className='text-xs text-indigo-500'>
              <span className='mr-1'>
                <i className='fas fa-arrow-right' />
              </span>
              {c.to}
            </div>
          </div>
        ))}

        {/* Entries */}
        {data.rows.map((r, i) => (
          <React.Fragment key={i}>
            {r.cells.map((c, j) => (
              <div
                key={j}
                className={`truncate max-w-[200px] p-2 ${
                  c.color || (c.valid ? '' : 'text-red-500')
                }`}
              >
                {j === 0 && (
                  <InputListItemCheckbox
                    selected={selected}
                    id={String(i)}
                    disabled={disabled}
                    className='mr-2'
                    onChange={toggleSelection}
                  />
                )}
                {c.href && (
                  <a href={c.href} target='_blank' rel='noreferrer' className='mr-1'>
                    <i className='fas fa-external-link-alt' />
                  </a>
                )}
                {c.value}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
