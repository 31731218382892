import { IEmployeeCommThreadSalarySlipExtra } from '../interfaces/IEmployeeCommThread'

export function isValidSalarySlip(extra: IEmployeeCommThreadSalarySlipExtra): boolean {
  return (
    extra.contract.result === 'valid' &&
    extra.content.result === 'valid' &&
    extra.document === 'available' &&
    extra.receiver.contact !== '' &&
    !!extra.receiver.iban
  )
}
