export const getBuildingData = (resident) => {
  if (
    (resident.data.accommodation.building && resident.data.accommodation.room) !==
    undefined
  ) {
    return (
      resident.data.accommodation?.building?.label +
      ' / ' +
      resident.data.accommodation?.room?.label
    )
  } else return ''
}
