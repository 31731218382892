import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import * as React from 'react'
import { InventoryItemTopBar } from './InventoryItemTopBar'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { InventoryItemForm } from './InventoryItemForm'

interface Props {
  folder: IInventoryFolder
  navigate: (url: string) => void
}

@observer
export class InventoryItemNew extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private item: IInventoryItem

  constructor(props: Props) {
    super(props)
    this.item = {
      id: '',
      label: '',
      bundleIds: [],
      folderId: props.folder.id,
      imageId: null,
      brutto: null,
      netto: null,
      count: null as any,
      durationOfUse: null,
      minStock: null,
      maxStock: null,
      issueCount: null,
      packageSize: null,
      isOrderable: true,
      isDispensable: true,
      deletedAt: null,
      unit: 'Stück',
      orderlink: '',
      barcode: '',
      scope: '',
    }
    makeObservable(this)
  }

  render() {
    return (
      <>
        <InventoryItemTopBar
          folder={this.props.folder}
          item={{ label: 'Neuer Gegenstand' } as any}
        />

        <InventoryItemForm
          item={this.item}
          setEdit={() =>
            this.props.navigate('/inventory/folders/' + this.props.folder.id)
          }
          navigate={this.props.navigate}
          compoundId={this.props.folder.compoundId}
        />
      </>
    )
  }
}
