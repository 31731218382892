import * as React from 'react'
import { observer } from 'mobx-react'
import { PreventRouteChange } from '../../../../../../components/PreventRouteChange'
import { makeObservable, observable, runInAction } from 'mobx'
import { ICompanyExportLohnart } from 'contracts/companies/interfaces/ICompanyExportLohnart'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { NotAllowed } from 'components/Callout/components/NotAllowed'
import { Spinner } from 'components/Spinner'
import styles from '../styles.module.scss'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { CompanyTitleBar } from '../CompanyTitleBar'
import { Message } from 'components/Message'
import { LohnartenList } from './components/LohnartenList'
import { Button } from 'components/Form/components/Button'
import { toast } from 'react-toastify'

interface Props {
  companyId: string
}

@observer
export class CompanyExportTab extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private hasUnsavedChanges = false
  @observable private lohnarten: ICompanyExportLohnart[] | null = null
  @observable private error: string | null = null
  @observable private loading = true

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.reset()
  }

  private reset = async () => {
    try {
      runInAction(() => {
        this.loading = true
        this.lohnarten = null
      })
      const response = await hermes.indexOnceNew<ICompanyExportLohnart>(
        `/api/${this.context.instance.id}/companyExportLohnarten?companyId=${this.props.companyId}`,
      )
      runInAction(() => {
        this.lohnarten = response
        this.loading = false
        this.error = null
      })
    } catch (_e) {
      runInAction(() => {
        this.lohnarten = null
        this.loading = false
        this.error = 'Lohnarten konnten nicht geladen werden'
      })
    }
  }

  private save = async () => {
    if (!this.lohnarten) {
      return
    }

    const data = {
      companyId: this.props.companyId,
      lohnarten: this.lohnarten.map((lohnart) => ({
        id: lohnart.id.startsWith('-') ? null : lohnart.id,
        companyId: this.props.companyId,
        type: lohnart.type,
        scope: lohnart.scope,
        lohnartId: lohnart.lohnartId,
        schichtzulageId: lohnart.schichtzulageId,
        konto: lohnart.konto,
      })),
    }

    for (const lohnart of data.lohnarten) {
      if (!lohnart.type) {
        alert('Bitte geben Sie für jede Lohnart einen Typ an.')
        return
      }
      if (!lohnart.lohnartId) {
        alert(
          'Bitte geben Sie den Kreis der zu berücksichtigenden Mitarbeiter für jede Lohnart an.',
        )
        return
      }
      if (!lohnart.konto) {
        alert('Bitte geben Sie für jede Lohnart eine Kontonummer an.')
        return
      }
      if (lohnart.type === 'schichtzulage' && !lohnart.schichtzulageId) {
        alert('Bitte geben Sie die zu berücksichtigenden Schichten für jede Lohnart an.')
        return
      }
    }

    try {
      runInAction(() => (this.loading = true))
      await hermes.create(`/api/${this.context.instance.id}/companyExportLohnarten`, data)
      await this.reset()
      runInAction(() => (this.loading = false))
      toast.success('Die Lohnarten wurden gespeichert.')
    } catch (e: any) {
      runInAction(() => {
        this.loading = false
        this.error = 'Beim Speichern ist ein Fehler aufgetreten. ' + e?.message
      })
    }
  }

  render() {
    if (!this.context.permissions.companies_editExportLohnartenConfig) {
      return (
        <div className={styles.tabs}>
          <CompanyTitleBar companyId={this.props.companyId} />
          <NotAllowed />
        </div>
      )
    }

    return (
      <div className={styles.tabs}>
        <CompanyTitleBar companyId={this.props.companyId} />
        <div className='mt-6'>
          <CardColumnCard
            title='Export'
            subtitle='Hier können Sie Lohnarten für den Kanzleiexport konfigurieren'
          >
            {this.loading && <Spinner />}
            {!this.loading && this.error && (
              <Message color='danger' className='mb-4'>
                {this.error}
              </Message>
            )}
            {!this.loading && this.lohnarten && (
              <div>
                <LohnartenList
                  lohnarten={this.lohnarten}
                  companyId={this.props.companyId}
                />
                <div className='flex gap-2 mt-4'>
                  <Button color='secondary' onClick={this.reset}>
                    Zurücksetzen
                  </Button>
                  <Button color='primary' onClick={this.save}>
                    Speichern
                  </Button>
                </div>
              </div>
            )}
          </CardColumnCard>
        </div>
        {this.hasUnsavedChanges && <PreventRouteChange />}
      </div>
    )
  }
}
