import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import * as React from 'react'

interface Props {
  address: IBooking['extra']['address']
  disabled?: boolean
}

export class ResponsibilityAddressForm extends React.Component<Props, {}> {
  private readonly model: Model<IBooking['extra']['address']>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.address)
  }

  render() {
    return (
      <>
        <InputText
          className='col-span-4'
          disabled={this.props.disabled}
          model={this.model}
          name='name'
          label='Überstellung an'
        />
        <InputText
          className='col-span-4'
          disabled={this.props.disabled}
          model={this.model}
          name='street'
          label='Straße'
        />
        <InputText
          className='col-span-2'
          disabled={this.props.disabled}
          model={this.model}
          name='zip'
          label='PLZ'
        />
        <InputText
          className='col-span-2'
          disabled={this.props.disabled}
          model={this.model}
          name='city'
          label='Ort'
        />
        <InputText
          className='col-span-4'
          disabled={this.props.disabled}
          model={this.model}
          name='contact'
          label='Kontakt'
          placeholder='Ansprechpartner inkl. Telefon'
        />
      </>
    )
  }
}
