import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { TransferPlanFilter } from '../types/TransferPlanFilter'
import { action, makeObservable } from 'mobx'

interface Props {
  model: Model<TransferPlanFilter>
}

@observer
export class TransferPlanPeriodFilter extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setPeriod = (period: 'Letzte 2 Wochen' | 'Nächste 7 Tage' | 'Alle') =>
    (this.props.model.values.period = period)

  render() {
    return (
      <Menu
        as='div'
        className='relative inline-block text-left'
        onClick={(e) => e.stopPropagation()}
      >
        <Menu.Button className='text-gray-500 text-sm'>
          {this.props.model.values.period} <i className='fas fa-caret-down' />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='z-10 origin-top-right absolute -left-1 mt-2 w-[150px] rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <Menu.Item>
              <span
                onClick={() => this.setPeriod('Letzte 2 Wochen')}
                className='cursor-pointer block px-4 py-2 text-sm hover:bg-gray-100'
              >
                Letzte 2 Wochen
              </span>
            </Menu.Item>
            <Menu.Item>
              <span
                onClick={() => this.setPeriod('Nächste 7 Tage')}
                className='cursor-pointer block px-4 py-2 text-sm hover:bg-gray-100'
              >
                Nächste 7 Tage
              </span>
            </Menu.Item>
            <Menu.Item>
              <span
                onClick={() => this.setPeriod('Alle')}
                className='cursor-pointer block px-4 py-2 text-sm hover:bg-gray-100'
              >
                Alle
              </span>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
