import * as React from 'react'

interface Props {}

export const MigrationListHeader: React.FC<Props> = () => (
  <>
    <div className='uppercase text-xs font-bold text-gray-400 px-3'>Status</div>
    <div className='uppercase text-xs font-bold text-gray-400 px-3'>
      Uploaddatum <i className='fas fa-caret-down' />
    </div>
    <div className='uppercase text-xs font-bold text-gray-400 px-3 truncate'>
      <span className='hidden sm:inline-block'>Bezeichnung</span>
    </div>
    <div className='uppercase text-xs font-bold text-gray-400 px-3 truncate text-right'>
      Datensätze
    </div>
    <div />
  </>
)
