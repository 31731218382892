import * as React from 'react'
import { IBlogpost } from 'contracts/dashboard/interfaces/IBlogpost'
import { AppContext } from 'services/connection/models/AppContext'
import { XIcon } from '@heroicons/react/outline'
import styles from '../styles.module.scss'

interface Props {
  blogpost: Partial<IBlogpost>
  onClose: () => void
}

export class BlogpostDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  render() {
    return (
      <>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='py-6 px-20'>
          <h3 className='text-2xl'>
            <b>{this.props.blogpost.title}</b>
          </h3>
          <div className='text-lg mt-2 text-gray-700'>{this.props.blogpost.subtitle}</div>
        </div>
        <img
          src={`/api/${this.context.instance.id}/images/p${this.props.blogpost.imageId}`}
          alt=''
        />
        <div className='py-6 px-20'>
          {this.props.blogpost.markdown && (
            <div
              className={`text-lg my-2 text-gray-500 ${styles.markdown} ${styles.dialog}`}
              dangerouslySetInnerHTML={{ __html: this.props.blogpost.text! }}
            />
          )}
          {!this.props.blogpost.markdown && (
            <div className='text-lg my-2 text-gray-500 whitespace-pre-line'>
              {this.props.blogpost.text}
            </div>
          )}
        </div>
      </>
    )
  }
}
