import { IResident } from 'contracts/residents/interfaces/IResident'
import * as React from 'react'
import { OverviewCards } from './components/OverviewCards'
import { OverviewTodos } from './components/OverviewTodos'
import { OverviewDocuments } from './components/OverviewDocuments'
import { OverviewCostCoverages } from './components/OverviewCostCoverages'
import { OverviewCostCoverageSignatures } from './components/OverviewCostCoverageSignatures'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { OverviewSummary } from './components/OverviewSummary'
import { useNavigate } from 'react-router'
import { OverviewDelete } from './components/OverviewDelete'
import { OverviewQuarantine } from './components/OverviewQuarantine'
import { OverviewGroups } from './components/OverviewGroups'

interface Props {
  resident: IResident
}

export const OverviewTab: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  const navigate = useNavigate()
  React.useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])
  return (
    <div>
      <OverviewSummary resident={props.resident} />
      {context.permissions.resident_overview_quarantine > 0 && (
        <OverviewQuarantine resident={props.resident} />
      )}
      {context.permissions.resident_id_cards > 0 &&
        (context.permissions.resident_id_card_modules === 0 ||
          context.permissions.resident_id_card_modules === 2) && (
          <OverviewCards resident={props.resident} />
        )}
      {context.permissions.resident_todos > 0 && (
        <OverviewTodos resident={props.resident} />
      )}
      <OverviewDocuments resident={props.resident} navigate={navigate} />
      {context.permissions.resident_ukns > 0 && (
        <OverviewCostCoverageSignatures resident={props.resident} />
      )}
      {context.permissions.resident_ku_list && (
        <OverviewCostCoverages resident={props.resident} key={props.resident.familyId} />
      )}
      {context.permissions.menu_groups > 0 && (
        <OverviewGroups resident={props.resident} />
      )}
      <OverviewDelete resident={props.resident} />
    </div>
  )
})
