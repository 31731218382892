import HomeIcon from '@heroicons/react/outline/HomeIcon'
import { Callout } from 'components/Callout'
import { TopBar } from 'components/SideBarLayout/components/TopBar'
import * as React from 'react'
import { Link } from 'react-router-dom'

interface Props {}

export const InventoryOrderPlaceholder: React.FC<Props> = () => (
  <>
    <TopBar>
      <ol className='bg-white px-2 flex'>
        <li className='flex'>
          <div className='flex items-center'>
            <Link to={`/inventory/items`} className={`text-gray-400 hover:text-gray-500`}>
              <HomeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
              <span className='sr-only'>Home</span>
            </Link>
            <svg
              className='flex-shrink-0 h-5 w-5 text-gray-300'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 20 20'
              aria-hidden='true'
            >
              <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
            </svg>
            <span className='text-gray-600'>Bestellungen</span>
          </div>
        </li>
      </ol>
    </TopBar>

    <div className='rounded-md shadow-md mx-4 bg-white mt-[72px] flex-auto mb-4 flex flex-col'>
      <div className='my-auto flex-content'>
        <Callout
          icon='fas fa-shopping-cart'
          iconColor='#6366f1'
          title='Bitte wählen Sie eine Bestellung aus'
        />
      </div>
    </div>
  </>
)
