import { hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { InputJugendamt } from 'components/Form/components/InputJugendamt'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { Spinner } from 'components/Spinner'
import { IJugendamt } from 'contracts/general/interfaces/IJugendamt'
import { JugendamtValidator } from 'contracts/general/validators/JugendamtValidator'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  onClose: () => void
  selectedTransfers: Set<string>
}

@observer
export class JugendamtDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable loading = false
  @observable jugendamt: Partial<IJugendamt> = {
    id: '',
    label: '',
    federalState: '',
    street: '',
    zip: '',
    city: '',
    contactPerson: '',
    comment: '',
  }
  private readonly model: Model<IJugendamt>
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(this.jugendamt, JugendamtValidator.omit({ id: true }))
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => this.model.values.id,
        () => this.loadJugendamt(),
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadJugendamt = async () => {
    if (this.isExistingJugendamt()) {
      try {
        const jugendamt: IJugendamt = await hermes.getOnceNew<IJugendamt>(
          `/api/${this.context.instance.id}/jugendaemter/${this.model.values.id}`,
        )
        runInAction(() => {
          this.jugendamt = jugendamt
          this.model.values.federalState = this.jugendamt.federalState ?? ''
          this.model.values.street = this.jugendamt.street ?? ''
          this.model.values.zip = this.jugendamt.zip ?? ''
          this.model.values.city = this.jugendamt.city ?? ''
          this.model.values.contactPerson = this.jugendamt.contactPerson ?? ''
          this.model.values.comment = this.jugendamt.comment ?? ''
        })
      } catch (_e) {
        box.alert(
          'Fehler',
          'Beim Laden des Jugendamts ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
        )
      }
    } else {
      runInAction(() => {
        this.model.values.federalState = ''
        this.model.values.street = ''
        this.model.values.zip = ''
        this.model.values.city = ''
        this.model.values.contactPerson = ''
        this.model.values.comment = ''
      })
    }
  }

  private save = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    try {
      const promises: any[] = []
      if (this.jugendamt.id && this.model.values.id !== 'new') {
        for (const residentId of this.props.selectedTransfers) {
          promises.push(
            hermes.patch(`/api/${this.context.instance.id}/residents/${residentId}`, {
              jugendamtId: this.jugendamt.id,
            }),
          )
        }
      } else {
        const data = {
          label: this.model.values.label,
          street: this.model.values.street,
          zip: this.model.values.zip,
          city: this.model.values.city,
          federalState: this.model.values.federalState,
          contactPerson: this.model.values.contactPerson,
          comment: this.model.values.comment,
        }
        const res = await hermes.create(
          `/api/${this.context.instance.id}/jugendaemter`,
          data,
        )
        for (const residentId of this.props.selectedTransfers) {
          promises.push(
            hermes.patch(`/api/${this.context.instance.id}/residents/${residentId}`, {
              jugendamtId: res.id,
            }),
          )
        }
      }
      await Promise.all(promises)
      runInAction(() => (this.loading = false))
    } catch (_e) {
      runInAction(() => (this.loading = false))
      await box.alert(
        'Fehler',
        'Beim Speichern ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
      )
    }
    this.props.onClose()
  }

  @action
  private onChoose = (label: string | null) => {
    if (!label) {
      return
    }
    this.model.values.label = label
  }

  private isExistingJugendamt = () => {
    return this.model.values.id && this.model.values.id !== 'new' ? true : false
  }

  render() {
    return (
      <div className='min-h-[240px]'>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        {!this.loading && (
          <div>
            <h3 className='text-gray-500 text-xl'>Aufnehmendes Jugendamt eintragen</h3>
            <div id={this.model.id} className='flex gap-4 mt-4'>
              <div
                id={this.model.id}
                className='grid grid-cols-2 xl:grid-cols-4 gap-5 mt-4'
              >
                <InputJugendamt
                  className='col-span-2'
                  model={this.model}
                  name='id'
                  label='Aufnehmendes Jugendamt'
                  onChoose={this.onChoose}
                />
                <InputText
                  className='xl:col-span-2'
                  model={this.model}
                  name='federalState'
                  label='Bundesland'
                  disabled={this.isExistingJugendamt()}
                />
                <InputText
                  className='xl:col-span-2'
                  model={this.model}
                  name='street'
                  label='Straße'
                  disabled={this.isExistingJugendamt()}
                />
                <InputText
                  model={this.model}
                  name='zip'
                  label='PLZ'
                  disabled={this.isExistingJugendamt()}
                />
                <InputText
                  model={this.model}
                  name='city'
                  label='Ort'
                  disabled={this.isExistingJugendamt()}
                />
                <InputText
                  className='xl:col-span-4 col-span-2'
                  model={this.model}
                  name='contactPerson'
                  label='Kontaktperson'
                  disabled={this.isExistingJugendamt()}
                />
                <InputTextarea
                  className='xl:col-span-4 col-span-2'
                  model={this.model}
                  name='comment'
                  label='Notiz'
                  rows={3}
                  disabled={this.isExistingJugendamt()}
                />
              </div>
            </div>
            <div className='mt-4 mb-6 float-right'>
              <Button
                className='mr-2'
                color='secondary'
                outline
                onClick={this.props.onClose}
              >
                Abbrechen
              </Button>
              <Button onClick={this.save}>Speichern</Button>
            </div>
          </div>
        )}
        {this.loading && <Spinner />}
      </div>
    )
  }
}
