import { Model } from 'components/Form/Model'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import * as React from 'react'
import { UnsyncedCollection } from '../../../models/UnsyncedCollection'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { AppContext } from 'services/connection/models/AppContext'
import { box } from 'services/box'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { Button } from 'components/Form/components/Button'
import { Tooltip } from 'components/Tooltip'

interface Props {
  model: Model<IResidentSearchResultsFilter>
  residents: UnsyncedCollection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
}

export const SearchResultDownloadButton: React.FC<Props> = ({ model, residents }) => {
  const context = React.useContext(AppContext)
  const [loading, setLoading] = React.useState(false)

  const excelDownload = async () => {
    if (!residents.resources || residents.resources.length === 0) {
      void box.alert(
        'Download',
        'Bitte stellen Sie den Suchfilter so ein, dass es mindestens einen Treffer gibt, um eine Excel-Datei mit den Sucherergebnissen zu erstellen.',
      )
      return
    }
    setLoading(true)
    await createAndDownloadReport(
      'resident-search-results',
      context.instance.id,
      model.values,
      'Suchergebnisse.xlsx',
    )
    setLoading(false)
  }

  return (
    <Button
      onClick={excelDownload}
      type='button'
      className='has-tooltip border-r border-transparent hover:border-indigo-700'
      style={{
        width: 39,
        borderRadius:
          context.permissions.menu_resident_search !== 1 ? '0' : '0 6px 6px 0',
        padding: '9px 0',
      }}
      disabled={loading}
    >
      <Tooltip position='bottom'>Download</Tooltip>
      {!loading && (
        <span>
          <i className='fas fa-download' />
        </span>
      )}
      {loading && (
        <span>
          <i className='fas fa-spin fa-spinner' />
        </span>
      )}
    </Button>
  )
}
