import * as React from 'react'
import styles from '../styles.module.scss'

export const PdfPageError: React.FC<{}> = () => (
  <div className={`${styles.page} ${styles.empty}`} style={{ padding: 5 }}>
    <div className='mt-6 text-center'>
      <strong>Ohje, wir können diese Seite leider nicht darstellen.</strong>
      <br />
      <br />
      Wenden Sie sich an einen Administrator,
      <br />
      falls Sie Hilfe benötigen.
    </div>
  </div>
)
