import * as React from 'react'
import { InputText } from 'components/Form/components/InputText'
import { InputDate } from 'components/Form/components/InputDate'
import { InputNationality } from 'components/Form/components/InputNationality'
import { InputSelect } from 'components/Form/components/InputSelect'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { Model } from 'components/Form/Model'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { sexOptions } from '../constants/sexOptions'
import { familyRelationOptions } from '../constants/familyRelationOptions'
import { InputCountry } from 'components/Form/components/InputCountry'
import { maritalStatusOptions } from '../constants/maritalStatusOptions'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { InputCheckboxText } from 'components/Form/components/InputCheckboxText'
import { CaseRecordCaption } from './CaseRecordCaption'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { lunchPackageOptions } from '../constants/lunchPackageOptions'
import { action, makeObservable } from 'mobx'
import { legalStatusOptions } from '../constants/legalStatusOptions'
import { InputAutosuggest } from 'components/Form/components/InputAutosuggest'
import { roleInFamilyOptions } from '../constants/roleInFamilyOptions'
interface Props {
  model: Model<IResident>
  requiredFields?: any
  readOnly: boolean
}

export class CaseRecordFormJbp extends React.Component<Props, {}> {
  private jbpIdModel: Model<{ jbpId: string }>

  constructor(props: Props) {
    super(props)
    this.jbpIdModel = new Model({
      jbpId: props.model.values.id ? toJbpId(+props.model.values.id) : 'Automatisch',
    })
    makeObservable(this)
  }

  @action private setFalse = (name: string) => (this.props.model.values[name] = false)

  render() {
    return (
      <div className='grid grid-cols-3 gap-5 flex-initial relative'>
        {/* Row 1 */}
        <InputSelect
          model={this.props.model}
          name='sex'
          label={this.props.requiredFields?.sex ? 'Anrede *' : 'Anrede'}
          options={sexOptions}
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='title'
          label='Titel'
          disabled={this.props.readOnly}
        />
        <InputDate
          model={this.props.model}
          name='dateOfBirth'
          label={
            this.props.requiredFields?.dateOfBirth ? 'Geburtsdatum *' : 'Geburtsdatum'
          }
          disabled={this.props.readOnly}
        />

        {/* Row 2 */}
        <InputText
          model={this.props.model}
          name='firstName'
          label='Vorname *'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='lastName'
          label='Nachname *'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='aliasName'
          label='Alias'
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Identifikationsnummern</CaseRecordCaption>
        {/* Row 3 */}
        <InputText model={this.jbpIdModel} name='jbpId' label='JBP-ID' disabled />
        {/* Unique constraint on db */}
        {/*<InputDecimal
          model={this.props.model}
          name='migVisId'
          label='MigVIS ID'
          precision={10}
          scale={0}
          notNegative
          noThousandsSeparators
          disabled={this.props.readOnly}
        />*/}
        {/* Unique constraint on db */}
        <InputDecimal
          model={this.props.model}
          name='azrNr'
          label='AZR-Nummer'
          precision={12}
          scale={0}
          notNegative
          noThousandsSeparators
          disabled={this.props.readOnly}
        />

        {/* Unique constraint on db */}
        <InputDecimal
          model={this.props.model}
          name='wimNr'
          label='WIM-Nr.'
          precision={8}
          scale={0}
          notNegative
          noThousandsSeparators
          disabled={this.props.readOnly}
        />
        {/* Row 4 */}
        {/* Unique constraint on db */}
        <InputDecimal
          model={this.props.model}
          name='mId'
          label='MID (LABEA)'
          precision={10}
          scale={0}
          notNegative
          noThousandsSeparators
          disabled={this.props.readOnly}
        />
        {/* Not unique. Same value could be used by different residents */}
        {/*<InputText model={this.props.model} name='kaId' maxLength={10} label='KA-ID' disabled={this.props.readOnly} />*/}
        {/* Unique constraint on db */}
        <InputDecimal
          model={this.props.model}
          name='bvNr'
          label='BV Nr.'
          precision={8}
          scale={0}
          notNegative
          noThousandsSeparators
          disabled={this.props.readOnly}
        />
        <InputDecimal
          model={this.props.model}
          name='tId'
          label='T-ID'
          precision={8}
          scale={0}
          notNegative
          noThousandsSeparators
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Herkunft</CaseRecordCaption>
        {/* Row 5 */}
        <InputCountry
          model={this.props.model}
          name='countryOfBirthId'
          label='Herkunftsland'
          disabled={this.props.readOnly}
        />
        <InputNationality
          model={this.props.model}
          name='nationalityId'
          label='Staatsangehörigkeit'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='ethnicity'
          label='Volkszugehörigkeit'
          disabled={this.props.readOnly}
        />

        {/* Row 6 */}
        <InputText
          model={this.props.model}
          name='cityOfBirth'
          label='Geburtsort'
          disabled={this.props.readOnly}
        />
        <InputText
          className='col-span-2'
          model={this.props.model}
          name='nativeLanguage'
          label='Sprachen'
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Familie</CaseRecordCaption>
        <InputSelect
          model={this.props.model}
          name='familyRelation'
          label={
            this.props.requiredFields?.familyRelation
              ? 'Relation zum HV *'
              : 'Relation zum HV'
          }
          options={familyRelationOptions}
          disabled={this.props.readOnly}
        />
        <InputSelect
          model={this.props.model}
          name='maritalStatus'
          label='Familienstand'
          className='col-span-1'
          options={maritalStatusOptions}
          disabled={this.props.readOnly}
        />
        <InputAutosuggest
          model={this.props.model}
          name='roleInFamily'
          label='Rolle in der Familie'
          className='col-span-1'
          options={roleInFamilyOptions}
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Besonderheiten</CaseRecordCaption>
        <InputCheckboxText
          className='col-span-3'
          model={this.props.model}
          checkboxName='abschiebeschutzberechtigt'
          label='Abschiebeschutzberechtigt'
          disabled={this.props.readOnly}
        />
        <InputCheckboxText
          className='col-span-3'
          model={this.props.model}
          checkboxName='leaSchuleCheckbox'
          textName='leaSchuleText'
          label='Schule'
          placeholder='Notiz'
          disabled={this.props.readOnly}
        />
        <InputCheckboxText
          className='col-span-3'
          model={this.props.model}
          checkboxName='leaSpecialNeedsCheckbox'
          textName='leaSpecialNeedsText'
          label='Besondere Bedürfnisse'
          placeholder='Beschreibung'
          disabled={this.props.readOnly}
        />
        <InputCheckboxText
          className='col-span-3'
          model={this.props.model}
          checkboxName='leaInactive'
          textName='leaInactiveReason'
          label='Inaktiv'
          placeholder='Grund'
          disabled={this.props.readOnly}
        />
        <InputCheckboxText
          className='col-span-3'
          model={this.props.model}
          checkboxName='leaGnaCheckbox'
          textName='leaGnaText'
          label='GNA'
          placeholder='Notiz'
          disabled={this.props.readOnly}
        />
        <InputCheckboxText
          className='col-span-3'
          model={this.props.model}
          checkboxName='leaRamadan'
          label='Ramadan'
          disabled={this.props.readOnly}
        >
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <InputCheckbox
              className='flex-content'
              model={this.props.model}
              name='hasSchonkost'
              label='Schonkost'
              disabled={this.props.readOnly}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <InputCheckbox
              className='flex-content'
              model={this.props.model}
              name='foodMustBeBroughtToRoom'
              label='Berechtigt für Essen auf Zimmer'
              disabled={this.props.readOnly}
            />
          </>
        </InputCheckboxText>

        <InputSelect
          model={this.props.model}
          name='lunchPackage'
          label='Lunchpakete'
          className='col-span-3'
          options={lunchPackageOptions}
          disabled={this.props.readOnly}
        />

        <CaseRecordCaption>Sonstiges</CaseRecordCaption>
        {/* Row 7 */}
        <InputText
          model={this.props.model}
          name='phone'
          label='Telefonnummer'
          disabled={this.props.readOnly}
        />
        <InputDate
          model={this.props.model}
          name='legalAufenthaltsstatusSeit'
          label='IMVS-RG.'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='religion'
          label='Religion'
          disabled={this.props.readOnly}
          className='col-span-1'
        />
        <InputText
          model={this.props.model}
          name='residentStatus'
          label='Bewohnerstatus'
          disabled={this.props.readOnly}
        />
        <InputSelect
          model={this.props.model}
          name='legalAufenthaltsstatus'
          label='Aufenthaltsstatus'
          className='col-span-1'
          options={legalStatusOptions}
          disabled={this.props.readOnly}
        />
        <InputDate
          model={this.props.model}
          name='lfgAufenthaltsstatusValidTill'
          label='Gültig bis'
          disabled={this.props.readOnly}
        />

        {/* Row 8 */}
        <InputTextarea
          rows={4}
          className='col-span-3'
          model={this.props.model}
          name='comment'
          label='Notiz'
          disabled={this.props.readOnly}
        />

        <div
          className={`relative flex col-span-3 gap-x-5 gap-y-3 flex-wrap w-full shadow-sm text-sm border border-gray-300 rounded-md px-3 py-2 ${
            this.props.readOnly ? 'mb-6' : ''
          }`}
        >
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='isChild'
            label='Kind'
            onChange={() => this.setFalse('isAdult')}
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='isAdult'
            label='Erwachsener'
            onChange={() => this.setFalse('isChild')}
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='personalCharacteristicsIsSchwangerschaft'
            label='Schwangere Person'
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='personalCharacteristicsIsEinzelzimmer'
            label='Einzelzimmerberechtigung'
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='personalCharacteristicsIsRollstuhl'
            label='Rollstuhlfahrer'
            disabled={this.props.readOnly}
          />
          <InputCheckbox
            className='flex-content'
            model={this.props.model}
            name='personalCharacteristicsIsBarrierefreiheit'
            label='Eingeschränkte Barrierefreiheit benötigt'
            disabled={this.props.readOnly}
          />
        </div>

        {this.props.children}
      </div>
    )
  }
}
