import { SideBarLayout } from 'components/SideBarLayout'
import * as React from 'react'
import { Route, Routes, Navigate } from 'react-router'
import { AccountIntegrations } from './components/AccountIntegrations'
import { AccountNotifications } from './components/AccountNotifications'
import { AccountProfile } from './components/AccountProfile'
import { AccountSecurity } from './components/AccountSecurity'
import { AccountSideBar } from './components/AccountSideBar'
import { AccountStammBuildings } from './components/AccountStammBuildings'

interface Props {}

export class Account extends React.Component<Props, {}> {
  render() {
    return (
      <SideBarLayout>
        <AccountSideBar />
        <Routes>
          <Route path='profile' element={<AccountProfile />} />
          <Route path='security' element={<AccountSecurity />} />
          <Route path='buildings' element={<AccountStammBuildings />} />
          <Route path='notifications' element={<AccountNotifications />} />
          <Route path='integrations' element={<AccountIntegrations />} />
          <Route path='*' element={<RenderRedirect />} />
        </Routes>
      </SideBarLayout>
    )
  }
}

export const RenderRedirect: React.FC<{}> = () => {
  return (
    <>
      <Navigate to='/account/profile' />
    </>
  )
}
