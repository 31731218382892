import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { getRoomEquipmentOptions } from 'contracts/accommodations/helpers/getRoomEquipmentOptions'

interface Props {
  model: Model<{ equipment: string }>
  showLeft?: boolean
}

@observer
export class RoomEquipmentFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly equipmentOptions: InputMultiSelectOption[]

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.equipmentOptions = getRoomEquipmentOptions(context.instance.id).map((value) => ({
      id: value,
      label: value,
    }))
    makeObservable(this)
  }

  @action private reset = () => {
    this.props.model.values.equipment = ''
  }

  render() {
    let label = 'Merkmale'
    let color: string = 'text-gray-500'
    if (this.props.model.values.equipment) {
      const values = this.props.model.values.equipment.split(',')
      label = values[0] + (values.length > 1 ? ` +${values.length - 1}` : '')
      color = 'text-blue-500 max-w-[200px] truncate'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={`origin-top-left absolute ${
              this.props.showLeft ? 'right-0' : 'left-0'
            } mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60`}
          >
            <div className='flex flex-col gap-4'>
              <InputMultiSelect
                model={this.props.model}
                name='equipment'
                label='Merkmale'
                options={this.equipmentOptions}
              />
              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
