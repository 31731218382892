import * as React from 'react'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Dialog } from '@headlessui/react'
import { AnnotationIcon } from '@heroicons/react/outline'
import { AppContext } from 'services/connection/models/AppContext'
import { InputText } from '../../InputText'
import { Model } from 'components/Form/Model'
import { InputForm } from '../../InputForm'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Message } from 'components/Message'
import { hermes } from '@byll/hermes'
import { ConflictError } from 'contracts/errors/HermesErrors'

interface Props {
  model: Model<{ label: string }>
  onClose: (id?: string) => void
  type: string
}

@observer
export class NewDropdownEntryDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private onSubmit = async () => {
    runInAction(
      () => (this.props.model.values.label = this.props.model.values.label.trim()),
    )
    if (!this.props.model.isValid()) {
      this.props.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    try {
      const response = await hermes.create(
        `/api/${this.context.instance.id}/dropdownEntries`,
        {
          type: this.props.type,
          label: this.props.model.values.label,
          abbreviation: '',
        },
      )
      this.props.onClose(response.id)
    } catch (e: any) {
      runInAction(() => {
        this.loading = false
        this.error =
          e?.id === ConflictError.id
            ? e.message
            : 'Beim Erstellen ist ein Fehler aufgetreten.'
      })
    }
  }

  render() {
    return (
      <>
        <div className='sm:flex sm:items-start'>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-indigo-100`}
          >
            <AnnotationIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />
          </div>
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-auto'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Neu erstellen
            </Dialog.Title>

            {this.error && (
              <Message color='danger' className='mt-4'>
                {this.error}
              </Message>
            )}

            <InputForm onSubmit={this.onSubmit} id={this.props.model.id}>
              <div className='mt-2 text-sm text-gray-500'>
                <InputText
                  className='mt-6'
                  model={this.props.model}
                  name='label'
                  label='Bezeichnung'
                  disabled={this.loading}
                  placeholder='Name'
                />
              </div>
            </InputForm>
          </div>
        </div>
        <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
          <button
            type='button'
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500`}
            onClick={this.onSubmit}
          >
            Erstellen
          </button>
          <button
            type='button'
            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
            onClick={() => this.props.onClose()}
          >
            Abbrechen
          </button>
        </div>
        {this.loading && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
