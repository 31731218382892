import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { Spinner } from 'components/Spinner'
import { ICompanyIdCardTemplate } from 'contracts/userCard/interfaces/ICompanyIdCardTemplate'
import { IUserCard } from 'contracts/userCard/interfaces/IUserCard'
import dayjs from 'dayjs'
import { getImageDimensions } from 'helpers/getImageDimensions'
import { toDataUrl } from 'helpers/toDataUrl'
import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { EmployeeIdCardFront, IdCardLogoModel } from './components/EmployeeIdCardFront'
import { EmployeeIdCardBack } from './components/EmployeeIdCardBack'
import * as uuid from 'uuid'
import axios, { AxiosResponse } from 'axios'
import styles from '../styles.module.scss'
import { IImage } from 'contracts/general/interfaces/IImage'
import { Callout } from 'components/Callout'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { Card } from 'components/Card'
import { CompanyTitleBar } from '../CompanyTitleBar'

const idCarsignatureLabels: InputSelectOption[] = [
  { value: 'companyName', label: 'Aussteller' },
  { value: 'contactPersonName', label: 'Bevollmächtigter' },
]

interface Props {
  companyId: string
  companyIdCardTemplate: ICompanyIdCardTemplate
}

@observer
export class CompanyCardTab extends React.Component<Props, {}> {
  static contextType = AppContext
  fileInputId = uniqueId('file-')
  private userCard: IUserCard
  private readonly model: Model<ICompanyIdCardTemplate>
  @observable private logoModel: IdCardLogoModel | null = null
  @observable private uploading = false
  @observable private loading = false
  @observable private hasUnsavedChanges = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.userCard = {
      id: '1000',
      instanceId: context.instance.id,
      userId: '0',
      imageId: null,
      sex: 'male',
      firstName: 'Max',
      lastName: 'Mustermann',
      dateOfIssue: dayjs().format('YYYY-MM-DD'),
      color: '#2b5e93',
      bewacherId: '',
      token: 'ABC',
      companyId: this.props.companyId,
      createdAt: new Date().toISOString(),
      createdBy: context.user.id,
      legacyCardNumber: '',
    }
    this.model = new Model({ ...props.companyIdCardTemplate })
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.onReset()
    reaction(
      () => toJS(this.model.values),
      () => (this.hasUnsavedChanges = true),
    )
    reaction(
      () => toJS(this.logoModel),
      () => (this.hasUnsavedChanges = true),
    )
  }

  private onSave = async () => {
    if (!this.logoModel) {
      box.alert('Fehler', 'Beim Speichern ist ein Fehler aufgetreten.')
      return
    }
    runInAction(() => (this.loading = true))
    try {
      await hermes.patch(
        `/api/${this.context.instance.id}/companyIdCardTemplates/${this.model.values.id}`,
        {
          title: this.model.values.title,
          text: this.model.values.text,
          signatureLabel: this.model.values.signatureLabel,
          signatureName: this.model.values.signatureName,
          companyNumber: this.model.values.companyNumber,
          logoXCm: this.logoModel.logoX / 40,
          logoYCm: this.logoModel.logoY / 40,
          logoWidthCm: this.logoModel.logoWidth / 40,
          logoHeightCm: this.logoModel.logoHeight / 40,
          logoImageId: this.model.values.logoImageId,
        },
      )
    } catch (_e) {
      box.alert('Fehler', 'Die Ausweisvorlage konnte nicht gespeichert werden.')
    }
    runInAction(() => {
      this.loading = false
      this.hasUnsavedChanges = false
    })
  }

  @action
  private onReset = () => {
    try {
      this.model.values.companyNumber = this.props.companyIdCardTemplate.companyNumber
      this.model.values.signatureLabel = this.props.companyIdCardTemplate.signatureLabel
      this.model.values.signatureName = this.props.companyIdCardTemplate.signatureName
      this.model.values.text = this.props.companyIdCardTemplate.text
      this.model.values.title = this.props.companyIdCardTemplate.title
      this.logoModel = {
        logoX: this.props.companyIdCardTemplate?.logoXCm! * 40,
        logoY: this.props.companyIdCardTemplate?.logoYCm! * 40,
        logoWidth: this.props.companyIdCardTemplate?.logoWidthCm! * 40,
        logoHeight: this.props.companyIdCardTemplate?.logoHeightCm! * 40,
        logoOriginalWidth: this.props.companyIdCardTemplate?.logoWidthCm! * 40,
        logoOriginalHeight: this.props.companyIdCardTemplate?.logoHeightCm! * 40,
      }
      setTimeout(
        action(() => (this.hasUnsavedChanges = false)),
        100,
      )
    } catch (_e) {
      box.alert('Fehler', 'Die Ausweisvorlage konnte nicht geladen werden.')
    }
  }

  private triggerFileSelect = () => {
    const input = document.getElementById(this.fileInputId)
    input?.click()
  }

  private onSelectFile = async (e) => {
    if (!this.userCard.companyId) {
      box.alert('Fehler', 'Der Upload ist leider fehlgeschlagen')
      return
    }
    try {
      const file = e.target.files[0]
      const imageDataUrl = await toDataUrl(file)
      const { width, height } = await getImageDimensions(imageDataUrl)
      const scale = width / height
      const calculatedWidth = width / 40 > 8 ? 8 : width / 40
      const calculatedHeight = calculatedWidth / scale

      const form = new FormData()
      form.append('id', uuid.v4())
      form.append('file', file)
      form.append('scope', 'card company logo')
      form.append(
        'section',
        JSON.stringify({
          x: 0,
          y: 0,
          width: 10,
          height: 10,
        }),
      )
      runInAction(() => (this.uploading = true))
      const img: AxiosResponse<IImage> = await axios.post(
        `/api/${this.context.instance.id}/images`,
        form,
      )

      runInAction(() => {
        if (!this.props.companyIdCardTemplate) {
          return
        }
        this.model.values.logoImageId = img.data.id
        this.model.values.logoXCm = 1
        this.model.values.logoYCm = 1
        this.model.values.logoWidthCm = calculatedWidth
        this.model.values.logoHeightCm = calculatedHeight
        const template = this.model.values
        this.logoModel = {
          logoX: template.logoXCm! * 40,
          logoY: template.logoYCm! * 40,
          logoWidth: template.logoWidthCm! * 40,
          logoHeight: template.logoHeightCm! * 40,
          logoOriginalWidth: template.logoWidthCm! * 40,
          logoOriginalHeight: template.logoHeightCm! * 40,
        }
      })
    } catch (_e) {
      alert('Der Upload ist leider fehlgeschlagen')
    }

    runInAction(() => (this.uploading = false))
    const input: HTMLInputElement | null = document.getElementById(
      this.fileInputId,
    ) as any
    if (input) {
      input.value = ''
    }
  }

  private createCompanyIdCardTemplate = async () => {
    const data = {
      ...this.props.companyIdCardTemplate,
      id: this.props.companyId,
      logoImageId: undefined,
    }
    try {
      const res: ICompanyIdCardTemplate = await hermes.create(
        `/api/${this.context.instance.id}/companyIdCardTemplates`,
        data,
      )
      runInAction(() => {
        this.model.values.id = res.id
        this.model.values.title = res.title
      })
    } catch (_e) {
      box.alert('Fehler', 'Die Ausweisvorlage konnte nicht erstellt werden.')
    }
  }

  render() {
    const readOnly = !this.context.permissions.companies_editIdCardTemplates
    if (!this.model.values.id) {
      return (
        <div className={styles.tabs}>
          <CompanyTitleBar companyId={this.props.companyId} />
          <Card className='mt-6'>
            <Callout
              className='p-6 rounded shadow'
              title='Ausweisvorlage'
              icon='fas fa-id-card'
            >
              {readOnly ? (
                <span>Es wurde noch keine Ausweisvorlage erstellt.</span>
              ) : (
                <Button onClick={this.createCompanyIdCardTemplate}>
                  Ausweisvorlage erstellen
                </Button>
              )}
            </Callout>
          </Card>
        </div>
      )
    }
    return (
      <div className={styles.tabs}>
        <CompanyTitleBar companyId={this.props.companyId} />
        <div className='flex flex-col justify-center md:flex-row mt-[9px]'>
          {/* Card preview */}
          <div className='flex-content mr-[39px]'>
            <div className='mb-[20px]'>
              <EmployeeIdCardFront
                userCard={this.userCard}
                companyIdCardTemplate={this.model}
                logoModel={this.logoModel || undefined}
              />
            </div>
            <EmployeeIdCardBack
              userCard={this.userCard}
              companyIdCardTemplate={this.model}
            />
          </div>

          {/* Loader */}
          {!this.props.companyIdCardTemplate && (
            <Card>
              <Spinner />
            </Card>
          )}

          {/* Card settings */}
          {this.props.companyIdCardTemplate && (
            <div className='bg-white rounded-md shadow-md overflow-hidden flex-auto md:min-w-[222px] p-6 mt-[15px] mb-[20px]'>
              {!readOnly && (
                <Button
                  className='w-full'
                  disabled={this.uploading}
                  color='secondary'
                  style={{ marginBottom: '1.25rem' }}
                  onClick={this.triggerFileSelect}
                >
                  {this.uploading && (
                    <span>
                      <i className='fas fa-spin fa-spinner' />
                    </span>
                  )}{' '}
                  {`Firmenlogo ${
                    this.props.companyIdCardTemplate.logoImageId ? 'ändern' : 'hochladen'
                  }`}
                </Button>
              )}
              <InputText
                model={this.model}
                name='title'
                label='Titelzeile Rückseite'
                disabled={readOnly}
                style={{ marginBottom: '1.25rem' }}
              />
              <InputTextarea
                model={this.model}
                name='text'
                label='Text Rückseite'
                disabled={readOnly}
                rows={4}
                style={{ marginBottom: '1.25rem' }}
              />
              <InputText
                model={this.model}
                name='companyNumber'
                label='Unternehmens ID'
                disabled={readOnly}
                style={{ marginBottom: '1.25rem' }}
              />
              <InputSelect
                model={this.model}
                options={idCarsignatureLabels}
                name='signatureLabel'
                label='Firmenunterschrift Bezeichnung'
                disabled={readOnly}
                style={{ marginBottom: '1.25rem' }}
              />
              <InputText
                model={this.model}
                name='signatureName'
                label='Firmenunterschrift Name'
                disabled={readOnly}
                style={{ marginBottom: '1.25rem' }}
              />
              {!readOnly && (
                <div>
                  <Button
                    color='secondary'
                    onClick={this.onReset}
                    block
                    style={{ marginBottom: '1.25rem' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    loading={this.loading}
                    color='primary'
                    onClick={this.onSave}
                    block
                  >
                    Speichern
                  </Button>
                </div>
              )}
            </div>
          )}
          <input
            id={this.fileInputId}
            type='file'
            className='hidden'
            onChange={this.onSelectFile}
            multiple={false}
            accept='.jpg,.jpeg,.png,image/jpeg,image/png'
          />
        </div>
        {this.hasUnsavedChanges && <PreventRouteChange />}
      </div>
    )
  }
}
