import { Collection, hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Dialog } from 'components/Dialog'
import { TodoDialog } from './components/TodoDialog'
import { Button } from 'components/Form/components/Button'
import { RoundIcon } from 'components/RoundIcon'
import { box } from 'services/box'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { toast } from 'react-toastify'

interface Props {
  resident: IResident
}

@observer
export class OverviewTodos extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly todos: Collection<ITodoSearchResult>
  private readonly disposers: Disposer[] = []
  @observable private dialog: ITodoSearchResult | null = null
  @observable private changed = { hasUnsavedChanges: false }
  @observable private filter: 'all' | 'open' | 'done' = 'open'

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.todos = new Collection(
      `/api/${context.instance.id}/todoLists/${context.defaults.residentTodoListId}/todos`,
      { residentId: props.resident.id },
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.todos.init({ readOnly: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private todoMapper = (res: Resource<ITodoSearchResult>) => {
    const todo = res.data
    if (!todo) {
      return null
    }
    if (this.filter === 'done' && !todo.doneDate) {
      return null
    }
    if (this.filter === 'open' && todo.doneDate) {
      return null
    }
    return (
      <div
        key={todo.id}
        className='group hover:bg-indigo-100 bg-gray-100 mt-4 px-3 py-2 rounded-md overflow-hidden relative cursor-pointer truncate'
        onClick={() => this.openTodo(todo)}
      >
        <input
          type='checkbox'
          checked={!!todo.doneDate}
          className={`rounded-full ${
            todo.doneDate ? 'border-0' : 'border border-gray-300'
          } h-5 w-5 text-green-500 mr-2`}
          onChange={(event) => {
            event.stopPropagation()
            this.toggleTodo(todo)
          }}
          onClick={(event) => event.stopPropagation()}
        />
        {todo.label}
        {todo.doneDate && (
          <span className='ml-2 inline-flex items-center px-2 rounded-full text-sm font-medium bg-gray-100 text-gray-800 border border-gray-500'>
            {dayjs(todo.doneDate).format('DD.MM.YYYY')}
          </span>
        )}
        {!!todo.gateMessage && (
          <>
            <span
              className={`ml-2 inline-flex items-center px-2 rounded-full text-sm font-medium ${
                todo.deliveredAt
                  ? 'bg-green-100 text-green-800 border border-green-500'
                  : 'bg-red-100 text-red-800 border border-red-500'
              }`}
            >
              {todo.deliveredAt ? 'Zugestellt' : 'Noch nicht zugestellt'}
            </span>
          </>
        )}
        {!!todo.notes && (
          <span className='ml-2 text-blue-500'>
            <i className='fas fa-comment' />
          </span>
        )}
        <RoundIcon
          classNameContainer='hidden group-hover:block'
          tooltip={{ text: 'Todo löschen', position: 'left' }}
          style={{ position: 'absolute', top: 5, right: 5 }}
          icon='fas fa-trash'
          color='danger'
          onClick={(event) => {
            event.stopPropagation()
            this.deleteTodo(todo)
          }}
        />
      </div>
    )
  }

  @action
  private toggleTodo = (todo: ITodoSearchResult) => {
    if (this.context.permissions.resident_todos < 2) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um Aufgaben zu bearbeiten.',
        { color: 'danger' },
      )
      return
    }
    this.changed = { hasUnsavedChanges: false }
    const oldDoneDate = todo.doneDate
    todo.doneDate = todo.doneDate ? null : dayjs().format('YYYY-MM-DD')
    hermes
      .patch(
        `/api/${this.context.instance.id}/todoLists/${this.context.defaults.residentTodoListId}/todos/${todo.id}`,
        { doneDate: todo.doneDate },
      )
      .catch(
        action(() => {
          toast.error(
            `Todo konnte nicht ${todo.doneDate ? 'erledigt' : 'wieder geöffnet'} werden.`,
          )
          todo.doneDate = oldDoneDate
        }),
      )
  }

  @action
  private openTodo = (todo: ITodoSearchResult) => {
    this.dialog = todo
  }

  @action
  private addTodo = () => {
    if (this.context.permissions.resident_todos < 2) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um neue Aufgaben zu erstellen.',
        { color: 'danger' },
      )
      return
    }
    this.changed = { hasUnsavedChanges: false }
    this.dialog = {
      id: '',
      listId: '',
      residentId: this.props.resident.id,
      compoundId: null,
      buildingId: null,
      priority: 1,
      dueDate: null,
      doneDate: null,
      label: '',
      notes: '',
      managed: false,
      gateMessage: '',
      deliveredAt: null,
      gateMessageValidTill: null,
      url: '',
      createdAt: new Date().toISOString(),
      createdBy: null,
      doneAt: null,
      doneBy: null,
      rating: null,
      ratingBy: null,
      readConfirmation: false,

      assigneeIds: [],
      assignees: [],
      watcherIds: [],
      watchers: [],
      creator: null,
      assigner: null,
    }
  }

  private deleteTodo = async (todo: ITodoSearchResult) => {
    if (this.context.permissions.resident_todos < 2) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um Aufgaben zu löschen.',
        { color: 'danger' },
      )
      return
    }
    if (todo.managed) {
      void box.alert(
        'Schreibschutz',
        'Sie können nur eigene Todo-Einträge löschen, die mit "Todo hinzufügen" angelegt wurden. Dieser Todo Eintrag ist schreibgeschützt und kann nicht gelöscht werden.',
      )
      return
    }
    const confirmed = await box.alert(
      'Todo löschen',
      'Möchten Sie diesen Todo Eintrag wirklich unwiderruflich löschen?',
      { confirm: 'Ja, jetzt löschen', cancel: 'Abbrechen', color: 'danger' },
    )
    if (!confirmed) {
      return
    }
    hermes
      .delete(
        `/api/${this.context.instance.id}/todoLists/${this.context.defaults.residentTodoListId}/todos/${todo.id}`,
        { immediately: true },
      )
      .catch(() => alert('Der Todo Eintrag konnte nicht gelöscht werden.'))
  }

  @action
  private closeTodo = () => {
    if (this.changed.hasUnsavedChanges) {
      const result = window.confirm(
        'Sie haben ungespeicherte Änderungen. Wollen Sie den Dialog wirklich schließen und die Änderungen verwerfen?',
      )
      if (!result) {
        return
      }
    }
    this.changed.hasUnsavedChanges = false
    this.dialog = null
  }

  @action setFilterAll = () => (this.filter = 'all')
  @action setFilterOpen = () => (this.filter = 'open')
  @action setFilterDone = () => (this.filter = 'done')

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Aufgaben</span>
          <br />
          <span className='text-sm text-gray-400'>Für den Bewohner zu erledigen</span>
        </div>

        {!this.todos.resources && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            <Spinner delay />
          </div>
        )}

        {this.todos.resources && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            <div className='pl-2'>
              <Button
                color='secondary'
                onClick={this.setFilterAll}
                outline={this.filter !== 'all'}
                style={{ borderRadius: '6px 0 0 6px' }}
              >
                Alle
              </Button>
              <Button
                color='secondary'
                onClick={this.setFilterOpen}
                outline={this.filter !== 'open'}
                style={{ borderRadius: '0' }}
              >
                Offen
              </Button>
              <Button
                color='secondary'
                onClick={this.setFilterDone}
                outline={this.filter !== 'done'}
                style={{ borderRadius: '0 6px 6px 0' }}
              >
                Erledigt
              </Button>
            </div>
            {this.todos.resources.map(this.todoMapper)}
            <div
              className='hover:bg-indigo-100 bg-gray-100 mt-4 px-4 py-2 rounded-md overflow-hidden relative cursor-pointer'
              onClick={this.addTodo}
            >
              <span className='text-lg'>
                <i className='fas fa-plus text-gray-500 mr-2' />
              </span>
              Todo hinzufügen
            </div>

            {/* Dialog */}
            <Dialog size='md' open={!!this.dialog} setOpen={this.closeTodo}>
              {this.dialog && (
                <TodoDialog
                  onClose={this.closeTodo}
                  todo={this.dialog}
                  changed={this.changed}
                />
              )}
            </Dialog>
          </div>
        )}
      </div>
    )
  }
}
