import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import { AttachmentUpload } from 'components/Attachment/AttachmentUpload'
import { AttachmentTile } from 'components/Attachment/AttachmentTile'
import { ICostCoverageSignature } from 'contracts/costCoverages/interfaces/ICostCoverageSignature'
import { IAttachment } from 'contracts/general/interfaces/IAttachment'
import { download } from 'helpers/download'
import { hermes } from '@byll/hermes'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { months } from 'components/Form/components/InputMonth'
import { box } from 'services/box'

interface Props {
  resident: IResident
  signature: ICostCoverageSignature
}

@observer
export class CostCoverageSignatureDocuments extends React.Component<Props, {}> {
  static contextType = AppContext

  private onUploaded = async (attachment: IAttachment) => {
    // Create doc
    runInAction(() => this.props.signature.attachments.push(attachment))
    try {
      await hermes.create(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/costCoverageSignatureAttachments`,
        {
          month: this.props.signature.month,
          costCoverageId: this.props.signature.costCoverage.id,
          documentId: attachment.id,
        },
      )
    } catch (_e) {
      alert('Der Upload ist fehlgeschlagen.')
      runInAction(() => {
        // Remove
        for (let i = this.props.signature.attachments.length - 1; i >= 0; i--) {
          if (this.props.signature.attachments[i].id === attachment.id) {
            this.props.signature.attachments.splice(i, 1)
          }
        }
      })
    }
  }

  private onDelete = async (attachment: IAttachment) => {
    if (
      !window.confirm('Möchten Sie diese Unterschrift wirklich unwiderruflich löschen?')
    ) {
      return
    }
    let deleted
    runInAction(() => {
      for (let i = this.props.signature.attachments.length - 1; i >= 0; i--) {
        if (this.props.signature.attachments[i].id === attachment.id) {
          deleted = this.props.signature.attachments.splice(i, 1)
        }
      }
    })
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/costCoverageSignatureAttachments/${attachment.id}`,
      )
    } catch (_e) {
      alert('Das Löschen ist fehlgeschlagen.')
      if (deleted[0]) {
        // Re-add deleted
        runInAction(() => this.props.signature.attachments.push(deleted[0]))
      }
    }
  }

  private onDownload = (attachment: IAttachment) => {
    download(`/api/${this.context.instance.id}/documents/files/${attachment.id}`)
  }

  private onPreview = (attachment: IAttachment) => {
    window.open(`/documents/${attachment.id}`, '_blank')
  }

  private onSelect = (): boolean => {
    if (this.props.signature.attachments.length > 0) {
      void box.alert(
        'Unterschrift liegt bereits vor',
        'Es kann nur ein Unterschriftsnachweis pro KÜ und Monat hochgeladen werden. Falls der vorliegende Unterschriftsnachweis ungültig ist, können Sie diesen löschen und danach einen neuen Unterschriftsnachweis hochladen.',
      )
      return true
    }
    return false
  }

  render() {
    const month = dayjs(this.props.signature.month, 'YYYY-MM')
    return (
      <div>
        {/* Title */}
        <div className='text-gray-400 uppercase text-xs mt-6 -mb-3'>
          Unterschriften
          <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-500 text-white'>
            {`${months[month.month()]} ${month.year()}`}
          </span>
        </div>

        {/* File list */}
        {this.props.signature.attachments.map((a) => (
          <AttachmentTile
            key={a.id}
            attachment={a}
            onDelete={this.onDelete}
            onDownload={this.onDownload}
            onPreview={this.onPreview}
          />
        ))}

        {/* Select file */}
        <AttachmentUpload
          onSelect={this.onSelect}
          onUploaded={this.onUploaded}
          scope='cost coverage signature'
          accept='application/pdf,.pdf'
        >
          Klicken Sie <span className='text-blue-500 hover:underline'>hier</span>, um den
          unterschriebenen Unterkunftsnachweis anzuhängen.
        </AttachmentUpload>
      </div>
    )
  }
}
