import { Age } from 'components/Age'
import { getRoomLockAt } from 'contracts/accommodations/helpers/getRoomLockAt'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IBed, IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { Dayjs } from 'dayjs'
import { flags } from 'helpers/countries/flags'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { getOccupancyColor } from '../../../helpers/getOccupancyColor'
import { ResidentRoomActionDialog } from './ResidentRoomActionDialog'
import { useDrop } from 'react-dnd'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { hermes } from '@byll/hermes'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { accommodationForms } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/AccommodationTab/helpers/accommodationForms'
import { DraggableName, ItemTypes } from './DraggableName'
import { isMucInstance } from 'contracts/general/helpers/instanceIds'
import { EquipmentIcon } from '../../EquipmentIcon'
import { RoomTileLockDialogToggle } from './RoomTileLockDialogToggle'

export const ROOM_TILE_HEIGHT = 250

interface Props {
  room: IRoom
  occupancy: IOccupancy
  compoundId: string
  at: string | 'now'
  refetchRoomOccupancy: (roomId: string, time: Dayjs) => Promise<void>
}

function getTimes(beginAt: string, endAt: string | null): string {
  const begin = dayjs(beginAt)
  if (!endAt) {
    return `ab ${begin.format('DD.MM.YYYY HH:mm')}`
  }
  return `${begin.format('DD.MM.YYYY HH:mm')} - ${dayjs(endAt).format(
    'DD.MM.YYYY HH:mm',
  )}`
}

export const ResidentOccupancyRoomTile: React.FC<Props> = observer(
  ({ at, room, occupancy, compoundId, refetchRoomOccupancy }) => {
    const t = at === 'now' ? dayjs() : dayjs(at)
    const context = React.useContext(AppContext)
    const lock = getRoomLockAt(room.locks, t)
    const [{ isOver, isDragging }, drop] = useDrop(() => ({
      accept: ItemTypes.NAME,
      drop: (monitor: { residentId: string; roomId: string }) => {
        onCreateBooking(monitor.residentId, monitor.roomId)
      },
      collect: (monitor) => ({
        isOver: monitor.getItem<any>()?.roomId !== room.id && !!monitor.isOver(),
        isDragging: !!monitor.canDrop(),
      }),
      canDrop: (item) => {
        return item?.roomId !== room.id
      },
    }))

    async function toggleBed(room: IRoom, bed: IBed) {
      if (at !== 'now') {
        alert(
          'Diese Ansicht zeigt einen Zeitpunkt in der Zukunft oder Vergangenheit. Bettsperren oder Bettreservierungen können nur in einer Ansicht geändert werden, die den gegenwärtigen Zustand des Zimmers widerspiegelt.',
        )
        return
      }
      const promise = box.custom(
        <ResidentRoomActionDialog
          room={room}
          bed={bed}
          onClose={() => promise.close()}
        />,
        { context },
      )
      await promise
      void refetchRoomOccupancy(room.id, dayjs().add(2, 'minute'))
    }

    async function onCreateBooking(residentId: string, oldRoomId?: string) {
      if (getRoomLockAt(room.locks, t)) {
        box.alert(
          'Gesperrt',
          'Dieser Raum ist derzeit gesperrt. Sie können diese Person erst in diesen Raum verschieben, nachdem Sie ihn entsperrt haben.',
          { color: 'danger' },
        )
        return
      }

      const now = dayjs()
      const members: any = []
      const booking: IBooking & { buildingId: string } = {
        id: '',
        instanceId: context.instance.id,
        type: 'internal-residence',
        compoundId: compoundId,
        residentId,
        roomId: room.id,
        bed: null,
        reason: '',
        comment: '',
        beginAt: now.add(-1, 'minute').toISOString(),
        endAt: null,
        label: '',
        extra: {},
        buildingId: room.buildingId,
      }

      const resident = await hermes.getOnceNew<IResident>(
        `/api/${context.instance.id}/residents/${residentId}`,
      )
      if (!resident) {
        return
      }

      // Load current occupancy for this room
      const data = await hermes.indexOnceNew<IOccupancy>(
        `/api/${context.instance.id}/accommodations/occupancies?roomId=${
          room.id
        }&queryAt=${encodeURIComponent(now.toISOString())}&queryTill=${encodeURIComponent(
          '2100-12-31T00:00:00.000Z',
        )}`,
      )
      if (!data[0]) {
        box.alert('Fehler', 'Belegung konnte nicht geladen werden.', { color: 'danger' })
        return
      }

      const Form = accommodationForms['internal-residence']
      const promise = box.custom(
        <Form
          compoundId={compoundId}
          booking={booking}
          members={members}
          occupancy={data[0]}
          resident={resident}
          onClose={(val?: any) => promise.close(val)}
          autoResponsibility
          checkInPermission={
            context.permissions.resident_accommodation_checkin_transfers ||
            context.permissions.resident_accommodationBookings_dragAndDrop
              ? 2
              : 0
          }
        />,
        { context },
      )
      await promise
      void refetchRoomOccupancy(room.id, dayjs().add(2, 'minute'))
      if (oldRoomId) {
        void refetchRoomOccupancy(oldRoomId, dayjs().add(2, 'minute'))
      }
    }

    return (
      <div
        ref={drop}
        style={{ height: ROOM_TILE_HEIGHT }}
        className={`shadow rounded-md px-3 pb-3 min-w-0 overflow-x-hidden overflow-y-auto ${
          isOver ? 'border-2 rounded-lg border-blue-500 bg-indigo-100' : 'bg-white'
        } ${
          !isOver && isDragging
            ? 'border-2 rounded-lg border-dashed border-blue-500'
            : 'bg-white'
        }
        `}
      >
        <div
          className='text-base -mx-3 -mt-3 mb-2 bg-gray-800 p-3 text-white flex min-w-0 sticky top-0 z-10'
          style={{ borderRadius: '6px 6px 0 0' }}
        >
          <div className='flex-[0_1_auto] mr-2 truncate min-w-[20px]'>
            {room.label}
            {isMucInstance(context.instance.id) && room.typeOfUse && (
              <span className='ml-1 px-2.5 py-[3px] rounded-full text-xs font-medium text-white bg-gray-500'>
                {room.typeOfUse.replace(/,/, ', ')}
              </span>
            )}
          </div>
          <div className={lock ? 'flex-content' : 'flex-auto'}>
            <RoomTileLockDialogToggle
              room={room}
              askNotes
              at={at === 'now' ? undefined : t}
              lock={lock}
            />
          </div>
          {lock && (
            <div className='text-red-500 truncate flex-[1_10_auto] ml-2 min-w-0'>
              {lock.reason
                .split(' ')
                .map((w) => w.substring(0, 1))
                .join('')
                .toUpperCase()}
            </div>
          )}
          <div className='flex-content'>
            {room.equipment.split(',').map((e) =>
              EquipmentIcon[e] ? (
                <span key={e} className='ml-1'>
                  {EquipmentIcon[e]}
                </span>
              ) : null,
            )}
            <span
              className={`${getOccupancyColor(
                +occupancy.occupied,
                +occupancy.capacity,
                lock !== null,
              )} ml-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white`}
            >{`${occupancy.occupied} / ${room.capacity}`}</span>
          </div>
        </div>
        {/* Use room.createdAt because this prop is used to trigger a rerender of this tile in refetchRoomOccupancy */}
        <div key={room.createdAt} className='h-3' />
        {lock && (
          <div className='truncate'>{`Sperre ab ${dayjs(lock.beginAt).format(
            'DD.MM.',
          )} von ${lock.beginBy
            .split(' ')
            .map((n) => n.substring(0, 1))
            .join('')
            .toUpperCase()}${lock.notes ? `: ${lock.notes}` : ''}`}</div>
        )}
        {occupancy.beds.map((b) => (
          <div key={b.id} className='has-tooltip group'>
            <div className='truncate'>
              {b.bed && (
                <span
                  className={`mr-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium cursor-pointer ${
                    b.bed > +room.capacity || b.lock !== null
                      ? 'bg-red-500'
                      : 'bg-gray-500'
                  } text-white`}
                  onClick={() => toggleBed(room, b)}
                >
                  {b.lock !== null && (
                    <span className='mr-1'>
                      <i className='fas fa-lock' />
                    </span>
                  )}
                  {b.bed}
                </span>
              )}
              {flags[b.resident?.flag || ''] && (
                <img
                  alt={b.resident!.nationality}
                  src={flags[b.resident?.flag || ''] || ''}
                  className='w-5 h-5 mr-1 inline-flex rounded-full'
                />
              )}
              {b.resident?.isBaby && (
                <span className='mr-1 text-gray-500'>
                  <i className='fas fa-baby-carriage' />
                </span>
              )}
              {b.type === 'internal-reservation' && (
                <span className='mr-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border border-indigo-500 text-indigo-500'>
                  Reserviert
                </span>
              )}
              {b.type === 'internal-reservation' && !b.resident && (
                <span className='text-gray-400'>·&nbsp;&nbsp;{b.comment}</span>
              )}
              {b.resident && (
                <span>
                  <Age dateOfBirth={b.resident.dateOfBirth} sex={b.resident.sex as any} />

                  {context.permissions.resident_accommodationBookings_dragAndDrop ? (
                    <DraggableName
                      residentId={b.resident.id}
                      name={b.resident.name}
                      roomId={room.id}
                    />
                  ) : (
                    <Link
                      className='text-gray-900 mx-2 underline hover:text-blue-500'
                      to={`/residents/${toJbpId(
                        +b.resident.id,
                      ).toLowerCase()}/accommodation`}
                      target='_blank'
                    >
                      {b.resident.name}
                    </Link>
                  )}
                  <span className='text-gray-400'>{getTimes(b.beginAt, b.endAt)}</span>
                </span>
              )}
              {b.type === 'empty-bed' && b.lock && (
                <span className='text-gray-400'>
                  {b.lock.reason}
                  {b.lock.notes && <>&nbsp;&nbsp;·&nbsp;&nbsp;{b.lock.notes}</>}
                </span>
              )}
              {b.type === 'empty-bed' && !b.lock && (
                <span className='text-gray-400'>Leeres Bett</span>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  },
)
