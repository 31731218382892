import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Model } from 'components/Form/Model'
import { InputDropdownOption } from 'components/Form/components/InputDropdown'
import { IEmployeeCommTopic } from 'contracts/employeeComm/interfaces/IEmployeeCommTopic'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { NewSalarySlipTopic } from './components/NewSalarySlipTopic'

interface Props {
  type: IEmployeeCommTopic['type']
  company: InputDropdownOption
  onClose: () => void
}

const titles = {
  'employee message': 'Neue Mitarbeiternachricht',
  'salary slip': 'Gehaltszettel Sammelupload',
}

@observer
export class NewTopicDialog extends React.Component<Props, {}> {
  private readonly model: Model<{ month: string; company: string }>

  constructor(props: Props) {
    super(props)
    this.model = new Model({
      month: dayjs().format('YYYY-MM'),
      company: props.company.label,
    })
  }

  render() {
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='text-left'>
          <Dialog.Title
            as='h3'
            className='text-lg leading-6 font-medium text-gray-900 mb-4'
          >
            {titles[this.props.type]}
          </Dialog.Title>
        </div>

        {this.props.type === 'salary slip' && (
          <NewSalarySlipTopic
            key={this.model.values.month}
            company={this.props.company}
            onClose={this.props.onClose}
          />
        )}
      </div>
    )
  }
}
