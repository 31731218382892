import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { makeObservable, observable, reaction, runInAction, when } from 'mobx'
import { Message } from 'components/Message'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { Button } from 'components/Form/components/Button'
import { hermes, Resource } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import * as uuid from 'uuid'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { ICostCoverageMeta } from 'contracts/costCoverages/interfaces/ICostCoverageMeta'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { download } from 'helpers/download'
import { IInvoiceBatch } from 'contracts/costCoverages/interfaces/IInvoiceBatch'

interface Props {
  costCoverageIds: string[]
  month: string
  compoundId: string
  buildingGroupId: string | null
  onClose: (val?: any) => void
  model: Model<{ selected: Map<string, ICostCoverageMeta | null> }>
  storno?: boolean
}

@observer
export class CreateCostCoverageInvoicesDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private done: string | null = null // batch-id when done! Otherwise null.
  @observable private progress: number | null = null
  @observable private error: string | null = null
  private readonly model = new Model<{ label: string }>({ label: '' })

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private onSubmit = async () => {
    if (this.progress !== null) {
      return
    }
    const label = this.model.values.label.trim().replace(/[^a-zA-Z0-9._ -]/g, '')
    if (!label || label.startsWith('.')) {
      runInAction(() => (this.error = 'Bitte wählen Sie einen gültigen Dateinamen'))
      return
    }
    const disposers: Disposer[] = []
    const data = {
      id: uuid.v4(),
      compoundId: this.props.compoundId,
      month: this.props.month,
      label,
      storno: !!this.props.storno,
      costCoverageIds: this.props.costCoverageIds,
      buildingGroupId: this.props.buildingGroupId,
    }
    // This resource watches the invoice generation progress
    const resource = new Resource<IInvoiceBatch>(
      `/api/${this.context.instance.id}/costCoverages/invoiceBatches/${data.id}`,
    )
    disposers.push(resource.init())
    try {
      runInAction(() => {
        this.error = null
        this.progress = 0
      })
      await when(() => !!resource.data)
      const response = await hermes.create(
        `/api/${this.context.instance.id}/costCoverages/batchCreate`,
        data,
        { timeout: 60000 },
      )
      runInAction(() => {
        this.progress = 0.2
        for (const row of response.coverages) {
          const coverage = this.props.model.values.selected.get(row.id)
          if (coverage) {
            coverage.status = this.props.storno ? 'not billed' : 'billed'
            coverage.invoice = this.props.storno ? null : row.invoice
          }
        }
      })
      disposers.push(
        reaction(
          () =>
            resource.data && resource.data.total > 0
              ? resource.data.docs / resource.data.total
              : 0,
          (progress) => (this.progress = 0.2 + 0.7 * progress),
          { fireImmediately: true },
        ),
      )
      await when(
        () =>
          resource.data?.docs === response.coverages.length &&
          !!resource.data?.concatenated,
      )
      runInAction(() => (this.done = data.id))
    } catch (e: any) {
      runInAction(() => {
        this.progress = null
        console.log('>>', e)
        this.error =
          e.id === ConflictError.id
            ? e.message
            : 'Der Rechnungsstapel konnte nicht erstellt werden'
      })
    }
    dispose(disposers)
  }

  private download = () => {
    download(`/api/${this.context.instance.id}/costCoverages/batchDownload/${this.done}`)
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {this.props.storno ? 'Rechnungen stornieren' : 'Rechnungen erstellen'}
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        {!this.error && !this.done && (
          <Message color='primary' className='mt-6'>
            {this.progress === null
              ? 'Der Dateiname wird in der Änderungsliste und dem Log verwendet, um diesen Rechnungsstapel zu dokumentieren.'
              : 'Rechnungsstapel wird erstellt...'}
          </Message>
        )}

        {!this.done && (
          <div className='relative mt-4' id={this.model.id}>
            {this.progress === null && (
              <InputText
                model={this.model}
                name='label'
                placeholder='Dateiname'
                label='Rechnungsstapel Dateiname'
              />
            )}
            {this.progress !== null && (
              <div className='border border-gray-300 rounded-md h-10 overflow-hidden'>
                <div
                  className={`bg-green-500 text-white text-sm overflow-hidden whitespace-nowrap transition-width duration-500 ease-in-out ${
                    this.progress > 0 ? 'p-2' : ''
                  }`}
                  style={{ width: `${this.progress * 100}%`, height: '100%' }}
                >
                  {Math.round(this.progress * 100)} %
                </div>
              </div>
            )}

            <div className='mt-4 text-right'>
              <Button color='secondary' outline onClick={this.props.onClose}>
                Abbrechen
              </Button>
              {this.progress === null && (
                <Button
                  color={this.props.storno ? 'danger' : 'primary'}
                  className='ml-2'
                  onClick={this.onSubmit}
                >
                  {this.props.storno ? 'Rechnungen stornieren' : 'Rechnungen erstellen'}
                </Button>
              )}
            </div>
          </div>
        )}

        {this.done && (
          <div className='relative mt-4'>
            <Message color='success' className='mb-4'>
              Die Sammelrechnung wurde erstellt.
            </Message>

            <div className='mt-4 text-right'>
              <Button color='secondary' outline onClick={this.props.onClose}>
                Schließen
              </Button>
              <Button color='success' className='ml-3' onClick={this.download}>
                Sammelrechnung herunterladen <i className='fas fa-download' />
              </Button>
            </div>
          </div>
        )}
      </>
    )
  }
}
