import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { IVisitScanResult } from 'contracts/scan/interfaces/IVisitScanResult'
import { useResource } from 'helpers/useResource'
import { useCollection } from 'helpers/useCollection'
import { observer } from 'mobx-react-lite'
import { MemberItem } from 'modules/Residents/modules/CaseRecord/components/CaseRecordMenu/components/MemberItem'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import styles from '../../Residents/modules/CaseRecord/components/CaseRecordMenu/styles.module.scss'
import { VisitorSuccessCard } from './VisitorSuccessCard'

interface Props {
  station: IScanningStation
  scan: IVisitScanResult
}

export const VisitScanSuccessTile: React.FC<Props> = observer(({ scan, station }) => {
  const context = React.useContext(AppContext)
  const textColor = scan.type === 'in' ? 'text-green-500' : 'text-indigo-500'
  const family = useResource<IFamily>(
    `/api/${context.instance.id}/families/${scan.resident.familyId}`,
  )
  const familyMembers = useCollection<IResidentSearchResult>(
    `/api/${context.instance.id}/residentSearchResults`,
    {},
    {
      familyId: scan.resident.familyId,
      fields: 'bookings,accommodation,lastScanAtDe',
      sort: 'dateOfBirth,asc',
    },
  )

  return (
    <div className='bg-white rounded-md shadow-md p-4 flex-auto relative'>
      <div className='absolute top-0 bottom-0 left-0 right-0 overflow-x-hidden overflow-y-auto flex flex-col'>
        <div className='flex-content relative mt-6 mb-4'>
          <div className={`text-center ${textColor}`} style={{ fontSize: 40 }}>
            {scan.type === 'in' ? 'Eintritt' : 'Austritt'}
          </div>
        </div>
        <VisitorSuccessCard scan={scan} station={station} />
        {familyMembers.resources && familyMembers.resources.length > 1 && (
          <div className='flex-[1_1_100px] mt-6 mb-4'>
            <div className='flex-content relative mt-6'>
              <div className={`text-lg text-center font-bold ${textColor}`}>
                Weitere Familienmitglieder
              </div>
            </div>
            <div className='flex relative pb-6 px-6 justify-center'>
              <div
                className={`${styles.menu} flex-auto overflow-x-hidden overflow-y-scroll p-4 flex flex-col gap-4 relative`}
              >
                {familyMembers.resources.map((member) => {
                  if (!member.data || member.id === scan.resident.id) {
                    return null
                  }
                  const isHv = member.id === family.data?.hv
                  return (
                    <MemberItem
                      key={member.id}
                      resident={member.data}
                      isActive={false}
                      isHv={isHv}
                      allowRemove={false}
                      showPresence={true}
                      openInNewTab
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
