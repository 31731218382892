import { Resource } from '@byll/hermes'
import { ResourceChange } from '@byll/hermes/lib/interfaces/ResourceChange'
import axios from 'axios'
import { Button } from 'components/Form/components/Button'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {}

interface ITest {
  id: string
  counter: number
}

@observer
export class HermesResourceChangeHandlerExample extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly resource: Resource<ITest>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.resource = new Resource(`/api/${context.instance.id}/test-change-handlers/2`, {
      onChange: this.onChange,
    })
  }

  componentDidMount() {
    this.resource.init()
  }

  componentWillUnmount(): void {
    this.resource.dispose()
  }

  private onChange = (change: ResourceChange<ITest>) => {
    console.log('Got changes (individual resource)', change)
  }

  private inc = () => {
    void axios.patch(`/api/${this.context.instance.id}/test-change-handlers/2`, {
      counter: (this.resource.data?.counter || 0) + 1,
    })
  }

  render() {
    return (
      <div className='border border-black my-10'>
        Test resource change handlers {this.resource.data?.counter}
        <br />
        <Button color='primary' onClick={this.inc}>
          Inc
        </Button>
      </div>
    )
  }
}
