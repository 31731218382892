import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { InputImage } from 'components/Form/components/InputImage'
import { box } from 'services/box'
import { Card } from 'components/Card'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { Spinner } from 'components/Spinner'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { InventoryBundleValidator } from 'contracts/inventory/validators/InventoryBundleValidator'

interface Props {
  compoundId: string
  bundle: Partial<IInventoryBundle>
  setEdit: (edit: boolean) => void
  navigate: (url: string) => void
}

@observer
export class InventoryBundleForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IInventoryItem>
  @observable loading = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({ ...props.bundle }, InventoryBundleValidator)
  }

  private saveBundle = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    try {
      const data: any = {
        compoundId: this.props.compoundId,
        label: this.model.values.label,
        imageId: this.model.values.imageId,
      }
      if (!this.props.bundle.id) {
        const { id } = await hermes.create(
          `/api/${this.context.instance.id}/inventory/bundles`,
          data,
        )
        this.props.navigate(`/inventory/bundles/${id}`)
      } else {
        delete data.compoundId
        await hermes.patch(
          `/api/${this.context.instance.id}/inventory/bundles/${this.props.bundle.id}`,
          data,
        )
        this.props.setEdit(false)
      }
    } catch (_e) {
      box.alert(
        'Fehler',
        'Das Paket konnte nicht gespeichert werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
      )
    }
    runInAction(() => (this.loading = false))
  }

  private deleteBundle = async () => {
    if (!window.confirm('Wollen Sie dieses Paket wirklich unwiderruflich löschen?')) {
      return
    }
    runInAction(() => (this.loading = true))
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/inventory/bundles/${this.props.bundle.id}`,
      )
      this.props.navigate(`/inventory/bundles`)
    } catch (_e) {
      box.alert(
        'Fehler',
        'Das Paket konnte nicht gelöscht werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
      )
    }
    runInAction(() => (this.loading = false))
  }

  private cancel = () => {
    if (this.props.bundle.id) {
      this.props.setEdit(false)
    } else {
      this.props.navigate(`/inventory/bundles`)
    }
  }

  render() {
    if (!this.context.permissions.inventory_manageItems) {
      return <Forbidden />
    }
    return (
      <CardColumn>
        <Card>
          {!this.loading && (
            <div className='p-6'>
              <h3 className='text-gray-500 text-xl'>
                {this.props.bundle.id ? 'Paket bearbeiten' : 'Paket hinzufügen'}
              </h3>
              <div id={this.model.id} className='flex gap-4 mt-4'>
                <div className='flex-content'>
                  <InputImage
                    model={this.model}
                    name='imageId'
                    width={300}
                    height={300}
                    scope='inventory item'
                  />
                </div>
                <div id={this.model.id} className='flex-auto mt-4'>
                  <InputText model={this.model} name='label' label='Bezeichnung' />
                </div>
              </div>
              {this.props.bundle.id && (
                <Button
                  className='mt-4 float-left'
                  color='danger'
                  outline
                  onClick={this.deleteBundle}
                >
                  Löschen
                </Button>
              )}
              <div className='mt-4 mb-6 float-right'>
                <Button className='mr-2' color='secondary' outline onClick={this.cancel}>
                  Abbrechen
                </Button>
                <Button onClick={this.saveBundle}>Speichern</Button>
              </div>
            </div>
          )}
          {this.loading && <Spinner />}
        </Card>
      </CardColumn>
    )
  }
}
