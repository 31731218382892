import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'

interface Props {
  model: Model<{ locked: 'yes' | 'no' | null }>
}

@observer
export class RoomLockedFilter extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setOpen = () => (this.props.model.values.locked = 'no')
  @action private setLocked = () => (this.props.model.values.locked = 'yes')

  @action private reset = () => {
    this.props.model.values.locked = null
  }

  render() {
    const locked = this.props.model.values.locked || ''
    let label = 'Sperre'
    let color: string = 'text-gray-500'
    if (locked) {
      label = locked === 'no' ? 'Offen' : 'Gesperrt'
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='grid grid-cols-2 gap-4'>
              <Button
                onClick={this.setOpen}
                color={locked === 'no' ? 'primary' : 'secondary'}
                outline={locked !== 'no'}
              >
                Offen
              </Button>
              <Button
                onClick={this.setLocked}
                color={locked === 'yes' ? 'primary' : 'secondary'}
                outline={locked !== 'yes'}
              >
                Gesperrt
              </Button>

              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
