import { Menu } from '@headlessui/react'

import * as React from 'react'

interface Props {
  onSelect: (dialog: 'upload' | 'template') => void
}

function stopPropagation(event: any) {
  event.stopPropagation()
}

export const NewDocumentDropdown: React.FC<Props> = (props) => {
  return (
    <Menu
      as='div'
      className='absolute inline-block text-left'
      style={{ top: 17, right: 17 }}
    >
      <Menu.Button
        onClick={stopPropagation}
        className='bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-indigo-500 text-center rounded-full shadow-md text-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 has-tooltip'
        style={{ width: 30, height: 30, padding: 0 }}
      >
        <i className='fas fa-plus' aria-hidden='false' />
      </Menu.Button>
      <Menu.Items className='absolute right-0 top-5 w-32 mt-2 py-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10'>
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={(e) => {
                e.stopPropagation()
                props.onSelect('upload')
              }}
              className={`${
                active ? 'bg-blue-500 text-white' : 'text-gray-900'
              } group flex items-center w-full px-3 py-1 text-sm`}
            >
              Hochladen
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={(e) => {
                e.stopPropagation()
                props.onSelect('template')
              }}
              className={`${
                active ? 'bg-blue-500 text-white' : 'text-gray-900'
              } group flex items-center w-full px-3 py-1 text-sm`}
            >
              Aus Vorlage
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  )
}
