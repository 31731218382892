import { ITodoSearchResult } from '../interfaces/ITodoSearchResult'

export function getEmptyTodoSearchResult(): ITodoSearchResult {
  return {
    id: '',
    listId: '',
    residentId: null,
    compoundId: null,
    buildingId: null,
    priority: 1,
    dueDate: null,
    doneDate: null,
    label: '',
    notes: '',
    managed: false,
    gateMessage: '',
    deliveredAt: null,
    gateMessageValidTill: null,
    url: '',
    readConfirmation: false,

    rating: null,
    ratingBy: null,

    assigneeIds: [],
    assignees: [],

    watcherIds: [],
    watchers: [],

    creator: null,
    assigner: null,
    createdAt: new Date().toISOString(),
    createdBy: null,
    doneAt: null,
    doneBy: null,
  }
}
