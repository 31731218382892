import * as React from 'react'
import styles from '../styles.module.scss'
import { sleep } from '../../../../helpers/sleep'
import { AppStatus } from 'services/connection/helpers/getAppStatus'
import axios from 'axios'

interface Props {
  onSuccess: () => void
  onError: (error: any) => void
  appStatus: AppStatus
  // Component is displayed at least for minDuration
  // milliseconds on error so that user sees loading feedback
  minDurationOnError?: number
}

export class AppConnecting extends React.Component<Props, {}> {
  componentDidMount(): void {
    void this.checkConnection()
  }

  private checkConnection = async () => {
    const start = new Date()
    try {
      await axios.get(
        `/api/${this.props.appStatus.instanceId}/users/${this.props.appStatus.userId}`,
        { timeout: 6000 },
      )
      this.props.onSuccess()
    } catch (e: any) {
      await this.waitUntilMinDurationIsReached(start)
      console.log(e)
      if (e.error?.code === 401) {
        this.props.onError(new Error('invalid session'))
      } else {
        this.props.onError(e)
      }
    }
  }

  private waitUntilMinDurationIsReached = async (start: Date) => {
    const duration = new Date().getTime() - start.getTime()
    const remaining = this.props.minDurationOnError
      ? this.props.minDurationOnError - duration
      : 0
    if (remaining > 0) {
      await sleep(remaining)
    }
  }

  render() {
    return (
      <div className={styles.connecting}>
        <div className='flex-content mt-auto mb-auto text-center'>
          <div className={styles.wifi}>
            <svg
              className='svg-inline--fa fa-plug fa-w-12'
              style={{ fontSize: 50 }}
              aria-hidden='true'
              focusable='false'
              data-prefix='fas'
              data-icon='plug'
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 384 512'
              data-fa-i2svg=''
            >
              <path
                fill='currentColor'
                d='M320,32a32,32,0,0,0-64,0v96h64Zm48,128H16A16,16,0,0,0,0,176v32a16,16,0,0,0,16,16H32v32A160.07,160.07,0,0,0,160,412.8V512h64V412.8A160.07,160.07,0,0,0,352,256V224h16a16,16,0,0,0,16-16V176A16,16,0,0,0,368,160ZM128,32a32,32,0,0,0-64,0v96h64Z'
              ></path>
            </svg>
          </div>
          <div className='f3 mt2'>Verbinden</div>
        </div>
      </div>
    )
  }
}
