import * as React from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { ICostCoverageSignature } from 'contracts/costCoverages/interfaces/ICostCoverageSignature'
import { dayjs } from 'helpers/dayjs'

interface Props {
  signature: ICostCoverageSignature
  onClose: () => void
}

export const CostCoverageSignatureProblemAlert: React.FC<Props> = ({
  onClose,
  signature,
}) => (
  <>
    <div className='sm:flex sm:items-start'>
      <div
        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-red-100`}
      >
        <ExclamationIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
      </div>
      <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
        <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
          Aktion erforderlich
        </Dialog.Title>
        <div className='mt-2'>
          <div className='text-sm text-gray-500'>
            Der Nachweis kann erst gedruckt werden, wenn alle Belegungen von{' '}
            {dayjs(signature.beginDate).format('DD.MM.YYYY')} bis{' '}
            {dayjs(signature.endDate).format('DD.MM.YYYY')} in Inuv erfasst sind.
            <br />
            <br />
            {signature.occupancyProblems.map((p, i) => (
              <div key={i}>
                <span className='text-black font-fold mr-2'>{p.name}</span>
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                  {p.problem}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
      <button
        type='button'
        className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm bg-red-600 hover:bg-red-700 focus:ring-red-500`}
        onClick={onClose}
      >
        OK
      </button>
    </div>
  </>
)
