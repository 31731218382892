import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { IExtendedGroupParticipantEntry } from 'contracts/groups/interfaces/IExtendedGroupParticipantEntry'
import { Collection } from '@byll/hermes'
import { action } from 'mobx'
import { box } from 'services/box'

interface Props {
  compoundId: string
  selected: Set<string>
  entries: Collection<IExtendedGroupParticipantEntry>
}

const select = action(
  (
    e,
    scope: 'all' | 'none' | 'without responsibility',
    entries: Collection<IExtendedGroupParticipantEntry>,
    selected: Set<string>,
    compoundId: string,
  ) => {
    e.stopPropagation()
    switch (scope) {
      case 'all':
        for (const result of entries?.resources || []) {
          selected.add(result.id)
        }
        break
      case 'none':
        selected.clear()
        break
      case 'without responsibility':
        selected.clear()
        for (const result of entries?.resources || []) {
          if (result.data?.responsibleCompoundId === compoundId) {
            continue
          }
          selected.add(result.id)
        }
        if (selected.size === 0) {
          void box.alert(
            'Auswahlt',
            'Die Gruppe enthält keine Einträge mit anderer Zuständigkeit. Daher wurde nichts ausgewählt.',
          )
        }
    }
  },
)

export const GroupItemSelectDropdown: React.FC<Props> = (props) => (
  <Menu
    as='span'
    className='relative inline-block text-left'
    onClick={(e) => e.stopPropagation()}
  >
    <div>
      <Menu.Button className='mr-2 text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gradient-to-r from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500 py-0.5 px-2'>
        Auswählen <i className='fas fa-caret-down' />
      </Menu.Button>
    </div>

    <Transition
      as={React.Fragment}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <Menu.Items
        className='z-10 origin-top-right absolute left-0 md:right-0 mt-2 rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none'
        style={{ width: 220 }}
      >
        <div className='py-1'>
          <Menu.Item>
            {({ active }) => (
              <span
                className={`${
                  active && 'bg-blue-500 text-white'
                } block px-4 py-2 text-sm`}
                onClick={(e) =>
                  select(e, 'none', props.entries, props.selected, props.compoundId)
                }
              >
                Niemand auswählen
              </span>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <span
                className={`${
                  active && 'bg-blue-500 text-white'
                } block px-4 py-2 text-sm`}
                onClick={(e) =>
                  select(e, 'all', props.entries, props.selected, props.compoundId)
                }
              >
                Alle auswählen
              </span>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <span
                className={`${
                  active && 'bg-blue-500 text-white'
                } block px-4 py-2 text-sm`}
                onClick={(e) =>
                  select(
                    e,
                    'without responsibility',
                    props.entries,
                    props.selected,
                    props.compoundId,
                  )
                }
              >
                Andere Zuständigkeit
              </span>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
)
