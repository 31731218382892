/* This is a shared file. Please edit in api
   This info is used on frontend (select) and backend (report) */

export const lfgUnterbringungsgrundOptions = [
  { value: null, label: '' },
  { value: 'Asylbewerber', label: 'Asylbewerber' },
  { value: 'Obdachlose', label: 'Obdachlose' },
  { value: 'Ausländische Obdachlose', label: 'Ausländische Obdachlose' },
  { value: 'Spätaussiedler', label: 'Spätaussiedler' },
]
