import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { action, makeObservable, observable, reaction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext } from 'services/connection/models/AppContext'
import { IGroupWithParticipant } from 'contracts/groups/interfaces/IGroupWithParticipant'
import { IGroupField } from 'contracts/groups/interfaces/IGroupField'
import { GroupParticipantInputs } from 'modules/Groups/components/ParticipantEntry'
import { hermes } from '@byll/hermes'
import { toast } from 'react-toastify'

interface Props {
  onClose: () => void
  group: IGroupWithParticipant
  changed: { hasUnsavedChanges: boolean }
}

@observer
export class GroupParticipantDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private readonly model: Model<any>
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    this.model = new Model({ ...props.group.participant })
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => toJS(this.model.values),
        (current) => {
          for (const key of Object.keys(current)) {
            if (current[key] === this.props.group[key]) {
              continue
            }
            this.props.changed.hasUnsavedChanges = true
            return
          }
          this.props.changed.hasUnsavedChanges = false
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  private onSubmit = async () => {
    this.props.changed.hasUnsavedChanges = false
    const data: any = { ...this.model.values }
    delete data.id
    try {
      await hermes.patch(
        `/api/${this.context.instance.id}/groups/${this.props.group.id}-${this.props.group.version}/participants/${this.props.group.participant.id}`,
        data,
      )
    } catch (_e) {
      toast.error('Fehler beim Speichern')
    }
    this.props.onClose()
  }

  private mapOptions = (options: string[]) => {
    if (options.length === 0) {
      return
    }
    return [
      { value: '', label: 'Bitte wählen' },
      ...options.map((o) => ({ value: o, label: o })),
    ]
  }

  render() {
    const disabled = !this.context.permissions.groups_participants
    return (
      <div id={this.model.id}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {this.props.group.label}: Teilnehmer
              </Dialog.Title>
            </div>
          </div>
        </div>

        <div className='p-6 flex flex-col gap-4'>
          {this.props.group.fields.map((field: IGroupField) => {
            const FieldComponent = GroupParticipantInputs[field.type ?? 'text']
            return (
              <div key={field.id} className='whitespace-nowrap text-sm'>
                <FieldComponent
                  name={field.id}
                  model={this.model}
                  label={field.label}
                  options={this.mapOptions(field.options) as any}
                  disabled={!this.context.permissions.groups_participants}
                />
              </div>
            )
          })}
        </div>

        <div
          className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button color='secondary' outline onClick={this.props.onClose}>
            {disabled ? 'Schließen' : 'Abbrechen'}
          </Button>
          {!disabled && (
            <Button color='primary' className='ml-2' onClick={this.onSubmit}>
              Speichern
            </Button>
          )}
        </div>
      </div>
    )
  }
}
