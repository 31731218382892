import { z } from 'zod'

export const CompanyValidator = z.object({
  id: z.string(),
  instanceId: z.string(),
  name: z.string().min(1).max(255),
  address: z.string(),
  contactEmail: z.union([z.string().email(), z.string().max(0)]),
  contactPerson: z.string().max(255),
  comment: z.string(),
  archived: z.boolean(),
})
