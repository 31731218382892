import { Model } from 'components/Form/Model'
import { SideBarLayout } from 'components/SideBarLayout'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { getCurrentResponsibilityCompoundId } from 'helpers/getCurrentResponsibilityCompoundId'
import { isStammCompound } from 'helpers/isStamm'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InventoryScanContent } from './components/InventoryScanContent'
import { InventoryScanSidebar } from './components/InventoryScanSidebar'

interface Props {}

export interface IView {
  compoundId: string | null
  residentId: string | null
  responsibleCompoundId: string | null
  scope: 'child' | 'woman' | 'man' | null
}

@observer
export class InventoryScan extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly view = new Model<IView>({
    compoundId: null,
    residentId: null,
    responsibleCompoundId: null,
    scope: null,
  })

  constructor(props: Props, context: AppContextProps) {
    super(props)
    const filter = (compound: ICompound) => {
      if (!compound.hasInventory) {
        return false
      }
      return context.permissions.menu_inventoryScan === 2 || isStammCompound(compound.id)
    }
    this.view.values.compoundId = getCurrentResponsibilityCompoundId(context, filter)
  }

  render() {
    return (
      <SideBarLayout>
        <InventoryScanSidebar view={this.view} />
        <InventoryScanContent view={this.view} key={this.view.values.compoundId} />
      </SideBarLayout>
    )
  }
}
