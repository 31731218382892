import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from '../../../components/Form/components/Button'
import { Dialog } from '@headlessui/react'
import { IGroupField } from '../../../contracts/groups/interfaces/IGroupField'
import { RoundIcon } from '../../../components/RoundIcon'
import { hermes } from '@byll/hermes'
import * as uuid from 'uuid'
import { Model } from '../../../components/Form/Model'
import { GroupField } from './GroupField'
import { InputText } from '../../../components/Form/components/InputText'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { IGroup } from '../../../contracts/groups/interfaces/IGroup'
import { AppContext } from 'services/connection/models/AppContext'
import { Message } from '../../../components/Message'
import { CreateGroupValidator } from 'contracts/groups/validators/CreateGroupValidator'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { InputTextDate } from 'components/Form/components/InputTextDate'
import { dayjs } from 'helpers/dayjs'

interface Props {
  onClose: (groupId?: string) => void
  compoundId: string
}

export interface IDialogField {
  id: string
  type: 'text' | 'date' | 'select' | 'checkbox' | ''
  label: string
  options: string
}

@observer
export class NewGroupDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private fields: IDialogField[] = []
  @observable private saving = false
  @observable private error: string | null = null
  private readonly model = new Model<{ label: string; deletedAfterYmd: string | null }>(
    {
      label: '',
      deletedAfterYmd: null,
    },
    CreateGroupValidator.omit({ fields: true, compoundId: true }),
  )

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private save = async () => {
    const fields: IGroupField[] = []
    for (const field of this.fields) {
      if (field.type === 'select') {
        // Trim, filter and deduplicate options
        const options = Array.from(
          new Set(
            (field.options || '')
              .split(',')
              .map((o) => o.substring(0, 255).trim())
              .filter(Boolean),
          ).values(),
        )
        fields.push({ ...(field as any), label: field.label.trim(), options })
      } else {
        fields.push({ ...(field as any), label: field.label.trim(), options: [] })
      }
    }

    const entry: Omit<IGroup, 'id' | 'version' | 'createdAt' | 'createdBy'> = {
      compoundId: this.props.compoundId,
      label: this.model.values.label.trim(),
      deletedAt: this.model.values.deletedAfterYmd
        ? dayjs(this.model.values.deletedAfterYmd).toISOString()
        : null,
      fields,
    }

    if (!entry.label) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (
      fields.some(
        (f) => !f.label || !f.type || (f.type === 'select' && f.options.length === 0),
      )
    ) {
      runInAction(() => (this.error = 'Bitte füllen Sie alle Felder aus'))
      return
    }

    try {
      runInAction(() => (this.saving = true))
      const response = await hermes.create(
        `/api/${this.context.instance.id}/groups`,
        entry,
      )
      this.props.onClose(response.id)
    } catch (_e) {
      runInAction(() => {
        this.saving = false
        this.error = 'Die Gruppe konnte nicht erstellt werden'
      })
    }
  }

  @action private addNewField = () => {
    this.fields.push({
      id: uuid.v4(),
      type: '',
      label: '',
      options: '',
    })
  }

  @action private removeField = (id: string) => {
    this.fields.splice(
      this.fields.findIndex((f) => f.id === id),
      1,
    )
  }

  private mapFields = (field: IDialogField) => (
    <GroupField key={field.id} field={field} onDelete={this.removeField} />
  )

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Neue Gruppe erstellen
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='my-3'>
            {this.error}
          </Message>
        )}

        <div id={this.model.id} className='flex gap-5'>
          <InputText
            name={'label'}
            model={this.model}
            label={'Gruppenname'}
            className={'flex-auto my-5'}
          />
          <InputTextDate
            blurAction='clear-field-if-invalid-date'
            name='deletedAfterYmd'
            model={this.model}
            label='Autmatisch archivieren am'
            className='flex-[0_0_170px] my-5'
          />
        </div>
        <div>{this.fields.map(this.mapFields)}</div>

        <div>
          <RoundIcon
            icon='fa fa-plus'
            onClick={this.addNewField}
            style={{ marginRight: 7 }}
          />
          Neues Feld hinzufügen
        </div>

        <div className='mt-4 text-right'>
          <Button color='secondary' outline onClick={() => this.props.onClose()}>
            Schließen
          </Button>
          <Button color='success' className='ml-3' onClick={this.save}>
            Gruppe speichern
          </Button>
        </div>

        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
