import { Collection } from '@byll/hermes'
import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { InventoryItemPreview } from './components/InventoryItemPreview'
import { InventoryItemPlaceholder } from '../InventoryFolder/components/InventoryFolderPlaceholder'
import { InventoryItemEdit } from './components/InventoryItemEdit'

interface Props {
  id: string
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
}

export const InventoryItem: React.FC<Props> = (props) => {
  const [edit, setEdit] = React.useState(false)
  const navigate = useNavigate()
  const folder = props.folders.resources?.find(
    (f) => !!f.data?.items.filter((i) => i.id === props.id)?.length,
  )

  if (!edit && folder?.data) {
    return (
      <InventoryItemPreview
        id={props.id}
        folder={folder.data}
        folders={props.folders}
        setEdit={setEdit}
      />
    )
  }

  if (edit && folder?.data) {
    return (
      <InventoryItemEdit
        id={props.id}
        folder={folder.data}
        navigate={navigate}
        setEdit={setEdit}
      />
    )
  }

  return <InventoryItemPlaceholder />
}
