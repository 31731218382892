import { allImagesLoaded } from './allImagesLoaded'
import { hasWaiters } from './hasWaiters'

export function setReadyFlagWhenRenderingComplete(timeout = 15000) {
  // Timeout in milliseconds
  // Wait until none of the children reports a blocker. (Elements with class "wait" are blockers)
  // Blockers are used if a components loads something asynchronously and cannot show the
  // final content yet.
  const start = new Date().getTime()
  const timer = setInterval(() => {
    if (new Date().getTime() - start > timeout) {
      console.log(`Timeout occured. Rendering could not be completed after ${timeout}ms.`)
      clearInterval(timer)
      return
    }
    if (hasWaiters() || !allImagesLoaded()) {
      console.log('Waiter detected, try again...')
      return
    }
    console.log('Rendering completed. Final result is displayed.')
    ;(window as any).ready = true
    clearInterval(timer)
  }, 50)
}
