import { Collection, hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { ITodoMessage } from 'contracts/todos/interfaces/ITodoMessage'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { TicketMessage } from './TicketMessage'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { Spinner } from 'components/Spinner'
import { InputDocument } from 'components/Form/components/InputDocument'
import { Tooltip } from 'components/Tooltip'

interface Props {
  ticket: ITodoSearchResult
}

@observer
export class TicketDialogActivities extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly messages: Collection<ITodoMessage>
  private readonly disposers: Disposer[] = []
  private model = new Model<{ label: string; documentId: string | null }>({
    label: '',
    documentId: null,
  })
  @observable loading: boolean = false
  @observable uploading: boolean = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.messages = new Collection<ITodoMessage>(
      `/api/${context.instance.id}/todos/${props.ticket.id}/todoMessages`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.messages.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private addMessage = async () => {
    runInAction(() => {
      this.model.values.label = this.model.values.label.trim()
      if (this.model.values.label.length === 0 && this.model.values.documentId) {
        this.model.values.label = `${this.context.user.firstName} ${this.context.user.lastName} hat eine Datei hochgeladen.`
      } else {
        this.model.values.label = `${this.context.user.firstName} ${this.context.user.lastName}: ${this.model.values.label}`
      }
    })
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    await hermes.create(
      `/api/${this.context.instance.id}/todos/${this.props.ticket.id}/todoMessages`,
      {
        label: this.model.values.label,
        documentId: this.model.values.documentId,
      },
    )
    runInAction(() => {
      this.loading = false
      this.model.values.label = ''
      this.model.values.documentId = null
      this.model.untouchAll()
    })
  }

  @action
  private onUpload = (event: 'started' | 'finished' | 'failed') => {
    this.uploading = event === 'started'
  }

  render() {
    const hasPermissionToChat =
      this.context.permissions.dashboard_tickets > 0 ||
      this.props.ticket.assignees
        .filter((a) => a.entity === 'user')
        .map((a) => a.id)
        .includes(this.context.user.id) ||
      this.props.ticket.createdBy === this.context.user.id
    return (
      <div className='rounded-sm shadow bg-white flex relative pb-8'>
        <div className='flex-[0_0_268px] pt-11 pl-11 leading-7'>
          <div>Aktivitäten</div>
          <div className='text-gray-400 text-xs'>
            Hier werden wichtige Aktivitäten und
            <br />
            die Kommunikation bzgl. der Aufgabe festgehalten.
          </div>
        </div>

        <div className='flex-auto pt-11 pr-6'>
          <div className='flex flex-col-reverse gap-2'>
            {!this.messages.resources && (
              <div className='relative min-h-[20px]'>
                <Spinner />
              </div>
            )}
            {this.messages.resources?.map((message) => {
              if (!message.data) {
                return null
              }
              return <TicketMessage key={message.id} message={message.data} />
            })}
          </div>
          {hasPermissionToChat && (
            <div id={this.model.id}>
              <div className='flex mt-4 mb-2'>
                <img
                  className={`relative h-8 w-8 rounded-full ring-2 ring-white`}
                  src={getResidentImageSrc(
                    this.context.instance.id,
                    this.context.user.imageId,
                    this.context.user.sex,
                    'portrait',
                  )}
                  alt=''
                />
                <InputTextarea
                  className='ml-2 w-full'
                  model={this.model}
                  name='label'
                  placeholder='Kommentar hinzufügen'
                  disabled={this.loading}
                />
              </div>
              <InputDocument
                className='ml-10 mb-4'
                name='documentId'
                model={this.model}
                scope='employee'
                onUpload={this.onUpload}
                disabled={this.uploading}
              />
            </div>
          )}
          {hasPermissionToChat && (
            <Button
              onClick={this.addMessage}
              className={`float-right ${this.uploading ? 'has-tooltip' : ''}`}
              loading={this.loading}
              disabled={
                this.uploading ||
                (this.model.values.label.trim() === '' && !this.model.values.documentId)
              }
            >
              Abschicken
              <Tooltip position='top'>Dokument wird hochgeladen</Tooltip>
            </Button>
          )}
        </div>
      </div>
    )
  }
}
