import * as React from 'react'
import { RoundIcon } from '../../../../RoundIcon'
import { IImageSection } from '../helpers/getImageSection'

interface Props {
  src: string
  width: number
  height: number
  onEdit: () => void
  onDelete: () => void
  cache?: {
    id: string
    src: string
    width: number
    height: number
    section: IImageSection
  }
  disabled?: boolean
}

export class DisplayImage extends React.Component<Props, {}> {
  render() {
    const cache = this.props.cache
    const style: any = {
      position: 'absolute',
      maxWidth: 'initial',
      left: 0,
      top: 0,
      width: this.props.width,
      height: this.props.height,
    }
    if (cache?.section) {
      const scale = this.props.width / cache.section.width
      style.left = -Math.round(cache.section.x * scale)
      style.top = -Math.round(cache.section.y * scale)
      style.width = Math.ceil(cache.width * scale)
      style.height = Math.ceil(cache.height * scale)
    }

    return (
      <div>
        <img alt='' style={style} src={this.props.src} />
        {!this.props.disabled && (
          <>
            <RoundIcon
              color='white'
              icon='fas fa-pencil-alt'
              onClick={this.props.onEdit}
              tooltip='Bild bearbeiten'
              style={{
                position: 'absolute',
                right: 50,
                bottom: 10,
                padding: '1px 0 0 0',
              }}
            />
            <RoundIcon
              color='white'
              icon='fas fa-trash'
              onClick={this.props.onDelete}
              tooltip={{ text: 'Bild löschen', offset: -80 }}
              style={{
                position: 'absolute',
                right: 10,
                bottom: 10,
                padding: '1px 0 0 0',
              }}
            />
          </>
        )}
      </div>
    )
  }
}
