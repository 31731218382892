import { hermes } from '@byll/hermes'
import { IRastNotificationResponse } from 'contracts/general/interfaces/IRastNotificationResponse'
import dayjs from 'dayjs'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  notifications: IRastNotificationResponse[] | null
}

@observer
export class RastNotificationsList extends React.Component<Props, {}> {
  static contextType = AppContext

  private toggleNotification = async (n: IRastNotificationResponse) => {
    if (this.context.permissions.menu_rastNotification < 2) {
      return
    }
    if (!n.createdAt) {
      const data = {
        type: n.type,
        residentId: n.residentId,
        buildingId: n.buildingId,
        date: dayjs(n.date).format('YYYY-MM-DD'),
        createdAt: dayjs().toISOString(),
      }
      try {
        await hermes.create(`/api/${this.context.instance.id}/rastNotifications`, data)
        n.createdAt = dayjs().toISOString()
      } catch (e) {
        box.alert(
          'Fehler',
          'Die RASt Meldung konnte nicht gespeichert werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator',
        )
      }
    } else {
      try {
        const id = `${n.type}-${dayjs(n.date).format('YYYY-MM-DD')}-${n.residentId}-${
          n.buildingId
        }`
        await hermes.delete(`/api/${this.context.instance.id}/rastNotifications/${id}`)
        n.createdAt = null
      } catch (e) {
        box.alert(
          'Fehler',
          'Die RASt Meldung konnte nicht gelöscht werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator',
        )
      }
    }
  }

  private downloadNotification = (n: IRastNotificationResponse) => {
    n.date = dayjs(n.date).format('YYYY-MM-DD')
    createAndDownloadReport(
      'rast-notification',
      this.context.instance.id,
      n,
      'Rastmeldung.docx',
    )
  }

  render() {
    return (
      <div className='m-6 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      RASt Meldung
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Aktion
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Durchgeführt am
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Unterkunft
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {this.props.notifications &&
                    this.props.notifications.length > 0 &&
                    this.props.notifications.map((n, i) => (
                      <tr key={i}>
                        <td className='whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                          <span
                            className='text-gray-600 cursor-pointer hover:bg-slate-100 p-2 rounded'
                            onClick={() => this.downloadNotification(n)}
                          >
                            <i className='fas fa-file-download' />
                          </span>
                          <button
                            disabled={this.context.permissions.menu_rastNotification < 2}
                            className={`relative ml-2 cursor pointer inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gradient-to-r ${
                              n.createdAt
                                ? 'from-green-500 to-green-600'
                                : 'from-red-500 to-red-600'
                            } hover:bg-gradient-to-r ${
                              this.context.permissions.menu_rastNotification === 2
                                ? n.createdAt
                                  ? 'hover:from-green-500 hover:to-green-500 focus:ring-green-500'
                                  : 'hover:from-red-500 hover:to-red-500 focus:ring-red-500'
                                : ''
                            } `}
                            onClick={() => this.toggleNotification(n)}
                          >
                            {n.createdAt ? 'Erledigt' : 'Ausstehend'}
                          </button>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {n.residentName}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {n.type}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {dayjs(n.date).format('DD.MM.YYYY')}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {n.label}
                        </td>
                      </tr>
                    ))}
                  {(!this.props.notifications ||
                    this.props.notifications.length === 0) && (
                    <tr>
                      <td
                        className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'
                        colSpan={5}
                      >
                        Derzeit liegen keine Einträge mit Ihren Filterkriterien vor.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
