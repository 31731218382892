import { Nullable } from '../../general/interfaces/Nullable'
import { IPermissions } from './IPermissions'

export const INSTANCE_ROLE_LABEL = 'Instanz'
export const ALL_PERMISSIONS_ROLE_LABEL = 'Alle Rechte'

export interface IRole extends Nullable<IPermissions> {
  id: string
  instanceId: string
  userId: string | null
  label: string
  description: string
  createdAt: string
}
