import { Model } from 'components/Form/Model'
import { reaction } from 'mobx'
import { AppContextProps } from 'services/connection/models/AppContext'
import { storage } from 'services/storage'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'

export function saveOccupancyRowFilterInLocalStorage(
  model: Model<IOccupancyFilter>,
  context: AppContextProps,
): Disposer {
  return reaction(
    () =>
      `${model.values.compoundId}/${model.values.buildingId}/${model.values.floorIds}/${model.values.view}`,
    () => {
      storage.set(`occupancy.${context.user.id}`, {
        compoundId: model.values.compoundId,
        buildingId: model.values.buildingId,
        floorIds: model.values.floorIds,
        view: model.values.view,
      })
    },
  )
}
