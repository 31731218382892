import { mealDict } from '../../scan/validators/MealScanValidator'
import { IPermissions } from '../../users/interfaces/IPermissions'

export function getMealTypes(
  permissions: IPermissions,
): { value: string; label: string }[] {
  // Figure out which meals are offered. Ensure that breakfast, lunch and dinner are always in list.
  // If not explicitly specified in resident_meal_types, put them first in list. Otherwise respect order of resident_meal_types.
  const keys: string[] = permissions.resident_meal_types.split(',').filter(Boolean)
  if (!keys.some((k) => k === 'dinner')) {
    keys.unshift('dinner')
  }
  if (!keys.some((k) => k === 'lunch')) {
    keys.unshift('lunch')
  }
  if (!keys.some((k) => k === 'breakfast')) {
    keys.unshift('breakfast')
  }
  return keys.map((key) => ({
    value: key,
    label: mealDict.get(key as any) || key,
  }))
}
