import * as React from 'react'
import { Collection, Resource } from '@byll/hermes'
import { IHealth } from 'contracts/residents/interfaces/IHealth'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { Table } from '..'
import { Dialog } from 'components/Dialog'
import { HealthDialog } from './HealthDialog'
import dayjs from 'dayjs'
import { RoundIcon } from 'components/RoundIcon'

interface Props {
  table: Table
  residentId: string
  health: Collection<IHealth>
  readOnly?: boolean
}

@observer
export class HealthTable extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private dialog: IHealth | null = null
  @observable private changed = { hasUnsavedChanges: false }

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private addData = () => {
    this.changed = { hasUnsavedChanges: false }
    this.dialog = {
      id: '',
      residentId: this.props.residentId,
      label: '',
      type: this.props.table.type,
      date: dayjs().format('YYYY-MM-DD'),
      intake: '',
      quarter: 'I',
      intakeUntilDate: null,
    }
  }

  @action
  private openDialog = (h: Resource<IHealth>) => {
    this.dialog = h.data
  }

  @action
  private closeDialog = () => {
    if (this.changed.hasUnsavedChanges) {
      const result = window.confirm(
        'Sie haben ungespeicherte Änderungen. Wollen Sie den Dialog wirklich schließen und die Änderungen verwerfen?',
      )
      if (!result) {
        return
      }
    }
    this.changed.hasUnsavedChanges = false
    this.dialog = null
  }

  render() {
    return (
      <>
        <Dialog size='md' open={!!this.dialog} setOpen={this.closeDialog}>
          {this.dialog && (
            <HealthDialog
              readOnly={this.props.readOnly}
              healthData={this.dialog}
              onClose={this.closeDialog}
              changed={this.changed}
            />
          )}
        </Dialog>
        <div className='px-4 sm:px-6 lg:px-8'>
          <div className='mb-8 flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='inline-block min-w-full py-2 align-middle px-8'>
                <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg relative'>
                  <table className='min-w-full max-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50'>
                      <tr>
                        {this.props.table.columns.map((c, i) => (
                          <th
                            key={i}
                            scope='col'
                            className='py-3.5 px-3 text-left text-sm font-semibold text-gray-900'
                            style={c.width ? { width: c.width } : undefined}
                          >
                            {c.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {this.props.health?.resources?.filter(
                        (t) => t.data?.type === this.props.table.type,
                      ).length === 0 && (
                        <tr>
                          <td className='text-gray-500 p-3' colSpan={2}>
                            Es sind keine Einträge vorhanden
                          </td>
                        </tr>
                      )}
                      {this.props.table.type === 'Medikament' &&
                        this.props.health?.resources
                          ?.filter((r) => r.data?.type === 'Medikament')
                          .map((h) => (
                            <tr
                              key={h.id}
                              className='text-left hover:bg-indigo-100 cursor-pointer'
                              onClick={() => this.openDialog(h)}
                            >
                              <td className='text-gray-500 p-3 truncate max-w-0'>
                                {h.data?.label}
                              </td>
                              <td className='text-gray-500 p-3 truncate max-w-[160px]'>
                                {h.data?.intake || ''}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {dayjs(h.data?.date).format('DD.MM.YYYY')}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {h.data?.intakeUntilDate
                                  ? dayjs(h.data?.intakeUntilDate).format('DD.MM.YYYY')
                                  : ''}
                              </td>
                            </tr>
                          ))}
                      {this.props.table.type === 'Impfung' &&
                        this.props.health?.resources
                          ?.filter((r) => r.data?.type === 'Impfung')
                          .map((h) => (
                            <tr
                              key={h.id}
                              className='text-left hover:bg-indigo-100 cursor-pointer'
                              onClick={() => this.openDialog(h)}
                            >
                              <td className='text-gray-500 p-3 truncate max-w-0'>
                                {h.data?.label}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {dayjs(h.data?.date).format('DD.MM.YYYY')}
                              </td>
                            </tr>
                          ))}
                      {this.props.table.type === 'Krankenschein' &&
                        this.props.health?.resources
                          ?.filter((r) => r.data?.type === 'Krankenschein')
                          .map((h) => (
                            <tr
                              key={h.id}
                              className='text-left hover:bg-indigo-100 cursor-pointer'
                              onClick={() => this.openDialog(h)}
                            >
                              <td className='text-gray-500 p-3 truncate max-w-0'>
                                {h.data?.label}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {dayjs(h.data?.date).format('DD.MM.YYYY')}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {h.data?.quarter || ''}
                              </td>
                            </tr>
                          ))}
                      {this.props.table.type === 'Zahnschein' &&
                        this.props.health?.resources
                          ?.filter((r) => r.data?.type === 'Zahnschein')
                          .map((h) => (
                            <tr
                              key={h.id}
                              className='text-left hover:bg-indigo-100 cursor-pointer'
                              onClick={() => this.openDialog(h)}
                            >
                              <td className='text-gray-500 p-3 truncate max-w-0'>
                                {h.data?.label}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {dayjs(h.data?.date).format('DD.MM.YYYY')}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {h.data?.quarter || ''}
                              </td>
                            </tr>
                          ))}
                      {this.props.table.type === 'Untersuchung' &&
                        this.props.health?.resources
                          ?.filter((r) => r.data?.type === 'Untersuchung')
                          .map((h) => (
                            <tr
                              key={h.id}
                              className='text-left hover:bg-indigo-100 cursor-pointer'
                              onClick={() => this.openDialog(h)}
                            >
                              <td className='text-gray-500 p-3 truncate max-w-0'>
                                {h.data?.label}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {dayjs(h.data?.date).format('DD.MM.YYYY')}
                              </td>
                            </tr>
                          ))}
                      {this.props.table.type === 'Corona' &&
                        this.props.health?.resources
                          ?.filter((r) => r.data?.type === 'Corona')
                          .map((h) => (
                            <tr
                              key={h.id}
                              className='text-left hover:bg-indigo-100 cursor-pointer'
                              onClick={() => this.openDialog(h)}
                            >
                              <td className='text-gray-500 p-3 truncate max-w-0'>
                                {h.data?.label}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {dayjs(h.data?.date).format('DD.MM.YYYY')}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {h.data?.intakeUntilDate
                                  ? dayjs(h.data?.intakeUntilDate).format('DD.MM.YYYY')
                                  : ''}
                              </td>
                            </tr>
                          ))}
                      {this.props.table.type === 'Quarantäne' &&
                        this.props.health?.resources
                          ?.filter((r) => r.data?.type === 'Quarantäne')
                          .map((h) => (
                            <tr
                              key={h.id}
                              className='text-left hover:bg-indigo-100 cursor-pointer'
                              onClick={() => this.openDialog(h)}
                            >
                              <td className='text-gray-500 p-3 truncate max-w-0'>
                                {h.data?.label}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {dayjs(h.data?.date).format('DD.MM.YYYY')}
                              </td>
                              <td className='text-gray-500 p-3'>
                                {dayjs(h.data?.intakeUntilDate).format('DD.MM.YYYY')}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  {!this.props.readOnly && (
                    <RoundIcon
                      classNameContainer='group-hover:block float-right'
                      tooltip={{
                        text: `${this.props.table.type} hinzufügen`,
                        position: 'left',
                      }}
                      icon='fas fa-plus'
                      color='primary'
                      onClick={this.addData}
                      style={{ position: 'absolute', top: 8, right: 8 }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
