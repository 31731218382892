import * as React from 'react'
import { observer } from 'mobx-react'
import { getFileExtensionIcon } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/helpers/getFileExtensionIcon'
import styles from './styles.module.scss'
import dayjs from 'dayjs'
import { getDiskSizeLabel } from 'contracts/general/helpers/getDiskSizeLabel'
import { RoundIcon } from 'components/RoundIcon'
import { IAttachment } from 'contracts/general/interfaces/IAttachment'

interface Props {
  attachment: IAttachment
  onDownload: (document: IAttachment) => void
  onDelete: (document: IAttachment) => void
  onPreview?: (document: IAttachment) => void
}

const trashStyle = {
  position: 'absolute',
  top: 21,
  right: 21,
  background: '#FD2547',
  color: 'white',
}

@observer
export class AttachmentTile extends React.Component<Props, {}> {
  private onDownload = () => {
    this.props.onDownload(this.props.attachment)
  }
  private onDelete = (event) => {
    event.stopPropagation()
    event.preventDefault()
    this.props.onDelete(this.props.attachment)
  }
  private onPreview = (event) => {
    event.stopPropagation()
    event.preventDefault()
    this.props.onPreview?.(this.props.attachment)
  }

  render() {
    const attachment = this.props.attachment

    return (
      <div
        className='group relative flex bg-white shadow-md h-[70px] cursor-pointer mt-4'
        onClick={this.onDownload}
      >
        <div className='flex-[0_0_70px] bg-center'>
          <img
            src={getFileExtensionIcon(attachment.name)}
            alt=''
            style={{ height: 50, margin: 10 }}
          />
        </div>
        <div className='flex-auto'>
          <div className='flex'>
            <div className={styles.documentName}>{attachment.name}</div>
            {/*attachment.file !== null && (
              <AttachmentUploadStripe
                key={attachment.id}
                state={attachment.state}
              />
            )*/}
          </div>
          <div className={`text-gray-400 text-sm truncate ${styles.documentDetails}`}>
            {getDiskSizeLabel(attachment.size)}
            &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
            {dayjs(attachment.createdAt).format('DD.MM.YYYY')}
            &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
            {attachment.uploadUser}
          </div>
        </div>
        <div className='hidden group-hover:block'>
          <RoundIcon
            tooltip='Anhang löschen'
            icon='fas fa-trash-alt'
            onClick={this.onDelete}
            style={trashStyle}
          />

          {this.props.onPreview && (
            <RoundIcon
              tooltip='Vorschau'
              icon='fas fa-external-link-alt'
              onClick={this.onPreview}
              style={{
                position: 'absolute',
                top: 21,
                right: 10 + 21 + 30,
                background: '#4f46e5',
                color: 'white',
              }}
            />
          )}
        </div>
      </div>
    )
  }
}
