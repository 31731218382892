import { dayjs } from 'contracts/general/helpers/dayjs'
import { IUserDefaults } from 'contracts/users/interfaces/IUserDefaults'

export function getMonthFromDefaults(defaults: IUserDefaults): string {
  if (!defaults.month) {
    return dayjs().format('YYYY-MM')
  }
  return dayjs().diff(defaults.month, 'months') > 3
    ? dayjs().format('YYYY-MM')
    : defaults.month
}
