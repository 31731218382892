import { hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { IEmployeeCommThread } from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { z } from 'zod'
import Iban from 'iban'
import { normalizeIban } from 'contracts/employeeComm/helpers/normalizeIban'

interface Props {
  thread: IEmployeeCommThread
}

@observer
export class EmployeeSalarySheetContact extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private isEditing = false
  @observable private isSaving = false
  private model = new Model<{ contact: string; iban: string }>({
    contact: '',
    iban: '',
  })

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private startEditing = (target: 'contact' | 'dob') => {
    if (this.isSaving) {
      return
    }
    if (this.context.permissions.menu_employeeCommunication < 2) {
      alert('Sie haben nicht die nötige Berechtigung, um diese Information zu ändern.')
      return
    }
    this.model.values.contact = this.props.thread.extra.receiver.contact
    this.model.values.iban = Iban.printFormat(this.props.thread.extra.receiver.iban, ' ')
    this.isEditing = true
    setTimeout(() => document.getElementById(`${this.model.id}-${target}`)?.focus(), 40)
  }

  @action private stopEditing = () => (this.isEditing = false)

  private save = async () => {
    const data = {
      contact: this.model.values.contact.trim(),
      iban: normalizeIban(this.model.values.iban),
    }
    if (data.iban && !Iban.isValid(data.iban)) {
      window.alert('Bitte geben Sie eine gültige IBAN ein.')
      return
    }
    if (data.contact && z.string().email().safeParse(data.contact).success === false) {
      window.alert('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
      return
    }

    runInAction(() => {
      this.isEditing = false
      this.isSaving = true
    })
    try {
      await hermes.update(
        `/api/${this.context.instance.id}/employeeComm/contacts/${this.props.thread.employee.id}`,
        data,
      )
    } catch (_e) {
      console.log(_e)
      setTimeout(() => {
        window.alert('Beim Speichern ist ein Fehler aufgetreten.')
      }, 0)
    }
    runInAction(() => (this.isSaving = false))
  }

  private onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.save()
    }
    if (e.key === 'Escape') {
      this.stopEditing()
    }
  }

  render() {
    if (this.isEditing) {
      return (
        <>
          <InputText
            placeholder='Handy oder E-Mail'
            model={this.model}
            name='contact'
            style={{ padding: '1px 4px', width: 168 }}
            id={this.model.id + '-contact'}
            onKeyDown={this.onKeyDown}
          />
          <InputText
            placeholder='IBAN'
            model={this.model}
            name='iban'
            style={{ padding: '1px 4px', width: 120 }}
            id={this.model.id + '-dob'}
            onKeyDown={this.onKeyDown}
          />
        </>
      )
    }

    return (
      <>
        <span
          onClick={() => this.startEditing('contact')}
          className={`cursor-pointer inline-flex whitespace-nowrap items-center rounded-md px-2 py-1 text-xs font-medium text-white ${
            this.isSaving
              ? 'bg-blue-500'
              : !this.props.thread.extra.receiver.contact
              ? 'bg-red-500'
              : 'bg-green-500'
          }`}
        >
          {this.isSaving && (
            <span className='mr-1'>
              <i className='fas fa-spinner fa-spin' />
            </span>
          )}
          {this.isSaving
            ? 'Speichern...'
            : this.props.thread.extra.receiver.contact || 'Kontaktinformationen fehlen'}
        </span>
        <span
          onClick={() => this.startEditing('dob')}
          className={`cursor-pointer inline-flex whitespace-nowrap items-center rounded-md px-2 py-1 text-xs font-medium text-white ${
            !this.props.thread.extra.receiver.iban ? 'bg-red-500' : 'bg-green-500'
          }`}
        >
          {Iban.printFormat(this.props.thread.extra.receiver.iban, ' ') || 'IBAN fehlt'}
        </span>
      </>
    )
  }
}
