import { InformationCircleIcon } from '@heroicons/react/outline'
import { IVisitScanResult } from 'contracts/scan/interfaces/IVisitScanResult'
import * as React from 'react'

interface Props {
  notifications: IVisitScanResult['gateNotifications']
}

export const GateNotifications: React.FC<Props> = (props) => (
  <div className='mb-8'>
    <div className={`rounded-md px-4 py-3 bg-indigo-500 mb-4 text-white`}>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <InformationCircleIcon className='h-5 w-5 text-white' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          {props.notifications.length === 1
            ? 'Bitte geben Sie die Nachricht an den Bewohner weiter'
            : 'Bitte geben Sie die Nachrichten an den Bewohner weiter'}
        </div>
      </div>
    </div>

    {props.notifications.map((n) => (
      <div key={n.id} className='bg-white rounded-md shadow-md px-4 py-3 mb-4'>
        <i className='fas fa-comment-alt' />
        <span className='ml-2'>{n.gateMessage}</span>
      </div>
    ))}
  </div>
)
