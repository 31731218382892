import { Collection, hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { observable, action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  itemId: string
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
  onClose: () => void
}

@observer
export class InventoryItemMoveDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable selectedFolder: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private moveItem = (key: string) => {
    this.selectedFolder = key
  }

  private saveItem = async () => {
    if (!this.context.permissions.inventory_manageItems) {
      return
    }
    await hermes.patch(
      `/api/${this.context.instance.id}/inventory/items/${this.props.itemId}`,
      { folderId: this.selectedFolder },
    )
    this.props.onClose()
  }

  render() {
    if (!this.props.folders?.resources) {
      return null
    }
    const nodes = this.props.folders.resources.map((f) => {
      if (!f.data) {
        return { key: '', title: '', children: [] }
      }
      return { key: `${f.id}`, title: f.data.label, children: [] }
    })
    return (
      <div>
        <span className='text-gray-500 text-xl'>Gegenstand verschieben</span>
        <div className='mt-5'>
          {nodes.map((n, i) => (
            <div key={i} className='my-1 text-sm'>
              <span
                className={`whitespace-nowrap px-2 py-1 ${
                  this.selectedFolder === n.key
                    ? 'text-white bg-indigo-500 rounded-md'
                    : ''
                }`}
              >
                <span
                  className={
                    this.selectedFolder === n.key
                      ? 'text-white mr-1 cursor-pointer'
                      : 'text-yellow-500 mr-1 cursor-pointer'
                  }
                >
                  <i className='fas fa-folder' />
                </span>
                <span
                  className={
                    this.selectedFolder === n.key
                      ? 'cursor-pointer'
                      : 'hover:text-black hover:underline cursor-pointer'
                  }
                  onClick={() => this.moveItem(n.key)}
                >
                  {n.title || '(leer)'}
                </span>
              </span>
            </div>
          ))}
        </div>
        <div className='float-right'>
          <Button color='secondary' className='mt-4' outline onClick={this.props.onClose}>
            Abbrechen
          </Button>
          <Button className='mt-4 ml-4' outline onClick={this.saveItem}>
            Speichern
          </Button>
        </div>
      </div>
    )
  }
}
