import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { observer } from 'mobx-react'
import { GateNotifications } from './components/GateNotifications'
import { ScanWarnings } from './components/ScanWarnings'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { Age } from 'components/Age'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { Button } from 'components/Form/components/Button'
import { Wait } from 'modules/Pdf/components/Wait'
import { IMealScanResult } from 'contracts/scan/interfaces/IMealScanResult'
import { SpecialMealPermissions } from '../SpecialMealPermissions'

interface Props {
  scan: IMealScanResult
  onClose: (accept: boolean) => void
}

@observer
export class MealActionDialog extends React.Component<Props, {}> {
  static contextType = AppContext

  componentDidMount(): void {
    setTimeout(() => {
      // Do not focus default option: Next scan would close the dialog because of the trailing return key. This is not intended.
      // const node = document.getElementById('VISIT_DEFAULT_OPTION')
      // node?.focus()
      if ((document.activeElement as any)?.blur) {
        ;(document.activeElement as any).blur()
      }
    }, 300)
  }

  private onSubmit = () => {
    if (this.props.scan.gateNotifications.length > 0) {
      void this.markTodosAsDelivered()
    }
    this.props.onClose(true)
  }

  private markTodosAsDelivered = async () => {
    try {
      await Promise.all(
        this.props.scan.gateNotifications.map((notification) =>
          hermes.patch(
            `/api/${this.context.instance.id}/todoLists/${this.context.defaults.residentTodoListId}/todos/${notification.id}`,
            { deliveredAt: new Date().toISOString() },
          ),
        ),
      )
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const resident = this.props.scan.resident
    const onlyGateNotification =
      this.props.scan.gateNotifications.length > 0 &&
      this.props.scan.warnings.length === 0
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose(false)}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {`${resident.lastName.toUpperCase()}, ${resident.firstName}`}{' '}
              <Age dateOfBirth={resident.dateOfBirth} sex={resident.sex} />
            </Dialog.Title>
            <div className='mt-3 flex gap-2 flex-wrap'>
              <SpecialMealPermissions item={this.props.scan} highlight />
            </div>
          </div>
        </div>

        <div className='mt-4 -mx-6 px-6 py-4 bg-gray-100 border-t border-gray-200'>
          <div className='flex h-[325px] overflow-hidden'>
            <img
              src={getResidentImageSrc(
                this.context.instance.id,
                resident.imageId,
                resident.sex,
                'portrait',
              )}
              alt={`${resident.lastName}, ${resident.firstName}`}
              className='w-2/3 rounded-full mb-4 mx-auto'
            />
          </div>
          {this.props.scan.gateNotifications.length > 0 && (
            <GateNotifications notifications={this.props.scan.gateNotifications} />
          )}
          {this.props.scan.warnings.length > 0 && (
            <ScanWarnings warnings={this.props.scan.warnings} />
          )}
          {onlyGateNotification && (
            <div className='flex pt-4 px-6 -mt-4 -mx-6 bg-white -mb-4 border-t border-gray-200'>
              <Button
                className='flex-auto text-base mr-2'
                color='secondary'
                outline
                onClick={() => this.props.onClose(false)}
              >
                Abbrechen
              </Button>
              <Button
                color='primary'
                id='VISIT_DEFAULT_OPTION'
                className='flex-auto text-base'
                onClick={this.onSubmit}
              >
                {this.props.scan.gateNotifications.length === 1
                  ? 'Bewohner hat Nachricht erhalten'
                  : 'Bewohner hat Nachrichten erhalten'}
              </Button>
            </div>
          )}
          {!onlyGateNotification && (
            <div
              className={`flex pt-4 px-6 -mx-6 bg-white -mb-4 border-t border-gray-200 ${
                this.props.scan.gateNotifications.length === 0 ? '-mt-4' : ''
              }`}
            >
              <Button
                id='VISIT_DEFAULT_OPTION'
                className='flex-auto text-2xl'
                autoFocus
                color='danger'
                onClick={() => this.props.onClose(false)}
              >
                Mahlzeit verweigern
              </Button>
              <Button
                color='success'
                className='flex-auto ml-2 text-2xl'
                onClick={this.onSubmit}
              >
                Mahlzeit ausgeben
              </Button>
            </div>
          )}
        </div>

        <Wait />
      </>
    )
  }
}
