export const INSTANCE_IDS = {
  dev: '0',
  app: '1',
  testapp: '2',
  stja: '3',
  teststja: '4',
  awum: '5',
  testawum: '6',
  lfg: '7',
  testlfg: '8',
}

function getSimulationInstanceId(instanceId: string): string {
  let DEV_SIMULATE_INSTANCE = ''
  if (instanceId === INSTANCE_IDS.dev) {
    try {
      if (!window) {
        throw new Error('Not defined')
      }
      DEV_SIMULATE_INSTANCE = (window as any).DEV_SIMULATE_INSTANCE
    } catch (_e) {
      DEV_SIMULATE_INSTANCE = process.env.DEV_SIMULATE_INSTANCE || ''
    }
  }
  return DEV_SIMULATE_INSTANCE || instanceId
}

export function isJbpInstance(instanceId: string): boolean {
  instanceId = getSimulationInstanceId(instanceId)
  return INSTANCE_IDS.app === instanceId || INSTANCE_IDS.testapp === instanceId
}

export function isLfgInstance(instanceId: string): boolean {
  instanceId = getSimulationInstanceId(instanceId)
  return INSTANCE_IDS.lfg === instanceId || INSTANCE_IDS.testlfg === instanceId
}

export function isMucInstance(instanceId: string): boolean {
  instanceId = getSimulationInstanceId(instanceId)
  return (
    INSTANCE_IDS.awum === instanceId ||
    INSTANCE_IDS.testawum === instanceId ||
    INSTANCE_IDS.stja === instanceId ||
    INSTANCE_IDS.teststja === instanceId
  )
}

export function isAwumInstance(instanceId: string): boolean {
  instanceId = getSimulationInstanceId(instanceId)
  return INSTANCE_IDS.awum === instanceId || INSTANCE_IDS.testawum === instanceId
}

export function isStjaInstance(instanceId: string): boolean {
  instanceId = getSimulationInstanceId(instanceId)
  return INSTANCE_IDS.stja === instanceId || INSTANCE_IDS.teststja === instanceId
}
