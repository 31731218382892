import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { isYmdDate } from 'contracts/general/helpers/isYmdDate'
import { IHealth } from 'contracts/residents/interfaces/IHealth'
import { z } from 'zod'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { action, makeObservable, reaction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { box } from 'services/box'

const quarters: InputSelectOption[] = [
  { value: 'I', label: 'I' },
  { value: 'II', label: 'II' },
  { value: 'III', label: 'III' },
  { value: 'IV', label: 'IV' },
]

const coronaTypes: InputSelectOption[] = [
  { value: '', label: 'Bitte wählen...' },
  { value: 'Corona Kontaktkategorie I', label: 'Corona Kontaktkategorie I' },
  { value: 'Corona Verdacht', label: 'Corona Verdacht' },
  { value: 'Corona Positiv', label: 'Corona Positiv' },
  { value: 'Corona Negativ', label: 'Corona Negativ' },
]

interface Props {
  onClose: () => void
  healthData: IHealth
  changed: { hasUnsavedChanges: boolean }
  readOnly?: boolean
}

@observer
export class HealthDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IHealth>

  get hasEndDate(): boolean {
    switch (this.model.values.type) {
      case 'Medikament':
      case 'Quarantäne':
        return true
      case 'Impfung':
      case 'Krankenschein':
      case 'Zahnschein':
      case 'Untersuchung':
        return false
      case 'Corona':
        return this.model.values.label === 'Corona Kontaktkategorie I'
    }
    return false
  }

  constructor(props: Props) {
    super(props)
    let validator = this.selectValidator()
    this.model = new Model({ ...props.healthData }, validator)
    makeObservable(this)
  }

  private selectValidator = () => {
    switch (this.props.healthData.type) {
      case 'Medikament':
        return z.object({
          label: z.string().min(1).max(255),
          date: z.string().refine(isYmdDate),
          intake: z.string().min(1).max(255),
        })
      case 'Impfung':
        return z.object({
          label: z.string().min(1).max(255),
          date: z.string().refine(isYmdDate),
          quarter: z.enum(['I', 'II', 'III', 'IV']),
        })
      case 'Untersuchung':
        return z.object({
          label: z.string().min(1).max(255),
          date: z.string().refine(isYmdDate),
        })
      case 'Krankenschein':
        return z.object({
          label: z.string().min(1).max(255),
          date: z.string().refine(isYmdDate),
          quarter: z.enum(['I', 'II', 'III', 'IV']),
        })
      case 'Zahnschein':
        return z.object({
          label: z.string().min(1).max(255),
          date: z.string().refine(isYmdDate),
        })
      case 'Corona':
        return z.object({
          label: z.string().min(1).max(255),
          date: z.string().refine(isYmdDate),
        })
      case 'Quarantäne':
        return z.object({
          label: z.string().min(1).max(255),
          date: z.string().refine(isYmdDate),
          intakeUntilDate: z.string().refine(isYmdDate),
        })
    }
  }

  componentDidMount() {
    reaction(
      () => toJS(this.model.values),
      () => (this.props.changed.hasUnsavedChanges = true),
    )
  }

  @action
  private onSubmit = () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (
      this.model.values.intakeUntilDate &&
      this.model.values.date > this.model.values.intakeUntilDate
    ) {
      box.alert('Datum ungültig', 'Das Enddatum darf nicht vor dem Startdatum liegen.')
      return
    }
    const data: Partial<IHealth> = {
      residentId: this.model.values.residentId.trim(),
      label: this.model.values.label.trim(),
      type: this.model.values.type,
      date: this.model.values.date,
      intake: this.model.values.intake?.trim(),
      quarter: this.model.values.quarter,
      intakeUntilDate: this.hasEndDate ? this.model.values.intakeUntilDate : null,
    }
    if (!this.model.values.id) {
      hermes
        .create(
          `/api/${this.context.instance.id}/residents/${this.model.values.residentId}/health`,
          data,
        )
        .catch(() =>
          alert(
            'Beim Erstellen ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.',
          ),
        )
    } else {
      hermes
        .patch(
          `/api/${this.context.instance.id}/residents/${this.model.values.residentId}/health/${this.model.values.id}`,
          data,
        )
        .catch(() =>
          alert(
            'Beim Speichern ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.',
          ),
        )
    }
    this.props.changed.hasUnsavedChanges = false
    this.props.onClose()
  }

  private onDelete = () => {
    hermes
      .delete(
        `/api/${this.context.instance.id}/residents/${this.model.values.residentId}/health/${this.model.values.id}`,
      )
      .catch(() =>
        alert(
          'Beim Löschen ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.',
        ),
      )
    this.props.changed.hasUnsavedChanges = false
    this.props.onClose()
  }

  @action private resetEndDate = () => (this.model.values.intakeUntilDate = null)

  render() {
    const readOnly = this.props.readOnly
    return (
      <div id={this.model.id}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {this.props.healthData.type}
              </Dialog.Title>
            </div>
          </div>
        </div>

        {(this.props.healthData.type === 'Impfung' ||
          this.props.healthData.type === 'Untersuchung') && (
          <div className='p-6 grid grid-cols-2 gap-4'>
            <InputText
              disabled={readOnly}
              model={this.model}
              name='label'
              label='Bezeichnung'
            />
            <InputDate disabled={readOnly} model={this.model} name='date' label='Datum' />
          </div>
        )}

        {(this.props.healthData.type === 'Krankenschein' ||
          this.props.healthData.type === 'Zahnschein') && (
          <div className='p-6 grid grid-cols-2 gap-4'>
            <InputText
              disabled={readOnly}
              className='col-span-2'
              model={this.model}
              name='label'
              label='Bezeichnung'
            />
            <InputDate
              disabled={readOnly}
              model={this.model}
              name='date'
              label='Ausgabedatum'
            />
            <InputSelect
              disabled={readOnly}
              model={this.model}
              name='quarter'
              label='Quartal'
              options={quarters}
            />
          </div>
        )}

        {this.props.healthData.type === 'Medikament' && (
          <div className='p-6 grid grid-cols-2 gap-4'>
            <InputText
              disabled={readOnly}
              model={this.model}
              name='label'
              label='Bezeichnung'
            />
            <InputText
              disabled={readOnly}
              model={this.model}
              name='intake'
              label='Einnahme'
              placeholder='z.B. 3x täglich'
            />
            <InputDate disabled={readOnly} model={this.model} name='date' label='Von' />
            <InputDate
              disabled={readOnly}
              model={this.model}
              name='intakeUntilDate'
              label='Bis'
            />
          </div>
        )}

        {this.props.healthData.type === 'Corona' && (
          <div className='p-6 grid grid-cols-2 gap-4'>
            <InputSelect
              disabled={readOnly}
              onChange={this.resetEndDate}
              className='col-span-2'
              model={this.model}
              name='label'
              label='Typ'
              options={coronaTypes}
            />
            <InputDate disabled={readOnly} model={this.model} name='date' label='Von' />
            <InputDate
              disabled={readOnly || !this.hasEndDate}
              model={this.model}
              name='intakeUntilDate'
              label='Bis'
            />
          </div>
        )}

        {this.props.healthData.type === 'Quarantäne' && (
          <div className='p-6 grid grid-cols-2 gap-4'>
            <InputText
              disabled={readOnly}
              className='col-span-2'
              model={this.model}
              name='label'
              label='Grund'
            />
            <InputDate disabled={readOnly} model={this.model} name='date' label='Von' />
            <InputDate
              disabled={readOnly}
              model={this.model}
              name='intakeUntilDate'
              label='Bis'
            />
          </div>
        )}

        {!readOnly && (
          <div
            className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200'
            style={{ borderRadius: '0 0 8px 8px' }}
          >
            {!!this.model.values.id && (
              <Button color='danger' className='float-left' onClick={this.onDelete}>
                Löschen
              </Button>
            )}
            <Button color='secondary' outline onClick={this.props.onClose}>
              {'Abbrechen'}
            </Button>
            <Button color='primary' className='ml-2' onClick={this.onSubmit}>
              Speichern
            </Button>
          </div>
        )}
        {readOnly && (
          <div
            className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200'
            style={{ borderRadius: '0 0 8px 8px' }}
          >
            <Button color='primary' className='ml-2' onClick={this.props.onClose}>
              Schließen
            </Button>
          </div>
        )}
      </div>
    )
  }
}
