import axios from 'axios'
import { ICostCoverageInvoice } from 'contracts/costCoverages/interfaces/ICostCoverageInvoice'
import { ICostCoverageSignature } from 'contracts/costCoverages/interfaces/ICostCoverageSignature'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { IFullOrder } from 'contracts/inventory/interfaces/IFullOrder'
import * as React from 'react'
import { Route, Routes, useParams } from 'react-router'
import { AppStatus } from 'services/connection/helpers/getAppStatus'
import { CornerBadge } from './components/CornerBadge'
import { CostCoverageInvoice } from './templates/CostCoverageInvoice'
import { CostCoverageSignature } from './templates/CostCoverageSignature'
import { OrderSheet } from './templates/OrderSheet'
import { ResidentDocumentTemplates } from './templates/ResidentDocumentTemplates'
import { ResidentIdCards } from './templates/ResidentIdCards'
import { ResidentCards } from './templates/ResidentCards'
import { ICardStorageData } from 'contracts/residents/interfaces/ICardStorageData'
import { Arbeitsplan } from './templates/Arbeitsplan'
import { ArbeitsplanFilterValidator } from 'contracts/pdf/ArbeitsplanFilterValidator'
import { setReadyFlagWhenRenderingComplete } from './helpers/setReadyFlagWhenRenderingComplete'
import { DokuExport } from './templates/DokuExport'
import { IDokuExportData } from 'contracts/residents/interfaces/IDokuExportData'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { ResidentProfileExport } from './templates/ResidentProfileExport'
import { ILogChange } from 'contracts/general/interfaces/ILog'
import { Stundenzettel } from './templates/Stundenzettel'
import { StundenzettelFilterValidator } from 'contracts/pdf/StundenzettelFilterValidator'
import { EmployeeIdCard } from './templates/EmployeeIdCard'
import { IUserCard } from 'contracts/userCard/interfaces/IUserCard'
import { ICompanyIdCardTemplate } from 'contracts/userCard/interfaces/ICompanyIdCardTemplate'
import { useSearchParams } from 'react-router-dom'
import { EinzelrechnungWohnungslose } from './templates/EinzelrechnungWohnungslose'

interface Props {
  appStatus: AppStatus
}

export const Pdf: React.FC<Props> = (props) => (
  <Routes>
    <Route
      path='resident-id-cards/:type/:cardIds'
      element={<RenderResidentIdCards instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='employee-id-cards/:cardId'
      element={<RenderEmployeeIdCard instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='resident-cards/:type/:cardId'
      element={<RenderResidentCardsBatch instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='cost-coverage-signature/:residentId/:signatureId'
      element={<RenderCostCoverageSignature instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='arbeitsplan/:month/:userId/:arbeitsplanDataId'
      element={<RenderArbeitsplan instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='stundenzettel/:month/:employeeIds'
      element={<RenderStundenzettel instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='cost-coverage-invoice/:invoiceId'
      element={<RenderCostCoverageInvoice instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='einzelrechnung-wohnungslose'
      element={
        <RenderEinzelrechnungWohnungslose instanceId={props.appStatus.instanceId} />
      }
    />
    <Route
      path='order-sheet/:orderId'
      element={<RenderOrderSheet instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='doku-export/:compoundId/:residentId/:family/:storageId'
      element={<RenderDokuExport instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='resident-profile-export/:residentId/:familyId'
      element={<RenderResidentProfileExport instanceId={props.appStatus.instanceId} />}
    />
    <Route
      path='resident-document-template/:component/:residentId/:storageId'
      element={
        <RenderResidentDocumentTemplates
          instanceId={props.appStatus.instanceId}
          userId={props.appStatus.userId}
        />
      }
    />
  </Routes>
)

const RenderOrderSheet: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<{ orderId: string }>()
  const [order, setOrder] = React.useState<IFullOrder | null>(null)
  React.useEffect(() => {
    axios
      .get(`/api/${props.instanceId}/inventory/orders/${params.orderId}`)
      .then((response) => {
        setOrder(response.data)
      })
    // eslint-disable-next-line
  }, [])

  if (!order) {
    return null
  }
  return <OrderSheet instanceId={props.instanceId} order={order} readOnly />
}

const RenderResidentIdCards: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<{ type: string; cardIds: string }>()

  return (
    <ResidentIdCards
      instanceId={props.instanceId}
      cardIds={params.cardIds!}
      type={params.type!}
    />
  )
}

const RenderArbeitsplan: React.FC<{ instanceId: string }> = (props) => {
  const params = { ...useParams<any>() }
  if (params.arbeitsplanDataId === 'none') {
    delete params.arbeitsplanDataId
  }
  const validated = ArbeitsplanFilterValidator.safeParse(params)
  if (!validated.success) {
    return <div>url parameters did not pass validation.</div>
  }
  return (
    <Arbeitsplan
      instanceId={props.instanceId}
      month={validated.data.month}
      userId={validated.data.userId}
      arbeitsplanDataId={validated.data.arbeitsplanDataId}
    />
  )
}

const RenderStundenzettel: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<any>()
  const validated = StundenzettelFilterValidator.safeParse({
    month: params.month,
    employeeIds: params.employeeIds?.split(',') ?? [],
  })
  if (!validated.success || !validated.data.employeeIds) {
    return <div>url parameters did not pass validation.</div>
  }
  return (
    <Stundenzettel
      instanceId={props.instanceId}
      month={validated.data.month}
      employeeIds={validated.data.employeeIds}
    />
  )
}

const RenderResidentCardsBatch: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<{ type: string; cardId: string }>()
  const [storageData, setStorageData] = React.useState<ICardStorageData | null>(null)
  React.useEffect(() => {
    axios
      .get<{ id: string; value: ICardStorageData }>(
        `/api/${props.instanceId}/storage/${params.cardId}`,
      )
      .then((response) => setStorageData(response.data.value))
    // eslint-disable-next-line
  }, [])
  if (!storageData || !params.type) {
    return null
  }

  return (
    <ResidentCards
      storageData={storageData}
      type={params.type}
      instanceId={props.instanceId}
    />
  )
}

const RenderEmployeeIdCard: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<{ cardId: string }>()
  const [storageData, setStorageData] = React.useState<{
    userCards: IUserCard[]
    template: ICompanyIdCardTemplate | null
  } | null>(null)
  React.useEffect(() => {
    axios
      .get<{
        id: string
        value: { userCards: IUserCard[]; template: ICompanyIdCardTemplate | null }
      }>(`/api/${props.instanceId}/storage/${params.cardId}`)
      .then((response) => setStorageData(response.data.value))
    // eslint-disable-next-line
  }, [])
  if (!storageData) {
    return null
  }

  return <EmployeeIdCard storageData={storageData} instanceId={props.instanceId} />
}

const RenderDokuExport: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<{
    residentId: string
    family: 'yes' | 'no'
    compoundId: string
    storageId: string
  }>()
  const [data, setData] = React.useState<IDokuExportData | null>(null)
  React.useEffect(() => {
    if (params.storageId === 'none') {
      axios
        .get<IDokuExportData>(
          `/api/${
            props.instanceId
          }/residents/${params.residentId!}/documentationExport?family=${params.family!}&compoundId=${params.compoundId!}`,
        )
        .then((response) => setData(response.data))
    } else {
      axios
        .get<{ value: IDokuExportData }>(
          `/api/${props.instanceId}/storage/${params.storageId!}`,
        )
        .then((response) => setData(response.data.value))
    }

    // eslint-disable-next-line
  }, [])
  if (!data) {
    return null
  }

  return (
    <DokuExport
      residents={data.metadata.residents}
      compound={data.metadata.compound}
      documentations={data.resources}
      instanceId={props.instanceId}
      attachments={data.metadata.attachments}
      readOnly
    />
  )
}

const RenderResidentProfileExport: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<{ residentId: string; familyId: string | 'none' }>()
  const [family, setFamily] = React.useState<
    (IResidentSearchResult & { changes: ILogChange[] })[] | null
  >(null)
  React.useEffect(() => {
    axios
      .get<{ resources: (IResidentSearchResult & { changes: ILogChange[] })[] }>(
        `/api/${props.instanceId}/pdf/data?type=resident-profile-export&scope=${
          params.familyId === 'none' ? 'resident' : 'family'
        }&residentId=${params.residentId}&familyId=${
          params.familyId === 'none' ? '0' : params.familyId
        }`,
      )
      .then((response) => setFamily(response.data.resources))
    // eslint-disable-next-line
  }, [])
  if (!family) {
    return null
  }

  return <ResidentProfileExport family={family} readOnly />
}

const RenderResidentDocumentTemplates: React.FC<{
  instanceId: string
  userId: string | null
}> = (props) => {
  const params = useParams<{ component: string; residentId: string; storageId: string }>()
  if (!props.userId) {
    return null
  }
  return (
    <ResidentDocumentTemplates
      instanceId={props.instanceId}
      userId={props.userId}
      residentId={params.residentId!}
      component={params.component!}
      storageId={params.storageId!}
    />
  )
}

const RenderCostCoverageSignature: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<{ residentId: string; signatureId: string }>()
  const [signatures, setSignatures] = React.useState<ICostCoverageSignature[] | null>(
    null,
  )
  React.useEffect(() => {
    if (params.residentId === 'batch') {
      axios
        .get<{ id: string; value: ICostCoverageSignature[] }>(
          `/api/${props.instanceId}/storage/${params.signatureId}`,
        )
        .then((response) => setSignatures(response.data.value))
    } else {
      axios
        .get(
          `/api/${props.instanceId}/residents/${params.residentId}/costCoverageSignatures/${params.signatureId}`,
        )
        .then((response) => setSignatures([response.data]))
    }
    // eslint-disable-next-line
  }, [])

  if (!signatures) {
    return null
  }
  return <CostCoverageSignatures signatures={signatures} instanceId={props.instanceId} />
}

const CostCoverageSignatures: React.FC<{
  signatures: ICostCoverageSignature[]
  instanceId: string
}> = ({ signatures, instanceId }) => {
  React.useEffect(setReadyFlagWhenRenderingComplete, [])

  return (
    <>
      {signatures.map((s) => (
        <CostCoverageSignature
          key={s.id}
          signature={s}
          instanceId={instanceId}
          readOnly
        />
      ))}
    </>
  )
}

const RenderCostCoverageInvoice: React.FC<{ instanceId: string }> = (props) => {
  const params = useParams<{ invoiceId: string }>()
  const [invoice, setInvoice] = React.useState<ICostCoverageInvoice | null>(null)
  React.useEffect(() => {
    axios
      .get(`/api/${props.instanceId}/costCoverageInvoices/${params.invoiceId}`)
      .then((response) => setInvoice(response.data))
    // eslint-disable-next-line
  }, [])

  if (!invoice) {
    return null
  }
  return (
    <CostCoverageInvoice invoice={invoice} instanceId={props.instanceId} readOnly>
      {!isIntegerString(invoice.id) && (
        <CornerBadge className='bg-blue-500' label='Entwurf' />
      )}
    </CostCoverageInvoice>
  )
}

const RenderEinzelrechnungWohnungslose: React.FC<{ instanceId: string }> = () => {
  const [params] = useSearchParams()
  const invoice = JSON.parse(params.get('invoice') || 'null')
  return <EinzelrechnungWohnungslose invoice={invoice} />
}
