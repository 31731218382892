export function getResidentSalutationDe(sex: string | null | undefined) {
  if (sex === 'male') {
    return 'Herr'
  } else if (sex === 'female') {
    return 'Frau'
  } else if (sex === 'divers') {
    return 'Divers'
  } else {
    return ''
  }
}
