export const MONTHS = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]
export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'März',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Okt',
  'Nov',
  'Dez',
]
