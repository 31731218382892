import { hermes } from '@byll/hermes'
import { Menu, Transition } from '@headlessui/react'
import { Tooltip } from 'components/Tooltip'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { LastSeenValidator } from 'modules/Residents/validators/LastSeenValidator'
import { z } from 'zod'
import { storage } from 'services/storage'
import { SearchHistoryEntry } from './SearchHistoryEntry'

interface Props {}

@observer
export class SearchHistory extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private residents:
    | (IResidentSearchResult & { lastSeen: string })[]
    | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    const validated = LastSeenValidator.safeParse(
      storage.get(`lastSeen.${this.context.user.id}`),
    )
    const lastSeen = validated.success ? validated.data : []
    this.loadResidents(lastSeen)
  }

  private loadResidents = async (lastSeen: z.infer<typeof LastSeenValidator>) => {
    const residentIds = lastSeen.map((resident) => resident.id).join(',')
    const residents = await hermes.indexOnceNew<IResidentSearchResult>(
      `/api/${this.context.instance.id}/residentSearchResults?residentIds=${residentIds}`,
    )
    const resultMap = new Map<string, IResidentSearchResult>()
    for (const resident of residents) {
      resultMap.set(resident.id, resident)
    }
    runInAction(() => {
      this.residents = lastSeen
        .map((ls) =>
          resultMap.has(ls.id)
            ? { ...resultMap.get(ls.id), lastSeen: ls.seenAt }
            : (null as any),
        )
        .filter(Boolean) // Removes null entries
    })
  }

  render() {
    return (
      <Menu as='div' className='relative'>
        <div>
          <Menu.Button
            style={{ width: 38, padding: '9px 0' }}
            className='border-r has-tooltip border-transparent hover:border-indigo-700 text-white bg-gradient-to-r from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500 py-[9px] px-[17px] text-center shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2'
          >
            <i className='fas fa-history' />
            <Tooltip position='bottom'>Zuletzt angesehen</Tooltip>
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className='origin-top-left absolute right-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-[300px] overflow-auto'
            style={{ maxHeight: '50vh' }}
          >
            {this.residents &&
              this.residents.length > 0 &&
              this.residents.map((resident) => (
                <SearchHistoryEntry key={resident.id} resident={resident} />
              ))}
            {this.residents && this.residents.length === 0 && (
              <div className='text-gray-600'>Keine zuletzt gesehenen Bewohner.</div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
