import * as React from 'react'
import { observer } from 'mobx-react'
import { LeaInactiveListItem } from './LeaInactiveListItem'
import { Spinner } from 'components/Spinner'
import { Model } from 'components/Form/Model'
import { IInactiveListFilterModel } from 'contracts/inactiveList/interfaces/IInactiveListFilterModel'
import { IInactiveResident } from 'contracts/inactiveList/interfaces/IInactiveResident'
import { Collection, Resource } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { action, makeObservable, observable } from 'mobx'
import { LeaInactiveListSearchCaption } from './LeaInactiveListSearchCaption'

interface Props {
  model: Model<IInactiveListFilterModel>
}

@observer
export class LeaInactiveListView extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly residents: Collection<IInactiveResident>
  private disposers: Disposer[] = []
  @observable nameIcon: string = 'fas fa-caret-up'
  @observable scanIcon: string = 'fas fa-sort'

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.residents = new Collection(
      `/api/${context.instance.id}/inactiveListResidents`,
      props.model.values,
    )
  }

  @action
  onSort = (sortBy: string) => {
    if (sortBy === 'Name' && this.residents) {
      if (this.props.model.values.sort === 'residents."lastName" ASC') {
        this.props.model.values.sort = 'residents."lastName" DESC'
        this.nameIcon = 'fas fa-caret-down'
        this.scanIcon = 'fas fa-sort'
      } else {
        this.props.model.values.sort = 'residents."lastName" ASC'
        this.nameIcon = 'fas fa-caret-up'
        this.scanIcon = 'fas fa-sort'
      }
    }
    if (sortBy === 'Zuletzt gescannt' && this.residents) {
      if (this.props.model.values.sort === '"lastScannedAt" ASC NULLS FIRST') {
        this.props.model.values.sort = '"lastScannedAt" DESC NULLS LAST'
        this.scanIcon = 'fas fa-caret-down'
        this.nameIcon = 'fas fa-sort'
      } else {
        this.props.model.values.sort = '"lastScannedAt" ASC NULLS FIRST'
        this.scanIcon = 'fas fa-caret-up'
        this.nameIcon = 'fas fa-sort'
      }
    }
  }

  componentDidMount(): void {
    this.disposers.push(this.residents.init({ observeQuery: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  mapResident = (resident: Resource<IInactiveResident>) =>
    resident.data && (
      <LeaInactiveListItem
        key={resident.id}
        model={this.props.model}
        resident={resident.data}
      />
    )

  render() {
    return (
      <>
        <div className='m-6 flex flex-col'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-2'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                      >
                        JBP ID
                      </th>
                      <th
                        scope='col'
                        className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                      >
                        MigVIS ID
                      </th>
                      <th
                        scope='col'
                        className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                      >
                        <LeaInactiveListSearchCaption
                          label='Name'
                          icon={this.nameIcon}
                          onSort={this.onSort}
                          key={this.nameIcon}
                        />
                      </th>
                      <th
                        scope='col'
                        className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                      >
                        <LeaInactiveListSearchCaption
                          label='Zuletzt gescannt'
                          icon={this.scanIcon}
                          onSort={this.onSort}
                          key={this.scanIcon}
                        />
                      </th>
                      <th
                        scope='col'
                        className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                      >
                        Aufenthalt
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {this.residents && this.residents.resources?.map(this.mapResident)}
                    {this.residents && this.residents.resources?.length === 0 && (
                      <tr>
                        <td colSpan={7}>
                          <div className='text-center p-20 text-gray-500'>
                            <i className='fa fa-filter' style={{ fontSize: 100 }} />
                            <br />
                            <span className='text-2xl'>
                              Keine Personen mit den aktuellen
                              <br />
                              Filtereinstellungen gefunden
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {!this.residents && (
          <div className='flex-content mt-auto mb-auto tc'>
            <Spinner />
          </div>
        )}
      </>
    )
  }
}
