import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { makeObservable, observable, reaction, runInAction, toJS, when } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IResidentDocumentationSearchResult } from 'contracts/residents/interfaces/IResidentDocumentationSearchResult'
import { DocumentationCreateValidator } from 'contracts/residents/validators/DocumentationCreateValidator'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Collection, hermes, Resource } from '@byll/hermes'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { Message } from 'components/Message'
import { IRole } from 'contracts/users/interfaces/IRole'
import { DocumentationDialogTags } from './DocumentationDialogTags'
import { DocumentationDialogText } from './DocumentationDialogText'
import { DocumentationDialogAttachments } from './DocumentationDialogAttachments'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { getEmptyTodoSearchResult } from 'contracts/todos/helpers/getEmptyTodoSearchResult'
import { DocumentationDialogTodo } from './DocumentationDialogTodo'
import { z } from 'zod'
import { createTodo } from '../helpers/createTodo'
import { updateTodo } from '../helpers/updateTodo'
import { deleteTodo } from '../helpers/deleteTodo'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { isStammCompound } from 'helpers/isStamm'

interface Props {
  resident: IResident
  documentation: IResidentDocumentationSearchResult
  onClose: () => void
  roles: Collection<IRole>
}

const TodoValidator = z.object({
  label: z.string().min(1).max(255),
})

@observer
export class DocumentationDialogContent extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disposers: Disposer[] = []
  private readonly documents: Collection<IDocumentMetadata>
  private readonly model: Model<
    IResidentDocumentationSearchResult & { author: string; compoundId: string | null }
  >
  @observable private loading = false
  @observable private hasUnsavedChanges = false
  @observable private error: string | null = null
  @observable.ref private todo: Model<
    ITodoSearchResult & {
      isDone: boolean
      assigneeIds: string[]
      watcherIds: string[]
      hasTodo: boolean
    }
  >

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.documents = new Collection(`/api/${context.instance.id}/documents/metadata`, {
      scope: 'resident documentation',
      documentationId: props.documentation.id || 'new',
    })
    this.todo = new Model(
      {
        ...getEmptyTodoSearchResult(),
        isDone: false,
        assigneeIds: [],
        watcherIds: [],
        hasTodo: false,
      },
      TodoValidator,
    )
    this.model = new Model(
      {
        ...toJS(props.documentation),
        compoundId: props.documentation.compound.id,
        author: `${props.documentation.createdBy.firstName} ${props.documentation.createdBy.lastName}`,
      },
      DocumentationCreateValidator.omit({ roleIds: true }),
    )
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => toJS(this.model.values),
        () => (this.hasUnsavedChanges = true),
      ),
    )

    this.disposers.push(
      reaction(
        () => this.props.documentation.todoId,
        (todoId) => {
          if (!todoId) {
            // If todo gets deleted by another user => Live clear todo in open dialog
            this.todo.values.hasTodo = false
          } else {
            // If todo gets added by another user => load todo
            void this.loadTodo(todoId)
          }
        },
        { fireImmediately: true },
      ),
    )

    if (this.props.documentation.id) {
      this.disposers.push(this.documents.init({ readOnly: true }))
    } else {
      runInAction(() => {
        this.documents.error = null
        this.documents.resources = []
      })
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadTodo = async (todoId: string) => {
    try {
      const resource = new Resource<ITodoSearchResult>(
        `/api/${this.context.instance.id}/todoLists/${this.context.defaults.dokuTodoListId}/todos/${todoId}`,
      )
      this.disposers.push(resource.init({ readOnly: true }))
      await when(() => !!resource.data)
      const todo = resource.data!
      runInAction(() => {
        this.todo = new Model({
          ...todo,
          isDone: !!todo.doneDate,
          assigneeIds: todo.assignees.map((a) => `${a.entity}-${a.id}`),
          watcherIds: todo.watchers.map((w) => `${w.entity}-${w.id}`),
          hasTodo: true,
        })
      })
      this.disposers.push(
        reaction(
          () => todo.doneDate,
          (doneDate) => {
            if (this.todo.values.id !== todoId || !this.todo.values.hasTodo) {
              return
            }
            this.todo.values.doneDate = doneDate
            this.todo.values.isDone = !!doneDate
          },
        ),
      )
    } catch (e) {
      runInAction(() => (this.error = 'Todo konnte nicht geladen werden'))
    }
  }

  private onSave = async () => {
    if (!this.todo) {
      return
    }
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (this.todo.values.hasTodo && !this.todo.isValid()) {
      this.todo.setFocusToLeftTopmostInvalidField()
      return
    }
    const doku: any = {
      compoundId: this.model.values.compoundId,
      date: this.model.values.date,
      time: this.model.values.time,
      type: this.model.values.type,
      topics: this.model.values.topics,
      notes: this.model.values.notes,
      residentIds: this.model.values.residents.filter((r) => r.checked).map((r) => r.id),
      roleIds: this.model.values.roleIds,
      todoId: null,
    }
    const hasAllPermissions = this.context.permissions.resident_documentation_anyRole > 0
    const hasUserRole = this.props.roles.resources
      ? this.props.roles.resources
          ?.map((r) => doku.roleIds.includes(r.id))
          .filter(Boolean).length > 0
      : false
    /*if (hasAllPermissions && doku.roleIds.length === 0) {
      runInAction(
        () =>
          (this.error =
            'Bitte fügen Sie mindestens eine Funktionsgruppe hinzu, die auf diesen Eintrag zugreifen darf.'),
      )
      return
    }*/
    if (!hasAllPermissions && !hasUserRole) {
      runInAction(
        () =>
          (this.error =
            'Bitte fügen Sie Ihre Funktionsgruppe hinzu, damit Sie nach dem Speichern weiterhin auf diesen Eintrag zugreifen können.'),
      )
      return
    }
    if (doku.residentIds.length === 0) {
      runInAction(
        () =>
          (this.error =
            'Bitte wählen Sie in der Fußzeile mindestens einen betroffenen Bewohner aus.'),
      )
      return
    }
    try {
      runInAction(() => {
        this.todo.values.watcherIds = doku.roleIds.map((r) => `role-${r}`)
        this.loading = true
      })
      if (!this.props.documentation.id) {
        doku.documentIds = this.documents.resources?.map((r) => r.id) ?? []
        if (this.todo.values.hasTodo) {
          doku.todoId = await createTodo(
            { ...this.todo.values, listId: this.context.defaults.dokuTodoListId },
            this.model.values.compoundId!,
            this.context.instance.id,
          )
        }
        const response = await hermes.create(
          `/api/${this.context.instance.id}/residents/${this.props.resident.id}/documentationSearchResults`,
          doku,
        )
        if (doku.todoId) {
          const url = `/residents/${toJbpId(
            +this.props.resident.id,
          ).toLowerCase()}/documentation/${response.id}`
          await hermes.patch(
            `/api/${this.context.instance.id}/todoLists/${this.context.defaults.dokuTodoListId}/todos/${doku.todoId}`,
            { url },
          )
        }
      } else {
        const todoId = this.props.documentation.todoId
        const hasTodo = this.todo.values.hasTodo
        if (hasTodo && !todoId) {
          // Create todo
          runInAction(
            () =>
              (this.todo!.values.url = `/residents/${toJbpId(
                +this.props.resident.id,
              ).toLowerCase()}/documentation/${this.props.documentation.id}`),
          )
          doku.todoId = await createTodo(
            { ...this.todo.values, listId: this.context.defaults.dokuTodoListId },
            this.model.values.compoundId!,
            this.context.instance.id,
          )
        } else if (hasTodo && todoId) {
          // Update todo
          this.todo.values.id = todoId
          doku.todoId = todoId
          await updateTodo(
            { ...this.todo.values, listId: this.context.defaults.dokuTodoListId },
            this.model.values.compoundId!,
            this.context.instance.id,
          )
        }
        await hermes.update(
          `/api/${this.context.instance.id}/residents/${this.props.resident.id}/documentationSearchResults/${this.props.documentation.id}`,
          doku,
        )
        if (!hasTodo && todoId) {
          try {
            // Delete todo
            await deleteTodo(
              { id: todoId, listId: this.context.defaults.dokuTodoListId },
              this.context.instance.id,
            )
          } catch (_e) {
            /* Ignore: Could have been deleted before */
          }
        }
      }
      runInAction(() => (this.hasUnsavedChanges = false))
      setTimeout(() => this.props.onClose(), 100)
    } catch (e: any) {
      runInAction(() => {
        this.loading = false
        this.error = this.props.documentation.id
          ? 'Die Dokumentation konnte nicht gespeichert werden. Bitte wenden Sie sich an einen Administrator.'
          : 'Die Dokumentation konnte nicht erstellt werden. Bitte wenden Sie sich an einen Administrator.'
      })
    }
  }

  render() {
    const isNew = !this.model.values.id
    const readOnly =
      !isNew &&
      this.context.permissions.resident_documentation === 1 &&
      !isStammCompound(this.model.values.compoundId || '')

    return (
      <div id={this.model.id}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {isNew ? 'Tätigkeit dokumentieren' : 'Dokumentation'}
              </Dialog.Title>
              <div className='truncate text-gray-400 text-sm mt-1'>
                <i className='far fa-user' /> {`Erstellt von ${this.model.values.author}`}
              </div>
            </div>
          </div>
        </div>

        <div className='bg-gray-100 p-6'>
          {this.error && (
            <Message
              color='danger'
              className='mb-6 border border-red-500 sticky top-0 z-10'
            >
              {this.error}
            </Message>
          )}

          {readOnly && (
            <Message
              color='primary'
              className='mb-6 border border-indigo-500 sticky top-0 z-10'
            >
              Diese Dokumentation ist schreibgeschützt, weil sie außerhalb Ihrer
              Zuständigkeit erstellt wurde.
            </Message>
          )}

          <DocumentationDialogTags model={this.model} readOnly={readOnly} />
          <DocumentationDialogText model={this.model} readOnly={readOnly} />
          {this.todo && !readOnly && <DocumentationDialogTodo todo={this.todo} />}
          <DocumentationDialogAttachments
            readOnly={readOnly}
            model={this.model}
            documents={this.documents}
          />
        </div>

        <div
          className='py-4 px-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button color='secondary' outline onClick={this.props.onClose}>
            {isNew ? 'Abbrechen' : 'Schließen'}
          </Button>
          {!readOnly && (
            <Button
              color='primary'
              className='ml-2'
              disabled={this.loading || !this.todo}
              onClick={this.onSave}
            >
              Speichern
            </Button>
          )}
        </div>
        {this.hasUnsavedChanges && <PreventRouteChange />}
        {this.loading && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}
