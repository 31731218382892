import * as React from 'react'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from '../../Model'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'
import Select from 'react-select'

export interface InputDropdownOption {
  value: string // JSON.stringified version of model value!
  label: string
}

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  options: InputDropdownOption[] // options that can be chosen by this input
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  children?: Element
  setRef?: (HTMLInputElement) => void
}

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    minHeight: '36px',
    backgroundColor: state.isSelected ? '#6366f1' : state.isFocused ? '#e0e7ff' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: state.isSelected ? undefined : '#e0e7ff',
    },
  }),
  control: (styles, options) => ({
    ...styles,
    'input:focus': {
      boxShadow: 'none',
    },
    borderRadius: '0.375rem',
    cursor: 'text',
    boxShadow: options.isFocused
      ? '0 0 0 1px rgb(99, 102, 241)'
      : '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontSize: '14px',
    border: options.isFocused
      ? '1px solid rgb(99, 102, 241) !important'
      : '1px solid rgb(212, 212, 212) !important',
    color: '#000000',
  }),
}

function noOptionsMessage() {
  return 'Keine Ergebnisse'
}

@observer
export class InputDropdown extends React.Component<Props, {}> {
  private readonly id: string
  private valueIndex: number = 0

  private get value(): InputDropdownOption {
    const value = JSON.stringify(this.props.model.values[this.props.name])
    // Check valueIndex (cached)
    if (this.props.options[this.valueIndex]?.value === value) {
      return this.props.options[this.valueIndex]
    }
    for (let i = 0; i < this.props.options.length; i++) {
      if (this.props.options[i].value === value) {
        this.valueIndex = i // Cache, so that it can be directly retrieved the next time, .value is executed.
        return this.props.options[i]
      }
    }
    return { value: '""', label: '' }
  }

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('input-dropdown-')
    makeObservable(this)
  }

  @action
  private onChange = (option: InputDropdownOption | null) => {
    this.props.model.values[this.props.name] = option ? JSON.parse(option.value) : null
  }

  render() {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const {
      name,
      model,
      options,
      label,
      className,
      setRef,
      children,
      placeholder,
      disabled,
    } = this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (disabled) {
      innerClassName += ' bg-gray-100'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <Select
          styles={customStyles}
          className={innerClassName}
          name={name}
          ref={setRef}
          onChange={this.onChange}
          placeholder={placeholder || ''}
          value={this.value}
          id={this.id}
          options={options}
          noOptionsMessage={noOptionsMessage}
          isDisabled={disabled}
        />
        {children}
      </div>
    )
  }
}
