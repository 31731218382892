import { Collection, hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Age } from 'components/Age'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { Spinner } from 'components/Spinner'
import { Tooltip } from 'components/Tooltip'
import { getRoomLockAt } from 'contracts/accommodations/helpers/getRoomLockAt'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { flags } from 'helpers/countries/flags'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { RoomLockDropdown } from 'modules/ResidentsOccupancy/components/RoomLockDropdown'
import { getOccupancyColor } from 'modules/ResidentsOccupancy/helpers/getOccupancyColor'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IInternalResidenceFormModel } from '../interfaces/IInternalResidenceFormModel'
import { isMucInstance } from 'contracts/general/helpers/instanceIds'

interface Props {
  buildingId: string
  model: Model<IInternalResidenceFormModel>
}

@observer
export class InternalResidenceFormRoomList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly rooms: Collection<
    IRoom,
    {},
    { buildingId: string; occupancyAt: string }
  >
  private readonly disposers: Disposer[] = []
  @observable.ref private occupancies: Map<string, IOccupancy> | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.rooms = new Collection(`/api/${context.instance.id}/accommodations/rooms`, {
      buildingId: props.buildingId,
      occupancyAt: new Date().toISOString(),
    })
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.rooms.init({ readOnly: true }))
    void this.getOccupancies()
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private getOccupancies = async () => {
    const beginAt = dayjs(
      `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
    )
    const endAt = this.props.model.values.hasEnd
      ? dayjs(`${this.props.model.values.endDate} ${this.props.model.values.endTime}`)
      : dayjs('2100-12-31')
    const data = await hermes.indexOnceNew<IOccupancy>(
      `/api/${this.context.instance.id}/accommodations/occupancies?${
        this.props.buildingId ? `buildingId=${this.props.buildingId}&` : ''
      }queryAt=${encodeURIComponent(
        beginAt.toISOString(),
      )}&queryTill=${encodeURIComponent(endAt.toISOString())}`,
    )
    const occupancies = new Map<string, IOccupancy>()
    for (const o of data) {
      occupancies.set(o.id, o)
    }
    runInAction(() => (this.occupancies = occupancies))
  }

  private roomMapper = (res: Resource<IRoom>) => {
    const room = res.data
    const occupancy = this.occupancies?.get(res.id)
    if (!room || !occupancy || room.capacity === '0') {
      return null
    }
    const active = this.props.model.values.roomId === res.id
    const lock = getRoomLockAt(room.locks)

    return (
      <div
        key={room.id}
        style={{ minHeight: 146 }}
        onClick={() =>
          lock
            ? alert('Dieser Raum ist gesperrt und kann daher nicht ausgewählt werden.')
            : this.onSelect(res.data!)
        }
        className={`${
          active ? 'border-2 border-blue-500' : ''
        } shadow bg-white rounded-md p-3 min-w-0 ${
          lock ? 'cursor-not-allowed' : 'cursor-pointer'
        } text-sm relative`}
      >
        <div
          className='text-base -mx-3 -mt-3 mb-2 bg-gray-800 px-3 py-2 text-white flex min-w-0'
          style={{ borderRadius: '4px 4px 0 0' }}
        >
          <div className='flex-[0_1_auto] mr-2 min-w-[20px] truncate'>
            {room.label}
            {isMucInstance(this.context.instance.id) && room.typeOfUse && (
              <span className='ml-1 px-2.5 py-[3px] rounded-full text-xs font-medium text-white bg-gray-500'>
                {room.typeOfUse}
              </span>
            )}
          </div>
          <div className={lock ? 'flex-content' : 'flex-auto'}>
            <RoomLockDropdown
              room={room}
              lock={lock}
              onChange={() => this.onSelect(null)}
            />
          </div>
          {lock && (
            <div className='text-red-500 truncate flex-content ml-2 min-w-0'>
              {lock.reason
                .split(' ')
                .map((w) => w.substring(0, 1))
                .join('')
                .toUpperCase()}
            </div>
          )}
          <div className='flex-content'>
            <span
              className={`${getOccupancyColor(
                +occupancy.occupied,
                +room.capacity,
                lock !== null,
              )} ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium  text-white`}
            >{`${occupancy.occupied} / ${room.capacity}`}</span>
          </div>
        </div>
        {occupancy.beds.map((b, i) => {
          if (!active && i > 3) {
            return null
          }
          return (
            <div key={b.id} className='truncate'>
              {b.bed && (
                <span
                  className={`mr-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    b.bed > +room.capacity || b.lock !== null
                      ? 'bg-red-500'
                      : 'bg-gray-500'
                  } text-white`}
                >
                  {b.lock !== null && (
                    <span className='mr-1'>
                      <i className='fas fa-lock' />
                    </span>
                  )}
                  {b.bed}
                </span>
              )}
              {flags[b.resident?.flag || ''] && (
                <img
                  alt={b.resident!.nationality}
                  src={flags[b.resident?.flag || ''] || ''}
                  className='w-5 h-5 mr-1 inline-flex rounded-full'
                />
              )}
              {b.resident?.isBaby && (
                <span className='mr-1 text-gray-500'>
                  <i className='fas fa-baby-carriage' />
                </span>
              )}
              {b.type === 'internal-reservation' && (
                <span className='mr-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border border-indigo-500 text-indigo-500'>
                  Reserviert
                </span>
              )}
              {b.type === 'internal-reservation' && !b.resident && (
                <span className='text-gray-400'>·&nbsp;&nbsp;{b.comment}</span>
              )}
              {b.resident && (
                <span>
                  <Age dateOfBirth={b.resident.dateOfBirth} sex={b.resident.sex as any} />
                  <Link
                    className='text-gray-900 mx-2 underline hover:text-blue-500'
                    to={`/residents/${toJbpId(
                      +b.resident.id,
                    ).toLowerCase()}/accommodation`}
                    target='_blank'
                  >
                    {b.resident.name}
                  </Link>
                </span>
              )}
              {b.type === 'empty-bed' && b.lock && (
                <span className='text-gray-400'>
                  {b.lock.reason}
                  {b.lock.notes && <>&nbsp;&nbsp;·&nbsp;&nbsp;{b.lock.notes}</>}
                </span>
              )}
              {b.type === 'empty-bed' && !b.lock && (
                <span className='text-gray-400'>Leeres Bett</span>
              )}
            </div>
          )
        })}
        {!active && occupancy.beds.length > 4 && (
          <div
            className='has-tooltip absolute bottom-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800 translate-y-2/4 -translate-x-2/4 left-1/2'
            style={{ position: 'absolute' }}
          >
            <i className='fas fa-ellipsis-h' />
            {!lock && <Tooltip>Weitere Betten anzeigen</Tooltip>}
          </div>
        )}
      </div>
    )
  }

  @action
  private onSelect = (room: IRoom | null) => {
    if (!room) {
      this.props.model.values.roomId = null
      this.props.model.values.occupancy = null
      return
    }

    if (!this.occupancies?.get(room.id)) {
      return
    }
    if (this.props.model.values.roomId === room.id) {
      this.props.model.values.roomId = null
      this.props.model.values.occupancy = null
    } else {
      this.props.model.values.roomId = room.id
      this.props.model.values.occupancy = this.occupancies.get(room.id)!
      const building = hermes.getFromStore<IBuilding>(
        `/api/${this.context.instance.id}/accommodations/buildings/${room.buildingId}`,
        false,
      )
      const compound = building
        ? hermes.getFromStore<ICompound>(
            `/api/${this.context.instance.id}/accommodations/compounds/${building.compoundId}`,
            false,
          )
        : null
      if (building && compound) {
        this.props.model.values.label = `${
          building.label === compound.label
            ? building.label
            : `${compound.label} / ${building.label}`
        } / ${room.label}`
      } else {
        this.props.model.values.label = room.label
      }
    }
  }

  render() {
    if (!this.rooms.resources || !this.occupancies) {
      return (
        <div className='relative mt-4 h-20'>
          <Spinner delay />
        </div>
      )
    }

    if (this.rooms.resources.length === 0) {
      return (
        <Message color='primary' className='mt-4'>
          Dieses Gebäude enthält keine Schlafräume
        </Message>
      )
    }

    return (
      <div className='relative grid grid-cols-2 gap-4 my-4'>
        {this.rooms.resources.map(this.roomMapper)}
      </div>
    )
  }
}
