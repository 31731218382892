import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { InputDecimal } from 'components/Form/components/InputDecimal'

const statusDict: any = {
  full: 'Voll',
  'not full': 'Teilbelegt',
  empty: 'Leer',
  overcrowded: 'Überbelegt',
}

interface IModel {
  occupied: 'full' | 'not full' | 'empty' | 'overcrowded' | null
  capacity: string | null
}

interface Props {
  model: Model<IModel>
  onlyBeds?: boolean
}

@observer
export class RoomOccupiedFilter extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setFull = () => (this.props.model.values.occupied = 'full')
  @action private setNotFull = () => (this.props.model.values.occupied = 'not full')
  @action private setEmpty = () => (this.props.model.values.occupied = 'empty')
  @action private setOvercrowded = () =>
    (this.props.model.values.occupied = 'overcrowded')

  @action private reset = () => {
    this.props.model.values.capacity = null
    this.props.model.values.occupied = null
  }

  render() {
    const occupied = this.props.model.values.occupied || ''
    let label = 'Betten'
    let color: string = 'text-gray-500'
    if (occupied) {
      label = statusDict[occupied]
      color = 'text-blue-500'
    }
    if (this.props.model.values.capacity) {
      const bedText = `${+this.props.model.values.capacity} ${
        +this.props.model.values.capacity === 1 ? 'Bett' : 'Betten'
      }`
      label = occupied ? `${label}, ${bedText}` : bedText
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='grid grid-cols-2 gap-4'>
              {this.props.onlyBeds !== true && (
                <>
                  <Button
                    onClick={this.setFull}
                    color={occupied === 'full' ? 'primary' : 'secondary'}
                    outline={occupied !== 'full'}
                  >
                    {statusDict.full}
                  </Button>
                  <Button
                    onClick={this.setNotFull}
                    color={occupied === 'not full' ? 'primary' : 'secondary'}
                    outline={occupied !== 'not full'}
                  >
                    {statusDict['not full']}
                  </Button>
                  <Button
                    onClick={this.setEmpty}
                    color={occupied === 'empty' ? 'primary' : 'secondary'}
                    outline={occupied !== 'empty'}
                  >
                    {statusDict.empty}
                  </Button>
                  <Button
                    onClick={this.setOvercrowded}
                    color={occupied === 'overcrowded' ? 'primary' : 'secondary'}
                    outline={occupied !== 'overcrowded'}
                  >
                    {statusDict.overcrowded}
                  </Button>
                </>
              )}
              <InputDecimal
                className='col-span-2'
                model={this.props.model}
                name='capacity'
                label='Bettenzahl'
                precision={3}
                scale={0}
                notNegative
              />

              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
