import * as React from 'react'
import { FormColor } from '../../interfaces/FormColor'
import { getButtonStyle } from './helpers/getButtonStyle'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: FormColor | 'pink' | 'blue' | 'purple'
  outline?: boolean
  block?: boolean
  className?: string
  loading?: boolean
  small?: boolean
}

export const Button: React.FC<Props> = (props) => {
  const { color, outline, block, className, loading, disabled, small, ...attributes } =
    props
  return (
    <button
      {...attributes}
      className={getButtonStyle(color, props)}
      disabled={loading || disabled}
    >
      {loading && (
        <span className='mr-1'>
          <i className='fas fa-spinner fa-spin' />
        </span>
      )}
      {props.children}
    </button>
  )
}
