import * as React from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import { isRetina } from '../../helpers/isRetina'
import { PdfPageError } from './components/PdfPageError'
import { PdfDocumentError } from './components/PdfDocumentError'
import { PdfLoading } from './components/PdfLoading'
import styles from './styles.module.scss'

// Build script ops/build.sh automatically downloads required version and places
// it in public folder, so that it can be included here.
pdfjs.GlobalWorkerOptions.workerSrc = `/pdfjs.${pdfjs.version}/pdf.worker.min.js`

const options = {
  cMapUrl: `/pdfjs.${pdfjs.version}/`,
  cMapPacked: true,
}

interface Props {
  onError?: () => void
  file: Blob
  width?: number
  height?: number
}

interface State {
  numPages: number | null
}

export class Pdf extends React.Component<Props, State> {
  private width = 840
  private scale = 1

  constructor(props: Props) {
    super(props)
    if (props.width) {
      this.width = props.width
    }
    if (isRetina()) {
      this.width /= 2
      this.scale *= 2
    }

    this.state = { numPages: null }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  render() {
    const pages: JSX.Element[] = []
    for (let p = 1; p <= (this.state.numPages ?? 1); p++) {
      pages.push(
        <Page
          key={p}
          className={styles.page}
          pageNumber={p}
          error={<PdfPageError />}
          loading={<PdfLoading />}
          width={this.width}
          scale={this.scale}
        />,
      )
    }

    return (
      <Document
        className={styles.container}
        options={options}
        file={this.props.file}
        onLoadSuccess={this.onDocumentLoadSuccess}
        onLoadError={this.props.onError}
        loading={
          <PdfLoading
            children={this.props.children}
            percentage={null}
            width={this.props.width}
            height={this.props.height}
          />
        }
        error={
          <PdfDocumentError
            children={this.props.children}
            width={this.props.width}
            height={this.props.height}
          />
        }
      >
        {pages}
        {this.props.children}
      </Document>
    )
  }
}
