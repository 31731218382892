import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { classNames } from 'helpers/classNames'
import { InputCheckbox } from '../InputCheckbox'

interface Props {
  model: Model<any>
  checkboxName: string
  textName?: string
  label: string
  placeholder?: string
  className?: string
  children?: JSX.Element
  disabled?: boolean
}

export const InputCheckboxText: React.FC<Props> = observer((props) => {
  let innerClassName =
    'relative flex w-full shadow-sm text-sm border border-gray-300 rounded-md pl-3 focus-within:border-indigo-500 focus-within:border-2'
  const {
    model,
    checkboxName,
    textName,
    label,
    placeholder,
    className,
    children,
    disabled,
  } = props
  const onChange = action((event: React.FormEvent<HTMLInputElement>) => {
    if (!textName || !(event.target instanceof HTMLInputElement) || disabled) {
      return
    }
    const target: HTMLInputElement = event.target
    const value: string = target.value
    model.values[textName] = value
  })

  if (disabled) {
    innerClassName += ' bg-gray-100'
  }

  return (
    <div
      className={classNames(innerClassName, className)}
      style={{ height: 38, lineHeight: '36px' }}
    >
      <InputCheckbox
        className='flex-content'
        model={model}
        name={checkboxName}
        label={label}
        disabled={disabled}
      />

      {textName && (
        <input
          onChange={onChange}
          className='flex-auto border-none focus:ring-0 text-sm text-gray-500 bg-transparent'
          type='text'
          maxLength={255}
          name={textName}
          value={model.values[textName]}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
      {children}
    </div>
  )
})
