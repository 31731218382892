import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { action, makeObservable, observable, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { InputDate } from 'components/Form/components/InputDate'
import { Collection, hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { ICostCoverageSearchResult } from 'contracts/costCoverages/interfaces/ICostCoverageSearchResult'
import { CostCoverageCreateValidator } from 'contracts/costCoverages/validators/CostCoverageCreateValidator'
import { ICostCoverageCreate } from 'contracts/costCoverages/interfaces/ICostCoverageCreate'
import { ICostCoveragePayer } from 'contracts/costCoverages/interfaces/ICostCoveragePayer'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Message } from 'components/Message'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { z } from 'zod'
import { DocumentDropzone } from 'modules/Documents/components/DocumentDropzone'
import { ResidentDropzoneView } from 'modules/Documents/components/ResidentDropzoneView'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { RoundIcon } from 'components/RoundIcon'
import { PrintIcon } from '../../OverviewDocuments/components/PrintIcon'
import { download } from 'helpers/download'
import { PdfPreview } from '../../OverviewDocuments/components/PdfPreview'
import axios, { AxiosProgressEvent } from 'axios'
import { DialogOverlayArea } from 'components/Dialog/components/DialogOverlaySpinner/components/DialogOverlayArea'
import { CircularUploadProgress } from '../../OverviewDocuments/components/CircularUploadProgress'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import dayjs from 'dayjs'
import { ABRECHNUNGSMODUS_LFGB } from 'contracts/costCoverages/interfaces/abrechnungsmodus'

const verpflegungOptions: InputSelectOption[] = [
  { value: 'Vollverpflegung', label: 'Vollverpflegung' },
  { value: 'Selbstverpflegung', label: 'Selbstverpflegung' },
]

const validOptions: InputSelectOption[] = [
  { value: false, label: 'Nein' },
  { value: true, label: 'Ja' },
]

interface Props {
  onClose: () => void
  resident: IResident
  coverage: ICostCoverageSearchResult
  changed: { hasUnsavedChanges: boolean }
  payers: Collection<ICostCoveragePayer>
}

@observer
export class CostCoverageDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<
    ICostCoverageSearchResult & {
      file: Blob | null
      documentId: string | null
      compoundId: string
      recipientId: string | null
      doubleBilling: boolean
    }
  >
  private readonly payers: InputSelectOption[]
  private mounted = true
  private cancelUpload: (() => void) | null = null
  @observable private uploading: { percentage: number } | null = null
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    this.model = new Model(
      {
        ...toJS(props.coverage),
        file: null,
        documentId: props.coverage.document.id,
        compoundId: props.coverage.compound.id,
        recipientId: props.coverage.residents.filter((r) => r.contact)[0]?.id || null,
        doubleBilling: props.coverage.factor === 2,
        isValid: props.coverage.isValid,
      },
      CostCoverageCreateValidator.extend({ compoundId: z.string().min(1) }),
    )
    if (!this.model.values.recipientId) {
      this.model.values.recipientId = this.model.values.residents[0].id
    }
    this.payers =
      props.payers.resources?.map((p) => ({ value: p.id, label: p.data?.label || '' })) ??
      []
    this.payers.unshift({ value: null, label: 'Bitte wählen...' })
    makeObservable(this)
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancelUpload?.()
  }

  private onSubmit = async () => {
    if (this.uploading) {
      return
    }
    runInAction(() => {
      this.error = null
      this.model.values.aktenzeichen = this.model.values.aktenzeichen.trim()
      this.model.values.ansprechpartner = this.model.values.ansprechpartner.trim()
      this.model.values.email = this.model.values.email.trim()
      this.model.values.fax = this.model.values.fax.trim()
      this.model.values.telefon = this.model.values.telefon.trim()
    })
    const invalid = Object.keys(this.model.getInvalidFields())
    // Model valid or only documentId invalid but upload prepared.
    if (
      invalid.length > 0 &&
      !(this.model.values.file && invalid.length === 1 && invalid[0] === 'documentId')
    ) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }

    window.scrollY = 0

    if (this.model.values.endDate < this.model.values.beginDate) {
      runInAction(
        () => (this.error = 'Das Enddatum darf nicht vor dem Anfangsdatum liegen'),
      )
      return
    }

    // Upload file
    if (this.model.values.file) {
      const file = this.model.values.file
      const name = 'KÜ.pdf'
      const form = new FormData()
      form.append('file', file)
      form.append('scope', 'cost coverage')
      form.append('name', name)

      const source = axios.CancelToken.source()
      this.cancelUpload = () => source.cancel('Canceled by user')
      runInAction(() => (this.uploading = { percentage: 0.05 }))
      const uploadPromise = axios.post(
        `/api/${this.context.instance.id}/documents/files`,
        form,
        {
          cancelToken: source.token,
          onUploadProgress: action((event: AxiosProgressEvent) => {
            if (event.total && event.loaded / event.total <= 1 && this.uploading) {
              // Start at 5% to visualize pending upload for files that are scheduled to upload.
              this.uploading.percentage = 0.05 + 0.95 * (event.loaded / event.total)
            }
          }),
        },
      )
      try {
        const response: any = await uploadPromise
        if (!this.mounted) {
          return
        }
        runInAction(() => {
          if (this.uploading) {
            this.uploading.percentage = 1
          }
          this.model.values.documentId = response.data.id
        })
      } catch (e) {
        runInAction(() => {
          this.uploading = null
          if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
            // Already handeled
          } else {
            this.error = 'Beim Upload ist ein Fehler aufgetreten.'
          }
        })
        return
      }
    }

    this.cancelUpload = null

    const data: ICostCoverageCreate = {
      documentId: this.model.values.documentId || '',
      compoundId: this.model.values.compoundId,
      payerId: this.model.values.payerId,
      issueDate: this.model.values.issueDate,
      beginDate: this.model.values.beginDate,
      endDate: this.model.values.endDate,
      factor: this.model.values.doubleBilling ? 2 : 1,
      aktenzeichen: this.model.values.aktenzeichen,
      betrag: this.model.values.betrag,
      eigenanteil: this.model.values.eigenanteil,
      eigenanteilZyklus: this.model.values.eigenanteilZyklus,
      ansprechpartner: this.model.values.ansprechpartner,
      verpflegung: this.model.values.verpflegung,
      email: this.model.values.email,
      fax: this.model.values.fax,
      telefon: this.model.values.telefon,
      notes: this.model.values.notes,
      isValid: this.model.values.isValid,
      residents: this.model.values.residents.map((r) => ({
        ...r,
        contact: r.id === this.model.values.recipientId,
      })),
    }
    if (!this.model.values.id) {
      // Create KÜ
      try {
        hermes.create(
          `/api/${this.context.instance.id}/residents/${this.props.resident.id}/costCoverageSearchResults`,
          data,
        )
      } catch (_e) {
        runInAction(() => {
          this.uploading = null
          this.error =
            'Beim Anlegen der KÜ ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.'
        })
        return
      }
    } else {
      // Update KÜ
      try {
        hermes.update(
          `/api/${this.context.instance.id}/residents/${this.props.resident.id}/costCoverageSearchResults/${this.model.values.id}`,
          data,
        )
      } catch (_e) {
        runInAction(() => {
          this.uploading = null
          this.error =
            'Beim Speichern der KÜ ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.'
        })
        return
      }
    }
    this.props.changed.hasUnsavedChanges = false
    this.props.onClose()
  }

  @action private setContact = (person: any) => {
    if (
      !window.confirm(
        `Möchten Sie ${person.firstName} ${person.lastName} wirklich als Haushaltsvorstand (Ansprechpartner) für diese Kostenübernahme festlegen?`,
      )
    ) {
      return
    }
    this.model.values.recipientId = person.id
    person.checked = true
  }

  @action private toggleChecked = (person: any) => {
    if (person.id === this.model.values.recipientId) {
      window.alert(
        'Der Haushaltsvorstand dieser KÜ kann nicht abgewählt werden. Sie können einen anderen Haushaltsvorstand für diese KÜ festlegen, indem Sie die Maus über den Namen eines Mitgeltenden bewegen und dann auf den roten Button "HV" klicken. Danach kann der soeben angeklickte Bewohner von dieser KÜ ausgeschlossen werden. Beachten Sie, dass der Haushaltsvorstand dieser KÜ nicht zwingend mit dem echten Haushaltsvorstand der Familie übereinstimmen muss.',
      )
      return
    }
    person.checked = !person.checked
  }

  @action private onSelectFile = (doc: IDocumentMetadata & { file: Blob }) => {
    this.model.values.file = doc.file
    this.model.values.documentId = null
    this.props.changed.hasUnsavedChanges = true
    // Upload on save (and not here)
  }

  @action private onDelete = () => {
    this.model.values.file = null
    this.model.values.documentId = null
  }

  private onDownload = () => {
    download(
      `/api/${this.context.instance.id}/documents/files/${this.model.values.documentId}`,
    )
  }

  @action private onChangeCompound = () => {
    if (!this.context.permissions.host_lfgb || !this.model.values.compoundId) {
      return
    }
    const compound = hermes.getFromStore<ICompound>(
      `/api/${this.context.instance.id}/accommodations/compounds/${this.model.values.compoundId}`,
      false,
    )
    this.model.values.verpflegung =
      compound?.type === 'Aufnahmeeinrichtung' ? 'Vollverpflegung' : 'Selbstverpflegung'
  }

  render() {
    const hasDownload = !!this.model.values.documentId
    const noDocument =
      this.model.touched.get('documentId') && !this.model.values.file && !hasDownload
    let preview: JSX.Element | null = null
    if (this.model.values.file || this.model.values.documentId) {
      preview = (
        <PdfPreview
          url={
            this.model.values.documentId
              ? `/api/${this.context.instance.id}/documents/files/${this.model.values.documentId}`
              : undefined
          }
          cache={this.model.values.file || undefined}
          key={
            this.model.values.documentId
              ? `/api/${this.context.instance.id}/documents/files/${this.model.values.documentId}`
              : 'file'
          }
        >
          {hasDownload && (
            <>
              <RoundIcon
                icon='fas fa-download'
                tooltip='Herunterladen'
                style={{ position: 'absolute', top: 16, right: 16 }}
                color='primary'
                onClick={this.onDownload}
              />
              <PrintIcon
                url={`/api/${this.context.instance.id}/documents/files/${this.model.values.documentId}`}
                style={{ position: 'absolute', top: 54, right: 16 }}
              />
            </>
          )}
          <RoundIcon
            icon='fas fa-trash-alt'
            tooltip='PDF löschen'
            style={{ position: 'absolute', top: !hasDownload ? 16 : 92, right: 16 }}
            color='danger'
            onClick={this.onDelete}
          />
        </PdfPreview>
      )
    } else {
      preview = (
        <div className={noDocument ? 'border-2 rounded-md border-red-500' : undefined}>
          <DocumentDropzone
            accept='application/pdf,.pdf'
            scope='cost coverage'
            onSelect={this.onSelectFile}
            view={ResidentDropzoneView}
          />
        </div>
      )
    }

    return (
      <div id={this.model.id}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='p-6 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                Kostenübernahme
              </Dialog.Title>
            </div>
          </div>
        </div>

        {this.error && (
          <div className='p-6 border-b border-gray-200 sticky top-0 z-50 bg-white'>
            <Message color='danger'>{this.error}</Message>
          </div>
        )}

        <div className='grid grid-cols-1 xl:grid-cols-[888px_1fr]'>
          <div className='border-b xl:border-l xl:border-b-0 border-gray-200 xl:order-2'>
            <div className='grid grid-cols-4 xl:grid-cols-2 gap-4 p-6 top-0 sticky'>
              <InputSelect
                model={this.model}
                name='payerId'
                label='Kostenträger'
                options={this.payers}
              />
              <InputDate model={this.model} name='issueDate' label='Ausstellungsdatum' />
              <InputDate model={this.model} name='beginDate' label='Anfangsdatum' />
              <InputDate model={this.model} name='endDate' label='Enddatum' />
              <InputCompound
                model={this.model}
                name='compoundId'
                label='Gelände'
                onChange={this.onChangeCompound}
                at={
                  this.model.values.beginDate
                    ? dayjs(this.model.values.beginDate).toISOString()
                    : null
                }
              />
              <InputText model={this.model} name='aktenzeichen' label='Aktenzeichen' />
              <InputDecimal
                disabled={
                  this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
                }
                model={this.model}
                name='betrag'
                label={
                  this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
                    ? 'Tagessatz'
                    : 'Monatl. Kosten p. P.'
                }
                precision={8}
                scale={2}
                notNegative
              />
              <InputDecimal
                disabled={
                  this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
                }
                model={this.model}
                name='eigenanteil'
                label={
                  this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
                    ? 'Eigenanteil'
                    : 'Monatl. Eigenanteil'
                }
                precision={8}
                scale={2}
                notNegative
              />
              {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
                <InputSelect
                  model={this.model}
                  name='isValid'
                  label='Gültig'
                  options={validOptions}
                />
              )}
              {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
                <div className='block w-full shadow-sm text-sm border border-gray-300 rounded-md px-3 py-2'>
                  <InputCheckbox
                    color='danger'
                    name='doubleBilling'
                    model={this.model}
                    label='2x abrechnen'
                  />
                </div>
              )}
              <InputText
                className={
                  this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
                    ? ''
                    : 'col-span-1 xl:col-span-2'
                }
                model={this.model}
                name='ansprechpartner'
                label='Ansprechpartner'
              />
              {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
                <InputSelect
                  model={this.model}
                  name='verpflegung'
                  label='Verpflegung'
                  options={verpflegungOptions}
                />
              )}
              <InputText
                className={
                  this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
                    ? 'col-span-2'
                    : 'col-span-1 xl:col-span-2'
                }
                model={this.model}
                name='email'
                label='E-Mail'
              />
              <InputText model={this.model} name='fax' label='Fax' />
              <InputText model={this.model} name='telefon' label='Telefon' />
              <InputTextarea
                className='col-span-4 xl:col-span-2'
                model={this.model}
                name='notes'
                label='Notiz'
                rows={3}
              />
              <div className='col-span-4 xl:col-span-2 text-sm flex flex-wrap gap-x-3'>
                {this.model.values.residents.map((r) => {
                  const isHv = r.id === this.model.values.recipientId
                  return (
                    <div
                      key={r.id}
                      className={`group relative truncate flex p-1 min-w-[90px] hover:bg-indigo-50 rounded-md border-2 ${
                        isHv ? 'border-indigo-500' : 'border-transparent'
                      }`}
                    >
                      <input
                        type='checkbox'
                        checked={r.checked}
                        onChange={() => this.toggleChecked(r)}
                        className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded relative'
                        style={{ top: 2 }}
                      />
                      <span
                        className='truncate flex-auto cursor-pointer ml-1 text-gray-500'
                        onClick={() => this.toggleChecked(r)}
                      >{`${r.lastName.toUpperCase()}, ${r.firstName}`}</span>
                      {isHv && (
                        <span className='ml-2 flex-content mr-auto inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-500 text-white'>
                          HV
                        </span>
                      )}
                      <div
                        className='hidden group-hover:block absolute'
                        style={{ top: 3, right: 5 }}
                      >
                        {!isHv && (
                          <span
                            onClick={() => this.setContact(r)}
                            className='hidden group-hover:inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-500 text-white cursor-pointer'
                          >
                            <i className='fas fa-share' />
                            &nbsp;HV
                          </span>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='p-6 bg-gray-100 xl:order-1'>{preview}</div>
        </div>

        {this.uploading && (
          <DialogOverlayArea opaque>
            <CircularUploadProgress
              onCancel={this.props.onClose}
              upload={this.uploading}
            />
          </DialogOverlayArea>
        )}
        <div
          className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button
            disabled={!!this.uploading}
            color='secondary'
            outline
            onClick={this.props.onClose}
          >
            Abbrechen
          </Button>
          <Button
            disabled={!!this.uploading}
            color='primary'
            className='ml-2'
            onClick={this.onSubmit}
          >
            Speichern
          </Button>
        </div>
      </div>
    )
  }
}
