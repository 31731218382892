export function getMinDimensions(
  original: { width: number; height: number },
  target: { width: number; height: number },
): { minWidth: number; minHeight: number } {
  if (original.height < 1 || original.width < 1) {
    return { minWidth: target.width, minHeight: target.height }
  }
  const xScale = target.width / original.width
  if (original.height * xScale > target.height) {
    // Image height doesn't fit
    return { minWidth: target.width, minHeight: original.height * xScale }
  }

  // Image width doesn't fit
  const yScale = target.height / original.height
  return { minWidth: original.width * yScale, minHeight: target.height }
}
