import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { XIcon } from '@heroicons/react/outline'
import { NotFound } from 'components/Callout/components/NotFound'
import { Dialog } from 'components/Dialog'
import { NotAuthorizedError, NotFoundError } from 'contracts/errors/HermesErrors'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentDocumentationSearchResult } from 'contracts/residents/interfaces/IResidentDocumentationSearchResult'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { ALL_PERMISSIONS_ROLE_LABEL, IRole } from 'contracts/users/interfaces/IRole'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { DocumentationDialogContent } from './components/DocumentationDialogContent'
import { Dialog as UIDialog } from '@headlessui/react'
import { Button } from 'components/Form/components/Button'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { getCurrentResponsibilityCompoundId } from 'contracts/residents/helpers/getCurrentResponsibilityCompoundId'
import { isStammCompound } from 'helpers/isStamm'

interface Props {
  documentationId: string
  resident: IResident
  residentSearchResults: Collection<IResidentSearchResult>
  roles: Collection<IRole>
  onClose: () => void
}

@observer
export class DocumentationDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly documentation: Resource<IResidentDocumentationSearchResult>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.documentation = new Resource(
      `/api/${context.instance.id}/residents/${props.resident.id}/documentationSearchResults/${props.documentationId}`,
    )
    if (this.props.documentationId === 'new') {
      const u = context.user
      const residents = props.residentSearchResults.resources
        ? props.residentSearchResults.resources
            .filter((r) => !!r.data)
            .map((r) => ({
              checked: r.id === props.resident.id,
              id: r.id,
              sex: r.data!.sex,
              firstName: r.data!.firstName,
              lastName: r.data!.lastName,
              imageId: r.data!.imageId,
            }))
        : []
      const doku: IResidentDocumentationSearchResult = {
        id: '',
        instanceId: context.instance.id,
        compound: {
          id: props.residentSearchResults.resources
            ? getCurrentResponsibilityCompoundId(
                props.residentSearchResults.resources.filter(
                  (r) => r.id === props.resident.id,
                )[0].data?.data.bookings,
              )
            : (null as any),
          label: '',
        },
        date: dayjs().format('YYYY-MM-DD'),
        time: '',
        type: null as any,
        topics: [],
        notes: '',
        createdAt: new Date().toISOString(),
        createdBy: {
          id: u.id,
          sex: u.sex,
          firstName: u.firstName,
          lastName: u.lastName,
          imageId: u.imageId,
        },
        residents,
        roleIds:
          props.roles.resources
            ?.filter(
              (r) =>
                !!r.data && !r.data.userId && r.data.label !== ALL_PERMISSIONS_ROLE_LABEL,
            )
            .map((r) => r.id) ?? [],
        todoId: null,
        todoDoneDate: null,
        documentIds: [],
      }
      if (
        context.permissions.resident_documentation === 1 &&
        !isStammCompound(doku.compound.id || '')
      ) {
        doku.compound.id = null as any
      }
      this.documentation.data = doku
      this.documentation.error = null
    }
  }

  componentDidMount() {
    if (this.props.documentationId !== 'new') {
      this.disposers.push(this.documentation.init({ readOnly: true }))
    }
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  render() {
    if (
      this.documentation.error &&
      this.documentation.error?.id !== RequestPendingError.id
    ) {
      return (
        <Dialog size='lg' open setOpen={this.props.onClose}>
          <div>
            <div className='absolute top-0 right-0 pt-4 pr-6'>
              <button
                type='button'
                className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={this.props.onClose}
              >
                <span className='sr-only'>Close</span>
                <XIcon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>

            <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
              <div className='flex items-start'>
                <div className='-mt-2 text-left'>
                  <UIDialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900'
                  >
                    Dokumentation
                  </UIDialog.Title>
                </div>
              </div>
            </div>

            <div className='py-10 bg-gray-100'>
              {this.documentation.error.id !== NotFoundError.id && (
                <LoadingError
                  title={
                    this.documentation.error.id === NotAuthorizedError.id
                      ? 'Keine Sichtberechtigung für diese Dokumentation'
                      : 'Diese Doku konnte nicht geladen werden'
                  }
                  subtitle='Bitte wenden Sie sich an einen Administrator'
                />
              )}
              {this.documentation.error.id === NotFoundError.id && (
                <NotFound
                  title='Diese Doku konnte nicht gefunden werden'
                  subtitle='Möglicherweise wurde sie in der Zwischenzeit gelöscht'
                />
              )}
            </div>

            <div
              className='py-4 px-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
              style={{ borderRadius: '0 0 8px 8px' }}
            >
              <Button color='secondary' outline onClick={this.props.onClose}>
                Schließen
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }

    if (!this.documentation.data) {
      return null
    }

    return (
      <Dialog size='lg' open setOpen={this.props.onClose}>
        <DocumentationDialogContent
          key={this.props.documentationId}
          resident={this.props.resident}
          onClose={this.props.onClose}
          documentation={this.documentation.data}
          roles={this.props.roles}
        />
      </Dialog>
    )
  }
}
