import { hermes } from '@byll/hermes'
import { ITodoCreate } from 'contracts/todos/interfaces/ITodoCreate'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'

export async function createTodo(
  todo: ITodoSearchResult & {
    isDone: boolean
    assigneeIds: string[]
    watcherIds: string[]
    hasTodo: boolean
  },
  compoundId: string,
  instanceId: string,
): Promise<string> {
  const creatable: ITodoCreate = {
    residentId: null,
    compoundId,
    priority: todo.priority,
    dueDate: todo.dueDate,
    doneDate: todo.isDone ? todo.doneDate : null,
    label: todo.label,
    notes: todo.notes,
    gateMessage: todo.gateMessage,
    gateMessageValidTill: todo.gateMessageValidTill,
    url: todo.url,
    assigneeIds: todo.assigneeIds,
    watcherIds: todo.watcherIds,
  }
  const response = await hermes.create(
    `/api/${instanceId}/todoLists/${todo.listId}/todos`,
    creatable,
  )
  return response.id
}
