import { hermes } from '@byll/hermes'
import { IUser } from 'contracts/users/interfaces/IUser'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  userId: string
  doneAt: string
}

@observer
export class DoneBy extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private user: IUser | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    hermes
      .getOnceNew<IUser>(`/api/${this.context.instance.id}/users/${this.props.userId}`)
      .then(
        action((user) => {
          this.user = user
        }),
      )
  }

  render() {
    if (!this.user) {
      return null
    }
    return (
      <div className='text-gray-400 text-xs -mt-2 ml-2'>
        <i className='fa fa-check' /> Erledigt von {this.user.firstName}{' '}
        {this.user.lastName} am {dayjs(this.props.doneAt).format('DD.MM.YYYY')}
      </div>
    )
  }
}
