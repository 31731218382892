import { hermes } from '@byll/hermes'
import { KeyIcon } from '@heroicons/react/outline'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { TopBar } from 'components/SideBarLayout/components/TopBar'
import { NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { AccountGeneralLogout } from './AccountGeneralLogout'

interface Props {}

@observer
export class AccountSecurity extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<any>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      login: context.user.login,
      type: '',
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    })
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    const data = await hermes.getOnceNew<{
      id: string
      type: 'ldap' | 'password' | 'none'
    }>(`/api/${this.context.instance.id}/users/changePassword/${this.context.user.id}`)
    runInAction(() => {
      switch (data.type) {
        case 'ldap':
          this.model.values.type = 'Active Directory'
          break
        case 'password':
          this.model.values.type = 'Login / Passwort'
          break
        default:
          this.model.values.type = ''
          break
      }
    })
  }

  private updatePassword = async () => {
    if (this.model.values.type !== 'Login / Passwort') {
      return
    }
    if (this.model.values.currentPassword.length === 0) {
      void box.alert(
        'Aktuelles Passwort',
        'Bitte tragen Sie das aktuelle Passwort ein, um Ihre Identiatät vor dem Passwortwechsel zu bestätigen.',
        { color: 'danger' },
      )
      return
    }
    if (this.model.values.newPassword.length < 6) {
      void box.alert(
        'Neues Passwort',
        'Das neue Passwort muss mindestens 6 Zeichen lang sein.',
        { color: 'danger' },
      )
      return
    }
    if (this.model.values.newPassword !== this.model.values.newPasswordRepeat) {
      void box.alert(
        'Neues Passwort',
        'Das neue Passwort stimmt nicht mit der Passwortwiederholung überein.',
        { color: 'danger' },
      )
      return
    }
    try {
      await hermes.update(
        `/api/${this.context.instance.id}/users/changePassword/${this.context.user.id}`,
        {
          currentPassword: this.model.values.currentPassword,
          newPassword: this.model.values.newPassword,
        },
      )
      void box.alert('Neues Passwort', 'Das Passwort wurde erfolgreich geändert.')
      runInAction(() => {
        this.model.values.currentPassword = ''
        this.model.values.newPassword = ''
        this.model.values.newPasswordRepeat = ''
      })
    } catch (e: any) {
      void box.alert(
        'Neues Passwort',
        e.id === NotAuthorizedError.id
          ? 'Das aktuelle Passwort war falsch und konnte daher nicht geändert werden.'
          : 'Die Passwortänderung ist fehlgeschlagen.',
        { color: 'danger' },
      )
    }
  }

  render() {
    return (
      <div className='md:pl-80 flex flex-col flex-1 min-h-screen'>
        <TopBar>
          <ol className='bg-white px-2 flex'>
            <li className='flex'>
              <div className='flex items-center text-gray-600'>
                <KeyIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
                <span className='ml-2'>{`${this.context.user.firstName} ${this.context.user.lastName}`}</span>
              </div>
            </li>
            <li className='flex'>
              <div className='flex items-center'>
                <svg
                  className='flex-shrink-0 h-5 w-5 text-gray-300'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                >
                  <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                </svg>
                Sicherheit
              </div>
            </li>
          </ol>
        </TopBar>
        <CardColumn>
          {/* ID card */}
          <CardColumnCard
            title='Passwort'
            subtitle='Login anzeigen und Passwort ändern'
            saveButton={
              this.model.values.type === 'Login / Passwort'
                ? 'Passwort ändern'
                : undefined
            }
            onSave={this.updatePassword}
          >
            {this.model.values.type !== 'Login / Passwort' &&
              this.model.values.type !== '' && (
                <Message color='primary' className='mb-5'>
                  Eine Passwortänderung ist mit der aktuellen Anmeldeart nicht möglich.
                </Message>
              )}
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
              <InputText
                className='col-span-2'
                disabled
                model={this.model}
                name='type'
                label='Anmeldeart'
              />
              <InputText
                className='col-span-2'
                disabled
                model={this.model}
                name='login'
                label='Login'
              />
              <InputText
                type='password'
                disabled={this.model.values.type !== 'Login / Passwort'}
                className='col-span-2'
                model={this.model}
                name='currentPassword'
                label='Aktuelles Passwort'
              />
              <InputText
                type='password'
                disabled={this.model.values.type !== 'Login / Passwort'}
                className='col-span-2 sm:col-span-1'
                model={this.model}
                name='newPassword'
                label='Neues Passwort'
              />
              <InputText
                type='password'
                disabled={this.model.values.type !== 'Login / Passwort'}
                className='col-span-2 sm:col-span-1'
                model={this.model}
                name='newPasswordRepeat'
                label='Passwort wiederholen'
              />
            </div>
            {/*Login anzeigen. PW änderungs-Formular, email für pw vergessen Beachte ldap server (Alles mit Hinweis ausgegraut.)*/}
          </CardColumnCard>
          <AccountGeneralLogout />
        </CardColumn>
      </div>
    )
  }
}
