import { Collection, hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { ICardTemplate } from 'contracts/general/interfaces/ICardTemplate'
import { IResidentCard } from 'contracts/residents/interfaces/IResidentCard'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Dialog } from 'components/Dialog'
import { isValidCard } from '../../../../../../../../../../contracts/residents/helpers/isValidCard'
import { CardDialog } from './components/CardDialog'
import { RoundIcon } from 'components/RoundIcon'
import { box } from 'services/box'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { Button } from 'components/Form/components/Button'
import { isStammCompound } from 'helpers/isStamm'

interface Props {
  resident: IResident
}

@observer
export class OverviewCards extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly cards: Collection<IResidentCard>
  private readonly searchResult: Resource<IResidentSearchResult>
  private readonly disposers: Disposer[] = []
  @observable private filter: 'all' | 'valid' | 'invalid' = 'valid'
  @observable private dialog: IResidentCard | null = null
  @observable private changed = { hasUnsavedChanges: false }
  @observable private templates: ICardTemplate[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.searchResult = new Resource(
      `/api/${context.instance.id}/residentSearchResults/${props.resident.id}`,
    )
    this.cards = new Collection(
      `/api/${context.instance.id}/residents/${props.resident.id}/cards`,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.searchResult.init({ readOnly: true }))
    this.disposers.push(this.cards.init({ readOnly: true }))
    void this.loadTemplates()
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private loadTemplates = async () => {
    const templates = await hermes.indexOnceNew<ICardTemplate>(
      `/api/${this.context.instance.id}/cardTemplates?scope=resident`,
    )
    runInAction(() => (this.templates = templates))
  }

  private cardMapper = (res: Resource<IResidentCard>) => {
    const card = res.data
    if (!card) {
      return null
    }
    const isValid = isValidCard(card) && !!card.token
    if (this.filter === 'valid' && !isValid) {
      return null
    }
    if (this.filter === 'invalid' && isValid) {
      return null
    }
    return (
      <div
        key={card.id}
        className='group hover:bg-indigo-100 bg-gray-100 mt-4 px-3 py-2 rounded-md overflow-hidden relative cursor-pointer truncate'
        onClick={() => this.openCard(card)}
      >
        #{card.id}
        &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
        {`Erstellt am ${dayjs(card.createdAt).format('DD.MM.YYYY')}`}
        {isValid && (
          <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 border border-green-500 ml-2'>
            {card.validTill
              ? `Gültig bis ${dayjs(card.validTill).format('DD.MM.')}`
              : 'Gültig'}
          </span>
        )}
        {!isValid && (
          <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 border border-red-500 ml-2'>
            Ungültig
          </span>
        )}
        {card.notes && (
          <span className='ml-2 text-blue-500'>
            <i className='fas fa-comment' />
          </span>
        )}
        <span className='text-gray-400 text-sm'>
          &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;{card.token}
        </span>
        {this.context.permissions.resident_id_cards >= 2 && (
          <RoundIcon
            classNameContainer='hidden group-hover:block'
            tooltip={{ text: 'Ausweis löschen', position: 'left' }}
            style={{ position: 'absolute', top: 5, right: 5 }}
            icon='fas fa-trash'
            color='danger'
            onClick={(event) => {
              event.stopPropagation()
              this.deleteCard(card)
            }}
          />
        )}
      </div>
    )
  }

  @action
  private openCard = (card: IResidentCard) => {
    this.dialog = card
  }

  @action
  private addCard = () => {
    this.changed = { hasUnsavedChanges: false }
    this.dialog = {
      id: '',
      residentId: this.props.resident.id,
      compoundId:
        (this.searchResult.data?.data.accommodation?.type === 'internal-residence'
          ? this.searchResult.data.data.accommodation.building?.compoundId
          : null) || (null as any),
      templateId: null,
      token: '',
      validTill: null,
      createdBy: `${this.context.user.lastName}, ${this.context.user.firstName}`,
      createdAt: new Date().toISOString(),
      documentId: '',
      notes: '',
    }
  }

  private deleteCard = async (card: IResidentCard) => {
    if (
      this.context.permissions.resident_id_cards === 2 &&
      !isStammCompound(card.compoundId)
    ) {
      await box.alert(
        'Geländeberechtigung fehlt',
        'Sie haben nicht die nötige Berechtigung, um Ausweise außerhalb Ihrer Stammgelände zu löschen.',
        { color: 'danger' },
      )
      return
    }
    const confirmed = await box.alert(
      'Ausweis löschen',
      'Möchten Sie diesen Ausweis wirklich unwiderruflich löschen?',
      { confirm: 'Ja, jetzt löschen', cancel: 'Abbrechen', color: 'danger' },
    )
    if (!confirmed) {
      return
    }
    hermes
      .delete(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/cards/${card.id}`,
        { immediately: true },
      )
      .catch(() => alert('Der Ausweis konnte nicht gelöscht werden.'))
  }

  @action
  private closeCard = () => {
    if (this.changed.hasUnsavedChanges) {
      const result = window.confirm(
        'Sie haben ungespeicherte Änderungen. Wollen Sie den Dialog wirklich schließen und die Änderungen verwerfen?',
      )
      if (!result) {
        return
      }
    }
    this.changed.hasUnsavedChanges = false
    this.dialog = null
    this.filter = 'all'
  }

  @action setFilterAll = () => (this.filter = 'all')
  @action setFilterValid = () => (this.filter = 'valid')
  @action setFilterInvalid = () => (this.filter = 'invalid')

  render() {
    const rows = this.cards.resources
      ? this.cards.resources.map(this.cardMapper).filter(Boolean)
      : []
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Ausweise</span>
          <br />
          <span className='text-sm text-gray-400'>
            Kurzprofil, Scans und Ausweisdokumente
          </span>
        </div>

        {(!this.cards.resources || !this.templates || !this.searchResult.data) && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            <Spinner delay />
          </div>
        )}

        {this.cards.resources && this.templates && this.searchResult.data && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            <div className='pl-2'>
              <Button
                color='secondary'
                onClick={this.setFilterAll}
                outline={this.filter !== 'all'}
                style={{ borderRadius: '6px 0 0 6px' }}
              >
                Alle
              </Button>
              <Button
                color='secondary'
                onClick={this.setFilterValid}
                outline={this.filter !== 'valid'}
                style={{ borderRadius: '0' }}
              >
                Gültig
              </Button>
              <Button
                color='secondary'
                onClick={this.setFilterInvalid}
                outline={this.filter !== 'invalid'}
                style={{ borderRadius: '0 6px 6px 0' }}
              >
                Ungültig
              </Button>
            </div>

            {/* Id cards */}
            {rows}

            {/* No cards available */}
            {rows.length === 0 && (
              <div className='text-gray-500 bg-gray-100 mt-4 px-4 py-5 rounded-md overflow-hidden relative'>
                Für den aktuellen Filter liegen keine Ausweise vor.
              </div>
            )}

            {/* Add new id card */}
            {this.context.permissions.resident_id_cards >= 2 && (
              <div
                className='hover:bg-indigo-100 bg-gray-100 mt-4 px-4 py-2 rounded-md overflow-hidden relative cursor-pointer'
                onClick={this.addCard}
              >
                <span className='text-lg'>
                  <i className='fas fa-plus text-gray-500 mr-2' />
                </span>
                Ausweis erstellen
              </div>
            )}

            {/* Dialog */}
            <Dialog size='md' open={!!this.dialog} setOpen={this.closeCard}>
              {this.dialog && (
                <CardDialog
                  onClose={this.closeCard}
                  card={this.dialog}
                  changed={this.changed}
                  templates={this.templates}
                  resident={this.searchResult.data}
                />
              )}
            </Dialog>
          </div>
        )}
      </div>
    )
  }
}
