import { IBuildingFloor } from 'contracts/accommodations/interfaces/IBuildingFloor'
import { IRoom } from './../../../contracts/accommodations/interfaces/IRoom'

export function createNewLegendWithRoom(
  room: IRoom,
  x: number,
  y: number,
  legend: IBuildingFloor['legend'],
): IBuildingFloor['legend'] {
  const rooms = [...legend.rooms]
  for (let i = rooms.length - 1; i >= 0; i--) {
    if (rooms[i].id === room.id) {
      rooms.splice(i, 1)
    }
  }
  rooms.push({ id: room.id, x, y, width: 200, height: 100 })
  return { ...legend, rooms }
}
