import { ITreeNode } from 'contracts/general/interfaces/ITreeNode'
import React from 'react'
import { TreeNode } from './components/TreeNode'
import { expand } from './helpers/expand'
interface Props {
  nodes: ITreeNode[]
  onSelect: (key: string) => void
  selected: string | null
  className?: string
}

export class Tree extends React.Component<Props, {}> {
  private readonly expanded = new Set<string>()
  private skipAutoExpand = false

  constructor(props: Props) {
    super(props)
    if (props.selected) {
      expand(props.nodes, props.selected, this.expanded)
    }
  }

  componentDidUpdate() {
    if (this.skipAutoExpand) {
      this.skipAutoExpand = false
      return
    }
    if (this.props.selected) {
      const len = this.expanded.size
      expand(this.props.nodes, this.props.selected, this.expanded)
      if (len !== this.expanded.size) {
        this.forceUpdate()
      }
    }
  }

  private onExpand = (key: string) => {
    if (this.expanded.has(key)) {
      this.expanded.delete(key)
    } else {
      this.expanded.add(key)
    }
    this.skipAutoExpand = true
    this.forceUpdate()
  }

  render() {
    return (
      <div className='bg-white flex-auto relative'>
        <div className='absolute h-fit overflow-auto top-0 left-0 bottom-0 right-0 text-sm pl-2 py-3 text-gray-500'>
          {this.props.nodes.map((child) => (
            <TreeNode
              key={child.key}
              node={child}
              selected={this.props.selected}
              onSelect={this.props.onSelect}
              expanded={this.expanded}
              onExpand={this.onExpand}
            />
          ))}
        </div>
      </div>
    )
  }
}
