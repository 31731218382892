import { hermes } from '@byll/hermes'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { makeObservable, observable, runInAction } from 'mobx'
import { Button } from 'components/Form/components/Button'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { InputUser } from 'components/Form/components/InputUser'
import { EmployeeList } from './components/EmployeeList'
import { InputText } from 'components/Form/components/InputText'
import { box } from 'services/box'
import { dayjs } from 'helpers/dayjs'
import { waitForElementWithId } from 'helpers/waitForElementWithId'
import { InputMonth } from 'components/Form/components/InputMonth'
import { Tooltip } from 'components/Tooltip'
import { UploadTimeSheetsDialog } from './components/UploadTimeSheetsDialog'

interface Props {}

@observer
export class WorkplanSignatures extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ employeeId: string | null; month: string }>
  @observable private downloading = false

  constructor(props: Props) {
    super(props)
    this.model = new Model({
      employeeId: null,
      month: dayjs().startOf('month').add(-1, 'month').format('YYYY-MM'),
    })
    makeObservable(this)
  }

  private download = async () => {
    if (this.downloading) {
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport(
      'pdfStundenzettel',
      this.context.instance.id,
      { month: this.model.values.month, employeeIds: null },
      `${this.model.values.month} Stundenzettel.pdf`,
    )
    runInAction(() => (this.downloading = false))
  }

  private openUploadDialog = async () => {
    const promise = box.custom(
      <UploadTimeSheetsDialog onClose={() => promise.close()} />,
      { context: this.context },
    )
  }

  private onChoose = async () => {
    if (!this.model.values.employeeId) {
      return
    }
    try {
      await hermes.create(
        `/api/${this.context.instance.id}/workplan/signatures/${this.context.user.id}-${this.model.values.month}/employees`,
        { employeeId: this.model.values.employeeId },
      )
      runInAction(() => (this.model.values.employeeId = null))
      const elem = await waitForElementWithId('input-add-user')
      elem?.querySelector('input')?.focus()
    } catch (_e) {
      runInAction(() => (this.model.values.employeeId = null))
      box.alert(
        'Fehler',
        'Der Mitarbeiter konnte nicht zu dieser Liste hinzugefügt werden. Bitte wenden Sie sich an einen Administrator.',
        { color: 'danger' },
      )
    }
  }

  render() {
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full'>
        {/* Section heading */}
        <div className='relative my-7 pb-5 sm:pb-0 bg-white sm:rounded-lg shadow p-6'>
          <div className='flex items-center justify-between pb-6'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>Stundenzettel</h3>
            <div className='flex-auto ml-4 relative'>
              {!this.model.values.employeeId && (
                <InputUser
                  id='input-add-user'
                  onChoose={this.onChoose}
                  model={this.model}
                  name='employeeId'
                  placeholder='Mitarbeiter hinzufügen...'
                />
              )}
              {this.model.values.employeeId && (
                <InputText readOnly model={this.model} name='' />
              )}
              {this.model.values.employeeId && (
                <span className='absolute top-2 left-3 text-indigo-500'>
                  <i className='fas fa-spin fa-spinner' />
                </span>
              )}
            </div>
            <div className='flex-content ml-4'>
              <InputMonth model={this.model} name='month' />
            </div>
            <div className='flex-content ml-4'>
              <Button
                color='primary'
                className='has-tooltip'
                style={{ width: 38, height: 38, padding: 0, borderRadius: '6px 0 0 6px' }}
                onClick={this.download}
              >
                {this.downloading && (
                  <span>
                    <i className='fas fa-sync fa-spin' />
                  </span>
                )}
                {!this.downloading && (
                  <span>
                    <i className='fas fa-download' />
                  </span>
                )}
                <Tooltip position='top'>Download</Tooltip>
              </Button>
              <Button
                className='has-tooltip'
                color='primary'
                style={{ width: 38, height: 38, padding: 0, borderRadius: '0 6px 6px 0' }}
                onClick={this.openUploadDialog}
              >
                <span>
                  <i className='fas fa-upload' />
                </span>
                <Tooltip position='top'>Upload</Tooltip>
              </Button>
            </div>
          </div>
        </div>

        {/* Table */}
        <EmployeeList key={this.model.values.month} month={this.model.values.month} />
      </div>
    )
  }
}
