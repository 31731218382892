import * as React from 'react'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { actionType, calcCaretPosition } from './helpers/calcCaretPosition'
import { appendDecimalPlaces } from './helpers/appendDecimalPlaces'
import { formatDecimal } from './helpers/formatDecimal'
import { Decimal } from 'decimal.js-light'
import { parseDecimal } from './helpers/parseDecimal'
import { isUpdateRequired } from './helpers/isUpdateRequired'
import { Model } from '../../Model'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  inputClassName?: string
  style?: any
  children?: Element
  setRef?: (HTMLInputElement) => void

  precision: number
  scale: number
  notFillDecimalPlaces?: boolean
  notNegative?: boolean
  noThousandsSeparators?: boolean
  postfix?: string
  percentage?: boolean // model value: 0.2, display value: 20 %
}

// do not use g flag here, it will lead numberRegex to store the lastIndex and the calculations will no longer
// work as expected.
const maskRegex = /\./ // all the characters that are used as masks and cannot be deleted

@observer
export class InputDecimal extends React.Component<Props, {}> {
  public static defaultProps = {
    notNegative: false,
  }

  @observable private isEmpty = false
  private readonly id: string
  private ref: HTMLInputElement | null = null
  private nextChangeAction: actionType | null = null
  private formattedValue: string = ''
  private value: string | null = null
  private dispose: Disposer | null = null

  @computed get modelValue(): string | null {
    if (this.props.percentage && this.props.model.values[this.props.name]) {
      return new Decimal(this.props.model.values[this.props.name]).times(100).toString()
    }
    return this.props.model.values[this.props.name]
  }

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('input-')
    makeObservable(this)
  }

  componentDidMount() {
    this.dispose = reaction(
      (): string => {
        return formatDecimal(
          appendDecimalPlaces(
            this.modelValue,
            this.props.scale,
            this.props.notFillDecimalPlaces,
          ),
          this.props.postfix,
          this.props.noThousandsSeparators,
        )
      },
      (formatted: string) => {
        if (
          !isUpdateRequired(
            this.props.postfix || '',
            !!this.props.noThousandsSeparators,
            this.formattedValue,
            this.modelValue,
            this.value,
          )
        ) {
          return
        }
        this.setText(formatted)
        this.value = appendDecimalPlaces(
          this.modelValue,
          this.props.scale,
          this.props.notFillDecimalPlaces,
        )
      },
      { fireImmediately: true },
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.width !== this.props.width) {
      this.setText(this.formattedValue)
    }
  }

  @action setText = (text: string, caretPos?: number) => {
    if (!this.ref) {
      return
    }
    this.formattedValue = text
    this.isEmpty = text.length === 0

    this.ref.value = text

    if (caretPos) {
      // setting caret async is required for mobile chrome, but also set the
      // caret sync so other browsers do not flicker
      this.setCaret(this.ref, caretPos)
      setTimeout(() => this.ref && this.setCaret(this.ref, caretPos), 0)
    }
  }

  componentWillUnmount() {
    if (this.dispose) {
      this.dispose()
    }
  }

  @action
  onBlur = (event) => {
    const name = this.props.name
    const valueWithAppendedDecimalPlaces = appendDecimalPlaces(
      this.modelValue,
      this.props.scale,
      this.props.notFillDecimalPlaces,
    )

    this.props.model.touched[name] = true
    this.setText(
      formatDecimal(
        valueWithAppendedDecimalPlaces,
        this.props.postfix,
        this.props.noThousandsSeparators,
      ),
    )
    this.value = this.modelValue
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  onChange = (event) => {
    this.changedOrPasted(event.target, event.target.value, this.nextChangeAction)
    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }

  onKeyDown = (event) => {
    const element = event.target
    const inputCaretPos = element.selectionStart
    const inputValue = event.target.value
    if (event.key === 'Delete' && maskRegex.test(inputValue[inputCaretPos])) {
      // trying to delete a masking character. Prevent deletion and shift the caret to the right
      event.preventDefault()
      this.setCaret(element, inputCaretPos + 1)
    } else {
      if (event.key === 'Backspace') {
        this.nextChangeAction = 'deletedBackwards'
      } else if (event.key === 'Delete') {
        this.nextChangeAction = 'deletedForward'
      } else {
        this.nextChangeAction = 'typed'
      }
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event)
    }
  }

  onPaste = (event) => {
    // if we take event.clipboardData.getData('Text') here, we would always replace the whole value of the input,
    // even if the user just wanted to replace a few characters. That's why we treat the paste in the onChange hook,
    // but set a toggle here so we know a value was pasted
    this.nextChangeAction = 'pasted'
    if (this.props.onPaste) {
      this.props.onPaste(event)
    }
  }

  @action
  changedOrPasted = (
    element: HTMLInputElement,
    inputValue: string,
    type: actionType | null,
  ) => {
    const name = this.props.name
    const inputCaretPos = element.selectionStart || 0

    const { parsedValue, parseChanges, negative } = parseDecimal(
      inputValue,
      type === 'pasted',
      this.props.precision,
      this.props.scale,
      this.props.notNegative!,
      this.modelValue,
      this.formattedValue,
      inputCaretPos,
    )

    let formattedValue
    // allow formattedValue to include a minus (which is not allowed as the value)
    if (parsedValue === null && negative) {
      formattedValue = formatDecimal(
        '-',
        this.props.postfix,
        this.props.noThousandsSeparators,
      )
    } else {
      formattedValue = formatDecimal(
        parsedValue,
        this.props.postfix,
        this.props.noThousandsSeparators,
      )
    }

    const formattedCaretPos = calcCaretPosition(
      inputValue,
      formattedValue,
      inputCaretPos,
      type,
      parseChanges,
    )

    this.setText(formattedValue, formattedCaretPos)
    this.value = parsedValue
    if (this.props.percentage && parsedValue) {
      this.props.model.values[name] = new Decimal(parsedValue).div(100).toString()
    } else {
      this.props.model.values[name] = parsedValue
    }
    this.props.model.touched[name] = true
  }

  innerRef = (ref: HTMLInputElement) => {
    this.ref = ref
  }

  setCaret = (element: HTMLInputElement, caretPos: number) => {
    element.focus()
    element.setSelectionRange(caretPos, caretPos)
  }

  render() {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const {
      name,
      model,
      label,
      tooltip,
      className,
      inputClassName,
      setRef,
      precision,
      scale,
      notFillDecimalPlaces,
      notNegative,
      noThousandsSeparators,
      postfix,
      percentage,
      children,
      ...attributes
    } = this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    if (inputClassName) {
      innerClassName += ` ${inputClassName}`
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7 }}
          >
            {label}
          </label>
        )}
        <input
          type='text'
          className={innerClassName}
          maxLength={255}
          {...attributes}
          name={name}
          value={undefined}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onPaste={this.onPaste}
          onKeyDown={this.onKeyDown}
          ref={this.innerRef}
          id={this.id}
        />
        {children}
      </div>
    )
  }
}
