import * as React from 'react'
import { Callout } from '../index'

export class NotAllowed extends React.Component<{ className?: string }, {}> {
  render() {
    return (
      <Callout
        className={this.props.className}
        icon='fas fa-ban'
        iconColor='#ef4444'
        title='Dieser Bereich ist gesperrt'
        subtitle='Zugang ist über einen Administrator möglich.'
      />
    )
  }
}
