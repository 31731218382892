import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  model: Model<any>
  name: string
  label: string
  placeholder: string
  roles: InputMultiSelectOption[] | null
  className?: string
  disabled?: boolean
}

@observer
export class DocumentationRoleIdDropdown extends React.Component<Props, {}> {
  render() {
    if (!this.props.roles) {
      return (
        <InputText
          disabled={this.props.disabled}
          className={this.props.className}
          model={this.props.model}
          name={this.props.name}
          label='Sichtbar für'
          readOnly
        />
      )
    }
    return (
      <InputMultiSelect
        disabled={this.props.disabled}
        className={this.props.className}
        model={this.props.model}
        name='roleIds'
        label='Sichtbar für'
        placeholder={this.props.placeholder}
        options={this.props.roles}
      />
    )
  }
}
