import { Collection } from '@byll/hermes'
import { CollectionOptions } from '@byll/hermes/lib/interfaces/CollectionOptions'
import { useEffect, useState } from 'react'

export function useCollection<D extends { id: string }, M = {}, Q = {}>(
  path: string,
  options?: CollectionOptions,
  query?: Q,
): Collection<D, M, Q> {
  const [col] = useState(() => new Collection<D, M, Q>(path, query))
  useEffect(() => {
    return col.init(options)
    // eslint-disable-next-line
  }, [col])
  return col
}
