import {
  OfficeBuildingIcon,
  ExclamationIcon,
  HomeIcon,
  LogoutIcon,
  FlagIcon,
  HandIcon,
} from '@heroicons/react/solid'
import * as React from 'react'

export interface BookingTypeDetail {
  past: string
  present: string
  future: string
  color: string
  icon: any
  endButtonColor?: string
  endButtonText?: string
}

export const bookingTypes: { [type: string]: BookingTypeDetail } = {
  'internal-residence': {
    past: 'Frühere Belegung',
    present: 'Aktuelle Belegung',
    future: 'Zukünftige Belegung',
    color: 'text-white bg-yellow-500',
    icon: HomeIcon,
    endButtonText: 'Beenden',
    endButtonColor:
      'from-yellow-500 to-yellow-600 hover:bg-gradient-to-r hover:from-yellow-500 hover:to-yellow-500 focus:ring-yellow-500',
  },
  'internal-reservation': {
    past: 'Frühere Zimmerreservierung',
    present: 'Aktuelle Zimmerreservierung',
    future: 'Zukünftige Zimmerreservierung',
    color: 'text-white bg-gray-500',
    icon: HomeIcon,
    endButtonText: 'Beenden',
    endButtonColor:
      'from-gray-500 to-gray-600 hover:bg-gradient-to-r hover:from-gray-500 hover:to-gray-500 focus:ring-gray-500',
  },
  'external-residence': {
    past: 'Frühere Abwesenheit',
    present: 'Aktuelle Abwesenheit',
    future: 'Zukünftige Abwesenheit',
    color: 'text-white bg-indigo-500',
    icon: OfficeBuildingIcon,
    endButtonText: 'Beenden',
    endButtonColor:
      'from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500',
  },
  'responsibility-begin': {
    past: 'Neue Zuständigkeit',
    present: 'Neue Zuständigkeit',
    future: 'Neue Zuständigkeit',
    color: 'text-white bg-pink-500',
    icon: FlagIcon,
    endButtonColor:
      'from-pink-500 to-pink-600 hover:bg-gradient-to-r hover:from-pink-500 hover:to-pink-500 focus:ring-pink-500',
  },
  'responsibility-end': {
    past: 'Ende der Zuständigkeit',
    present: 'Ende der Zuständigkeit',
    future: 'Ende der Zuständigkeit',
    color: 'text-white bg-green-500',
    icon: LogoutIcon,
  },
  // This is just for awum. Awum doesn't show the 'responsibility-end' state but instead shows the 'end-of-stay' state if this type of info booking is available.
  'end-of-stay': {
    past: 'Aufenthalt beendet',
    present: 'Aufenthalt beendet',
    future: 'Aufenthalt beendet',
    color: 'text-white bg-green-500',
    icon: LogoutIcon,
  },
  // This state is added by the frontend if there is no current 'stay-terminated' and also no booking. It is only added for an ongoing unexplained time period in the present, NOT for unexplained time periods in the past!
  // It has the purpose to clearly show that the
  unknown: {
    past: '',
    present: 'Aktuell keine Belegung',
    future: '',
    color: 'text-white bg-red-500',
    icon: ExclamationIcon,
  },
  // This is not a booking state but it is used parallel to booking states all over the app
  // 'stay-terminated-over-age': { label: 'Aufenthalt wegen Volljährigkeit beendet', activeLabel: '', color: '', icon: LogoutIcon },
  // 'end-of-liability-to-charges': { label: 'Gebührenpflichtende', activeLabel: '', color: '', icon: LogoutIcon },
}

interface Props {
  className?: string
}

export const HouseBan: React.FC<Props> = (props) => (
  <HandIcon
    className={`${props.className || 'h-4 w-4'} bg-red-500 text-white rounded-full`}
  />
)
