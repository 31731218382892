import { Collection, hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Menu, Transition } from '@headlessui/react'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { InputCompound } from 'components/Form/components/InputCompound'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { action, makeObservable, when } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {
  model: Model<ITodoSearchResultFilter>
}

@observer
export class TicketAccommodationFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly buildings: Collection<IBuilding>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.buildings = new Collection(
      `/api/${context.instance.id}/accommodations/buildings`,
    )
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(this.buildings.init({ readOnly: true, observeQuery: true }))
    this.disposers.push(
      when(
        () => !!this.buildings.resources,
        () => {
          if (
            this.buildings.resources?.length === 1 &&
            this.props.model.values.buildingId === null
          ) {
            this.props.model.values.buildingId = this.buildings.resources[0].id
          }
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action private reset = () => {
    this.props.model.values.compoundId = null
    this.props.model.values.buildingId = null
  }

  render() {
    let label = 'Unterkunft'
    let color: string = 'text-gray-500'
    if (this.props.model.values.compoundId) {
      label =
        hermes.getFromStore<ICompound>(
          `/api/${this.context.instance.id}/accommodations/compounds/${this.props.model.values.compoundId}`,
          false,
        )?.label ?? 'Gewählte Unterkunft'
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='block flex-content mr-4 cursor-pointer'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-4 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputCompound
                model={this.props.model}
                name='compoundId'
                label='Gelände'
                disabled={false}
              />
              <InputBuilding
                model={this.props.model}
                name='buildingId'
                label='Gebäude'
                compoundId={this.props.model.values.compoundId}
                autoSelectOnlyItem={false}
              />
              <Menu.Item>
                <div className='flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
