import { waitForElementWithId } from 'helpers/waitForElementWithId'
import React from 'react'
import ReactDOM from 'react-dom'
import * as uuid from 'uuid'

export interface IPageElement {
  id: string
  jsx: JSX.Element
  height: number
  meta?: any
}

export interface IPage {
  elements: IPageElement[]
}

export class DocumentCreator {
  private readonly elements: IPageElement[] = []
  private readonly pageSize: { width: number; height: number }

  constructor(pageSize: { width: number; height: number }) {
    this.pageSize = pageSize
  }

  add(jsx: JSX.Element, meta?: any) {
    this.elements.push({ id: uuid.v4(), jsx, height: 0, meta })
  }

  render(elements?: IPageElement[]): JSX.Element {
    return (
      <React.Fragment>
        {(elements || this.elements).map((element, i) => (
          <div key={i} id={element.id}>
            {element.jsx}
          </div>
        ))}
      </React.Fragment>
    )
  }

  getPages(): IPage[] {
    const pages: IPage[] = []
    let page: IPage = { elements: [] }
    let pageHeight = 0
    for (const elem of this.elements) {
      if (pageHeight + elem.height > this.pageSize.height) {
        pages.push(page)
        page = { elements: [] }
        pageHeight = 0
      }
      page.elements.push(elem)
      pageHeight += elem.height
    }
    if (page.elements.length > 0) {
      pages.push(page)
    }
    return pages
  }

  async detectHeights() {
    if (this.elements.length === 0) {
      return []
    }
    // Render all elements to get their height
    const rootDiv = document.createElement('div')
    rootDiv.style.width = `${this.pageSize.width}px`
    document.getElementsByTagName('body')[0].appendChild(rootDiv)
    ReactDOM.render(
      <React.Fragment>
        {this.elements.map((element, i) => (
          <div key={i} id={element.id}>
            {element.jsx}
          </div>
        ))}
      </React.Fragment>,
      rootDiv,
    )

    // Get the height of each element
    await waitForElementWithId(this.elements[0].id, 400)
    for (const elem of this.elements) {
      const node: HTMLDivElement = document.getElementById(elem.id) as any
      if (!node) {
        throw new Error(`Element with id '${elem.id}' not found`)
      }
      elem.height = node.offsetHeight
    }
    console.log('detected heights', this.elements)

    // Remove the root div
    ReactDOM.unmountComponentAtNode(rootDiv)
    rootDiv.remove()
  }
}
