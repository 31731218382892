import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from 'components/Form/Model'
import { getInputWidth } from '../helpers/getInputWidth'

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  readOnly: boolean
  className?: string
  inputClassName?: string
  style?: any
  children?: Element
  minWidth?: number
  maxWidth?: number
}

@observer
export class PaperInputText extends React.Component<Props, {}> {
  private readonly cache = {
    value: '148f182a-0281-41a6-97cf-d9870f50a675',
    className: '',
    width: 0,
  }
  private get className(): string {
    return `block w-full text-base focus:bg-white border-0 px-1 py-[2px] focus:ring-0 ${
      this.props.readOnly ? 'bg-white' : 'bg-gray-100'
    } ${this.props.inputClassName || ''}`
  }

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private onChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (!(event.target instanceof HTMLInputElement)) {
      return
    }
    const target: HTMLInputElement = event.target
    const value: string = target.value
    const width = getInputWidth(this.cache, this.className, value)

    if (this.props.maxWidth) {
      // Discard event if it would make the input wider than max width
      if (width > this.props.maxWidth) {
        return
      }
    } else {
      // Discard event if it would make the text wider than the input field
      const rect = target.getBoundingClientRect()
      if (width > rect.width) {
        return
      }
    }

    this.props.model.values[this.props.name] = value
    this.props.onChange?.(event)
  }

  render() {
    const {
      name,
      model,
      label,
      className,
      inputClassName,
      style,
      children,
      minWidth,
      maxWidth,
      ...attributes
    } = this.props

    if (this.props.readOnly) {
      attributes.placeholder = ''
    }
    const textOrPlaceholder = model.values[name] || attributes.placeholder || ''

    return (
      <div className={className}>
        <input
          type='text'
          className={this.className}
          style={{
            width:
              maxWidth && getInputWidth(this.cache, this.className, textOrPlaceholder),
            minWidth,
            ...style,
          }}
          maxLength={255}
          {...attributes}
          name={name}
          onChange={this.onChange}
          value={model.values[name]}
          autoComplete='off'
        />
        {children}
      </div>
    )
  }
}
