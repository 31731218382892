import { hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { XIcon } from '@heroicons/react/outline'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputDocument } from 'components/Form/components/InputDocument'
import { InputText } from 'components/Form/components/InputText'
import { Message } from 'components/Message'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { z } from 'zod'
// import { InputButtonGroup } from 'components/Form/components/InputButtonGroup'

interface Props {
  onClose: (id?: string) => void
}

/* const scopeOptions = [
  { value: 'residents', label: 'Bewohner' },
  { value: 'rooms', label: 'Räume' },
]*/

const Validator = z.object({
  documentId: z.string().min(1),
  label: z.string().min(1),
})

@observer
export class MigrationFileUploadDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{
    documentId: string
    scope: 'residents' | 'rooms'
    label: string
  }>
  private readonly disposers: Disposer[] = []
  @observable private saving = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({ documentId: '', scope: 'residents', label: '' }, Validator)
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => this.model.values.documentId,
        (documentId) => {
          if (!documentId) {
            return
          }
          hermes
            .getOnceNew<IDocumentMetadata>(
              `/api/${this.context.instance.id}/documents/metadata/${documentId}`,
            )
            .then((doc) =>
              runInAction(
                () => (this.model.values.label = doc.name.replace(/\.[^/.]+$/, '')),
              ),
            )
        },
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private save = async () => {
    runInAction(() => (this.model.values.label = this.model.values.label.trim()))
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }

    try {
      runInAction(() => (this.saving = true))
      const { id } = await hermes.create(`/api/${this.context.instance.id}/imports`, {
        documentId: this.model.values.documentId,
        label: this.model.values.label,
        scope: this.model.values.scope,
      })
      this.props.onClose(id)
    } catch (_e) {
      runInAction(() => {
        this.error = 'Beim Erstellen der Migration ist ein Fehler aufgetreten.'
        this.saving = false
      })
    }
  }

  render() {
    return (
      <div id={this.model.id}>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div>
          <h3 className='text-gray-500 text-xl'>Migrationsdatei hochladen</h3>
          <div className='mt-4'>
            {!this.error && (
              <Message className='my-4' color='primary'>
                Laden Sie Ihre Migrationsdatei im CSV-Format hoch.
              </Message>
            )}
            {this.error && (
              <Message className='my-4' color='danger'>
                {this.error}
              </Message>
            )}
            {/*<InputButtonGroup
              className='mb-2'
              model={this.model}
              name='scope'
              label='Scope'
              options={scopeOptions}
            />*/}
            <InputDocument
              model={this.model}
              name='documentId'
              scope='migration file'
              label='CSV'
              accept='.csv'
            />

            <InputText
              className='mt-4'
              model={this.model}
              name='label'
              label='Bezeichnung'
            />
          </div>
          <div className='mt-4 float-right'>
            <Button
              className='mr-2'
              color='secondary'
              outline
              onClick={() => this.props.onClose()}
            >
              Abbrechen
            </Button>
            <Button onClick={this.save} disabled={this.saving}>
              Speichern
            </Button>
          </div>
        </div>
        {this.saving && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}
