import { Collection, Resource } from '@byll/hermes'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Callout } from 'components/Callout'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { Spinner } from 'components/Spinner'
import { IEmployeeCommThread } from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { NotFoundError } from 'contracts/errors/HermesErrors'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { EmployeeSalarySheetPreview } from '../EmployeeSalarySheetPreview'
import { RerunSalarySheetCheck } from './components/RerunSalarySheetCheck'
import { IEmployeeCommTopic } from 'contracts/employeeComm/interfaces/IEmployeeCommTopic'
import { isValidSalarySlip } from 'contracts/employeeComm/helpers/isValidSalarySlip'
import { Button } from 'components/Form/components/Button'
import { SendAllValidSalarySheets } from './components/SendAllValidSalarySheets'
import { box } from 'services/box'

const statusMap = {
  sent: { color: 'bg-blue-500 text-white', text: 'Versendet' },
  'send error': { color: 'bg-red-500 text-white', text: 'Versandfehler' },
  received: { color: 'bg-green-500 text-white', text: 'Empfangen' },
}

interface Props {
  topicId: string
  threadId: string | null
  onSelect: (threadId: string) => void
}

@observer
export class EmployeeCommunicationContent extends React.Component<Props, {}> {
  static contextType = AppContext
  private topic: Resource<IEmployeeCommTopic>
  private threads: Collection<IEmployeeCommThread>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.topic = new Resource(
      `/api/${context.instance.id}/employeeComm/topics/${props.topicId}`,
    )
    this.threads = new Collection(
      `/api/${context.instance.id}/employeeComm/topics/${props.topicId}/threads`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.topic.init({ readOnly: true }))
    this.disposers.push(this.threads.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private sendAll = async () => {
    const salarySheets =
      this.threads.resources?.map((r) => r.data!).filter((t) => t?.status === 'draft') ??
      []
    if (salarySheets.length === 0) {
      alert('Es liegen keine Gehaltszettel zum Versenden vor.')
      return
    }
    if (!salarySheets.some((s) => isValidSalarySlip(s.extra))) {
      alert('Es befinden sich keine versandfertigen Gehaltszettel in der Liste.')
      return
    }
    const promise = box.custom(
      <SendAllValidSalarySheets
        onClose={() => promise.close()}
        salarySheets={salarySheets}
      />,
      { context: this.context },
    )
  }

  render() {
    if (this.threads.error) {
      const error = this.threads.error?.id || ''
      return (
        <div className='flex-auto relative'>
          <div className='absolute left-6 right-6 bottom-6 top-6 overflow-x-hidden overflow-y-auto flex'>
            {error === RequestPendingError.id && <Spinner delay />}
            {error === NotFoundError.id && (
              <Callout
                title='Nachricht nicht gefunden'
                icon='far fa-lemon text-yellow-400'
              />
            )}
            {error !== RequestPendingError.id && error !== NotFoundError.id && (
              <LoadingError title='Nachrichten konnten nicht geladen werden' />
            )}
          </div>
        </div>
      )
    }

    if (!this.threads.resources || this.threads.resources.length === 0) {
      return (
        <div className='flex-auto relative'>
          <div className='absolute left-6 right-6 bottom-6 top-6 overflow-x-hidden overflow-y-auto flex'>
            <Callout
              title='Keine Gehaltszettel vorhanden'
              subtitle='Klicken Sie auf den Button "Neu", um Gehaltszettel hochzuladen.'
              icon='fas fa-folder-open'
            />
          </div>
        </div>
      )
    }

    return (
      <>
        <div className='flex-auto relative'>
          <div className='absolute left-6 right-6 top-10 text-gray-500 text-sm'>
            {this.topic.data?.label || 'Gehaltszettel'}{' '}
            {this.threads.resources && (
              <Button
                style={{ padding: '0 4px', margin: '0 8px' }}
                color='secondary'
                outline
                onClick={this.sendAll}
              >
                Sammelversand
              </Button>
            )}
            <RerunSalarySheetCheck topicId={this.props.topicId} />
          </div>

          <div className='absolute left-6 right-6 bottom-6 top-[72px] overflow-x-hidden overflow-y-auto'>
            {this.threads.resources.length > 0 && (
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                {this.threads.resources.map((t) => {
                  if (!t.data) {
                    return null
                  }
                  const valid = isValidSalarySlip(t.data.extra)
                  return (
                    <div
                      key={t.id}
                      className={`overflow-hidden bg-white rounded-md shadow p-2 h-[70px] border-2 cursor-pointer ${
                        this.props.threadId === t.id
                          ? 'border-indigo-500'
                          : 'border-white'
                      }`}
                      onClick={() => this.props.onSelect(t.id)}
                    >
                      <div className='truncate'>
                        {t.data.employee.firstName} {t.data.employee.lastName}
                      </div>
                      {t.data.status === 'draft' && (
                        <div className='truncate'>
                          {!valid && (
                            <span className='inline-flex items-center rounded-md bg-red-500 px-2 py-1 text-xs font-medium text-white'>
                              Nicht versandfertig
                            </span>
                          )}
                          {valid && (
                            <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'>
                              Versandfertig
                            </span>
                          )}
                        </div>
                      )}
                      {t.data.status !== 'draft' && (
                        <div className='truncate'>
                          <span
                            className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${statusMap[
                              t.data.status
                            ]?.color}`}
                          >
                            {statusMap[t.data.status]?.text}
                          </span>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        <div className='flex-[0_0_864px] relative hidden xxl:block'>
          {this.props.threadId && (
            <EmployeeSalarySheetPreview
              key={`${this.props.topicId}/${this.props.threadId}`}
              topicId={this.props.topicId}
              threadId={this.props.threadId}
            />
          )}
        </div>
      </>
    )
  }
}
