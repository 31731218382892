import * as React from 'react'
import { Callout } from '../index'

export class LoadingError extends React.Component<
  { title: string; subtitle?: string },
  {}
> {
  render() {
    return (
      <Callout
        icon='fas fa-exclamation-triangle'
        iconColor='#ef4444'
        title={this.props.title}
        subtitle={this.props.subtitle}
      />
    )
  }
}
