import * as React from 'react'
import { observer } from 'mobx-react'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { action, makeObservable } from 'mobx'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'

interface Props {
  selected: Map<string, boolean>
  building: IBuilding
}

@observer
export class BuildingsSelect extends React.Component<Props, {}> {
  static contextType = AppContext

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private changeHandler = () => {
    if (this.props.selected.get(this.props.building.id)) {
      this.props.selected.delete(this.props.building.id)
    } else {
      this.props.selected.set(this.props.building.id, true)
    }
  }

  render() {
    const compound: ICompound | null = hermes.getFromStore(
      `/api/${this.context.instance.id}/accommodations/compounds/${this.props.building.compoundId}`,
      false,
    )
    if (!compound) {
      return null
    }
    return (
      <div className='relative flex items-center'>
        <input
          className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
          type='checkbox'
          checked={!!this.props.selected.get(this.props.building.id)}
          onChange={this.changeHandler}
        />
        <label className='ml-2 block text-sm text-gray-900'>
          {compound.label === this.props.building.label
            ? compound.label
            : `${compound.label}/${this.props.building.label}`}
        </label>
      </div>
    )
  }
}
