import { Collection, hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Callout } from 'components/Callout'
import { IBuildingFloor } from 'contracts/accommodations/interfaces/IBuildingFloor'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { isStammBuilding } from 'helpers/isStamm'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { BuildingFloorPlanSidebar } from './BuildingFloorPlanSidebar'
import { BuildingFloorRoomOverlay } from './BuildingFloorRoomOverlay'

interface Props {
  compoundId: string
  buildingId: string
  floorId: string
  at: string
}

@observer
export class BuildingFloorPlan extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly rooms: Collection<
    IRoom,
    {},
    { compoundId: string; buildingId: string }
  >
  private readonly floors: Collection<IBuildingFloor>
  private readonly disposers: Disposer[] = []
  @observable private occupancies: Map<string, IOccupancy> | null = null
  @observable private dragMode = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.rooms = new Collection(`/api/${context.instance.id}/accommodations/rooms`, {
      compoundId: props.compoundId,
      buildingId: props.buildingId,
    })
    this.floors = new Collection(`/api/${context.instance.id}/accommodations/floors`, {
      buildingId: props.buildingId,
    })
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.rooms.init({ readOnly: true }))
    this.disposers.push(this.floors.init())
    window.addEventListener('keydown', this.keyDown)
    window.addEventListener('keyup', this.keyUp)
    this.disposers.push(() => window.removeEventListener('keydown', this.keyDown))
    this.disposers.push(() => window.removeEventListener('keyup', this.keyUp))
    void this.getOccupancies()
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private getOccupancies = async () => {
    const data = await hermes.indexOnceNew<IOccupancy>(
      `/api/${this.context.instance.id}/accommodations/occupancies?compoundId=${
        this.props.compoundId
      }${
        this.props.buildingId ? `&buildingId=${this.props.buildingId}` : ''
      }&queryAt=${encodeURIComponent(this.props.at)}`,
    )
    const occupancies = new Map<string, IOccupancy>()
    for (const o of data) {
      occupancies.set(o.id, o)
    }
    runInAction(() => (this.occupancies = occupancies))
  }

  @action private keyDown = (e) => {
    if (e.keyCode === 16) {
      if (
        this.context.permissions.asset_manageFloors === 0 ||
        (this.context.permissions.asset_manageFloors === 1 &&
          !isStammBuilding(this.props.buildingId))
      ) {
        // No permission for drag mode
        return
      }
      this.dragMode = true
    }
  }

  @action private keyUp = (e) => {
    if (e.keyCode === 16) {
      this.dragMode = false
    }
  }

  render() {
    const floor = this.floors.resources
      ? hermes.getFromStore<IBuildingFloor>(
          `/api/${this.context.instance.id}/accommodations/floors/${this.props.floorId}`,
          false,
        )
      : null
    const at = dayjs(this.props.at === 'now' ? undefined : this.props.at)
    const dragMode = floor?.documentId && floor.legend.image ? this.dragMode : false
    const plan =
      floor?.documentId && floor.legend.image
        ? `/api/${this.context.instance.id}/documents/files/${floor.documentId}`
        : null
    const allowPlanEdit =
      this.context.permissions.asset_manageFloors === 2 ||
      (this.context.permissions.asset_manageFloors === 1 &&
        isStammBuilding(this.props.buildingId))

    return (
      <>
        <div
          className={`absolute top-0 left-6 right-6 md:right-[290px] bottom-4 rounded-lg shadow bg-white overflow-auto select-none ${
            dragMode
              ? 'border-2 border-blue-500 border-dashed'
              : 'border-2 border-transparent'
          }`}
        >
          {!floor?.documentId && (
            <div className='absolute top-0 left-0 right-0 bottom-0 flex'>
              <Callout
                className='my-auto'
                icon='fas fa-image'
                iconColor='rgb(209,213,219)'
                title='Grundriss liegt noch nicht vor'
                subtitle='Upload in der rechten Seitenleiste möglich'
              />
            </div>
          )}
          {plan && (
            <img
              alt='Grundriss'
              width={`${floor?.legend.image?.width}px`}
              height={floor?.legend.image?.height}
              className='absolute top-0 left-0'
              style={{ maxWidth: 'initial' }}
              src={plan}
            />
          )}
          {plan && this.rooms.resources && floor?.legend && this.occupancies && (
            <BuildingFloorRoomOverlay
              floor={floor}
              occupancies={this.occupancies}
              at={at}
            />
          )}
        </div>
        {floor && this.rooms.resources && this.occupancies && (
          <BuildingFloorPlanSidebar
            key={floor.id}
            floor={floor}
            rooms={this.rooms}
            occupancies={this.occupancies}
            dragMode={dragMode}
            allowPlanEdit={allowPlanEdit}
            at={this.props.at === 'now' ? undefined : at}
          />
        )}
        {(!floor || !this.rooms.resources || !this.occupancies) && (
          <div className='hidden md:block absolute top-0 right-6 w-[250px] bottom-4 rounded-lg shadow bg-white' />
        )}
      </>
    )
  }
}
