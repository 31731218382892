import { IImageSection } from './getImageSection'

export function getSectionFromStyle(
  style: { left: number; top: number; width: number; height: number },
  original: { width: number; height: number },
  target: { width: number; height: number },
): IImageSection {
  const scale = original.width / style.width
  return {
    x: Math.round(-style.left * scale),
    y: Math.round(-style.top * scale),
    width: Math.round(target.width * scale),
    height: Math.round(target.height * scale),
  }
}
