import * as React from 'react'
import styles from './styles.module.scss'

interface Props {
  offset?: number
  position?: 'left' | 'top' | 'right' | 'bottom'
}

export const Tooltip: React.FC<Props> = (props) => {
  return (
    <>
      <span className={styles.triangle + ' ' + styles[props.position || 'top']} />
      <div
        className={styles.tooltip + ' ' + styles[props.position || 'top']}
        style={
          props.offset ? { transform: `translate(${props.offset}px, 0)` } : undefined
        }
      >
        {props.children}
      </div>
    </>
  )
}
