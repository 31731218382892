import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  label: string
  icon: string
  onSort: (label: string) => void
}

@observer
export class LeaInactiveListSearchCaption extends React.Component<Props, {}> {
  render() {
    return (
      <div>
        {this.props.label}
        <div
          className='cursor-pointer inline-block ml-1'
          onClick={() => this.props.onSort(this.props.label)}
        >
          <i style={{ fontSize: 13 }} className={this.props.icon} />
        </div>
      </div>
    )
  }
}
