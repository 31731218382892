import * as React from 'react'
import styles from './styles.module.scss'
import { MAX_FILE_SIZE_IN_MB } from 'modules/Documents/components/DocumentDropzone'
import { AppContext } from 'services/connection/models/AppContext'
import { uniqueId } from 'helpers/uniqueId'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import axios, { AxiosProgressEvent } from 'axios'
import { TransferState } from 'components/Form/components/InputImage/components/UploadStripe'
import { IAttachment } from 'contracts/general/interfaces/IAttachment'
import { dayjs } from 'helpers/dayjs'
import { getDiskSizeLabel } from 'contracts/general/helpers/getDiskSizeLabel'
import { getFileExtensionIcon } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/helpers/getFileExtensionIcon'
import { RoundIcon } from 'components/RoundIcon'
const trashStyle = {
  position: 'absolute',
  top: 21,
  right: 21,
  background: '#FD2547',
  color: 'white',
}

interface Props {
  scope:
    | 'employee'
    | 'booking'
    | 'cost coverage'
    | 'cost coverage signature'
    | 'resident documentation'
  onUploaded: (attachment: IAttachment) => void
  onSelect?: () => boolean // cancel file selection if it returns with true
  accept?: string
  disabled?: boolean
  metadata?: { documentationId?: string } // Object containing metadata that should be transfered in addition to document
}

@observer
export class AttachmentUpload extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  private cancelUpload: (() => void) | null = null
  private mounted = true
  @observable private upload: TransferState | null = null
  @observable.ref attachment: IAttachment | null = null

  constructor(props: Props) {
    super(props)
    this.id = uniqueId('upload-')
    makeObservable(this)
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancelUpload?.()
  }

  private triggerFileSelect = () => {
    if (this.props.disabled) {
      window.alert('Sie haben nicht die nötige Berechtigung für einen Dateiupload.')
      return
    }
    if (this.attachment || this.props.onSelect?.()) {
      return
    }
    document.getElementById(this.id + '-input')?.click()
  }

  private onSelectFile = async (e) => {
    if (this.attachment) {
      return
    }
    const file = e.target.files[0]
    const name = file.name
    const form = new FormData()
    form.append('file', file)
    form.append('scope', this.props.scope)
    form.append('name', name)
    if (this.props.metadata) {
      for (const key of Object.keys(this.props.metadata)) {
        form.append(key, this.props.metadata[key])
      }
    }

    const source = axios.CancelToken.source()
    this.cancelUpload = () => source.cancel('Canceled by user')
    runInAction(() => {
      this.upload = { progress: 0.05, type: 'uploading' }
      this.attachment = {
        id: '',
        name,
        size: String(file.size),
        createdAt: new Date().toISOString(),
        uploadUser: this.context.user.firstName + ' ' + this.context.user.lastName,
      }
    })
    const uploadPromise = axios.post(
      `/api/${this.context.instance.id}/documents/files`,
      form,
      {
        cancelToken: source.token,
        onUploadProgress: action((event: AxiosProgressEvent) => {
          if (event.total && event.loaded / event.total <= 1 && this.upload) {
            // Start at 5% to visualize pending upload for files that are scheduled to upload.
            this.upload.progress = 0.05 + 0.95 * (event.loaded / event.total)
          }
        }),
      },
    )
    try {
      const response: any = await uploadPromise
      if (!this.mounted) {
        return
      }
      runInAction(() => {
        if (this.upload) {
          this.upload.progress = 1
          this.upload.type = 'uploaded'
        }
        this.attachment!.id = response.data.id
        this.props.onUploaded(this.attachment!)
      })
    } catch (e) {
      runInAction(() => (this.upload = null))
      if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
        // Already handeled
      } else {
        alert('Beim Upload ist ein Fehler aufgetreten.')
      }
    }

    this.cancelUpload = null
    const input: HTMLInputElement | null = document.getElementById(
      this.id + '-input',
    ) as any
    if (input) {
      input.value = ''
    }
    runInAction(() => (this.attachment = null))
  }

  render() {
    const { accept, children } = this.props
    return (
      <div onClick={this.triggerFileSelect} className='relative'>
        {/* Select file */}
        <div className='flex bg-white shadow-md h-[70px] cursor-pointer mt-4'>
          <div className='flex-[0_0_70px] bg-center'>
            {!this.attachment && <div className={styles.drop} />}
            {this.attachment && (
              <img
                src={getFileExtensionIcon(this.attachment.name)}
                alt=''
                style={{ height: 50, margin: 10 }}
              />
            )}
          </div>
          <div className='flex-auto'>
            <div className='flex'>
              {!this.attachment && <div className={styles.documentName}>{children}</div>}
              {this.attachment && (
                <>
                  <div className={styles.documentName} style={{ maxWidth: 400 }}>
                    {this.attachment.name}
                  </div>
                  <div className='flex-auto' style={{ padding: '13px 13px 0 10px' }}>
                    <div
                      className='bg-blue-500 rounded-md ease-in-out duration-200 transition-width '
                      style={{
                        height: 24,
                        width: `${(this.upload?.progress || 0) * 100}%`,
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {!this.attachment && (
              <div className={`text-gray-400 text-sm ${styles.documentDetails}`}>
                {`Die maximale Dateigröße pro Dokument beträgt ${MAX_FILE_SIZE_IN_MB} MB`}
              </div>
            )}
            {this.attachment && (
              <div className={`text-gray-400 text-sm truncate ${styles.documentDetails}`}>
                {getDiskSizeLabel(this.attachment.size)}
                &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                {dayjs(this.attachment.createdAt).format('DD.MM.YYYY')}
                &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                {this.attachment.uploadUser}
              </div>
            )}
          </div>
        </div>

        {this.attachment && (
          <RoundIcon
            tooltip='Upload abbrechen'
            icon='fas fa-times'
            onClick={(event) => {
              event.stopPropagation()
              this.cancelUpload?.()
            }}
            style={trashStyle}
          />
        )}

        <input
          id={this.id + '-input'}
          type='file'
          className='hidden'
          multiple={false}
          onChange={this.onSelectFile}
          accept={accept}
        />
      </div>
    )
  }
}
