import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Message } from 'components/Message'
import { makeObservable, observable } from 'mobx'
import * as React from 'react'
import { Button } from '../../Button'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Model } from 'components/Form/Model'
import { InputText } from '../../InputText'

interface Props {
  model: Model<{ label: string }>
  onClose: (id?: string) => void
}

export class ShortcutCreateDialog extends React.Component<Props, {}> {
  @observable private loading = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private addShortcut = () => {
    this.props.onClose(this.props.model.values.label)
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Kurzwahl erstellen
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        <div className='mt-6'>
          <div className='mb-4 text-gray-600'>
            Erstellen Sie eine Kurzwahl der aktuell ausgewählten Gebäude mit der
            Bezeichnung:
          </div>
          <InputText model={this.props.model} name='label' label='Bezeichnung' />
        </div>

        <div className='relative mt-4'>
          <div className='mt-4 text-right'>
            <Button
              disabled={this.loading}
              color='secondary'
              outline
              onClick={() => this.props.onClose()}
            >
              Abbrechen
            </Button>
            <Button disabled={this.loading} className='ml-3' onClick={this.addShortcut}>
              Erstellen
            </Button>
          </div>
        </div>
        {this.loading && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
