import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { Message } from 'components/Message'
import { Button } from 'components/Form/components/Button'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { download } from 'helpers/download'
import { Model } from 'components/Form/Model'
import { InputCompound } from 'components/Form/components/InputCompound'
import { DocumentationExportDocuments } from './DocumentationExportDocuments'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { Tooltip } from 'components/Tooltip'
import { isStammBuilding } from 'helpers/isStamm'

interface Props {
  resident: IResident
  onClose: () => void
}

@observer
export class DocumentationExport extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ compoundId: string | null; family: 'yes' | 'no' }>
  @observable private step: 'select compound' | 'done' = 'select compound'
  @observable private token: string | null = null // concatenated pdf id (for download)

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      compoundId: context.defaults.responsibilityCompoundId,
      family: 'yes',
    })
    if (
      context.permissions.resident_documentation < 2 &&
      !isStammBuilding(context.defaults.responsibilityCompoundId || '')
    ) {
      this.model.values.compoundId = null
    }
    makeObservable(this)
  }

  @action private setToFamily = () => {
    this.model.values.family = 'yes'
  }

  @action private setToResident = () => {
    this.model.values.family = 'no'
  }

  @action private setStepDone = (token: string) => {
    this.step = 'done'
    this.token = token
  }

  private onDownload = () => {
    if (!this.token) {
      return
    }
    download(
      `/api/${this.context.instance.id}/download/files/${
        this.token
      }?fileName=${encodeURIComponent('Export Bewohnerdokumentation.pdf')}`,
    )
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Export Dokumentationen
            </Dialog.Title>
          </div>
        </div>

        {this.step === 'done' && (
          <>
            <div className='relative mt-4 h-[200px] flex flex-col'>
              <Message className='flex-content' color='primary'>
                Der Export wurde erstellt. Klicken Sie auf "Download", um ihn
                herunterzuladen.
              </Message>
              <div className='flex-auto' />
            </div>

            <div className='mt-4 text-right flex-content'>
              <Button color='secondary' outline onClick={this.props.onClose}>
                Schließen
              </Button>
              <Button color='success' className='ml-3' onClick={this.onDownload}>
                Download
              </Button>
            </div>
          </>
        )}

        {this.step === 'select compound' && (
          <div className='relative mt-4'>
            <div className='flex gap-4' id={this.model.id}>
              <InputCompound
                className='flex-auto'
                model={this.model}
                name='compoundId'
                label='Einrichtung'
                onlyStamm={this.context.resident_documentation < 2}
                saveResponsibility
              />
              <div className='flex-content'>
                <Button
                  color='secondary'
                  className='has-tooltip'
                  outline={this.model.values.family === 'no'}
                  style={{ borderRadius: '6px 0 0 6px' }}
                  onClick={this.setToFamily}
                >
                  Familie
                  <Tooltip>
                    Dokumentation der gesamten
                    <br />
                    Familie exportieren
                  </Tooltip>
                </Button>
                <Button
                  className='has-tooltip'
                  color='secondary'
                  outline={this.model.values.family === 'yes'}
                  style={{ borderRadius: '0 6px 6px 0' }}
                  onClick={this.setToResident}
                >
                  Bewohner
                  <Tooltip>
                    Dokumentation des ausgewälten
                    <br />
                    Bewohners exportieren
                  </Tooltip>
                </Button>
              </div>
            </div>
            {this.model.values.compoundId && (
              <DocumentationExportDocuments
                key={`${this.model.values.compoundId}.${this.props.resident.id}.${this.model.values.family}`}
                compoundId={this.model.values.compoundId}
                residentId={this.props.resident.id}
                family={this.model.values.family}
                onClose={this.props.onClose}
                setStepDone={this.setStepDone}
              />
            )}
            {!this.model.values.compoundId && (
              <>
                <div className='min-h-[200px] pt-4'>
                  <Message color='primary'>
                    Bitte wählen Sie eine Einrichtung aus.
                  </Message>
                </div>
                <div className='-mb-4 -mx-6 px-6 text-right sticky bottom-0 z-1 py-4 bg-white border-t border-gray-200'>
                  <Button color='secondary' outline onClick={this.props.onClose}>
                    Abbrechen
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </>
    )
  }
}
