import * as React from 'react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Dialog } from '@headlessui/react'
import { AnnotationIcon } from '@heroicons/react/outline'
import { AppContext } from 'services/connection/models/AppContext'
import { InputText } from '../../InputText'
import { Model } from 'components/Form/Model'
import { InputForm } from '../../InputForm'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Message } from 'components/Message'
import { hermes } from '@byll/hermes'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { z } from 'zod'
import { InputCompound } from '../../InputCompound'
import { Button } from '../../Button'
import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { Wait } from 'modules/Pdf/components/Wait'
import { box } from 'services/box'
import { DeleteScanningStationPrompt } from './DeleteScanningStationPrompt'

interface Props {
  onClose: (id?: string) => void
  type: 'gate' | 'canteen'
  station?: IScanningStation // if set: edit mode. If not set: create mode
}

const titleDict = {
  newgate: 'Neue Pforte erstellen',
  newcanteen: 'Neue Essensausgabestelle erstellen',
  editgate: 'Pforte bearbeiten',
  editcanteen: 'Essensausgabestelle bearbeiten',
}

@observer
export class ScanningStationDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{
    name: string
    compoundId: string | null
    entrance: string
  }>
  @observable private loading = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    this.model = new Model(
      {
        name: props.station?.name ?? '',
        compoundId: props.station?.compoundId ?? null,
        entrance: props.station?.entrance ?? 'right',
      },
      z.object({ name: z.string().min(1), compoundId: z.string() }),
    )
    makeObservable(this)
  }

  private onSubmit = async () => {
    runInAction(() => (this.model.values.name = this.model.values.name.trim()))
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    try {
      if (this.props.station) {
        const response = await hermes.patch(
          `/api/${this.context.instance.id}/scan/scanningStations/${this.props.station.id}`,
          {
            name: this.model.values.name,
            entrance: this.model.values.entrance,
          },
        )
        this.props.onClose(response.id)
      } else {
        const response = await hermes.create(
          `/api/${this.context.instance.id}/scan/scanningStations`,
          {
            type: this.props.type,
            name: this.model.values.name,
            compoundId: this.model.values.compoundId,
            entrance: this.model.values.entrance,
          },
        )
        this.props.onClose(response.id)
      }
    } catch (e: any) {
      runInAction(() => {
        this.loading = false
        this.error =
          e?.id === ConflictError.id
            ? e.message
            : 'Beim Speichern ist ein Fehler aufgetreten.'
      })
    }
  }

  private deleteScanningStation = async () => {
    if (!this.props.station) {
      return
    }
    if (
      this.props.type === 'gate' &&
      !this.context.permissions.resident_barcodeVisitTracking_deleteGate
    ) {
      alert('Sie haben nicht die nötige Berechtigung, um diese Pforte zu löschen.')
      return
    }
    if (
      this.props.type === 'canteen' &&
      !this.context.permissions.resident_barcodeMealTracking_deleteCanteen
    ) {
      alert('Sie haben nicht die nötige Berechtigung, um diese Ausgabestelle zu löschen.')
      return
    }
    const promise = box.custom(
      <DeleteScanningStationPrompt
        type={this.props.type}
        station={this.props.station}
        onClose={() => promise.close()}
      />,
      { context: this.context },
    )
  }

  @action private setEntranceLeft = () => (this.model.values.entrance = 'left')
  @action private setEntranceRight = () => (this.model.values.entrance = 'right')

  render() {
    return (
      <>
        <div className='sm:flex sm:items-start'>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-indigo-100`}
          >
            <AnnotationIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />
          </div>
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-auto'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {titleDict[`${this.props.station ? 'edit' : 'new'}${this.props.type}`]}
            </Dialog.Title>

            {this.error && (
              <Message color='danger' className='mt-4'>
                {this.error}
              </Message>
            )}

            <InputForm onSubmit={this.onSubmit} id={this.model.id}>
              <div className='my-2 text-sm text-gray-500'>
                <InputCompound
                  className='mt-6'
                  model={this.model}
                  name='compoundId'
                  label='Gelände'
                  disabled={this.loading || !!this.props.station}
                />
                <InputText
                  className='mt-4'
                  model={this.model}
                  name='name'
                  label='Bezeichnung'
                  disabled={this.loading}
                  placeholder='Name'
                />
                {this.props.type === 'gate' && (
                  <div className='mt-4 flex'>
                    <Button
                      type='button'
                      onClick={this.setEntranceLeft}
                      className='flex-auto'
                      color={
                        this.model.values.entrance === 'left' ? 'primary' : 'secondary'
                      }
                      outline={this.model.values.entrance !== 'left'}
                      style={{ borderRadius: '6px 0 0 6px' }}
                    >
                      <i className='fas fa-arrow-left' /> Eingang ist links
                    </Button>
                    <Button
                      type='button'
                      onClick={this.setEntranceRight}
                      className='flex-auto'
                      color={
                        this.model.values.entrance === 'left' ? 'secondary' : 'primary'
                      }
                      outline={this.model.values.entrance === 'left'}
                      style={{ borderRadius: '0 6px 6px 0' }}
                    >
                      Eingang ist rechts <i className='fas fa-arrow-right' />
                    </Button>
                  </div>
                )}
              </div>
            </InputForm>
          </div>
        </div>
        <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
          <button
            type='button'
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500`}
            onClick={this.onSubmit}
          >
            {this.props.station ? 'Speichern' : 'Erstellen'}
          </button>
          <button
            type='button'
            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
            onClick={() => this.props.onClose()}
          >
            Abbrechen
          </button>
          {this.props.station && (
            <Button
              color='danger'
              className='mr-auto ml-14'
              onClick={this.deleteScanningStation}
            >
              <span>
                <i className='fas fa-trash' />
              </span>
            </Button>
          )}
          <Wait />
        </div>
        {this.loading && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
