import * as React from 'react'
import { action, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from '../../Model'
import { classNames } from 'helpers/classNames'
import { hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { isStammCompound } from 'helpers/isStamm'

/**
 * Checkbox list of compounds. Multiple compounds can be selected and will be
 * stored in the model as a comma-separated string of compound ids.
 */

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  className?: string
  onlyStamm?: boolean // Show only stamm compounds
  filter?: (compound: ICompound) => boolean
  at?: string | null // ISO date. All compounds that are either not archived or archived after .at are shown. If .at is missing, archived compounds are not shown.
}

@observer
export class InputCompoundList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly compounds: ICompound[]
  private readonly disposers: Disposer[] = []
  @observable private readonly selected = new Set<string>() // Ids of selected compounds

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.compounds =
      hermes.indexFromStore<ICompound>(
        `/api/${context.instance.id}/accommodations/compounds`,
      ) ?? []
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.props.model.values[this.props.name],
        (value: string) => {
          this.selected.clear()
          const compoundIds = value?.split(',').filter(Boolean) ?? []
          for (const id of compoundIds) {
            this.selected.add(id)
          }
        },
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private isIncluded = (compound: ICompound): boolean => {
    if (compound.deletedAt && (!this.props.at || compound.deletedAt <= this.props.at)) {
      return false
    }
    if (this.props.filter && !this.props.filter(compound)) {
      return false
    }
    if (
      this.props.onlyStamm &&
      !this.selected.has(compound.id) &&
      !isStammCompound(compound.id)
    ) {
      return false
    }
    return true
  }

  private compoundMapper = (compound: ICompound) => {
    if (!this.isIncluded(compound)) {
      return null
    }
    return (
      <div key={compound.id} className='relative flex items-start mb-0.5'>
        <input
          type='checkbox'
          className='mt-0.5 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
          checked={!!this.selected.has(compound.id)}
          onChange={() => this.toggle(compound)}
        />
        <label className='ml-2 block text-sm text-gray-900'>{compound.label}</label>
      </div>
    )
  }

  @action
  private toggle = (compound: ICompound) => {
    const compoundIds: string[] =
      this.props.model.values[this.props.name]?.split(',').filter(Boolean) ?? []
    const index = compoundIds.indexOf(compound.id)
    if (index === -1) {
      compoundIds.push(compound.id)
    } else {
      compoundIds.splice(index, 1)
    }
    this.props.model.values[this.props.name] = compoundIds.join(',')
  }

  @action
  private selectAll = () => {
    this.props.model.values[this.props.name] = this.compounds
      .filter(this.isIncluded)
      .map((c) => c.id)
      .join(',')
  }

  @action
  private selectNone = () => {
    this.props.model.values[this.props.name] = ''
  }

  render() {
    return (
      <div className={classNames('relative', this.props.className)}>
        <div className='text-gray-400 text-xs mb-1.5'>
          <span
            onClick={this.selectAll}
            className='pl-2 pr-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100'
            style={{ borderRadius: '4px 0 0 4px' }}
          >
            Alle auswählen
          </span>
          <span
            onClick={this.selectNone}
            className='pl-1 pr-2 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer'
            style={{ borderRadius: '0 4px 4px 0' }}
          >
            Alle abwählen
          </span>
        </div>
        {this.compounds.map(this.compoundMapper)}
      </div>
    )
  }
}
