import { uniqueId } from 'helpers/uniqueId'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { classNames } from 'helpers/classNames'
import { StylesConfig } from 'react-select'
import AsyncSelect from 'react-select/async'
import styles from './styles.module.scss'
import { InputSelectOption } from '../InputSelect'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import * as qs from 'qs'
import { IJugendamt } from 'contracts/general/interfaces/IJugendamt'

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  children?: Element
  onChoose?: (label: string | null) => void
  setRef?: (HTMLInputElement) => void
  filter?: IResidentSearchResultsFilter
}

const colourStyles: StylesConfig<any> = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    minHeight: '36px',
    backgroundColor: state.isSelected ? '#6366f1' : state.isFocused ? '#e0e7ff' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: state.isSelected ? undefined : '#e0e7ff',
    },
  }),
  control: (styles, options) => ({
    ...styles,
    'input:focus': {
      boxShadow: 'none',
    },
    borderRadius: '0.375rem',
    cursor: 'text',
    boxShadow: options.isFocused
      ? '0 0 0 1px rgb(99, 102, 241)'
      : '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontSize: '14px',
    border: options.isFocused
      ? '1px solid rgb(99, 102, 241) !important'
      : '1px solid rgb(212, 212, 212) !important',
    color: '#000000',
  }),
}

const DropdownIndicator = () => {
  return <div className={styles.dropdownIndicator} />
}

@observer
export class InputJugendamt extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  @observable.ref private selected: InputSelectOption | null = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.id = props.id || uniqueId('input-')
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.props.model.values[this.props.name],
        (value) => {
          if (!value) {
            this.selected = null
          } else if (value !== this.selected?.value && value !== 'new') {
            hermes
              .getOnceNew<IJugendamt>(
                `/api/${this.context.instance.id}/jugendaemter/${value}`,
              )
              .then((jugendamt) => {
                if (this.props.model.values[this.props.name] !== jugendamt.id) {
                  return
                }
                runInAction(
                  () =>
                    (this.selected = {
                      value: jugendamt.id,
                      label: jugendamt.label,
                    }),
                )
              })
          }
        },
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private loadOptions = async (inputValue: string): Promise<InputSelectOption[]> => {
    try {
      const jugendaemter = await hermes.indexOnceNew<IJugendamt>(
        `/api/${this.context.instance.id}/jugendaemter${qs.stringify(
          { searchString: inputValue.trim() },
          { allowDots: true, addQueryPrefix: true, skipNulls: true },
        )}`,
      )
      const options = jugendaemter.map((u) => ({
        value: u.id,
        label: u.label,
      }))
      if (inputValue.trim()) {
        options.push({ value: 'new', label: `${inputValue.trim()} anlegen` })
      }
      return options
    } catch (_e) {
      return []
    }
  }

  private onChange = (item: InputSelectOption | null) => {
    item = { value: item?.value, label: item?.label.replace('anlegen', '') ?? '' }
    runInAction(() => {
      this.props.model.values[this.props.name] = item?.value ?? null
      this.selected = item
    })
    this.props.onChoose?.(item?.label ?? null)
  }

  render() {
    const { name, label, placeholder, className } = this.props

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <AsyncSelect
          id={this.id}
          cacheOptions
          name={name}
          styles={colourStyles}
          placeholder={placeholder || ''}
          isClearable
          components={{ DropdownIndicator }}
          noOptionsMessage={() => 'Keine Ergebnisse'}
          loadOptions={this.loadOptions}
          defaultOptions
          onChange={this.onChange}
          value={this.selected}
        />
      </div>
    )
  }
}
