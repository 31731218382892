import * as React from 'react'
import { SideBar } from 'components/SideBarLayout/components/SideBar'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router'
import { classNames } from 'helpers/classNames'
import { Link } from 'react-router-dom'
import { IReport } from '..'

interface Props {
  reports: IReport[]
}

export const ReportingSideBar: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  const location = useLocation()
  const match = location.pathname.split('/')[2]
  const currentReportKey: string | null = match ?? null

  const reports: IReport[] = props.reports.filter(
    (report) => !!context.permissions[report.permission],
  )

  return (
    <SideBar>
      <div style={{ flex: '0 0 64px', lineHeight: '64px' }} className='px-6 text-lg'>
        <i className='fas fa-scroll text-gray-400' />
        <span className='ml-3 text-gray-500'>Reporting</span>
      </div>

      <div className='pt-5 flex flex-col absolute bottom-0 left-0 right-0 overflow-y-auto top-10'>
        <nav className='flex-1 px-4 pb-4 space-y-1'>
          {reports.length === 0 && (
            <div className='text-gray-500'>
              Es wurden noch keine Reports für Sie freigeschalten.
            </div>
          )}
          {reports.map((report) => (
            <Link
              key={report.key}
              to={`/reports/${report.key}`}
              className={classNames(
                report.key === currentReportKey
                  ? 'bg-gray-100 text-gray-900 border-2 border-indigo-500'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group relative flex items-center px-2 py-2 text-sm font-medium rounded-md truncate',
              )}
            >
              <i className='fas fa-th-list text-gray-400' />
              <span className='ml-3'>{report.label || '(Leer)'}</span>
            </Link>
          ))}
        </nav>
      </div>
    </SideBar>
  )
})
