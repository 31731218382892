import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, observable } from 'mobx'
import { ZodObject, ZodType } from 'zod'

export interface FieldPosition {
  name: string
  node: HTMLInputElement
  left: number
  top: number
}

export class Model<T> {
  readonly id = uniqueId('form-')
  readonly values: T
  readonly touched = observable.map<string, boolean>()
  readonly validators = new Map<string, ZodType<any>>()

  constructor(values, schema?: ZodObject<any>) {
    if (schema) {
      for (const key of Object.keys(values)) {
        if (schema.shape[key]) {
          this.validators.set(key, schema.shape[key])
        }
      }
    }
    this.values = observable(values)
    makeObservable(this)
  }

  @action
  touchAll() {
    for (const key of Object.keys(this.values)) {
      this.touched.set(key, true)
    }
  }

  @action
  untouchAll() {
    this.touched.clear()
  }

  isValid() {
    for (const key of Object.keys(this.values)) {
      const validator = this.validators.get(key)
      if (!validator) {
        continue
      }
      if (!validator.safeParse(this.values[key]).success) {
        return false
      }
    }
    return true
  }

  getInvalidFields() {
    const invalid: any = {}
    for (const key of Object.keys(this.values)) {
      const validator = this.validators.get(key)
      if (!validator) {
        continue
      }
      if (!validator.safeParse(this.values[key]).success) {
        invalid[key] = this.values[key]
      }
    }
    return invalid
  }

  setFocusToLeftTopmostInvalidField() {
    this.touchAll()
    const leftTopmost = this.getLeftTopmostInvalidField()
    if (leftTopmost) {
      setTimeout(() => {
        leftTopmost.node.focus()
      }, 0)
    }
  }

  getLeftTopmostInvalidField(): FieldPosition | null {
    const model = document.getElementById(this.id)
    if (!model) {
      throw new Error(
        `Model wrapper for field "${Object.keys(this.values)[0]}" not found. ` +
          `Wrap your input fields with <div id={this.model.id}>`,
      )
    }

    let leftTopmost: FieldPosition | null = null
    for (const key of Object.keys(this.values)) {
      const validator = this.validators.get(key)
      // Ignore fields without errors
      if (!validator || validator.safeParse(this.values[key]).success) {
        continue
      }
      // Get input field
      const input = model.querySelector(
        `input[name="${key}"],textarea[name="${key}"],select[name="${key}"],` +
          `button[name="${key}"],button[name="${key}-0"]`,
      ) as HTMLInputElement

      // Not all fields of a Model must have associated ui elements
      if (!input) {
        continue
      }
      // Get position of input field
      const rect = input.getBoundingClientRect()
      if (!leftTopmost) {
        leftTopmost = { node: input, left: rect.left, top: rect.top, name: key }
      }
      if (
        rect.top < leftTopmost.top || // Current input is above last topmost input
        (rect.top === leftTopmost.top && rect.left < leftTopmost.left) // Equal y position, but further left
      ) {
        leftTopmost = { node: input, left: rect.left, top: rect.top, name: key }
      }
    }
    return leftTopmost
  }
}
