import { InputAsyncMultiSelect } from 'components/Form/components/InputAsyncMultiSelect'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { InputCountry } from 'components/Form/components/InputCountry'
import { InputNationality } from 'components/Form/components/InputNationality'
import { Model } from 'components/Form/Model'
import { IMigration } from 'contracts/general/interfaces/IMigration'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  type: string
  row: IMigration
}

export class MigrationRow extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<IMigration>

  constructor(props: Props) {
    super(props)
    this.model = new Model<IMigration>(props.row)
  }

  render() {
    return (
      <div className='flex p-2 bg-white shadow rounded-md mb-3'>
        <div className='flex-[0_0_400px] truncate pt-2 pl-2'>
          <InputCheckbox model={this.model} name='migrate' label={this.props.row.from} />
        </div>
        <div className='flex-auto'>
          {this.props.type === 'countryOfBirth->countryOfBirthId' && (
            <InputCountry model={this.model} name='to' label='Land' />
          )}
          {this.props.type === 'nationality->nationalityId' && (
            <InputNationality model={this.model} name='to' label='Nationalität' />
          )}
          {this.props.type === 'nativeLanguage->languageIds' && (
            <InputAsyncMultiSelect
              model={this.model}
              name='to'
              label='Sprachen'
              options={`/api/${this.context.instance.id}/languages`}
            />
          )}
        </div>
      </div>
    )
  }
}
