import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { InventoryBundleValidator } from 'contracts/inventory/validators/InventoryBundleValidator'

interface Props {
  compoundId: string
  onClose: (id?: string) => void
}

@observer
export class BundleFormDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IInventoryBundle>
  @observable loading = false

  constructor(props: Props) {
    super(props)
    this.model = new Model({ label: '' }, InventoryBundleValidator)
  }

  private saveBundle = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (!this.props.compoundId) {
      return
    }
    if (this.loading) {
      return
    }
    runInAction(() => (this.loading = true))
    try {
      const data = {
        label: this.model.values.label,
        compoundId: this.props.compoundId,
        imageId: null,
      }
      const id = await hermes.create(
        `/api/${this.context.instance.id}/inventory/bundles`,
        data,
      )
      this.props.onClose(id)
    } catch (_e) {
      box.alert(
        'Fehler',
        'Das Paket konnte nicht gespeichert werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator',
      )
    }
    runInAction(() => (this.loading = false))
  }

  render() {
    return (
      <div id={this.model.id}>
        <h3>Paket erstellen</h3>
        <InputText className='mt-4' model={this.model} name='label' label='Bezeichnung' />
        <div className='mt-4 float-right'>
          <Button color='secondary' outline onClick={() => this.props.onClose()}>
            Abbrechen
          </Button>
          <Button className='ml-2' onClick={this.saveBundle}>
            {this.loading ? (
              <span>
                <i className='fa fa-spin fa-sync fa-sm' />
              </span>
            ) : (
              'Speichern'
            )}
          </Button>
        </div>
      </div>
    )
  }
}
