import * as React from 'react'
import { Callout } from '../index'

export class NotFound extends React.Component<{ title: string; subtitle?: string }, {}> {
  render() {
    return (
      <Callout
        icon='fas fa-search'
        iconColor='#ef4444'
        title={this.props.title}
        subtitle={this.props.subtitle}
      />
    )
  }
}
