import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import * as React from 'react'

interface Props {
  model: Model<{ label: string }>
  type: 'comment' | 'characteristics'
}

export class FederalTransferListEntryInput extends React.Component<Props, {}> {
  render() {
    return (
      <div className='my-4 text-black'>
        <InputText
          model={this.props.model}
          name='label'
          label={this.props.type === 'comment' ? 'Fluchtgemeinschaft' : 'Besonderheiten'}
          placeholder={
            this.props.type === 'comment' ? 'Verhältnis, z.B. Bruder, Vater, ...' : ''
          }
        />
      </div>
    )
  }
}
