import * as React from 'react'
import { useLocation } from 'react-router'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { NoCompanySelected } from './components/CompaniesContent/components/NoCompanySelected'
import { SideBarLayout } from 'components/SideBarLayout'
import { Collection } from '@byll/hermes'
import { ICompany } from 'contracts/companies/interfaces/ICompany'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { CompaniesSidebar } from './components/CompaniesSidebar'
import { Spinner } from 'components/Spinner'
import { CompaniesContent } from './components/CompaniesContent'

interface Props {}

@observer
export class Companies extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly companies: Collection<ICompany>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.companies = new Collection(`/api/${context.instance.id}/companies`)
  }

  componentDidMount(): void {
    this.disposers.push(this.companies.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  render() {
    if (!this.context.permissions.menu_companies) {
      return <Forbidden />
    }

    if (!this.companies.resources) {
      return <Spinner className='pt-14 bg-gray-100 min-h-full' delay />
    }

    return (
      <SideBarLayout>
        <CompaniesSidebar companies={this.companies} />
        <RenderCompaniesContent />
      </SideBarLayout>
    )
  }
}

export const RenderCompaniesContent: React.FC<{}> = () => {
  const location = useLocation()
  let currentCompanyId: string | null = location.pathname.split('/')[2] ?? null

  if (!currentCompanyId) {
    return (
      <div className='md:ml-80 flex flex-col flex-1 min-h-screen relative'>
        <NoCompanySelected />
      </div>
    )
  }
  return <CompaniesContent key={currentCompanyId} />
}
