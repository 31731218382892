import * as React from 'react'
import { getFileExtensionIcon } from '../helpers/getFileExtensionIcon'

interface Props {
  id: string
  name: string
  cache?: Blob
}

export class OtherPreview extends React.Component<Props, {}> {
  constructor(props) {
    super(props)
    this.state = { dataUrl: null }
  }

  render() {
    const icon = getFileExtensionIcon(this.props.name, true)
    return (
      <div className='flex flex-col shadow bg-white relative'>
        <div className='flex-content mx-auto mt-12 mb-6'>
          <img src={icon} style={{ height: 120 }} alt='' />
        </div>
        <div className='flex-content mb-12'>
          <div className='text-gray-900 truncate text-lg'>{this.props.name}</div>
          <div className='text-gray-500 truncate'>
            Für diesen Dateityp ist keine Vorschau verfügbar
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}
