import * as React from 'react'
import { Collection } from '@byll/hermes'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { runInAction } from 'mobx'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'

interface Props {
  tickets: Collection<ITodoSearchResult, any, ITodoSearchResultFilter>
}

export class TicketsTabs extends React.Component<Props, {}> {
  private setOpen = () => {
    runInAction(() => {
      this.props.tickets.query.status = 'open'
      this.props.tickets.query.page = '0,10'
    })
  }

  private setDone = () => {
    runInAction(() => {
      this.props.tickets.query.status = 'done without rating'
      this.props.tickets.query.page = '0,10'
    })
  }

  private setRated = () => {
    runInAction(() => {
      this.props.tickets.query.status = 'rated'
      this.props.tickets.query.page = '0,10'
    })
  }

  render() {
    return (
      <div className='text-gray-500 mt-2 pb-4'>
        <span
          onClick={this.setOpen}
          className={
            this.props.tickets.query.status === 'open'
              ? 'text-blue-500 cursor-pointer'
              : 'hover:text-black cursor-pointer'
          }
        >
          Offen
        </span>
        &nbsp;|&nbsp;
        <span
          onClick={this.setDone}
          className={
            this.props.tickets.query.status === 'done without rating'
              ? 'text-blue-500 cursor-pointer'
              : 'hover:text-black cursor-pointer'
          }
        >
          Erledigt
        </span>
        &nbsp;|&nbsp;
        <span
          onClick={this.setRated}
          className={
            this.props.tickets.query.status === 'rated'
              ? 'text-blue-500 cursor-pointer'
              : 'hover:text-black cursor-pointer'
          }
        >
          Bewertet
        </span>
      </div>
    )
  }
}
