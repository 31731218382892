import { hermes } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Message } from 'components/Message'
import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { observable, runInAction } from 'mobx'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { Button } from '../../Button'

interface Props {
  station: IScanningStation
  onClose: () => void
  type: 'gate' | 'canteen'
}

export class DeleteScanningStationPrompt extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading = false
  @observable private error: string | null = null

  onDelete = async () => {
    runInAction(() => (this.loading = true))
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/scan/scanningStations/${this.props.station.id}`,
      )
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.loading = false
        this.error = 'Beim Löschen ist ein Fehler aufgetreten.'
      })
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {this.props.type === 'gate'
                ? 'Pforte löschen'
                : 'Essensausgabestelle löschen'}
            </Dialog.Title>
          </div>
        </div>

        <div className='mt-3 text-sm text-gray-500'>
          {this.error && (
            <Message color='danger' className='mb-4'>
              {this.error}
            </Message>
          )}
          {this.props.type === 'gate'
            ? 'Möchten Sie diese Pforte wirklich unwiderruflich löschen?'
            : 'Möchten Sie diese Essensausgabestelle wirklich unwiderruflich löschen?'}
        </div>

        <div className='mt-4 text-right'>
          <Button
            disabled={this.loading}
            color='secondary'
            outline
            onClick={this.props.onClose}
          >
            Abbrechen
          </Button>
          <Button
            disabled={this.loading}
            color='danger'
            className='ml-3'
            onClick={this.onDelete}
          >
            Unwiderruflich löschen
          </Button>
        </div>
        {this.loading && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
