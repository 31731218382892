import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { InventoryItemValidator } from 'contracts/inventory/validators/InventoryItemValidator'
import { InputImage } from 'components/Form/components/InputImage'
import { box } from 'services/box'
import { Card } from 'components/Card'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { Spinner } from 'components/Spinner'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
// import { BundleFormDialog } from '../../InventoryBundle/components/BundleFormDialog'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'

interface Props {
  compoundId: string
  item: Partial<IInventoryItem>
  setEdit: (edit: boolean) => void
  navigate: (url: string) => void
}

const scopeOptions: InputMultiSelectOption[] = [
  { id: 'child', label: 'Für Kinder' },
  { id: 'woman', label: 'Für Frauen' },
  { id: 'man', label: 'Für Männer' },
]

@observer
export class InventoryItemForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IInventoryItem>
  @observable private bundles: InputMultiSelectOption[] = []
  @observable loading = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({ ...props.item }, InventoryItemValidator)
  }

  componentDidMount() {
    void this.loadBundles()
  }

  private loadBundles = async () => {
    const bundles = await hermes.indexOnceNew<{ id: string; label: string }>(
      `/api/${this.context.instance.id}/inventory/bundles?compoundId=${this.props.compoundId}`,
    )
    runInAction(() => (this.bundles = bundles))
  }

  private saveItem = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    try {
      const data = {
        compoundId: this.props.compoundId,
        label: this.model.values.label,
        bundleIds: this.model.values.bundleIds,
        folderId: this.props.item.folderId,
        imageId: this.model.values.imageId,
        brutto: this.model.values.brutto,
        netto: this.model.values.netto,
        count: this.model.values.count,
        unit: this.model.values.unit,
        durationOfUse: this.model.values.durationOfUse,
        minStock: this.model.values.minStock,
        maxStock: this.model.values.maxStock,
        issueCount: this.model.values.issueCount,
        packageSize: this.model.values.packageSize,
        isOrderable: this.model.values.isOrderable,
        isDispensable: this.model.values.isDispensable,
        orderlink: this.model.values.orderlink,
        barcode: this.model.values.barcode,
        scope: this.model.values.scope,
      }
      if (!this.props.item.id) {
        const { id } = await hermes.create(
          `/api/${this.context.instance.id}/inventory/items`,
          data,
        )
        this.props.navigate(`/inventory/items/${id}`)
      } else {
        await hermes.patch(
          `/api/${this.context.instance.id}/inventory/items/${this.props.item.id}`,
          data,
        )
        this.props.setEdit(false)
      }
    } catch (_e) {
      box.alert(
        'Fehler',
        'Der Gegenstand konnte nicht gespeichert werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator',
      )
    }
    runInAction(() => (this.loading = false))
  }

  private deleteItem = async () => {
    if (
      !window.confirm('Wollen Sie diesen Gegenstand wirklich unwiderruflich löschen?')
    ) {
      return
    }
    runInAction(() => (this.loading = true))
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/inventory/items/${this.props.item.id}`,
      )
      this.props.navigate(`/inventory/items`)
    } catch (_e) {
      box.alert(
        'Fehler',
        'Der Gegenstand konnte nicht gelöscht werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator',
      )
    }
    runInAction(() => (this.loading = false))
  }

  private cancel = () => {
    if (this.props.item.id) {
      this.props.setEdit(false)
    } else {
      this.props.navigate(`/inventory/folders/${this.props.item.folderId}`)
    }
  }

  private createBundle = async () => {
    /*if (this.model.values.bundleId !== '-1') { return }
    const promise = box.custom(<BundleFormDialog compoundId={this.props.compoundId} onClose={(id?: string) => promise.close(id)} />, { context: this.context })
    const x = await promise
    if (!x) {
      runInAction(() => this.model.values.bundleId = null)
    } else {
      this.loadBundles()
      runInAction(() => this.model.values.bundleId = x.id[0])
    }*/
  }

  render() {
    if (!this.context.permissions.inventory_manageItems) {
      return <Forbidden />
    }
    return (
      <CardColumn>
        <Card>
          {!this.loading && (
            <div className='p-6'>
              <h3 className='text-gray-500 text-xl'>
                {this.props.item.id ? 'Gegenstand bearbeiten' : 'Gegenstand hinzufügen'}
              </h3>
              <div id={this.model.id} className='flex gap-4 mt-4'>
                <div className='min-w-[300px]'>
                  <InputImage
                    model={this.model}
                    name='imageId'
                    width={300}
                    height={300}
                    scope='inventory item'
                  />
                </div>
                <div
                  id={this.model.id}
                  className='grid grid-cols-2 xl:grid-cols-3 gap-5 mt-4'
                >
                  <InputText
                    className='col-span-2'
                    model={this.model}
                    name='label'
                    label='Bezeichnung'
                  />
                  <InputDecimal
                    precision={5}
                    scale={0}
                    notNegative
                    model={this.model}
                    name='count'
                    label='Anzahl'
                  />
                  <InputText model={this.model} name='unit' label='Einheit' />
                  <InputDecimal
                    precision={5}
                    scale={0}
                    notNegative
                    model={this.model}
                    name='minStock'
                    label='Kritischer Bestand'
                  />
                  <InputDecimal
                    precision={5}
                    scale={0}
                    notNegative
                    model={this.model}
                    name='maxStock'
                    label='Maximaler Bestand'
                  />
                  <InputDecimal
                    precision={8}
                    scale={2}
                    notNegative
                    model={this.model}
                    name='brutto'
                    label='Bruttopreis'
                  />
                  <InputDecimal
                    precision={8}
                    scale={2}
                    notNegative
                    model={this.model}
                    name='netto'
                    label='Nettopreis'
                  />
                  <InputDecimal
                    precision={4}
                    scale={0}
                    notNegative
                    model={this.model}
                    name='durationOfUse'
                    label='Nutzungsdauer'
                    postfix={
                      this.model.values.durationOfUse
                        ? +this.model.values.durationOfUse === 1
                          ? 'Tag'
                          : 'Tage'
                        : ''
                    }
                  />
                  <InputDecimal
                    precision={5}
                    scale={0}
                    notNegative
                    model={this.model}
                    name='issueCount'
                    label='Ausgabemenge'
                  />
                  <InputDecimal
                    precision={5}
                    scale={0}
                    notNegative
                    model={this.model}
                    name='packageSize'
                    label='Packungsgröße'
                  />
                  <InputText model={this.model} name='barcode' label='Barcode' />
                  <InputText
                    className='col-span-2 xl:col-span-3'
                    model={this.model}
                    name='orderlink'
                    label='Bestelllink'
                  />
                  <InputMultiSelect
                    className='col-span-2 xl:col-span-3'
                    model={this.model}
                    name='scope'
                    label='Zielgruppen'
                    options={scopeOptions}
                  />
                  <InputMultiSelect
                    className='col-span-2 xl:col-span-3'
                    key={this.bundles.length}
                    model={this.model}
                    name='bundleIds'
                    label='Pakete'
                    options={this.bundles}
                  />
                  <InputCheckbox
                    name='isOrderable'
                    model={this.model}
                    label='Bestellbar'
                  />
                  <InputCheckbox
                    name='isDispensable'
                    model={this.model}
                    label='Für Ausgabe verfügbar'
                  />
                </div>
              </div>
              {this.props.item.id && (
                <Button
                  className='mt-4 float-left'
                  color='danger'
                  outline
                  onClick={this.deleteItem}
                >
                  Löschen
                </Button>
              )}
              <div className='mt-4 mb-6 float-right'>
                <Button className='mr-2' color='secondary' outline onClick={this.cancel}>
                  Abbrechen
                </Button>
                <Button onClick={this.saveItem}>Speichern</Button>
              </div>
            </div>
          )}
          {this.loading && <Spinner />}
        </Card>
      </CardColumn>
    )
  }
}
