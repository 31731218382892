export function getGridColumns(cols: number): string {
  if (cols === 1) {
    return '1fr'
  }

  let result = ''
  for (let i = 1; i < cols; i++) {
    result += 'auto '
  }
  result += '1fr'

  return result
}
