import { RoundIcon } from 'components/RoundIcon'
import { Spinner } from 'components/Spinner'
import { MONTHS } from 'contracts/general/helpers/months'
import {
  ITimeSheetPage,
  ITimeSheetPageDone,
  ITimeSheetPageError,
  ITimeSheetPageProcessing,
} from 'contracts/workplan/interfaces/ITimeSheetPage'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  page: ITimeSheetPage
}

export const UploadTimeSheetDialogPageTile: React.FC<Props> = observer((props) => {
  if (props.page.status === 'done') {
    return <UploadTimeSheetDialogPageTileDone page={props.page as ITimeSheetPageDone} />
  }
  if (props.page.status === 'error') {
    return <UploadTimeSheetDialogPageTileError page={props.page as ITimeSheetPageError} />
  }
  return (
    <UploadTimeSheetDialogPageTileProcessing
      page={props.page as ITimeSheetPageProcessing}
    />
  )
})

@observer
class UploadTimeSheetDialogPageTileProcessing extends React.Component<
  { page: ITimeSheetPageProcessing },
  {}
> {
  static contextType = AppContext

  private onOpen = () => {
    window.open(
      `/api/${this.context.instance.id}/download/files/${this.props.page.id}?mode=open&fileName=Seite_${this.props.page.batchPageNumber}.pdf`,
      '_blank',
    )
  }

  render() {
    const page = this.props.page
    if (page.status === 'pending') {
      return null
    }

    return (
      <div className='group relative flex bg-white shadow-md h-[70px] mt-4'>
        <div className='flex-[0_0_70px] bg-center relative'>
          <Spinner />
        </div>

        <div className='flex-auto'>
          <div
            style={{ flex: '0 1 auto', margin: '23px 15px 0 5px' }}
            className='truncate text-gray-400'
          >
            QR Code Erkennung...
          </div>
        </div>

        <div className='idden group-hover:block'>
          <RoundIcon
            tooltip='Vorschau'
            icon='fas fa-external-link-alt'
            onClick={this.onOpen}
            style={{ position: 'absolute', top: 20, right: 10 }}
          />
        </div>

        <span className='absolute -top-2 -right-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white'>
          {`Seite ${page.batchPageNumber}`}
        </span>
      </div>
    )
  }
}

@observer
class UploadTimeSheetDialogPageTileError extends React.Component<
  { page: ITimeSheetPageError },
  {}
> {
  static contextType = AppContext

  private onOpen = () => {
    window.open(
      `/api/${this.context.instance.id}/download/files/${this.props.page.id}?mode=open&fileName=Seite_${this.props.page.batchPageNumber}.pdf`,
      '_blank',
    )
  }

  render() {
    const page = this.props.page

    return (
      <div className='group relative flex bg-white shadow-md h-[70px] mt-4'>
        <div className='flex-[0_0_70px] bg-center relative'>
          <div
            className='bg-red-500 rounded-full text-white text-center'
            style={{ height: 50, width: 50, margin: 10, fontSize: 26, paddingTop: 7 }}
          >
            <i className='fas fa-times' />
          </div>
        </div>

        <div className='flex-auto truncate'>
          <div className='flex'>
            <div
              style={{ flex: '0 1 auto', margin: '13px 15px 0 5px' }}
              className='truncate'
            >
              Die Seite konnte nicht zugeordnet werden.
            </div>
          </div>
          <div
            className={`text-gray-400 text-sm truncate`}
            style={{ margin: '0 15px 0 5px' }}
          >
            {page.message}
          </div>
        </div>

        <div className='idden group-hover:block'>
          <RoundIcon
            tooltip='Vorschau'
            icon='fas fa-external-link-alt'
            onClick={this.onOpen}
            style={{ position: 'absolute', top: 20, right: 10 }}
          />
        </div>

        <span className='absolute -top-2 -right-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white'>
          {`Seite ${page.batchPageNumber}`}
        </span>
      </div>
    )
  }
}

@observer
class UploadTimeSheetDialogPageTileDone extends React.Component<
  { page: ITimeSheetPageDone },
  {}
> {
  static contextType = AppContext

  private onOpen = () => {
    window.open(
      `/api/${this.context.instance.id}/download/files/${this.props.page.id}?mode=open&fileName=Seite_${this.props.page.batchPageNumber}.pdf`,
      '_blank',
    )
  }

  render() {
    const page = this.props.page
    const month = dayjs(page.month, 'YYYY-MM')
    return (
      <div className='group relative flex bg-white shadow-md h-[70px] mt-4'>
        <div className='flex-[0_0_70px] bg-center relative'>
          <div
            className='bg-green-500 rounded-full text-white text-center'
            style={{ height: 50, width: 50, margin: 10, fontSize: 26, paddingTop: 7 }}
          >
            <i className='fas fa-check' />
          </div>
        </div>
        {page.status === 'done' && (
          <div className='flex-auto truncate max-w-[340px]'>
            <div className='flex'>
              <div
                style={{ flex: '0 1 auto', margin: '13px 15px 0 5px' }}
                className='truncate'
              >
                {page.employeeName}
              </div>
            </div>
            <div
              className={`text-gray-400 text-sm truncate`}
              style={{ margin: '0 15px 0 5px' }}
            >
              {`${MONTHS[month.month()]} ${month.year()}`}
              &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              {`Seite ${page.pageNumber} von ${page.numberOfPages}`}
            </div>
          </div>
        )}

        <div className='idden group-hover:block'>
          <RoundIcon
            tooltip='Vorschau'
            icon='fas fa-external-link-alt'
            onClick={this.onOpen}
            style={{ position: 'absolute', top: 20, right: 10 }}
          />
        </div>

        <span className='absolute -top-2 -right-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white'>
          {`Seite ${page.batchPageNumber}`}
        </span>
      </div>
    )
  }
}
