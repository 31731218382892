import { InputSelectOption } from 'components/Form/components/InputSelect'

export const aufenthaltsstatusOptions: InputSelectOption[] = [
  { value: '', label: '' },
  {
    value: 'Bei Einreise §1 Nr. 2 AsylG 2 Wochen',
    label: 'Bei Einreise §1 Nr. 2 AsylG 2 Wochen',
  },
  {
    value: 'Bescheinigung §63a AsylG 2 Wochen',
    label: 'Bescheinigung §63a AsylG 2 Wochen',
  },
  { value: 'Duldung §60a AufenthG 1 Monat', label: 'Duldung §60a AufenthG 1 Monat' },
  {
    value: 'Duldungsfiktion §81 III S. 2 AufenthG 1 Monat',
    label: 'Duldungsfiktion §81 III S. 2 AufenthG 1 Monat',
  },
  {
    value: 'Erlaubnisfiktion §81 III S. 1 AufenthG 1 Monat',
    label: 'Erlaubnisfiktion §81 III S. 1 AufenthG 1 Monat',
  },
  {
    value: 'Folgeanträge §§ 71, 71a Asyl-VerfG 2 Wochen',
    label: 'Folgeanträge §§ 71, 71a Asyl-VerfG 2 Wochen',
  },
  { value: 'Gestattung §55 AsylG 2 Wochen', label: 'Gestattung §55 AsylG 2 Wochen' },
  {
    value: 'Vollziehbar ausreisepflichtig §1 I Nr. 5 AsylG 1 Monat',
    label: 'Vollziehbar ausreisepflichtig §1 I Nr. 5 AsylG 1 Monat',
  },
  /*  {
    value: 'Noch kein Status definiert - nur übergangsweise!',
    label: 'Noch kein Status definiert - nur übergangsweise!',
  },
  { value: 'Asylbewerber', label: 'Asylbewerber' },
  {
    value: 'Sonstige Ausländer ohne Antrag § 15a AufenthG',
    label: 'Sonstige Ausländer ohne Antrag § 15a AufenthG',
  },
  { value: 'Abgelehnt ohne Duldung', label: 'Abgelehnt ohne Duldung' },
  {
    value: 'Abgelehnt Ausreisefrist GÜB (Grenzübertrittsbescheinigung)',
    label: 'Abgelehnt Ausreisefrist GÜB (Grenzübertrittsbescheinigung)',
  },
  {
    value: 'Sonstige ausreisepflichtige Leistungsberechtigte',
    label: 'Sonstige ausreisepflichtige Leistungsberechtigte',
  },
  {
    value:
      'Ausländer mit Aufenthaltserlaubnis – §§ 23 Abs. 1 oder § 24 wegen Krieges im Heimatland oder § 25 Abs. 4 Satz 1 oder Abs. 5 AufenthG',
    label:
      'Ausländer mit Aufenthaltserlaubnis – §§ 23 Abs. 1 oder § 24 wegen Krieges im Heimatland oder § 25 Abs. 4 Satz 1 oder Abs. 5 AufenthG',
  },
  {
    value: 'Aufenthaltserlaubnis § 104a AufenthG',
    label: 'Aufenthaltserlaubnis § 104a AufenthG',
  },
  {
    value: 'Inhaber einer Duldung nach § 60a AufenthG',
    label: 'Inhaber einer Duldung nach § 60a AufenthG',
  },
  {
    value: 'Abschiebungsaussetzung nach Ablehnung',
    label: 'Abschiebungsaussetzung nach Ablehnung',
  },
  {
    value: 'Asylberechtigte Art. 16a GG (als solcher anerkannt)',
    label: 'Asylberechtigte Art. 16a GG (als solcher anerkannt)',
  },
  {
    value:
      'Abschiebeschutz nach § 25 Abs. 2 i. V. m. § 60 Abs. 1 AufenthG und sonstige Abschiebeschutzberechtigte § 25 Abs. 3 AufenthG',
    label:
      'Abschiebeschutz nach § 25 Abs. 2 i. V. m. § 60 Abs. 1 AufenthG und sonstige Abschiebeschutzberechtigte § 25 Abs. 3 AufenthG',
  },
  {
    value: 'Sonstige Nichtleistungsberechtigte nach dem AsylbLG',
    label: 'Sonstige Nichtleistungsberechtigte nach dem AsylbLG',
  },
  { value: 'Deutsches Kind', label: 'Deutsches Kind' },*/
]
