import * as React from 'react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { AppContext } from 'services/connection/models/AppContext'
import { Link } from 'react-router-dom'
import { getResidentUrl } from 'helpers/getResidentUrl'
import { bookingTypes, HouseBan } from 'modules/Residents/helpers/bookingTypes'
import { SearchIcon } from '@heroicons/react/outline'
import { IMealScanResult } from 'contracts/scan/interfaces/IMealScanResult'
import { mealDict } from 'contracts/scan/validators/MealScanValidator'
import { box } from 'services/box'
import { runInAction } from 'mobx'
import { hermes } from '@byll/hermes'
import { SpecialMealPermissions } from './SpecialMealPermissions'

interface Props {
  scan: IMealScanResult
}

@observer
export class MealIssueTile extends React.Component<Props, {}> {
  static contextType = AppContext

  get image(): string {
    return getResidentImageSrc(
      this.context.instance.id,
      this.props.scan.resident.imageId,
      this.props.scan.resident.sex,
      'thumbnail',
    )
  }

  private onDelete = async (id: string) => {
    if (
      !(await box.alert(
        'Essensausgabe zurücknehmen',
        'Möchten Sie die Ausgabe dieser Mahlzeit rückgängig machen?',
        { color: 'danger', cancel: 'Abbrechen', confirm: 'Rückgängig machen' },
      ))
    ) {
      return
    }
    try {
      await hermes.delete(`/api/${this.context.instance.id}/scan/meals/${id}`)
      runInAction(
        () =>
          (this.props.scan.scans = this.props.scan.scans.filter(
            (scan) => scan.id !== id,
          )),
      )
    } catch (_e) {
      box.alert('Fehler', 'Die Essensausgabe konnte nicht rückgängig gemacht werden.', {
        color: 'danger',
      })
    }
  }

  render() {
    const item = this.props.scan
    const resident = this.props.scan.resident
    const accommodation = this.props.scan.resident.accommodation
    const bookingType = bookingTypes[accommodation?.type || ''] || {
      label: '',
      color: '',
      icon: SearchIcon,
    }
    const scans =
      item.scans.length > 6 ? item.scans.slice(-6, item.scans.length) : item.scans
    if (scans.length === 0) {
      return null
    }

    return (
      <div className={`card card-body relative border-b border-gray-200 px-4`}>
        <div className='truncate mb-1 mt-3'>
          <Link
            to={getResidentUrl(this.props.scan.resident.id)}
            className='hover:text-blue-500'
          >
            {`${resident.lastName.toUpperCase()}, ${resident.firstName}`}
          </Link>
        </div>

        <div className='flex'>
          {/* Image */}
          <div className='flex-[0_0_120px]'>
            <Link
              to={getResidentUrl(this.props.scan.resident.id)}
              className='hover:opacity-70'
            >
              <img src={this.image} alt='' className='w-full rounded-md' />
            </Link>
          </div>
          {/* Last scans */}
          <div className='flex-auto min-w-0 mx-3 text-sm text-gray-500'>
            {scans.length === 1 && <div>Mahlzeiten heute</div>}
            {scans.map((scan, i) => (
              <div
                key={scan.id}
                className={
                  i === scans.length - 1 ? 'text-black truncate group' : 'truncate group'
                }
              >
                {`${dayjs(scan.at).format('HH:mm')} ${
                  mealDict.get(scan.type) || scan.type
                }`}{' '}
                <span
                  className='hidden group-hover:inline-flex cursor-pointer hover:text-red-500'
                  onClick={() => this.onDelete(scan.id)}
                >
                  <i className='fas fa-undo' />
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className='mt-3 mb-4 flex gap-2 flex-wrap'>
          {accommodation && (
            <div className='inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600'>
              <div className='flex-content mr-1'>
                <bookingType.icon
                  className={`h-4 w-4 ${bookingType.color} rounded-full`}
                  style={{ padding: 1 }}
                  aria-hidden='true'
                />
              </div>
              {accommodation.reason === 'Hausverbot' && (
                <div className='flex-content mr-1'>
                  <HouseBan />
                </div>
              )}
              <div className='flex-auto overflow-hidden'>{accommodation.label}</div>
            </div>
          )}
          {!accommodation && (
            <div className='inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600'>
              Nicht eingebucht
            </div>
          )}
          <SpecialMealPermissions item={item} />
        </div>

        <div></div>
      </div>
    )
  }
}
