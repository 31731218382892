import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { InputDate } from 'components/Form/components/InputDate'
import { action, makeObservable } from 'mobx'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { InputBuildingFloor } from 'components/Form/components/InputBuildingFloor'
import { Message } from 'components/Message'
import { InputText } from 'components/Form/components/InputText'

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

@observer
export class SelectAccommodation extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setToNotNow = () => {
    this.props.model.values.checkedIn = 'not now'
  }
  @action private setToCustom = () => {
    this.props.model.values.checkedIn = 'custom'
  }
  @action private setToNow = () => {
    this.props.model.values.checkedIn = 'now'
    this.props.model.values.checkedInBegin = null
    this.props.model.values.checkedInEnd = null
  }
  @action private setToAnytime = () => {
    this.props.model.values.checkedIn = 'anytime'
    this.props.model.values.checkedInBegin = null
    this.props.model.values.checkedInEnd = null
  }

  @action private toggleMultipleCompounds = () => {
    this.props.model.values.allowMultipleCompoundIds =
      this.props.model.values.allowMultipleCompoundIds === 'yes' ? 'no' : 'yes'
    if (this.props.model.values.allowMultipleCompoundIds === 'no') {
      this.props.model.values.compoundId =
        typeof this.props.model.values.compoundId === 'string'
          ? this.props.model.values.compoundId.split(',')[0] || null
          : null
    } else {
      this.props.model.values.buildingId = null
      this.props.model.values.floorId = ''
      this.props.model.values.compoundId =
        this.props.model.values.compoundId === 'none'
          ? ''
          : this.props.model.values.compoundId || ''
    }
  }

  @action private reset = () => {
    this.props.model.values.compoundId = null
    this.props.model.values.buildingId = null
    this.props.model.values.floorId = ''
    this.props.model.values.checkedIn = 'anytime'
    this.props.model.values.checkedInBegin = null
    this.props.model.values.checkedInEnd = null
    this.props.model.values.allowMultipleCompoundIds = 'no'
  }

  @action private clearBuilding = () => {
    this.props.model.values.buildingId = null
    this.props.model.values.floorId = ''
  }

  @action private clearFloor = () => {
    console.log('clearFloor')
    this.props.model.values.floorId = ''
  }

  render() {
    const multipleCompounds = this.props.model.values.allowMultipleCompoundIds === 'yes'
    let label = 'Belegung'
    let color: string = 'text-gray-500'
    if (this.props.model.values.compoundId) {
      const compoundIds = multipleCompounds
        ? this.props.model.values.compoundId.split(',')
        : [this.props.model.values.compoundId]
      label =
        hermes.getFromStore<ICompound>(
          `/api/${this.context.instance.id}/accommodations/compounds/${compoundIds[0]}`,
          false,
        )?.label || ''

      if (label) {
        if (compoundIds.length > 1) {
          label += ` +${compoundIds.length - 1}`
        }
      } else {
        label = 'Gewählte Unterkunft'
      }
      if (this.props.model.values.compoundId === 'none') {
        label = 'Nur registriert'
      }
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60 cursor-default'>
            <div className='grid grid-col-2 gap-4'>
              {this.props.model.values.compoundId === 'none' && (
                <Message color='primary' className='col-span-2'>
                  Nur registrierte Bewohner, die nie eingecheckt waren.
                </Message>
              )}

              <InputCompound
                onChange={this.clearBuilding}
                className='col-span-2'
                model={this.props.model}
                name='compoundId'
                label='Gelände'
                multiple={multipleCompounds}
                onlyStamm={this.context.permissions.menu_resident_search < 3}
                noCompoundOption
              >
                <div
                  className='absolute -top-2 right-2 h-4 flex cursor-pointer'
                  onClick={this.toggleMultipleCompounds}
                >
                  <span
                    className={`inline-flex items-center px-1 py-0 text-xs font-medium ${
                      multipleCompounds
                        ? 'bg-white text-gray-900 border border-gray-300 border-l-0'
                        : 'bg-indigo-500 text-white'
                    } `}
                    style={{ borderRadius: '9999px 0 0 9999px' }}
                  >
                    Eins
                  </span>
                  <span
                    className={`inline-flex items-center px-1 py-0 text-xs font-medium ${
                      !multipleCompounds
                        ? 'bg-white text-gray-900 border border-gray-300 border-l-0'
                        : 'bg-indigo-500 text-white'
                    }`}
                    style={{ borderRadius: '0 9999px 9999px 0' }}
                  >
                    Mehrere
                  </span>
                </div>
              </InputCompound>
              {!multipleCompounds && this.props.model.values.compoundId !== 'none' && (
                <>
                  <InputBuilding
                    onChange={this.clearFloor}
                    className='col-span-2'
                    model={this.props.model}
                    compoundId={this.props.model.values.compoundId}
                    name='buildingId'
                    label='Gebäude'
                    emptyText={
                      this.props.model.values.compoundId ? 'Gebäude wählen' : ' '
                    }
                    disabled={!this.props.model.values.compoundId}
                  />
                  {this.props.model.values.buildingId ? (
                    <InputBuildingFloor
                      key={this.props.model.values.buildingId}
                      className='col-span-2'
                      model={this.props.model}
                      buildingId={this.props.model.values.buildingId}
                      name='floorId'
                      label='Stockwerk'
                      multiselect
                    />
                  ) : (
                    <InputText
                      className='col-span-2'
                      model={this.props.model}
                      name='floorId'
                      label='Stockwerk'
                      disabled
                    />
                  )}
                </>
              )}
              {this.props.model.values.compoundId &&
                this.props.model.values.compoundId !== 'none' && (
                  <div className='flex col-span-2'>
                    <Button
                      onClick={this.setToNow}
                      color={
                        this.props.model.values.checkedIn === 'now'
                          ? 'primary'
                          : 'secondary'
                      }
                      outline={this.props.model.values.checkedIn !== 'now'}
                      className='flex-auto'
                      style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                    >
                      Jetzt
                    </Button>
                    <Button
                      onClick={this.setToNotNow}
                      color={
                        this.props.model.values.checkedIn === 'not now'
                          ? 'primary'
                          : 'secondary'
                      }
                      outline={this.props.model.values.checkedIn !== 'not now'}
                      className='flex-auto'
                      style={{
                        borderRadius: '0',
                        padding: '9px 0',
                        borderLeft: 'none',
                        borderRight: 'none',
                      }}
                    >
                      Früher
                    </Button>
                    <Button
                      onClick={this.setToCustom}
                      color={
                        this.props.model.values.checkedIn === 'custom'
                          ? 'primary'
                          : 'secondary'
                      }
                      outline={this.props.model.values.checkedIn !== 'custom'}
                      className='flex-auto'
                      style={{
                        borderRadius: '0',
                        padding: '9px 0',
                        borderRight: 'none',
                      }}
                    >
                      Andere
                    </Button>
                    <Button
                      onClick={this.setToAnytime}
                      color={
                        this.props.model.values.checkedIn === 'anytime'
                          ? 'primary'
                          : 'secondary'
                      }
                      outline={this.props.model.values.checkedIn !== 'anytime'}
                      className='flex-auto'
                      style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                    >
                      Alle
                    </Button>
                  </div>
                )}
              {this.props.model.values.checkedIn === 'custom' && (
                <div className='col-span-2 flex flex-col gap-4'>
                  {this.props.model.values.checkedInBegin &&
                    this.props.model.values.checkedInEnd &&
                    this.props.model.values.checkedInBegin >
                      this.props.model.values.checkedInEnd && (
                      <Message color='warning'>
                        Enddatum darf nicht vor Anfangsdatum liegen.
                      </Message>
                    )}
                  <div className='flex gap-2'>
                    <InputDate
                      model={this.props.model}
                      name='checkedInBegin'
                      label='Anfangsdatum'
                      placeholder='TT.MM.JJJJ'
                      className='flex-auto'
                    />
                    <InputDate
                      model={this.props.model}
                      name='checkedInEnd'
                      label='Enddatum'
                      placeholder='TT.MM.JJJJ'
                      className='flex-auto'
                    />
                  </div>
                </div>
              )}

              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
