import { Collection } from '@byll/hermes'
import { BellIcon } from '@heroicons/react/outline'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  todos: Collection<ITodoSearchResult>
  onClick: () => void
}

export const TodoIndicator: React.FC<Props> = observer((props) => {
  return (
    <button
      onClick={props.onClick}
      type='button'
      className='bg-gray-800 p-1.5 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white relative'
    >
      <span className='sr-only'>Notifications ansehen</span>
      <BellIcon className='h-6 w-6' aria-hidden='true' />
      {!!props.todos?.resources && props.todos.resources.length > 0 && (
        <div className='absolute top-1.5 right-1.5 bg-red-500 rounded-full h-2.5 w-2.5' />
      )}
    </button>
  )
})
