import { Collection, hermes } from '@byll/hermes'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { Spinner } from 'components/Spinner'
import { IMigration } from 'contracts/migrations/interfaces/IMigration'
import { observer } from 'mobx-react'
import * as React from 'react'
import { MigrationListRow } from './components/MigrationListRow'
import { MigrationListHeader } from './components/MigrationListHeader'
import { useNavigate } from 'react-router'
import { LoadMoreButton } from 'components/LoadMoreButton'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import {
  InputButtonGroup,
  InputButtonGroupOption,
} from 'components/Form/components/InputButtonGroup'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { MigrationFileUploadDialog } from './components/MigrationFileUploadDialog'

interface Props {
  migrations: Collection<IMigration>
}

const statusOptions: InputButtonGroupOption[] = [
  { value: '', label: 'Alle' },
  { value: 'Entwurf', label: 'Entwurf' },
  { value: 'Importiert', label: 'Importiert' },
  { value: 'Papierkorb', label: 'Papierkorb' },
]

export const MigrationList: React.FC<Props> = observer(({ migrations }) => {
  const context = React.useContext(AppContext)
  const navigate = useNavigate()
  const [model] = React.useState(() => new Model(migrations.query))

  if (migrations.error?.id === RequestPendingError.id) {
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full flex flex-col'>
        <Spinner delay />
      </div>
    )
  }
  if (migrations.error || !migrations.resources) {
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full flex flex-col'>
        <LoadingError title='Diese Ansicht konnte nicht geladen werden' />
      </div>
    )
  }

  const onClick = (migration: IMigration) => {
    navigate(`/migrations/${migration.id}`)
  }

  const onDelete = async (migration: IMigration) => {
    if (
      !(await box.alert(
        'Vorgang archivieren',
        'Möchten Sie diesen Vorgang wirklich in den Papierkorb verschieben? Dies hat keine Auswirkung auf bereits importierte Daten.',
        { cancel: 'Abbrechen', confirm: 'Ja, Vorgang archivieren', color: 'danger' },
      ))
    ) {
      return
    }
    await hermes.delete(`/api/${context.instance.id}/imports/${migration.id}`)
  }

  const onCreate = async () => {
    const promise = box.custom(
      <MigrationFileUploadDialog onClose={(id?: string) => promise.close(id)} />,
      { context },
    )
    const id = await promise
    if (id) {
      navigate(`/migrations/${id[0]}`)
    }
  }

  return (
    <div className='pt-14 bg-gray-100 min-h-full flex flex-col relative'>
      {/* Filters */}
      <div className='px-4 sm:px-6 sticky top-14 pt-6 mb-5 pb-2 flex-content bg-gray-100'>
        <div className='bg-white sm:rounded-lg shadow p-4 flex items-center gap-4 overflow-hidden'>
          <div
            className='bg-indigo-500 text-white rounded-md'
            style={{ padding: '7px 10px' }}
          >
            Importlisten
          </div>
          <div className='flex-content'>
            <InputButtonGroup model={model} name='status' options={statusOptions} />
          </div>
          <div className='flex-content ml-auto'>
            <Button color='secondary' outline onClick={onCreate}>
              Neuer Import
            </Button>
          </div>
        </div>
      </div>

      {/* List */}
      <div className='px-4 sm:px-6 text-gray-800 grid gap-y-2 grid-cols-[max-content_max-content_minmax(0,1fr)_minmax(50px,100px)_max-content]'>
        <MigrationListHeader />
        {migrations.resources.map((r) => {
          if (!r.data) {
            return null
          }
          return (
            <MigrationListRow
              onClick={onClick}
              onDelete={onDelete}
              key={r.id}
              migration={r.data}
            />
          )
        })}
      </div>

      <div className='relative px-4 mt-6 mb-8 sm:px-6 text-center'>
        <LoadMoreButton collection={migrations} />
      </div>
    </div>
  )
})
