import { Collection, hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { InputDocument } from 'components/Form/components/InputDocument'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { IBuildingFloor } from 'contracts/accommodations/interfaces/IBuildingFloor'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { Dayjs } from 'dayjs'
import { getSyncState } from 'helpers/getSyncState'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { createGhostLabel } from 'modules/ResidentsOccupancy/helpers/createGhostLabel'
import { createNewLegendWithoutRoom } from 'modules/ResidentsOccupancy/helpers/createNewLegendWithoutRoom'
import { createNewLegendWithRoom } from 'modules/ResidentsOccupancy/helpers/createNewLegendWithRoom'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { FLOOR_PLAN_ROOM_OVERLAY_ID } from './BuildingFloorRoomOverlay'
import { SidebarRoomTile } from './SideBarRoomTile'

interface Props {
  floor: IBuildingFloor
  rooms: Collection<IRoom>
  occupancies: Map<string, IOccupancy>
  dragMode: boolean
  allowPlanEdit: boolean
  at?: Dayjs
}

@observer
export class BuildingFloorPlanSidebar extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly resource: Resource<IBuildingFloor>
  private readonly model: Model<IBuildingFloor>
  @observable.ref private assignedRooms: Set<string> = new Set()
  private ghost: any = null
  private readonly disposers: Disposer[] = []
  private readonly mountedAt = new Date()

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model(props.floor)
    this.resource = new Resource(
      `/api/${context.instance.id}/accommodations/floors/${props.floor.id}`,
    )
    makeObservable(this)
  }

  componentDidMount(): void {
    // Event handlers
    window.addEventListener('mousedown', this.mouseDown)
    window.addEventListener('mousemove', this.mouseMove)
    window.addEventListener('mouseup', this.mouseUp)
    this.disposers.push(() => {
      window.removeEventListener('mousedown', this.mouseDown)
      window.removeEventListener('mousemove', this.mouseMove)
      window.removeEventListener('mouseup', this.mouseUp)
    })

    // Watch assigned rooms
    this.disposers.push(
      reaction(
        () => this.props.floor.legend?.rooms,
        (rooms) => {
          this.assignedRooms = new Set(rooms?.map((r) => r.id) || [])
        },
        { fireImmediately: true },
      ),
    )

    this.disposers.push(this.resource.init())
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private mouseDown = (e) => {
    if (!this.props.dragMode || this.ghost) {
      return
    }
    const movable = e.target.getAttribute('data-template-movable')
    if (!movable?.startsWith('room-')) {
      return
    }
    const room = hermes.getFromStore<IRoom>(
      `/api/${this.context.instance.id}/accommodations/rooms/${movable.substring(5)}`,
      false,
    )
    const occupancy = this.props.occupancies.get(room?.id || '')
    if (!room || !occupancy) {
      return
    }
    this.ghost = createGhostLabel(room, occupancy)
  }

  private mouseUp = (e) => {
    if (!this.ghost) {
      return
    }
    const movable = this.ghost.getAttribute('data-template-movable')
    this.ghost.remove()
    this.ghost = null

    if (!movable?.startsWith('room-')) {
      return
    }
    const room = hermes.getFromStore<IRoom>(
      `/api/${this.context.instance.id}/accommodations/rooms/${movable.substring(5)}`,
      false,
    )
    const overlay = document.getElementById(FLOOR_PLAN_ROOM_OVERLAY_ID)
    if (!room || !overlay) {
      return
    }

    // Set room position
    const rect = overlay.getBoundingClientRect()
    runInAction(() => {
      this.props.floor.legend = createNewLegendWithRoom(
        room,
        e.clientX - rect.left,
        e.clientY - rect.top,
        this.props.floor.legend,
      )
    })
  }

  private mouseMove = (e) => {
    if (!this.ghost) {
      return
    }
    this.ghost.style.left = `${e.clientX}px`
    this.ghost.style.top = `${e.clientY}px`
  }

  private assignedRoomMapper = (room: IRoom) => {
    const occupancy = this.props.occupancies?.get(room.id)
    if (!room || !occupancy) {
      return null
    }
    if (room.floorId !== this.props.floor.id) {
      return null
    }
    return (
      <SidebarRoomTile
        key={room.id}
        onUnassign={this.onUnassign}
        room={room}
        occupancy={occupancy}
        at={this.props.at}
        dragMode={this.props.dragMode}
        allowUnassign={this.props.allowPlanEdit}
      />
    )
  }

  private unassignedRoomMapper = (room: IRoom) => {
    const occupancy = this.props.occupancies?.get(room.id)
    if (!room || !occupancy) {
      return null
    }
    if (room.floorId !== this.props.floor.id) {
      return null
    }
    return (
      <SidebarRoomTile
        key={room.id}
        onUnassign={this.onUnassign}
        room={room}
        occupancy={occupancy}
        at={this.props.at}
        dragMode={this.props.dragMode}
      />
    )
  }

  @action onUnassign = (room: IRoom) => {
    this.props.floor.legend = createNewLegendWithoutRoom(room, this.props.floor.legend)
  }

  render() {
    const disabled = !this.props.allowPlanEdit
    const assigned: IRoom[] =
      this.props.rooms.resources
        ?.map((r) => r.data)
        .filter(
          (r) =>
            r?.floorId === this.props.floor.id &&
            r.deletedAt === null &&
            this.assignedRooms.has(r.id),
        ) ?? ([] as any)
    const unassigned: IRoom[] =
      this.props.rooms.resources
        ?.map((r) => r.data)
        .filter(
          (r) =>
            r?.floorId === this.props.floor.id &&
            r.deletedAt === null &&
            !this.assignedRooms.has(r.id),
        ) ?? ([] as any)
    return (
      <div className='hidden md:block absolute top-0 right-6 w-[250px] bottom-4 rounded-lg shadow bg-white overflow-y-auto overflow-x-hidden pb-4'>
        <div className='text-gray-500 text-sm px-4 pt-4 -mb-3'>Grundriss</div>
        {getSyncState(this.mountedAt, this.resource) === 'patchError' && (
          <Message className='mt-6 mx-4' color='danger'>
            Fehler beim Speichern
          </Message>
        )}
        <InputDocument
          disabled={disabled}
          className='mx-4 mt-4'
          model={this.model}
          name='documentId'
          scope='floor plan'
        />

        {/* Unsassigned rooms */}
        {unassigned.length > 0 && (
          <div
            className={`flex flex-col gap-3 px-4 ${
              this.props.dragMode ? 'select-none' : ''
            }`}
          >
            <div className='mt-6 -mb-2 text-gray-500 text-sm'>Unzugeordnet</div>
            {unassigned.map(this.unassignedRoomMapper)}
          </div>
        )}

        {/* Assigned rooms */}
        {assigned.length > 0 && (
          <div
            className={`flex flex-col gap-3 px-4 ${
              this.props.dragMode ? 'select-none' : ''
            }`}
          >
            <div className='mt-6 -mb-2 text-gray-500 text-sm'>Zugeordnet</div>
            {assigned.map(this.assignedRoomMapper)}
          </div>
        )}

        {/* No rooms */}
        {assigned.length === 0 && unassigned.length === 0 && (
          <div className='pt-6 px-4 text-gray-500'>
            Diesem Stockwerk sind derzeit keine Räume zugeordnet.
          </div>
        )}
      </div>
    )
  }
}
