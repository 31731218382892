import { Resource } from '@byll/hermes'
import { IUserDefaults } from 'contracts/users/interfaces/IUserDefaults'
import { reaction } from 'mobx'

const stammBuildings = new Set<string>()
const stammCompounds = new Set<string>()

export function initStamm(resource: Resource<IUserDefaults>) {
  reaction(
    () => resource.data?.stammBuildingIds,
    (ids) => {
      if (!ids) {
        return
      }
      stammBuildings.clear()
      for (const id of ids) {
        stammBuildings.add(id)
      }
    },
    { fireImmediately: true },
  )
  reaction(
    () => resource.data?.stammCompoundIds,
    (ids) => {
      if (!ids) {
        return
      }
      stammCompounds.clear()
      for (const id of ids) {
        stammCompounds.add(id)
      }
    },
    { fireImmediately: true },
  )
}

export function isStammBuilding(id: string): boolean {
  return stammBuildings.has(id)
}

export function isStammCompound(id: string): boolean {
  return stammCompounds.has(id)
}
