export const flags = {
  AD: require('./images/AD.png'),
  AE: require('./images/AE.png'),
  AF: require('./images/AF.png'),
  AG: require('./images/AG.png'),
  AI: require('./images/AI.png'),
  AL: require('./images/AL.png'),
  AM: require('./images/AM.png'),
  AO: require('./images/AO.png'),
  AR: require('./images/AR.png'),
  AS: require('./images/AS.png'),
  AT: require('./images/AT.png'),
  AU: require('./images/AU.png'),
  AW: require('./images/AW.png'),
  AX: require('./images/AX.png'),
  AZ: require('./images/AZ.png'),
  BA: require('./images/BA.png'),
  BB: require('./images/BB.png'),
  BD: require('./images/BD.png'),
  BE: require('./images/BE.png'),
  BF: require('./images/BF.png'),
  BG: require('./images/BG.png'),
  BH: require('./images/BH.png'),
  BI: require('./images/BI.png'),
  BJ: require('./images/BJ.png'),
  BL: require('./images/BL.png'),
  BM: require('./images/BM.png'),
  BN: require('./images/BN.png'),
  BO: require('./images/BO.png'),
  BQ: require('./images/BQ.png'),
  BR: require('./images/BR.png'),
  BS: require('./images/BS.png'),
  BT: require('./images/BT.png'),
  BW: require('./images/BW.png'),
  BY: require('./images/BY.png'),
  BZ: require('./images/BZ.png'),
  CA: require('./images/CA.png'),
  CC: require('./images/CC.png'),
  CD: require('./images/CD.png'),
  CF: require('./images/CF.png'),
  CG: require('./images/CG.png'),
  CH: require('./images/CH.png'),
  CI: require('./images/CI.png'),
  CK: require('./images/CK.png'),
  CL: require('./images/CL.png'),
  CM: require('./images/CM.png'),
  CN: require('./images/CN.png'),
  CO: require('./images/CO.png'),
  CR: require('./images/CR.png'),
  CU: require('./images/CU.png'),
  CV: require('./images/CV.png'),
  CW: require('./images/CW.png'),
  CX: require('./images/CX.png'),
  CY: require('./images/CY.png'),
  CZ: require('./images/CZ.png'),
  DE: require('./images/DE.png'),
  DJ: require('./images/DJ.png'),
  DK: require('./images/DK.png'),
  DM: require('./images/DM.png'),
  DO: require('./images/DO.png'),
  DZ: require('./images/DZ.png'),
  EC: require('./images/EC.png'),
  EE: require('./images/EE.png'),
  EG: require('./images/EG.png'),
  ER: require('./images/ER.png'),
  ES: require('./images/ES.png'),
  ET: require('./images/ET.png'),
  FI: require('./images/FI.png'),
  FJ: require('./images/FJ.png'),
  FK: require('./images/FK.png'),
  FM: require('./images/FM.png'),
  FO: require('./images/FO.png'),
  FR: require('./images/FR.png'),
  GA: require('./images/GA.png'),
  GB: require('./images/GB.png'),
  GD: require('./images/GD.png'),
  GE: require('./images/GE.png'),
  GG: require('./images/GG.png'),
  GH: require('./images/GH.png'),
  GI: require('./images/GI.png'),
  GL: require('./images/GL.png'),
  GM: require('./images/GM.png'),
  GN: require('./images/GN.png'),
  GQ: require('./images/GQ.png'),
  GR: require('./images/GR.png'),
  GT: require('./images/GT.png'),
  GU: require('./images/GU.png'),
  GW: require('./images/GW.png'),
  HK: require('./images/HK.png'),
  HN: require('./images/HN.png'),
  HR: require('./images/HR.png'),
  HT: require('./images/HT.png'),
  HU: require('./images/HU.png'),
  ID: require('./images/ID.png'),
  IE: require('./images/IE.png'),
  IL: require('./images/IL.png'),
  IM: require('./images/IM.png'),
  IN: require('./images/IN.png'),
  IO: require('./images/IO.png'),
  IQ: require('./images/IQ.png'),
  IR: require('./images/IR.png'),
  IS: require('./images/IS.png'),
  IT: require('./images/IT.png'),
  JE: require('./images/JE.png'),
  JM: require('./images/JM.png'),
  JO: require('./images/JO.png'),
  JP: require('./images/JP.png'),
  KE: require('./images/KE.png'),
  KG: require('./images/KG.png'),
  KH: require('./images/KH.png'),
  KI: require('./images/KI.png'),
  KM: require('./images/KM.png'),
  KN: require('./images/KN.png'),
  KP: require('./images/KP.png'),
  KR: require('./images/KR.png'),
  KW: require('./images/KW.png'),
  KY: require('./images/KY.png'),
  KZ: require('./images/KZ.png'),
  LA: require('./images/LA.png'),
  LB: require('./images/LB.png'),
  LC: require('./images/LC.png'),
  LI: require('./images/LI.png'),
  LK: require('./images/LK.png'),
  LR: require('./images/LR.png'),
  LS: require('./images/LS.png'),
  LT: require('./images/LT.png'),
  LU: require('./images/LU.png'),
  LV: require('./images/LV.png'),
  LY: require('./images/LY.png'),
  MA: require('./images/MA.png'),
  MC: require('./images/MC.png'),
  MD: require('./images/MD.png'),
  ME: require('./images/ME.png'),
  MG: require('./images/MG.png'),
  MH: require('./images/MH.png'),
  MK: require('./images/MK.png'),
  ML: require('./images/ML.png'),
  MM: require('./images/MM.png'),
  MN: require('./images/MN.png'),
  MO: require('./images/MO.png'),
  MP: require('./images/MP.png'),
  MQ: require('./images/MQ.png'),
  MR: require('./images/MR.png'),
  MS: require('./images/MS.png'),
  MT: require('./images/MT.png'),
  MU: require('./images/MU.png'),
  MV: require('./images/MV.png'),
  MW: require('./images/MW.png'),
  MX: require('./images/MX.png'),
  MY: require('./images/MY.png'),
  MZ: require('./images/MZ.png'),
  NA: require('./images/NA.png'),
  NE: require('./images/NE.png'),
  NF: require('./images/NF.png'),
  NG: require('./images/NG.png'),
  NI: require('./images/NI.png'),
  NL: require('./images/NL.png'),
  NO: require('./images/NO.png'),
  NP: require('./images/NP.png'),
  NR: require('./images/NR.png'),
  NU: require('./images/NU.png'),
  NZ: require('./images/NZ.png'),
  OM: require('./images/OM.png'),
  PA: require('./images/PA.png'),
  PE: require('./images/PE.png'),
  PF: require('./images/PF.png'),
  PG: require('./images/PG.png'),
  PH: require('./images/PH.png'),
  PK: require('./images/PK.png'),
  PL: require('./images/PL.png'),
  PN: require('./images/PN.png'),
  PR: require('./images/PR.png'),
  PT: require('./images/PT.png'),
  PW: require('./images/PW.png'),
  QA: require('./images/QA.png'),
  RO: require('./images/RO.png'),
  RS: require('./images/RS.png'),
  RU: require('./images/RU.png'),
  RW: require('./images/RW.png'),
  SA: require('./images/SA.png'),
  SB: require('./images/SB.png'),
  SC: require('./images/SC.png'),
  SD: require('./images/SD.png'),
  SE: require('./images/SE.png'),
  SG: require('./images/SG.png'),
  SI: require('./images/SI.png'),
  SK: require('./images/SK.png'),
  SL: require('./images/SL.png'),
  SM: require('./images/SM.png'),
  SN: require('./images/SN.png'),
  SO: require('./images/SO.png'),
  SR: require('./images/SR.png'),
  SS: require('./images/SS.png'),
  ST: require('./images/ST.png'),
  SV: require('./images/SV.png'),
  SX: require('./images/SX.png'),
  SY: require('./images/SY.png'),
  SZ: require('./images/SZ.png'),
  TC: require('./images/TC.png'),
  TD: require('./images/TD.png'),
  TG: require('./images/TG.png'),
  TH: require('./images/TH.png'),
  TJ: require('./images/TJ.png'),
  TK: require('./images/TK.png'),
  TL: require('./images/TL.png'),
  TM: require('./images/TM.png'),
  TN: require('./images/TN.png'),
  TO: require('./images/TO.png'),
  TR: require('./images/TR.png'),
  TT: require('./images/TT.png'),
  TV: require('./images/TV.png'),
  TW: require('./images/TW.png'),
  TZ: require('./images/TZ.png'),
  UA: require('./images/UA.png'),
  UG: require('./images/UG.png'),
  US: require('./images/US.png'),
  UY: require('./images/UY.png'),
  UZ: require('./images/UZ.png'),
  VA: require('./images/VA.png'),
  VC: require('./images/VC.png'),
  VE: require('./images/VE.png'),
  VG: require('./images/VG.png'),
  VI: require('./images/VI.png'),
  VN: require('./images/VN.png'),
  VU: require('./images/VU.png'),
  WS: require('./images/WS.png'),
  YE: require('./images/YE.png'),
  ZA: require('./images/ZA.png'),
  ZM: require('./images/ZM.png'),
  ZW: require('./images/ZW.png'),
}
