import { hermes } from '@byll/hermes'
import { Card } from 'components/Card'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { Spinner } from 'components/Spinner'
import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { InventoryFolderValidator } from 'contracts/inventory/validators/InventoryFolderValidator'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  folder: IInventoryFolder & { items?: IInventoryItem[] }
  setEdit: (edit: boolean) => void
  navigate: (url: string) => void
}

@observer
export class InventoryFolderForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IInventoryFolder>
  @observable loading = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(props.folder, InventoryFolderValidator)
  }

  private saveFolder = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    try {
      const data = {
        compoundId: this.props.folder.compoundId,
        label: this.model.values.label,
      }
      if (!this.props.folder.id) {
        const { id } = await hermes.create(
          `/api/${this.context.instance.id}/inventory/folders`,
          data,
        )
        this.props.navigate(`/inventory/folders/${id}`)
      } else {
        await hermes.patch(
          `/api/${this.context.instance.id}/inventory/folders/${this.props.folder.id}`,
          data,
        )
        this.props.setEdit(false)
      }
    } catch (_e) {
      box.alert(
        'Fehler',
        'Der Ordner konnte nicht gespeichert werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator',
      )
    }
    runInAction(() => (this.loading = false))
  }

  private deleteFolder = async () => {
    if (this.props.folder.items && this.props.folder.items.length > 0) {
      box.alert(
        'Fehler',
        'Dieser Ordner ist nicht leer. Es können nur leere Ordner gelöscht werden.',
      )
      return
    }
    if (!window.confirm('Wollen Sie diesen Ordner wirklich unwiderruflich löschen?')) {
      return
    }
    runInAction(() => (this.loading = true))
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/inventory/folders/${this.props.folder.id}`,
      )
      this.props.navigate(`/inventory/folders`)
    } catch (_e) {
      box.alert(
        'Fehler',
        'Der Ordner konnte nicht gelöscht werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator',
      )
    }
    runInAction(() => (this.loading = false))
  }

  private cancel = () => {
    this.props.setEdit(false)
  }

  render() {
    if (!this.context.permissions.inventory_manageFolders) {
      return <Forbidden />
    }
    return (
      <CardColumn>
        <Card>
          {!this.loading && (
            <div className='p-6' id={this.model.id}>
              <h3 className='text-gray-500 text-xl'>
                {this.props.folder.id ? 'Ordner bearbeiten' : 'Ordner hinzufügen'}
              </h3>
              <InputText
                className='mt-4'
                model={this.model}
                name='label'
                label='Ordnername'
              />
              {this.props.folder.id && (
                <Button
                  className='mt-4 float-left'
                  color='danger'
                  outline
                  onClick={this.deleteFolder}
                >
                  Löschen
                </Button>
              )}
              <div className='mt-5 mb-6 float-right'>
                <Button className='mr-2' color='secondary' outline onClick={this.cancel}>
                  Abbrechen
                </Button>
                <Button onClick={this.saveFolder}>Speichern</Button>
              </div>
            </div>
          )}
          {this.loading && <Spinner />}
        </Card>
      </CardColumn>
    )
  }
}
