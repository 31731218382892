import { Collection, hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { ICostCoverageSearchResult } from 'contracts/costCoverages/interfaces/ICostCoverageSearchResult'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Dialog } from 'components/Dialog'
import { CostCoverageDialog } from './components/CostCoverageDialog'
import { Button } from 'components/Form/components/Button'
import { RoundIcon } from 'components/RoundIcon'
import { box } from 'services/box'
import { ICostCoveragePayer } from 'contracts/costCoverages/interfaces/ICostCoveragePayer'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { getRangeLabel } from './helpers/getRangeLabel'
import { Tooltip } from 'components/Tooltip'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { ABRECHNUNGSMODUS_WOHNUNGSLOSE } from 'contracts/costCoverages/interfaces/abrechnungsmodus'

interface Props {
  resident: IResident
}

@observer
export class OverviewCostCoverages extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly family: Resource<IFamily>
  private readonly payers: Collection<ICostCoveragePayer>
  private readonly coverages: Collection<ICostCoverageSearchResult>
  private readonly residentSearchResults: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly disposers: Disposer[] = []
  @observable private dialog: ICostCoverageSearchResult | null = null

  @observable private changed = { hasUnsavedChanges: false }
  @observable private filter: 'resident' | 'family' = 'family'

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.family = new Resource(
      `/api/${context.instance.id}/families/${props.resident.familyId}`,
    )
    this.payers = new Collection(`/api/${context.instance.id}/costCoveragePayers`)
    this.coverages = new Collection(
      `/api/${context.instance.id}/residents/${props.resident.id}/costCoverageSearchResults`,
    )
    this.residentSearchResults = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      {
        familyId: props.resident.familyId,
        fields: 'bookings,accommodation,lastScanAtDe',
        sort: 'dateOfBirth,asc',
      },
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.family.init())
    this.disposers.push(this.payers.init({ readOnly: true }))
    this.disposers.push(this.coverages.init())
    this.disposers.push(this.residentSearchResults.init({ readOnly: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private coverageMapper = (res: Resource<ICostCoverageSearchResult>) => {
    const coverage = res.data
    if (!coverage) {
      return null
    }
    if (this.filter === 'resident' && !coverage.coversCurrentResident) {
      return null
    }
    return (
      <div
        key={coverage.id}
        className='group hover:bg-indigo-100 bg-gray-100 mt-4 px-3 py-2 rounded-md overflow-hidden relative cursor-pointer truncate'
        onClick={() => this.openCoverage(coverage)}
      >
        {!coverage.coversCurrentResident && coverage.isValid && (
          <span className='mr-2 text-red-500 has-tooltip'>
            <i className='fas fa-exclamation-triangle' />
            <Tooltip position='right'>
              Der ausgewählte Bewohner ist nicht in dieser KÜ eingeschlossen
            </Tooltip>
          </span>
        )}
        {!coverage.isValid && (
          <span className='mr-2 text-gray-500 has-tooltip'>
            <i className='fas fa-ban' />
            <Tooltip position='right'>Diese KÜ ist ungültig</Tooltip>
          </span>
        )}
        <span className='mr-2'>{dayjs(coverage.issueDate).format('DD.MM.YYYY')}</span>
        <span className='mr-2'>{coverage.compound.label}</span>
        <span className='text-gray-500 text-sm mr-2'>
          {getRangeLabel(coverage.beginDate, coverage.endDate)}
          &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;{coverage.aktenzeichen}
        </span>
        {coverage.notes && (
          <span className='ml-2 text-blue-500'>
            <i className='fas fa-comment' />
          </span>
        )}
        {coverage.earlyEndOfResponsibility && (
          <span className='ml-2 text-gray-500 has-tooltip'>
            <img
              src={require('./images/exit.png')}
              alt='Zuständigkeit vorzeitig beendet'
              className='inline-block h-[22px] relative -top-[1px]'
            />
            <Tooltip position='left'>Zuständigkeit vorzeitig beendet</Tooltip>
          </span>
        )}
        <RoundIcon
          classNameContainer='hidden group-hover:block'
          tooltip={{ text: 'KÜ löschen', position: 'left' }}
          style={{ position: 'absolute', top: 5, right: 5 }}
          icon='fas fa-trash'
          color='danger'
          onClick={(event) => {
            event.stopPropagation()
            this.deleteCoverage(coverage)
          }}
        />
      </div>
    )
  }

  @action
  private openCoverage = (coverage: ICostCoverageSearchResult) => {
    this.dialog = coverage
  }

  @action
  private addCoverage = () => {
    // If only current resident/hv should be added, use checked: r.id === this.props.resident.id || r.id === this.family.data?.hv
    const residents = this.residentSearchResults.resources
      ? this.residentSearchResults.resources
          .filter((r) => !!r.data)
          .map((r) => ({
            checked: true,
            contact: r.id === this.family.data?.hv,
            id: r.id,
            sex: r.data!.sex,
            firstName: r.data!.firstName,
            lastName: r.data!.lastName,
            dateOfBirth: r.data!.dateOfBirth,
          }))
      : []
    // If contact could not be set from family hv, use current person as hv
    if (residents.filter((r) => r.contact).length === 0) {
      for (const r of residents) {
        if (r.id === this.props.resident.id) {
          r.contact = true
          break
        }
      }
    }
    if (residents.length === 0) {
      window.alert(
        'Die Bewohnerdaten konnten nicht geladen werden. Bitte wenden Sie sich an den Support.',
      )
      return
    }
    this.changed = { hasUnsavedChanges: false }
    this.dialog = {
      id: '',
      document: { id: '', name: '' },
      compound: { id: null as any, label: '' },
      payerId: null as any,
      issueDate: null as any,
      beginDate: null as any,
      endDate: null as any,
      factor: 1,
      isValid: true,
      aktenzeichen:
        this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_WOHNUNGSLOSE
          ? '-'
          : '',
      betrag: null,
      eigenanteil: null,
      eigenanteilZyklus: null,
      ansprechpartner: '',
      verpflegung: 'Selbstverpflegung',
      email: '',
      fax: '',
      telefon: '',
      notes: '',
      createdBy: {
        id: this.context.user.id,
        firstName: this.context.user.firstName,
        lastName: this.context.user.lastName,
      },
      createdAt: new Date().toISOString(),
      coversCurrentResident: true,
      earlyEndOfResponsibility: false,
      residents,
    }

    const lastCoverage = this.coverages.resources?.[0]?.data ?? null
    if (this.context.permissions.host_lfgb && lastCoverage) {
      this.dialog.compound = { ...lastCoverage.compound }
      this.dialog.payerId = lastCoverage.payerId
      this.dialog.beginDate = dayjs(lastCoverage.endDate)
        .add(1, 'day')
        .format('YYYY-MM-DD')
      this.dialog.aktenzeichen = lastCoverage.aktenzeichen
      this.dialog.verpflegung = lastCoverage.verpflegung
    }
  }

  private deleteCoverage = async (coverage: ICostCoverageSearchResult) => {
    const confirmed = await box.alert(
      'Kostenübernahme löschen',
      'Möchten Sie diese Kostenübernahme wirklich unwiderruflich löschen?',
      { confirm: 'Ja, jetzt löschen', cancel: 'Abbrechen', color: 'danger' },
    )
    if (!confirmed) {
      return
    }
    hermes
      .delete(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/costCoverageSearchResults/${coverage.id}`,
        { immediately: true },
      )
      .catch(
        (e) =>
          void box.alert(
            'Löschen fehlgeschlagen',
            e.id === ConflictError.id
              ? e.message
              : 'Die Kostenübernahme konnte nicht gelöscht werden.',
            { color: 'danger' },
          ),
      )
  }

  @action
  private closeCoverage = () => {
    if (this.changed.hasUnsavedChanges) {
      const result = window.confirm(
        'Sie haben ungespeicherte Änderungen. Wollen Sie den Dialog wirklich schließen und die Änderungen verwerfen?',
      )
      if (!result) {
        return
      }
    }
    this.changed.hasUnsavedChanges = false
    this.dialog = null
  }

  @action setFilterResident = () => (this.filter = 'resident')
  @action setFilterFamily = () => (this.filter = 'family')

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Abrechnung</span>
          <br />
          <span className='text-sm text-gray-400'>
            Kostenübernahmen für diesen Bewohner
          </span>
        </div>

        {(!this.coverages.resources ||
          !this.residentSearchResults.resources ||
          !this.residentSearchResults.resources) && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            <Spinner delay />
          </div>
        )}

        {this.coverages.resources &&
          this.residentSearchResults.resources &&
          this.payers.resources && (
            <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
              <div className='pl-2'>
                <Button
                  color='secondary'
                  onClick={this.setFilterFamily}
                  outline={this.filter !== 'family'}
                  style={{ borderRadius: '6px 0 0 6px' }}
                >
                  Komplette Familie
                </Button>
                <Button
                  color='secondary'
                  onClick={this.setFilterResident}
                  outline={this.filter !== 'resident'}
                  style={{ borderRadius: '0 6px 6px 0' }}
                >
                  Dieser Bewohner
                </Button>
              </div>
              {this.coverages.resources.map(this.coverageMapper)}
              <div
                className='hover:bg-indigo-100 bg-gray-100 mt-4 px-4 py-2 rounded-md overflow-hidden relative cursor-pointer'
                onClick={this.addCoverage}
              >
                <span className='text-lg'>
                  <i className='fas fa-plus text-gray-500 mr-2' />
                </span>
                Kostenübernahme hinzufügen
              </div>

              {/* Dialog */}
              <Dialog size='xl' open={!!this.dialog} setOpen={this.closeCoverage}>
                {this.dialog && (
                  <CostCoverageDialog
                    onClose={this.closeCoverage}
                    coverage={this.dialog}
                    changed={this.changed}
                    payers={this.payers}
                    resident={this.props.resident}
                  />
                )}
              </Dialog>
            </div>
          )}
      </div>
    )
  }
}
