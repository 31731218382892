import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Spinner } from 'components/Spinner'
import { IMigration } from 'contracts/general/interfaces/IMigration'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { MigrationRow } from './MigrationRow'

interface Props {
  type: string
}

@observer
export class MigrationList extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private total: number = 0
  @observable private rows: (IMigration & { migrate: boolean })[] | null = null
  @observable private saving = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    runInAction(() => (this.rows = null))
    const rows = await hermes.indexOnceNew<any>(
      `/api/${this.context.instance.id}/residentMigration?type=${encodeURIComponent(
        this.props.type,
      )}`,
    )
    runInAction(() => {
      this.total = rows.length
      this.rows = rows.slice(0, 50).map((r) => ({ ...r, migrate: true }))
    })
  }

  private onSubmit = async () => {
    if (!this.rows) {
      return
    }
    runInAction(() => (this.saving = true))
    try {
      await hermes.create(`/api/${this.context.instance.id}/residentMigration`, {
        type: this.props.type,
        migrations: this.rows
          .filter((r) => r.migrate)
          .map((r) => ({ from: r.from, to: r.to })),
      })
    } catch (e) {
      alert('Migration failed (s. console output)')
      console.log(e)
    }
    runInAction(() => (this.saving = false))
    void this.load()
  }

  render() {
    if (!this.rows) {
      return <Spinner />
    }

    return (
      <div className='mt-6'>
        {this.rows.map((r, i) => (
          <MigrationRow key={i} row={r} type={this.props.type} />
        ))}
        <Button disabled={this.saving} color='primary' onClick={this.onSubmit}>
          {this.saving && (
            <span className='mr-2'>
              <i className='fas fa-spin fa-sync' />
            </span>
          )}
          Speichern
        </Button>
        <div className='my-2 text-gray-500 text-sm'>{`Gesamt: ${this.total}`}</div>
      </div>
    )
  }
}
