import { RoundIcon } from 'components/RoundIcon'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { IInstance } from 'contracts/users/interfaces/IInstance'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'
import * as React from 'react'

interface Props {
  caption: string
  instance: IInstance
  bundles: IInventoryBundle[]
  onSelect: (bundle: IInventoryBundle) => void
  onOpen: (bundle: IInventoryBundle) => void
}

export const InventoryScanBundleGrid: React.FC<Props> = (props) => (
  <>
    <div className='mb-2 text-lg'>{props.caption}</div>
    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xxl:grid-cols-6 gap-3 sm:gap-6 mb-10 border-b border-gray-300 pb-6'>
      {props.bundles.map((bundle) => (
        <div
          key={bundle.id}
          className='cursor-pointer truncate relative hover:bg-slate-100 rounded-md shadow-md hover:opacity-80 group'
          onClick={() => props.onSelect(bundle)}
        >
          <img
            src={getInventoryImageSrc(bundle.imageId, props.instance.id)}
            alt=''
            width='200'
            height='200'
          />
          <div
            className={`absolute bottom-0 left-0 right-0 text-center bg-gray-900 opacity-50 text-white leading-10`}
          >
            {bundle.label}
          </div>
          <RoundIcon
            classNameContainer='hidden group-hover:block z-20'
            tooltip={{ text: 'Inhalt ansehen', position: 'left' }}
            style={{ position: 'absolute', bottom: 5, right: 5 }}
            icon='fas fa-question'
            color='white'
            onClick={(e) => {
              e.stopPropagation()
              props.onOpen(bundle)
            }}
          />
        </div>
      ))}
    </div>
  </>
)
