export function jsonParse(text: string): object | null {
  if (!text || typeof text !== 'string') {
    return null
  }
  try {
    return JSON.parse(text)
  } catch (_e) {
    return null
  }
}
