import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { makeObservable, observable, runInAction } from 'mobx'
import { z } from 'zod'
import { isPastDate } from '../helpers/isPastDate'
import { box } from 'services/box'
import { dayjs } from 'helpers/dayjs'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { InputBuildingListFoldable } from 'components/Form/components/InputBuildingListFoldable'

interface Props {}

@observer
export class BelegungshistorieSoz3 extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading: boolean = false
  private readonly model: Model<{
    begin: string
    end: string
    singleSheet: boolean
    buildingIds: string
  }>

  constructor(props: Props) {
    super(props)
    const today = dayjs()
    this.model = new Model(
      {
        begin: today.startOf('month').format('YYYY-MM-DD'),
        end: today.format('YYYY-MM-DD'),
        singleSheet: false,
        buildingIds: null,
      },
      z.object({
        begin: z.string().refine(isPastDate),
        end: z.string().refine(isPastDate),
      }),
    )
    makeObservable(this)
  }

  private downloadReport = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (!isPastDate(this.model.values.begin) || !isPastDate(this.model.values.end)) {
      box.alert(
        'Datum ungültig',
        'Start- und Enddatum dürfen nicht in der Zukunft liegen.',
      )
      return
    }
    if (this.model.values.begin > this.model.values.end) {
      box.alert('Datum ungültig', 'Das Enddatum darf nicht vor dem Startdatum liegen.')
      return
    }
    if (!this.model.values.buildingIds) {
      box.alert(
        'Keine Gebäude ausgewählt',
        'Bitte wählen Sie mindestens ein Gebäude aus.',
      )
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'belegungshistorie-soz3',
      this.context.instance.id,
      {
        buildingIds: this.model.values.buildingIds,
        begin: this.model.values.begin,
        end: this.model.values.end,
        singleSheet: this.model.values.singleSheet,
      },
      'Belegungshistorie.xlsx',
    )
    runInAction(() => (this.loading = false))
  }

  render() {
    if (!this.context.permissions.report_belegungszahlen) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Belegungshistorie'
        subtitle='Wählen Sie die Gebäude aus, die in die Bele- gungshistorie aufge- nommen werden sollen.'
      >
        <div id={this.model.id}>
          <InputBuildingListFoldable
            className='max-h-[800px] overflow-auto'
            name='buildingIds'
            model={this.model}
          />
          <div className='mt-4 w-36'>
            <InputDate label='von' name='begin' model={this.model} />
            <InputDate className='mt-2' label='bis' name='end' model={this.model} />
          </div>
        </div>
        <InputCheckbox
          model={this.model}
          name='singleSheet'
          disabled={this.loading}
          label='Zusammenführen'
        />
        <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
