import * as React from 'react'
import { hermes } from '@byll/hermes'
import { makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { isYmdDate } from 'contracts/general/helpers/isYmdDate'
import { RastNotificationsList } from './RastNotificationsList'
import { IRastNotificationResponse } from 'contracts/general/interfaces/IRastNotificationResponse'
import { RastNotificationTopBar } from './RastNotificationTopBar'
import { InputSelect } from 'components/Form/components/InputSelect'
import { isPastDate } from 'modules/Reporting/helpers/isPastDate'
import { box } from 'services/box'

const typeInputSelectOptions = [
  { value: 'Transfer', label: 'Nur Transfers' },
  { value: 'Neuaufnahme', label: 'Nur Neuaufnahmen' },
  { value: 'Abgang', label: 'Nur Abgänge' },
  { value: '', label: 'Neuaufnahmen, Transfers und Abgänge' },
]

const statusInputSelectOptions = [
  { value: 'done', label: 'Erledigt' },
  { value: 'pending', label: 'Ausstehend' },
  { value: '', label: 'Erledigt und ausstehend' },
]

interface Props {
  model: Model<{ begin: string; end: string; type: string; status: string }>
  selectedBuildings: Map<string, boolean>
}

@observer
export class RastNotificationsContent extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private rastNotifications: IRastNotificationResponse[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    this.loadNotifications()
    reaction(
      () => toJS(this.props.model.values),
      () => this.loadNotifications(),
    )
  }

  loadNotifications = async () => {
    const buildingIds = Array.from(this.props.selectedBuildings)
      .filter((b) => b[1] === true)
      .map((b) => b[0])
    const ids = buildingIds.join(',')
    if (
      !isYmdDate(this.props.model.values.begin) ||
      !isYmdDate(this.props.model.values.end)
    ) {
      box.alert('Datum ungültig', 'Start- und Enddatum müssen gesetzt sein.')
      return
    }
    if (
      !isPastDate(this.props.model.values.begin) ||
      !isPastDate(this.props.model.values.end)
    ) {
      box.alert(
        'Datum ungültig',
        'Start- und Enddatum dürfen nicht in der Zukunft liegen.',
      )
      return
    }
    try {
      // @ts-ignore:next-line
      const rastNotifications: IRastNotificationResponse[] =
        await hermes.indexOnceNew<any>(
          `/api/${this.context.instance.id}/rastNotifications?buildingIds=${ids}&begin=${
            this.props.model.values.begin
          }&end=${this.props.model.values.end}${
            this.props.model.values.type ? `&type=${this.props.model.values.type}` : ''
          }${
            this.props.model.values.status
              ? `&status=${this.props.model.values.status}`
              : ''
          }`,
        )
      runInAction(() => (this.rastNotifications = rastNotifications))
    } catch (e: any) {
      box.alert(
        'Fehler',
        'Die RASt Meldungen konnten nicht geladen werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator',
      )
    }
  }

  render() {
    return (
      <div className='md:pl-80 flex flex-col flex-1 min-h-screen overflow-auto'>
        {/* TopBar */}
        <RastNotificationTopBar>
          <div className='flex items-center text-gray-500 gap-4'>
            <InputDate
              className='z-20'
              name='begin'
              model={this.props.model}
              label='von'
            />
            <InputDate className='z-20' name='end' model={this.props.model} label='bis' />
            <InputSelect
              className='z-20'
              name='type'
              model={this.props.model}
              options={typeInputSelectOptions}
            />
            <InputSelect
              className='z-20'
              name='status'
              model={this.props.model}
              options={statusInputSelectOptions}
            />
          </div>
        </RastNotificationTopBar>

        <div className='rounded-md shadow-md mx-4 bg-white mt-[72px] flex-auto mb-4 flex flex-col'>
          <RastNotificationsList notifications={this.rastNotifications} />
        </div>
      </div>
    )
  }
}
