import * as React from 'react'
import { IBooking } from '../../../../../../../../../contracts/residents/interfaces/IBooking'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { InputDate } from 'components/Form/components/InputDate'
import { dayjs } from 'helpers/dayjs'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { Button } from 'components/Form/components/Button'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { observer } from 'mobx-react'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Collection, hermes } from '@byll/hermes'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { box } from 'services/box'
import { isStammBuilding, isStammCompound } from 'helpers/isStamm'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { isTime } from 'contracts/general/helpers/isTime'
import { z } from 'zod'
import { Tooltip } from 'components/Tooltip'
import { IPermissions } from 'contracts/users/interfaces/IPermissions'

interface Props {
  bookings: (IBooking & { buildingId?: string })[]
  booking: IBooking & { buildingId?: string }
  className: string
  familyId: string
}

const checkOutMap = {
  'internal-residence': (
    <>
      Belegung für alle Familienmitglieder
      <br />
      beenden, die zum angegebenen
      <br />
      Zeitpunkt eine Belegung haben.
    </>
  ),
  'internal-reservation': (
    <>
      Reservierung für alle Familienmitglieder
      <br />
      beenden, die zum angegebenen
      <br />
      Zeitpunkt eine Reservierung haben.
    </>
  ),
  'external-residence': (
    <>
      Abwesenheit für alle
      <br />
      Familienmitglieder beenden, die
      <br />
      zum angegebenen Zeitpunkt eine
      <br />
      Abwesenheit haben.
    </>
  ),
}

interface CheckOutFormProps extends Props {
  onClose: () => void
}

@observer
class CheckOutForm extends React.Component<CheckOutFormProps, { loading: boolean }> {
  static contextType = AppContext
  private readonly model: Model<{
    date: string | null
    time: string
    checkOutFamily: boolean
  }>
  private readonly residentSearchResults: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly disposers: Disposer[] = []

  constructor(props: CheckOutFormProps, context: AppContextProps) {
    super(props)
    this.state = { loading: false }
    this.residentSearchResults = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      {
        familyId: props.familyId,
        fields: 'bookings,accommodation,lastScanAtDe',
        sort: 'dateOfBirth,asc',
      },
    )
    let now = dayjs().add(-2, 'minute')
    if (now.isSameOrBefore(props.booking.beginAt)) {
      now = null
    }
    const validator = z.object({ date: z.string(), time: z.string().refine(isTime) })
    this.model = new Model(
      {
        date: now?.format('YYYY-MM-DD') || null,
        time: now?.format('HH:mm') || '',
        checkOutFamily: false,
      },
      validator,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.residentSearchResults.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private checkOut = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }

    const endAt = dayjs(
      `${this.model.values.date} ${this.model.values.time}`,
    ).toISOString()
    const type = this.props.booking.type
    const bookingIds: string[] = []
    for (const member of this.residentSearchResults?.resources || []) {
      if (member.data?.deletedAt) {
        continue
      }
      // Detect active booking of type
      const bookings = member.data?.data.bookings?.filter(
        (b) => !b.endAt && b.type === type,
      )
      if (
        bookings &&
        bookings[0] &&
        (bookings[0].id === this.props.booking.id || this.model.values.checkOutFamily)
      ) {
        bookingIds.push(bookings[0].id)
      }
    }

    // Check-out
    let success = 0
    this.setState({ loading: true })
    for (const id of bookingIds) {
      try {
        await hermes.patch(`/api/${this.context.instance.id}/bookings/${id}`, { endAt })
        success++
      } catch (_e) {
        /* */
      }
    }

    this.props.onClose()

    if (success === 0) {
      void box.alert(
        'Buchungsende',
        'Der Vorgang ist leider fehlgeschlagen. Entweder wurde der Bewohner in der Zwischenzeit bereits ausgebucht, oder Sie haben einen Endzeitpunkt angegeben, der vor dem Anfangszeitpunkt liegt. Bitte überprüfen Sie auch Ihre Check-out Berechtigung.',
        { color: 'danger' },
      )
    } else if (bookingIds.length > 1) {
      void box.alert(
        'Buchungsende',
        `${success} / ${
          this.residentSearchResults?.resources?.filter((r) => !r.data?.deletedAt)
            .length || bookingIds.length
        } Familienmitglieder erfolgreich ausgebucht.`,
      )
    }
  }

  render() {
    return (
      <div className='p-3' id={this.model.id}>
        <div className='flex gap-3 mb-3'>
          <InputDate
            className='flex-auto'
            model={this.model}
            name='date'
            label='Enddatum'
          />
          <InputText
            className='flex-[0_0_65px]'
            placeholder='hh:mm'
            model={this.model}
            name='time'
            label='Uhrzeit'
          />
        </div>
        {this.residentSearchResults?.resources &&
          this.residentSearchResults.resources.length > 1 && (
            <div className='flex'>
              <InputCheckbox
                className='flex-content'
                model={this.model}
                name='checkOutFamily'
                label='Alle Familienmitglieder'
              />
              <div className='flex-content ml-2 mr-auto text-blue-500 has-tooltip'>
                <i className='fas fa-question-circle' />
                <Tooltip>{checkOutMap[this.props.booking.type]}</Tooltip>
              </div>
            </div>
          )}
        <div className='flex mt-3'>
          <Button
            color='secondary'
            className='w-full'
            outline
            onClick={() => this.props.onClose()}
            style={{ borderRadius: '6px 0 0 6px' }}
          >
            Abbrechen
          </Button>
          <Button
            color='primary'
            disabled={!this.residentSearchResults?.resources}
            className='w-full'
            onClick={this.checkOut}
            style={{ borderRadius: '0 6px 6px 0' }}
          >
            OK
          </Button>
        </div>
        {this.state.loading && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}

export const CheckOutDropdown: React.FC<Props> = (props) => {
  const context = React.useContext(AppContext)

  function stopPropagation(e) {
    e.stopPropagation()
  }

  function onOpen(e) {
    const error = getCheckOutErrorText(props.booking, props.bookings, context.permissions)

    if (error) {
      e.stopPropagation()
      void box.alert('Berechtigung benötigt', error, { color: 'danger' })
      return
    }
  }

  return (
    <Popover as='span' onClick={stopPropagation} className='relative'>
      <Popover.Button className={props.className} onClickCapture={onOpen}>
        {props.children}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Popover.Panel className='z-10 origin-top-right absolute bottom-6 -right-3 mt-2 w-56 rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none cursor-default'>
          {({ close }) => <CheckOutForm {...props} onClose={close} />}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

function getCheckOutErrorText(
  booking: IBooking & { buildingId?: string },
  bookings: IBooking[],
  permissions: IPermissions,
): string | null {
  switch (booking.type) {
    case 'internal-residence':
      if (permissions.resident_internalCheckInOut === 2) {
        return null
      }
      if (permissions.resident_internalCheckInOut !== 1) {
        return 'Sie haben derzeit nicht die nötige Berechtigungsstufe für diese Aktion.'
      }
      return isStammBuilding(booking.buildingId || '')
        ? null
        : 'Sie haben derzeit nicht die nötige Berechtigungsstufe für diese Aktion, da die Belegung in keinem Ihrer Stammgebäude liegt.'
    case 'internal-reservation':
      if (permissions.resident_reservationCheckInOut === 2) {
        return null
      }
      if (permissions.resident_reservationCheckInOut !== 1) {
        return 'Sie haben derzeit nicht die nötige Berechtigungsstufe für diese Aktion.'
      }
      return isStammBuilding(booking.buildingId || '')
        ? null
        : 'Sie haben derzeit nicht die nötige Berechtigungsstufe für diese Aktion, da die Reservierung in keinem Ihrer Stammgebäude liegt.'
    case 'external-residence':
      if (permissions.resident_externalCheckInOut === 2) {
        return null
      }
      if (permissions.resident_externalCheckInOut !== 1 || !bookings) {
        return 'Sie haben derzeit nicht die nötige Berechtigungsstufe für diese Aktion.'
      }
      for (let i = 0; i < bookings.length; i++) {
        if (
          bookings[i].type !== 'responsibility-begin' &&
          bookings[i].type !== 'responsibility-end'
        ) {
          continue
        }
        if (dayjs(bookings[i].beginAt).isAfter(booking.beginAt, 'minute')) {
          continue
        }
        return bookings[i].type === 'responsibility-begin' &&
          isStammCompound(bookings[i].compoundId!)
          ? null
          : 'Sie haben derzeit nicht die nötige Berechtigungsstufe für diese Aktion, da die Abwesenheit nicht in Ihrer Zuständigkeit liegt.'
      }
  }
  return 'Sie haben derzeit nicht die nötige Berechtigungsstufe für diese Aktion.'
}
