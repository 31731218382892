import axios from 'axios'
import { env } from 'env'
import { sleep } from 'helpers/sleep'

export async function logout(instanceId: string) {
  // Create a new div element for the overlay
  const overlay = document.createElement('div')

  // Set the style of the overlay to make it full-screen and black
  overlay.style.position = 'fixed' // Fixed position to cover the whole screen
  overlay.style.top = '0' // Start from the top of the page
  overlay.style.left = '0' // Start from the left of the page
  overlay.style.width = '100%' // Cover the full width
  overlay.style.height = '100%' // Cover the full height
  overlay.style.backgroundColor = 'white' // Set the background color to black
  overlay.style.zIndex = '1000' // Ensure it's on top of other elements
  overlay.style.display = 'flex' // Use flexbox for centering
  overlay.style.justifyContent = 'center' // Center horizontally
  overlay.style.alignItems = 'center' // Center vertically
  overlay.style.color = 'black' // Set text color to white
  overlay.style.fontSize = '16px' // Increase font size

  // Create a text node for the message
  const textNode = document.createTextNode('Sie werden abgemeldet, bitte warten...')

  // Append the text node to the overlay
  overlay.appendChild(textNode)

  // Append the overlay to the body
  document.body.appendChild(overlay)

  try {
    await axios.delete(`/api/${instanceId}/sessions/all`, {
      withCredentials: true,
      timeout: 3000,
    })
  } catch (_e) {
    /* ignore */
  }

  if (env.NODE_ENV === 'development') {
    // Clear session cookie in dev environment
    document.cookie = 'isession=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }

  await sleep(500)
  if (window.location.pathname === '/') {
    window.location.reload()
  } else {
    window.location.assign('/')
  }
}
