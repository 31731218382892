import * as React from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ICostCoverageMeta } from 'contracts/costCoverages/interfaces/ICostCoverageMeta'

interface Props {
  coverage: ICostCoverageMeta
}

export const CostCoverageInfo: React.FC<Props> = (props) => (
  <Popover as='div' className='absolute top-2 right-2'>
    <Popover.Button className='bg-black text-white'>
      <i className='fas fa-address-card' />
    </Popover.Button>

    <Transition
      as={Fragment}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <Popover.Panel className='z-10 origin-top-right absolute -top-1 right-5 mt-2 px-3 py-1 max-w-[300px] rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none cursor-default'>
        <div className='truncate'>
          Ansprechpartner: {props.coverage.ansprechpartner.name}
          <br />
          Telefon: {props.coverage.ansprechpartner.phone}&nbsp;&nbsp;&nbsp;&nbsp;Fax:{' '}
          {props.coverage.ansprechpartner.fax}
          <br />
          E-Mail: {props.coverage.ansprechpartner.email}
          <br />
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
)
