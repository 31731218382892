import { IPermissions } from 'contracts/users/interfaces/IPermissions'

export function isVisibleMenuItem(
  permissions: IPermissions,
  variable?: string | ((permissions: IPermissions) => boolean),
): boolean {
  if (typeof variable === 'string') {
    return !!permissions[variable]
  } else if (typeof variable === 'function') {
    return variable(permissions)
  } else {
    return true
  }
}
