import { Callout } from 'components/Callout'
import * as React from 'react'

interface Props {}

export const BuildingFloorPlanPlaceholder: React.FC<Props> = () => (
  <div className='absolute top-0 left-0 right-0 bottom-0 flex'>
    <Callout
      className='my-auto'
      icon='fas fa-city'
      iconColor='#8b5cf6'
      title='Grundrisse pro Stockwerk'
      subtitle='Bitte wählen Sie ein Stockwerk aus.'
    />
  </div>
)
