import * as React from 'react'
import { observer } from 'mobx-react'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { Age } from 'components/Age'
import { RoundIcon } from 'components/RoundIcon'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import styles from '../../styles.module.scss'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { useLocation, useNavigate } from 'react-router'
import { box } from 'services/box'
import { bookingTypes, HouseBan } from 'modules/Residents/helpers/bookingTypes'
import { SearchIcon } from '@heroicons/react/outline'
import { Tooltip } from 'components/Tooltip'

interface Props {
  resident: IResidentSearchResult
  isActive: boolean
  isHv: boolean
  showPresence: boolean
  allowRemove: boolean
  openInNewTab?: boolean
}

export const MemberItem: React.FC<Props> = observer(
  ({ resident, isActive, isHv, allowRemove, showPresence, openInNewTab }) => {
    const [removing, setRemoving] = React.useState<boolean>(false)
    const context = React.useContext(AppContext)
    const location = useLocation()
    const navigate = useNavigate()

    async function onRemove(event) {
      event.preventDefault()
      if (removing) {
        return
      }

      // Remove from family
      try {
        const confirmed = await box.alert(
          'Sind Sie sicher?',
          `Möchten Sie ${resident.firstName} ${resident.lastName} wirklich aus dieser Familie herausnehmen? Dadurch wird ${resident.firstName} ${resident.lastName} zukünftig als Einzelperson ohne Familienmitglieder geführt.`,
          { cancel: 'Abbrechen', confirm: 'Ja, Person herausnehmen' },
        )
        if (!confirmed) {
          return
        }
        setRemoving(true)
        // Create new family
        const family = await hermes.create(`/api/${context.instance.id}/families`, {})
        await hermes.patch(`/api/${context.instance.id}/residents/${resident.id}`, {
          familyId: family.id,
        })
      } catch (e) {
        setRemoving(false)
        box.alert(
          'Entfernen fehlgeschlagen',
          'Der Bewohner konnte nicht aus dieser Familie herausgenommen werden. Bitte wenden Sie sich an einen Administrator.',
          { color: 'danger' },
        )
      }
    }

    function onClick(event) {
      if (event.isDefaultPrevented() || removing) {
        return
      }
      const to = location.pathname.substring(11).split('/')
      to.shift()
      if (openInNewTab) {
        const url = `/residents/${toJbpId(+resident.id).toLowerCase()}/overview`
        window.open(url, '_blank')
      } else {
        const url = `/residents/${toJbpId(+resident.id).toLowerCase()}/${to.join('/')}`
        navigate(url)
      }
    }

    const bookingType = bookingTypes[resident.data.accommodation?.type || ''] || {
      label: '',
      color: '',
      icon: SearchIcon,
    }

    return (
      <div
        onClick={onClick}
        className={`${styles.member} ${isActive ? styles.active : ''}`}
      >
        <div className={styles.name}>
          <div className='mr1 flex-auto truncate'>{`${resident.lastName.toUpperCase()}, ${
            resident.firstName
          }`}</div>
          <div className='flex-content'>
            <Age dateOfBirth={resident.dateOfBirth} sex={resident.sex} />
          </div>
        </div>
        {isHv && (
          <div
            className={`has-tooltip h-4 w-4 absolute rounded-full bg-gray-500 -left-2 ${
              showPresence ? 'top-1' : '-top-2'
            }`}
            style={{ position: 'absolute' }}
          >
            <Tooltip position='right'>Haushaltsvorstand</Tooltip>
          </div>
        )}
        {showPresence && (
          <div
            className={`has-tooltip h-4 w-4 absolute -top-2 -left-2 rounded-full ${
              resident.currentVisitCompoundId ? 'bg-green-500' : 'bg-red-500'
            }`}
            style={{ position: 'absolute' }}
          >
            <Tooltip position='right'>{`${
              resident.currentVisitCompoundId ? 'Anwesend' : 'Abwesend'
            }${
              resident.data.lastScanAtDe ? ` seit ${resident.data.lastScanAtDe}` : ''
            }`}</Tooltip>
          </div>
        )}
        {resident.data.accommodation ? (
          <div className={`${styles.residence} flex`}>
            <div className='flex-content mr-1'>
              <bookingType.icon
                className={`h-4 w-4 ${bookingType.color} rounded-full`}
                style={{ padding: 1 }}
                aria-hidden='true'
              />
            </div>
            {resident.data.accommodation.reason === 'Hausverbot' && (
              <div className='flex-content mr-1'>
                <HouseBan />
              </div>
            )}
            <div className='flex-auto overflow-hidden'>
              {resident.data.accommodation.label}
            </div>
          </div>
        ) : (
          <div className={styles.residence}>Nicht eingebucht</div>
        )}
        {allowRemove && (
          <div
            className={removing ? undefined : styles.showOnHover}
            style={{ position: 'absolute', top: -10, right: -10 }}
          >
            <RoundIcon
              color='danger'
              icon={removing ? 'fas fa-times fa-spin' : 'fas fa-times'}
              tooltip={{
                text: (
                  <>
                    Bewohner aus dieser
                    <br />
                    Familie herausnehmen
                  </>
                ),
                position: 'left',
              }}
              onClick={onRemove}
            />
          </div>
        )}
      </div>
    )
  },
)
