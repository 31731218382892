import { dayjs } from 'contracts/general/helpers/dayjs'
import { DAYS } from 'contracts/general/helpers/days'
import { MONTHS } from 'contracts/general/helpers/months'
import { IArbeitsplanData, IPlan } from 'contracts/pdf/interfaces/IArbeitsplanData'
import * as React from 'react'
import styles from '../styles.module.scss'

interface Props {
  data: IArbeitsplanData
  onPagesRendered: () => void
}

export class ArbeitsplanPages extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.onPagesRendered()
  }

  private mapDay = (day: { date: string; plans: IPlan[]; holiday: string | null }) => (
    <React.Fragment key={day.date}>
      {day.plans.map((plan, i) => (
        <tr
          key={i}
          style={{
            backgroundColor: day.holiday
              ? '#ff9800'
              : +day.date.substr(8) % 2
              ? '#DDDDDD'
              : 'white',
          }}
        >
          <td style={{ borderLeft: '0.5px solid black' }}>
            {i === 0
              ? `${dayjs(day.date).format('DD.MM.YYYY')} ${DAYS[dayjs(day.date).day()]}.`
              : ''}
          </td>
          <td style={{ borderLeft: '0.5px solid black' }}>
            <div
              style={{ maxWidth: 440 }}
              className={`truncate${plan.status === 'deleted' ? ' line-through' : ''}`}
            >
              {(plan.status === 'new' || plan.status === 'changed') && (
                <span className={styles.badge}>Änderung</span>
              )}
              {plan.status === 'deleted' && (
                <span className={styles.badge}>Abgesagt</span>
              )}
              {+plan.shiftId < 0 ? (
                plan.label
              ) : (
                <>
                  {`${plan.begin} - ${plan.end}`}&nbsp;&nbsp;&nbsp;&nbsp;
                  {plan.shiftType === 'on call' && (
                    <span className='inline-flex items-center rounded-full bg-blue-500 px-1.5 py-0.25 text-xs font-medium text-white mr-1'>
                      Bereitschaft
                    </span>
                  )}
                  {plan.label}
                </>
              )}
            </div>
          </td>
          <td style={{ borderLeft: '0.5px solid black', textAlign: 'right' }}>
            {+plan.shiftId < 0 ? '' : plan.break ? `${plan.break} Min.` : '-'}
          </td>
          <td
            style={{
              borderLeft: '0.5px solid black',
              borderRight: '0.5px solid black',
              textAlign: 'right',
            }}
          >
            {+plan.shiftId < 0
              ? ''
              : `${(plan.duration / 60).toFixed(2).replace('.', ',')} Std.`}
          </td>
        </tr>
      ))}
    </React.Fragment>
  )

  private getTotal = () => {
    let minutes = 0
    for (const day of this.props.data.days) {
      for (const plan of day.plans) {
        if (+plan.shiftId < 0 || plan.status === 'deleted') {
          continue
        }
        minutes += plan.duration
      }
    }
    return (minutes / 60).toFixed(2).replace('.', ',')
  }

  render() {
    const data = this.props.data
    const month = dayjs(data.month, 'YYYY-MM')

    return (
      <div className={styles.A4}>
        <table className={styles.table}>
          <tbody>
            {/* Caption, month & date */}
            <tr>
              <td colSpan={4} style={{ border: '0.5px solid black' }}>
                <span className='font-bold'>{`Arbeitsplan ${
                  MONTHS[month.month()]
                } ${month.year()}`}</span>
                <span style={{ float: 'right' }}>{`V ${dayjs(data.createdAt).format(
                  'DD.MM. HH:mm',
                )}`}</span>
              </td>
            </tr>

            {/* Employee name */}
            <tr>
              <td
                colSpan={4}
                style={{
                  border: '0.5px solid black',
                  height: 60,
                  maxWidth: 16.6 * 40 - 1,
                  fontSize: 24,
                }}
              >
                <div className='flex'>
                  <div className='flex-[0_1_400px] truncate'>{data.userName}</div>
                  <div className='ml-4 flex-auto text-sm text-right'>
                    Achtung: Dienstbeginn und Dienstende in der
                    <br />
                    Spalte "Planung" sind pünktlich einzuhalten!
                  </div>
                </div>
              </td>
            </tr>

            {/* Table captions */}
            <tr>
              <td style={{ borderLeft: '0.5px solid black' }} className='font-bold'>
                Datum
              </td>
              <td style={{ borderLeft: '0.5px solid black' }} className='font-bold'>
                Planung
              </td>
              <td
                style={{ borderLeft: '0.5px solid black' }}
                className='font-bold text-right'
              >
                Pause
              </td>
              <td
                style={{
                  borderLeft: '0.5px solid black',
                  borderRight: '0.5px solid black',
                }}
                className='font-bold text-right'
              >
                Arbeitszeit
              </td>
            </tr>

            {/* Entries */}
            {data.days.map(this.mapDay)}

            {/* Sum of hours */}
            <tr>
              <td colSpan={3} style={{ borderTop: '0.5px solid black' }} />
              <td
                style={{ border: '0.5px solid black' }}
                className='font-bold text-right'
              >{`${this.getTotal()} Std.`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
