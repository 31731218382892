import * as React from 'react'
import styles from './styles.module.scss'

export const Forbidden: React.FC<{}> = () => (
  <div className={styles.forbidden}>
    <div className='flex-content mt-auto mb-auto text-center'>
      <div>
        <i className='fa fa-ban' style={{ fontSize: 100 }} />
      </div>
      <div className='f3 mt-3'>
        Sie haben keine Freigabe
        <br />
        für dieses Modul
      </div>
    </div>
  </div>
)
