import * as React from 'react'

interface SpinnerProps {
  className?: string
  delay?: number | boolean // true for default delay, number [milliseconds] to specify delay.
}
export class Spinner extends React.Component<SpinnerProps, { delay: boolean }> {
  private timer

  constructor(props) {
    super(props)
    if (props.delay) {
      this.state = { delay: true }
    } else {
      this.state = { delay: false }
    }
  }

  componentDidMount() {
    if (this.props.delay) {
      this.timer = setTimeout(
        () => this.setState({ delay: false }),
        this.props.delay === true ? 200 : this.props.delay,
      )
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {!this.state.delay && (
          <svg className='included-spinner' viewBox='0 0 50 50'>
            <circle
              className='included-path'
              cx='25'
              cy='25'
              r='20'
              fill='none'
              strokeWidth='5'
            />
          </svg>
        )}
      </div>
    )
  }
}
