import * as React from 'react'
import { action, makeObservable, observable, reaction, runInAction, when } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from '../../Model'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'
import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { isStammCompound } from 'helpers/isStamm'
import { InputMultiSelect, InputMultiSelectOption } from '../InputMultiSelect'

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  className?: string
  children?: React.ReactChild
  setRef?: (HTMLInputElement) => void
  onlyStamm?: boolean // Show only stamm compounds
  stammOption?: boolean // Offers option 'Meine Stammgelände'
  noCompoundOption?: boolean // Offers option 'Kein Gelände'
  notNull?: boolean // No "Gelände wählen" / Abwählen des vorausgewählten Geländes nicht möglich.
  saveResponsibility?: boolean // Save compound in default responsibility
  filter?: (compound: ICompound) => boolean
  autoSelectIfOnlyOneOption?: boolean
  multiple?: boolean // allow selection of multiple compounds, e.g. model: '3,7,4'
  at?: string | null // ISO date. All compounds that are either not archived or archived after .at are shown. If .at is missing, archived compounds are not shown.
}

@observer
export class InputCompound extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  private readonly compounds: Collection<ICompound>
  private readonly disposers: Disposer[] = []
  @observable.ref private multiOptions: InputMultiSelectOption[] | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.id = props.id || uniqueId('compound-select-')
    this.compounds = new Collection<any>(
      `/api/${context.instance.id}/accommodations/compounds`,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.compounds.init({ readOnly: true }))
    // Autoselect if there is only one entry
    if (this.props.autoSelectIfOnlyOneOption) {
      this.disposers.push(
        when(
          () => !!this.compounds.resources,
          () => {
            const compoundIds: string[] = []
            for (const res of this.compounds.resources ?? []) {
              if (!res.data || !this.isIncluded(res.data)) {
                continue
              }
              compoundIds.push(res.id)
            }
            if (compoundIds.length === 1) {
              runInAction(() => {
                this.props.model.values[this.props.name] = compoundIds[0]
                if (this.props.saveResponsibility) {
                  this.context.defaults.responsibilityCompoundId = compoundIds[0]
                }
              })
              this.props.onChange?.({} as any)
            }
          },
        ),
      )
    }

    this.disposers.push(
      reaction(
        () => this.props.at || null,
        () => {
          let isValidSelection = false
          for (const res of this.compounds.resources ?? []) {
            if (
              !res.data ||
              !this.isIncluded(res.data) ||
              this.props.model.values[this.props.name] !== res.id
            ) {
              continue
            }
            isValidSelection = true
            break
          }
          if (
            !isValidSelection &&
            !this.props.notNull &&
            this.props.model.values[this.props.name] !== '0'
          ) {
            this.props.model.values[this.props.name] = null
          }
        },
      ),
    )

    this.disposers.push(
      when(
        () => !!this.compounds.resources,
        () => {
          const options: InputMultiSelectOption[] =
            this.compounds.resources
              ?.filter((r) => !!r.data && this.isIncluded(r.data))
              .map((r) => ({ id: r.id, label: r.data!.label })) ?? []
          if (this.props.stammOption) {
            options.unshift({ id: '0', label: 'Stammgelände' })
          }
          this.multiOptions = options
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  private onChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (!(event.target instanceof HTMLSelectElement)) {
      return
    }
    const target: HTMLSelectElement = event.target
    this.props.model.values[this.props.name] = target.value || null
    // Do this at InputBuilding, not here: this.context.defaults.selectedCompoundId = target.value || null
    if (this.props.saveResponsibility) {
      this.context.defaults.responsibilityCompoundId = target.value || null
    }
    this.props.onChange?.(event)
  }

  private optionMapper = (res: Resource<ICompound>) => {
    if (!res.data || !this.isIncluded(res.data)) {
      return null
    }
    return (
      <option key={res.id} value={res.id}>
        {res.data.label}
      </option>
    )
  }

  private isIncluded = (compound: ICompound): boolean => {
    if (compound.deletedAt && (!this.props.at || compound.deletedAt <= this.props.at)) {
      return false
    }
    if (this.props.filter && !this.props.filter(compound)) {
      return false
    }
    if (
      this.props.onlyStamm &&
      compound.id !== this.props.model.values[this.props.name] &&
      !isStammCompound(compound.id)
    ) {
      return false
    }
    return true
  }

  render() {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const {
      name,
      model,
      label,
      className,
      setRef,
      onlyStamm,
      stammOption,
      noCompoundOption,
      notNull,
      saveResponsibility,
      autoSelectIfOnlyOneOption,
      filter,
      children,
      at,
      multiple,
      ...attributes
    } = this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    if (multiple) {
      return (
        <div className={classNames('relative', className)}>
          {!!this.multiOptions && (
            <InputMultiSelect
              name={name}
              model={model}
              label={label}
              options={this.multiOptions}
              disabled={attributes.disabled}
              error={touched && error}
            >
              {children}
            </InputMultiSelect>
          )}
        </div>
      )
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <select
          {...attributes}
          className={innerClassName}
          name={name}
          ref={setRef}
          onChange={this.onChange}
          value={model.values[name] || ''}
          id={this.id}
        >
          {!notNull && (
            <option key='null' value=''>
              Gelände wählen
            </option>
          )}
          {stammOption && (
            <option key='0' value='0'>
              Stammgelände
            </option>
          )}
          {noCompoundOption && (
            <option key='none' value='none'>
              Kein Gelände, nur registriert
            </option>
          )}
          {this.compounds.resources && this.compounds.resources.map(this.optionMapper)}
        </select>
        {children}
      </div>
    )
  }
}
