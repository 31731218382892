import * as React from 'react'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputDate } from 'components/Form/components/InputDate'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { SearchResultList } from './components/SearchResultTable'
import { SelectedResidents } from './components/SelectedResidents'
import { isStammCompound } from 'helpers/isStamm'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { LoadMoreButton } from 'components/LoadMoreButton'

interface Props {}

@observer
export class Cards extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IResidentSearchResultsFilter>
  private readonly residents: UnsyncedCollection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly disposers: Disposer[] = []
  @observable private selected: Map<string, IResidentSearchResult> = new Map()

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    let compoundId = context.defaults.responsibilityCompoundId
    if (
      context.permissions.resident_id_cards <= 2 &&
      !isStammCompound(compoundId || '')
    ) {
      compoundId = null
    }
    this.model = new Model<IResidentSearchResultsFilter>({
      page: '0,100',
      searchString: '',
      compoundId,
      buildingId: null,
      checkedIn: 'now',
      dateType: 'createdAt',
      dateBegin: null,
      fields: 'nationality,jbpId,accommodation',
    })
    this.residents = new UnsyncedCollection(
      `/api/${context.instance.id}/residentSearchResults`,
      this.model.values,
    )
  }

  componentDidMount() {
    this.disposers.push(this.residents.init())
    this.disposers.push(
      reaction(
        () =>
          `${this.model.values.searchString}${this.model.values.compoundId}${this.model.values.buildingId}${this.model.values.dateBegin}`,
        () => (this.model.values.page = '0,100'),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  render() {
    if (
      this.context.permissions.resident_id_cards === 0 ||
      this.context.permissions.resident_id_card_modules > 1
    ) {
      return <Forbidden />
    }

    return (
      <div className='pt-[72px] sm:pt-20 px-4 sm:px-6 pb-4 sm:pb-6 flex flex-col md:flex-row min-h-screen md:h-screen overflow-hidden bg-gray-100 gap-4 sm:gap-6'>
        {/* Left side */}
        <div className='flex-auto px-6 pb-6 bg-white rounded-md shadow-md overflow-x-hidden overflow-y-auto'>
          <div className='md:sticky top-0 bg-white z-10 pb-4 -mx-4 px-4'>
            <div className='mb-4'>
              <h1 className='text-2xl font-semibold text-gray-500 pt-6'>
                Ausweiserstellung
              </h1>
              <p className='mt-2 text-md text-gray-500'>
                Suchen Sie die Bewohner, für die Sie einen neuen Ausweis erstellen
                möchten.
              </p>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-[1fr_140px_180px_180px] gap-x-2 gap-y-4'>
              <InputText model={this.model} name='searchString' label='Suche' />
              <InputDate model={this.model} name='dateBegin' label='Erfasst nach dem' />
              <InputCompound
                model={this.model}
                name='compoundId'
                label='Gelände'
                saveResponsibility
                onlyStamm={this.context.permissions.resident_id_cards < 3}
              />
              <InputBuilding
                compoundId={this.model.values.compoundId}
                model={this.model}
                name='buildingId'
                disabled={!this.model.values.compoundId}
                label='Gebäude'
              />
            </div>
          </div>

          <SearchResultList residents={this.residents} selected={this.selected} />
          <div className='mt-6 text-center'>
            <LoadMoreButton collection={this.residents} incrementBy={100} />
          </div>
        </div>

        {/* Right side */}
        <div className='flex-auto md:flex-[0_0_400px] px-6 pb-6 bg-white rounded-md shadow-md overflow-x-hidden overflow-y-auto'>
          <SelectedResidents selected={this.selected} />
        </div>
      </div>
    )
  }
}
