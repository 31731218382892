import * as React from 'react'
import { Model } from '../../Model'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InputSelect, InputSelectOption } from '../InputSelect'
import { Collection } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IBuildingFloor } from 'contracts/accommodations/interfaces/IBuildingFloor'
import { InputMultiSelect, InputMultiSelectOption } from '../InputMultiSelect'
import { InputText } from '../InputText'

interface Props {
  buildingId: string
  name: string
  model: Model<any>
  label: string
  className?: string
  disabled?: boolean
  allowCreate?: boolean
  emptyValue?: '' | null
  multiselect?: boolean
}

@observer
export class InputBuildingFloor extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly floors: Collection<IBuildingFloor>
  private readonly disposers: Disposer[] = []

  private get options(): InputSelectOption[] {
    const os: InputSelectOption[] = [
      { value: this.props.emptyValue === '' ? '' : null, label: '-' },
    ]
    for (const o of this.floors.resources || []) {
      if (!o.data) {
        continue
      }
      os.push({ value: o.data.id, label: o.data.label })
    }
    if (this.props.allowCreate) {
      os.push({ value: 'new', label: 'Neu...' })
    }
    return os
  }

  private get multiOptions(): InputMultiSelectOption[] {
    const os: InputMultiSelectOption[] = []
    for (const o of this.floors.resources || []) {
      if (!o.data) {
        continue
      }
      os.push({ id: o.data.id, label: o.data.label })
    }
    return os
  }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.floors = new Collection(`/api/${context.instance.id}/accommodations/floors`, {
      buildingId: props.buildingId,
    })
  }

  componentDidMount() {
    this.disposers.push(this.floors.init())
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  render() {
    if (this.props.multiselect) {
      if (!this.floors.resources) {
        return (
          <InputText
            name='wait'
            model={this.props.model}
            label={this.props.label}
            className={this.props.className}
            disabled
          />
        )
      }
      return (
        <InputMultiSelect
          name={this.props.name}
          model={this.props.model}
          label={this.props.label}
          className={this.props.className}
          disabled={this.props.disabled}
          options={this.multiOptions}
        />
      )
    }

    return (
      <InputSelect
        name={this.props.name}
        model={this.props.model}
        label={this.props.label}
        className={this.props.className}
        disabled={this.props.disabled}
        options={this.options}
      />
    )
  }
}
