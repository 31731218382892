import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'

export function getTicketStatusDe(
  ticket: ITodoSearchResult,
): 'Neu' | 'Zugewiesen' | 'In Bearbeitung' | 'Erledigt' {
  if (!!ticket.doneDate) {
    return 'Erledigt'
  }
  if (ticket.gateMessage === '2-accepted') {
    return 'In Bearbeitung'
  }
  if (ticket.gateMessage === '1-assigned') {
    return 'Zugewiesen'
  }
  return 'Neu'
}
