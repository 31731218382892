import {
  IBooking,
  INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE,
  INFO_BOOKING_REASON_AWUM_GEPLANTE_GEBUEHRENAENDERUNG,
  INFO_BOOKING_REASON_END_OF_STAY,
  INFO_BOOKING_REASON_PLANNED_TRANSFER,
} from 'contracts/residents/interfaces/IBooking'
import { observer } from 'mobx-react'
import * as React from 'react'
import {
  BookingTypeDetail,
  bookingTypes,
  HouseBan,
} from 'modules/Residents/helpers/bookingTypes'
import { ArrowRightIcon, LogoutIcon, SearchIcon } from '@heroicons/react/solid'
import { CurrencyEuroIcon } from '@heroicons/react/solid'
import { classNames } from 'helpers/classNames'
import { dayjs } from 'helpers/dayjs'
import { RoundIcon } from 'components/RoundIcon'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { observable, runInAction } from 'mobx'
import { Collection, hermes } from '@byll/hermes'
import { accommodationForms } from '../helpers/accommodationForms'
import { CheckOutDropdown } from './CheckOutDropdown'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { ABRECHNUNGSMODUS_AWUM } from 'contracts/costCoverages/interfaces/abrechnungsmodus'
import { isAwumInstance } from 'contracts/general/helpers/instanceIds'

interface Props {
  bookings: (IBooking & {
    buildingId?: string
    timeline: 'past' | 'present' | 'future'
  })[]
  members: Collection<IResidentSearchResult> // Family members
  resident: IResident
}

const infoBookingDetails = {
  [INFO_BOOKING_REASON_END_OF_STAY]: {
    icon: LogoutIcon,
    color: 'text-white bg-green-500',
  },
  [INFO_BOOKING_REASON_PLANNED_TRANSFER]: {
    icon: ArrowRightIcon,
    color: 'text-white bg-blue-500',
  },
  [INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE]: {
    icon: CurrencyEuroIcon,
    color: 'text-white bg-purple-500',
  },
  [INFO_BOOKING_REASON_AWUM_GEPLANTE_GEBUEHRENAENDERUNG]: {
    icon: CurrencyEuroIcon,
    color: 'text-white bg-purple-500',
  },
}

const billingCategoryMap = {
  Statuswechsler: 'SW',
  Selbstzahler: 'SZ',
  'Aufnahmeverfügung fehlt': 'AV',
  'Bescheiddatum fehlt': 'Bescheiddatum fehlt',
}

function getDateText(beginAt: string, endAt: string | null, type: string) {
  const begin = dayjs(beginAt)
  if (!endAt || type === 'responsibility-begin') {
    return `ab ${begin.format('DD.MM.YYYY')}`
  }
  if (begin.isSame(endAt, 'day')) {
    return begin.format('DD.MM.YYYY')
  }
  return `${begin.format('DD.MM.YYYY')} - ${dayjs(endAt).format('DD.MM.YYYY')}`
}

@observer
export class ResidentAccomodationList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly deleting = observable.set<string>() // booking id

  private onDelete = async (booking: IBooking) => {
    if (this.context.permissions.resident_patchBookings === 0) {
      box.alert(
        'Keine Berechtigung',
        'Zum Löschen von Buchungen ist die Berechtigung "Buchungen nachträglich bearbeiten" erforderlich. Ein Administrator kann Ihnen diese Berechtigung geben.',
        { color: 'danger' },
      )
      return
    }

    let text = `Der Eintrag sollte nur gelöscht werden, wenn er fälschlicherweise angelegt wurde. Falls die Person dagegen in einen anderen Wohnstatus wechselt, sollte in diesem Eintrag ein Enddatum hinterlegt werden und ein neuer Eintrag für den nächsten Wohnstatus angelegt werden. Möchten Sie diesen Eintrag wirklich unwiderbringlich löschen?`
    if (
      booking.type === 'info' &&
      booking.reason === INFO_BOOKING_REASON_PLANNED_TRANSFER
    ) {
      text = `Sie sollten diese Transferplanung nur löschen, falls sie fälschlicherweise angelegt wurde. Auch die Planung für alte, bereits durchgeführte Transfers sollte aus Gründen der späteren Nachvollziehbarkeit beibehalten werden. Nach dem Löschen kann der Transfer nicht mehr durchgeführt werden. Möchten Sie diesen Eintrag wirklich unwiderbringlich löschen?`
    }

    const confirmed = await box.alert('Sind Sie sicher?', text, {
      cancel: 'Abbrechen',
      confirm: 'Ja, Eintrag löschen',
      color: 'danger',
    })
    if (!confirmed) {
      return
    }
    try {
      runInAction(() => this.deleting.add(booking.id))
      await hermes.delete(`/api/${this.context.instance.id}/bookings/${booking.id}`)
    } catch (e: any) {
      runInAction(() => this.deleting.delete(booking.id))
      box.alert(
        'Löschen fehlgeschlagen',
        e.id === ConflictError.id
          ? e.message
          : 'Der Eintrag konnte nicht gelöscht werden. Bitte wenden Sie sich an einen Administrator.',
        { color: 'danger' },
      )
    }
  }

  private onOpen = async (booking: IBooking) => {
    if (booking.type === 'unknown') {
      box.alert(
        'Belegung eintragen',
        'Sie können die oben stehenden Buttons verwenden, um eine Belegung, eine Zuständigkeit oder einen anderen Wohnstatus anzulegen.',
      )
      return
    }
    const Form =
      accommodationForms[
        booking.type + (booking.type === 'info' ? `-${booking.reason}` : '')
      ]
    if (!Form) {
      return
    }
    const promise = box.custom(
      <Form
        resident={this.props.resident}
        booking={booking}
        members={this.props.members}
        onClose={(val?: any) => promise.close(val)}
      />,
      { context: this.context },
    )
    await promise
  }

  private bookingMapper = (
    booking: IBooking & { timeline: 'past' | 'present' | 'future' },
    i: number,
  ) => {
    if (this.deleting.has(booking.id)) {
      return null
    }
    if (
      !(window as any).showResponsibilities &&
      this.context.permissions.resident_automatic_setResponsibility &&
      (booking.type === 'responsibility-begin' || booking.type === 'responsibility-end')
    ) {
      return null // Hide responsibility begin/end for hosts with resident_automatic_setResponsibility. Always show it in dev for debugging purposes.
    }
    const type: BookingTypeDetail =
      booking.type === 'info'
        ? {
            past: booking.reason,
            present: booking.reason,
            future: booking.reason,
            color: infoBookingDetails[booking.reason]?.color || 'text-white bg-gray-500',
            icon: infoBookingDetails[booking.reason]?.icon || SearchIcon,
          }
        : bookingTypes[booking.type] || {
            past: '',
            present: '',
            future: '',
            color: '',
            icon: SearchIcon,
          }
    let billingStatus = booking.extra.billing?.status || ''
    if (
      booking.type === 'info' &&
      booking.reason === INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE &&
      !booking.extra.billing?.dateOfNoticeCreation
    ) {
      billingStatus = 'Bescheiddatum fehlt'
    }

    return (
      <li
        onClick={() => this.onOpen(booking)}
        key={booking.id}
        className={`group rounded-md p-3 cursor-pointer border-2 relative ${
          booking.timeline === 'present'
            ? 'bg-gray-100 hover:bg-gray-50 my-3 border-blue-500'
            : 'border-transparent hover:bg-gray-100'
        }`}
      >
        <div className='relative pb-2'>
          {i !== this.props.bookings.length - 1 ? (
            <span
              className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
              aria-hidden='true'
            />
          ) : null}
          <div className='relative flex space-x-3'>
            <div>
              <span
                className={classNames(
                  type.color,
                  'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                )}
              >
                <type.icon className='h-5 w-5 text-white' aria-hidden='true' />
              </span>
            </div>
            <div className='min-w-0 flex-1 pt-1.5 justify-between'>
              <div className='text-md text-gray-900 truncate'>
                {type[booking.timeline]}
                {booking.label && (
                  <span className='text-gray-400'>
                    &nbsp;&nbsp;·&nbsp;&nbsp;{booking.label}
                  </span>
                )}
                {(booking.type === 'internal-residence' ||
                  booking.type === 'internal-reservation') &&
                  booking.bed && (
                    <>
                      <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-400 text-white'>
                        <i className='fas fa-bed' />
                        &nbsp;{booking.bed}
                      </span>
                      {booking.isBaby && (
                        <span className='ml-2 text-gray-500'>
                          <i className='fas fa-baby-carriage' />
                        </span>
                      )}
                      {booking.extra.food === 'Vollverpflegung' && (
                        <span className='ml-2 text-gray-500'>
                          <i className='fas fa-pizza-slice' />
                        </span>
                      )}
                    </>
                  )}
              </div>
              <div className='text-sm text-gray-400'>
                {booking.type === 'unknown'
                  ? 'Für diesen Bewohner ist weder eine aktuelle Unterkunft, noch ein Zuständigkeitsende eingetragen. Bitte hinterlegen Sie den aktuellen Wohnstatus.'
                  : getDateText(booking.beginAt, booking.endAt, booking.type)}
                {!booking.endAt &&
                  type.endButtonColor &&
                  type.endButtonText &&
                  (!isAwumInstance(this.context.instance.id) ||
                    this.context.permissions.resident_internalCheckInOut > 0 ||
                    booking.type !== 'internal-residence') && (
                    <CheckOutDropdown
                      booking={booking}
                      bookings={this.props.bookings}
                      key={this.props.resident.familyId}
                      familyId={this.props.resident.familyId}
                      className={`relative ml-2 cursor pointer inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gradient-to-r ${type.endButtonColor}`}
                    >
                      {type.endButtonText}
                    </CheckOutDropdown>
                  )}

                {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_AWUM &&
                  billingStatus && (
                    <span className='text-gray-400'>
                      &nbsp;&nbsp;·&nbsp;&nbsp;
                      <span
                        className={`inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium text-white ${
                          billingStatus === 'Aufnahmeverfügung fehlt'
                            ? booking.extra.billing?.avDate
                              ? 'bg-blue-500'
                              : 'bg-red-500'
                            : booking.extra.billing?.dateOfNoticeCreation
                            ? 'bg-blue-500'
                            : 'bg-red-500'
                        }`}
                      >
                        {billingCategoryMap[billingStatus]}
                      </span>
                    </span>
                  )}

                {booking.reason === 'Hausverbot' && (
                  <span className='text-gray-400'>
                    &nbsp;&nbsp;·&nbsp;&nbsp;
                    <span className='inline-flex items-center rounded-full bg-red-500 pl-2.5 pr-1 py-0.5 text-xs font-medium text-white'>
                      {booking.type === 'internal-residence'
                        ? 'Anschließend Hausverbot'
                        : 'Hausverbot'}
                      &nbsp;
                      <HouseBan />
                    </span>
                  </span>
                )}
                {this.context.permissions.resident_booking_showRast &&
                  booking.extra.rast && (
                    <span className='text-gray-400'>&nbsp;&nbsp;·&nbsp;&nbsp; RAST</span>
                  )}
              </div>
            </div>
          </div>
        </div>
        {booking.type !== 'unknown' && (
          <div className='hidden group-hover:block'>
            <RoundIcon
              icon='fas fa-trash'
              color='danger'
              style={{ position: 'absolute', right: -10, top: -10 }}
              tooltip={{ text: 'Eintrag löschen', position: 'left' }}
              onClick={(e) => {
                e.stopPropagation()
                this.onDelete(booking)
              }}
            />
          </div>
        )}
      </li>
    )
  }

  render() {
    return (
      <div className='mt-10 mb-8'>
        <ul className='-mb-8'>{this.props.bookings.map(this.bookingMapper)}</ul>
      </div>
    )
  }
}
