import { uniqueId } from 'helpers/uniqueId'
import { observer } from 'mobx-react'
import * as React from 'react'
import { classNames } from 'helpers/classNames'
import { StylesConfig } from 'react-select'
import AsyncSelect from 'react-select/async'
import styles from './styles.module.scss'
import { InputSelectOption } from '../InputSelect'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { IOrder } from 'contracts/inventory/interfaces/IOrder'

interface Props extends React.HTMLProps<HTMLInputElement> {
  compoundId: string
  className?: string
  children?: Element
  onChoose?: (id: string) => void
  setRef?: (HTMLInputElement) => void
}

const colourStyles: StylesConfig<any> = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    minHeight: '36px',
    backgroundColor: state.isSelected ? '#6366f1' : state.isFocused ? '#e0e7ff' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: state.isSelected ? undefined : '#e0e7ff',
    },
  }),
  control: (styles, options) => ({
    ...styles,
    'input:focus': {
      boxShadow: 'none',
    },
    borderRadius: '0.375rem',
    cursor: 'text',
    boxShadow: options.isFocused
      ? '0 0 0 1px rgb(99, 102, 241)'
      : '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontSize: '14px',
    border: options.isFocused
      ? '1px solid rgb(99, 102, 241) !important'
      : '1px solid rgb(212, 212, 212) !important',
    color: '#000000',
  }),
}

const DropdownIndicator = () => {
  return <div className={styles.dropdownIndicator} />
}

@observer
export class InputInventoryOrder extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('input-')
  }

  private loadOptions = async (inputValue: string): Promise<InputSelectOption[]> => {
    if (!inputValue.trim()) {
      return []
    }
    try {
      const orders = await hermes.indexOnceNew<IOrder>(
        `/api/${
          this.context.instance.id
        }/inventory/orderSearchResults?searchString=${encodeURIComponent(
          inputValue.trim(),
        )}&compoundId=${this.props.compoundId}`,
      )
      return orders.map((o) => ({
        value: o.id,
        label: `#${o.id}${o.number ? ` (${o.number})` : ''}`,
      }))
    } catch (_e) {
      return []
    }
  }

  private onChange = (item: InputSelectOption | null) => {
    if (item) {
      this.props.onChoose?.(item.value)
    }
  }

  render() {
    const { name, label, placeholder, className } = this.props

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <AsyncSelect
          id={this.id}
          name={name}
          styles={colourStyles}
          placeholder={placeholder || ''}
          isClearable
          components={{ DropdownIndicator }}
          noOptionsMessage={() => 'Keine Ergebnisse'}
          loadOptions={this.loadOptions}
          defaultOptions
          onChange={this.onChange}
          value={null}
        />
      </div>
    )
  }
}
