import * as React from 'react'
import { InputResident } from '../../../components/Form/components/InputResident'
import {
  AppContext,
  AppContextProps,
} from '../../../services/connection/models/AppContext'
import { Model } from '../../../components/Form/Model'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { action, observable, runInAction, toJS } from 'mobx'
import { Collection, hermes, Resource } from '@byll/hermes'
import { IResidentSearchResultsFilter } from '../../../contracts/residents/interfaces/IResidentSearchResultsFilter'
import { ParticipantEntry } from './ParticipantEntry'
import { Spinner } from '../../../components/Spinner'
import { observer } from 'mobx-react'
import { SearchCaption } from './SearchCaption'
import { IGroupParticipantFilter } from '../../../contracts/groups/interfaces/IGroupParticipantFilter'
import { IGroup } from '../../../contracts/groups/interfaces/IGroup'
import { IResidentSearchResult } from '../../../contracts/residents/interfaces/IResidentSearchResult'
import * as uuid from 'uuid'
import { box } from 'services/box'
import { IExtendedGroupParticipantEntry } from 'contracts/groups/interfaces/IExtendedGroupParticipantEntry'
import { getGroupParticipantFieldValues } from 'contracts/groups/helpers/getGroupParticipantFieldValues'
import { dayjs } from 'helpers/dayjs'
import { IUser } from 'contracts/users/interfaces/IUser'
import { Tooltip } from 'components/Tooltip'
import { GroupItemSelectDropdown } from './GroupItemSelectDropdown'
import { getCurrentResponsibilityCompoundId } from 'contracts/residents/helpers/getCurrentResponsibilityCompoundId'
import { toast } from 'react-toastify'

interface Props {
  group: IGroup
}

const widths = {
  text: 'min-w-[200px]',
  select: 'min-w-[200px]',
  date: 'min-w-[130px]',
  checkbox: '',
}

@observer
export class ParticipantsList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly group: IGroup
  private readonly model = new Model<{ residentId: string | null }>({ residentId: null })
  private readonly disposers: Disposer[] = []
  private readonly createdBy: Resource<IUser>
  private readonly selected = observable(new Set<string>())
  private readonly entries: Collection<
    IExtendedGroupParticipantEntry,
    { sort: string },
    IGroupParticipantFilter
  >

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.group = toJS(props.group) // Copy that will not change. If group fields change, the
    this.createdBy = new Resource(
      `/api/${context.instance.id}/users/${this.group.createdBy}`,
    )
    this.entries = new Collection(
      `/api/${context.instance.id}/groups/${props.group.id}-${props.group.version}/participants`,
      observable({ sort: '' }),
    )
  }

  componentDidMount() {
    this.disposers.push(this.createdBy.init({ readOnly: true }))
    this.disposers.push(this.entries.init({ observeQuery: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onChoose = (residentId: string | null, label: string | null) => {
    if (!residentId || label === null) {
      return
    }
    runInAction(() => (this.model.values.residentId = null))
    const name = label.split(', ')
    const entry: IExtendedGroupParticipantEntry = {
      id: uuid.v4(),
      groupId: this.props.group.id,
      residentId: residentId,
      imageId: null,
      sex: null,
      firstName: name[1] || '',
      lastName: name[0] || '',
      dateOfBirth: null,
      nationality: '',
      accommodation: null,
      responsibleCompoundId: null,
      ...getGroupParticipantFieldValues({ fields: {} } as any, this.group.fields),
    }
    const available = this.entries.resources?.find(
      (r) => r.data?.residentId === residentId,
    )
    if (available) {
      void box.alert('Bereits hinzugefügt', 'Dieser Bewohner ist bereits auf der Liste.')
    } else {
      const resource = new Resource<IExtendedGroupParticipantEntry>(
        `/api/${this.context.instance.id}/groups/${this.group.id}-${this.group.version}/participants/${entry.id}`,
      )
      this.disposers.push(
        resource.init({
          implicit: true,
          create: { data: entry, notifySelf: false, indexPath: this.entries.path },
        }),
      )
      /**
       * Hermes immediate resource is not valid yet. room, imageId, sex and dateOfBirth are not available.
       * Option 1 would be to notify the resource again from the backend. But we could not ensure that the
       * backend notification arrives after the patch request returns. Even if it is fired with a delay, there
       * could be other subscribers that use up the delay time. Therefore we just update the resource afterwards.
       * This provokes a patch request that contains non-patchable data which is ignored without error in the controller.
       * -> Frontend is recent.
       */
      hermes
        .getOnceNew<IResidentSearchResult>(
          `/api/${this.context.instance.id}/residentSearchResults/${residentId}`,
          { getFromStoreIfPossible: true },
        )
        .then(
          action((resident) => {
            if (!resource.data) {
              return
            }
            resource.data.imageId = resident.imageId
            resource.data.sex = resident.sex
            resource.data.dateOfBirth = resident.dateOfBirth
            resource.data.nationality = resident.data.nationality || ''
            resource.data.accommodation = resident.accommodation
            resource.data.responsibleCompoundId = getCurrentResponsibilityCompoundId(
              resident.data.bookings || [],
            )
          }),
        )
    }
  }

  private getFilter = (compoundId: string): IResidentSearchResultsFilter => ({
    responsibleCompoundId: compoundId,
    responsibleScope: 'active',
    page: '0,6',
    deleted: 'no',
  })

  private mapParticipant = (res: Resource<IExtendedGroupParticipantEntry>) => {
    if (!res.data) {
      return null
    }
    return (
      <ParticipantEntry
        entry={res.data}
        key={res.id}
        group={this.group}
        selected={this.selected}
      />
    )
  }

  private deleteSelected = async (e) => {
    e.stopPropagation()
    if (
      !(await box.alert(
        'Einträge löschen',
        'Möchten Sie die markieren Einträge wirklich aus dieser Gruppe löschen?',
        { confirm: 'Ja, löschen', cancel: 'Abbrechen' },
      ))
    ) {
      return
    }
    try {
      await hermes.create(
        `/api/${this.context.instance.id}/groups/${this.group.id}-${this.group.version}/batchDelete`,
        { items: Array.from(this.selected.values()) },
      )
      toast.success('Einträge erfolgreich gelöscht.')
    } catch (e) {
      toast.error('Einträge konnten nicht gelöscht werden.')
    }
  }

  render() {
    const entries = this.entries.resources
    if (!entries) {
      return <Spinner />
    }
    return (
      <div className='flex flex-col pb-6 flex-content'>
        <div className='-my-2 overflow-x-auto'>
          <div className='py-2 align-middle inline-block min-w-full'>
            <div className='shadow border-b border-gray-200 sm:rounded-lg'>
              <table
                className={`min-w-full divide-y divide-gray-200${
                  entries.length === 0 ? ' min-h-[200px]' : ''
                }`}
              >
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      <SearchCaption entries={this.entries} orderBy='name'>
                        <GroupItemSelectDropdown
                          selected={this.selected}
                          entries={this.entries}
                          compoundId={this.group.compoundId}
                        />
                        {this.selected.size > 0 && (
                          <button
                            className='has-tooltip mr-2 text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gradient-to-r from-red-500 to-red-600 hover:bg-gradient-to-r hover:from-red-500 hover:to-red-500 focus:ring-red-500 py-0.5 px-2'
                            onClick={this.deleteSelected}
                          >
                            <span>
                              <i className='fas fa-trash' />
                            </span>
                            <Tooltip position='right'>
                              Ausgewählte Einträge löschen
                            </Tooltip>
                          </button>
                        )}
                        Name
                      </SearchCaption>
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Unterbringung
                    </th>
                    {this.group.fields.map((field) => (
                      <th
                        key={field.id}
                        scope='col'
                        className={`max-w-20 pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap ${
                          widths[field.type]
                        }`}
                      >
                        <SearchCaption entries={this.entries} orderBy={field.id}>
                          {field.label}
                        </SearchCaption>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {entries.map(this.mapParticipant)}
                  {entries.length === 0 && (
                    <tr>
                      <td className='p-6 bg-gray-100 text-gray-500'>
                        Keine Einträge vorhanden
                      </td>
                    </tr>
                  )}
                  {entries.length ===
                    1 /* Filler row to make enough space for dropdown menu */ && (
                    <tr>
                      <td className='h-[74px] bg-gray-100'>&nbsp;</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {this.context.permissions.groups_participants && (
          <div className='bg-white sm:rounded-lg mt-6 shadow p-6'>
            <InputResident
              model={this.model}
              name='residentId'
              label='Neuer Eintrag'
              placeholder='Bewohner auf die Liste setzen'
              onChoose={this.onChoose}
              filter={this.getFilter(this.group.compoundId)}
            />
          </div>
        )}

        {this.createdBy.data && (
          <div className='bg-gray-500 text-white rounded-md p-6 mt-6'>
            <span className='mr-1'>
              <i className='fas fa-info-circle' />
            </span>
            <span>{`Diese Gruppe wurde am ${dayjs(this.props.group.createdAt).format(
              'DD.MM.YYYY',
            )} von ${this.createdBy.data.firstName} ${
              this.createdBy.data.lastName
            } erstellt.`}</span>
          </div>
        )}
      </div>
    )
  }
}
