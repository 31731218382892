import * as React from 'react'

import styles from './styles.module.scss'

interface CircularProgressbarProps {
  percentage?: number
  strokeWidth?: number
  progressStyle?
}

/**
 * Adapted from https://github.com/iqnivek/react-circular-progressbar
 */
export class CircularProgressbar extends React.Component<CircularProgressbarProps, {}> {
  public static defaultProps = {
    strokeWidth: 8,
  }

  render() {
    const radius = 50 - this.props.strokeWidth! / 2
    const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `

    // If no percentage is given, rotate a small segment
    const percentage = this.props.percentage === undefined ? 0.03 : this.props.percentage

    const diameter = Math.PI * 2 * radius
    const progressStyle = {
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${(1 - percentage) * diameter}px`,
      ...this.props.progressStyle,
    }

    return (
      <svg className={styles.circularProgressbar} viewBox='0 0 100 100'>
        <path
          className={styles.circularProgressbarTrail}
          d={pathDescription}
          strokeWidth={this.props.strokeWidth}
          fillOpacity={0}
        />

        <path
          className={`${styles.circularProgressbarPath} ${
            this.props.percentage === undefined ? styles.animated : ''
          }`}
          d={pathDescription}
          strokeWidth={this.props.strokeWidth}
          fillOpacity={0}
          style={progressStyle}
        />
      </svg>
    )
  }
}
