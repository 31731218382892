import * as React from 'react'
import styles from '../styles.module.scss'

interface Props {
  onClick: () => void
}

export const AppNotConnected: React.FC<Props> = (props) => (
  <div className={styles.disconnected}>
    <div className={styles.text}>
      <div className={styles.caption}>Verbindung wurde unterbrochen</div>
      <div className={styles.body}>
        Das kann passieren, wenn die Seite im Hintergrund
        <br />
        ausgeführt wird oder Ihr Internet gerade langsam ist.
        <br />
      </div>
      <button
        className={`${styles.retry} 'py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        onClick={props.onClick}
      >
        Erneut verbinden
      </button>
    </div>
  </div>
)
