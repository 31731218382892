import { InputSelectOption } from 'components/Form/components/InputSelect'

export const maritalStatusOptions: InputSelectOption[] = [
  { value: '', label: '' },
  { value: 'Ledig', label: 'Ledig' },
  { value: 'Verheiratet', label: 'Verheiratet' },
  { value: 'Getrennt lebend', label: 'Getrennt lebend' },
  { value: 'Geschieden', label: 'Geschieden' },
  { value: 'Verwitwet', label: 'Verwitwet' },
  { value: 'Sonstiges', label: 'Sonstiges' },
  { value: 'Unbekannt', label: 'Unbekannt' },
]
