import { Collection } from '@byll/hermes'
import { XIcon } from '@heroicons/react/outline'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  items: Collection<IInventoryItem>
  bundle: IInventoryBundle
  onClose: () => void
}

export class InventoryBundleDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private bundleItems: IInventoryItem[] = []

  constructor(props: Props) {
    super(props)
    for (const item of props.items.resources || []) {
      if (item.data?.bundleIds.includes(props.bundle.id) && item.data.isDispensable) {
        this.bundleItems.push(item.data)
      }
    }
  }

  render() {
    return (
      <div className='p-6'>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <span className='text-gray-500 text-xl'>{this.props.bundle.label}</span>
        <div className='min-h-[250px]'>
          {this.bundleItems.length === 0 && (
            <div className='text-gray-500 mt-5'>
              Es befinden sich keine Gegenstände im Paket
            </div>
          )}
          {this.bundleItems.length > 0 && (
            <div className='mt-5'>
              <span className='text-gray-500'>Gegenstände im Paket:</span>
              <div className='flex flex-wrap gap-5 mt-2'>
                {this.bundleItems.map((i, j) => {
                  return (
                    <div
                      key={j}
                      className='h-[200px] w-[200px] relative hover:bg-slate-100'
                    >
                      <img
                        src={getInventoryImageSrc(i.imageId, this.context.instance.id)}
                        alt=''
                      />
                      <div
                        className={`absolute bottom-0 left-0 right-0 z-10 text-center bg-gray-900 opacity-50 text-white leading-10`}
                      >
                        {i.label}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
