import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import dayjs from 'dayjs'
import { z } from 'zod'
import { InputText } from 'components/Form/components/InputText'
import { isPastDate } from '../helpers/isPastDate'
import { isTime } from 'contracts/general/helpers/isTime'
import { makeObservable, observable, runInAction } from 'mobx'
import { box } from 'services/box'
import { isYmdDate } from 'contracts/general/helpers/isYmdDate'
import { InputBuildingListFoldable } from 'components/Form/components/InputBuildingListFoldable'

interface Props {}

@observer
export class Laenderstatistik extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model = new Model<{ buildingIds: string; date: string; time: string }>(
    {
      buildingIds: null,
      date: dayjs().format('YYYY-MM-DD'),
      time: dayjs().format('HH:mm'),
    },
    z.object({
      buildingIds: z.string(),
      date: z.string().refine(isYmdDate),
      time: z.string().refine(isTime),
    }),
  )
  @observable private loading: boolean = false

  private get time(): string | null {
    if (!isTime(this.model.values.time) || !this.model.values.date) {
      return null
    }
    const parts = this.model.values.time.split(':')
    return dayjs(this.model.values.date)
      .add(+parts[0], 'h')
      .add(+parts[1], 'm')
      .toISOString()
  }

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private downloadReport = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (!isPastDate(this.model.values.date)) {
      box.alert('Datum ungültig', 'Das Datum darf nicht in der Zukunft liegen.')
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'laenderstatistik',
      this.context.instance.id,
      { buildingIds: this.model.values.buildingIds, timestamp: this.time },
      'Länderstatistik.xlsx',
    )
    runInAction(() => (this.loading = false))
  }

  render() {
    if (!this.context.permissions.report_laenderstatistik) {
      return <Forbidden />
    }
    return (
      <CardColumnCard
        title='Länderstatistik'
        subtitle='Wählen Sie die Gebäude und einen Zeitpunkt aus, für das die Länderstatistik erstellt werden soll.'
      >
        <div id={this.model.id}>
          <div className='flex mb-4 gap-4'>
            <InputDate label='Datum' name='date' model={this.model} />
            <InputText
              label='Uhrzeit'
              name='time'
              model={this.model}
              placeholder='HH:mm'
            />
          </div>
          <InputBuildingListFoldable
            className='max-h-[800px] overflow-auto'
            name='buildingIds'
            model={this.model}
          />
        </div>
        <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
