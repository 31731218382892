import { Menu, Transition } from '@headlessui/react'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { Model } from 'components/Form/Model'
import { ITransferPlanningsFilter } from 'contracts/transfer/interfaces/ITransferPlanningsFilter'
import { ITransferTarget } from 'contracts/transfer/interfaces/ITransferTarget'
import { ITransferTransportation } from 'contracts/transfer/interfaces/ITransferTransportation'
import { classNames } from 'helpers/classNames'
import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Fragment } from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { TransferTargetDialog } from './TransferTargetDialog'
import { TransferTransportationDialog } from './TransferTransportationDialog'

interface Props {
  name: string
  label?: string
  id?: string
  readOnly?: boolean
  options: InputSelectOption[]
  className?: string
  model: Model<ITransferPlanningsFilter>
  onSelect: () => void
  transportations?: ITransferTransportation[]
  targets?: ITransferTarget[]
  loadOptions: () => void
}

@observer
export class TransferPlanningDropdown extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('transfer-planning-dropdown-')
    makeObservable(this)
  }

  @action
  private setOption = (option: InputSelectOption) => {
    if (option.value === '-1') {
      this.props.onSelect()
    } else {
      if (this.props.transportations) {
        this.props.model.values.transportationId = option.value
      }
      if (this.props.targets) {
        this.props.model.values.targetId = option.value
      }
    }
  }

  private edit = async (o: InputSelectOption) => {
    if (
      !this.context.permissions.residentTransferPlanningList_addPersons ||
      this.props.readOnly
    ) {
      return
    }
    if (this.props.transportations) {
      const transportation = this.props.transportations.find((t) => t.id === o.value)
      if (!transportation) {
        return
      }
      const promise = box.custom(
        <TransferTransportationDialog
          transportation={transportation}
          onClose={(id?: string) => promise.close(id)}
        />,
        { context: this.context },
      )
      const id = await promise
      if (id) {
        this.props.loadOptions()
        runInAction(() => (this.props.model.values.transportationId = id))
      }
    }
    if (this.props.targets) {
      const target = this.props.targets.find((t) => t.id === o.value)
      if (!target) {
        return
      }
      const promise = box.custom(
        <TransferTargetDialog
          target={target}
          onClose={(id?: string) => promise.close(id)}
        />,
        { context: this.context },
      )
      const id = await promise
      if (id) {
        this.props.loadOptions()
        runInAction(() => (this.props.model.values.targetId = id))
      }
    }
  }

  private getTitle = () => {
    if (this.props.transportations) {
      return this.props.model.values.transportationId
        ? this.props.options.find(
            (o) => o.value === this.props.model.values.transportationId,
          )?.label
        : this.props.options.find((o) => o.value === null)?.label
    }
    if (this.props.targets) {
      return this.props.model.values.targetId
        ? this.props.options.find((o) => o.value === this.props.model.values.targetId)
            ?.label
        : this.props.options.find((o) => o.value === null)?.label
    }
  }

  render() {
    let innerClassName =
      'block text-left p-3 w-full shadow-sm text-sm border-gray-300 rounded-md border-[1px] py-[0.5rem] px-[0.75rem] min-w-[160px]'
    const { label, className } = this.props
    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <div>
          <Menu id={this.id} as='span' onClick={(e) => e.stopPropagation()}>
            <div>
              <Menu.Button className={`${innerClassName}`}>{this.getTitle}</Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='z-10 origin-top-right absolute left-0 divide-y divide-gray-200 mt-2 w-max rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='py-1'>
                  {this.props.options.map((o) => (
                    <Menu.Item key={o.value}>
                      <div
                        className='cursor-pointer hover:bg-gray-100 flex items-center justify-between'
                        onClick={() => this.setOption(o)}
                      >
                        <span className={`px-4 py-2 text-sm hover:bg-gray-100'}`}>
                          {o.label}
                        </span>
                        {o.value &&
                          o.value !== '-1' &&
                          this.context.permissions
                            .residentTransferPlanningList_addPersons &&
                          !this.props.readOnly && (
                            <div
                              className='mr-2'
                              onClick={(e) => {
                                e.stopPropagation()
                                this.edit(o)
                              }}
                            >
                              <i className='far fa-edit' />
                            </div>
                          )}
                      </div>
                    </Menu.Item>
                  ))}
                </div>
                {this.context.permissions.residentTransferPlanningList_addPersons &&
                  !this.props.readOnly && (
                    <div>
                      <Menu.Item>
                        <div
                          className='cursor-pointer hover:bg-gray-100 flex items-center justify-between'
                          onClick={() => this.setOption({ value: '-1', label: '' })}
                        >
                          <span className={`px-4 py-2 text-sm hover:bg-gray-100'}`}>
                            {`Neues ${
                              this.props.transportations ? 'Transportmittel' : 'Ziel'
                            } hinzufügen`}
                          </span>
                        </div>
                      </Menu.Item>
                    </div>
                  )}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    )
  }
}
