import { Menu, Transition } from '@headlessui/react'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputSelect } from 'components/Form/components/InputSelect'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { action, makeObservable } from 'mobx'
import * as React from 'react'

interface Props {
  model: Model<ITodoSearchResultFilter>
}

const options = [
  { value: null, label: 'Bitte wählen...' },
  { value: 'all', label: 'Alle' },
  { value: 'new', label: 'Neu' },
  { value: 'assigned', label: 'Zugewiesen' },
  { value: 'in progress', label: 'In Bearbeitung' },
  { value: 'done without rating', label: 'Erledigt' },
  { value: 'rated', label: 'Bewertet' },
]

export class TicketProgressFilter extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private reset = () => {
    this.props.model.values.status = null
  }

  render() {
    let label = 'Status'
    let color: string = 'text-gray-500'
    if (
      this.props.model.values.status !== 'due' &&
      this.props.model.values.status !== 'open' &&
      this.props.model.values.status !== 'scheduled' &&
      this.props.model.values.status !== 'done' &&
      !!this.props.model.values.status
    ) {
      label =
        options.find((option) => option.value === this.props.model.values.status)
          ?.label || 'Status'
      color = 'text-blue-500 max-w-[200px] truncate'
    }
    return (
      <Menu as='div' className='flex-content cursor-pointer'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-4 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputSelect
                model={this.props.model}
                name='status'
                label='Status'
                options={options}
              />
              <Menu.Item>
                <div className='flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
