import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { action, makeObservable, observable, reaction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Button } from 'components/Form/components/Button'
import { z } from 'zod'
import { InputText } from 'components/Form/components/InputText'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { dayjs } from 'helpers/dayjs'
import { InputDate } from 'components/Form/components/InputDate'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { Tooltip } from 'components/Tooltip'
import { RoundIcon } from 'components/RoundIcon'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'

interface Props {
  onClose: () => void
  todo: ITodoSearchResult
  changed: { hasUnsavedChanges: boolean }
}

@observer
export class TodoDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private readonly model: Model<
    ITodoSearchResult & { isDone: boolean; hasGateMessage: boolean }
  >
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    const validator = z.object({
      label: z.string().min(1).max(255),
      doneDate: z
        .union([z.string(), z.null()])
        .refine((val) => !!val || !this.model.values.isDone),
      gateMessage: z.string().refine((val) => !!val || !this.model.values.hasGateMessage),
    })

    this.model = new Model(
      {
        ...props.todo,
        isDone: !!props.todo.doneDate,
        hasGateMessage: !!props.todo.gateMessage.trim(),
      },
      validator,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => toJS(this.model.values),
        (current) => {
          for (const key of Object.keys(current)) {
            if (key === 'isDone') {
              continue
            }
            if (current[key] === this.props.todo[key]) {
              continue
            }
            this.props.changed.hasUnsavedChanges = true
            return
          }
          this.props.changed.hasUnsavedChanges = false
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  private onSubmit = () => {
    this.model.values.label = this.model.values.label.trim()
    this.model.values.gateMessage = this.model.values.gateMessage.trim()
    this.model.values.doneDate = this.model.values.isDone
      ? this.model.values.doneDate
      : null
    this.model.values.label = this.model.values.label.trim()
    this.model.values.notes = this.model.values.notes.trim()
    if (!this.model.values.hasGateMessage) {
      this.model.values.gateMessage = ''
      this.model.values.gateMessageValidTill = null
    }

    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    const data: Partial<ITodoSearchResult> = {
      residentId: this.model.values.residentId,
      priority: this.model.values.priority,
      dueDate: this.model.values.dueDate,
      doneDate: this.model.values.doneDate,
      label: this.model.values.label,
      notes: this.model.values.notes,
      gateMessage: this.model.values.gateMessage,
      gateMessageValidTill: this.model.values.gateMessageValidTill,
    }
    if (!this.model.values.id) {
      // Create instead of patch
      hermes
        .create(
          `/api/${this.context.instance.id}/todoLists/${this.context.defaults.residentTodoListId}/todos`,
          data,
        )
        .catch(() =>
          alert(
            'Beim Anlegen des Todos ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.',
          ),
        )
    } else {
      delete data.residentId
      if (this.props.todo.managed) {
        delete data.label
      } // Cannot be changed for managed todos
      hermes
        .patch(
          `/api/${this.context.instance.id}/todoLists/${this.context.defaults.residentTodoListId}/todos/${this.model.values.id}`,
          data,
        )
        .catch(() =>
          alert(
            'Beim Speichern des Todos ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator, falls Sie Hilfe benötigen.',
          ),
        )
    }
    this.props.changed.hasUnsavedChanges = false
    this.props.onClose()
  }

  @action
  private toggleTodo = () => {
    if (this.model.values.isDone) {
      this.model.values.isDone = false
      this.model.values.doneDate = null
    } else {
      this.model.values.isDone = true
      this.model.values.doneDate = dayjs().format('YYYY-MM-DD')
    }
  }

  @action
  private addGateMessage = () => {
    this.model.values.hasGateMessage = true
  }

  @action
  private deleteGateMessage = () => {
    this.model.values.hasGateMessage = false
    this.model.values.gateMessage = ''
    this.model.values.deliveredAt = null
  }

  @action
  private resetDeliveredAt = () => {
    hermes.patch(
      `/api/${this.context.instance.id}/todoLists/${this.context.defaults.residentTodoListId}/todos/${this.props.todo.id}`,
      {
        deliveredAt: null,
      },
    )
  }

  render() {
    const disabled = this.context.permissions.resident_todos < 2
    return (
      <div id={this.model.id}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                Todo
              </Dialog.Title>
            </div>
          </div>
        </div>

        <div className='p-6 flex flex-col gap-4'>
          <div className='flex'>
            <div className='flex-content' style={{ paddingTop: 6 }}>
              <input
                disabled={disabled}
                type='checkbox'
                checked={this.model.values.isDone}
                className={`rounded-full ${
                  this.model.values.isDone ? 'border-0' : 'border border-gray-300'
                } h-5 w-5 text-green-500 mr-2`}
                onChange={this.toggleTodo}
              />
            </div>
            <div style={{ flex: '0 0 120px' }}>
              <InputDate
                disabled={!this.model.values.isDone || disabled}
                className='flex-auto'
                model={this.model}
                name='doneDate'
                label='Erledigt am'
              />
            </div>
            <InputText
              disabled={this.props.todo.managed || disabled}
              className='flex-auto ml-4'
              model={this.model}
              name='label'
              label='Bezeichnung'
            />
          </div>
          <InputTextarea
            disabled={disabled}
            model={this.model}
            name='notes'
            label='Notiz'
            rows={3}
          />

          {!this.model.values.hasGateMessage && !disabled && (
            <div className='-my-3'>
              <button
                className='text-sm text-gray-500 hover:underline hover:text-blue-500'
                onClick={this.addGateMessage}
              >
                Pfortenbenachrichtigung hinzufügen
              </button>
            </div>
          )}

          {this.model.values.hasGateMessage && (
            <>
              <div className='relative'>
                <InputTextarea
                  disabled={disabled}
                  model={this.model}
                  name='gateMessage'
                  label='Pfortenbenachrichtigung'
                  rows={3}
                />
                {!disabled && (
                  <RoundIcon
                    tooltip={{
                      text: 'Pfortenbenachrichtigung löschen',
                      position: 'left',
                    }}
                    style={{ position: 'absolute', top: -8, right: -8 }}
                    icon='fas fa-trash'
                    color='danger'
                    onClick={this.deleteGateMessage}
                  />
                )}
                {this.props.todo.deliveredAt && (
                  <>
                    <span className='text-sm text-slate-500'>
                      Übermittelt am{' '}
                      {dayjs(this.props.todo.deliveredAt).format('DD.MM.YYYY HH:mm')}
                    </span>
                    {!disabled && (
                      <button
                        className='has-tooltip'
                        onClick={this.resetDeliveredAt}
                        color='secondary'
                      >
                        <i className='fas fa-undo ml-2 text-slate-500 inline' />
                        &nbsp;
                        <Tooltip>Zurücksetzen</Tooltip>
                      </button>
                    )}
                  </>
                )}
              </div>
              <InputDate
                disabled={disabled}
                model={this.model}
                name='gateMessageValidTill'
                label='Benachrichtigung anzeigen bis'
                placeholder='Unbegrenzt'
              />
            </>
          )}
        </div>

        <div
          className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button color='secondary' outline onClick={this.props.onClose}>
            {disabled ? 'Schließen' : 'Abbrechen'}
          </Button>
          {!disabled && (
            <Button color='primary' className='ml-2' onClick={this.onSubmit}>
              Speichern
            </Button>
          )}
        </div>
      </div>
    )
  }
}
