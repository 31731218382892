import { z } from 'zod'
import { isISODate, isoDateErrorMessage } from '../../general/helpers/isISODate'

export const BlogpostValidator = z.object({
  id: z.string().min(1).max(255),
  title: z.string().min(1).max(255),
  subtitle: z.string().min(1).max(255),
  text: z.string().min(1),
  imageId: z.string().min(1).max(255),
  createdAt: z.string().refine(isISODate, isoDateErrorMessage),
  isActive: z.boolean(),
  markdown: z.boolean(),
})
