import * as React from 'react'

export interface ToggleContextProps {
  isOpen: boolean
  toggle: (isOpen?: boolean) => void
}

// This object implements BusinessEntityContextProps, but it throws
// an error instead of returning requested props. This mechanism helps
// to detect forbidden context access outside of provider tree.
const warning: ToggleContextProps = {
  get isOpen(): boolean {
    console.warn('Accessed context.isOpen without context provider.')
    throw new Error('Accessed context.isOpen without context provider.')
  },
  get toggle(): (isOpen?: boolean) => void {
    console.warn('Accessed context.toggle without context provider.')
    throw new Error('Accessed context.toggle without context provider.')
  },
}

export const ToggleContext = React.createContext<ToggleContextProps>(warning)
