import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { QuarantineTile } from './components/QuarantineTile'
import { CoronaTile } from './components/CoronaTile'
import { IHealth } from 'contracts/residents/interfaces/IHealth'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { HealthTable } from '../../../HealthTab/components/HealthTable'
import { dayjs } from 'helpers/dayjs'

interface Props {
  resident: IResident
}

const healthTableCorona = {
  type: 'Corona',
  columns: [
    { label: 'Corona' },
    { label: 'Von', width: 120 },
    { label: 'Bis', width: 120 },
  ],
}

const healthTableQuarantine = {
  type: 'Quarantäne',
  columns: [
    { label: 'Quarantäne' },
    { label: 'Von', width: 120 },
    { label: 'Bis', width: 120 },
  ],
}

@observer
export class OverviewQuarantine extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly health: Collection<IHealth>
  private readonly disposers: Disposer[] = []
  @observable private showMore = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.health = new Collection(
      `/api/${context.instance.id}/residents/${this.props.resident.id}/health`,
      { types: 'Quarantäne,Corona' },
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.health.init())
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action setShowMore = () => (this.showMore = true)

  render() {
    if (this.health.error && this.health.error.id !== RequestPendingError.id) {
      return (
        <div className='bg-gray-100 min-h-full flex overflow-hidden'>
          <LoadingError
            title='Gesundheitsdaten konnten nicht geladen werden'
            subtitle='Bitte wenden Sie sich an einen Administrator'
          />
        </div>
      )
    }

    if (!this.health.resources) {
      return (
        <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
          <Spinner delay />
        </div>
      )
    }

    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Quarantäne</span>
          <br />
          <span className='text-sm text-gray-400'>Corona und Quarantänestatus</span>
        </div>

        <div className='flex-auto min-h-[140px] overflow-hidden'>
          <div className='flex gap-4 relative mt-2'>
            <div className='flex-auto w-1/2'>
              <QuarantineTile
                health={this.health.resources.find(isCurrentQuarantine)?.data}
              />
            </div>
            <div className='flex-auto w-1/2'>
              <CoronaTile health={getRelevantCoronaStatus(this.health)} />
            </div>
          </div>
          {!this.showMore && (
            <span
              onClick={this.setShowMore}
              className='inline-block pt-2 text-gray-500 hover:text-black underline text-sm cursor-pointer'
            >
              Mehr anzeigen...
            </span>
          )}

          {this.showMore && (
            <div className='-mx-[31px] mt-8'>
              <HealthTable
                readOnly={this.context.permissions.resident_overview_quarantine < 2}
                table={healthTableQuarantine as any}
                health={this.health}
                residentId={this.props.resident.id}
              />
              <HealthTable
                readOnly={this.context.permissions.resident_overview_quarantine < 2}
                table={healthTableCorona as any}
                health={this.health}
                residentId={this.props.resident.id}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

function isCurrentQuarantine(health: Resource<IHealth>): boolean {
  if (health.data?.type !== 'Quarantäne') {
    return false
  }
  const today = dayjs()
  return (
    today.isSameOrAfter(health.data.date, 'day') &&
    today.isSameOrBefore(health.data.intakeUntilDate, 'day')
  )
}

function getRelevantCoronaStatus(health: Collection<IHealth>): IHealth | null {
  const today = dayjs()
  return (
    health.resources?.find(
      (h) =>
        h.data?.type === 'Corona' &&
        today.isSameOrAfter(h.data.date, 'day') &&
        today.isSameOrBefore(
          h.data.intakeUntilDate || dayjs(h.data.date).add(10, 'days'),
          'day',
        ),
    )?.data ?? null
  )
}
