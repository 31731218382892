import * as React from 'react'
import { InputResident } from 'components/Form/components/InputResident'
import { Model } from 'components/Form/Model'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { Collection, hermes } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { box } from 'services/box'
import { runInAction } from 'mobx'
import { transferStatus } from 'contracts/transfer/interfaces/transferStatusEnum'
import { TransferPlanningEntry } from './TransferPlanningEntry'
import { TransferPlanningListColumns } from './TransferPlanningListColumns'
import { ITransferPlanningsFilter } from 'contracts/transfer/interfaces/ITransferPlanningsFilter'
import { ITransferPlanningSearchResult } from 'contracts/transfer/interfaces/ITransferPlanningSearchResult'

interface Props {
  model: Model<ITransferPlanningsFilter>
  selectedBuildings: Map<string, boolean>
}

@observer
export class TransferPlanningList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ residentId: string | null }>
  private readonly incoming: Collection<ITransferPlanningSearchResult>
  private readonly outgoing: Collection<ITransferPlanningSearchResult>
  private readonly disposers: Disposer[] = []
  private statusMap: Map<transferStatus, string> = new Map([
    [transferStatus.complete, require('../images/complete.png')],
    [transferStatus.pending, require('../images/pending.png')],
    [transferStatus.ready, require('../images/ready.png')],
    [transferStatus.timeout, require('../images/timeout.png')],
  ])

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({ residentId: null })
    const buildingIds: string[] = []
    for (const [buildingId, selected] of this.props.selectedBuildings) {
      if (selected && buildingId !== '§') {
        buildingIds.push(buildingId)
      }
    }
    const filter = {
      buildingIds: props.selectedBuildings.has('§') ? '§42a' : buildingIds.join(','),
      begin: props.model.values.begin,
      end: props.model.values.end,
      transportationId:
        props.selectedBuildings.size === 1 ? props.model.values.transportationId : null,
      targetId:
        props.selectedBuildings.size === 1 && props.selectedBuildings.has('§')
          ? props.model.values.targetId
          : null,
      createdBy: props.model.values.createdBy,
      status: props.model.values.status,
    }
    this.incoming = new Collection(`/api/${context.instance.id}/transfer/plannings`, {
      ...filter,
      direction: 'incoming',
    })
    this.outgoing = new Collection(`/api/${context.instance.id}/transfer/plannings`, {
      ...filter,
      direction: 'outgoing',
    })
  }

  componentDidMount(): void {
    this.disposers.push(this.incoming.init())
    this.disposers.push(this.outgoing.init())
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private isEditable = () => {
    if (
      !this.props.model.values.begin ||
      !this.props.model.values.end ||
      !dayjs(this.props.model.values.begin).isSame(
        dayjs(this.props.model.values.end),
        'day',
      )
    ) {
      return 2 // false
    } else if (this.props.selectedBuildings.size !== 1) {
      return 1 // false
    } else {
      return 0 //true
    }
  }

  private onChoose = async () => {
    if (this.isEditable() !== 0) {
      return
    }
    if (!this.model.values.residentId) {
      return
    }
    if (
      this.incoming.resources?.find(
        (t) => t.data?.resident.id === this.model.values.residentId,
      )
    ) {
      box.alert('Fehler', 'Dieser Benutzer befindet sich bereits auf der Liste.')
      return
    }
    try {
      const targetBuildingId = this.props.selectedBuildings.has('§')
        ? null
        : Array.from(this.props.selectedBuildings.keys())[0]
      const data = {
        residentId: this.model.values.residentId,
        transferTargetBuildingId: targetBuildingId,
        transferTargetId: this.props.selectedBuildings.has('§')
          ? this.props.model.values.targetId
          : null,
        transferTransportationId: this.props.model.values.transportationId,
        date: dayjs(this.props.model.values.begin).format('YYYY-MM-DD'),
      }
      runInAction(() => (this.model.values.residentId = null))
      await hermes.create(`/api/${this.context.instance.id}/transfer/plannings`, data)
    } catch (_e) {
      box.alert(
        'Fehler',
        'Beim Hinzufügen des Bewohners ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
      )
    }
  }

  render() {
    return (
      <div className='m-6 flex flex-col'>
        {this.context.permissions.residentTransferPlanningList_addPersons && (
          <div className='border-b-2 border-blue-400 mb-4 text-sm text-blue-400'>
            {this.isEditable() === 0 && (
              <InputResident
                className='mb-2'
                model={this.model}
                name='residentId'
                placeholder='Person auf die Liste setzen'
                onChoose={this.onChoose}
              />
            )}
            {this.isEditable() === 1 && (
              <span>
                Wählen Sie genau ein Zielgebäude aus, um Personen auf die Transferliste zu
                setzen
              </span>
            )}
            {this.isEditable() === 2 && (
              <span>
                Setzen Sie das 'ab'- und das 'bis'-Datum auf den selben Tag, um Transfers
                für diesen Tag zu planen
              </span>
            )}
          </div>
        )}
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <TransferPlanningListColumns label='Check-in' />
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {this.incoming.resources?.length === 0 && (
                    <tr>
                      <td
                        className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'
                        colSpan={7}
                      >
                        Derzeit sind keine Check-Ins mit Ihren Filterkriterien geplant
                      </td>
                    </tr>
                  )}
                  {this.incoming.resources?.map((t) => {
                    if (!t.data) {
                      return null
                    }
                    return (
                      <TransferPlanningEntry
                        key={t.id}
                        statusMap={this.statusMap}
                        transfer={t.data}
                      />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='-my-2 mt-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <TransferPlanningListColumns label='Check-out' />
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {this.outgoing.resources?.length === 0 && (
                    <tr>
                      <td
                        className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'
                        colSpan={7}
                      >
                        Derzeit sind keine Check-Outs mit Ihren Filterkriterien geplant
                      </td>
                    </tr>
                  )}
                  {this.outgoing.resources?.map((t) => {
                    if (!t.data) {
                      return null
                    }
                    return (
                      <TransferPlanningEntry
                        key={t.id}
                        statusMap={this.statusMap}
                        transfer={t.data}
                      />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
