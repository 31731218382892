import { NotAuthenticatedError, NotFoundError } from 'contracts/errors/HermesErrors'
import {
  connected,
  State as ConnectionState,
} from '@byll/hermes/lib/interfaces/ConnectionData'
import { RequestTimeoutError } from '@byll/hermes/lib/errors/HermesErrors'
import { AppStatus } from './helpers/getAppStatus'
import { connectHermes } from './helpers/connectHermes'
import { connection } from '@byll/hermes/lib/services/connection'
import { action, reaction, when } from 'mobx'
import { Collection, Resource } from '@byll/hermes'
import { initStamm } from 'helpers/isStamm'
import { env } from 'env'

export async function init(appStatus: AppStatus) {
  connection.defaultHeaders = { Authorization: `Bearer ${appStatus.sessionId}` }
  try {
    await connectHermes()
  } catch (_e) {
    return
  }

  // Listen to disconnects
  connection.subscribeToConnectionStateChanges(
    action((state: ConnectionState) => {
      appStatus.connected = state === connected
    }),
  )

  // Add request listener for any future requests. The listener redirects to
  // sign out if any future request returns with a "Not Authenticated" error
  connection.subscribeToErrors((error) => {
    if (error.id === NotAuthenticatedError.id) {
      if (env.NODE_ENV === 'development') {
        // Clear session cookie in dev environment
        document.cookie = 'isession=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      }
      window.location.reload()
      return
    }
    // Only disconnect on short timeouts. Long running timeouts might be ok
    // since they might not occure due to connection failures
    // but rather due to service request processing times.
    if (error.id === RequestTimeoutError.id && error.details.timeout <= 10000) {
      connection.disconnect() // Also triggers reconnect screen
    }
  })

  // Cache warming
  const sessionResource = new Resource<any>(
    `/api/${appStatus.instanceId}/sessions/${appStatus.sessionId}`,
  )
  const statusResource = new Resource<any>(`/status/maintenance`)
  const userResource = new Resource<any>(
    `/api/${appStatus.instanceId}/users/${appStatus.userId}`,
  )
  const userDefaultsResource = new Resource<any>(
    `/api/${appStatus.instanceId}/userDefaults/${appStatus.userId}`,
  )
  const permissionsResource = new Resource<any>(
    `/api/${appStatus.instanceId}/users/permissions/${appStatus.userId}`,
  )
  const compoundsCollection = new Collection<any>(
    `/api/${appStatus.instanceId}/accommodations/compounds`,
  )

  sessionResource.init({ readOnly: true })
  statusResource.init({ readOnly: true })
  userResource.init({ readOnly: true })
  userDefaultsResource.init()
  permissionsResource.init({ readOnly: true })
  compoundsCollection.init({ readOnly: true })

  // Watch sessions so that this instance is informed if it is logged out (e.g. from another browser tab)
  when(
    () => sessionResource.error?.id === NotFoundError.id,
    () => {
      if (env.NODE_ENV === 'development') {
        // Clear session cookie in dev environment
        document.cookie = 'isession=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      }
      window.location.reload()
    },
  )

  // Transfer maintenance status to appStatus
  reaction(
    () => statusResource.data?.maintenance,
    (mnt) => {
      if (appStatus.maintenance !== true && mnt !== undefined) {
        appStatus.maintenance = mnt
      }
    },
    { fireImmediately: true },
  )

  // Wait until all precached resources are available
  await when(
    () =>
      !!(
        sessionResource.data &&
        statusResource.data &&
        userResource.data &&
        permissionsResource.data &&
        userDefaultsResource.data &&
        compoundsCollection.resources
      ),
  )
  ;(window as any).DEV_SIMULATE_INSTANCE = statusResource.data.devSimulateInstance
  initStamm(userDefaultsResource)
}
