import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { isPastDate } from '../helpers/isPastDate'
import { z } from 'zod'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { hermes } from '@byll/hermes'
import { Spinner } from 'components/Spinner'
import { BuildingsSelect } from '../AccommodationBookings/components/BuildingsSelect'
import { box } from 'services/box'
import { isStammBuilding } from 'helpers/isStamm'

interface Props {}

const COMPOUND_MIK = '86'
const COMPOUND_P3 = '87'
const COMPOUND_NBS = '88'

@observer
export class EssenslisteRob extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref buildings: IBuilding[] | null = null
  @observable private selected = new Map<string, boolean>() //key: buildingId value: selected/nicht selected
  @observable private oldVersion: boolean = false
  @observable private loading: boolean = false
  private readonly model = new Model<{ date: string }>(
    { date: null },
    z.object({ date: z.string() }),
  )

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.loadBuidlings()
  }

  private loadBuidlings = async () => {
    const buildings: IBuilding[] = await hermes.indexOnceNew(
      `/api/${this.context.instance.id}/accommodations/buildings`,
    )
    runInAction(() => {
      this.buildings = buildings.filter((b) => {
        return (
          !b.deletedAt &&
          (b.compoundId === COMPOUND_MIK ||
            b.compoundId === COMPOUND_P3 ||
            b.compoundId === COMPOUND_NBS) &&
          isStammBuilding(b.id)
        )
      })
      this.buildings.sort((b1, b2) => (b1.compoundId < b2.compoundId ? -1 : 1))
    })
  }

  private downloadReport = async () => {
    if (this.oldVersion) {
      if (!this.model.isValid()) {
        this.model.setFocusToLeftTopmostInvalidField()
        return
      }
      if (!isPastDate(this.model.values.date)) {
        box.alert('Datum ungültig', 'Das Datum darf nicht in der Zukunft liegen.')
        return
      }
    }
    if (this.selected.size < 1) {
      box.alert(
        'Keine Gebäude ausgewählt',
        'Bitte wählen Sie mindestens ein Gebäude aus.',
      )
      return
    }
    runInAction(() => (this.loading = true))
    const date =
      this.oldVersion && this.model.values.date
        ? this.model.values.date
        : dayjs(new Date()).format('YYYY-MM-DD')
    await createAndDownloadReport(
      'essensliste-rob',
      this.context.instance.id,
      {
        date,
        buildingIds: Array.from(this.selected)
          .filter((s) => s[1] === true)
          .map((s) => s[0]),
      },
      'Essensliste.xlsx',
    )
    runInAction(() => (this.loading = false))
  }

  @action
  private toggleVersion = () => {
    this.oldVersion = !this.oldVersion
  }

  @action private selectAllBuildings = () => {
    this.selected.clear()
    this.buildings?.forEach((b) => this.selected.set(b.id, true))
  }
  @action private selectMik = () => {
    this.selected.clear()
    this.buildings?.forEach((b) => {
      if (b.compoundId === COMPOUND_MIK) {
        this.selected.set(b.id, true)
      }
    })
  }
  @action private selectP3 = () => {
    this.selected.clear()
    this.buildings?.forEach((b) => {
      if (b.compoundId === COMPOUND_P3) {
        this.selected.set(b.id, true)
      }
    })
  }
  @action private selectNbs = () => {
    this.selected.clear()
    this.buildings?.forEach((b) => {
      if (b.compoundId === COMPOUND_NBS) {
        this.selected.set(b.id, true)
      }
    })
  }

  render() {
    if (!this.context.permissions.report_essensliste_rob) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Essensliste'
        subtitle='Wählen Sie die Gebäude aus, deren Bewohner die in die Liste aufgenommen werden sollen.'
      >
        <div>
          <div className='flex gap-4 mb-4'>
            <Button onClick={this.selectAllBuildings} color='secondary' outline>
              Alle
            </Button>
            <Button onClick={this.selectMik} color='secondary' outline>
              MIK
            </Button>
            <Button onClick={this.selectP3} color='secondary' outline>
              P3
            </Button>
            <Button onClick={this.selectNbs} color='secondary' outline>
              NBS
            </Button>
          </div>
          <div className='flex flex-col gap-1'>
            {!this.buildings && <Spinner />}
            {this.buildings &&
              this.buildings.map((building) => (
                <div key={building.id}>
                  <BuildingsSelect selected={this.selected} building={building} />
                </div>
              ))}
          </div>
          <div className='mt-6'>
            <button
              className={`text-sm ${
                this.oldVersion ? 'text-blue-500 underline' : 'text-gray-500'
              } hover:underline hover:text-blue-500 block`}
              onClick={this.toggleVersion}
            >
              {this.oldVersion
                ? 'Aktuelle Version herunterladen'
                : 'Vergangene Version herunterladen'}
            </button>
            {this.oldVersion && (
              <div id={this.model.id} className='flex mt-4'>
                <InputDate label='Datum' name='date' model={this.model} />
              </div>
            )}
          </div>
          <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
            Download
          </Button>
        </div>
      </CardColumnCard>
    )
  }
}
