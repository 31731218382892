import { observer } from 'mobx-react'
import * as React from 'react'
import { useDrag } from 'react-dnd'
import { AppContext } from 'services/connection/models/AppContext'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { ITransferPlanningSearchResult } from 'contracts/transfer/interfaces/ITransferPlanningSearchResult'
import { transferStatusMap } from '../helpers/transferStatusMap'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { Age } from 'components/Age'
import { Tooltip } from 'components/Tooltip'

interface Props {
  planning: ITransferPlanningSearchResult
  tooltip: string
}

export const ItemTypes = {
  NAME: 'name',
}

export const TransferPlanResidentListItemDnd: React.FC<Props> = observer(
  ({ planning, tooltip }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.NAME,
      collect: (monitor) => {
        return {
          isDragging: !!monitor.isDragging(),
          item: monitor.getItem(),
        }
      },
      item: {
        residentId: planning.resident.id,
        roomId: planning.resident.accommodation.room?.id || null,
      },
    }))
    const context = React.useContext(AppContext)
    const thumbnail = getResidentImageSrc(
      context.instance.id,
      planning.resident.imageId,
      planning.resident.sex,
      'thumbnail',
    )
    const openLink = () => {
      window.open(
        `/residents/${toJbpId(+planning.resident.id).toLowerCase()}/overview`,
        '_blank',
      )
    }

    return (
      <div
        className='flex bg-white shadow rounded-md p-2'
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
      >
        <img
          className='rounded-full w-10 h-10'
          src={thumbnail}
          alt=''
          width={40}
          height={40}
        />
        <div>
          <div className='flex ml-2'>
            <div className='flex-auto truncate max-w-[175px]'>
              {`${planning.resident.lastName.toUpperCase()}, ${
                planning.resident.firstName
              }`}
              <Age
                className='ml-1 relative -top-[1px]'
                sex={planning.resident.sex}
                dateOfBirth={planning.resident.dateOfBirth}
              />
            </div>
            <div
              onClick={openLink}
              className='flex-content mr-auto ml-2 text-blue-500 hover:text-indigo-900 cursor-pointer'
            >
              <i className='fas fa-external-link-alt' />
            </div>
          </div>
          <div className='text-sm flex'>
            <span className='has-tooltip'>
              <img
                src={transferStatusMap.get(planning.status)}
                alt=''
                className='ml-2 h-5'
              />
              <Tooltip position='right'>{tooltip}</Tooltip>
            </span>
          </div>
        </div>
      </div>
    )
  },
)
