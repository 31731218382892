import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import React from 'react'
import { useDrag } from 'react-dnd'
import { Link } from 'react-router-dom'

interface Props {
  name: string
  residentId: string
  roomId: string | null // source room id
}

export const ItemTypes = {
  NAME: 'name',
}

export const DraggableName: React.FC<Props> = (props) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.NAME,
    collect: (monitor) => {
      return {
        isDragging: !!monitor.isDragging(),
        item: monitor.getItem(),
      }
    },
    item: { residentId: props.residentId, roomId: props.roomId },
  }))

  return (
    <div
      className='inline mx-2 relative top-[1px]'
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <Link
        to={`/residents/${toJbpId(+props.residentId).toLowerCase()}/overview`}
        className='hidden group-hover:inline-flex w-5 text-center pt-1 h-5 pl-1 absolute -top-[2px] -left-2 rounded-full text-xs font-medium bg-blue-500 hover:bg-indigo-500 text-white ml-1'
        target='_blank'
      >
        <i className='fas fa-external-link-alt' />
      </Link>
      {props.name}
    </div>
  )
}
