import { Model } from 'components/Form/Model'
import { IUser } from 'contracts/users/interfaces/IUser'
import { reaction } from 'mobx'
import { storage } from 'services/storage'

export function saveLastUsedScanningStation(
  model: Model<{ scanningStationId: string | null }>,
  user: IUser,
) {
  return reaction(
    () => model.values.scanningStationId,
    (scanningStationId) => {
      if (!scanningStationId) {
        storage.remove(`scanning-station.canteen.${user.id}`)
      } else {
        storage.set(`scanning-station.canteen.${user.id}`, scanningStationId)
      }
    },
  )
}
