import { hermes } from '@byll/hermes'
import { IMealScanResult } from 'contracts/scan/interfaces/IMealScanResult'
import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { MealType, mealDict } from 'contracts/scan/validators/MealScanValidator'
import { dayjs } from 'helpers/dayjs'
import { getResidentUrl } from 'helpers/getResidentUrl'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { HouseBan } from 'modules/Residents/helpers/bookingTypes'
import { SpecialMealPermissions } from './SpecialMealPermissions'
import { Age } from 'components/Age'
import { Button } from 'components/Form/components/Button'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'

interface Props {
  station: IScanningStation
  scan: IMealScanResult
  mealTypes: InputSelectOption[]
}

export const MealScanSuccessTile: React.FC<Props> = observer(
  ({ scan, mealTypes, station }) => {
    const context = React.useContext(AppContext)
    const bgColor = 'bg-green-500'
    const borderColor = 'border-green-500'
    const textColor = 'text-green-500'
    const cardColor = 'bg-green-100'
    const scans = scan.scans
    const accommodation = scan.resident.accommodation

    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
      ;(
        document.getElementById('BTN_WATER') ||
        document.getElementById('BTN_LUNCH_PACKAGE')
      )?.focus()
    }, [])

    const onDelete = async (id: string) => {
      if (
        !(await box.alert(
          'Essensausgabe zurücknehmen',
          'Möchten Sie die Ausgabe dieser Mahlzeit rückgängig machen?',
          { color: 'danger', cancel: 'Abbrechen', confirm: 'Rückgängig machen' },
        ))
      ) {
        return
      }
      try {
        await hermes.delete(`/api/${context.instance.id}/scan/meals/${id}`)
        runInAction(() => (scan.scans = scan.scans.filter((scan) => scan.id !== id)))
      } catch (_e) {
        box.alert('Fehler', 'Die Essensausgabe konnte nicht rückgängig gemacht werden.', {
          color: 'danger',
        })
      }
    }

    const addFood = async (mealType: MealType) => {
      setLoading(true)
      try {
        const result = await hermes.create(`/api/${context.instance.id}/scan/meals`, {
          scanningStationId: station.id,
          mealType,
          token: toJbpId(+scan.resident.id),
          force: true, // force meal-hand-out because user accepted warnings (that were displayed in MealActionDialog)
        })
        runInAction(() =>
          scan.scans.push({ id: result.id, at: result.scannedAt, type: result.type }),
        )
        setTimeout(() => {
          ;(
            document.getElementById('BTN_WATER') ||
            document.getElementById('BTN_LUNCH_PACKAGE')
          )?.focus()
        }, 40)
      } catch (_e) {
        box.alert('Fehler', 'Ausgabe Fehlgeschlagen.', { color: 'danger' })
      }
      setLoading(false)
    }

    const addWater = () => addFood('water')
    const addLunchPackage = () => addFood('lunch package')

    return (
      <div className='bg-white rounded-md shadow-md p-4 flex-auto flex'>
        <div className='flex-[0_0_10%] relative' style={{ fontSize: 100 }} />
        <div className='flex-[1_1_10%] relative'>
          <div
            className={`absolute top-[50%] left-0 right-0 transform -translate-y-1/2 h-6 w-full ${bgColor}`}
          />
        </div>

        <div className='flex-[0_1_500px]  flex flex-col min-w-0'>
          <div className='flex-[1_1_100px] relative'>
            <div
              className={`absolute bottom-0 left-0 right-0 text-center ${textColor}`}
              style={{ fontSize: 40 }}
            >
              {scan.scans.length > 0
                ? `${mealDict.get(scan.type) ?? scan.type} ausgegeben`
                : ''}
            </div>
          </div>
          <div
            className={`p-4 flex-content border-4 rounded-lg min-h-[240px] ${borderColor} ${cardColor}`}
          >
            <div className='flex'>
              {/* Image */}
              <div className='flex-[0_0_40%]'>
                <Link to={getResidentUrl(scan.resident.id)} className='hover:opacity-70'>
                  <img
                    src={getResidentImageSrc(
                      context.instance.id,
                      scan.resident.imageId,
                      scan.resident.sex,
                      'portrait',
                    )}
                    alt={scan.resident.lastName}
                    className='w-full rounded-md'
                  />
                </Link>
              </div>
              {/* Last scans */}
              <div className='flex-auto min-w-0 mx-3 text-sm text-gray-500'>
                <div className='truncate text-xl text-black'>
                  {`${scan.resident.lastName}, ${scan.resident.firstName}`}{' '}
                  <Age dateOfBirth={scan.resident.dateOfBirth} sex={scan.resident.sex} />
                </div>
                {scans.length === 0 && (
                  <div>
                    Essensausgabe wurde
                    <br />
                    rückgängig gemacht
                  </div>
                )}
                {scans.length === 1 && <div>Mahlzeiten heute</div>}
                {scans.map((scan, i) => (
                  <div
                    key={scan.id}
                    className={
                      i === scans.length - 1
                        ? 'text-black truncate group'
                        : 'truncate group'
                    }
                  >
                    {`${dayjs(scan.at).format('HH:mm')} ${
                      mealDict.get(scan.type) || scan.type
                    }`}{' '}
                    <span
                      className='hidden group-hover:inline-flex cursor-pointer hover:text-red-500'
                      onClick={() => onDelete(scan.id)}
                    >
                      <i className='fas fa-undo' />
                    </span>
                  </div>
                ))}

                {mealTypes.some((t) => t.value === 'water') &&
                  !scans.some((s) => s.type === 'water') && (
                    <Button
                      loading={loading}
                      onClick={addWater}
                      id='BTN_WATER'
                      color='blue'
                      className='mt-2 mr-2'
                      style={{ padding: '2px 8px' }}
                    >
                      {!loading && (
                        <span>
                          <i className='fas fa-plus' />
                          &nbsp;
                        </span>
                      )}
                      Wasser
                    </Button>
                  )}
                {mealTypes.some((t) => t.value === 'lunch package') &&
                  !scans.some((s) => s.type === 'lunch package') &&
                  (scan.extra.lunchPackage1 || scan.extra.lunchPackage3) && (
                    <Button
                      loading={loading}
                      onClick={addLunchPackage}
                      id='BTN_LUNCH_PACKAGE'
                      color='primary'
                      className='mt-2'
                      style={{ padding: '2px 8px' }}
                    >
                      {!loading && (
                        <span>
                          <i className='fas fa-plus' />
                          &nbsp;
                        </span>
                      )}
                      Lunchpaket {scan.extra.lunchPackage1 ? '1' : '3'}
                    </Button>
                  )}
              </div>
            </div>

            <div className='mt-4 flex gap-2 flex-wrap'>
              {accommodation && (
                <div className='inline-flex items-center rounded-md bg-green-500 px-2 py-1 text-xs font-medium text-white'>
                  {/*<div className='flex-content mr-1'><bookingType.icon className={`h-4 w-4 ${bookingType.color} rounded-full`} style={{ padding: 1}} aria-hidden="true" /></div>*/}
                  {accommodation.reason === 'Hausverbot' && (
                    <div className='flex-content mr-1'>
                      <HouseBan />
                    </div>
                  )}
                  <div className='flex-auto overflow-hidden'>{accommodation.label}</div>
                </div>
              )}
              {!accommodation && (
                <div className='inline-flex items-center rounded-md bg-green-500 px-2 py-1 text-xs font-medium text-white'>
                  Nicht eingebucht
                </div>
              )}
              {scan.resident.country && (
                <div className='inline-flex items-center rounded-md bg-green-500 px-2 py-1 text-xs font-medium text-white'>
                  {scan.resident.country.nationality || scan.resident.country.country}
                </div>
              )}
              <SpecialMealPermissions item={scan} highlight />
            </div>

            {/*<div className='flex-[0_0_40%] p-4'>
            <img className='rounded-md' src={getResidentImageSrc(context.instance.id, scan.resident.imageId, scan.resident.sex, 'portrait')} alt={scan.resident.lastName} />
          </div>
          <div className='flex-auto py-4 pr-4 min-w-0' style={{ fontSize: 24 }}>
            <div className='truncate'>{`${scan.resident.lastName}, ${scan.resident.firstName}`}</div>

            {/* Last scans *
            <div className='text-sm'>
              {scans.length === 1 && <div>Mahlzeiten heute</div>}
              {scans.map((scan, i) => (
                <div key={scan.id} className={i === scans.length - 1 ? 'text-black truncate group' : 'truncate group'}>
                  {`${dayjs(scan.at).format('HH:mm')} ${mealDict.get(scan.type) || scan.type}`} <span className='hidden group-hover:inline-flex cursor-pointer hover:text-red-500' onClick={() => onDelete(scan.id)}><i className='fas fa-undo' /></span>
                </div>
              ))}
            </div>

            <div className='text-base'>{`Herkunft: ${scan.resident.country?.country || scan.resident.country?.nationality || '-'}`}</div>
            <div className='text-base'>{`Unterkunft: ${scan.resident.accommodation?.label || '-'}`}</div>
              </div>*/}
          </div>
          <div className='flex-[1_1_100px] relative' />
        </div>

        <div className='flex-[1_1_10%] relative'>
          <div
            className={`absolute top-[50%] left-0 right-0 transform -translate-y-1/2 h-6 w-full ${bgColor}`}
          />
        </div>
        <div className='flex-[0_0_10%] relative' style={{ fontSize: 100 }} />
      </div>
    )
  },
)
