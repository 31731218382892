import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../../../../../../../../../../components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { IInternalResidenceFormModel } from '../interfaces/IInternalResidenceFormModel'
import { AppContext } from 'services/connection/models/AppContext'
import { Message } from 'components/Message'
import { makeObservable, observable, runInAction } from 'mobx'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { dayjs } from 'helpers/dayjs'
import { hermes } from '@byll/hermes'
import { getReason } from '../helpers/getReason'

interface Props {
  booking: IBooking
  model: Model<IInternalResidenceFormModel>
  onClose: () => void
  color: 'secondary' | 'warning'
}

@observer
export class InternalResidenceFormStep4 extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: 'string' | null = null
  @observable private creating = true

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.create()
  }

  private create = async () => {
    try {
      const data: Partial<IBooking> & { checkOut?: boolean } = {
        instanceId: this.props.booking.instanceId,
        type: this.props.booking.type,
        compoundId: null,
        residentId: this.props.booking.residentId,
        roomId: this.props.model.values.roomId,
        bed: this.props.model.values.bed,
        isBaby: this.props.model.values.isBaby,
        reason: getReason(this.props.model.values),
        comment: this.props.model.values.comment,
        beginAt: dayjs(
          `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
        ).toISOString(),
        endAt: this.props.model.values.hasEnd
          ? dayjs(
              `${this.props.model.values.endDate} ${this.props.model.values.endTime}`,
            ).toISOString()
          : null,
        documentId: this.props.model.values.documentId,
        extra: {
          food: this.props.model.values.food || undefined,
          rast: this.props.model.values.hasRast || undefined,
          admissionFrom: this.props.model.values.admissionFrom,
          billing:
            this.props.booking.type !== 'internal-residence'
              ? undefined
              : {
                  status: this.props.model.values.billingStatus,
                  category: this.props.model.values.billingCategory,
                  dateOfNoticeCreation:
                    this.props.model.values.billingDateOfNoticeCreation,
                  avDate: this.props.model.values.billingAvDate,
                },
        },
      }

      if (data.endAt && dayjs(data.beginAt).isSameOrAfter(data.endAt)) {
        throw new Error('Der Anfangszeitpunkt muss vor dem Endzeitpunkt liegen.')
      }

      runInAction(() => (this.creating = true))
      await hermes.create(`/api/${this.context.instance.id}/bookings`, data)
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.error = e?.message || 'Die Belegung konnte nicht gespeichert werden.'
        this.creating = false
      })
    }
  }

  render() {
    return (
      <div className='relative flex flex-col mt-4 min-h-[200px]'>
        {this.error && (
          <Message className='mb-3 flex-auto' color='danger'>
            {this.error}
          </Message>
        )}

        <div className='col-span-4 text-right flex-content'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            Schließen
          </Button>
        </div>
        {this.creating && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}
