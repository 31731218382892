import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { observer } from 'mobx-react'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'
import { IInventoryDeliveryItem } from 'contracts/inventory/interfaces/IInventoryDeliveryItem'
import { InputText } from 'components/Form/components/InputText'

interface Props {
  item: IInventoryDeliveryItem
  disabled: boolean
}

@observer
export class InventoryDeliveryItem extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IInventoryDeliveryItem>
  private itemCopy: IInventoryDeliveryItem

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.item)
    this.itemCopy = { ...props.item }
  }

  render() {
    const { item, disabled } = this.props
    return (
      <tr key={item.inventoryItem.id}>
        <td className='pr-3 pl-6 py-4 whitespace-nowrap relative'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <img
                className='h-10 w-10 rounded-full'
                src={getInventoryImageSrc(
                  item.inventoryItem.imageId,
                  this.context.instance.id,
                  'thumbnail',
                )}
                alt=''
              />
            </div>
            <div className='ml-4 flex'>
              <div className='text-sm font-medium text-gray-900 truncate max-w-[200px]'>
                {item.inventoryItem.label}
              </div>
              {!this.itemCopy.count && (
                <span className='ml-3 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 border border-green-500'>
                  Geliefert
                </span>
              )}
            </div>
          </div>
        </td>
        <td className='px-3 py-4 whitespace-nowrap text-sm'>
          <InputText
            maxLength={1000}
            className='max-w-20'
            model={this.model}
            name='notes'
            label='Bemerkung'
            disabled={disabled}
          />
        </td>
        <td className='pl-3 pr-6 py-4 whitespace-nowrap text-sm w-[120px]'>
          <InputDecimal
            className='max-w-20'
            model={this.model}
            name='count'
            label='Anzahl'
            precision={6}
            scale={0}
            notNegative
            placeholder={item.inventoryItem.unit || undefined}
            postfix={item.inventoryItem.unit || undefined}
            disabled={disabled}
          />
        </td>
      </tr>
    )
  }
}
