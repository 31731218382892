import { Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { XIcon } from '@heroicons/react/outline'
import { NotFound } from 'components/Callout/components/NotFound'
import { Dialog } from 'components/Dialog'
import { NotAuthorizedError, NotFoundError } from 'contracts/errors/HermesErrors'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Dialog as UIDialog } from '@headlessui/react'
import { Button } from 'components/Form/components/Button'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { TicketDialogContent } from './TicketDialogContent'
import { getEmptyTodoSearchResult } from 'contracts/todos/helpers/getEmptyTodoSearchResult'

interface Props {
  ticketId: string
  listId: string
  onClose: () => void
}

@observer
export class TicketDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly ticket: Resource<ITodoSearchResult>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.ticket = new Resource(
      `/api/${context.instance.id}/todoLists/${props.listId}/todos/${props.ticketId}`,
    )
    if (this.props.ticketId === 'newTicket') {
      const ticket: ITodoSearchResult = {
        ...getEmptyTodoSearchResult(),
        id: '',
        listId: context.defaults.ticketTodoListId,
        gateMessage: '0-new',
        ratingBy: context.user.id,
      }
      this.ticket.data = ticket
      this.ticket.error = null
    } else if (this.props.ticketId === 'newAction') {
      const ticket: ITodoSearchResult = {
        ...getEmptyTodoSearchResult(),
        id: '',
        listId: context.defaults.actionTodoListId,
        gateMessage: '0-new',
        ratingBy: context.user.id,
      }
      this.ticket.data = ticket
      this.ticket.error = null
    }
  }

  componentDidMount() {
    if (this.props.ticketId !== 'newTicket' && this.props.ticketId !== 'newAction') {
      this.disposers.push(this.ticket.init())
    }
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  render() {
    if (this.ticket.error && this.ticket.error?.id !== RequestPendingError.id) {
      return (
        <Dialog size='lg' open setOpen={this.props.onClose}>
          <div>
            <div className='absolute top-0 right-0 pt-4 pr-6'>
              <button
                type='button'
                className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={this.props.onClose}
              >
                <span className='sr-only'>Close</span>
                <XIcon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>

            <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
              <div className='flex items-start'>
                <div className='-mt-2 text-left'>
                  <UIDialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900'
                  >
                    Ticket
                  </UIDialog.Title>
                </div>
              </div>
            </div>

            <div className='py-10 bg-gray-100'>
              {this.ticket.error.id !== NotFoundError.id && (
                <LoadingError
                  title={
                    this.ticket.error.id === NotAuthorizedError.id
                      ? 'Keine Sichtberechtigung für dieses Ticket'
                      : 'Dieses Ticket konnte nicht geladen werden'
                  }
                  subtitle='Bitte wenden Sie sich an einen Administrator'
                />
              )}
              {this.ticket.error.id === NotFoundError.id && (
                <NotFound
                  title='Dieses Ticket konnte nicht gefunden werden'
                  subtitle='Möglicherweise wurde es in der Zwischenzeit gelöscht'
                />
              )}
            </div>

            <div
              className='py-4 px-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
              style={{ borderRadius: '0 0 8px 8px' }}
            >
              <Button color='secondary' outline onClick={this.props.onClose}>
                Schließen
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }

    if (!this.ticket.data) {
      return null
    }
    return (
      <Dialog size='lg' open setOpen={this.props.onClose}>
        <TicketDialogContent
          key={this.props.ticketId}
          onClose={this.props.onClose}
          ticket={this.ticket}
        />
      </Dialog>
    )
  }
}
