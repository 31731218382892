// contract:protected
export class HermesError extends Error {
  static id = 'HermesError'
  id = 'HermesError'
  base: string = 'HermesError'
  code: number = 500
  details: any = {}
  constructor(message: string) {
    super(message)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HermesError)
    } // to exclude itself from the stack
  }

  static isTypeOf(error) {
    if (typeof error !== 'object' || error === null) {
      return false
    }
    return error.id === this.id
  }
}

export class MaintenanceError extends HermesError {
  static id = 'MaintenanceError'
  id = 'MaintenanceError'
  code = 503
  constructor() {
    super('Inuv api is currently in maintenance mode.')
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MaintenanceError)
    }
  }
}

export class NotAuthenticatedError extends HermesError {
  static id = 'NotAuthenticatedError'
  id = 'NotAuthenticatedError'
  code = 401
  constructor() {
    super(
      'An authorization header of the form authorization: Bearer abcdef1234567890 ' +
        'is required to access this resource',
    )
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotAuthenticatedError)
    }
  }
}

/**
 * This error should be used if user permissions don't permit the requested
 * operation. Please specify in the message which operation is not authorized.
 */
export class NotAuthorizedError extends HermesError {
  static id = 'NotAuthorizedError'
  id = 'NotAuthorizedError'
  code = 403
  constructor(message?: string) {
    super(message || 'Permission denied')
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotAuthorizedError)
    }
  }
}

export class NotFoundError extends HermesError {
  static id = 'NotFoundError'
  id = 'NotFoundError'
  code = 404
  constructor() {
    super('Resource could not be found')
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError)
    }
  }
}

export class AsyncTaskNotFinishedError extends HermesError {
  static id = 'AsyncTaskNotFinishedError'
  id = 'AsyncTaskNotFinishedError'
  code = 530
  constructor(
    message: string,
    public details: any = {},
  ) {
    super(message)
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AsyncTaskNotFinishedError)
    }
  }
}

export class ReadOnlyError extends HermesError {
  static id = 'ReadOnlyError'
  id = 'ReadOnlyError'
  constructor(message: string) {
    super(message)
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ReadOnlyError)
    }
  }
}

export class NotSupportedWithCurrentPlanError extends HermesError {
  static id = 'NotSupportedWithCurrentPlanError'
  id = 'NotSupportedWithCurrentPlanError'
  constructor(message: string) {
    super(message)
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotSupportedWithCurrentPlanError)
    }
  }
}

export class IsDeletedAndCannotBeModifiedError extends HermesError {
  static id = 'IsDeletedAndCannotBeModifiedError'
  id = 'IsDeletedAndCannotBeModifiedError'
  code = 520
  constructor() {
    super('This resource cannot be modified because it was deleted')
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, IsDeletedAndCannotBeModifiedError)
    }
  }
}

export class ValidationError extends HermesError {
  static id = 'ValidationError'
  id = 'ValidationError'
  code = 400
  constructor(public details: any) {
    super('Input data validation failed')
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError)
    }
  }
}

export class FilterError extends HermesError {
  static id = 'FilterError'
  id = 'FilterError'
  code = 400
  constructor(public details: any) {
    super('Url filter validation failed')
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FilterError)
    }
  }
}

export class ConflictError extends HermesError {
  static id = 'ConflictError'
  id = 'ConflictError'
  code = 409
  details
  constructor(message: string, details: any) {
    super(message)
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConflictError)
    }
    this.details = details
  }
}

export class GatewayTimeoutError extends HermesError {
  static id = 'GatewayTimeoutError'
  id = 'GatewayTimeoutError'
  code = 504
  constructor(
    message: string,
    public details: any,
  ) {
    super(message)
    this.details = details
  }
}

export class InstanceNotFoundError extends HermesError {
  static id = 'InstanceNotFoundError'
  id = 'InstanceNotFoundError'
  code = 404
  constructor(id: string) {
    super(`Inuv instance ${id} not found`)
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InstanceNotFoundError)
    }
  }
}

export class InvalidCredentialsError extends HermesError {
  static id = 'InvalidCredentialsError'
  id = 'InvalidCredentialsError'
  code = 401
  details
  constructor(user: string) {
    super(`Invalid credentials for user '${user}'`)
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidCredentialsError)
    }
    this.details = { user }
  }
}

export class InvalidTokenError extends HermesError {
  static id = 'InvalidTokenError'
  id = 'InvalidTokenError'
  code = 401
  constructor() {
    super('Invalid token')
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidTokenError)
    }
  }
}

export class TokenExpiredError extends HermesError {
  static id = 'TokenExpiredError'
  id = 'TokenExpiredError'
  code = 401
  constructor(
    public userId: string,
    public staySignedIn: boolean,
    public data: { action: any; [key: string]: any },
  ) {
    super('Token expired')
    // to exclude itself from the stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenExpiredError)
    }
  }
}
