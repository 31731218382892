import axios from 'axios'

interface IOptions {
  handleErrors?: boolean
}

/**
 * Creates report of type
 */
export async function createAndDownloadReport(
  type: string,
  instanceId: string,
  data,
  fileName = 'Report.xlsx',
  options?: IOptions,
) {
  try {
    const response: any = await axios.post(`/api/${instanceId}/reports`, {
      options: data,
      type,
    })
    const token = response?.data.token
    if (typeof token !== 'string') {
      throw new Error('Invalid return value')
    }
    const win = window.open(
      `/api/${instanceId}/reports?token=${encodeURIComponent(
        token,
      )}&fileName=${encodeURIComponent(fileName)}`,
      '_blank',
    )
    if (win) {
      //Browser has allowed it to be opened
      win.focus()
    } else {
      //Broswer has blocked it
      alert(
        'Die Datei konnte nicht geöffnet werden, weil Ihr Browser POP-Ups blockiert. Erlauben Sie POP-Ups und versuchen Sie es erneut.',
      )
    }
  } catch (e: any) {
    if (options?.handleErrors === false) {
      throw e
    }
    alert('Der Download ist leider fehlgeschlagen.')
  }
}
