import * as React from 'react'
import axios from 'axios'
import { templates } from './templates'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IUser } from 'contracts/users/interfaces/IUser'
import { Model } from 'components/Form/Model'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'

interface Props {
  instanceId: string
  userId: string
  residentId: string // comma separated list
  component: string
  storageId: string // document model is stored in storage with user data
}

export class ResidentDocumentTemplates extends React.Component<
  Props,
  { resident: IResident | null; user: IUser | null; model: Model<any> | null }
> {
  constructor(props: Props) {
    super(props)
    this.state = { resident: null, user: null, model: null }
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    try {
      // Load required data
      const [residentResponse, userResponse, storageResponse] = await Promise.all([
        axios.get<IResident>(
          `/api/${this.props.instanceId}/residents/${this.props.residentId}`,
        ),
        axios.get(`/api/${this.props.instanceId}/users/${this.props.userId}`),
        axios.get<{ id: string; value: any }>(
          `/api/${this.props.instanceId}/storage/${this.props.storageId}`,
        ),
      ])
      this.setState({
        resident: residentResponse.data,
        user: userResponse.data,
        model: new Model(storageResponse.data.value),
      })
      setReadyFlagWhenRenderingComplete()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const Template = templates.get(this.props.component)?.component
    if (!Template || !this.state.resident || !this.state.user || !this.state.model) {
      return null
    }
    return (
      <Template
        resident={this.state.resident}
        user={this.state.user}
        instanceId={this.props.instanceId}
        model={this.state.model}
        readOnly
      />
    )
  }
}
