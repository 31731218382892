import { Collection, hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { ResponsibilityBeginModel } from '../index'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { InputDate } from 'components/Form/components/InputDate'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { Tooltip } from 'components/Tooltip'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { ConflictError, NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { isStammCompound } from 'helpers/isStamm'
import { isTime } from 'contracts/general/helpers/isTime'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'

interface Props {
  model: Model<ResponsibilityBeginModel>
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  setStep: (step: 'form' | 'creating') => void
}

const responsibilityBeginReasons: InputSelectOption[] = [
  { value: '', label: '' },
  { value: 'Zugang', label: 'Zugang' },
  { value: 'Verlegung', label: 'Verlegung' },
  {
    value: 'Wiederaufnahme nach JVA Aufenthalt',
    label: 'Wiederaufnahme nach JVA Aufenthalt',
  },
  { value: 'Wiederaufnahme nach Krankenhaus', label: 'Wiederaufnahme nach Krankenhaus' },
]

@observer
export class ResponsibilityBeginFormStep1Form extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disabled: boolean
  @observable private patching = false
  @observable private error: string | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    if (
      props.model.values.isNew &&
      context.permissions.resident_setResponsibility === 0
    ) {
      this.error =
        'Sie haben nicht die nötige Berechtigung zur Erstellung einer Zuständigkeit. Bitte wenden Sie sich an einen Administrator.'
    }
    if (
      !props.model.values.isNew &&
      (context.permissions.resident_patchBookings === 0 ||
        (context.permissions.resident_patchBookings === 1 &&
          !isStammCompound(this.props.model.values.compoundId || '')))
    ) {
      this.error = 'Sie können diese Meldung nur ansehen, aber nicht ändern.'
    }
    this.disabled = !!this.error
    makeObservable(this)
  }

  private onSubmit = async () => {
    if (this.patching) {
      return
    }
    if (!this.props.model.isValid()) {
      this.props.model.setFocusToLeftTopmostInvalidField()
      return
    }

    // Create new booking
    if (this.props.model.values.isNew) {
      this.props.setStep('creating')
      return
    }

    // Save existing booking
    runInAction(() => (this.patching = true))
    const begin = dayjs(
      `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
    )
    const data: Partial<IBooking> = {
      compoundId: this.props.model.values.compoundId,
      comment: this.props.model.values.comment,
      beginAt: begin.toISOString(),
      endAt: begin.add(1, 'minute').toISOString(),
      reason: this.props.model.values.reason,
      documentId: this.props.model.values.documentId,
      extra: {
        billing: {
          status: '',
          category: '',
          dateOfNoticeCreation: this.props.model.values.billingDateOfNoticeCreation,
          avDate: null,
        },
      },
    }
    try {
      await hermes.patch(
        `/api/${this.context.instance.id}/bookings/${this.props.model.values.id}`,
        data,
      )
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.patching = false
        if (e.id === ConflictError.id) {
          this.error = e.message
        } else if (e.id === NotAuthorizedError.id) {
          this.error = 'Sie haben nicht die nötige Berechtigung für diesen Vorgang.'
        } else {
          this.error = 'Die Änderungen konnten nicht gespeichert werden.'
        }
      })
    }
  }

  render() {
    const disabled = this.disabled || this.patching
    const model = this.props.model
    const offerFamilyCheckbox =
      model.values.isNew &&
      this.props.members.resources &&
      this.props.members.resources.length > 1
    const beginAt =
      this.props.model.values.beginDate && isTime(this.props.model.values.beginTime)
        ? dayjs(
            `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
          ).toISOString()
        : null

    return (
      <div className='min-h-[242px] flex flex-col'>
        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        <div className='flex-content relative grid grid-cols-4 gap-4 my-4' id={model.id}>
          <InputDate
            disabled={disabled}
            model={model}
            name='beginDate'
            label='Ab Datum'
            placeholder='DD.MM.JJJJ'
          />
          <InputText
            disabled={disabled}
            model={model}
            name='beginTime'
            label='Uhrzeit'
            tooltip='Uhrzeit benötigt'
            placeholder='hh:mm'
          />
          <InputCompound
            className='col-span-2'
            disabled={disabled}
            model={model}
            name='compoundId'
            label='Gelände'
            onlyStamm={
              (this.props.model.values.isNew &&
                this.context.permissions.resident_setResponsibility === 1) ||
              (!this.props.model.values.isNew &&
                this.context.permissions.resident_patchBookings === 1)
            }
            saveResponsibility
            at={beginAt}
          />

          <InputSelect
            disabled={disabled}
            model={model}
            name='reason'
            label='Grund'
            className='col-span-4'
            options={responsibilityBeginReasons}
          />

          <InputTextarea
            disabled={disabled}
            model={model}
            name='comment'
            label='Notiz'
            rows={offerFamilyCheckbox ? 3 : 5}
            className='col-span-4'
          />

          {offerFamilyCheckbox && (
            <div className='col-span-4 flex'>
              <InputCheckbox
                className='flex-content'
                disabled={disabled}
                model={model}
                name='family'
                label='Alle Familienmitglieder'
              />
              <div className='flex-content ml-2 mr-auto text-blue-500 has-tooltip'>
                <i className='fas fa-question-circle' />
                <Tooltip>
                  Zuständigkeit für alle
                  <br />
                  Familienmitglieder festlegen
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        <div className='my-auto flex-content text-right'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            {disabled ? 'Schließen' : 'Abbrechen'}
          </Button>
          {!disabled && (
            <Button color='pink' className='ml-2' onClick={this.onSubmit}>
              {!model.values.isNew ? 'Speichern' : 'Zuständigkeit festlegen'}
            </Button>
          )}
        </div>

        {this.patching && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}
