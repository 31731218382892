/**
 * This is a modified version of https://github.com/yuanqing/autosize-input
 * It is adjusted for measurement only, so that the actual width can be specified
 * during regular react rendering.
 */

const GHOST_ELEMENT_ID = '__autosizeInputTextareaGhost'
export interface IHeightCache {
  className: string
  value: string
  height: number
}

// Create `ghostElement`, with inline styles to hide it and ensure that the text is all
// on a single line.
function createGhostElement(width: number) {
  const ghostElement = document.createElement('div')
  ghostElement.id = GHOST_ELEMENT_ID
  ghostElement.style.cssText = `display:block;width:${width}px;pointer-events:none;opacity:0;position:absolute;
    top:0;left:0;white-space:pre-wrap;overflow-wrap:break-word;`
  document.body.appendChild(ghostElement)
  return ghostElement
}

export function getHeight(
  cache: IHeightCache,
  className: string,
  value: string,
  width: number,
  lineHeight: number,
  minRows?: number,
): number {
  // Shortcut for empty fields
  if (value === '') {
    return minRows ? minRows * lineHeight : lineHeight
  }
  if (value.endsWith('\n')) {
    value = value + ' '
  }
  if (cache.className !== className || cache.value !== value) {
    cache.className = className
    cache.value = value
    cache.height = getGhostHeight(className, width, value)
  }
  return cache.height
}

function getGhostHeight(className: string, width: number, value: string): number {
  // Check if the `ghostElement` exists. If no, create it.
  const ghostElement =
    document.getElementById(GHOST_ELEMENT_ID) || createGhostElement(width)

  // Copy all height-affecting styles to the `ghostElement`.
  ghostElement.innerText = value
  if (ghostElement.className !== className) {
    ghostElement.className = className
  }
  // Copy the height of `ghostElement` to `element`.
  const height: string | null = window.getComputedStyle(ghostElement).height
  // Check if height is a correct number
  if (!height || !height.endsWith('px')) {
    throw new Error(`Got invalid text height: ${height}`)
  }
  let num = parseInt(height.slice(0, -2), 10)
  if (isNaN(num)) {
    throw new Error(`Got invalid text height: ${height}`)
  }
  return Math.ceil(num)
}
