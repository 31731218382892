import { Collection, hermes, Resource } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { ITodoCreate } from 'contracts/todos/interfaces/ITodoCreate'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { z } from 'zod'
import { TicketDialogTicket } from './TicketDialogTicket'
import { LastSavedButton } from 'components/Form/components/LastSavedButton'
import { getSyncState } from 'helpers/getSyncState'
import { TicketDialogAccept } from './TicketDialogAccept'
import { TicketDialogActivities } from './TicketDialogActivities'
import { TicketDialogSummary } from './TicketDialogSummary'
import { TicketDialogReview } from './TicketDialogReview'
import { ITodoMessage } from 'contracts/todos/interfaces/ITodoMessage'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IUser } from 'contracts/users/interfaces/IUser'

interface Props {
  ticket: Resource<ITodoSearchResult>
  onClose: () => void
}

const Validator = z.object({
  label: z.string().min(1).max(255),
  compoundId: z.string().min(1).max(255),
  buildingId: z.string().min(1).max(255),
  notes: z.string().min(1),
  ratingBy: z.string().min(1),
})

const ActionValidator = z.object({
  label: z.string().min(1).max(255),
  compoundId: z.string().min(1).max(255),
  buildingId: z.string().min(1).max(255),
  notes: z.string().min(1),
  ratingBy: z.string().min(1),
  assigneeIds: z.array(z.string()),
})

@observer
export class TicketDialogContent extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly summaries: Collection<ITodoMessage> | null = null
  private readonly reviews: Collection<ITodoMessage> | null = null
  private disposers: Disposer[] = []
  @observable private loading: boolean = false
  @observable private hasUnsavedChanges: boolean = false
  @observable private error: string | null = null
  @observable private roleUsers: IUser[] | null = null
  private readonly model: Model<ITodoSearchResult>
  private readonly mountedAt = new Date()

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    if (props.ticket.id) {
      this.model = new Model(
        props.ticket.data,
        props.ticket.data?.listId === context.defaults.ticketTodoListId
          ? Validator
          : ActionValidator,
      )
      this.summaries = new Collection<ITodoMessage>(
        `/api/${context.instance.id}/todos/${props.ticket.id}/todoMessages`,
        { type: 'summary' },
      )
      this.reviews = new Collection<ITodoMessage>(
        `/api/${context.instance.id}/todos/${props.ticket.id}/todoMessages`,
        { type: 'review' },
      )
    } else {
      this.model = new Model(
        {
          ...props.ticket.data,
        },
        Validator,
      )
    }
  }

  componentDidMount(): void {
    if (this.summaries) {
      this.disposers.push(this.summaries.init({ readOnly: true }))
    }
    if (this.reviews) {
      this.disposers.push(this.reviews.init({ readOnly: true }))
    }
    this.loadRoleUsers()
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadRoleUsers = async () => {
    const roleIds = this.model.values.assignees
      .filter((a) => a.entity === 'role')
      .map((a) => a.id)
    try {
      for (const roleId of roleIds) {
        const roleUsers = await hermes.indexOnceNew<IUser>(
          `/api/${this.context.instance.id}/users/roleUsers?roleId=${roleId}`,
        )
        runInAction(() =>
          this.roleUsers
            ? this.roleUsers.push(...roleUsers)
            : (this.roleUsers = roleUsers),
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  private onSubmit = async () => {
    runInAction(() => (this.error = null))
    if (!this.model.isValid()) {
      if (!this.model.values.ratingBy) {
        runInAction(
          () => (this.error = 'Bitte wählen Sie eine Person für die Bewertung aus.'),
        )
      }
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    const data: ITodoCreate = {
      residentId: this.model.values.residentId,
      compoundId: this.model.values.compoundId,
      buildingId: this.model.values.buildingId,
      priority: this.model.values.priority,
      dueDate: this.model.values.dueDate,
      doneDate: this.model.values.doneDate,
      label: this.model.values.label,
      notes: this.model.values.notes,
      gateMessage: this.model.values.gateMessage,
      gateMessageValidTill: this.model.values.gateMessageValidTill,
      url: this.model.values.url,
      assigneeIds: this.model.values.assigneeIds,
      watcherIds: this.model.values.watcherIds,
      ratingBy: this.model.values.ratingBy,
    }
    runInAction(() => (this.loading = true))
    try {
      await hermes.create(
        `/api/${this.context.instance.id}/todoLists/${this.model.values.listId}/todos`,
        data,
      )
      this.props.onClose()
    } catch (_e) {
      runInAction(() => {
        this.loading = false
        this.error = 'Beim Speichern des Tickets ist ein Fehler aufgetreten.'
      })
    }
  }

  render() {
    const isNew = !this.model.values.id
    const isTicket = this.model.values.listId === this.context.defaults.ticketTodoListId
    const isAssignedToCurrentUser =
      this.model.values.assignees.some((a) => a.id === this.context.user.id) ||
      this.roleUsers?.some((u) => u.id === this.context.user.id)
    const isDone =
      !!this.model.values.doneDate &&
      !!this.model.values.rating &&
      this.context.permissions.dashboard_tickets < 3
    const hasSummaries =
      this.summaries?.resources && this.summaries?.resources?.length > 0
    // there can only be one summary and one review for every ticket
    let summary: Resource<ITodoMessage> | undefined = undefined
    if (this.summaries?.resources?.length) {
      summary = this.summaries?.resources?.[0]
    }
    let review: Resource<ITodoMessage> | undefined = undefined
    if (this.reviews?.resources?.length) {
      review = this.reviews?.resources?.[0]
    }

    if (!this.props.ticket.data) {
      return null
    }
    return (
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {isNew
                  ? isTicket
                    ? 'Neues Ticket'
                    : 'Neue Maßnahme'
                  : isTicket
                  ? `Ticket #${this.model.values.id}`
                  : `Maßnahme #${this.model.values.id}`}
              </Dialog.Title>
              {!isNew && (
                <div className='truncate text-gray-400 text-sm mt-1'>
                  <i className='far fa-user' />
                  {` Erstellt von ${this.model.values.creator?.firstName} ${this.model.values.creator?.lastName}`}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='bg-gray-100 p-6'>
          {this.error && (
            <Message
              color='danger'
              className='mb-6 border border-red-500 sticky top-0 z-10'
            >
              {this.error}
            </Message>
          )}

          {!isNew &&
            !this.model.values.doneDate &&
            this.model.values.assignees.length > 0 &&
            (isAssignedToCurrentUser ||
              this.context.permissions.dashboard_tickets === 2) && (
              <TicketDialogAccept
                model={this.model}
                isAssignedToCurrentUser={!!isAssignedToCurrentUser}
              />
            )}
          {!isNew &&
            this.model.values.doneDate &&
            hasSummaries &&
            this.reviews?.resources && (
              <TicketDialogReview
                review={review}
                ticket={this.props.ticket.data}
                disabled={isDone}
              />
            )}
          {!isNew &&
            this.summaries?.resources &&
            this.model.values.gateMessage === '2-accepted' && (
              <TicketDialogSummary
                summary={summary}
                isAssignedToCurrentUser={!!isAssignedToCurrentUser}
                ticket={this.props.ticket.data}
                disabled={isDone}
              />
            )}
          <TicketDialogTicket summary={summary} model={this.model} />
          {!isNew && this.props.ticket.data && (
            <TicketDialogActivities ticket={this.props.ticket.data} />
          )}
        </div>

        <div
          className='flex gap-2 justify-end py-4 px-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button color='secondary' outline onClick={this.props.onClose}>
            {isNew ? 'Abbrechen' : 'Schließen'}
          </Button>
          {isNew && (
            <Button
              color='primary'
              disabled={this.loading}
              onClick={this.onSubmit}
              loading={this.loading}
            >
              Erstellen
            </Button>
          )}
          {!isNew && this.context.permissions.dashboard_tickets === 2 && (
            <LastSavedButton
              onClose={this.props.onClose}
              syncState={getSyncState(this.mountedAt, this.props.ticket)}
            />
          )}
        </div>
        {this.hasUnsavedChanges && <PreventRouteChange />}
        {this.loading && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}
