import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'

interface Props {
  model: Model<any>
  name: string
  disabled?: boolean
}

const PriorityOptions = {
  '0': { label: 'Niedrige Prio', color: 'bg-gray-500' },
  '1': { label: 'Mittlere Prio', color: 'bg-yellow-500' },
  '2': { label: 'Hohe Prio', color: 'bg-red-500' },
}

@observer
export class TicketPriority extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private onChange = (value: number) =>
    (this.props.model.values[this.props.name] = value)

  render() {
    const prio: number = this.props.model.values[this.props.name]

    if (this.props.disabled) {
      return (
        <span
          className={`rounded-full ml-2 text-xs ${PriorityOptions[prio].color} text-white py-1 px-3`}
        >
          {PriorityOptions[prio].label}
        </span>
      )
    }

    return (
      <Menu as='span' className='relative inline-block text-left'>
        <div>
          <Menu.Button
            className={`rounded-full ml-2 text-xs ${PriorityOptions[prio].color} text-white py-1 px-3 relative -top-[1px]`}
          >
            {PriorityOptions[prio].label}{' '}
            <span>
              <i className='fa fa-caret-down' />
            </span>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='z-10 origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1'>
              <Menu.Item>
                <span
                  className={`cursor-pointer block px-4 py-2 text-sm ${
                    prio === 2 ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                  }`}
                  onClick={() => this.onChange(2)}
                >
                  {PriorityOptions[2].label /* High */}
                </span>
              </Menu.Item>

              <Menu.Item>
                <span
                  className={`cursor-pointer block px-4 py-2 text-sm ${
                    prio === 1 ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                  }`}
                  onClick={() => this.onChange(1)}
                >
                  {PriorityOptions[1].label /* Medium */}
                </span>
              </Menu.Item>

              <Menu.Item>
                <span
                  className={`cursor-pointer block px-4 py-2 text-sm ${
                    prio === 0 ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                  }`}
                  onClick={() => this.onChange(0)}
                >
                  {PriorityOptions[0].label /* Low */}
                </span>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
