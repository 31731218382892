import { Decimal } from 'decimal.js-light'

export function isDecimalString(
  value: string,
  precision?: number,
  scale?: number,
  min?: string,
  max?: string,
) {
  if (typeof value !== 'string') {
    return false
  }

  let decimal
  try {
    decimal = new Decimal(value)
  } catch (e) {
    return false
  }

  if (precision !== undefined && scale !== undefined && !decimal.eq(0)) {
    // Do not allow because that's not supported by postgres
    if (scale > precision) {
      throw new Error('Precision must be >= scale')
    }
    if (decimal.decimalPlaces() > scale) {
      return false
    }
    const integerPlaces =
      decimal.precision(false) - // does not include trailing zeros in the integer part
      decimal.decimalPlaces()
    const maxIntegerPlaces = precision - scale

    if (integerPlaces > maxIntegerPlaces) {
      return false
    }
  }

  if (min && decimal.lessThan(min)) {
    return false
  }
  if (max && decimal.greaterThan(max)) {
    return false
  }

  return true
}
