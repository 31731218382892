import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import * as React from 'react'

interface Props {
  onClose: () => void
}

export const FindRecordHelpDialog: React.FC<Props> = (props) => (
  <>
    <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
      <button
        type='button'
        className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        onClick={props.onClose}
      >
        <span className='sr-only'>Close</span>
        <XIcon className='h-6 w-6' aria-hidden='true' />
      </button>
    </div>

    <div className='flex items-start'>
      <div className='-mt-2 text-left'>
        <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
          Hilfe zur Bewohnersuche
        </Dialog.Title>
      </div>
    </div>

    <div className='my-4'>
      <span className='text-indigo-500'>
        Sie können Folgendes in die Suche eingeben.
        <br />
        Die Eingaben können kombiniert werden:
      </span>
      <ul className='list-disc ml-6 mt-3'>
        <li>Name oder einen Teil des Namens</li>
        <li>Bewohner-IDs</li>
        <li>Geburtsdatum</li>
        <li>KÜ Aktenzeichen</li>
        <li>Raumnummer (@ voranstellen, z. B. @1.003)</li>
      </ul>
    </div>

    <div className='text-right'>
      <Button color='secondary' onClick={props.onClose}>
        Schließen
      </Button>
    </div>
  </>
)
