import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { Dialog as InuvDialog } from 'components/Dialog'
import { observer } from 'mobx-react'
import { Button } from 'components/Form/components/Button'
import { DialogOverlayArea } from 'components/Dialog/components/DialogOverlaySpinner/components/DialogOverlayArea'
import { Spinner } from 'components/Spinner'
import { IInventoryDelivery } from 'contracts/inventory/interfaces/IInventoryDelivery'
import { Collection, hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { IFullOrder } from 'contracts/inventory/interfaces/IFullOrder'
import { getDeliveredCount } from '../helpers/getDeliveredCount'
import { InventoryDeliveryItem } from './InventoryDeliveryItem'
import { Message } from 'components/Message'
import { useParams } from 'react-router-dom'
import { observable, toJS } from 'mobx'
import { dayjs } from 'helpers/dayjs'

interface Props {
  onClose: () => void
  order: IFullOrder
  deliveries: Collection<IInventoryDelivery>
}

export const InventoryDeliveryDialog: React.FC<Props> = observer((props) => {
  const params = useParams<{ id: string }>()
  const context = React.useContext(AppContext)
  const [error, setError] = React.useState<string | null>(null)
  const [saving, setSaving] = React.useState<boolean>(false)
  const [delivery] = React.useState<IInventoryDelivery | null>(() => {
    if (params.id !== 'new') {
      for (const d of props.deliveries.resources || []) {
        if (d.data && d.id === params.id) {
          return observable(toJS(d.data))
        }
      }
      setError('Eine Lieferung mit dieser ID ist nicht im System gespeichert.')
      return null
    }
    return observable({
      id: '',
      orderId: props.order.id,
      deliveredAt: new Date().toISOString(),
      receivedBy: context.user.id,
      receiver: {
        id: context.user.id,
        firstName: context.user.firstName,
        lastName: context.user.lastName,
      },
      items: props.order.items.map((i) => {
        const count = +i.count - getDeliveredCount(props.deliveries, i.inventoryItemId)
        return {
          deliveryId: '',
          inventoryItemId: i.inventoryItemId,
          notes: i.notes,
          count: count > 0 ? String(count) : (null as any),
          inventoryItem: {
            id: i.inventoryItemId,
            label: i.label,
            imageId: i.imageId,
            unit: i.unit,
          },
        }
      }),
    })
  })

  async function onSubmit() {
    if (!delivery) {
      return
    }
    const data = {
      orderId: delivery.orderId,
      items: delivery.items
        .map((i) => ({
          deliveryId: '0',
          inventoryItemId: i.inventoryItemId,
          notes: i.notes,
          count: i.count,
        }))
        .filter((i) => i.count !== null && +i.count > 0),
    }
    if (data.items.length === 0) {
      setError('Bitte geben Sie für mindestens einen Gegenstand eine Liefermenge an.')
      return
    }
    try {
      setSaving(true)
      setError(null)
      if (delivery.id === '') {
        await hermes.create(`/api/${context.instance.id}/inventory/deliveries`, data)
      } else {
        await hermes.update(
          `/api/${context.instance.id}/inventory/deliveries/${delivery.id}`,
          data,
        )
      }
      props.onClose()
    } catch (_e) {
      setSaving(false)
      setError('Die Lieferung konnte nicht gespeichert werden.')
    }
  }

  async function onDelete() {
    if (
      !delivery?.id ||
      !window.confirm('Möchten Sie diese Lieferung wirklich unwiderruflich löschen?')
    ) {
      return
    }
    try {
      setSaving(true)
      setError(null)
      await hermes.delete(
        `/api/${context.instance.id}/inventory/deliveries/${delivery.id}`,
      )
      props.onClose()
    } catch (_e) {
      setSaving(false)
      setError('Die Lieferung konnte nicht gelöscht werden.')
    }
  }

  const isNew = delivery?.id === ''
  return (
    <InuvDialog size='lg' open={true} setOpen={props.onClose}>
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='pt-6'>
          <div className='px-6 flex items-start'>
            <div className='-mt-2 mb-4 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {isNew
                  ? 'Neue Lieferung'
                  : `Lieferung vom ${
                      delivery?.deliveredAt
                        ? dayjs(delivery.deliveredAt).format('DD.MM.YYYY')
                        : '-'
                    }`}
                {delivery && (
                  <div className='truncate text-gray-400 text-sm mt-1 max-w-[840px]'>
                    <i className='far fa-user' />
                    <span className='ml-1'>{`Eingetragen von ${delivery.receiver.firstName} ${delivery.receiver.lastName}`}</span>
                  </div>
                )}
              </Dialog.Title>
            </div>
          </div>

          {error && (
            <div className='px-6 pb-4'>
              <Message color='danger'>{error}</Message>
            </div>
          )}

          {delivery && (
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50 border-t border-gray-200'>
                <tr>
                  <th
                    scope='col'
                    className='pr-3 pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Gegenstand
                  </th>
                  <th
                    scope='col'
                    className='max-w-20 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                  >
                    Bemerkung
                  </th>
                  <th
                    scope='col'
                    className='max-w-20 pl-3 pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                  >
                    Geliefert
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {delivery.items.map((i) => (
                  <InventoryDeliveryItem
                    key={i.inventoryItemId}
                    item={i}
                    disabled={!isNew && context.permissions.inventory_deliveries < 2}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div
          className='flex py-4 px-6 sticky bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <div className='flex-auto'>
            {!isNew && context.permissions.inventory_deliveries >= 2 && (
              <Button disabled={!!saving} color='danger' onClick={onDelete}>
                <i className='fas fa-trash' />
              </Button>
            )}
          </div>
          <div className='flex-content'>
            <Button
              disabled={!!saving}
              color='secondary'
              outline={isNew}
              onClick={props.onClose}
            >
              {isNew ? 'Abbrechen' : 'Schließen'}
            </Button>
            {delivery &&
              ((isNew && context.permissions.inventory_deliveries >= 1) ||
                (!isNew && context.permissions.inventory_deliveries >= 2)) && (
                <Button
                  disabled={!!saving}
                  color='primary'
                  className='ml-2'
                  onClick={onSubmit}
                >
                  Speichern
                </Button>
              )}
          </div>
        </div>

        {saving && !error && (
          <DialogOverlayArea opaque>
            <Spinner />
          </DialogOverlayArea>
        )}
      </div>
    </InuvDialog>
  )
})
