import { ITransferPlanningSearchResult } from 'contracts/transfer/interfaces/ITransferPlanningSearchResult'
import { dayjs } from 'helpers/dayjs'
import * as React from 'react'

interface Props {
  resident: ITransferPlanningSearchResult['resident']
}

export const ResidentInfoDialog: React.FC<Props> = (props) => (
  <div>
    {props.resident.firstName} {props.resident.lastName}
    <br />
    Geburtsdatum:{' '}
    {props.resident.dateOfBirth
      ? dayjs(props.resident.dateOfBirth).format('DD.MM.YYYY')
      : '-'}
    <br />
    {props.resident.accommodation.label}
    <br />
  </div>
)
