import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { IInventoryLogMetadata } from 'contracts/inventory/interfaces/IInventoryLogMetadata'
import { IInstance } from 'contracts/users/interfaces/IInstance'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'
import * as React from 'react'

interface Props {
  caption: string
  instance: IInstance
  items: IInventoryItem[]
  onSelect: (item: IInventoryItem) => void
  meta?: IInventoryLogMetadata | null
}

export const InventoryScanItemGrid: React.FC<Props> = observer((props) => (
  <>
    <div className='mb-2 text-lg'>{props.caption}</div>
    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xxl:grid-cols-6 gap-3 sm:gap-6 mb-10 border-b border-gray-300 pb-6'>
      {props.items.length === 0 && (
        <div className='relative pt-[100%]'>
          <div className='rounded-md shadow-md border-2 border-gray-300 border-dashed text-center absolute top-0 right-0 left-0 bottom-0 flex flex-col'>
            <div className='my-auto truncate'>
              Derzeit keine
              <br />
              Gegenstände
              <br />
              vorhanden.
            </div>
          </div>
        </div>
      )}
      {props.items.map((item) => {
        let criticalStock: 'min' | 'zero' | null = null
        if (!!item.minStock && +item.count <= +item.minStock) {
          criticalStock = 'min'
        }
        if (+item.count === 0 || (item.issueCount && +item.issueCount > +item.count)) {
          criticalStock = 'zero'
        }
        if (!item.isDispensable) {
          return null
        }
        return (
          <div
            key={item.id}
            className='cursor-pointer truncate relative hover:bg-slate-100 rounded-md shadow-md hover:opacity-80'
            onClick={() => props.onSelect(item)}
          >
            <img
              src={getInventoryImageSrc(item.imageId, props.instance.id)}
              alt=''
              width='200'
              height='200'
            />
            {props.meta?.durationOfUseIsOverOnDate?.[item.id] && (
              <>
                <div className='absolute bottom-10 left-0 right-0 top-0 bg-gray-900 opacity-70' />
                <span className='overflow-hidden absolute bottom-14 right-3 left-3 rounded-full bg-green-500 text-white text-sm px-3 py-1'>
                  <i className='fas fa-clock' />{' '}
                  {`ab ${dayjs(props.meta.durationOfUseIsOverOnDate[item.id]).format(
                    'DD.MM.YYYY',
                  )}`}
                </span>
              </>
            )}
            {!!item.issueCount && +item.issueCount > 1 && (
              <span className='absolute top-3 left-3 rounded-full bg-indigo-500 text-white text-sm px-3 py-1'>
                {item.issueCount}x
              </span>
            )}
            {criticalStock === 'min' && (
              <span className='absolute top-3 right-3 rounded-full bg-yellow-500 text-white text-sm px-3 py-1'>
                <i className='fas fa-battery-quarter' />
              </span>
            )}
            {criticalStock === 'zero' && (
              <span className='absolute top-3 right-3 rounded-full bg-red-500 text-white text-sm px-3 py-1'>
                <i className='fas fa-battery-empty' />
              </span>
            )}
            <div className='absolute bottom-0 left-0 right-0 text-center bg-gray-900 opacity-50 text-white leading-10'>
              {item.label}
            </div>
          </div>
        )
      })}
    </div>
  </>
))
