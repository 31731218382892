import * as React from 'react'
import { hermes } from '@byll/hermes'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ICardTemplate } from 'contracts/general/interfaces/ICardTemplate'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { InputCompound } from 'components/Form/components/InputCompound'
import { z } from 'zod'
import { Message } from 'components/Message'
import { isStammCompound } from 'helpers/isStamm'
import { box } from 'services/box'

const options: InputSelectOption[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
]

interface Props {
  selected: Map<string, IResidentSearchResult>
}

@observer
export class SelectedResidents extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private cardTemplateSelectOptions: InputSelectOption[] = []
  private readonly model: Model<{
    quantity: string
    makeOldCardsInvalid: boolean
    cardTemplate: string
    compoundId: string
  }>
  @observable private error: string | null = null
  @observable private loading: boolean = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    let compoundId = context.defaults.responsibilityCompoundId
    if (
      context.permissions.resident_id_cards <= 2 &&
      !isStammCompound(compoundId || '')
    ) {
      compoundId = null
    }
    this.model = new Model(
      { quantity: '1', makeOldCardsInvalid: false, cardTemplate: null, compoundId },
      z.object({ cardTemplate: z.string(), compoundId: z.string() }),
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.loadCardTemplates()
  }

  private loadCardTemplates = async () => {
    const cardTemplates: ICardTemplate[] = await hermes.indexOnceNew(
      `/api/${this.context.instance.id}/cardTemplates?scope=resident`,
    )
    runInAction(() => {
      this.cardTemplateSelectOptions = cardTemplates.map((c) => ({
        value: c.id,
        label: c.label,
      }))
      this.cardTemplateSelectOptions.unshift({ value: null, label: 'Bitte wählen...' })
      const stored = localStorage.getItem(`${this.context.user.id}.card-template`)
      if (stored && cardTemplates.find((c) => c.id === stored)) {
        this.model.values.cardTemplate = stored
      }
    })
  }

  private removeResident = (residentId: string) => {
    this.props.selected.delete(residentId)
  }

  private downloadCards = async () => {
    if (this.loading) {
      return
    }
    if (this.context.permissions.resident_id_cards < 2) {
      void box.alert(
        'Berechtigung fehlt',
        'Sie haben nicht die nötige Berechtigung, um Ausweise zu erstellen.',
      )
      return
    }
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (
      this.context.permissions.resident_id_cards === 2 &&
      !isStammCompound(this.model.values.compoundId)
    ) {
      void box.alert(
        'Nur Stammgelände',
        'Sie haben nicht die nötige Berechtigung, um Ausweise für das gewählte Gelände zu erstellen. Bitte wählen Sie ein Stammgelände aus.',
      )
      return
    }
    if (this.props.selected.size === 0) {
      runInAction(() => (this.error = 'Es wurden keine Bewohner ausgewählt.'))
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'resident-cards',
      this.context.instance.id,
      {
        templateId: this.model.values.cardTemplate,
        residentIds: Array.from(this.props.selected.keys()),
        makeOldCardsInvalid: this.model.values.makeOldCardsInvalid,
        compoundId: this.model.values.compoundId,
        quantity: this.model.values.quantity,
      },
      'Ausweise.pdf',
    )
    runInAction(() => (this.loading = false))
  }

  private saveCardTemplateSelection = () => {
    localStorage.setItem(
      `${this.context.user.id}.card-template`,
      this.model.values.cardTemplate,
    )
  }

  render() {
    const selectedResidents = Array.from(this.props.selected.values())
    return (
      <div>
        <div className='md:sticky top-0 bg-white z-10 pb-4 -mx-4 px-4'>
          <div className='mb-4 pt-6'>
            <h1 className='text-2xl font-semibold text-gray-500'>Vorgemerkte Bewohner</h1>
            <p className='mt-2 text-md text-gray-500'>Sammelausweis</p>
          </div>
          <div className='grid grid-cols-2 gap-x-4 gap-y-4'>
            <InputSelect
              label='Ausweistyp'
              model={this.model}
              name='cardTemplate'
              options={this.cardTemplateSelectOptions}
              placeholder='Kartenvorlage'
              onChange={this.saveCardTemplateSelection}
            />
            <InputCompound
              label='Gültig für'
              model={this.model}
              name='compoundId'
              saveResponsibility
              onlyStamm={this.context.permissions.resident_id_cards < 3}
            />
          </div>
        </div>

        <div>
          {this.props.selected.size < 1 && (
            <div className='border-2 bg-gray-100 border-indigo-500 py-4 text-center rounded-md'>
              Es wurden noch keine Bewohner vorgemerkt.
            </div>
          )}
          {selectedResidents.map((r) => (
            <div
              className='flex items-center gap-4 bg-gray-100 border-gray-300 border-2 rounded-md px-3 py-2 mb-3'
              key={r.id}
            >
              <span className='cursor-pointer' onClick={() => this.removeResident(r.id)}>
                <i className='fas fa-times' />
              </span>
              {r.lastName + ', ' + r.firstName}
            </div>
          ))}
        </div>

        <div id={this.model.id} className='mt-4'>
          {this.error && (
            <Message className='mt-2 mb-4' color='danger'>
              {this.error}
            </Message>
          )}
          <div className='flex gap-4 mb-3'>
            <InputSelect
              className='flex-[0_0_168px]'
              model={this.model}
              name='quantity'
              options={options}
            />
            <Button className='flex-auto' onClick={this.downloadCards}>
              {this.loading ? (
                <span>
                  <i className='fa fa-spin fa-sync fa-sm' />
                </span>
              ) : (
                'Ausweise erstellen'
              )}
            </Button>
          </div>
          <InputCheckbox
            model={this.model}
            name='makeOldCardsInvalid'
            label='Alte Ausweise ungültig machen'
          />
        </div>
      </div>
    )
  }
}
