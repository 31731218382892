import { isYmdDate } from '../../general/helpers/isYmdDate'
import { IGroupField } from '../interfaces/IGroupField'
import { IGroupParticipantEntry } from '../interfaces/IGroupParticipantEntry'

export function getGroupParticipantFieldValues(
  entry: IGroupParticipantEntry,
  fields: IGroupField[],
): { [key: string]: string | boolean | null } {
  const values: { [key: string]: string | boolean | null } = {}

  for (const field of fields) {
    let value = entry.fields[field.id]
    switch (field.type) {
      case 'text':
        values[field.id] = String(value || '')
        break
      case 'date':
        values[field.id] = isYmdDate(value as any) ? value! : null
        break
      case 'select':
        values[field.id] = field.options.includes(String(value || '')) ? value! : ''
        break
      case 'checkbox':
        values[field.id] = !!value
        break
    }
  }
  return values
}
