import { ButtonDropdown } from 'components/ButtonDropdown'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import * as React from 'react'

interface Props {
  onExport: (type: 'resident' | 'family') => void
  loading?: boolean
}

const options: InputSelectOption[] = [
  { label: 'Für diesen Bewohner', value: 'resident' },
  { label: 'Für die gesamte Familie', value: 'family' },
]

export class ResidentProfileExportDropdown extends React.Component<Props, {}> {
  private onSelect = (option: InputSelectOption) => {
    this.props.onExport(option.value)
  }

  render() {
    return (
      <ButtonDropdown
        options={options}
        onSelect={this.onSelect}
        color='secondary'
        align='left'
        disabled={this.props.loading}
      >
        {this.props.loading && (
          <span className='mr-2'>
            <i className='fas fa-spinner fa-spin' />
          </span>
        )}
        Stammdaten exportieren
        <span className='ml-1'>
          <i className='fas fa-caret-down' />
        </span>
      </ButtonDropdown>
    )
  }
}
