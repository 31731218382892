import { dayjs } from 'helpers/dayjs'

export function getDefaultMealType(): 'breakfast' | 'lunch' | 'dinner' {
  const now = dayjs().format('HHmm')
  if (now >= '0600' && now < '1130') {
    return 'breakfast'
  }
  if (now >= '1130' && now < '1700') {
    return 'lunch'
  }
  return 'dinner'
}
