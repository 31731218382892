import { Spinner } from 'components/Spinner'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  building: IBuilding
  month: string
  selectedShifts: Map<string, { id: string; checked: boolean }[]> // buildingId -> { shiftId, checked: boolean }[]
  shifts: IShift[]
}

@observer
export class BuildingShifts extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable loading: boolean = false
  @observable showShifts: boolean = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private toggleShift = (shiftId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    const checkedArray = this.props.selectedShifts.get(this.props.building.id)
    if (checkedArray) {
      const index = checkedArray.findIndex((s) => s.id === shiftId)
      if (index !== -1) {
        runInAction(() => (checkedArray[index].checked = checked))
      }
      runInAction(() =>
        this.props.selectedShifts.set(this.props.building.id, checkedArray),
      )
    }
  }

  private toggleAll = () => {
    const checkedArray = this.props.selectedShifts.get(this.props.building.id)
    if (checkedArray) {
      const allChecked = checkedArray.every((s) => s.checked)
      runInAction(() => checkedArray.forEach((s) => (s.checked = !allChecked)))
      runInAction(() =>
        this.props.selectedShifts.set(this.props.building.id, checkedArray),
      )
    }
  }

  @action
  private toggleShowShifts = () => {
    this.showShifts = !this.showShifts
  }

  render() {
    const selectedShifts = this.props.selectedShifts.get(this.props.building.id)
    const buildingShifts = this.props.shifts.filter(
      (s) => s.buildingId === this.props.building.id,
    )
    if (!buildingShifts || buildingShifts.length === 0) {
      return null
    }
    return (
      <div className='shadow-md rounded-md py-4 bg-gray-100 max-h-[250px] overflow-y-auto'>
        <div className='font-bold mx-4'>
          <span
            key={`${this.showShifts}`}
            className='cursor-pointer text-gray-400 mr-2'
            onClick={this.toggleShowShifts}
          >
            {this.showShifts ? (
              <span>
                <i className='fa fa-caret-down' />
              </span>
            ) : (
              <span>
                <i className='fa fa-caret-right' />
              </span>
            )}
          </span>
          {this.props.building.label}
          {selectedShifts && selectedShifts.length > 0 && (
            <span className='bg-indigo-500 text-xs text-white ml-2 my-auto p-1 rounded'>
              {
                selectedShifts.filter(
                  (s) => buildingShifts.find((s2) => s2.id === s.id) && s.checked,
                ).length
              }
              /{buildingShifts.length}
            </span>
          )}
          {buildingShifts && buildingShifts.length > 0 && (
            <span
              className='px-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100 rounded-md ml-2'
              onClick={this.toggleAll}
            >
              Alle
            </span>
          )}
        </div>
        <div className='relative'>
          {this.loading && <Spinner />}
          {!this.loading && this.showShifts && buildingShifts.length > 0 && (
            <div>
              {buildingShifts.map((s, i) => (
                <div
                  key={i}
                  className='flex items-center px-4 py-2 border-b border-gray-200'
                >
                  <input
                    type='checkbox'
                    className='mr-2 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                    checked={
                      this.props.selectedShifts
                        .get(this.props.building.id)
                        ?.find((s2) => s2.id === s.id)?.checked ?? false
                    }
                    onChange={this.toggleShift(s.id)}
                  />
                  <div className='flex-1'>{s.label}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}
