import * as React from 'react'
import { Model } from 'components/Form/Model'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { AppContext } from 'services/connection/models/AppContext'
import { Menu, Transition } from '@headlessui/react'
import { Button } from 'components/Form/components/Button'
import { hermes } from '@byll/hermes'
import { IUser } from 'contracts/users/interfaces/IUser'
import { InputUser } from 'components/Form/components/InputUser'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'

interface Props {
  model: Model<ITodoSearchResultFilter>
}

export class TicketUserFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private user: IUser | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    reaction(
      () => this.props.model.values.userId,
      (userId) => this.loadUser(userId),
    )
  }

  private loadUser = async (userId: string | null | undefined) => {
    if (!userId) {
      return
    }
    const user = await hermes.getOnceNew<IUser>(
      `/api/${this.context.instance.id}/users/${userId}`,
    )
    runInAction(() => (this.user = user))
  }

  @action private reset = () => {
    this.props.model.values.userId = null
  }

  render() {
    let label = 'Mitarbeiter'
    let color: string = 'text-gray-500'
    if (this.props.model.values.userId) {
      label = this.user
        ? `${this.user.firstName} ${this.user.lastName}` || 'Mitarbeiter'
        : '...'
      color = 'text-blue-500 max-w-[200px] truncate'
    }
    return (
      <Menu as='div' className='flex-content mr-4 cursor-pointer'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-4 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputUser model={this.props.model} name='userId' label='Mitarbeiter' />
              <Menu.Item>
                <div className='flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
