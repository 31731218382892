import * as React from 'react'
import { action, makeObservable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from '../../Model'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Collection, Resource } from '@byll/hermes'
import { IDropdownEntry } from 'contracts/general/interfaces/IDropdownEntry'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { z } from 'zod'
import { box } from 'services/box'
import { NewDropdownEntryDialog } from './components/NewDropdownEntryDialog'

export type InputSelectOption = { value: any; label: string }

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any> // value: null | '' => 'Bitte wählen'
  type: string // table dropdown_entries.type
  className?: string
  children?: Element
  allowCreate?: boolean // Adds an option "Neuer Eintrag..." to the end of the list
}

@observer
export class InputDropdownEntry extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  private readonly dropdownEntries: Collection<IDropdownEntry>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.id = props.id || uniqueId('input-')
    this.dropdownEntries = new Collection<IDropdownEntry>(
      `/api/${context.instance.id}/dropdownEntries`,
      { type: props.type },
    )
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(this.dropdownEntries.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action
  private onChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (!(event.target instanceof HTMLSelectElement)) {
      return
    }
    const target: HTMLSelectElement = event.target
    if (target.value === 'new') {
      this.onCreate()
      return
    }
    this.props.model.values[this.props.name] = target.value
    this.props.onChange?.(event)
  }

  private mapEntries = (entry: Resource<IDropdownEntry>) => {
    if (!entry.data) {
      return null
    }
    return (
      <option key={entry.id} value={entry.id}>
        {entry.data.label}
      </option>
    )
  }

  private onCreate = async () => {
    const model = new Model<{ label: string }>(
      { label: '' },
      z.object({ label: z.string().min(1) }),
    )
    const promise = box.custom(
      <NewDropdownEntryDialog
        model={model}
        onClose={(id) => promise.close(id)}
        type={this.props.type}
      />,
      { context: this.context, closable: true },
    )
    const id = await promise
    if (id) {
      runInAction(() => {
        this.props.model.values[this.props.name] = id
      })
    }
  }

  render() {
    const { name, model, label, className, children, type, ...attributes } = this.props
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'

    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <select
          {...attributes}
          className={innerClassName}
          name={name}
          onChange={this.onChange}
          value={model.values[name] ? model.values[name] : ''}
          id={this.id}
        >
          {!this.dropdownEntries.resources && (
            <option key='loading' value=''>
              Lade...
            </option>
          )}
          {this.dropdownEntries.resources && (
            <>
              <option key='-' value=''>
                Bitte wählen...
              </option>
              {this.dropdownEntries.resources.map(this.mapEntries)}
              {this.props.allowCreate && (
                <option key='new' value='new'>
                  Neuer Eintrag...
                </option>
              )}
            </>
          )}
        </select>
        {children}
      </div>
    )
  }
}
