import { z } from 'zod'

export const InactiveListFilterValidator = z.object({
  compoundId: z.string().nullable(),
  residentCheckedIn: z.string(),
  residentCheckedOut: z.string(),
  residentInactive: z.string(),
  residentWasScanned: z.string(),
  residentWasNotScanned: z.string(),
  interval: z.enum(['24h', '48h', '72h', '6d']),
  sort: z.enum([
    'residents."lastName" ASC',
    'residents."lastName" DESC',
    '"lastScannedAt" ASC NULLS FIRST',
    '"lastScannedAt" DESC NULLS LAST',
  ]),
})
