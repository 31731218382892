import { InputCompound } from 'components/Form/components/InputCompound'
import * as React from 'react'
import { Model } from '../../../components/Form/Model'
import { InputDate } from 'components/Form/components/InputDate'
import { InputText } from 'components/Form/components/InputText'
import { observer } from 'mobx-react'
import { dayjs } from 'helpers/dayjs'
import { AppContext } from 'services/connection/models/AppContext'
import { Button } from 'components/Form/components/Button'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { isTime } from 'contracts/general/helpers/isTime'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { box } from 'services/box'
import { Tooltip } from 'components/Tooltip'
import { IOccupancyTimeModel } from '../interfaces/IOccupancyTimeModel'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'

export interface IResidentsOccupancyFilter {
  compoundId: string | null
  buildingId: string | null
  date: string | null
  time: string
  view: 'tile' | 'tile+transfer' | 'plan' // Kachelansicht, Kachelansicht+Geplante check-ins/check-outs, Grundriss
  locked: 'yes' | 'no' | null
  occupied: 'full' | 'not full' | 'empty' | 'overcrowded' | null
  capacity: string | null
  floorIds: string
  equipment: string
  typeOfUse: string
}

interface Props {
  time: Model<IOccupancyTimeModel>
  filter: Model<IOccupancyFilter>
}

@observer
export class ResidentsOccupancyFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly today = dayjs().format('YYYY-MM-DD')
  @observable private downloading = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private downloadExcel = async () => {
    if (!this.props.filter.values.compoundId) {
      void box.alert(
        'Gelände wählen',
        'Bitte wählen Sie ein Gelände aus, für das Sie die Belegung herunterladen möchten.',
      )
      return
    }
    if (!this.props.filter.values.queryAt) {
      void box.alert(
        'Zeitpunkt wählen',
        'Bitte wählen Sie einen Zeitpunkt aus, für den Sie die Belegung herunterladen möchten.',
      )
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('accommodation-bookings', this.context.instance.id, {
      compoundId: this.props.filter.values.buildingId
        ? null
        : this.props.filter.values.compoundId,
      buildingIds: this.props.filter.values.buildingId || '',
      timestamp:
        this.props.filter.values.queryAt === 'now'
          ? new Date().toISOString()
          : this.props.filter.values.queryAt,
    })
    runInAction(() => (this.downloading = false))
  }

  @action private setViewTile = () => (this.props.filter.values.view = 'tile')
  @action private toggleViewTileTransfer = () => {
    if (this.props.filter.values.view === 'tile+transfer') {
      this.props.filter.values.view = 'tile'
    } else {
      this.props.filter.values.view = 'tile+transfer'
      this.props.filter.values.queryAt = 'now'
      this.props.time.values.date = dayjs().format('YYYY-MM-DD')
      this.props.time.values.time = ''
    }
  }
  @action private setViewPlan = () => {
    this.props.filter.values.view = 'plan'
    // Only one id!
    this.props.filter.values.floorIds =
      (this.props.filter.values.floorIds || '')
        .split(',')
        .filter((id) => id !== 'null')[0] || ''

    this.props.filter.values.locked = null
    this.props.filter.values.occupied = null
    this.props.filter.values.capacity = null
    this.props.filter.values.equipment = ''
    this.props.filter.values.typeOfUse = ''
  }

  @action private onBlurTime = () => {
    if (!isTime(this.props.time.values.time)) {
      this.props.time.values.time = ''
    }
  }

  render() {
    const view = this.props.filter.values.view
    const hasToggle =
      this.context.instance.id === '0' ||
      this.context.instance.id === '7' ||
      this.context.instance.id === '8'
    return (
      <div
        className='sticky flex-content grid grid-cols-1 md:grid-cols-[max-content_200px_100px_100px_1fr_auto] gap-4 p-6 bg-white shadow border-b border-gray-300'
        style={{ top: 56, zIndex: 1 }}
      >
        <div className='col-span-1 text-lg'>
          <div
            className='bg-indigo-500 text-white rounded-md'
            style={{ padding: '5px 10px' }}
          >
            Zimmerbelegung
          </div>
        </div>
        <InputCompound
          model={this.props.filter}
          name='compoundId'
          label='Gelände'
          onlyStamm={this.context.permissions.resident_accommodationBookings === 1}
          saveResponsibility
          at={
            this.props.filter.values.queryAt && this.props.filter.values.queryAt !== 'now'
              ? this.props.filter.values.queryAt
              : undefined
          }
        />
        {/* Do not show date/time for transfer planning. This always needs to be now */}
        {this.props.filter.values.view !== 'tile+transfer' && (
          <>
            <InputDate model={this.props.time} name='date' label='Datum' />
            <InputText
              model={this.props.time}
              onBlur={this.onBlurTime}
              name='time'
              label='Uhrzeit'
              placeholder={
                this.props.time.values.date === null
                  ? ''
                  : this.props.time.values.at === 'now'
                  ? 'Jetzt'
                  : '23:59'
              }
            />
          </>
        )}
        {this.props.filter.values.view === 'tile+transfer' && (
          <>
            <div />
            <div />
          </>
        )}
        <div className='hidden md:block' />
        <div className='flex whitespace-nowrap'>
          {hasToggle && (
            <Button
              onClick={this.setViewTile}
              color={view.startsWith('tile') ? 'primary' : 'secondary'}
              outline={!view.startsWith('tile')}
              style={{ borderRadius: '6px 0 0 6px' }}
            >
              Kacheln
            </Button>
          )}
          {hasToggle && (
            <Button
              onClick={this.setViewPlan}
              color={view === 'plan' ? 'primary' : 'secondary'}
              outline={view !== 'plan'}
              style={{ borderRadius: '0 6px 6px 0' }}
            >
              Grundriss
            </Button>
          )}

          {this.context.permissions.resident_accommodation_plannedTransfers && (
            <Button
              onClick={this.toggleViewTileTransfer}
              color={view === 'tile+transfer' ? 'primary' : 'secondary'}
              outline={view !== 'tile+transfer'}
              style={{ width: 38, height: 38, padding: 0 }}
              className='ml-4 has-tooltip'
            >
              <Tooltip position='left'>Geplante Transfers</Tooltip>
              <i className='fas fa-random' />
            </Button>
          )}

          {!this.downloading && (
            <Button
              onClick={this.downloadExcel}
              color='primary'
              style={{ width: 38, height: 38, padding: 0 }}
              className='ml-4'
            >
              <i className='fas fa-download' />
            </Button>
          )}
          {this.downloading && (
            <Button
              color='primary'
              style={{ width: 38, height: 38, padding: 0 }}
              className='ml-4'
            >
              <i className='fas fa-sync fa-spin' />
            </Button>
          )}
        </div>
      </div>
    )
  }
}
