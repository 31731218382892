import { MONTHS } from 'contracts/general/helpers/months'
import { ICreateTimeSheetDocumentFromPages } from 'contracts/workplan/interfaces/ICreateTimeSheetDocumentFromPages'
import {
  ITimeSheetPage,
  ITimeSheetPageDone,
} from 'contracts/workplan/interfaces/ITimeSheetPage'
import { dayjs } from 'helpers/dayjs'

export function getTimeSheetDocumentCreatables(
  pages: ITimeSheetPage[],
): (ICreateTimeSheetDocumentFromPages & { error?: string; meta?: string })[] {
  const results: (ICreateTimeSheetDocumentFromPages & {
    error?: string
    meta?: string
  })[] = []
  const documents = new Map<
    string,
    { numberOfPages: number; pages: Map<number, ITimeSheetPageDone> }
  >() // Map<`${month.employeeId}`, { complete, numberOfPages, pages }>, complete: true if all pages are done and available
  for (const page of pages) {
    if (page.status !== 'done') {
      continue
    }
    const key = `${page.month}.${page.employeeId}`
    if (!documents.has(key)) {
      documents.set(key, {
        numberOfPages: page.numberOfPages,
        pages: new Map<number, ITimeSheetPageDone>(),
      })
    }
    const doc = documents.get(key)!
    doc.pages.set(page.pageNumber, page)
  }

  for (const [, document] of documents) {
    const error = getError(document)
    const page = Array.from(document.pages.values())[0]!
    results.push({
      month: page.month,
      employeeId: page.employeeId,
      ...(error as any),
    })
  }

  return results
}

function getError(doc: {
  numberOfPages: number
  pages: Map<number, ITimeSheetPageDone>
}): { pages: string[]; error?: string; meta?: string } {
  const missing: number[] = []
  const info: ITimeSheetPageDone = Array.from(doc.pages.values())[0]!
  const month = dayjs(info.month, 'YYYY-MM')
  const pageIds: string[] = []
  for (let p = 1; p <= doc.numberOfPages; p++) {
    const page = doc.pages.get(p)
    if (!page) {
      missing.push(p)
    } else {
      pageIds.push(page.id)
    }
  }
  missing.sort()
  if (missing.length === 1) {
    return {
      pages: [],
      error: `Seite ${missing[0]} fehlt.`,
      meta: `${info.employeeName} · ${MONTHS[month.month()]} ${month.year()}`,
    }
  }
  if (missing.length > 1) {
    const pgs = [...missing]
    const last = pgs.pop()!
    return {
      pages: [],
      error: `Seiten ${pgs.join(', ')} und ${last} fehlen.`,
      meta: `${info.employeeName} · ${MONTHS[month.month()]} ${month.year()}`,
    }
  }
  if (doc.pages.size !== doc.numberOfPages || doc.numberOfPages === 0) {
    return {
      pages: [],
      error: `Seiten fehlen.`,
      meta: `${info.employeeName} · ${MONTHS[month.month()]} ${month.year()}`,
    }
  }

  return { pages: pageIds }
}
