import { z } from 'zod'

/**
 * If there is a new meal type, it is sufficient to just add it to
 * MealTypeValidator and mealDict. In addition to that you need to
 * specify all meal types that are available on one instance
 * in instance role.resident_meal_types as comma separated string.
 */

export const MealTypeValidator = z.enum([
  'breakfast',
  'lunch',
  'dinner',
  'ramadan',
  'water',
  'lunch package',
  'baby food',
])
export type MealType = z.infer<typeof MealTypeValidator>

export const MealScanValidator = z
  .object({
    scanningStationId: z.string().min(1).max(255),
    mealType: MealTypeValidator,
    token: z.string().min(1).max(255),
    force: z.boolean(), // Perform action even if there are warnings or gateMessages
  })
  .strict()

export const mealDict: Map<MealType, string> = new Map([
  ['breakfast', 'Frühstück'],
  ['lunch', 'Mittagessen'],
  ['dinner', 'Abendessen'],
  ['ramadan', 'Ramadan'],
  ['water', 'Wasser'],
  ['lunch package', 'Lunchpaket'],
  ['baby food', 'Babykost'],
])
