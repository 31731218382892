import * as React from 'react'
import { action, makeObservable, runInAction, when } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from '../../Model'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'
import { Collection, Resource } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IGroup } from '../../../../contracts/groups/interfaces/IGroup'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { storage } from 'services/storage'

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  compoundId: string | null
  className?: string
  children?: Element
  preselect?: boolean // Restore last selected value if available
  new?: boolean // Allow selection of 'Neue Gruppe erstellen...', value='new'
  archived?: boolean // Show archived groups
}

@observer
export class InputGroups extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  private readonly groups: Collection<IGroup>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.id = props.id || uniqueId('group-select-')
    this.groups = new Collection(`/api/${context.instance.id}/groups`, {
      compoundId: props.compoundId,
    })

    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.groups.init({ readOnly: true }))
    if (this.props.preselect) {
      const last = storage.get(`resident-group-${this.context.user.id}`)
      this.disposers.push(
        when(
          () => !!this.groups.resources,
          () => {
            const group = this.groups.resources?.find((r) => r.id === last)?.data
            if (!group || this.props.model.values[this.props.name]) {
              return
            }
            if (!this.props.archived && group.label.startsWith('[ARCHIV]')) {
              return
            }
            this.props.model.values[this.props.name] = group.id
          },
        ),
      )
    }
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  private onChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (!(event.target instanceof HTMLSelectElement)) {
      return
    }
    const target: HTMLSelectElement = event.target
    runInAction(() => (this.props.model.values[this.props.name] = target.value))
    this.props.onChange?.(event)
    if (target.value) {
      storage.set(`resident-group-${this.context.user.id}`, target.value)
    }
  }

  private optionMapper = (res: Resource<IGroup>) => {
    if (!res.data || (!this.props.archived && res.data.label.startsWith('[ARCHIV]'))) {
      return null
    }
    return (
      <option key={res.id} value={res.id}>
        {res.data.label}
      </option>
    )
  }

  render() {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const {
      name,
      model,
      label,
      className,
      children,
      compoundId,
      preselect,
      new: _new,
      archived,
      ...attributes
    } = this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)
    const disabled =
      attributes.disabled || (!model.values.compoundId && !!this.groups?.resources)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (disabled) {
      innerClassName += ' bg-gray-100'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <select
          {...attributes}
          className={innerClassName}
          name={name}
          onChange={this.onChange}
          value={model.values[name] || ''}
          id={this.id}
          disabled={disabled}
        >
          <option key='null' value=''>
            Gruppe wählen
          </option>
          {this.groups?.resources && this.groups.resources.map(this.optionMapper)}
          {_new && (
            <option key='new' value='new'>
              Neue Gruppe erstellen...
            </option>
          )}
        </select>
        {children}
      </div>
    )
  }
}
