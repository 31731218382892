import { Decimal } from 'decimal.js-light'

const invalidCharacters = /[^\d,-]/g
const leadingZeros = /^(0+)([0-9])/

const ROUND_HALF_UP = 4
const ROUND_DOWN = 1

export interface ParseChanges {
  removedDigits?: boolean[] // contains for each position of input string whether that position has been removed
  addedDigits?: number[] // contains for each position of input string how many digits were inserted before the pos
  resetToPreviousValue?: boolean
}

// converts a string to a value
export function parseDecimal(
  str: string,
  pasted: boolean,
  precision: number,
  scale: number,
  notNegative: boolean,
  previousValue: string | null,
  previousFormattedValue: string,
  inputCaretPos: number,
): {
  parsedValue: string | null
  parseChanges: ParseChanges
  negative: boolean
} {
  let round = pasted ? true : false
  let value = str
  const parseChanges = {
    removedDigits: new Array(value.length),
    addedDigits: new Array(value.length),
    resetToPreviousValue: false,
  }

  // discard all invalid characters
  value = value.replace(invalidCharacters, '')

  // convert local decimal symbols to english ones
  value = value.split(',').join('.')

  // negate multiple minuses and save the result for later
  const splitByMinuses = value.split('-')
  value = splitByMinuses.join('')
  const numberOfMinuses = splitByMinuses.length - 1
  const negative = notNegative ? false : numberOfMinuses % 2 === 1

  const splitByDecimalPoints = value.split('.')

  // find decimalpoint of previous value
  let previousFormattedValueDecimalPointIndex = -1
  let previousValueNegative: boolean | undefined
  if (previousValue !== null) {
    previousValueNegative = previousValue.startsWith('-')
    previousFormattedValueDecimalPointIndex = previousFormattedValue.indexOf(',')
  }

  let integerPart: string
  let decimalPart: string | undefined
  // if a not allowed number of decimal points was inserted
  if (splitByDecimalPoints.length > 2) {
    let previousFormattedValueDecimalPointIndexOfAbsoluteValue = previousValueNegative
      ? previousFormattedValueDecimalPointIndex - 1
      : previousFormattedValueDecimalPointIndex
    if (
      previousFormattedValueDecimalPointIndexOfAbsoluteValue >
      splitByDecimalPoints[0].length
    ) {
      // a decimal point has been inserted in the integer part
      round = true
      integerPart = splitByDecimalPoints.shift()!
      decimalPart = splitByDecimalPoints.join('')
    } else {
      // a decimal point has been inserted in the decimal part or the previousValue had no decimal point
      // (might happen if a value is pasted instead of typed)
      decimalPart = splitByDecimalPoints.pop()
      integerPart = splitByDecimalPoints.join('')
    }
    value = `${integerPart}.${decimalPart}`
  } else {
    integerPart = splitByDecimalPoints[0]
    decimalPart = splitByDecimalPoints[1]

    // if there was no decimalpoint in the previous value (means a decimal point was typed), round
    if (previousFormattedValueDecimalPointIndex === -1) {
      round = true
    }
  }

  // if integerPart is '' but a decimalPart exists (e. g. ',123'), replace integerPart with a 0 (e. g. '0,123')
  // only do this on paste or if cursor is after the comma. Otherwise we would prevent deleting the integer 0
  if (
    scale > 0 &&
    integerPart === '' &&
    decimalPart !== undefined &&
    (pasted || inputCaretPos > (negative ? 1 : 0))
  ) {
    integerPart = '0'
    if (negative === true) {
      parseChanges.addedDigits[1] = 1
    } else {
      parseChanges.addedDigits[0] = 1
    }
  }

  // check whether length of integer part exceeds the given precision => if yes, return previousValue
  const maxIntegerPlaces = precision - scale
  if (integerPart.length > maxIntegerPlaces) {
    parseChanges.resetToPreviousValue = true
    return { parsedValue: previousValue, parseChanges, negative }
  }

  // check whether length of decimal part exceeds the given scale => if yes, round
  if (decimalPart !== undefined && decimalPart.length > scale) {
    // since we do not remove leading zeros, but Decimal does, we need to save leading zeros to add them again
    // after rounding
    const matchLeadingZeros = integerPart.match(leadingZeros)

    value = new Decimal(`${integerPart}.${decimalPart}`).toFixed(
      scale,
      round ? ROUND_HALF_UP : ROUND_DOWN,
    )

    // add leading zeros again
    if (matchLeadingZeros) {
      value = `${matchLeadingZeros[1]}${value}`
    }

    // mark from the removed digits as removed
    const newDecimalPart = value.split('.')[1] || ''
    const removedDigits = decimalPart.substr(newDecimalPart.length)
    markTrue(
      parseChanges.removedDigits,
      str.lastIndexOf(removedDigits),
      removedDigits.length,
    )
  } else if (scale > 0 && decimalPart !== undefined) {
    value = `${integerPart}.${decimalPart}`
  } else {
    value = integerPart
  }

  if (value === '' || value === '.') {
    return { parsedValue: null, parseChanges, negative }
  }

  // add minus if number is negative
  value = `${negative ? '-' : ''}${value}`

  return { parsedValue: value, parseChanges, negative }
}

function markTrue(removedDigits: boolean[], from: number, length?: number) {
  for (let i = from; i < removedDigits.length; i++) {
    if (length !== undefined && i - from >= length) {
      return
    }
    removedDigits[i] = true
  }
}
