import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Model } from 'components/Form/Model'
import { RoundIcon } from 'components/RoundIcon'
import { IDocumentFolderPermission } from 'contracts/general/interfaces/IDocumentFolderPermission'
import { observer } from 'mobx-react'
import * as React from 'react'

const permissionOptions: InputSelectOption[] = [
  { value: 0, label: 'Nicht erlaubt' },
  { value: 1, label: 'Nur in Stammgeländen' },
  { value: 2, label: 'Immer erlaubt' },
]

interface Props {
  folderPermission: IDocumentFolderPermission
  roles: InputSelectOption[]
  onDelete: (folderPermission: IDocumentFolderPermission) => void
}

@observer
export class FolderPermissionRow extends React.Component<Props, {}> {
  private readonly model: Model<IDocumentFolderPermission>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.folderPermission)
  }

  private onDelete = () => this.props.onDelete(this.props.folderPermission)

  render() {
    return (
      <div>
        {/* Line 1 */}
        <div className='flex mb-3'>
          <div className='flex-auto'>
            <InputSelect
              model={this.model}
              name='roleId'
              options={this.props.roles}
              label='Rolle'
            />
          </div>
          <div style={{ flex: '0 0 44px' }}>
            <RoundIcon
              icon='fa fa-trash'
              onClick={this.onDelete}
              style={{ top: 4, left: 16 }}
            />
          </div>
        </div>

        {/* Line 2 */}
        <div className='flex mb-3'>
          <div style={{ flex: '0 0 147px' }}>
            <InputSelect
              model={this.model}
              name='downloadDocuments'
              label='Download'
              options={permissionOptions}
            />
          </div>
          <div style={{ flex: '0 0 1rem' }} />
          <div style={{ flex: '0 0 147px' }}>
            <InputSelect
              model={this.model}
              name='uploadDocuments'
              label='Upload'
              options={permissionOptions}
            />
          </div>
          <div style={{ flex: '0 0 1rem' }} />
          <div style={{ marginRight: 44, flex: '1 1 auto' }}>
            <InputSelect
              model={this.model}
              name='deleteDocuments'
              label='Löschen'
              options={permissionOptions}
            />
          </div>
        </div>

        {/* Spacer & border */}
        <div
          style={{
            height: 7,
            width: 508,
            borderBottom: '1px solid #dddddd',
            marginBottom: 19,
          }}
        />
      </div>
    )
  }
}
