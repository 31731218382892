import * as React from 'react'
import { action, makeObservable, runInAction, when } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from '../../Model'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'
import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { isStammBuilding } from 'helpers/isStamm'

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  className?: string
  children?: Element
  setRef?: (HTMLInputElement) => void
  compoundId?: string | null // undefined: Load all buildings, null: load no building
  onlyStamm?: boolean
  emptyText?: string
  at?: string // ISO date. All buildings that are either not archived or archived after .at are shown. If .at is missing, archived buildings are not shown.
  autoSelectOnlyItem?: boolean // defaults to true if not disabled
}

@observer
export class InputBuilding extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  private readonly buildings: Collection<IBuilding, {}, { compoundId?: string | '' }>
  private readonly disposers: Disposer[] = []
  private whenDisposer: Disposer | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.id = props.id || uniqueId('compound-select-')
    this.buildings = new Collection(
      `/api/${context.instance.id}/accommodations/buildings`,
      { compoundId: props.compoundId === null ? '' : props.compoundId },
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.buildings.init({ readOnly: true, observeQuery: true }))
    this.disposers.push(() => this.whenDisposer?.())
    this.selectOnlyItem()
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.compoundId !== this.props.compoundId) {
      runInAction(() => {
        this.props.model.values[this.props.name] = null
        this.buildings.query.compoundId =
          this.props.compoundId === null ? '' : this.props.compoundId
      })
      this.selectOnlyItem()
    }
  }

  private selectOnlyItem = () => {
    if (this.props.autoSelectOnlyItem === false || this.props.disabled) {
      return
    }
    this.whenDisposer?.()
    if (!this.props.compoundId) {
      return
    }
    this.whenDisposer = when(
      () => {
        if (!this.buildings.resources || !this.props.compoundId) {
          return false
        }
        const filtered = this.buildings.resources
          .map((b) => b.data)
          .filter(this.isIncluded)
        return filtered.length === 1 && filtered[0]?.compoundId === this.props.compoundId
      },
      action(() => {
        const filtered = (this.buildings.resources || [])
          .map((b) => b.data)
          .filter(this.isIncluded)
        this.props.model.values[this.props.name] = filtered[0]?.id ?? null
        if (filtered[0]) {
          this.context.defaults.selectedBuildingId = filtered[0].id
          this.context.defaults.selectedCompoundId = filtered[0].compoundId
        } else {
          this.context.defaults.selectedBuildingId = null
        }
      }),
    )
  }

  @action
  private onChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (!(event.target instanceof HTMLSelectElement)) {
      return
    }
    const target: HTMLSelectElement = event.target
    this.props.model.values[this.props.name] = target.value || null
    const [building] = (this.buildings.resources || []).filter(
      (b) => b.id === this.props.model.values[this.props.name],
    )
    if (building?.data) {
      this.context.defaults.selectedBuildingId = building.id
      this.context.defaults.selectedCompoundId = building.data.compoundId
    } else {
      this.context.defaults.selectedBuildingId = null
    }
    this.props.onChange?.(event)
  }

  private optionMapper = (res: Resource<IBuilding>) => {
    if (!res.data || !this.isIncluded(res.data)) {
      return null
    }
    return (
      <option key={res.id} value={res.id}>
        {res.data.label}
      </option>
    )
  }

  private isIncluded = (building: IBuilding | null): boolean => {
    if (!building) {
      return false
    }
    if (building.deletedAt && (!this.props.at || building.deletedAt <= this.props.at)) {
      return false
    }
    if (
      this.props.onlyStamm &&
      building.id !== this.props.model.values[this.props.name] &&
      !isStammBuilding(building.id)
    ) {
      return false
    }
    return true
  }

  render() {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const {
      name,
      model,
      label,
      className,
      setRef,
      onlyStamm,
      compoundId,
      emptyText,
      children,
      autoSelectOnlyItem,
      at,
      ...attributes
    } = this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <select
          {...attributes}
          className={innerClassName}
          name={name}
          ref={setRef}
          onChange={this.onChange}
          value={model.values[name] || ''}
          id={this.id}
        >
          <option key='null' value=''>
            {emptyText || 'Gebäude wählen'}
          </option>
          {this.buildings.resources && this.buildings.resources.map(this.optionMapper)}
        </select>
        {children}
      </div>
    )
  }
}
