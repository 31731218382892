import { Collection, hermes, Resource } from '@byll/hermes'
import { Card } from 'components/Card'
import { Button } from 'components/Form/components/Button'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InventoryItemMoveDialog } from './InventoryItemMoveDialog'
import { toLocaleNumber } from 'contracts/general/helpers/toLocaleNumber'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { InventoryItemTopBar } from './InventoryItemTopBar'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { ColumnSpinner } from 'components/SideBarLayout/components/ColumnSpinner'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'

interface Props {
  id: string
  folder: IInventoryFolder
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
  setEdit: (edit: boolean) => void
}

const scopeDict = {
  '': 'Alle Zielgruppen',
  child: 'Für Kinder',
  woman: 'Für Frauen',
  man: 'Für Männer',
}

@observer
export class InventoryItemPreview extends React.Component<Props, {}> {
  static contextType = AppContext
  private item: Resource<IInventoryItem>
  private disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.item = new Resource(`/api/${context.instance.id}/inventory/items/${props.id}`)
  }

  componentDidMount() {
    this.disposers.push(this.item.init())
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private openItemMoveDialog = async () => {
    if (!this.item.data || !this.context.permissions.inventory_manageItems) {
      return
    }
    const promise = box.custom(
      <InventoryItemMoveDialog
        itemId={this.item.id}
        folders={this.props.folders}
        onClose={() => promise.close()}
      />,
      { context: this.context },
    )
  }

  private getURL = (url: string) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url
    } else {
      return 'http://'.concat(url)
    }
  }

  render() {
    if (!this.item.data || !this.props.folders?.resources) {
      return <ColumnSpinner />
    }
    const bundles: IInventoryBundle[] = this.item.data.bundleIds
      .map(
        (bundleId) =>
          hermes.getFromStore<IInventoryBundle>(
            `/api/${this.context.instance.id}/inventory/bundles/${bundleId}`,
            false,
          )!,
      )
      .filter(Boolean)
    return (
      <>
        <InventoryItemTopBar folder={this.props.folder} item={this.item.data} />

        <CardColumn>
          <Card>
            <div className='p-6'>
              <div className='text-xl text-gray-500'>{this.item.data.label}</div>
              <div className='mt-4 grid max-w-2xl grid-cols-1 sm:grid-cols-2 gap-5'>
                <img
                  className='rounded-md w-full sm:w-min'
                  width={300}
                  height={300}
                  src={getInventoryImageSrc(
                    this.item.data.imageId,
                    this.context.instance.id,
                  )}
                  alt=''
                />
                <div className='gap-0 grid grid-cols-2'>
                  <span>Bruttopreis</span>
                  <span>
                    {this.item.data.brutto
                      ? toLocaleNumber(this.item.data.brutto) + ' €'
                      : '-'}
                  </span>
                  <span>Nettopreis</span>
                  <span>
                    {this.item.data.netto
                      ? toLocaleNumber(this.item.data.netto) + ' €'
                      : '-'}
                  </span>
                  <span>Anzahl</span>
                  <span>{this.item.data.count}</span>
                  <span>Kritischer Bestand</span>
                  <span>{this.item.data.minStock || '-'}</span>
                  <span>Maximaler Bestand</span>
                  <span>{this.item.data.maxStock || '-'}</span>
                  <span>Ausgabemenge</span>
                  <span>{this.item.data.issueCount || '1'}</span>
                  <span>Packungsgröße</span>
                  <span>{this.item.data.packageSize || '-'}</span>
                  <span>Einheit</span>
                  <span>{this.item.data.unit}</span>
                  <span>Nutzungsdauer</span>
                  <span>
                    {this.item.data.durationOfUse
                      ? `${this.item.data.durationOfUse} ${
                          this.item.data.durationOfUse === '1' ? 'Tag' : 'Tage'
                        }`
                      : '-'}
                  </span>
                  <span>Bestelllink</span>
                  {this.item.data.orderlink ? (
                    <a
                      className='underline text-blue-500'
                      target={'_blank'}
                      rel='noreferrer'
                      href={this.getURL(this.item.data.orderlink)}
                    >
                      {this.item.data.orderlink}
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                  <span>Barcode</span>
                  <span>{this.item.data.barcode || '-'}</span>
                  <span>Zielgruppen</span>
                  <span>
                    {this.item.data.scope
                      .split(',')
                      .map((s) => scopeDict[s] || '')
                      .join(', ') || '-'}
                  </span>
                  <span>Paket</span>
                  <span>{bundles.map((b) => b.label).join(', ')}</span>
                </div>
              </div>
              <div className='mt-4 flex flex-col gap-4 sm:flex-row justify-start'>
                {this.context.permissions.inventory_manageItems && (
                  <Button onClick={() => this.props.setEdit(true)}>
                    Gegenstand bearbeiten
                  </Button>
                )}
                {this.context.permissions.inventory_manageItems && (
                  <Button outline onClick={this.openItemMoveDialog}>
                    Gegenstand verschieben
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </CardColumn>
      </>
    )
  }
}
