import { Button } from 'components/Form/components/Button'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { PrintIcon } from './PrintIcon'

interface Props {
  folder: IDocumentFolder
  documentIds: string[] | null // null: Do not offer batch download
}

export const BatchCreateSuccess: React.FC<Props> = (props) => {
  const context = React.useContext(AppContext)
  return (
    <div>
      <div className='text-green-500 pt-10' style={{ fontSize: 80 }}>
        <i className='fas fa-check-circle' />
      </div>
      <div className='text-lg text-gray-800 my-10'>
        Ihre ausgewählten Dokumente wurden erfolgreich
        <br />
        erstellt und im Ordner {props.folder.label} gespeichert.
      </div>
      <div className='mb-6'>
        {!props.documentIds && (
          <Button
            color='success'
            className='mb-6'
            onClick={() =>
              box.alert(
                'Nur für PDF möglich',
                'Sie haben mindestens eine Vorlage ausgewählt, die ein Dokument in einem anderen Format erzeugt. Ein Sammeldruck ist nur möglich, wenn Sie ausschließlich PDF Vorlagen auswählen.',
              )
            }
          >
            <span>
              <i className='fas fa-print' />
            </span>{' '}
            Sammeldruck
          </Button>
        )}
        {props.documentIds && (
          <PrintIcon
            type='button'
            label='Sammeldruck'
            url={`/api/${context.instance.id}/documents/concat/${
              props.documentIds[props.documentIds.length - 1]
            }`}
          />
        )}
      </div>
    </div>
  )
}
