import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import * as React from 'react'

interface Props {
  onClose: (response: boolean) => void
}

export class SortConfirmDialog extends React.Component<Props, {}> {
  render() {
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose(false)}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div>
          <h3 className='text-gray-500 text-xl'>Unsortierte Liste</h3>
          <div className='text-gray-500 mt-4'>
            Die Liste wird vor dem Speichern alphabetisch sortiert.
          </div>
          <div className='mt-4 float-right'>
            <Button
              className='mr-2'
              color='secondary'
              outline
              onClick={() => this.props.onClose(false)}
            >
              Abbrechen
            </Button>
            <Button onClick={() => this.props.onClose(true)}>Ok</Button>
          </div>
        </div>
      </div>
    )
  }
}
