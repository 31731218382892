import * as React from 'react'

import styles from './styles.module.scss'

interface Props {
  className: string
  label: JSX.Element | string
  small?: boolean
}

export class CornerBadge extends React.Component<Props, {}> {
  render() {
    return (
      <div className={this.props.small ? styles.cornerBadgeSmall : styles.cornerBadge}>
        <div className={this.props.className}>{this.props.label}</div>
      </div>
    )
  }
}
