import { ICostRange } from '../interfaces/IResidentCost'

export function getCostRangeColor(s: ICostRange) {
  if (!s.internal) {
    return 'bg-gray-500'
  }
  if (s.external) {
    if (s.external.reason === 'Krankenhausaufenthalt') {
      // Class hospital does not have layout function.
      // It is used to merge ranges with same className, just for visualization.
      if (!s.coverage) {
        return 'hospital bg-indigo-500'
      }
      if (!s.coverage.isValid) {
        return 'hospital bg-gray-500'
      }
      return s.external.hasConfirmation && s.coverage.hasConfirmation
        ? 'hospital bg-green-500'
        : 'hospital bg-red-500'
    }
    return 'bg-indigo-500'
  }
  if (s.coverage) {
    if (!s.coverage.isValid) {
      return 'bg-gray-500'
    }
    return s.coverage.hasConfirmation ? 'bg-green-500' : 'bg-red-500'
  }
  return 'bg-yellow-500'
}
