import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { hermes } from '@byll/hermes'
import { BuildingsSelect } from '../AccommodationBookings/components/BuildingsSelect'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { z } from 'zod'
import { isPastDate } from '../helpers/isPastDate'
import { Spinner } from 'components/Spinner'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { box } from 'services/box'
import { dayjs } from 'helpers/dayjs'
import { isStammBuilding } from 'helpers/isStamm'

interface Props {}

const COMPOUND_MIK = '86'
const COMPOUND_P3 = '87'
const COMPOUND_NBS = '88'

@observer
export class BelegungsaenderungenRob extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private buildings: IBuilding[] | null = null
  @observable private selected = new Map<string, boolean>() //key: buildingId value: selected/nicht selected
  @observable private loading: boolean = false
  private readonly model = new Model<{ date }>(
    { date: dayjs().format('YYYY-MM-DD') },
    z.object({ date: z.string().refine(isPastDate) }),
  )

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.loadBuildings()
  }

  private loadBuildings = async () => {
    const buildings: IBuilding[] = await hermes.indexOnceNew(
      `/api/${this.context.instance.id}/accommodations/buildings`,
    )
    runInAction(() => {
      this.buildings = buildings.filter((b) => {
        return (
          !b.deletedAt &&
          (b.compoundId === COMPOUND_MIK ||
            b.compoundId === COMPOUND_P3 ||
            b.compoundId === COMPOUND_NBS) &&
          isStammBuilding(b.id)
        )
      })
      this.buildings.sort((b1, b2) => (b1.compoundId < b2.compoundId ? -1 : 1))
    })
  }

  private downloadReport = async () => {
    if (!this.model.values.date) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (!isPastDate(this.model.values.date)) {
      box.alert('Datum ungültig', 'Das Datum darf nicht in der Zukunft liegen.')
      return
    }
    if (this.selected.size === 0) {
      box.alert('Gebäudeauswahl', 'Bitte wählen Sie mindestens ein Gebäude aus.')
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'belegungsaenderungenRob',
      this.context.instance.id,
      {
        buildingIds: Array.from(this.selected)
          .filter((s) => s[1] === true)
          .map((s) => s[0]),
        date: this.model.values.date,
        compoundGroup: [COMPOUND_MIK, COMPOUND_P3, COMPOUND_NBS],
        addLegacyResidents: true,
      },
      `Gesamtbelegungsliste_ANKER_Oberbayern_Nord_Stand_${dayjs(
        this.model.values.date,
      ).format('YYYY_MM_DD')}.xlsx`,
    )
    runInAction(() => (this.loading = false))
  }

  @action private selectAllBuildings = () => {
    this.selected.clear()
    this.buildings?.forEach((b) => this.selected.set(b.id, true))
  }
  @action private selectMik = () => {
    this.selected.clear()
    this.buildings?.forEach((b) => {
      if (b.compoundId === COMPOUND_MIK) {
        this.selected.set(b.id, true)
      }
    })
  }
  @action private selectP3 = () => {
    this.selected.clear()
    this.buildings?.forEach((b) => {
      if (b.compoundId === COMPOUND_P3) {
        this.selected.set(b.id, true)
      }
    })
  }
  @action private selectNbs = () => {
    this.selected.clear()
    this.buildings?.forEach((b) => {
      if (b.compoundId === COMPOUND_NBS) {
        this.selected.set(b.id, true)
      }
    })
  }

  render() {
    if (!this.context.permissions.report_belegungsaenderungen_rob) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Belegungsänderungen'
        subtitle='Wählen Sie aus, welche Gebäude in die in den Report aufgenommen werden sollen.'
      >
        <div id={this.model.id}>
          <div className='flex gap-4 mb-4'>
            <Button onClick={this.selectAllBuildings} color='secondary' outline>
              Alle
            </Button>
            <Button onClick={this.selectMik} color='secondary' outline>
              MIK
            </Button>
            <Button onClick={this.selectP3} color='secondary' outline>
              P3
            </Button>
            <Button onClick={this.selectNbs} color='secondary' outline>
              NBS
            </Button>
          </div>
          <div className='flex flex-col gap-1'>
            {!this.buildings && <Spinner />}
            {this.buildings &&
              this.buildings.map((building) => (
                <div key={building.id}>
                  <BuildingsSelect selected={this.selected} building={building} />
                </div>
              ))}
          </div>
          <div className='mt-4 w-36'>
            <InputDate label='Datum' name='date' model={this.model} />
          </div>
        </div>
        <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
