import * as React from 'react'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { isTime } from 'contracts/general/helpers/isTime'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { z } from 'zod'
import { Collection } from '@byll/hermes'
import { dayjs } from 'helpers/dayjs'
import { AppContext } from 'services/connection/models/AppContext'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { ResponsibilityEndModel } from '../../ResponsibilityEndForm'
import { Model } from 'components/Form/Model'
import { EndOfStayStep1Form } from './components/EndOfStayStep1Form'
import { EndOfStayStep2Creating } from './components/EndOfStayStep2Creating'

interface Props {
  resident: IResident
  booking: IBooking
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  bookings?: IBooking[]
}

@observer
export class EndOfStayForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<ResponsibilityEndModel>
  @observable private step: 'form' | 'creating' = 'form'

  constructor(props: Props) {
    super(props)
    const beginAt = dayjs(props.booking.beginAt)
    const validator = z.object({
      beginDate: z.string(),
      beginTime: z.string().refine(isTime),
    })
    this.model = new Model(
      {
        isNew: !props.booking.id,
        id: props.booking.id,
        beginDate: beginAt.format('YYYY-MM-DD'),
        beginTime: beginAt.format('HH:mm'),
        compoundId: props.booking.compoundId,
        reason: !props.booking.id ? 'Überstellung' : props.booking.extra.infoReason || '',
        extra: {
          ...props.booking.extra,
          address: {
            name: props.booking.extra.address?.name || '',
            street: props.booking.extra.address?.street || '',
            zip: props.booking.extra.address?.zip || '',
            city: props.booking.extra.address?.city || '',
            contact: props.booking.extra.address?.contact || '',
          },
        },
        comment: props.booking.comment,
        family: false,
      },
      validator,
    )
    makeObservable(this)
  }

  @action
  private setStep = (step: 'form' | 'creating') => (this.step = step)

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {this.model.values.isNew ? 'Aufenthalt beenden' : 'Aufenthaltsende'}
            </Dialog.Title>
          </div>
        </div>

        {/* Date / compound */}
        {this.step === 'form' && (
          <EndOfStayStep1Form
            model={this.model}
            setStep={this.setStep}
            onClose={this.props.onClose}
            members={this.props.members}
            bookings={this.props.bookings}
          />
        )}

        {/* Save */}
        {this.step === 'creating' && (
          <EndOfStayStep2Creating
            model={this.model}
            setStep={this.setStep}
            onClose={this.props.onClose}
            members={this.props.members}
            resident={this.props.resident}
          />
        )}
      </>
    )
  }
}
