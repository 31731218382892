import * as React from 'react'
import { hermes } from '@byll/hermes'
import { Spinner } from 'components/Spinner'
import { TitleBar } from 'components/TitleBar'
import { ICompany } from 'contracts/companies/interfaces/ICompany'
import { ICompanyIdCardTemplate } from 'contracts/userCard/interfaces/ICompanyIdCardTemplate'
import { observer } from 'mobx-react'
import { Routes, Route, useParams, useNavigate } from 'react-router'
import { AppContext } from 'services/connection/models/AppContext'
import { CompanyCardTab } from './components/CompanyCardTab'
import { CompanyOverviewTab } from './components/CompanyOverviewTab'
import styles from './components/styles.module.scss'
import { NotFoundError } from 'contracts/errors/HermesErrors'
import { Callout } from 'components/Callout'
import { CompanyExportTab } from './components/CompanyExportTab'

interface Props {}

@observer
export class CompaniesContent extends React.Component<Props, {}> {
  render() {
    return (
      <div className='md:ml-80 pt-14 flex min-h-screen'>
        <div className={styles.spacer}>
          <div className='top-14 sticky'>
            <TitleBar />
          </div>
        </div>
        <Routes>
          <Route path=':id/overview' element={<RenderCompanyOverviewTab />} />
          <Route path=':id/card' element={<RenderCompanyCardTab />} />
          <Route path=':id/export' element={<RenderCompanyExportTab />} />
        </Routes>
        <div className={styles.spacer}>
          <div className='top-14 sticky'>
            <TitleBar />
          </div>
        </div>
      </div>
    )
  }
}

export const RenderCompanyExportTab: React.FC<{}> = () => {
  const params = useParams()
  return <CompanyExportTab companyId={params.id!} />
}

export const RenderCompanyCardTab: React.FC<{}> = (props) => {
  const context = React.useContext(AppContext)
  const params = useParams()
  const [error, setError] = React.useState<boolean>(false)

  const [companyCardTemplate, setCompanyCardTemplate] =
    React.useState<ICompanyIdCardTemplate | null>(null)

  async function loadData() {
    let company: ICompany | null = null
    try {
      company = await hermes.getOnceNew<ICompany>(
        `/api/${context.instance.id}/companies/${params.id}`,
        { getFromStoreIfPossible: true },
      )
    } catch (_e) {
      setError(true)
    }
    try {
      const companyIdCardTemplate = await hermes.getOnceNew<ICompanyIdCardTemplate>(
        `/api/${context.instance.id}/companyIdCardTemplates/${params.id}`,
      )
      setCompanyCardTemplate(companyIdCardTemplate)
    } catch (e: any) {
      if (e.id === NotFoundError.id) {
        setCompanyCardTemplate({
          id: '',
          logoXCm: null,
          logoYCm: null,
          logoWidthCm: null,
          logoHeightCm: null,
          title: company?.name || '',
          text: '',
          signatureLabel: 'companyName',
          signatureName: '',
          companyNumber: '',
          logoImageId: null,
        })
      } else {
        setError(true)
      }
    }
  }

  React.useEffect(() => {
    setCompanyCardTemplate(null)
    loadData()
    // eslint-disable-next-line
  }, [])

  if (error) {
    return (
      <div>
        <div className={styles.spacer}>
          <div className='top-14 sticky'>
            <TitleBar />
          </div>
        </div>
        <div className='flex h-full'>
          <Callout
            icon='fas fa-exclamation-triangle'
            iconColor='#ef4444'
            title={'Fehler'}
            subtitle={'Beim Laden der Ausweisvorlage ist ein Fehler aufgetreten.'}
          />
        </div>
      </div>
    )
  }
  if (!companyCardTemplate) {
    return <Spinner />
  }
  return (
    <CompanyCardTab companyIdCardTemplate={companyCardTemplate} companyId={params.id!} />
  )
}

export const RenderCompanyOverviewTab: React.FC<{}> = () => {
  const context = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()
  const [company, setCompany] = React.useState<ICompany | null>(null)
  const [error, setError] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (params.id === 'new') {
      setCompany({
        id: '',
        instanceId: '',
        name: '',
        address: '',
        contactEmail: '',
        contactPerson: '',
        comment: '',
        archived: false,
        options: {},
      })
    } else {
      hermes
        .getOnceNew<ICompany>(`/api/${context.instance.id}/companies/${params.id}`, {
          getFromStoreIfPossible: true,
        })
        .then(setCompany)
        .catch(() => setError(true))
    }
    // eslint-disable-next-line
  }, [])

  if (error) {
    return (
      <div>
        <div className={styles.spacer}>
          <div className='top-14 sticky'>
            <TitleBar />
          </div>
        </div>
        <div className='flex h-full'>
          <Callout
            icon='fas fa-exclamation-triangle'
            iconColor='#ef4444'
            title={'Fehler'}
            subtitle={'Beim Laden der Ausweisvorlage ist ein Fehler aufgetreten.'}
          />
        </div>
      </div>
    )
  }

  if (!company) {
    return <Spinner />
  }

  return <CompanyOverviewTab company={company} navigate={navigate} />
}
