import * as React from 'react'
import styles from '../styles.module.scss'

interface Props {
  className?: string
  opaque?: boolean
  getRef?
}

export const DialogOverlayArea: React.FC<Props> = (props) => (
  <div
    className={`${styles.overlay} ${props.opaque ? styles.opaque : styles.transparent} ${
      props.className || ''
    }`}
    ref={props.getRef}
  >
    {props.children}
  </div>
)
