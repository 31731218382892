import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { getBuildingData } from '../Hausausweis/getBuildingData'
import { encodeBarcode } from 'modules/Pdf/helpers/encodeBarcode'

export class LEAvorl extends React.Component<ResidentCardProps, {}> {
  render() {
    return (
      <div
        className={styles.page}
        style={this.props.batch ? {} : { pageBreakAfter: 'always' }}
      >
        <div className='flex'>
          <div style={{ position: 'absolute', top: 8, left: 8, width: 226 }}>
            <img
              src={getResidentImageSrc(
                this.props.instanceId,
                this.props.resident.imageId,
                this.props.resident.sex,
                'portrait',
              )}
              alt='Bewohnerbild'
            />
            <p
              style={{
                fontFamily: 'Barcode',
                fontSize: 40,
                marginTop: -11,
                paddingBottom: 0,
                transform: 'scaleY(-2.3)',
              }}
            >
              {encodeBarcode(this.props.resident.token || 'EXAMPLE')}
            </p>
          </div>
          <div>
            <div className='absolute pl-2' style={{ left: 238 }}>
              <div style={{ height: 36, fontSize: 30, marginBottom: 8 }}>
                <b>Hausausweis</b>
              </div>
            </div>

            <div
              className='absolute flex flex-col overflow-hidden pl-2'
              style={{ left: 238, top: 32, right: 8, fontSize: 12, lineHeight: '12px' }}
            >
              <div className='flex mt-2' style={{ marginTop: 16 }}>
                <div
                  className='flex-content overflow-hidden'
                  style={{ width: 160, marginRight: 24 }}
                >
                  <div style={{ fontSize: 10 }}>Name/Surname/Nom</div>
                  <div style={{ fontSize: 14 }}>
                    {this.props.resident.lastName.toUpperCase()}&nbsp;
                  </div>
                </div>
                <div className='flex-auto ml-1'>
                  <div style={{ fontSize: 10 }}>JBP ID</div>
                  <div style={{ fontSize: 14 }}>{toJbpId(+this.props.resident.id)}</div>
                </div>
              </div>
              <div className='flex mt-2'>
                <div
                  className='flex-content overflow-hidden'
                  style={{ width: 160, marginRight: 24 }}
                >
                  <div style={{ fontSize: 10, marginTop: 8 }}>
                    Vorname/Given Names/Prénoms
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {this.props.resident.firstName}&nbsp;
                  </div>
                </div>
                <div className='flex-auto ml-1'>
                  <div style={{ fontSize: 10, marginTop: 8 }}>MigVIS ID</div>
                  <div style={{ fontSize: 14 }}>
                    {this.props.resident.data.migVisId}&nbsp;
                  </div>
                </div>
              </div>
              <div className='mt-2'>
                <div style={{ fontSize: 10, marginTop: 8 }}>
                  Geburtsdatum/Date of birth/Date de naissance
                </div>
                <div style={{ fontSize: 14 }}>
                  {this.props.resident.dateOfBirth
                    ? dayjs(this.props.resident.dateOfBirth).format('DD.MM.YYYY')
                    : ' -'}
                  &nbsp;
                </div>
              </div>
              <div className='mt-2'>
                <div style={{ fontSize: 10, marginTop: 8 }}>
                  Herkunftsland/Country of origin/Pays d'origine
                </div>
                <div style={{ fontSize: 14 }}>
                  {this.props.resident.data.countryOfBirth?.toUpperCase() || ' -'}&nbsp;
                </div>
              </div>
              <div className='mt-2'>
                <div style={{ fontSize: 10, marginTop: 8 }}>
                  Unterkunft/Residence/l'hébergement
                </div>
                <div style={{ fontSize: 14, lineHeight: '15px' }}>
                  {getBuildingData(this.props.resident) || 'Nicht eingecheckt'}&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
