import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { makeObservable, observable, runInAction } from 'mobx'
import { hermes } from '@byll/hermes'
import axios from 'axios'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { getDiskSizeLabel } from 'contracts/general/helpers/getDiskSizeLabel'
import { isPreviewImage } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/helpers/isPreviewImage'
import { ImagePreview } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/ImagePreview'
import { RoundIcon } from 'components/RoundIcon'
import { Spinner } from 'components/Spinner'
import { download } from 'helpers/download'
import { PdfPreview } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/PdfPreview'
import { PrintIcon } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/PrintIcon'
import { OtherPreview } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/OtherPreview'

interface Props {
  id: string
}

@observer
export class Documents extends React.Component<Props, {}> {
  static contextType = AppContext
  private cancelDownload: (() => void) | null = null
  @observable.ref private meta: IDocumentMetadata | null = null
  @observable.ref private error: string | null = null
  private file: Blob | null = null
  private mounted = true

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load(this.props.id)
  }

  private load = async (id: string) => {
    try {
      // Get document
      const source = axios.CancelToken.source()
      this.cancelDownload = () => source.cancel('Canceled by user')

      const [response, meta] = await Promise.all([
        axios.get(`/api/${this.context.instance.id}/documents/files/${id}`, {
          cancelToken: source.token,
          responseType: 'arraybuffer',
        }),
        hermes.getOnceNew(`/api/${this.context.instance.id}/documents/metadata/${id}`),
      ])
      this.cancelDownload = null
      if (!this.mounted) {
        return
      }
      this.file = new Blob([response.data])
      runInAction(() => (this.meta = meta as any))
    } catch (_e) {
      runInAction(() => (this.error = 'Das Dokument konnte leider nicht geladen werden.'))
    }
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancelDownload?.()
  }

  private onDownload = () => {
    download(`/api/${this.context.instance.id}/documents/files/${this.props.id}`)
  }

  render() {
    let preview: JSX.Element | null = null
    if (this.error) {
      preview = (
        <div className='flex flex-col shadow bg-white relative'>
          <div className='flex-content mx-auto mt-12 mb-6' style={{ fontSize: '120px' }}>
            <i className='fas fa-exclamation-triangle text-red-500' />
          </div>
          <div className='flex-content mb-12'>
            <div className='text-gray-900 truncate text-lg'>Laden abgebrochen</div>
            <div className='text-gray-500 truncate'>Dokument wurde nicht gefunden.</div>
          </div>
        </div>
      )
    } else if (!this.meta || !this.file) {
      preview = <Spinner delay className='shadow bg-white relative flex-auto' />
    } else if (isPreviewImage(this.meta.name)) {
      preview = (
        <ImagePreview id={this.props.id} name={this.meta.name} cache={this.file}>
          <RoundIcon
            icon='fas fa-download'
            tooltip='Herunterladen'
            style={{ position: 'absolute', top: 16, right: 16 }}
            color='primary'
            onClick={this.onDownload}
          />
        </ImagePreview>
      )
    } else if (this.meta.name.toLowerCase().endsWith('.pdf')) {
      preview = (
        <PdfPreview cache={this.file}>
          <RoundIcon
            icon='fas fa-download'
            tooltip='Herunterladen'
            style={{ position: 'absolute', top: 16, right: 16 }}
            color='primary'
            onClick={this.onDownload}
          />
          <PrintIcon
            url={`/api/${this.context.instance.id}/documents/files/${this.props.id}`}
            style={{ position: 'absolute', top: 54, right: 16 }}
          />
        </PdfPreview>
      )
    } else {
      preview = (
        <OtherPreview id={this.props.id} name={this.meta.name} cache={this.file}>
          <RoundIcon
            icon='fas fa-download'
            tooltip='Herunterladen'
            style={{ position: 'absolute', top: 16, right: 16 }}
            color='primary'
            onClick={this.onDownload}
          />
        </OtherPreview>
      )
    }

    return (
      <div className='pt-14 bg-gray-100 min-h-full'>
        <div className='relative min-h-[280px] w-[840px] mx-auto my-6 pb-10 text-center flex flex-col'>
          {this.meta && (
            <div className='text-gray-400 text-sm mb-1 truncate text-left'>
              {this.meta.name}&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              {getDiskSizeLabel(this.meta.size)}
            </div>
          )}
          {!this.meta && (
            <div className='text-gray-400 text-sm mb-1 truncate text-left'>Lade...</div>
          )}
          {preview}
        </div>
      </div>
    )
  }
}
