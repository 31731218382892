import { QRCode } from 'components/QrCode'
import { MONTHS } from 'contracts/general/helpers/months'
import { IStundenzettelData } from 'contracts/workplan/interfaces/IStundenzettelData'
import { dayjs } from 'helpers/dayjs'
import * as React from 'react'

interface Props {
  data: IStundenzettelData
  pageNumber: number
  totalNumberOfPages: number
  instanceId: string
}

export class StundenzettelHeader extends React.Component<Props, {}> {
  render() {
    const data = this.props.data
    const month = dayjs(data.month, 'YYYY-MM')

    return (
      <div style={{ padding: '32px 32px 0 32px' }}>
        <div className='flex relative bg-white h-[84px] leading-[84px] text-white'>
          <div style={{ flex: '0 0 116px' }}>
            <QRCode
              qrCodeId={data.qrCodeId}
              pageNumber={this.props.pageNumber}
              totalNumberOfPages={this.props.totalNumberOfPages}
              instanceId={this.props.instanceId}
            />
          </div>
          <div className='flex-content pl-5 bg-gray-200 border-b border-t border-l border-black text-black font-bold'>
            Stundenzettel&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
            {`${MONTHS[month.month()]} ${month.year()}`}
            &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
          </div>
          <div className='pr-5 bg-gray-200 flex-auto truncate border-b border-t border-black text-black font-bold'>
            {data.employeeName}
          </div>
          <div
            style={{ flex: '0 0 90px', backgroundColor: '#333333' }}
            className='text-center'
          >
            {`${this.props.pageNumber} / ${this.props.totalNumberOfPages}`}
          </div>
        </div>
      </div>
    )
  }
}
