import { InputMultiSelectOption } from 'components/Form/components/InputMultiSelect'

export const lfgSchutzbeduerftigOptions: InputMultiSelectOption[] = [
  { id: 'Behinderung', label: 'Behinderung' },
  {
    id: 'Schwere körperliche/ psychische Erkrankung',
    label: 'Schwere körperliche/ psychische Erkrankung',
  },
  { id: 'Schwanger', label: 'Schwanger' },
  { id: 'Alleinerziehend', label: 'Alleinerziehend' },
  { id: 'Opfer von Menschenhandel', label: 'Opfer von Menschenhandel' },
  {
    id: 'Opfer von Folter/ psychischer-/ sexueller Gewalt',
    label: 'Opfer von Folter/ psychischer-/ sexueller Gewalt',
  },
  { id: '65+', label: '65+' },
  { id: 'LSBTTIQ', label: 'LSBTTIQ' },
]
