import * as React from 'react'
import { observer } from 'mobx-react'
import { ICompanyExportLohnart } from 'contracts/companies/interfaces/ICompanyExportLohnart'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { RoundIcon } from 'components/RoundIcon'
import { InputDropdownEntry } from 'components/Form/components/InputDropdownEntry'
import { hasCompanyExportLohnartScope } from 'contracts/companies/helpers/hasCompanyExportLohnartScope'

interface Props {
  lohnart: ICompanyExportLohnart
  onDelete: (id: string) => void
}

const types: InputSelectOption[] = [
  { value: '', label: 'Bitte wählen...' },
  { value: 'stundenlohn', label: 'Stundenlohn [Std]' },
  { value: 'bereitschaft', label: 'Bereitschaft [Std]' },
  { value: 'schichtzulage', label: 'Schichtzulage [Std]' },
  { value: 'urlaub', label: 'Urlaub [Tage]' },
  { value: 'urlaubsstunden', label: 'Urlaub [Std]' },
  { value: 'krank', label: 'Krank [Tage]' },
  { value: 'krankstunden', label: 'Krank [Std]' },
  { value: 'krankstunden schicht', label: 'Krank Schicht [Std]' },
  { value: 'krankstunden bereitschaft', label: 'Krank Bereitschaft [Std]' },
  { value: 'rufbereitschaft', label: 'Rufbereitschaft [Tage]' },
  { value: 'freistellung', label: 'Freistellung [Tage]' },
]

const scopes: InputSelectOption[] = [
  { value: 'alle', label: 'Alle Stunden' },
  { value: 'nacht alle', label: 'Alle Nachtstunden' }, // Alle Nachtstunden, auch am Sonn/Vor/Feiertag.
  { value: 'nacht', label: 'Nachtstunden' }, // Nur Nachtstunden, die nicht gleichzeitig Sonn/Vor/Feiertagsstunden sind.
  { value: 'sonntag', label: 'Sonntagsstunden' },
  { value: 'feiertag', label: 'Feiertagsstunden' },
  { value: 'vorfeiertag', label: 'Vorfeiertagsstunden' },
]

@observer
export class LohnartRow extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<ICompanyExportLohnart>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.lohnart)
  }

  private onDelete = () => this.props.onDelete(this.props.lohnart.id)

  private downloadSchichtzulagen = async () => {
    createAndDownloadReport('schichtenMitZulage', this.context.instance.id, {
      schichtzulageId: this.props.lohnart.schichtzulageId,
    })
  }

  render() {
    const lohnart = this.props.lohnart
    const showScope = hasCompanyExportLohnartScope(lohnart.type)

    return (
      <div>
        {/* Line 1 */}
        <div className='flex mb-4 gap-4'>
          <div className='flex-auto'>
            <InputSelect model={this.model} name='type' options={types} label='Typ' />
          </div>
          {showScope && (
            <div className='flex-[0_0_160px]'>
              <InputSelect
                model={this.model}
                name='scope'
                options={scopes}
                label='Berücksichtigte Zeiten'
              />
            </div>
          )}
          <div className='flex-[0_0_160px]'>
            <InputDecimal
              model={this.model}
              name='konto'
              label='Konto'
              precision={5}
              scale={0}
              noThousandsSeparators
              notNegative
            />
          </div>
          <div className='flex-[0_0_30px]'>
            <RoundIcon
              icon='fa fa-trash'
              onClick={this.onDelete}
              style={{ top: 4, right: 0 }}
            />
          </div>
        </div>

        {/* Line 2 */}
        <div className='flex mb-3 gap-4'>
          <div className='flex-auto'>
            <InputDropdownEntry
              model={this.model}
              name='lohnartId'
              type='personnelRecordContracts.Lohnart'
              label='Berücksichtigte Mitarbeiter'
              allowCreate
            />
          </div>
          {lohnart.type === 'schichtzulage' && (
            <div className='flex-[0_0_336px] relative'>
              <InputDropdownEntry
                model={this.model}
                name='schichtzulageId'
                type='schichtzulage'
                label='Berücksichtigte Schichten'
                allowCreate
              />
              {lohnart.schichtzulageId && (
                <span
                  onClick={this.downloadSchichtzulagen}
                  style={{ top: 8, right: 32 }}
                  className='absolute cursor-pointer inline-flex items-center rounded-full bg-indigo-500 p-1 text-sm font-medium text-white'
                >
                  <i className='fa fa-download' />
                </span>
              )}
            </div>
          )}
          <div className='flex-[0_0_30px]' />
        </div>

        {/* Spacer & border */}
        <div className='h-2 border-b border-gray-200 mb-5' />
      </div>
    )
  }
}
