import { hermes } from '@byll/hermes'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Model } from 'components/Form/Model'
import { IInvoiceBuildingGroup } from 'contracts/costCoverages/interfaces/IInvoiceBuildingGroup'
import { IResidentCostsFilterValidator } from 'contracts/costCoverages/interfaces/IResidentCostsFilterValidator'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  model: Model<IResidentCostsFilterValidator>
  month: string
  compoundId: string
}

export class CostCoverageBuildingGroupDropdown extends React.Component<
  Props,
  { options: InputSelectOption[] | null }
> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    this.state = { options: null }
  }

  componentDidMount() {
    void this.fetchOptions()
  }

  private fetchOptions = async () => {
    const response = await hermes.indexOnceNew<IInvoiceBuildingGroup>(
      `/api/${this.context.instance.id}/costCoverages/invoiceBuildingGroups?month=${this.props.month}&compoundId=${this.props.compoundId}`,
    )
    const options: InputSelectOption[] = response.map((o) => ({
      value: o.id,
      label: o.label,
    }))
    options.unshift({ value: null, label: 'Alle Gebäude' })
    this.setState({ options })
  }

  render() {
    if (!this.state.options || this.state.options.length === 1) {
      return null
    }
    return (
      <InputSelect
        className='flex-[1_1_150px]'
        label='Gebäude'
        model={this.props.model}
        name='buildingGroupId'
        options={this.state.options}
      />
    )
  }
}
