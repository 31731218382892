/**
 * Function needs to be called in same tick as click event
 * Otherwise popup blockers prevent it.
 */
export function download(url: string) {
  const win = window.open(url, '_blank')

  // Important: Alert user if browser prevented popup (e. g. all popups are blocked)
  // Otherwise it would look like the download button just didn't do anything.
  if (!win) {
    alert(
      `Ihr Browser hat den Download blockiert. Bitte erlauben Sie Popups in deinem Browser, um die Datei herunterzuladen.`,
    )
  }
  return
}
