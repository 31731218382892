import axios from 'axios'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  topicId: string
}

export const RerunSalarySheetCheck: React.FC<Props> = (props) => {
  const context = React.useContext(AppContext)
  const [loading, setLoading] = React.useState(false)

  async function runChecks() {
    try {
      setLoading(true)
      await axios.post(
        `/api/${context.instance.id}/employeeComm/topics/${props.topicId}/threads`,
        {},
      )
    } catch (e) {
      alert(
        'Die Gehaltszettel konnten nicht überprüft werden. Bitte wenden Sie sich an einen Administrator.',
      )
    } finally {
      setLoading(false)
    }
  }

  if (!loading) {
    return (
      <span key='none' className='ml-1 cursor-pointer' onClick={runChecks}>
        <i className='fas fa-cog' />
      </span>
    )
  }

  return (
    <span key='loading' className='ml-1 text-blue-500'>
      <i className='fas fa-cog fa-spin' />
    </span>
  )
}
