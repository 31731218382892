import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { encodeBarcode } from 'modules/Pdf/helpers/encodeBarcode'
import { getResidentSexDe } from 'contracts/residents/helpers/getResidentSexDe'

export class AnkerSchwaben extends React.Component<ResidentCardProps, {}> {
  render() {
    return (
      <div
        className={styles.page}
        style={this.props.batch ? {} : { pageBreakAfter: 'always' }}
      >
        <div className='flex'>
          <div style={{ position: 'absolute', top: 8, left: 8, width: 144 }}>
            <img
              src={getResidentImageSrc(
                this.props.instanceId,
                this.props.resident.imageId,
                this.props.resident.sex,
                'portrait',
              )}
              alt='Bewohnerbild'
            />
            <div
              style={{
                fontFamily: 'Barcode',
                fontSize: 40,
                marginTop: -11,
                paddingBottom: 0,
                transform: 'scaleY(-2.3)',
              }}
            >
              {encodeBarcode(this.props.resident.token || 'EXAMPLE')}
            </div>
          </div>
          <div>
            <div className='absolute pl-2' style={{ left: 152, width: '200px' }}>
              <div style={{ fontSize: 24, marginBottom: -2 }}>
                <b>Hausausweis</b>
              </div>
              <div className='truncate' style={{ fontSize: 10, lineHeight: '10px' }}>
                <b>
                  {`${this.props.resident.data.accommodation?.building?.residentCardText} ${this.props.resident.data.accommodation?.room?.label}` ||
                    ''}
                </b>
              </div>
              <div className='truncate' style={{ fontSize: 10 }}>
                <b>{this.props.resident.data.accommodation?.building?.street || ''}</b>
              </div>
            </div>

            <div
              className='absolute grid grid-cols-2 gap-2 overflow-hidden pl-2'
              style={{
                left: 152,
                top: 66,
                right: 8,
                fontSize: 12,
                lineHeight: '12px',
                gridTemplateColumns: '80px auto',
              }}
            >
              <div className='overflow-hidden'>
                <div className='truncate' style={{ fontSize: 10 }}>
                  {this.props.resident.lastName.toUpperCase()}&nbsp;
                </div>
                <div style={{ fontSize: 8 }}>Name</div>
              </div>
              <div>
                <div style={{ fontSize: 10 }}>{toJbpId(+this.props.resident.id)}</div>
                <div style={{ fontSize: 8 }}>ID</div>
              </div>
              <div>
                <div className='truncate' style={{ fontSize: 10 }}>
                  {this.props.resident.firstName}&nbsp;
                </div>
                <div style={{ fontSize: 8 }}>Vorname</div>
              </div>
              <div>
                <div style={{ fontSize: 10 }}>
                  {getResidentSexDe(this.props.resident.sex)}&nbsp;
                </div>
                <div style={{ fontSize: 8 }}>Geschlecht</div>
              </div>
              <div>
                <div style={{ fontSize: 10 }}>
                  {this.props.resident.dateOfBirth
                    ? dayjs(this.props.resident.dateOfBirth).format('DD.MM.YYYY')
                    : ' -'}
                  &nbsp;
                </div>
                <div style={{ fontSize: 8 }}>Geburtsdatum</div>
              </div>
              <div className='truncate'>
                <div className='truncate' style={{ fontSize: 10 }}>
                  {this.props.resident.data.countryOfBirth || ' -'}&nbsp;
                </div>
                <div style={{ fontSize: 8 }}>Herkunftsland</div>
              </div>
            </div>

            <div
              className='absolute border-black p-1 overflow-hidden'
              style={{
                right: 8,
                bottom: 8,
                borderWidth: 1.5,
                height: 55,
                width: 140,
                fontSize: 8,
              }}
            >
              {this.props.resident.data.comment}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
