import { Collection, hermes, Resource } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { toast } from 'react-toastify'
import { box } from 'services/box'
import { ILogCreate } from 'contracts/inventory/interfaces/ILogCreate'
import { IInventoryItemScan } from 'contracts/inventory/interfaces/IInventoryItemScan'
import { IInventoryLogMetadata } from 'contracts/inventory/interfaces/IInventoryLogMetadata'
import { action, makeObservable, observable } from 'mobx'
import { ResidentInventoryScanBundle } from './components/ResidentInventoryScanBundle'
import { ResidentInventoryScanItem } from './components/ResidentInventoryScanItem'
import { IInventoryLog } from 'contracts/inventory/interfaces/IInventoryLog'
import { InventoryBundleDialog } from 'modules/InventoryScan/components/InventoryBundleDialog'
import { Dialog } from 'components/Dialog'

interface Props {
  resident: IResident
  compoundId: string | null
}

@observer
export class ResidentInventoryScan extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly logs: Collection<IInventoryItemScan, IInventoryLogMetadata>
  private readonly items: Collection<IInventoryItem>
  private readonly bundles: Collection<IInventoryBundle>
  private readonly disposers: Disposer[] = []
  @observable private showBundleDialog: IInventoryBundle | null = null // null if closed, selected bundle if opened

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.logs = new Collection(`/api/${context.instance.id}/inventory/logs`, {
      residentId: props.resident.id,
      compoundId: props.compoundId,
    })
    this.items = new Collection(`/api/${context.instance.id}/inventory/items`, {
      compoundId: props.compoundId,
      page: '0,1000',
    })
    this.bundles = new Collection(`/api/${context.instance.id}/inventory/bundles`, {
      compoundId: props.compoundId,
    })
  }

  componentDidMount() {
    this.disposers.push(this.logs.init({ readOnly: true }))
    this.disposers.push(this.items.init())
    this.disposers.push(this.bundles.init())
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private addItem = async (item: IInventoryItem, enforce = false) => {
    if (!this.props.compoundId) {
      box.alert(
        'Ausgabe nicht möglich',
        'Bitte stellen Sie vor der Inventarausgabe sicher, dass der Bewohner in einer Unterkunft eingecheckt ist.',
      )
      return
    }
    try {
      const data: ILogCreate = {
        residentId: this.props.resident.id,
        compoundId: this.props.compoundId,
        items: [{ id: item.id, count: item.issueCount || '1' }],
        enforce,
      }
      await hermes.create(`/api/${this.context.instance.id}/inventory/logs`, data)
      toast.success(`${item.label} wurde ausgegeben.`)
    } catch (e: any) {
      if (e.id === ConflictError.id && e.details?.canEnforce) {
        if (
          await box.alert(
            'Ausgabe fehlgeschlagen',
            e.message + ' Möchten Sie den Gegenstand trotzdem ausgeben?',
            { confirm: 'Ja', cancel: 'Nein' },
          )
        ) {
          this.addItem(item, true)
        }
      } else {
        await box.alert(
          'Ausgabe fehlgeschlagen',
          e.id === ConflictError.id
            ? e.message
            : 'Der Gegenstand konnte nicht ausgegeben werden. Bitte wenden Sie sich an einen Administrator.',
          { color: 'danger' },
        )
      }
    }
  }

  private addBundleItems = async (
    bundle: IInventoryBundle,
    enforce = false,
  ): Promise<IInventoryLog[] | null> => {
    if (!this.props.compoundId) {
      box.alert(
        'Ausgabe nicht möglich',
        'Bitte stellen Sie vor der Inventarausgabe sicher, dass der Bewohner in einer Unterkunft eingecheckt ist.',
      )
      return null
    }
    try {
      const data: ILogCreate = {
        residentId: this.props.resident.id,
        compoundId: this.props.compoundId,
        items:
          this.items.resources
            ?.filter((i) => i.data?.bundleIds.includes(bundle.id) && i.data.isDispensable)
            .map((i) => ({ id: i.id, count: i.data?.issueCount || '1' })) ?? [],
        enforce,
      }
      if (data.items.length === 0) {
        box.alert('Leeres Paket', 'Fügen Sie dem Paket Gegenstände hinzu.')
        return null
      }
      const response = await hermes.create(
        `/api/${this.context.instance.id}/inventory/logs`,
        data,
      )
      toast.success(`${bundle.label} wurde ausgegeben.`)
      return response.logs
    } catch (e: any) {
      if (e.id === ConflictError.id && e.details?.canEnforce) {
        if (
          await box.alert(
            'Ausgabe fehlgeschlagen',
            e.message + ' Möchten Sie das Paket trotzdem ausgeben?',
            { confirm: 'Ja', cancel: 'Nein' },
          )
        ) {
          return this.addBundleItems(bundle, true)
        }
      } else {
        await box.alert(
          'Ausgabe fehlgeschlagen',
          e.id === ConflictError.id
            ? `${e.message} Es werden entweder alle Gegenstände des Paketes ausgegeben oder keiner. Deshalb wurde das Paket als Ganzes nicht ausgegeben.`
            : 'Das Paket konnte nicht ausgegeben werden. Bitte wenden Sie sich an einen Administrator.',
          { color: 'danger' },
        )
      }
      return null
    }
  }

  // This is an undo functionality. It removes bundle items that have just been issued (by log ids)
  private removeBundleItems = async (logs: IInventoryLog[]) => {
    const promises: any[] = []
    for (const log of logs) {
      promises.push(
        hermes.delete(`/api/${this.context.instance.id}/inventory/logs/${log.id}`),
      )
    }

    try {
      await Promise.all(promises)
    } catch (_e) {
      await box.alert(
        'Rückgängig fehlgeschlagen',
        'Die Paketausgabe konnte nicht rückgängig gemacht werden.',
        { color: 'danger' },
      )
    }
  }

  private bundleMapper = (b: Resource<IInventoryBundle>) => {
    const bundle = b.data
    if (!bundle) {
      return null
    }
    return (
      <ResidentInventoryScanBundle
        key={bundle.id}
        bundle={bundle}
        onClick={this.addBundleItems}
        onUndo={this.removeBundleItems}
        openBundle={this.openBundleDialog}
        check={!!this.logs.metadata?.issuedBundles[bundle.id]}
      />
    )
  }

  private itemMapper = (i: Resource<IInventoryItem>) => {
    const item = i.data
    if (!item) {
      return null
    }
    if (!item.isDispensable) {
      return null
    }
    return (
      <ResidentInventoryScanItem
        key={item.id}
        item={item}
        instance={this.context.instance}
        onClick={this.addItem}
      />
    )
  }

  @action
  private openBundleDialog = (bundle: IInventoryBundle) =>
    (this.showBundleDialog = bundle)

  @action
  private closeBundleDialog = () => (this.showBundleDialog = null)

  render() {
    return (
      <div
        className={`bg-gray-100 rounded p-6 max-h-[500px] grid shadow-md ${
          this.context.permissions.host_lfgb ? 'grid-cols-1' : 'grid-cols-2'
        }`}
      >
        <div>
          <h3 className='mb-3'>Pakete</h3>
          <div className='border-r border-gray-300 pb-3 pr-3 max-h-[435px] overflow-y-scroll'>
            <div className='overflow-hidden shadow ring-1 ring-black bg-white ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300 mt-3'>
                <thead>
                  <tr>
                    <th scope='col' className='hidden md:block'></th>
                    <th
                      scope='col'
                      className='pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-2'
                    >
                      Bezeichnung
                    </th>
                  </tr>
                </thead>
                <tbody>{this.bundles.resources?.map(this.bundleMapper)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {!this.context.permissions.host_lfgb && (
          <div>
            <h3 className='mb-3'>Gegenstände</h3>
            <div className='pl-3 pb-3 max-h-[435px] overflow-y-scroll'>
              <div className='overflow-hidden shadow-md ring-1 ring-black bg-white ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300 mt-3 shadow-md'>
                  <thead>
                    <tr>
                      <th scope='col' className='hidden md:block'></th>
                      <th
                        scope='col'
                        className='pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 md:pl-0'
                      >
                        Bezeichnung
                      </th>
                      <th
                        scope='col'
                        className='hidden pb-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell'
                      >
                        Anzahl
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.items.resources?.map(this.itemMapper)}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {/* Bundle details */}
        {this.showBundleDialog && (
          <Dialog size='xl' open setOpen={this.closeBundleDialog}>
            <InventoryBundleDialog
              items={this.items}
              bundle={this.showBundleDialog}
              onClose={this.closeBundleDialog}
            />
          </Dialog>
        )}
      </div>
    )
  }
}
