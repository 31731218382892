import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { AppContext } from 'services/connection/models/AppContext'
import { Message } from 'components/Message'

const options = [
  {
    id: 'Unerlaubter Auszug',
    name: 'Unerlaubter Auszug',
    description: 'Aufenthalt wurde beendet.',
  },
  {
    id: 'Erlaubter Auszug',
    name: 'Erlaubter Auszug',
    description: 'Aufenthalt wurde beendet.',
  },
  {
    id: 'Ausgecheckt mit Transfer',
    name: 'Ausgecheckt mit Transfer',
    description: 'Kein Aufenthaltsende, aber ein geplanter Transfer.',
  },
  {
    id: 'Ausgecheckt ohne Infos',
    name: 'Ausgecheckt ohne Infos',
    description: 'Kein Aufenthaltsende oder Transfer, nur Checkout.',
  },
]

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

@observer
export class SelectEndOfStay extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private onSelect = (e) => {
    this.props.model.values.endOfStay = e.target.id
  }

  @action private reset = () => {
    this.props.model.values.endOfStay = null
  }

  render() {
    const hasCompound =
      this.props.model.values.compoundId && this.props.model.values.compoundId !== 'none'
    const hasSelection = !!this.props.model.values.endOfStay && hasCompound
    let label = hasSelection ? this.props.model.values.endOfStay : 'Aufenthaltsende'
    let color: string = hasSelection ? 'text-blue-500' : 'text-gray-500'

    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60 cursor-default'>
            <div className='flex flex-col gap-4'>
              {!hasCompound && (
                <Message color='danger'>
                  Bitte wählen Sie im Belegungsfilter ein Gelände aus.
                </Message>
              )}

              {hasCompound && (
                <>
                  <fieldset aria-label='Plan'>
                    <div className='space-y-5'>
                      {options.map((option) => (
                        <div key={option.id} className='relative flex items-start'>
                          <div className='flex h-6 items-center'>
                            <input
                              onChange={this.onSelect}
                              id={option.id}
                              checked={this.props.model.values.endOfStay === option.id}
                              name='endOfStay'
                              type='radio'
                              aria-describedby={`${option.id}-description`}
                              className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer'
                            />
                          </div>
                          <div className='ml-3 text-sm leading-6'>
                            <label
                              htmlFor={option.id}
                              className='font-medium text-gray-900 cursor-pointer'
                            >
                              {option.name}
                            </label>
                            <p
                              id={`${option.id}-description`}
                              className='text-gray-500 text-xs'
                            >
                              {option.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </fieldset>

                  <Menu.Item>
                    <div className='col-span-2 flex'>
                      <Button
                        color='secondary'
                        outline
                        className='flex-auto'
                        onClick={this.reset}
                        style={{ borderRadius: '6px 0 0 6px' }}
                      >
                        Zurücksetzen
                      </Button>
                      <Button
                        color='primary'
                        className='flex-content'
                        style={{ borderRadius: '0 6px 6px 0' }}
                      >
                        OK
                      </Button>
                    </div>
                  </Menu.Item>
                </>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
