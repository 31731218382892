import * as React from 'react'
import { XCircleIcon, CheckIcon, InformationCircleIcon } from '@heroicons/react/outline'

interface Props {
  color: 'primary' | 'success' | 'danger' | 'warning'
  className?: string
}

const colors = {
  primary: {
    background: 'bg-indigo-50',
    icon: (
      <InformationCircleIcon className='h-5 w-5 text-indigo-400' aria-hidden='true' />
    ),
  },
  success: {
    background: 'bg-green-50 border border-green-500',
    icon: <CheckIcon className='h-5 w-5 text-green-400' aria-hidden='true' />,
  },
  danger: {
    background: 'bg-red-50',
    icon: <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />,
  },
  warning: {
    background: 'bg-yellow-50 border border-yellow-400',
    icon: <XCircleIcon className='h-5 w-5 text-yellow-400' aria-hidden='true' />,
  },
}

export const Message: React.FC<Props> = (props) => (
  <div
    className={`rounded-md p-4 ${colors[props.color].background} ${
      props.className || ''
    }`}
  >
    <div className='flex'>
      <div className='flex-shrink-0'>{colors[props.color].icon}</div>
      <div className='ml-3 text-sm'>{props.children}</div>
    </div>
  </div>
)
