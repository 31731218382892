import { Button } from 'components/Form/components/Button'
import * as React from 'react'

interface Props {
  title: string
  subtitle: string
  saveButton?: string
  cancelButton?: string
  onCancel?: () => void
  onSave?: () => void
  className?: string
  minHeight?: number
}

export const CardColumnCard: React.FC<Props> = (props) => {
  return (
    <div
      className={
        'bg-white rounded-md shadow-md p-6 mb-6 flex flex-content last:flex-auto ' +
        (props.className || '')
      }
    >
      <div className='hidden xl:block pr-12 pt-4 text-right flex-[0_0_200px]'>
        <span className='text-gray-900 text-lg'>{props.title}</span>
        <br />
        <span className='text-sm text-gray-400'>{props.subtitle}</span>
      </div>

      <div
        className='flex-auto pt-6 relative'
        style={{ minHeight: props.minHeight || 180 }}
      >
        {props.children}
        {!!props.saveButton && (
          <div className='text-right py-5 mt-5 sticky bottom-0 bg-white border-t border-gray-300 -mx-6 px-6 xl:-ml-[224px]'>
            {!!props.cancelButton && (
              <Button onClick={props.onCancel} color='secondary' outline className='mr-3'>
                {props.cancelButton}
              </Button>
            )}
            <Button onClick={props.onSave} color='primary'>
              {props.saveButton}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
