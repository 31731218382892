import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { ITransferTarget } from 'contracts/transfer/interfaces/ITransferTarget'
import { TransferTargetValidator } from 'contracts/transfer/validators/TransferTargetValidator'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  target: any
  onClose: (id?: string) => void
}

export class TransferTargetDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<ITransferTarget> = new Model(
    { ...this.props.target },
    TransferTargetValidator.omit({ id: true, instanceId: true }),
  )

  private save = async () => {
    try {
      if (!this.model.isValid()) {
        this.model.setFocusToLeftTopmostInvalidField()
        return
      }
      const data = {
        label: this.model.values.label,
        street: this.model.values.street,
        zip: this.model.values.zip,
        city: this.model.values.city,
        federalState: this.model.values.federalState,
        contactPerson: this.model.values.contactPerson,
        comment: this.model.values.comment,
      }
      let res: { id: string } = { id: '' }
      if (this.props.target.id) {
        res = await hermes.patch(
          `/api/${this.context.instance.id}/transfer/targets/${this.props.target.id}`,
          data,
        )
      } else {
        res = await hermes.create(
          `/api/${this.context.instance.id}/transfer/targets`,
          data,
        )
      }
      this.props.onClose(res.id)
    } catch (_e) {
      box.alert(
        'Fehler',
        'Beim Erstellen des Ziels ist ein Fehler aufgetreten. Probieren Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
      )
    }
  }

  render() {
    return (
      <>
        <div>{this.props.target.id ? 'Ziel bearbeiten' : 'Ziel anlegen'}</div>
        <div id={this.model.id} className='my-4'>
          <div className='grid grid-cols-2 gap-4 mt-4'>
            <InputText
              className='col-span-2'
              label='Bezeichnung'
              model={this.model}
              name='label'
            />
            <InputText label='Straße' model={this.model} name='street' />
            <InputText label='Bundesland' model={this.model} name='federalState' />
            <InputText label='PLZ' model={this.model} name='zip' />
            <InputText label='Ort' model={this.model} name='city' />
            <InputText
              className='col-span-2'
              label='Kontaktperson'
              model={this.model}
              name='contactPerson'
            />
            <InputTextarea
              className='col-span-2'
              label='Notizen'
              model={this.model}
              name='comment'
              rows={4}
            />
          </div>
        </div>
        <div className='text-right col-span-2'>
          <Button color='secondary' outline onClick={() => this.props.onClose()}>
            {'Abbrechen'}
          </Button>
          <Button color='primary' className='ml-2' onClick={this.save}>
            {this.props.target.id ? 'Speichern' : 'Ziel erstellen'}
          </Button>
        </div>
      </>
    )
  }
}
