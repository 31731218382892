import { IResident } from 'contracts/residents/interfaces/IResident'
import { getAge } from 'helpers/getAge'

export function getResidentScope(resident: IResident): 'child' | 'woman' | 'man' | null {
  const age = getAge(resident.dateOfBirth)
  if (age === null) {
    return null
  }
  if (age <= 12) {
    return 'child'
  }
  if (resident.sex === 'female') {
    return 'woman'
  }
  if (resident.sex === 'male') {
    return 'man'
  }
  return null
}
