import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Message } from 'components/Message'
import { Button } from 'components/Form/components/Button'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { download } from 'helpers/download'
import axios from 'axios'
import { CircularProgressbar } from 'components/CircularProgressbar'
import { ICostCoverageMeta } from 'contracts/costCoverages/interfaces/ICostCoverageMeta'
import { ConflictError } from 'contracts/errors/HermesErrors'

interface Props {
  coverages: ICostCoverageMeta[]
  onClose: (val?: any) => void
  compoundId: string
  buildingGroupId: string | null
  month: string
  type: 'concatenateCoverages' | 'concatenateAll'
}
@observer
export class CreateBatchCostCoverageDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private step: 'create draft invoices' | 'concatenate' = 'concatenate'
  @observable private progress: number | null = 0
  @observable private token: string | null = null
  @observable private error: JSX.Element | string | null = null
  private timer
  private mounted = true
  private readonly endpoint: string
  private drafts = new Map<string, string>() // costCoverageId => uuid of draft file

  constructor(props: Props, context: AppContextProps) {
    super(props)
    if (props.type === 'concatenateCoverages') {
      this.endpoint = `/api/${context.instance.id}/costCoverages/concatenateCoverages`
    } else {
      this.endpoint = `/api/${context.instance.id}/costCoverages/concatenateAll`
    }
    if (props.type === 'concatenateAll') {
      // Detect unfinished invoices
      for (const coverage of props.coverages) {
        if (!coverage.invoice) {
          this.drafts.set(coverage.id, '')
        }
      }
      if (this.drafts.size > 0) {
        this.step = 'create draft invoices'
      }
    }
    makeObservable(this)
  }

  componentDidMount() {
    if (this.step === 'create draft invoices') {
      void this.createDraftInvoices()
    } else {
      void this.concat()
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    this.mounted = false
  }

  private createDraftInvoices = async () => {
    try {
      let i = 0
      runInAction(() => (this.progress = 0.1))
      for (const [costCoverageId, uuid] of this.drafts) {
        if (!this.mounted) {
          return
        }
        if (uuid) {
          continue
        }
        const response: any = await axios.post(
          `/api/${this.context.instance.id}/costCoverages/invoiceDraft`,
          {
            costCoverageId,
            buildingGroupId: this.props.buildingGroupId,
            month: this.props.month,
          },
        )
        this.drafts.set(costCoverageId, response.data.id)
        i++
        // eslint-disable-next-line
        runInAction(() => (this.progress = 0.1 + (i / this.drafts.size) * 0.9))
      }
      runInAction(() => {
        this.progress = 0
        this.step = 'concatenate'
      })
      void this.concat()
    } catch (e: any) {
      runInAction(() => {
        this.error =
          e?.response?.data?.id === ConflictError.id
            ? e.response.data.message
            : 'Das Dokument konnte leider nicht erstellt werden.'
        this.progress = null
      })
    }
  }

  private concat = async () => {
    try {
      this.timer = setInterval(
        action(() => {
          this.progress = (this.progress || 0) + 0.05
          if (this.progress > 1) {
            this.progress = 1
          }
        }),
        200,
      )
      let data: any = {
        costCoverageIds: this.props.coverages.map((c) => c.id),
        month: this.props.month,
        compoundId: this.props.compoundId,
      }
      if (this.props.type === 'concatenateAll') {
        data = {
          costCoverages: this.props.coverages.map((c) => ({
            id: c.id,
            invoice: this.drafts.has(c.id)
              ? { id: this.drafts.get(c.id)!, status: 'draft' }
              : undefined,
          })),
          month: this.props.month,
          compoundId: this.props.compoundId,
          buildingGroupId: this.props.buildingGroupId,
        }
      }
      const response: any = await axios.post(this.endpoint, data)
      const token = response?.data.id
      if (typeof token !== 'string') {
        throw new Error('Invalid return value')
      }
      clearInterval(this.timer)
      runInAction(() => {
        this.token = token
        this.progress = null
      })
    } catch (e: any) {
      clearInterval(this.timer)
      runInAction(() => {
        if (
          e?.response?.data?.id === ConflictError.id &&
          Array.isArray(e.response.data.details?.invalid)
        ) {
          this.error = (
            <div>
              <div className='mb-2'>{e.response.data.message}</div>
              {e.response.data.details.invalid.map((f) => (
                <div key={f.id} className='leading-6'>
                  <a
                    className='underline text-blue-500'
                    href={f.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {f.description}
                  </a>
                </div>
              ))}
            </div>
          )
        } else {
          this.error =
            e?.response?.data?.id === ConflictError.id
              ? e.response.data.message
              : 'Das Dokument konnte leider nicht erstellt werden.'
        }
        this.progress = null
      })
    }
  }

  private download = () => {
    if (!this.token) {
      return
    }
    download(`${this.endpoint}/${this.token}`)
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Sammeldokument erstellen
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        {!this.error && this.step === 'concatenate' && (
          <div className='relative mt-4 h-[200px]'>
            {this.progress !== null && (
              <div className='px-40 pt-6'>
                <CircularProgressbar
                  percentage={this.progress === 1 ? undefined : this.progress}
                  strokeWidth={4}
                />
              </div>
            )}
            {this.progress === null && (
              <Message color='primary'>
                Das Sammeldokument wurde erstellt. Klicken Sie auf "Download", um es
                herunterzuladen.
              </Message>
            )}
          </div>
        )}

        {!this.error &&
          this.step === 'create draft invoices' &&
          this.progress !== null && (
            <div className='relative mt-4 h-[200px]'>
              <div className='relative text-center text-indigo-500'>
                <i
                  className='fas fa-drafting-compass'
                  style={{ fontSize: '100px' }}
                  aria-hidden='true'
                />
                <p className={`mt-4 mb-4 font-bold`}>Erzeuge Rechnungsentwürfe...</p>
              </div>
              <div className='border border-gray-300 rounded-md h-10 overflow-hidden'>
                <div
                  className={`bg-green-500 text-white text-sm overflow-hidden whitespace-nowrap transition-width duration-500 ease-in-out ${
                    this.progress > 0 ? 'p-2' : ''
                  }`}
                  style={{ width: `${this.progress * 100}%`, height: '100%' }}
                >
                  {Math.round(this.progress * 100)} %
                </div>
              </div>
            </div>
          )}

        <div className='mt-4 text-center'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            {this.progress !== null ? 'Abbrechen' : 'Schließen'}
          </Button>
          {this.progress === null && this.token && (
            <Button color='success' className='ml-3' onClick={this.download}>
              Download <i className='fas fa-download' />
            </Button>
          )}
        </div>
      </>
    )
  }
}
