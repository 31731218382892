import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import * as React from 'react'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Collection, hermes } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { Model } from 'components/Form/Model'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { Spinner } from 'components/Spinner'
import { InputScanningStation } from 'components/Form/components/InputScanningStation'
import { InputText } from 'components/Form/components/InputText'
import { DownloadDropdown } from './components/DownloadDropdown'
import { box } from 'services/box'
import { ScanningStationDialog } from 'components/Form/components/InputScanningStation/components/ScanningStationDialog'
import { Callout } from 'components/Callout'
import { hasWaiters } from 'modules/Pdf/helpers/hasWaiters'
import { IVisitScanResult } from 'contracts/scan/interfaces/IVisitScanResult'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { preselectLastUsedScanningStation } from './helpers/preselectLastUsedScanningStation'
import { saveLastUsedScanningStation } from './helpers/saveLastUsedScanningStation'
import { IVisitScanError, ScanError } from './components/ScanError'
import { VisitScanSuccessTile } from './components/VisitScanSuccessTile'
import { LastScans } from './components/LastScans'
import { VisitActionDialog } from './components/VisitActionDialog'

const SCAN_INPUT_ID = 'SCAN_INPUT_ID'

interface IVisitScanOther {
  type: 'loading' | 'initial'
}

interface Props {}

@observer
export class Visits extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model = new Model<{ scanningStationId: string | null; token: string }>(
    { scanningStationId: null, token: '' },
  )
  private readonly stations: Collection<IScanningStation>
  private readonly disposers: Disposer[] = []
  @observable private scans: IVisitScanResult[] = []
  @observable.ref private currentScan:
    | IVisitScanResult
    | IVisitScanError
    | IVisitScanOther = { type: 'initial' }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.stations = new Collection(`/api/${context.instance.id}/scan/scanningStations`, {
      type: 'gate',
    })
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(this.stations.init({ readOnly: true }))

    // Preselect last used scanning station
    this.disposers.push(
      preselectLastUsedScanningStation(
        this.model,
        this.stations,
        this.context.user,
        this.context.permissions,
      ),
    )

    // Save last used scanning station
    this.disposers.push(saveLastUsedScanningStation(this.model, this.context.user))

    this.disposers.push(
      reaction(
        () => this.model.values.scanningStationId,
        () => (this.currentScan = { type: 'initial' }),
      ),
    )

    // Focus scan input
    document.addEventListener('keydown', this.onKeyDown)
    this.disposers.push(() => document.removeEventListener('keydown', this.onKeyDown))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private editStation = () => {
    const station = this.model.values.scanningStationId
      ? hermes.getFromStore<IScanningStation>(
          `/api/${this.context.instance.id}/scan/scanningStations/${this.model.values.scanningStationId}`,
          false,
        )
      : null
    if (station) {
      const promise = box.custom(
        <ScanningStationDialog
          onClose={(id) => promise.close(id)}
          type='gate'
          station={station}
        />,
        { context: this.context, closable: true },
      )
    }
  }

  private onKeyDown = (e) => {
    if (hasWaiters()) {
      return
    } // This line skips the focus if a dialog is open (scan dialogs render .wait class as a signal for this function)
    if (!/^[0-9a-zA-Z]$/.test(e.key)) {
      return
    } // Only focus on alphanumeric keys
    const input: HTMLInputElement = document.getElementById(SCAN_INPUT_ID) as any
    if (input) {
      input.focus()
    }
  }

  private onScan = async (e) => {
    if (e.keyCode !== 13) {
      return
    }
    const token = this.model.values.token.trim()
    if (!token) {
      return
    }
    try {
      runInAction(() => {
        this.currentScan = { type: 'loading' }
        this.model.values.token = ''
      })
      let result = await hermes.create(`/api/${this.context.instance.id}/scan/visits`, {
        scanningStationId: this.model.values.scanningStationId,
        token: token,
        force: false, // Do not force check-in/check-out if there are warnings or gateMessages (this is only done in user interaction dialog if user explicitly decides to ignore the warnings)
      })

      if (result.id === null) {
        const promise = box.custom(
          <VisitActionDialog scan={result} onClose={(accept) => promise.close(accept)} />,
          { context: this.context, closable: false },
        )
        if (await promise) {
          result = await hermes.create(`/api/${this.context.instance.id}/scan/visits`, {
            scanningStationId: this.model.values.scanningStationId,
            token: token,
            force: true, // force check-in/check-out because user accepted warnings (that were displayed in VisitActionDialog)
          })
        }
      }

      if (result.id !== null) {
        runInAction(() => {
          this.currentScan = result
          this.scans.unshift(result)
          if (this.scans.length > 60) {
            // Ensure that list doesn't get too long
            this.scans = this.scans.slice(0, 40)
          }
        })
      } else {
        runInAction(() => (this.currentScan = { type: 'initial' }))
      }
    } catch (e: any) {
      runInAction(() => {
        this.currentScan = {
          type: 'error',
          message: e.id === ConflictError.id ? e.message : 'Scanfehler',
          icon:
            (e.id === ConflictError.id ? e.details.icon : '') ||
            'fas fa-exclamation-triangle',
        }
      })
    }
  }

  render() {
    if (this.context.permissions.resident_barcodeVisitTrackings === 0) {
      return <Forbidden />
    }

    if (this.stations.error?.id === RequestPendingError.id) {
      return (
        <div className='absolute top-14 left-0 right-0 bottom-0'>
          <Spinner delay />
        </div>
      )
    }

    if (this.stations.error || !this.stations.resources) {
      return (
        <div className='absolute top-14 left-0 right-0 bottom-0 flex flex-col'>
          <LoadingError title='Beim Laden ist ein Fehler aufgetreten' />
        </div>
      )
    }

    let station = this.model.values.scanningStationId
      ? hermes.getFromStore<IScanningStation>(
          `/api/${this.context.instance.id}/scan/scanningStations/${this.model.values.scanningStationId}`,
          false,
        )
      : null
    if (station?.deletedAt) {
      station = null
    }

    return (
      <div className='flex min-h-full px-6 pb-6 pt-20 bg-gray-100 gap-6'>
        <div className='flex-auto flex flex-col gap-6'>
          {/* Control bar */}
          <div className='bg-white rounded-md shadow-md p-4 flex gap-4'>
            <div className='bg-indigo-500 text-white rounded-md px-3 leading-[38px] text-sm flex-content'>
              Ein- und Ausgang
            </div>
            <div className='flex-[0_1_260px] relative'>
              <InputScanningStation
                label='Pforte'
                type='gate'
                model={this.model}
                name='scanningStationId'
                allowCreate={
                  this.context.permissions.resident_barcodeVisitTracking_newGate
                }
                onlyStamm={this.context.permissions.resident_barcodeVisitTrackings === 1}
              />
              {station &&
                this.context.permissions.resident_barcodeVisitTracking_newGate && (
                  <span
                    onClick={this.editStation}
                    className='absolute rounded-full bg-gray-100 hover:bg-white cursor-pointer text-center h-6 w-6 top-[7px] right-9 text-indigo-500'
                  >
                    <i className='fas fa-pencil-alt' />
                  </span>
                )}
            </div>
            {station && (
              <InputText
                id={SCAN_INPUT_ID}
                label='Erfassung'
                model={this.model}
                name='token'
                className='flex-[0_1_260px] mr-auto'
                placeholder='Ausweis scannen...'
                onKeyUp={this.onScan}
              />
            )}
            {station && <DownloadDropdown compoundId={station.compoundId} />}
          </div>

          {/* Gate not yet selected */}
          {!station && (
            <div className='bg-white rounded-md shadow-md p-4 flex-auto flex flex-col'>
              <Callout
                icon='fas fa-torii-gate'
                title='Ein- und Ausgang'
                subtitle='Bitte wählen Sie eine Pforte'
              />
            </div>
          )}

          {/* No scans yet */}
          {station && this.currentScan.type === 'initial' && (
            <div className='bg-white rounded-md shadow-md p-4 flex-auto flex flex-col'>
              <Callout
                icon='fas fa-address-card'
                title='Warte auf nächsten Scan...'
                subtitle='Ein- und Ausgang'
              />
            </div>
          )}

          {/* Scan error */}
          {station && this.currentScan.type === 'error' && (
            <ScanError error={this.currentScan} />
          )}

          {/* Scan in progress */}
          {station && this.currentScan.type === 'loading' && (
            <div className='bg-white rounded-md shadow-md p-4 flex-auto flex flex-col relative'>
              <Spinner delay />
            </div>
          )}

          {/* Show current scan */}
          {station &&
            (this.currentScan.type === 'in' || this.currentScan.type === 'out') && (
              <VisitScanSuccessTile scan={this.currentScan} station={station} />
            )}
        </div>

        {/* List of last scans */}
        {station && (
          <div className='hidden md:block bg-white rounded-md shadow-md flex-[0_0_360px] relative'>
            <LastScans key={station.id} scans={this.scans} station={station} />
          </div>
        )}
      </div>
    )
  }
}
