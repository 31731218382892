import { Collection } from '@byll/hermes'
import { IResidentLetterEntry } from 'contracts/residents/interfaces/IResidentLetterEntry'
import { IResidentLettersFilter } from 'contracts/residents/interfaces/IResidentLettersFilter'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  entries: Collection<IResidentLetterEntry, { sort: string }, IResidentLettersFilter>
  orderBy: 'receiver.name' | 'room.label'
}

@observer
export class SearchCaption extends React.Component<Props, {}> {
  private sort = action(() => {
    if (this.props.entries.query.sort === this.props.orderBy) {
      this.props.entries.query.sort = null
    } else {
      this.props.entries.query.sort = this.props.orderBy
    }
    // Entries that have been added after mountedAt will appear at the
    // bottom to ensure that new entries are always visible next to the
    // "add entry" textbox right after adding.
    // If user decides to sort the entries, it is expected behavior that
    // all entries are sorted. Therefore mountedAt is pushed to 'now'.
    this.props.entries.query.mountedAt = new Date().toISOString()
  })

  render() {
    let icon: string = 'fas fa-sort'
    if (
      this.props.orderBy === this.props.entries.metadata?.sort &&
      this.props.orderBy === this.props.entries.query.sort
    ) {
      icon = 'fas fa-caret-up'
    } else if (this.props.orderBy === this.props.entries.query.sort) {
      icon = 'fas fa-sync fa-spin'
    }

    return (
      <span
        className={`${this.props.orderBy ? 'cursor-pointer truncate' : 'truncate'}`}
        onClick={this.sort}
      >
        {this.props.children}
        {icon && (
          <span className='ml-2' key={icon}>
            <i className={icon} />
          </span>
        )}
      </span>
    )
  }
}
