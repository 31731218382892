import { z } from 'zod'
import { isDecimalString } from '../../general/helpers/isDecimalString'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isMonth } from '../../general/helpers/isMonth'

export const CostCoverageInvoicePositionValidator = z.object({
  id: z.string().refine(isIntegerString),
  costCoverageId: z.string().refine(isIntegerString),
  month: z.string().refine(isMonth),
  buildingGroupId: z.union([z.string().refine(isIntegerString), z.null()]),
  label: z.string().min(1),
  amount: z.string().refine((v) => isDecimalString(v, 8, 2)),
})
