import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from 'components/Form/Model'
import { InputImage } from 'components/Form/components/InputImage'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { AppContext } from 'services/connection/models/AppContext'
import { CornerBadge } from 'modules/Pdf/components/CornerBadge'

interface Props {
  resident: IResident
}

@observer
export class MemberImage extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IResident>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.resident)
  }

  render() {
    const resident = this.props.resident
    const portrait = getResidentImageSrc(
      this.context.instance.id,
      resident.imageId,
      resident.sex,
      'portrait',
    )
    return (
      <InputImage
        key={resident.id}
        model={this.model}
        name='imageId'
        width={300}
        height={300}
        scope='resident'
        style={{ flex: '0 0 300px', borderRadius: 0 }}
        placeholder={portrait}
        disabled={
          resident.deletedAt !== null ||
          !this.context.permissions.resident_editPersonImage
        }
      >
        {resident.deletedAt && (
          <CornerBadge className='bg-red-500' label='Archiv' small />
        )}
      </InputImage>
    )
  }
}
