import { BookingValidator } from '../validators/BookingValidator'
import { z } from 'zod'

export const INFO_BOOKING_REASON_END_OF_STAY = 'Aufenthalt beendet'
export const INFO_BOOKING_REASON_PLANNED_TRANSFER = 'Transfer geplant'
export const INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE = 'Gebührenpflichtende'
export const INFO_BOOKING_REASON_AWUM_GEPLANTE_GEBUEHRENAENDERUNG =
  'Geplante Gebührenänderung'

export type IBooking = z.infer<typeof BookingValidator>
