import { hermes } from '@byll/hermes'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { AppContextProps } from 'services/connection/models/AppContext'

export function getCurrentResponsibilityCompoundId(
  context: AppContextProps,
  filter?: (compound: ICompound) => boolean,
): string | null {
  if (!context.defaults.responsibilityCompoundId) {
    return null
  }
  if (!filter) {
    return context.defaults.responsibilityCompoundId
  }
  const compound = hermes.getFromStore<ICompound>(
    `/api/${context.instance.id}/accommodations/compounds/${context.defaults.responsibilityCompoundId}`,
    false,
  )
  if (!compound) {
    return null
  }
  return filter(compound) ? context.defaults.responsibilityCompoundId : null
}
