import { Spinner } from 'components/Spinner'
import { IResidentLetterEntry } from 'contracts/residents/interfaces/IResidentLetterEntry'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  letters: IResidentLetterEntry[]
}

@observer
export class OverviewLetters extends React.Component<Props, {}> {
  render() {
    if (!this.props.letters) return <Spinner />
    return (
      <>
        <div>
          Abholbereite Briefe:&nbsp;
          {this.props.letters.map((l) => {
            const total = +(l.letters || 0) + +(l.yellowLetters || 0)
            return (
              <span
                key={l.id}
                className='mr-1 inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-white'
              >
                {`${l.list} (${total} ${total === 1 ? 'Brief' : 'Briefe'})`}
              </span>
            )
          })}
        </div>
      </>
    )
  }
}
