import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Button } from 'components/Form/components/Button'
import { RoundIcon } from 'components/RoundIcon'
import { Spinner } from 'components/Spinner'
import { IFullOrder } from 'contracts/inventory/interfaces/IFullOrder'
import { IInventoryDelivery } from 'contracts/inventory/interfaces/IInventoryDelivery'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { OrderSheet } from 'modules/Pdf/templates/OrderSheet'
import * as React from 'react'
import { Route, Routes } from 'react-router'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InventoryBundleTopBar } from '../../InventoryBundle/components/InventoryBundleTopBar'
import { InventoryDeliveryDialog } from './InventoryDeliveryDialog'
import { InventoryOrderForm } from './InventoryOrderForm'

interface Props {
  orderId: string
  navigate: (url: string) => void
}

@observer
export class InventoryOrderPreview extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly order: Resource<IFullOrder>
  private readonly deliveries: Collection<IInventoryDelivery, {}, { orderId: string }>
  @observable private downloading: boolean = false
  @observable private editable: IFullOrder | null = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.order = new Resource(
      `/api/${context.instance.id}/inventory/orders/${this.props.orderId}`,
    )
    this.deliveries = new Collection(`/api/${context.instance.id}/inventory/deliveries`, {
      orderId: this.order.id,
    })
  }

  componentDidMount() {
    this.disposers.push(this.order.init())
    this.disposers.push(this.deliveries.init({ readOnly: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  private setEdit = () => {
    this.editable = toJS(this.order.data)
  }

  @action
  private unsetEdit = () => (this.editable = null)

  private downloadOrderSheet = async () => {
    if (this.downloading) {
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport(
      'order-sheet',
      this.context.instance.id,
      { orderId: this.props.orderId },
      this.order.data?.type === 'inventory' ? 'Inventur.pdf' : 'Bestellschein.pdf',
    )
    runInAction(() => (this.downloading = false))
  }

  @action onReject = () => {
    if (!this.order.data) {
      return
    }
    if (!this.order.data.rejectedAt) {
      this.order.data.approvedAt = null
      this.order.data.rejectedAt = new Date().toISOString()
    } else {
      this.order.data.approvedAt = null
      this.order.data.rejectedAt = null
      ;(document.activeElement as any)?.blur()
    }
  }

  @action onAccept = () => {
    if (!this.order.data) {
      return
    }
    if (!this.order.data.approvedAt) {
      this.order.data.approvedAt = new Date().toISOString()
      this.order.data.rejectedAt = null
    } else {
      this.order.data.approvedAt = null
      this.order.data.rejectedAt = null
      ;(document.activeElement as any)?.blur()
    }
  }

  private closeDeliveryDialog = () => {
    this.props.navigate(`/inventory/orders/${this.order.id}`)
  }

  render() {
    if (!this.order.data) {
      return <Spinner />
    }
    const offerDeliveries =
      !this.editable && this.order.data.type === 'order' && this.deliveries.resources
    return (
      <>
        <InventoryBundleTopBar
          bundle={
            {
              label:
                (this.order.data.type === 'order' ? 'Bestellung ' : 'Inventur ') +
                this.order.data.id,
            } as any
          }
        />

        {offerDeliveries && (
          <div className='w-[888px] md:mx-auto px-6 mt-16'>
            {this.context.permissions.inventory_deliveries > 0 && (
              <div
                onClick={() =>
                  this.props.navigate(`/inventory/orders/${this.order.id}/deliveries/new`)
                }
                className='bg-white my-4 px-5 py-3 rounded-md shadow-md text-gray-500 hover:text-blue-500 cursor-pointer'
              >
                <i className='fas fa-plus' />
                &nbsp; Neue Lieferung
              </div>
            )}
            {this.deliveries.resources &&
              this.deliveries.resources.map((r) => (
                <div
                  key={r.id}
                  onClick={() =>
                    this.props.navigate(
                      `/inventory/orders/${this.order.id}/deliveries/${r.id}`,
                    )
                  }
                  className='bg-white my-4 px-5 py-3 rounded-md shadow-md text-gray-500 hover:text-blue-500 cursor-pointer'
                >
                  {`Lieferung vom ${dayjs(r.data?.deliveredAt).format('DD.MM.YYYY')}`}
                  &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                  {`Eingetragen von ${r.data?.receiver.firstName} ${r.data?.receiver.lastName}`}
                </div>
              ))}
          </div>
        )}

        <div
          className={`flex flex-auto ${offerDeliveries ? 'mt-2' : 'mt-20'} ${
            this.order.data.type === 'order' ? '' : 'mb-6'
          }`}
        >
          <div className='relative flex-[0_0_840px] mx-4 sm:mx-6 lg:mx-auto flex flex-col rounded-md shadow-md overflow-hidden bg-white'>
            {this.editable && (
              <div className='p-6'>
                <InventoryOrderForm
                  order={this.editable}
                  navigate={this.props.navigate}
                  unsetEdit={this.unsetEdit}
                />
              </div>
            )}
            {!this.editable && (
              <OrderSheet order={this.order.data} instanceId={this.context.instance.id} />
            )}
            {!this.editable && (
              <RoundIcon
                icon={this.downloading ? 'fas fa-sync fa-spin' : 'fas fa-download'}
                tooltip={{ text: 'Herunterladen', position: 'left' }}
                style={{ position: 'absolute', top: 16, right: 16 }}
                color='primary'
                onClick={this.downloadOrderSheet}
              />
            )}
          </div>
        </div>

        {!this.editable && this.order.data.type === 'order' && (
          <div className='flex p-5 sticky bottom-0 bg-white border-t border-gray-300 w-[840px] mx-4 sm:mx-6 lg:mx-auto'>
            <div className='flex-auto flex items-start'>
              <Button
                disabled={!this.context.permissions.inventory_acceptOrders}
                onClick={this.onReject}
                color='danger'
                outline={!this.order.data.rejectedAt}
                style={{
                  borderRadius: '6px 0 0 6px',
                  borderRight:
                    this.order.data.rejectedAt || !this.order.data.approvedAt
                      ? undefined
                      : '0px',
                  background: this.order.data.rejectedAt ? undefined : 'white',
                  color: this.order.data.rejectedAt ? undefined : '#555555',
                }}
              >
                Abgelehnt
              </Button>
              <Button
                disabled={!this.context.permissions.inventory_acceptOrders}
                onClick={this.onAccept}
                color='success'
                outline={!this.order.data.approvedAt}
                style={{
                  borderRadius: '0 6px 6px 0',
                  borderLeft: this.order.data.approvedAt ? undefined : '0px',
                  background: this.order.data.approvedAt ? undefined : 'white',
                  color: this.order.data.approvedAt ? undefined : '#555555',
                }}
              >
                Genehmigt
              </Button>
            </div>
            {this.context.permissions.inventory_manageOrders >= 2 && (
              <div className='flex-content'>
                <Button onClick={this.setEdit} color='primary'>
                  Bestellung bearbeiten
                </Button>
              </div>
            )}
          </div>
        )}
        {this.deliveries.resources && this.order.data && (
          <Routes>
            <Route
              path='deliveries/:id'
              element={
                <InventoryDeliveryDialog
                  onClose={this.closeDeliveryDialog}
                  deliveries={this.deliveries}
                  order={this.order.data}
                />
              }
            />
          </Routes>
        )}
      </>
    )
  }
}
