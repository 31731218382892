import { InputMultiSelectOption } from 'components/Form/components/InputMultiSelect'

export function getMultiselectRemoved(
  oldIds: Set<string>,
  selected: readonly { value: string; label: string }[],
  all: InputMultiSelectOption[],
): InputMultiSelectOption[] {
  const removedOptions: InputMultiSelectOption[] = []
  for (const oldId of oldIds) {
    if (!selected.find((s) => s.value === oldId)) {
      removedOptions.push({
        id: oldId,
        label: all.find((a) => a.id === oldId)?.label || '',
      })
    }
  }
  return removedOptions
}
