import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { classNames } from 'helpers/classNames'
import styles from './styles.module.scss'
import { AppContext } from 'services/connection/models/AppContext'
import { Fragment } from 'react'
import { Combobox, Transition } from '@headlessui/react'

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  options: string[]
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  children?: Element
  setRef?: (HTMLInputElement) => void
}

const DropdownIndicator = () => {
  return <div className={styles.dropdownIndicator} />
}

@observer
export class InputAutosuggest extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  @observable private query = ''

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.id = props.id || uniqueId('input-')
  }

  @action
  private onChange = (item: string | null) => {
    this.props.model.values[this.props.name] = item || ''
  }

  @action
  setQuery = (value: string) => {
    this.query = value
    if (!value) {
      this.props.model.values[this.props.name] = ''
    }
  }

  render() {
    const { id, name, model, label, className, children, options, ...attributes } =
      this.props
    const q = this.query.toLowerCase()
    const filtered = this.query
      ? options.filter((o) => o.toLowerCase().includes(q))
      : options
    if (this.query && filtered.length === 0) {
      filtered.push(this.query)
    }
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md pr-10'

    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md pr-10'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <Combobox
          value={model.values[name] || null}
          onChange={this.onChange}
          nullable
          disabled={attributes.disabled}
        >
          <div className='relative'>
            <div className='relative w-full'>
              <Combobox.Input
                name={name}
                className={innerClassName}
                onChange={(event) => this.setQuery(event.target.value)}
              />
              <Combobox.Button className='absolute inset-y-0 right-0 flex items-center'>
                <DropdownIndicator />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave=''
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterLeave={() => undefined}
            >
              <Combobox.Options className='z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {filtered.map((option) => (
                  <Combobox.Option
                    key={option}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 px-4 ${
                        selected
                          ? 'bg-blue-500 text-white'
                          : active
                          ? 'bg-indigo-100 text-black'
                          : 'text-gray-900'
                      }`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option}
                      </span>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    )
  }
}
