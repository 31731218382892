import * as React from 'react'
import { Card } from 'components/Card'
import { hermes } from '@byll/hermes'
import { observer } from 'mobx-react'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { IResidentCount } from 'contracts/dashboard/interfaces/IResidentCount'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { isStammCompound } from 'helpers/isStamm'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import * as qs from 'qs'
import { storage } from 'services/storage'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { box } from 'services/box'
import { DashboardResidentStatsCompoundSelectionDialog } from '../DashboardResidentStats/components/DashboardResidentStatsCompoundSelectionDialog'
import { DashboardResidentStatsCompounds } from '../DashboardResidentStats/components/DashboardResidentStatsCompounds'
import { IResidentCheckInOut } from 'contracts/dashboard/interfaces/IResidentCheckInOut'

interface Props {}

@observer
export class DashboardCheckInsOuts extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private data: { [id: string]: IResidentCheckInOut } | null = null
  private readonly model: Model<{ compoundIds: string }>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    const stored =
      storage.get(`dashboard_checkInOuts_compoundIds.${context.user.id}`) ||
      context.defaults.responsibilityCompoundId ||
      ''
    let compoundIds: string[] =
      typeof stored === 'string'
        ? stored
            .split(',')
            .filter(
              (id) =>
                isIntegerString(id) &&
                !!hermes.getFromStore(
                  `/api/${context.instance.id}/accommodations/compounds/${id}`,
                  false,
                ),
            )
        : []
    if (context.permissions.dashboard_checkInsOuts === 1) {
      compoundIds = compoundIds.filter((id) => isStammCompound(id))
    }
    this.model = new Model({ compoundIds: compoundIds.join(',') })
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(() => this.model.values.compoundIds, this.load, { fireImmediately: true }),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private load = async () => {
    if (!this.model.values.compoundIds) {
      runInAction(() => (this.data = null))
      return
    }
    storage.set(
      `dashboard_checkInOuts_compoundIds.${this.context.user.id}`,
      this.model.values.compoundIds,
    )
    const response = await hermes.indexOnceNew<IResidentCount>(
      `/api/${this.context.instance.id}/dashboard/checkInOutStats${qs.stringify(
        this.model.values,
        { allowDots: true, addQueryPrefix: true, skipNulls: true },
      )}`,
    )
    const stats: { [id: string]: IResidentCheckInOut } = {}
    for (const row of response) {
      stats[row.id] = row as any
    }
    runInAction(() => (this.data = stats))
  }

  private openCompoundSelectionDialog = () => {
    const promise = box.custom(
      <DashboardResidentStatsCompoundSelectionDialog
        model={this.model}
        name='compoundIds'
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context },
    )
  }

  render() {
    return (
      <Card>
        <div className='flex flex-col h-full p-4'>
          <div className='flex-content'>
            <div className='font-bold text-xl mb-2'>Ein- und Auszüge</div>
            <DashboardResidentStatsCompounds
              model={this.model}
              name='compoundIds'
              onSelect={this.openCompoundSelectionDialog}
            />
          </div>
          {this.data && (
            <div className='grid grid-cols-2 gap-4 auto-rows-fr flex-auto'>
              <div className='truncate text-white bg-gradient-to-r from-yellow-600 to-yellow-500 rounded-md p-4'>
                <span className='text-xs'>HEUTE</span>
                <br />
                <span>{`Einzüge: ${this.data['0'].checkIns}`}</span>
                <br />
                <span>{`Auszüge: ${this.data['0'].checkOuts}`}</span>
                <br />
              </div>

              <div className='truncate text-white bg-gradient-to-r from-pink-600 to-pink-400 rounded-md p-4'>
                <span className='text-xs'>GESTERN</span>
                <br />
                <span>{`Einzüge: ${this.data['-1'].checkIns}`}</span>
                <br />
                <span>{`Auszüge: ${this.data['-1'].checkOuts}`}</span>
                <br />
              </div>

              <div className='truncate text-white bg-gradient-to-r from-indigo-600 to-indigo-400 rounded-md p-4'>
                <span className='text-xs'>VORGESTERN</span>
                <br />
                <span>{`Einzüge: ${this.data['-2'].checkIns}`}</span>
                <br />
                <span>{`Auszüge: ${this.data['-2'].checkOuts}`}</span>
                <br />
              </div>

              <div className='truncate text-white bg-gradient-to-r from-gray-600 to-gray-400 rounded-md p-4'>
                <span className='text-xs'>BELEGUNG</span>
                <br />
                <span>{`Heute: ${this.data['0'].belegung}`}</span>
                <br />
                <span>{`Gestern: ${this.data['-1'].belegung}`}</span>
                <br />
                <span>{`Vorgestern: ${this.data['-2'].belegung}`}</span>
                <br />
              </div>
            </div>
          )}
        </div>
      </Card>
    )
  }
}
