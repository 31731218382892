import { hermes } from '@byll/hermes'
import { IEmployeeCommThread } from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { ConflictError } from 'contracts/errors/HermesErrors'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  thread: IEmployeeCommThread
}

export const EmployeeSalarySheetSendButton: React.FC<Props> = ({ thread }) => {
  const context = React.useContext(AppContext)
  const [sending, setSending] = React.useState(false)

  async function send() {
    if (sending) {
      return
    }
    setSending(true)
    try {
      await hermes.create(
        `/api/${context.instance.id}/employeeComm/topics/${thread.topicId}/threads/${thread.id}/send`,
        {},
      )
    } catch (e: any) {
      alert(
        e?.id === ConflictError.id
          ? e.message
          : 'Beim Versand ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator.',
      )
    }
    setSending(false)
  }

  return (
    <span
      onClick={send}
      className={`cursor-pointer inline-flex whitespace-nowrap items-center rounded-md px-2 py-1 text-xs font-medium text-white bg-indigo-500`}
    >
      {sending && (
        <span className='mr-1'>
          <i className='fas fa-spinner fa-spin' />
        </span>
      )}
      {thread.status === 'draft' ? 'Versenden' : 'Erneut versenden'}
    </span>
  )
}
