import { ILogChange } from 'contracts/general/interfaces/ILog'
import * as React from 'react'
import { LogValue } from './LogValue'
import styles from '../styles.module.scss'

export class DetailFromTo extends React.Component<{ detail: ILogChange }, {}> {
  render() {
    return (
      <tr key={this.props.detail.field}>
        <td className={styles.detailField}>{this.props.detail.field}</td>
        <td className={styles.detailFrom}>
          <LogValue value={this.props.detail.from} />
        </td>
        <td className={styles.detailArrow}>
          <i className='fa fa-long-arrow-alt-right ml-2' aria-hidden='false' />
        </td>
        <td className={styles.detailTo}>
          <LogValue value={this.props.detail.to} />
        </td>
      </tr>
    )
  }
}
