import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { uniqueId } from 'helpers/uniqueId'
import { getOccupancyColor } from './getOccupancyColor'

export function createGhostLabel(room: IRoom, occupancy: IOccupancy) {
  const elemDiv = document.createElement('div')
  const id = uniqueId('ghost-label-')
  elemDiv.style.cssText = 'position:absolute;z-index:100;display:flex;'
  elemDiv.innerHTML = `
    <div class="px-2 py-1 bg-gray-300 text-sm text-gray-600 whitespace-nowrap flex-content" style="border-radius: 6px 0 0 6px;" id="${id}"></div>
    <div class="${getOccupancyColor(
      +occupancy.occupied,
      +occupancy.capacity,
      false,
    )} px-2 py-1 text-sm text-white whitespace-nowrap flex-content" style="border-radius: 0 6px 6px 0;">${
      occupancy.occupied
    } / ${room.capacity}</div>`
  elemDiv.setAttribute('data-template-movable', `room-${room.id}`)
  document.body.appendChild(elemDiv)
  const label = document.getElementById(id)
  if (label) {
    label.innerText = room.label
  } // HTML escaping is done by the browser
  return elemDiv
}
