import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Collection, hermes } from '@byll/hermes'
import { IBuildingFloor } from 'contracts/accommodations/interfaces/IBuildingFloor'
import { box } from 'services/box'
import { EditBuildingFloorsPrompt } from 'modules/Compounds/components/RoomList/components/EditBuildingFloorsPrompt'
import { InputBuildingFloor } from 'components/Form/components/InputBuildingFloor'

interface Props {
  model: Model<{ buildingId: string | null; floorIds: string }>
  allowEditFloors?: boolean
  onlyOneFloor?: boolean
}

@observer
export class RoomFloorFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly floors: Collection<IBuildingFloor>
  private readonly disposers: Disposer[] = []

  private get options(): InputMultiSelectOption[] {
    const os: InputMultiSelectOption[] = [{ id: 'null', label: 'Kein Stockwerk' }]
    for (const o of this.floors.resources || []) {
      if (!o.data) {
        continue
      }
      os.push({ id: o.data.id, label: o.data.label })
    }
    return os
  }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.floors = new Collection(`/api/${context.instance.id}/accommodations/floors`, {
      buildingId: props.model.values.buildingId,
    })
    makeObservable(this)
  }

  componentDidMount() {
    if (
      this.props.model.values.buildingId &&
      this.props.model.values.buildingId !== 'none'
    ) {
      this.disposers.push(this.floors.init())
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action private reset = () => {
    this.props.model.values.floorIds = ''
  }

  private openFloorDialog = () => {
    if (
      !this.props.model.values.buildingId ||
      this.props.model.values.buildingId === 'none'
    ) {
      return
    }
    const promise = box.custom(
      <EditBuildingFloorsPrompt
        floors={this.floors}
        onClose={() => promise.close()}
        buildingId={this.props.model.values.buildingId}
      />,
      { context: this.context, closable: true },
    )
  }

  render() {
    let label = 'Stockwerk'
    let color: string = 'text-gray-500'
    const buildingId =
      this.props.model.values.buildingId === 'none'
        ? null
        : this.props.model.values.buildingId
    if (this.props.model.values.floorIds) {
      const values = this.floors.resources
        ? this.props.model.values.floorIds
            .split(',')
            .map((f) =>
              f === 'null'
                ? 'Kein Stockwerk'
                : hermes.getFromStore<IBuildingFloor>(
                    `/api/${this.context.instance.id}/accommodations/floors/${f}`,
                  )?.label,
            )
            .filter(Boolean)
        : ['...']
      label =
        (values[0] || 'Stockwerk') + (values.length > 1 ? ` +${values.length - 1}` : '')
      color = 'text-blue-500 max-w-[200px] truncate'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              {buildingId && !this.props.onlyOneFloor && (
                <InputMultiSelect
                  model={this.props.model}
                  name='floorIds'
                  label='Stockwerk'
                  options={this.options}
                />
              )}
              {buildingId && this.props.onlyOneFloor && (
                <InputBuildingFloor
                  model={this.props.model}
                  name='floorIds'
                  label='Stockwerk'
                  buildingId={buildingId}
                  emptyValue=''
                />
              )}
              {!buildingId && (
                <div>
                  <p className='text-gray-500'>
                    Bitte wählen Sie zuerst ein Gebäude aus. Danach können Sie nach
                    Stockwerk filtern.
                  </p>
                </div>
              )}
              <Menu.Item>
                <div>
                  {this.props.allowEditFloors && buildingId && (
                    <Button
                      color='secondary'
                      onClick={this.openFloorDialog}
                      className='w-full mb-4'
                    >
                      Stockwerke bearbeiten <i className='fas fa-pen' />
                    </Button>
                  )}
                  {buildingId && (
                    <div className='flex'>
                      <Button
                        color='secondary'
                        outline
                        className='flex-auto'
                        onClick={this.reset}
                        style={{ borderRadius: '6px 0 0 6px' }}
                      >
                        Zurücksetzen
                      </Button>
                      <Button
                        color='primary'
                        className='flex-content'
                        style={{ borderRadius: '0 6px 6px 0' }}
                      >
                        OK
                      </Button>
                    </div>
                  )}
                  {!buildingId && (
                    <Button color='primary' className='flex-content w-full'>
                      OK
                    </Button>
                  )}
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
