import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable, when } from 'mobx'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Collection, Resource, hermes } from '@byll/hermes'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { isStammBuilding } from 'helpers/isStamm'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'

interface Props {
  model: Model<IOccupancyFilter>
}

@observer
export class RoomBuildingFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly buildings: Collection<IBuilding, {}, { compoundId?: string | '' }>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.buildings = new Collection(
      `/api/${context.instance.id}/accommodations/buildings`,
      { compoundId: props.model.values.compoundId || '' },
    )
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(this.buildings.init({ readOnly: true, observeQuery: true }))
    this.disposers.push(
      when(
        () => !!this.buildings.resources,
        () => {
          if (isIntegerString(this.props.model.values.buildingId)) {
            return
          }
          const buildings: Resource<IBuilding>[] =
            this.buildings.resources?.filter(
              (b) =>
                this.context.permissions.resident_accommodationBookings === 2 ||
                isStammBuilding(b.id),
            ) ?? []
          if (buildings.length === 1) {
            this.props.model.values.buildingId = buildings[0].id
          } else {
            this.props.model.values.buildingId = null
          }
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action private reset = () => {
    this.props.model.values.buildingId = null
  }

  render() {
    let label = 'Gebäude'
    let color: string = 'text-gray-500'
    if (
      this.props.model.values.buildingId &&
      this.props.model.values.buildingId !== 'none'
    ) {
      const building = hermes.getFromStore<IBuilding>(
        `/api/${this.context.instance.id}/accommodations/buildings/${this.props.model.values.buildingId}`,
        false,
      )
      label = this.buildings.resources ? building?.label || 'Gebäude' : '...'
      color = 'text-blue-500 max-w-[200px] truncate'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputBuilding
                model={this.props.model}
                name='buildingId'
                label='Gebäude'
                compoundId={this.props.model.values.compoundId}
                at={
                  this.props.model.values.queryAt &&
                  this.props.model.values.queryAt !== 'now'
                    ? this.props.model.values.queryAt
                    : undefined
                }
                autoSelectOnlyItem={false}
                onlyStamm={this.context.permissions.resident_accommodationBookings === 1}
              />
              <Menu.Item>
                <div className='flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
