import { Model } from 'components/Form/Model'
import * as React from 'react'
import { TransferPlanFilter } from './types/TransferPlanFilter'
import { TransferPlanTypeFilter } from './components/TransferPlanTypeFilter'
import { TransferPlanPeriodFilter } from './components/TransferPlanPeriodFilter'
import { TransferPlanResidentList } from './components/TransferPlanResidentList'
import { observer } from 'mobx-react'

interface Props {
  buildingId: string | null
}

@observer
export class PlannedTransfers extends React.Component<Props, {}> {
  private readonly model: Model<TransferPlanFilter>

  constructor(props: Props) {
    super(props)
    this.model = new Model({ type: 'Geplante Ankünfte', period: 'Alle' })
  }

  render() {
    return (
      <div
        className='absolute top-0 right-0 w-[312px] bottom-0'
        style={{ borderRadius: '6px 0 0 0' }}
      >
        {/* Dropdown filter */}
        <div className='absolute -top-11 h-11 pt-[10px] left-3 flex gap-4'>
          <TransferPlanPeriodFilter model={this.model} />
        </div>

        {/* Caption filter */}
        <div className='sticky h-12 top-0 ml-3 mr-6 bg-blue-500 text-white rounded-md p-3 z-1'>
          <TransferPlanTypeFilter model={this.model} />
        </div>

        {/* List */}
        {!this.props.buildingId && (
          <div className='text-gray-500 leading-6 p-3 text-sm bg-white shadow absolute top-16 left-3 right-6 bottom-6 rounded-md'>
            Wählen Sie ein Gebäude aus, um die Transferplanung zu laden.
          </div>
        )}
        {this.props.buildingId && (
          <TransferPlanResidentList
            key={`${this.props.buildingId}.${this.model.values.type}.${this.model.values.period}`}
            buildingId={this.props.buildingId}
            type={this.model.values.type}
            period={this.model.values.period}
          />
        )}
      </div>
    )
  }
}
