import * as React from 'react'
import { FormEvent } from 'react'

/**
 * Wraps children in a form with a not displayed submit input.
 * This is necessary for dynamic forms where form controls such as inputs are dynamically added/removed. In these
 * forms, the onSubmit handler of the form will only be called if a form control of type submit is present.
 * See http://stackoverflow.com/questions/39942969/reactjs-form-with-multiple-inputs-does-not-submit for an example
 */
interface Props {
  onSubmit: (event: FormEvent) => void
  id?: string
}

export class InputForm extends React.Component<Props, {}> {
  onSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(event)
  }

  render() {
    return (
      <form onSubmit={this.onSubmit} id={this.props.id}>
        {this.props.children}
        <input type='submit' style={{ display: 'none' }} />
      </form>
    )
  }
}
