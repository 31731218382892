import { RoundIcon } from 'components/RoundIcon'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import printJS from 'print-js-updated'
import { Button } from 'components/Form/components/Button'

interface Props {
  url: string
  style?
  type?: 'button' | 'icon'
  label?: string
}

@observer
export class PrintIcon extends React.Component<Props, {}> {
  @observable private loading = false
  private timer

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = null
  }

  private onPrint = () => {
    if (this.loading) {
      return
    }
    runInAction(() => (this.loading = true))
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    this.timer = setTimeout(
      action(() => (this.loading = false)),
      5000,
    )
    printJS({
      printable: this.props.url,
      type: 'pdf',
      showModal: false,
      onPrintDialogClose: this.onPrintDialogClose,
      onPdfOpen: this.onPrintDialogClose,
      onError: this.onPrintError,
    })
  }

  @action private onPrintDialogClose = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = null
    this.loading = false
  }

  @action private onPrintError = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = null
    alert(
      `Das Dokument konnt nicht gedruckt werden. Sie können das Dokument stattdessen herunterladen und Ihr PDF Anzeigeprogramm zum Drucken verwenden.`,
    )
  }

  render() {
    if (this.props.type === 'button') {
      return (
        <Button
          color='success'
          className='mb-6'
          disabled={this.loading}
          style={this.props.style}
          onClick={this.onPrint}
        >
          {this.loading && (
            <span>
              <i className='fa fa-spin fa-sync fa-sm' />
            </span>
          )}
          {!this.loading && (
            <span>
              <i className='fas fa-print' />
            </span>
          )}
          &nbsp;
          {this.props.label || 'Drucken'}
        </Button>
      )
    }

    return (
      <RoundIcon
        icon={this.loading ? 'fa fa-spin fa-sync fa-sm' : 'fas fa-print'}
        tooltip='Drucken'
        style={this.props.style}
        color='primary'
        onClick={this.onPrint}
      />
    )
  }
}
