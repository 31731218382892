import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
import { getBuildingAddress } from 'contracts/accommodations/helpers/getBuildingAddress'

export class Default extends React.Component<ResidentCardProps, {}> {
  render() {
    return (
      <div
        className={styles.page}
        style={this.props.batch ? {} : { pageBreakAfter: 'always' }}
      >
        <div
          className='bg-blue-500 absolute top-0 left-0 right-0 text-white pl-2'
          style={{ height: 40, lineHeight: '40px' }}
        >
          Bewohnerausweis
        </div>
        <img
          src={getResidentImageSrc(
            this.props.instanceId,
            this.props.resident.imageId,
            this.props.resident.sex,
            'portrait',
          )}
          alt='Bewohnerbild'
          style={{ position: 'absolute', top: 48, left: 8, width: 112 }}
        />

        <div
          className='absolute flex flex-col overflow-hidden'
          style={{ left: 128, top: 55, right: 8, fontSize: 12, lineHeight: '12px' }}
        >
          <div>
            <div>{this.props.resident.firstName}&nbsp;</div>
            <div style={{ fontSize: 9 }}>Vorname</div>
          </div>
          <div style={{ marginTop: 8 }}>
            <div>{this.props.resident.lastName}&nbsp;</div>
            <div style={{ fontSize: 9 }}>Nachname</div>
          </div>
          <div className='flex' style={{ marginTop: 8 }}>
            <div className='flex-content' style={{ marginRight: 20 }}>
              <div>
                {this.props.resident.dateOfBirth
                  ? dayjs(this.props.resident.dateOfBirth).format('DD.MM.YYYY')
                  : '-'}
                &nbsp;
              </div>
              <div style={{ fontSize: 9 }}>Geburtsdatum</div>
            </div>
            <div className='flex-auto'>
              <div>{this.props.resident.data.nationality || '-'}&nbsp;</div>
              <div style={{ fontSize: 9 }}>Staatsangehörigkeit</div>
            </div>
          </div>
        </div>

        {this.props.resident.data.accommodation?.type === 'internal-residence' && (
          <div
            className='absolute'
            style={{ left: 8, top: 167, fontSize: 12, lineHeight: '12px' }}
          >
            Check-in:{' '}
            {dayjs(this.props.resident.data.accommodation.beginAt).format('DD.MM.YYYY')}
            <br />
            Zimmer: {this.props.resident.data.accommodation.room?.label ?? ''}
          </div>
        )}

        <div
          className='absolute bg-blue-500'
          style={{
            left: 0,
            bottom: 0,
            right: 0,
            height: 18,
            color: 'white',
            fontSize: 9,
            lineHeight: '18px',
            paddingLeft: 8,
          }}
        >
          {getBuildingAddress(this.props.resident.data.accommodation?.building)}
        </div>
      </div>
    )
  }
}
