import * as React from 'react'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { InventoryBundleTopBar } from './InventoryBundleTopBar'
import { InventoryBundleForm } from './InventoryBundleForm'

interface Props {
  compoundId: string
  navigate: (url: string) => void
}

@observer
export class InventoryBundleNew extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private bundle: IInventoryBundle

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.bundle = {
      id: '',
      label: '',
      instanceId: context.instance.id,
      compoundId: props.compoundId,
      imageId: null,
    }
    makeObservable(this)
  }

  render() {
    return (
      <>
        <InventoryBundleTopBar bundle={{ label: 'Neues Paket' } as any} />

        <InventoryBundleForm
          bundle={this.bundle}
          setEdit={() => this.props.navigate('/inventory/bundles')}
          navigate={this.props.navigate}
          compoundId={this.props.compoundId}
        />
      </>
    )
  }
}
