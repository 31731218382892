import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { IVisitScanResult } from 'contracts/scan/interfaces/IVisitScanResult'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  station: IScanningStation
  scan: IVisitScanResult
}

export const VisitorSuccessCard: React.FC<Props> = ({ scan, station }) => {
  const context = React.useContext(AppContext)
  const bgColor = scan.type === 'in' ? 'bg-green-500' : 'bg-indigo-500'
  const borderColor = scan.type === 'in' ? 'border-green-500' : 'border-indigo-500'
  const textColor = scan.type === 'in' ? 'text-green-500' : 'text-indigo-500'
  const cardColor = scan.type === 'in' ? 'bg-green-100' : 'bg-indigo-100'
  let arrow: 'left' | 'right' = scan.type === 'out' ? 'left' : 'right'
  if (station.entrance === 'left') {
    arrow = scan.type === 'out' ? 'right' : 'left'
  }

  return (
    <div className='flex'>
      <div className='flex-[0_0_10%] relative' style={{ fontSize: 100 }}>
        {arrow === 'left' && (
          <div
            className={`absolute top-[50%] h-[100px] left-0 right-0 transform -translate-y-1/2 ${textColor}`}
          >
            <i className='absolute top-0 right-0 fas fa-caret-left' />
          </div>
        )}
      </div>
      <div className='flex-[1_1_10%] relative'>
        <div
          className={`absolute top-[50%] left-0 right-0 transform -translate-y-1/2 h-6 w-full ${bgColor}`}
        />
      </div>

      <div className='flex-[0_1_500px]  flex flex-col min-w-0'>
        <div
          className={`flex flex-content border-4 rounded-lg min-h-[240px] ${borderColor} ${cardColor}`}
        >
          <div className='flex-[0_0_40%] p-4'>
            <img
              className='rounded-md'
              src={getResidentImageSrc(
                context.instance.id,
                scan.resident.imageId,
                scan.resident.sex,
                'portrait',
              )}
              alt={scan.resident.lastName}
            />
          </div>
          <div className='flex-auto py-4 pr-4 min-w-0' style={{ fontSize: 24 }}>
            <div className='truncate'>{`${scan.resident.lastName}, ${scan.resident.firstName}`}</div>
            <div className='text-base'>{`Herkunft: ${
              scan.resident.country?.country || scan.resident.country?.nationality || '-'
            }`}</div>
            <div className='text-base'>{`Unterkunft: ${
              scan.resident.accommodation?.label || '-'
            }`}</div>
          </div>
        </div>
      </div>

      <div className='flex-[1_1_10%] relative'>
        <div
          className={`absolute top-[50%] left-0 right-0 transform -translate-y-1/2 h-6 w-full ${bgColor}`}
        />
      </div>
      <div className='flex-[0_0_10%] relative' style={{ fontSize: 100 }}>
        {arrow === 'right' && (
          <div
            className={`absolute top-[50%] h-[100px] left-0 right-0 transform -translate-y-1/2 ${textColor}`}
          >
            <i className='absolute top-0 left-0 fas fa-caret-right' />
          </div>
        )}
      </div>
    </div>
  )
}
