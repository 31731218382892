/**
 * There is one entry for each document in table qr_codes (qr_codes.id = qrCodeId). The document
 * could have several pages. All pages share the same qrCodeId, but have the page number and
 * total number of pages encoded separately. Also the instance id is encoded separately, so
 * that the same document cannot be used in different instances. (and as check-sum)
 */
export function encodeQrCode(
  qrCodeId: string,
  pageNumber: number,
  totalNumberOfPages: number,
  instanceId: string,
): string {
  return `${encodeBlock(instanceId)}${encodeBlock(String(pageNumber))}${encodeBlock(
    String(totalNumberOfPages),
  )}${qrCodeId}`
}

function encodeBlock(block: string): string {
  const length = String(block.length)
  if (length.length !== 1) {
    throw new Error('encodeBlock failed with invalid length')
  } // Format assumes block is max 9 digits, so that length is always 1
  return `${length}${block}`
}
