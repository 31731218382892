import * as React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { IUserCard } from 'contracts/userCard/interfaces/IUserCard'
import { ICompanyIdCardTemplate } from 'contracts/userCard/interfaces/ICompanyIdCardTemplate'
import { Movable } from 'components/Movable/Movable'
import styles from '../../styles.module.scss'
import { Model } from 'components/Form/Model'
import { AppContext } from 'services/connection/models/AppContext'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'

export interface IdCardLogoModel {
  logoX: number
  logoY: number
  logoWidth: number
  logoHeight: number
  logoOriginalWidth: number
  logoOriginalHeight: number
}

interface Props {
  userCard: IUserCard
  companyIdCardTemplate?: Model<ICompanyIdCardTemplate>
  logoModel?: IdCardLogoModel
  media?: 'screen' | 'print'
}

// Front: Format 8.56 x 5.398 cm, 50 pixel per cm
@observer
export class EmployeeIdCardFront extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable template: ICompanyIdCardTemplate | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    if (props.companyIdCardTemplate) {
      this.template = props.companyIdCardTemplate.values
    }
  }

  @action
  onDeleteLogo = () => {
    if (!this.props.companyIdCardTemplate || !this.template) {
      return
    }
    this.template.logoImageId = null
  }

  render() {
    const card = this.props.userCard
    return (
      <div
        className={`${styles.card} ${styles.front} ${
          this.props.media !== 'print' ? ` ${styles.screen}` : ''
        }`}
      >
        {/* Colored stripe */}
        <div className={styles.frontStripe} style={{ background: card.color }} />

        {/* Portrait image */}
        <div className={styles.thumbnail}>
          <img
            alt=''
            src={getUserImageSrc(
              this.props.userCard.instanceId,
              card.imageId,
              card.sex,
              'portrait',
            )}
          />
        </div>

        {/* Title + first name */}
        <div className={styles.firstName}>{card.firstName}</div>

        {/* Last name */}
        <div className={styles.lastName}>{card.lastName}</div>

        {/* Company logo (not movable) */}
        {!this.props.logoModel && this.template && this.template.logoImageId && (
          <div
            className='absolute'
            style={{
              top: this.template.logoYCm! * 40,
              left: this.template.logoXCm! * 40,
            }}
          >
            <img
              alt=''
              src={`/api/${this.props.userCard.instanceId}/images/o${this.template.logoImageId}`}
              style={{ width: this.template.logoWidthCm! * 40 }}
            />
          </div>
        )}

        {/* Company logo (movable) */}
        {this.props.logoModel && this.template && this.template.logoImageId && (
          <Movable
            prefix='logo'
            model={this.props.logoModel}
            onDelete={this.onDeleteLogo}
            disabled={!this.context.permissions.companies_editIdCardTemplates}
          >
            {this.template.logoImageId && (
              <img
                alt=''
                src={`/api/${this.props.userCard.instanceId}/images/o${this.template.logoImageId}`}
              />
            )}
          </Movable>
        )}
      </div>
    )
  }
}
