import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'

interface Props {
  model: Model<IResidentSearchResultsFilter>
  defaultTodoOptions: InputSelectOption[]
}

@observer
export class SelectTodo extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setToOpen = () => {
    this.props.model.values.todoState = 'open'
  }
  @action private setToDone = () => {
    this.props.model.values.todoState = 'done'
  }

  @action private reset = () => {
    this.props.model.values.todoText = null
    this.props.model.values.todoState = 'open'
  }

  render() {
    let label = 'Todo'
    let color: string = 'text-gray-500'
    if (this.props.model.values.todoText && this.props.model.values.todoState) {
      label =
        (this.props.model.values.todoState === 'open' ? 'Offen: ' : 'Erledigt: ') +
        this.props.model.values.todoText
      color = 'text-blue-500'
    }
    if (this.props.model.values.todoText === 'Pfortenbenachrichtigung') {
      label = `Aktiv: ${this.props.model.values.todoText}`
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputSelect
                model={this.props.model}
                name='todoText'
                label='Aufgabe'
                options={this.props.defaultTodoOptions}
              />
              {this.props.model.values.todoText !== 'Pfortenbenachrichtigung' && (
                <div className='flex'>
                  <Button
                    onClick={this.setToOpen}
                    color={
                      this.props.model.values.todoState === 'open'
                        ? 'primary'
                        : 'secondary'
                    }
                    outline={this.props.model.values.todoState !== 'open'}
                    className='flex-auto'
                    style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                  >
                    Offen
                  </Button>
                  <Button
                    onClick={this.setToDone}
                    color={
                      this.props.model.values.todoState !== 'open'
                        ? 'primary'
                        : 'secondary'
                    }
                    outline={this.props.model.values.todoState === 'open'}
                    className='flex-auto'
                    style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                  >
                    Erledigt
                  </Button>
                </div>
              )}
              {this.props.model.values.todoText === 'Pfortenbenachrichtigung' && (
                <Button color='primary' className='w-full'>
                  Aktiv
                </Button>
              )}
              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
