import * as React from 'react'
import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { ICompany } from 'contracts/companies/interfaces/ICompany'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import styles from '../styles.module.scss'
import { CompanyValidator } from 'contracts/companies/validators/CompanyValidator'
import { CompanyTitleBar } from '../CompanyTitleBar'

interface Props {
  company: ICompany
  navigate: (url: string) => void
}

@observer
export class CompanyOverviewTab extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<ICompany>
  @observable private loading: boolean = false
  @observable private hasUnsavedChanges = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({ ...props.company }, CompanyValidator)
  }

  componentDidMount(): void {
    reaction(
      () => `
        ${this.model.values.name}
        ${this.model.values.address}
        ${this.model.values.contactPerson}
        ${this.model.values.contactEmail}
        ${this.model.values.comment}
      `,
      () => (this.hasUnsavedChanges = true),
    )
  }

  private save = async () => {
    const data = {
      name: this.model.values.name,
      address: this.model.values.address,
      contactPerson: this.model.values.contactPerson,
      contactEmail: this.model.values.contactEmail,
      comment: this.model.values.comment,
      archived: this.model.values.archived,
    }
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    if (this.props.company?.id) {
      try {
        await hermes.patch(
          `/api/${this.context.instance.id}/companies/${this.props.company.id}`,
          data,
        )
        runInAction(() => (this.hasUnsavedChanges = false))
      } catch (_e) {
        box.alert('Fehler', 'Beim Speichern der Firma ist ein Fehler aufgetreten.')
      }
    } else {
      try {
        const { id } = await hermes.create(
          `/api/${this.context.instance.id}/companies`,
          data,
        )
        runInAction(() => (this.hasUnsavedChanges = false))
        setTimeout(() => this.props.navigate(`/companies/${id}/overview`), 100)
      } catch (_e) {
        box.alert('Fehler', 'Beim Erstellen der Firma ist ein Fehler aufgetreten.')
      }
    }
    runInAction(() => (this.loading = false))
  }

  private toggleArchived = async () => {
    if (!this.props.company) {
      return
    }
    const archived = !this.props.company.archived
    try {
      await hermes.patch(
        `/api/${this.context.instance.id}/companies/${this.props.company?.id}`,
        { archived },
      )
      runInAction(() => {
        this.props.company.archived = archived
        this.model.values.archived = archived
      })
    } catch (_e) {
      box.alert(
        'Fehler',
        'Beim Archivieren/Wiederherstellen der Firma ist ein Fehler aufgetreten.',
      )
    }
  }

  @action
  private reset = () => {
    if (!this.props.company) {
      return
    }
    this.model.values.name = this.props.company.name
    this.model.values.address = this.props.company.address
    this.model.values.contactPerson = this.props.company.contactPerson
    this.model.values.contactEmail = this.props.company.contactEmail
    this.model.values.comment = this.props.company.comment
    setTimeout(
      action(() => (this.hasUnsavedChanges = false)),
      100,
    )
  }

  render() {
    const readOnly = !this.context.permissions.companies_edit
    if (!this.props.company) {
      return null
    }
    if (readOnly && !this.props.company.id) {
      return null
    }
    return (
      <div className={styles.tabs}>
        <CompanyTitleBar companyId={this.props.company?.id} />
        <div className='mt-6'>
          <CardColumnCard
            title={this.props.company.id ? 'Firmendaten' : 'Neue Firma'}
            subtitle={
              this.props.company.id
                ? 'Hier können Sie Kontaktdaten zu dieser Firma hinterlegen'
                : 'Hier können Sie eine neue Firma anlegen'
            }
          >
            <div id={this.model.id}>
              <div className='mb-4 relative'>
                <InputText
                  style={this.props.company.archived ? { paddingRight: 80 } : undefined}
                  name='name'
                  model={this.model}
                  label='Firmenname'
                  disabled={readOnly}
                />
                {this.model.values.archived && (
                  <span className='absolute right-1 top-2 bottom-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 border border-yellow-500'>
                    Archiviert
                  </span>
                )}
              </div>
              <InputTextarea
                rows={3}
                className='mb-4'
                name='address'
                model={this.model}
                label='Postanschrift'
                disabled={readOnly}
              />
              <InputText
                className='mb-4'
                name='contactPerson'
                model={this.model}
                label='Kontaktperson'
                disabled={readOnly}
              />
              <InputText
                className='mb-4'
                name='contactEmail'
                model={this.model}
                label='E-Mail'
                disabled={readOnly}
              />
              <InputTextarea
                rows={3}
                name='comment'
                model={this.model}
                label='Notizen'
                disabled={readOnly}
              />
              {!readOnly && (
                <div className='flex gap-2 mt-4 justify-end'>
                  {this.props.company.id && (
                    <Button onClick={this.toggleArchived} color='secondary' outline>
                      {this.model.values.archived
                        ? ' Aus Archiv wiederherstellen'
                        : 'Archivieren'}
                    </Button>
                  )}
                  <Button
                    onClick={
                      this.props.company.id
                        ? this.reset
                        : () => this.props.navigate('/companies')
                    }
                    color='secondary'
                  >
                    {this.props.company.id ? 'Zurücksetzen' : 'Abbrechen'}
                  </Button>
                  <Button loading={this.loading} onClick={this.save}>
                    {this.props.company.id ? 'Speichern' : 'Firma anlegen'}
                  </Button>
                </div>
              )}
            </div>
          </CardColumnCard>
        </div>
        {this.hasUnsavedChanges && <PreventRouteChange />}
      </div>
    )
  }
}
