import * as React from 'react'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { isTime } from 'contracts/general/helpers/isTime'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { z } from 'zod'
import { Collection, hermes } from '@byll/hermes'
import { dayjs } from 'helpers/dayjs'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { Message } from 'components/Message'
import { InputDate } from 'components/Form/components/InputDate'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { InputDocument } from 'components/Form/components/InputDocument'
import { Button } from 'components/Form/components/Button'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { ConflictError, NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { Model } from 'components/Form/Model'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { ABRECHNUNGSMODUS_AWUM } from 'contracts/costCoverages/interfaces/abrechnungsmodus'
import {
  IEnabledInternalResidenceFormFields,
  getEnabledFields,
} from '../../InternalResidenceForm/helpers/getEnabledFields'

interface EndOfBillingModel {
  id: string
  beginDate: string | null
  beginTime: string
  reason: string
  comment: string
  documentId: string | null
  billingDateOfNoticeCreation: string | null
}

interface Props {
  resident: IResident
  booking: IBooking
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  bookings?: IBooking[]
}

@observer
export class EndOfBillingForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<EndOfBillingModel>
  private readonly enabled: IEnabledInternalResidenceFormFields
  private readonly disposers: Disposer[] = []
  @observable private readOnly = true // show save button if readOnly = false
  @observable private patching = false
  @observable private error: string | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    const beginAt = dayjs(props.booking.beginAt)
    const validator = z.object({
      beginDate: z.string(),
      beginTime: z.string().refine(isTime),
    })
    this.model = new Model(
      {
        id: props.booking.id,
        beginDate: beginAt.format('YYYY-MM-DD'),
        beginTime: beginAt.format('HH:mm'),
        reason: props.booking.reason,
        comment: props.booking.comment,
        documentId: props.booking.documentId || null,
        billingDateOfNoticeCreation:
          props.booking.extra.billing?.dateOfNoticeCreation || null,
      },
      validator,
    )
    this.enabled = getEnabledFields(false, 'end-of-billing', null, 0, context.permissions)
    this.error = this.enabled.error
    this.readOnly = !!this.enabled.error
    makeObservable(this)
  }

  componentDidMount(): void {
    if (this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_AWUM) {
      this.disposers.push(
        reaction(
          () => this.model.values.documentId,
          (documentId) => {
            this.model.values.billingDateOfNoticeCreation = documentId
              ? dayjs().format('YYYY-MM-DD')
              : null
          },
        ),
      )
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private onSubmit = async () => {
    if (this.patching) {
      return
    }
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }

    // Save existing booking
    runInAction(() => (this.patching = true))
    const begin = dayjs(`${this.model.values.beginDate} ${this.model.values.beginTime}`)
    const data: Partial<IBooking> = {
      comment: this.model.values.comment,
      beginAt: begin.toISOString(),
      endAt: begin.add(1, 'minute').toISOString(),
      reason: this.model.values.reason,
      documentId: this.model.values.documentId,
      extra: {
        billing: {
          status: '',
          category: '',
          dateOfNoticeCreation: this.model.values.billingDateOfNoticeCreation,
          avDate: null,
        },
      },
    }
    try {
      await hermes.patch(
        `/api/${this.context.instance.id}/bookings/${this.model.values.id}`,
        data,
      )
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.patching = false
        if (e.id === ConflictError.id) {
          this.error = e.message
        } else if (e.id === NotAuthorizedError.id) {
          this.error = 'Sie haben nicht die nötige Berechtigung für diesen Vorgang.'
        } else {
          this.error = 'Die Änderungen konnten nicht gespeichert werden.'
        }
      })
    }
  }

  render() {
    const model = this.model

    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Gebührenpflichtende
            </Dialog.Title>
          </div>
        </div>

        <div className='min-h-[242px] flex flex-col'>
          {this.error && (
            <Message color='danger' className='mt-6'>
              {this.error}
            </Message>
          )}

          <div
            className='flex-content relative grid grid-cols-4 gap-4 my-4'
            id={model.id}
          >
            <InputDate
              disabled={this.patching || !this.enabled.all}
              model={model}
              name='beginDate'
              label='Ab Datum'
              placeholder='DD.MM.JJJJ'
            />
            <InputText
              disabled={this.patching || !this.enabled.all}
              model={model}
              name='beginTime'
              label='Uhrzeit'
              tooltip='Uhrzeit benötigt'
              placeholder='hh:mm'
            />
            <InputText
              className='col-span-2'
              disabled={true}
              model={model}
              name='compoundId'
              label=''
            />

            <InputTextarea
              disabled={this.patching || !this.enabled.all}
              model={model}
              name='comment'
              label='Notiz'
              rows={5}
              className='col-span-4'
            />

            <InputDocument
              scope='booking'
              disabled={this.patching || !this.enabled.documentId}
              model={model}
              name='documentId'
              label='Gebührenbescheid'
              className='col-span-2'
              preview
            />
            <InputDate
              disabled={this.patching || !this.enabled.billingDateOfNoticeCreation}
              model={model}
              name='billingDateOfNoticeCreation'
              label='Erstellt am'
              placeholder='DD.MM.JJJJ'
              className='col-span-2'
            />
          </div>

          <div className='my-auto flex-content text-right'>
            <Button color='secondary' outline onClick={this.props.onClose}>
              {this.readOnly ? 'Schließen' : 'Abbrechen'}
            </Button>
            {!this.readOnly && (
              <Button color='purple' className='ml-2' onClick={this.onSubmit}>
                Speichern
              </Button>
            )}
          </div>

          {this.patching && <DialogOverlaySpinner opaque />}
        </div>
      </>
    )
  }
}
