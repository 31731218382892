import { connection } from '@byll/hermes/lib/services/connection'
import { connected, connecting, State } from '@byll/hermes/lib/interfaces/ConnectionData'

export function connectHermes(): Promise<any> {
  return new Promise<void>((resolve, reject) => {
    connection.connect(
      window.location.protocol + '//' + window.location.host,
      (state: State) => {
        switch (state) {
          case connecting:
            return
          case connected:
            // Return promise after initial connect
            resolve() // Subsequent resolves are ignored by promise
            return
          default:
            reject('could not connect')
        }
      },
    )
  })
}
