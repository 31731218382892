import { hermes } from '@byll/hermes'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { LastScanList } from './LastScanList'

interface Props {
  resident: IResident
}

@observer
export class LastScans extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable nationality = ''
  private model = new Model<{ date: string | null }>({
    date: dayjs().format('YYYY-MM-DD'),
  })

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    if (!this.props.resident.nationalityId) {
      return
    }
    hermes
      .getOnceNew<any>(
        `/api/${this.context.instance.id}/nationalities/${this.props.resident.nationalityId}`,
      )
      .then(action((data) => (this.nationality = data.label)))
  }

  render() {
    return (
      <div className={`bg-gray-100 text-blick rounded-md p-4 flex-auto w-1/2`}>
        <InputDate model={this.model} name='date' label='Scans vom' />
        {this.model.values.date && (
          <LastScanList
            key={this.model.values.date}
            date={this.model.values.date}
            resident={this.props.resident}
          />
        )}
        {!this.model.values.date && (
          <div className='text-gray-500 mt-2'>Bitte wählen Sie ein Datum aus.</div>
        )}
      </div>
    )
  }
}
