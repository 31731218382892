import { Resource } from '@byll/hermes'
import { IInventoryItemScan } from 'contracts/inventory/interfaces/IInventoryItemScan'
import { dayjs } from 'helpers/dayjs'

export interface IInventoryItemScanGroup {
  date: string // ymd
  logs: IInventoryItemScan[]
}

export function getInventoryItemScanGroups(
  logs: Resource<IInventoryItemScan>[] | null,
): IInventoryItemScanGroup[] {
  const dates = new Map<string, IInventoryItemScan[]>() // ymd => logs
  const groups: IInventoryItemScanGroup[] = []
  if (!logs) {
    return []
  }

  for (const log of logs) {
    if (!log.data) {
      continue
    }
    const ymd = dayjs(log.data.issuedAt).format('YYYY-MM-DD')
    if (!dates.has(ymd)) {
      dates.set(ymd, [])
    }
    dates.get(ymd)!.push(log.data)
  }

  for (const [d, ls] of dates) {
    groups.push({ date: d, logs: ls })
  }
  groups.sort((a, b) => (a.date > b.date ? -1 : 1))
  return groups
}
