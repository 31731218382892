import { Model } from 'components/Form/Model'
import { ICompanyIdCardTemplate } from 'contracts/userCard/interfaces/ICompanyIdCardTemplate'
import { IUserCard } from 'contracts/userCard/interfaces/IUserCard'
import { observer } from 'mobx-react'
import { EmployeeIdCardBack } from 'modules/Companies/components/CompaniesContent/components/CompanyCardTab/components/EmployeeIdCardBack'
import { EmployeeIdCardFront } from 'modules/Companies/components/CompaniesContent/components/CompanyCardTab/components/EmployeeIdCardFront'
import * as React from 'react'
import { allImagesLoaded } from '../../helpers/allImagesLoaded'
import { hasWaiters } from '../../helpers/hasWaiters'
import styles from './styles.module.scss'

interface Props {
  storageData: {
    userCards: IUserCard[]
    template: ICompanyIdCardTemplate | null
  }
  instanceId: string
}

@observer
export class EmployeeIdCard extends React.Component<Props, {}> {
  private model: Model<ICompanyIdCardTemplate>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.storageData.template)
  }

  componentDidMount() {
    this.setReadyFlagWhenRenderingComplete()
  }

  private setReadyFlagWhenRenderingComplete = () => {
    // Wait until none of the children reports a blocker. (Elements with class "wait" are blockers)
    // Blockers are used if a components loads something asynchronously and cannot show the
    // final content yet.
    const timer = setInterval(() => {
      if (hasWaiters() || !allImagesLoaded()) {
        console.log('Waiter detected, try again...')
        return
      }
      console.log('Rendering completed. Final result is displayed.')
      ;(window as any).ready = true
      clearInterval(timer)
    }, 40)
  }

  render() {
    return (
      <>
        {this.props.storageData.userCards.map((card, i) => (
          <div key={i}>
            <div className={styles.page}>
              <EmployeeIdCardFront
                userCard={card}
                companyIdCardTemplate={this.model}
                media='print'
              />
            </div>

            <div className={styles.page}>
              <EmployeeIdCardBack
                userCard={card}
                companyIdCardTemplate={this.model}
                media='print'
              />
            </div>
          </div>
        ))}
      </>
    )
  }
}
