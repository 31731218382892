import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { makeObservable, observable, toJS } from 'mobx'
import { InventoryFolderForm } from './InventoryFolderForm'
import { InventoryItemTopBar } from '../../InventoryItem/components/InventoryItemTopBar'

interface Props {
  folder: IInventoryFolder
  navigate: (url: string) => void
  setEdit: (edit: boolean) => void
}

@observer
export class InventoryFolderEdit extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private folder: IInventoryFolder
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    this.folder = toJS(props.folder)
    makeObservable(this)
  }

  render() {
    return (
      <>
        <InventoryItemTopBar folder={this.props.folder} />

        <InventoryFolderForm
          folder={this.folder}
          navigate={this.props.navigate}
          setEdit={this.props.setEdit}
        />
      </>
    )
  }
}
