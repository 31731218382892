import { z } from 'zod'
import { isDecimalString } from '../../general/helpers/isDecimalString'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isISODate } from '../../general/helpers/isISODate'

export const InventoryItemValidator = z.object({
  id: z.string(),
  folderId: z.string(),
  bundleIds: z.array(z.string().refine(isIntegerString)),
  imageId: z.string().min(1).max(255).nullable(),
  label: z.string().min(1),
  brutto: z
    .string()
    .refine((v) => isDecimalString(v, 8, 2))
    .nullable(),
  netto: z
    .string()
    .refine((v) => isDecimalString(v, 8, 2))
    .nullable(),
  count: z.string().refine((v) => isDecimalString(v, 6, 0, '0')),
  durationOfUse: z
    .string()
    .refine((v) => isDecimalString(v, 4, 0))
    .nullable(),
  unit: z.string().min(1),
  orderlink: z.string(),
  barcode: z.string(),
  minStock: z
    .string()
    .refine((v) => isDecimalString(v, 6, 0))
    .nullable(),
  maxStock: z
    .string()
    .refine((v) => isDecimalString(v, 6, 0))
    .nullable(),
  issueCount: z
    .string()
    .refine((v) => isDecimalString(v, 4, 0, '1'))
    .nullable(), // How many items should be issued at once? e.g. 2 if two toilet paper roles should be issued at once.
  packageSize: z
    .string()
    .refine((v) => isDecimalString(v, 6, 0))
    .nullable(),
  isOrderable: z.boolean(),
  isDispensable: z.boolean(),
  deletedAt: z.union([z.string().refine(isISODate), z.null()]),
  scope: z.string(),
})
