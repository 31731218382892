import * as React from 'react'

interface Props {
  className?: string
}

export const Card: React.FC<Props> = (props) => (
  <div
    className={`bg-white rounded-md shadow-md overflow-hidden ${props.className || ''}`}
  >
    {props.children}
  </div>
)
// <h3 className="text-md leading-6 font-medium text-gray-900">Job Postings</h3>
