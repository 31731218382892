import { Collection, hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { FixedSizeGrid as Grid } from 'react-window'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { getNumberOfOccupancyColumns } from 'modules/ResidentsOccupancy/helpers/getNumberOfOccupancyColumns'
import debounce from 'lodash/debounce'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import { IOccupancyMetadata } from 'contracts/occupancies/interfaces/IOccupancyMetadata'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'
import { Callout } from 'components/Callout'
import {
  ROOM_TILE_HEIGHT,
  ResidentOccupancyRoomTile,
} from './components/ResidentOccupancyRoomTile'
import dayjs, { Dayjs } from 'dayjs'

interface Props {
  occupancies: UnsyncedCollection<IOccupancy, IOccupancyMetadata, IOccupancyFilter>
}

@observer
export class OccupancyRoomList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly rooms: Collection<
    IRoom,
    {},
    { compoundId: string; buildingId: string | null }
  > | null = null
  private readonly disposers: Disposer[] = []
  private parentRef: HTMLDivElement | null = null
  @observable.ref private size: {
    width: number
    height: number
    columns: number
  } | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    if (
      props.occupancies.query.compoundId &&
      props.occupancies.query.buildingId !== 'none'
    ) {
      this.rooms = new Collection(`/api/${context.instance.id}/accommodations/rooms`, {
        compoundId: props.occupancies.query.compoundId,
        buildingId: props.occupancies.query.buildingId,
      })
    }
    makeObservable(this)
  }

  componentDidMount() {
    if (this.rooms) {
      this.disposers.push(this.rooms.init({ readOnly: true }))
    }
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    dispose(this.disposers)
    window.removeEventListener('resize', this.onResize)
  }

  private onResize = debounce(
    action(() => {
      if (!this.parentRef) {
        return
      }
      const size = this.parentRef.getBoundingClientRect()
      this.size = {
        width: size.width,
        height: size.height,
        columns: getNumberOfOccupancyColumns(size.width),
      }
    }),
    200,
  )

  private refetchRoomOccupancy = async (roomId: string, time: Dayjs) => {
    const room = hermes.getFromStore<IRoom>(
      `/api/${this.context.instance.id}/accommodations/rooms/${roomId}`,
      false,
    )
    if (!room) {
      return
    }
    const occupancy = this.props.occupancies.resources?.find((o) => o.id === room.id)
    if (!occupancy) {
      return
    }
    const data = await hermes.indexOnceNew<IOccupancy>(
      `/api/${
        this.context.instance.id
      }/accommodations/occupancies?queryAt=${encodeURIComponent(
        time.toISOString(),
      )}&roomId=${room.id}`,
    )
    for (const o of data) {
      if (o.id === room.id) {
        occupancy.capacity = o.capacity
        occupancy.occupied = o.occupied
        occupancy.beds = o.beds
      }
    }
    // Force rerender by updating mobx observable prop that is not used otherwise
    // This prop is not synced to the server because the rooms are subscribed as readonly
    runInAction(
      () => (room.createdAt = dayjs(room.createdAt).add(-1, 'minute').toISOString()),
    )
  }

  private renderCell = ({ columnIndex, rowIndex, style }) => {
    if (!this.size || !this.props.occupancies.metadata?.queryAt) {
      return <div style={style} />
    }
    const occupancy =
      this.props.occupancies.resources?.[rowIndex * this.size.columns + columnIndex]
    const room = hermes.getFromStore<IRoom>(
      `/api/${this.context.instance.id}/accommodations/rooms/${occupancy?.id}`,
      false,
    )
    if (!occupancy || !room) {
      return <div style={style} />
    }

    return (
      <div style={style} className='pl-4'>
        <ResidentOccupancyRoomTile
          key={room.id}
          room={room}
          compoundId={this.props.occupancies.query.compoundId || ''}
          occupancy={occupancy}
          at={this.props.occupancies.metadata.queryAt}
          refetchRoomOccupancy={this.refetchRoomOccupancy}
        />
      </div>
    )
  }

  @action
  private setParentRef = (ref: HTMLDivElement) => {
    if (!ref) {
      this.parentRef = null
    } else {
      this.parentRef = ref
      const size = this.parentRef.getBoundingClientRect()
      this.size = {
        width: size.width,
        height: size.height,
        columns: getNumberOfOccupancyColumns(size.width),
      }
    }
  }

  render() {
    if (!this.rooms?.resources || !this.props.occupancies.resources) {
      return null
    }

    return (
      <div
        ref={this.setParentRef}
        className='absolute top-0 left-2 right-0 bottom-0 overflow-hidden'
      >
        {this.props.occupancies.resources.length === 0 && (
          <div className='absolute top-0 left-2 right-2 bottom-0 flex'>
            <Callout
              className='my-auto'
              icon='fas fa-filter'
              iconColor='#6b7280'
              title='Keine Ergebnisse für aktuellen Filter'
              subtitle='Es gibt kein Zimmer, das auf Ihren Filter zutrifft'
            />
          </div>
        )}

        {this.size && this.props.occupancies.resources.length > 0 && (
          <Grid
            key={this.props.occupancies.metadata?.key}
            columnCount={this.size.columns}
            columnWidth={Math.round((this.size.width - 24) / this.size.columns)}
            height={this.size.height}
            rowCount={Math.ceil(
              this.props.occupancies.resources.length / this.size.columns,
            )}
            rowHeight={ROOM_TILE_HEIGHT + 16}
            width={this.size.width}
          >
            {this.renderCell}
          </Grid>
        )}
      </div>
    )
  }
}
