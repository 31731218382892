import { Collection } from '@byll/hermes'
import { DAYS } from 'contracts/general/helpers/days'
import { MONTHS_SHORT } from 'contracts/general/helpers/months'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { TodoOverlayList } from './TodoOverlayList'

interface Props {
  lists: Record<
    'overdue' | 'due today' | 'no due date' | 'done',
    Collection<
      ITodoSearchResult,
      any,
      { status: ITodoSearchResultFilter['status']; page: string }
    >
  >
}

export const TodoOverlayContent: React.FC<Props> = observer((props) => {
  const navigate = useNavigate()
  for (const key of Object.keys(props.lists)) {
    if (!props.lists[key].resources) {
      return null
    }
  }

  let count = 0
  for (const key of Object.keys(props.lists)) {
    count += props.lists[key].resources.length
  }

  if (count === 0) {
    const today = dayjs()
    return (
      <div className='border-b border-gray-300 pb-4 mb-4'>
        {/* Caption */}
        <div>
          <span className='font-bold'>Heute</span>
          <span className='text-gray-500 ml-4'>{`${DAYS[today.day()]}. ${today.date()} ${
            MONTHS_SHORT[today.month()]
          }`}</span>
        </div>

        <span className='text-gray-500'>Heute liegen keine Aufgaben vor.</span>
      </div>
    )
  }

  return (
    <div className='text-sm'>
      <TodoOverlayList
        title='Überfällig'
        navigate={navigate}
        todos={props.lists['overdue']}
      />
      <TodoOverlayList
        title='Heute'
        navigate={navigate}
        todos={props.lists['due today']}
      />
      <TodoOverlayList
        title='Ohne Fälligkeit'
        navigate={navigate}
        todos={props.lists['no due date']}
      />
      <TodoOverlayList title='Erledigt' navigate={navigate} todos={props.lists['done']} />
    </div>
  )
})
