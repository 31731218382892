import { RoundIcon } from 'components/RoundIcon'
import { ICompanyExportLohnart } from 'contracts/companies/interfaces/ICompanyExportLohnart'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { LohnartRow } from './LohnartRow'
let idGenerator = 0

interface Props {
  companyId: string
  lohnarten: ICompanyExportLohnart[]
}

@observer
export class LohnartenList extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private addLohnart = () => {
    this.props.lohnarten.unshift({
      id: String(--idGenerator),
      companyId: this.props.companyId,
      type: '' as any,
      scope: 'alle',
      lohnartId: null as any,
      schichtzulageId: null,
      konto: null as any,
    })
  }

  @action private deleteLohnart = (id: string) => {
    for (let i = this.props.lohnarten.length - 1; i >= 0; i--) {
      if (this.props.lohnarten[i].id !== id) {
        continue
      }
      this.props.lohnarten.splice(i, 1)
      break
    }
  }

  private mapLohnart = (lohnart: ICompanyExportLohnart) => (
    <LohnartRow key={lohnart.id} lohnart={lohnart} onDelete={this.deleteLohnart} />
  )

  render() {
    return (
      <div className='flex-auto relative'>
        <div className='f3 truncate mb-3 text-lg pt-[7px] -mt-4'>
          Lohnarten
          <RoundIcon
            icon='fa fa-plus'
            onClick={this.addLohnart}
            style={{ marginLeft: 8, top: -3 }}
          />
        </div>
        {this.props.lohnarten.map(this.mapLohnart)}
        {this.props.lohnarten.length === 0 && (
          <span className='text-gray-500'>Derzeit keine Lohnarten hinterlegt</span>
        )}
      </div>
    )
  }
}
