import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Message } from 'components/Message'
import { Button } from 'components/Form/components/Button'
import { AppContext } from 'services/connection/models/AppContext'
import { DocumentDropzone } from 'modules/Documents/components/DocumentDropzone'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { SmallDropzoneView } from 'modules/Documents/components/SmallDropzoneView'
import axios, { AxiosProgressEvent } from 'axios'
import { sleep } from 'helpers/sleep'
import { DialogOverlayArea } from 'components/Dialog/components/DialogOverlaySpinner/components/DialogOverlayArea'
import { CircularUploadProgress } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/CircularUploadProgress'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { Spinner } from 'components/Spinner'
import { IUknPage } from 'contracts/costCoverages/interfaces/IUknPage'
import { months } from 'components/Form/components/InputMonth'
import { RoundIcon } from 'components/RoundIcon'
import { dayjs } from 'helpers/dayjs'
import { download } from 'helpers/download'

interface Props {
  onClose: (val?: any) => void
}

@observer
export class UploadBatchUknDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  // upload (batch pdf), processing (detect qr code on every page and assign to resident), done (show result)
  @observable private view: 'upload' | 'processing' | 'done' = 'upload'
  @observable private error: string | null = null
  @observable private concat: string | 'processing' | null = null // string: uuid for download, processing: creating concat, null: not applicable
  @observable private upload: { percentage: number } | null = null
  @observable private pages: IUknPage[] = []
  private mounted = true
  private dialogRef: any = null
  private cancelUpload: (() => void) | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancelUpload?.()
  }

  private onUpload = async (doc: IDocumentMetadata & { file: Blob }) => {
    if (this.upload) {
      return
    }
    const form = new FormData()
    form.append('file', doc.file)

    const source = axios.CancelToken.source()
    this.cancelUpload = () => source.cancel('Canceled by user')
    const uploadPromise = axios.post(`/api/${this.context.instance.id}/pdf/split`, form, {
      cancelToken: source.token,
      onUploadProgress: action((event: AxiosProgressEvent) => {
        if (event.total && event.loaded / event.total <= 1 && this.upload) {
          // Start at 5% to visualize pending upload for files that are scheduled to upload.
          this.upload.percentage = 0.05 + 0.95 * (event.loaded / event.total)
        }
      }),
    })
    try {
      runInAction(() => {
        this.upload = { percentage: 0.05 }
        this.error = null
      })
      const response = (await uploadPromise) as any
      this.cancelUpload = null
      runInAction(() => {
        if (this.upload) {
          this.upload.percentage = 1
        }
      })
      await sleep(300)

      // Next steps
      runInAction(() => {
        this.pages = response.data.pages.map((page, i) => ({
          id: page,
          name: '',
          pageNumber: i + 1,
          payer: '',
          aktenzeichen: '',
          month: '',
          status: 'pending',
        }))
        this.view = 'processing'
        this.upload = null
        this.error = null
      })
      for (let p = 0; p < this.pages.length && p < 5; p++) {
        this.onProcess()
      }
    } catch (e: any) {
      if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
        // Already handeled
      } else {
        runInAction(() => {
          this.upload = null
          this.error =
            e?.response?.data?.id === ConflictError.id
              ? e.response.data.message
              : 'Beim Upload ist ein Fehler aufgetreten'
        })
      }
    }
  }

  private onProcess = async () => {
    if (!this.mounted || this.view === 'done') {
      return
    }
    // Get next pending page for processing
    const page = this.pages.find((p) => p.status === 'pending')
    if (!page) {
      if (!this.pages.find((p) => p.status === 'processing')) {
        runInAction(() => (this.view = 'done'))
        setTimeout(() => this.scrollToBottom(), 400)
      }
      return
    }
    runInAction(() => (page.status = 'processing'))
    this.scrollToBottom()
    try {
      const response: any = await axios.post(
        `/api/${this.context.instance.id}/pdf/qr`,
        { id: page.id, type: 'cost coverage signature' },
        { timeout: 20000 },
      )
      const month = dayjs(response.data.month, 'YYYY-MM')
      runInAction(() => {
        page.name = response.data.name
        page.payer = response.data.payer
        page.aktenzeichen = response.data.aktenzeichen
        page.month = `${months[month.month()]} ${month.year()}`
        page.status = 'done'
      })
    } catch (e: any) {
      runInAction(() => {
        page.status = 'error'
        page.name =
          e?.response?.data?.id === ConflictError.id
            ? e.response.data.message
            : 'Leider wurde kein gültiger QR Code erkannt'
      })
    }
    this.onProcess()
  }

  private onConcat = async (e) => {
    e.preventDefault()
    runInAction(() => (this.concat = 'processing'))
    try {
      const files = this.pages
        .filter((p) => p.status === 'error')
        .map((p) => ({
          id: p.id,
          name: `Seite_${String(p.pageNumber).padStart(4, '0')}.pdf`,
        }))
      const response: any = await axios.post(
        `/api/${this.context.instance.id}/download/zip`,
        { files },
      )
      runInAction(() => (this.concat = response.data.id))
    } catch (_e) {
      runInAction(() => (this.concat = null))
      alert('Das ZIP Archiv konnte leider nicht erstellt werden.')
    }
  }

  private onDownload = () => {
    download(`/api/${this.context.instance.id}/download/files/${this.concat}`)
  }

  private setRef = (ref: HTMLDivElement | null) => {
    if (!ref) {
      return
    }
    this.dialogRef = ref.parentElement?.parentElement?.parentElement ?? null
  }

  private scrollToBottom = () => {
    if (this.dialogRef && this.mounted) {
      try {
        this.dialogRef.scrollTo({ top: this.dialogRef.scrollHeight, behavior: 'smooth' }) // .scrollTop = this.dialogRef.scrollHeight
      } catch (_e) {
        /* */
      }
    }
  }

  render() {
    let done = 0
    if (this.view === 'done') {
      done = this.pages.filter((p) => p.status === 'done').length
    }

    return (
      <>
        <div
          className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'
          ref={this.setRef}
        >
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              UKN Sammeldokument hochladen
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        {this.view === 'upload' && (
          <div className='relative mt-12'>
            <DocumentDropzone
              scope='resident'
              onSelect={this.onUpload}
              view={SmallDropzoneView}
              accept='application/pdf,.pdf'
              maxMb={90}
            />
            <div className='text-center mb-12'>
              <Button
                color='secondary'
                outline
                onClick={this.props.onClose}
                style={{ width: 162 }}
              >
                Abbrechen
              </Button>
            </div>
          </div>
        )}

        {(this.view === 'processing' || this.view === 'done') && (
          <div className='mt-4 -mx-4 sm:-mx-6'>
            <div className='bg-gray-100 px-6 pb-6 pt-2 border-t border-gray-200'>
              {this.pages.map((page, i) => (
                <PageTile key={i} page={page} />
              ))}
              {this.view === 'done' && done === 0 && (
                <div className={`mt-6 p-6 bg-red-500 text-white rounded-md shadow-md`}>
                  In diesem Sammeldokument konnten leider keine QR Codes erkannt werden.
                  Bitte beachten Sie, dass PDF Seiten nicht gedreht sein dürfen. Sie
                  dürfen also nicht im Querformat vorliegen und auch nicht auf dem Kopf
                  stehen. Zusätzlich muss jedes Blatt Papier so gescannt werden, dass es
                  auf einer eigenen PDF Seite dargestellt wird. Eine Zusammenfassung
                  mehrerer Papierblätter auf einer PDF Seite ist nicht zulässig. Bitte
                  wiederholen Sie den Vorgang, sobald Ihr Sammel-PDF den Vorgaben
                  entspricht.
                </div>
              )}
              {this.view === 'done' && done > 0 && done !== this.pages.length && (
                <div
                  className={`mt-6 p-6 bg-yellow-500 text-white rounded-md shadow-md min-h-[168px] relative`}
                >
                  {this.concat === null && (
                    <>
                      {`Es konnten ${
                        this.pages.filter((p) => p.status === 'done').length
                      } / ${this.pages.length} Seiten erfolgreich zugeordnet werden.`}
                      &nbsp; Wir haben ein ZIP Archiv mit den verbleibenden Seiten
                      erstellt, auf denen kein QR Code erkannt wurde. Dieses können Sie{' '}
                      <a href='/' onClick={this.onConcat} className='underline'>
                        hier
                      </a>{' '}
                      herunterladen, um die verbleibenden Seiten manuell zu verarbeiten.
                    </>
                  )}
                  {this.concat === 'processing' && <Spinner />}
                  {this.concat !== 'processing' && this.concat !== null && (
                    <div className='text-center mt-10'>
                      <Button color='secondary' onClick={this.onDownload}>
                        ZIP Archiv herunterladen
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {this.view === 'done' && done > 0 && done === this.pages.length && (
                <div className={`mt-6 p-6 bg-green-500 text-white rounded-md shadow-md`}>
                  Alle Seiten wurden erfolgreich aufgeteilt, zugeordnet und automatisch in
                  den jeweils richtigen Bewohnerprofilen hinterlegt. Sie können den Dialog
                  jetzt schließen.
                </div>
              )}
            </div>

            <div
              className='py-4 -mb-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200'
              style={{ borderRadius: '0 0 8px 8px' }}
            >
              <Button color='secondary' outline onClick={this.props.onClose}>
                {this.view === 'done' ? 'Schließen' : 'Abbrechen'}
              </Button>
            </div>
          </div>
        )}

        {this.upload && !this.error && (
          <DialogOverlayArea opaque>
            <CircularUploadProgress onCancel={this.props.onClose} upload={this.upload} />
          </DialogOverlayArea>
        )}
      </>
    )
  }
}

@observer
class PageTile extends React.Component<{ page: IUknPage }, {}> {
  private onOpen = () => {
    window.open(`/documents/${this.props.page.id}`, '_blank')
  }

  render() {
    const page = this.props.page
    if (page.status === 'pending') {
      return null
    }

    return (
      <div className='group relative flex bg-white shadow-md h-[70px] mt-4'>
        <div className='flex-[0_0_70px] bg-center relative'>
          {page.status === 'processing' && <Spinner />}
          {page.status === 'done' && (
            <div
              className='bg-green-500 rounded-full text-white text-center'
              style={{ height: 50, width: 50, margin: 10, fontSize: 26, paddingTop: 7 }}
            >
              <i className='fas fa-check' />
            </div>
          )}
          {page.status === 'error' && (
            <div
              className='bg-red-500 rounded-full text-white text-center'
              style={{ height: 50, width: 50, margin: 10, fontSize: 26, paddingTop: 7 }}
            >
              <i className='fas fa-times' />
            </div>
          )}
        </div>
        {page.status === 'done' && (
          <div className='flex-auto truncate'>
            <div className='flex'>
              <div
                style={{ flex: '0 1 auto', margin: '13px 15px 0 5px' }}
                className='truncate'
              >
                {page.name}
              </div>
            </div>
            <div
              className={`text-gray-400 text-sm truncate`}
              style={{ margin: '0 15px 0 5px' }}
            >
              {page.payer}
              &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              {page.aktenzeichen}
              &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              {page.month}
            </div>
          </div>
        )}
        {page.status === 'processing' && (
          <div className='flex-auto'>
            <div
              style={{ flex: '0 1 auto', margin: '23px 15px 0 5px' }}
              className='truncate text-gray-400'
            >
              QR Code Erkennung...
            </div>
          </div>
        )}
        {page.status === 'error' && (
          <div className='flex-auto truncate'>
            <div className='flex'>
              <div
                style={{ flex: '0 1 auto', margin: '13px 15px 0 5px' }}
                className='truncate'
              >
                Die Seite konnte nicht zugeordnet werden.
              </div>
            </div>
            <div
              className={`text-gray-400 text-sm truncate`}
              style={{ margin: '0 15px 0 5px' }}
            >
              {page.name}
            </div>
          </div>
        )}

        {page.status === 'done' && (
          <div className='idden group-hover:block'>
            <RoundIcon
              tooltip='Vorschau'
              icon='fas fa-external-link-alt'
              onClick={this.onOpen}
              style={{ position: 'absolute', top: 20, right: 10 }}
            />
          </div>
        )}

        <span className='absolute -top-2 -right-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white'>
          {`Seite ${page.pageNumber}`}
        </span>
      </div>
    )
  }
}
