import { XCircleIcon } from '@heroicons/react/outline'
import { IVisitScanResult } from 'contracts/scan/interfaces/IVisitScanResult'
import * as React from 'react'

interface Props {
  warnings: IVisitScanResult['warnings']
}

const icons: any = {
  generic: (
    <span>
      <i className='fas fa-exclamation-triangle' />
    </span>
  ),
  'fas fa-user-shield': (
    <span>
      <i className='fas fa-user-shield' />
    </span>
  ),
  'fas fa-virus': (
    <span>
      <i className='fas fa-virus' />
    </span>
  ),
  'fas fa-store-slash': (
    <span>
      <i className='fas fa-store-slash' />
    </span>
  ),
  'fas fa-hand-paper': (
    <span>
      <i className='fas fa-hand-paper' />
    </span>
  ),
  'fas fa-redo': (
    <span>
      <i className='fas fa-redo' />
    </span>
  ),
}

export const ScanWarnings: React.FC<Props> = (props) => (
  <div className='mb-8'>
    <div className={`rounded-md px-4 py-3 bg-red-500 mb-4 text-white`}>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <XCircleIcon className='h-5 w-5 text-white' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          {props.warnings.length === 1
            ? 'Es liegt eine Warnung vor'
            : 'Es liegen Warnungen vor'}
        </div>
      </div>
    </div>

    {props.warnings.map((w, i) => (
      <div key={i} className='bg-white rounded-md shadow-md px-4 py-3 mb-4'>
        {icons[w.icon] || icons.generic} <span className='ml-2'>{w.message}</span>
      </div>
    ))}
  </div>
)
