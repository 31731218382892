import * as React from 'react'
import { Model } from '../../../../../../../../../../components/Form/Model'
import { isTime } from 'contracts/general/helpers/isTime'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { z } from 'zod'
import { dayjs } from 'helpers/dayjs'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { AppContext } from 'services/connection/models/AppContext'
import { Message } from 'components/Message'
import { InputDate } from 'components/Form/components/InputDate'
import { InputText } from 'components/Form/components/InputText'
import { InputSelect } from 'components/Form/components/InputSelect'
import { billingStatusOptions } from '../InternalResidenceForm/components/InternalResidenceFormStep3'
import { InputDocument } from 'components/Form/components/InputDocument'
import { Button } from 'components/Form/components/Button'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'

interface BillingStatus {
  status: '' | 'Statuswechsler' | 'Selbstzahler' | 'Aufnahmeverfügung fehlt'

  beginDate: string | null
  beginTime: string
  endDate: string | null
  endTime: string
  hasEnd: boolean

  documentId: string | null
  dateOfNoticeCreation: string | null
  avDate: string | null
}

interface Props {
  resident: IResidentSearchResult
  onClose: (val?: any) => void
}

@observer
export class ChangeBillingStatusForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disposers: Disposer[] = []
  private readonly model: Model<BillingStatus> // Label contains the room path + label
  @observable private saving = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    const validator = z.object({
      beginDate: z.string(),
      beginTime: z.string().refine(isTime),
      endDate: z
        .union([z.string(), z.null()])
        .refine((val) => !this.model.values.hasEnd || val !== null),
      endTime: z.string().refine((val) => !this.model.values.hasEnd || isTime(val)),
    })

    const now = dayjs()
    this.model = new Model(
      {
        status: '',
        beginDate: now.format('YYYY-MM-DD'),
        beginTime: now.format('HH:mm'),
        endDate: null,
        endTime: '',
        hasEnd: false,
        documentId: null,
        dateOfNoticeCreation: null,
        avDate: null,
      },
      validator,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.model.values.beginDate,
        (beginDate) => {
          const bookings = this.props.resident.data.bookings
          if (!beginDate || !bookings) {
            return
          }
          const begin = dayjs(beginDate)
          for (let i = bookings.length - 1; i >= 0; i--) {
            if (bookings[i].type !== 'internal-residence') {
              continue
            }
            // Find first booking with beginAt >= beginDate
            if (begin.isSame(bookings[i].beginAt, 'day')) {
              this.model.values.beginTime = dayjs(bookings[i].beginAt).format('HH:mm')
              return
            }
          }
        },
        { fireImmediately: true },
      ),
    )

    this.disposers.push(
      reaction(
        () => this.model.values.endDate,
        (endDate) => {
          const bookings = this.props.resident.data.bookings
          if (!this.model.values.hasEnd || !endDate || !bookings) {
            return
          }
          const end = dayjs(endDate)
          for (let i = 0; i < bookings.length; i++) {
            if (bookings[i].type !== 'internal-residence') {
              continue
            }
            // Find last booking with endAt <= endDate
            if (bookings[i].endAt && end.isSame(bookings[i].endAt, 'day')) {
              this.model.values.endTime = dayjs(bookings[i].endAt).format('HH:mm')
              return
            }
          }
        },
        { fireImmediately: true },
      ),
    )

    this.disposers.push(
      reaction(
        () => this.model.values.documentId,
        (documentId) => {
          this.model.values.dateOfNoticeCreation = documentId
            ? dayjs().format('YYYY-MM-DD')
            : null
        },
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action
  private toggleHasEnd = () => {
    this.model.values.hasEnd = !this.model.values.hasEnd
    this.model.values.endDate = null
    this.model.values.endTime = ''
  }

  private onSubmit = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    const begin = dayjs(this.model.values.beginDate + ' ' + this.model.values.beginTime)
    const end = this.model.values.hasEnd
      ? dayjs(this.model.values.endDate + ' ' + this.model.values.endTime)
      : null
    if (end && !begin.isBefore(end)) {
      runInAction(
        () =>
          (this.error = 'Der Endzeitpunkt darf nicht vor dem Anfangszeitpunkt liegen.'),
      )
      return
    }
    try {
      runInAction(() => (this.saving = true))
      await hermes.create(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}/billingStatus`,
        {
          status: this.model.values.status,
          beginAt: begin.toISOString(),
          endAt: end?.toISOString() ?? null,
          documentId: this.model.values.documentId,
          dateOfNoticeCreation: this.model.values.dateOfNoticeCreation,
          avDate: this.model.values.avDate,
        },
      )
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.saving = false
        this.error =
          e?.id === ConflictError.id
            ? e.message
            : 'Die Statusänderung konnte nicht durchgeführt werden.'
      })
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Neuer Status
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message className='my-3' color='danger'>
            {this.error}
          </Message>
        )}

        <div className='relative grid grid-cols-4 gap-4 mt-4' id={this.model.id}>
          <InputSelect
            model={this.model}
            name='status'
            label='Gebührenstatus'
            className='col-span-4'
            options={billingStatusOptions}
          />

          <InputDate
            model={this.model}
            name='beginDate'
            label='Ab'
            placeholder='DD.MM.JJJJ'
          />
          <InputText
            model={this.model}
            name='beginTime'
            tooltip='Uhrzeit benötigt'
            placeholder='hh:mm'
          />
          <div className='relative'>
            <InputDate
              disabled={!this.model.values.hasEnd}
              model={this.model}
              name='endDate'
              label='      Bis'
              placeholder='DD.MM.JJJJ'
            />
            <input
              type='checkbox'
              checked={this.model.values.hasEnd}
              onChange={this.toggleHasEnd}
              style={{
                position: 'absolute',
                top: -8,
                left: 12,
                zIndex: 1,
                transform: 'scale(0.9)',
                borderRadius: '4px',
              }}
              className={`border-gray-300 text-indigo-600`}
            />
          </div>
          <InputText
            disabled={!this.model.values.hasEnd}
            tooltip='Uhrzeit benötigt'
            model={this.model}
            name='endTime'
            placeholder='hh:mm'
          />

          <InputDocument
            scope='booking'
            model={this.model}
            name='documentId'
            label={
              this.model.values.status === 'Aufnahmeverfügung fehlt'
                ? 'Aufnahmeverfügung'
                : 'Gebührenbescheid'
            }
            className='col-span-2'
            preview
          />
          {this.model.values.status !== 'Aufnahmeverfügung fehlt' && (
            <InputDate
              model={this.model}
              name='dateOfNoticeCreation'
              label='Erstellt am'
              placeholder='DD.MM.JJJJ'
              className='col-span-2'
            />
          )}
          {this.model.values.status === 'Aufnahmeverfügung fehlt' && (
            <InputDate
              model={this.model}
              name='avDate'
              label='AV Datum'
              placeholder='DD.MM.JJJJ'
              className='col-span-2'
            />
          )}

          <div className='col-span-4 text-right flex place-content-end'>
            <Button color='secondary' outline onClick={this.props.onClose}>
              Abbrechen
            </Button>
            <Button color='purple' className='ml-2' onClick={this.onSubmit}>
              Statusänderung durchführen
            </Button>
          </div>
        </div>

        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
