import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { AppContext } from 'services/connection/models/AppContext'
import { lfgSchutzbeduerftigOptions } from 'modules/Residents/components/CaseRecordForm/constants/lfgSchutzbeduerftigOptions'
import { InputMultiSelect } from 'components/Form/components/InputMultiSelect'

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

@observer
export class SelectSchutzbedarf extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private reset = () => {
    this.props.model.values.lfgSchutzbeduerftig = ''
  }

  render() {
    let label = 'Schutzbedarf'
    let color: string = 'text-gray-500'
    if (this.props.model.values.lfgSchutzbeduerftig) {
      const values = this.props.model.values.lfgSchutzbeduerftig.split(',')
      label = values[0] + (values.length > 1 ? ` +${values.length - 1}` : '')
      color = 'text-blue-500 max-w-[200px] truncate'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputMultiSelect
                model={this.props.model}
                name='lfgSchutzbeduerftig'
                label='Schutzbedürftig'
                options={lfgSchutzbeduerftigOptions}
              />
              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
