import { detectOverflow } from '@popperjs/core'
import { uniqueId } from 'helpers/uniqueId'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { usePopper } from 'react-popper'
import { Model } from '../../Model'
import classes from './classes.module.scss'

export const target: { get: () => string; set: (value: string) => void } =
  observable.box('-')

export interface InjectedProps {
  model: Model<any>
  name: string
  setRef: (HTMLElement) => void
  id?: string
  disabled?: boolean
  onFocus?: (event) => void
  onBlur?: (event) => void
  onMouseOver?: (event) => void
  onMouseOut?: (event) => void
  onChange?: (event) => void
}

export interface TooltipProps {
  model: Model<any>
  name: string
  tooltip?: string | ((error: boolean) => string | null) // If string: only visible on error. If function: -> Displayed all the time (if on focus/hover). Function can decide what to return depending on error state or non-error state.
  id?: string
  disabled?: boolean
  onFocus?: (event) => void
  onBlur?: (event) => void
  onMouseOver?: (event) => void
  onMouseOut?: (event) => void
  onChange?: (event) => void
  setRef?: (HTMLElement) => void
}

const modifiers = [
  {
    name: 'preventOverflow',
    options: {
      mainAxis: false, // true by default
      rootBoundary: 'document',
    },
  },
  {
    name: 'offset',
    options: {
      offset: [0, 5],
    },
  },
]

export const tooltip = <OriginalProps extends {}>(
  Component:
    | React.ComponentClass<OriginalProps & InjectedProps>
    | React.FunctionComponent<OriginalProps & InjectedProps>,
): React.ComponentClass<OriginalProps & TooltipProps> => {
  class Tooltip extends React.Component<OriginalProps & TooltipProps, {}> {
    private readonly id: string
    @observable.ref private ref: HTMLInputElement | null = null
    @observable private hasFocus = false
    @observable private hasMouseOver = false
    @computed private get isOpen(): boolean {
      return target.get() === this.id
    }

    constructor(props: OriginalProps & TooltipProps) {
      super(props)
      this.id = props.id || uniqueId('tooltip-')
      makeObservable(this)
    }

    componentWillUnmount() {
      if (this.isOpen) {
        runInAction(() => {
          target.set('-')
        })
      }
    }

    @action
    private onFocus = (event) => {
      this.hasFocus = true
      this.updateTooltipVisibility()
      this.props.onFocus?.(event)
    }

    @action
    private onBlur = (event) => {
      this.hasFocus = false
      this.props.model.touched.set(this.props.name, true)
      this.updateTooltipVisibility()
      this.props.onBlur?.(event)
    }

    @action
    private onChange = (event) => {
      this.updateTooltipVisibility()
      this.props.onChange?.(event)
    }

    @action
    private onMouseOver = (event) => {
      this.hasMouseOver = true
      this.updateTooltipVisibility()
      this.props.onMouseOver?.(event)
    }

    @action
    private onMouseOut = (event) => {
      this.hasMouseOver = false
      this.updateTooltipVisibility()
      this.props.onMouseOut?.(event)
    }

    @action
    private updateTooltipVisibility = () => {
      if (this.props.disabled) {
        return
      } // Skip tooltip update for disabled elements
      if (!this.props.tooltip) {
        return
      } // Skip tooltip update for elements without tooltip message
      if (!this.hasFocus && !this.isOpen && target.get() !== '-') {
        return
      }
      target.set(this.hasFocus || this.hasMouseOver ? this.id : '-')
    }

    @action
    private setRef = (ref: HTMLInputElement) => {
      this.ref = ref
      this.props.setRef?.(ref)
    }

    render(): JSX.Element {
      const { name, model, tooltip, children, ...attributes } = this.props
      const validator = model.validators.get(name)
      const error =
        !(validator?.safeParse(model.values[name]).success ?? true) &&
        !!model.touched.get(name)
      const tip = getTooltip(error, tooltip)

      return (
        <Component
          {...(attributes as any)}
          id={this.id}
          name={name}
          model={model}
          setRef={this.setRef}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
        >
          {tip && this.isOpen && (
            <Popover error={error} target={this.ref} tooltip={tip} />
          )}
        </Component>
      )
    }
  }

  return observer(Tooltip)
}

function getTooltip(
  error: boolean,
  tooltip?: string | ((error: boolean) => string | null),
): string | null {
  if (typeof tooltip === 'string' && tooltip && error) {
    return tooltip
  }
  if (typeof tooltip === 'function') {
    return tooltip(error) || null
  }
  return null
}

interface PopoverProps {
  tooltip: string
  error: boolean
  target: HTMLInputElement | null
}

const Popover: React.FC<PopoverProps> = (props) => {
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(null)
  const [overflow, setOverflow] = React.useState<boolean>(false) // Popover overflows viewport on x-axis
  const { styles, attributes, state } = usePopper(props.target, popperElement, {
    placement: 'right',
    modifiers: [...modifiers, { name: 'arrow', options: { element: arrowElement } }],
  })
  if (overflow) {
    return null
  }
  if (state) {
    const overflowRect = detectOverflow(state, {})
    if (overflowRect.right > 0 || overflowRect.left > 0) {
      // The popover is hidden, if it does not completely fit the viewport. It is not turned on again
      // after it could possibly fit again. Otherwise the screen keeps toggling which looks weird.
      setOverflow(true)
      return null
    }
  }

  return (
    <div
      className={`${classes.tooltip} ${
        props.error ? 'bg-red-300 border-red-500' : 'bg-indigo-300 border-indigo-500'
      } border px-2 z-10 text-sm rounded-md pointer-events-none whitespace-nowrap`}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      {props.tooltip}
      <div
        className={classes.arrow}
        ref={setArrowElement}
        style={styles.arrow}
        data-popper-arrow
      />
    </div>
  )
}
