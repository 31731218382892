import { Collection, Resource } from '@byll/hermes'
import { ResourceChange } from '@byll/hermes/lib/interfaces/ResourceChange'
import axios from 'axios'
import { Button } from 'components/Form/components/Button'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {}

interface ITest {
  id: string
  counter: number
}

@observer
export class HermesCollectionChangeHandlerExample extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly collection: Collection<ITest>
  private readonly filter = observable({ limit: 2 })
  private counter = 0

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.collection = new Collection(
      `/api/${context.instance.id}/test-change-handlers`,
      this.filter,
      {
        onChange: this.onChange,
        onAdd: this.onAdd,
        onRemove: this.onRemove,
      },
    )
  }

  componentDidMount() {
    this.collection.init()
  }

  componentWillUnmount(): void {
    this.collection.dispose()
  }

  private onChange = (changes: ResourceChange<ITest>[]) => {
    console.log('Got changes (collection)', changes)
  }

  private onAdd = (resources: Resource<ITest>[]) => {
    console.log(
      'onAdd',
      resources.map((r) => r.id),
    )
  }

  private onRemove = (resources: Resource<ITest>[]) => {
    console.log(
      'onDelete',
      resources.map((r) => r.id),
    )
  }

  private add = () => {
    this.counter++
    void axios.post(`/api/${this.context.instance.id}/test-change-handlers`, {
      counter: 2,
    })
  }

  render() {
    return (
      <div className='border border-black my-10'>
        <div>Test collection change handlers</div>
        {this.collection.resources?.map((row) => <Row key={row.id} row={row} />)}
        <Button color='primary' onClick={this.add}>
          Add
        </Button>
      </div>
    )
  }
}

export const Row: React.FC<{ row: Resource<ITest> }> = observer((props) => {
  const context = React.useContext(AppContext)
  return (
    <div>
      {`ID ${props.row.id} - Counter ${props.row.data?.counter}`}
      <Button
        color='danger'
        onClick={() =>
          void axios.delete(
            `/api/${context.instance.id}/test-change-handlers/${props.row.id}`,
          )
        }
      >
        Delete
      </Button>
      <Button
        color='primary'
        onClick={() =>
          void axios.patch(
            `/api/${context.instance.id}/test-change-handlers/${props.row.id}`,
            { counter: (props.row.data?.counter || 0) + 1 },
          )
        }
      >
        +1
      </Button>
    </div>
  )
})
