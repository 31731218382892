import { Card } from 'components/Card'
import { Button } from 'components/Form/components/Button'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { InventoryItemTopBar } from '../../InventoryItem/components/InventoryItemTopBar'

interface Props {
  folder: IInventoryFolder
  navigate: (url: string) => void
  setEdit: (edit: boolean) => void
}

export class InventoryFolderPreview extends React.Component<Props, {}> {
  static contextType = AppContext

  render() {
    return (
      <>
        <InventoryItemTopBar folder={this.props.folder} />
        <CardColumn>
          <Card>
            <div className='p-6'>
              <h3 className='text-xl text-gray-500'>{this.props.folder.label}</h3>
              {this.context.permissions.inventory_manageItems > 0 && (
                <Button
                  className='mt-4'
                  onClick={() =>
                    this.props.navigate(`/inventory/folders/${this.props.folder.id}/new`)
                  }
                >
                  Gegenstand hinzufügen
                </Button>
              )}
              {this.context.permissions.inventory_manageFolders > 0 && (
                <Button
                  className='mt-4 ml-2'
                  outline
                  onClick={() => this.props.setEdit(true)}
                >
                  Ordner bearbeiten
                </Button>
              )}
            </div>
          </Card>
        </CardColumn>
      </>
    )
  }
}
