import { observer } from 'mobx-react'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {}

export const NavbarUserIcon: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  return (
    <img
      className='h-9 w-9 rounded-full'
      src={getUserImageSrc(
        context.instance.id,
        context.user.imageId,
        context.user.sex,
        'thumbnail',
      )}
      alt=''
    />
  )
})
