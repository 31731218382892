import { observer } from 'mobx-react-lite'
import { AppReconnect } from 'modules/ErrorPages/AppReconnect'
import * as React from 'react'
import { Route, Routes } from 'react-router'
import { AppStatus } from 'services/connection/helpers/getAppStatus'
import { App } from './App'
import { Pdf } from './modules/Pdf'

interface Props {
  appStatus: AppStatus
}

export const GlobalRoutes: React.FC<Props> = observer((props) => {
  let status: 'disconnected' | 'maintenance' | 'app' = 'app'
  if (
    (!props.appStatus.connected && props.appStatus.maintenance !== false) ||
    props.appStatus.maintenance === true
  ) {
    status = 'maintenance'
  } else if (!props.appStatus.connected) {
    status = 'disconnected'
  }

  let component: JSX.Element
  switch (status) {
    case 'maintenance':
      // Server serves maintenance page from the moment the counter starts
      // counting downwards. First the mnt page is delivered by node app,
      // then the (exact same) mnt page is delivered by nginx. So once the
      // counter finished (or even during the counter), the mnt page is
      // displayed on reload.
      component = <Reload />
      break
    case 'disconnected':
      component = <AppReconnect appStatus={props.appStatus} />
      break
    default:
      component = <App appStatus={props.appStatus} />
  }

  return (
    <Routes>
      {/* Do not add any further routes here. Routes of
          logged in user should be added in App.tsx */}

      <Route path='/pdf/*' element={<Pdf appStatus={props.appStatus} />} />
      <Route path='/*' element={component} />
    </Routes>
  )
})

export const Reload: React.FC<{}> = () => {
  React.useEffect(() => window.location.reload(), [])
  return null
}
