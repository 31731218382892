import { hermes, Resource } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { ITodoMessage } from 'contracts/todos/interfaces/ITodoMessage'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { z } from 'zod'

interface Props {
  ticket: ITodoSearchResult
  summary: Resource<ITodoMessage> | undefined
  isAssignedToCurrentUser: boolean
  disabled: boolean
}

@observer
export class TicketDialogSummary extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<{ summary: string }>
  @observable private loading: boolean = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      {
        summary: props.summary?.data?.label || '',
      },
      z.object({ summary: z.string().min(1) }),
    )
  }

  private submit = async () => {
    if (this.props.disabled) {
      return
    }
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => {
      this.error = null
      this.loading = true
    })
    try {
      if (this.props.summary) {
        await hermes.patch(
          `/api/${this.context.instance.id}/todos/${this.props.ticket.id}/todoMessages/${this.props.summary.id}`,
          {
            label: this.model.values.summary,
          },
        )
      } else {
        await hermes.create(
          `/api/${this.context.instance.id}/todos/${this.props.ticket.id}/todoMessages`,
          {
            label: this.model.values.summary,
            type: 'summary',
          },
        )
      }
      runInAction(() => (this.loading = false))
    } catch (_e) {
      runInAction(() => {
        this.error = 'Beim Speichern der Zusammenfassung ist ein Fehler aufgetreten.'
        this.loading = false
      })
    }
  }

  render() {
    if (!this.props.isAssignedToCurrentUser && !this.props.summary) {
      return null
    }
    const canEdit =
      ((!this.props.summary ||
        (!this.props.ticket.doneDate && this.props.isAssignedToCurrentUser)) &&
        (!this.props.summary ||
          (!this.props.disabled && this.props.isAssignedToCurrentUser))) ||
      this.context.permissions.dashboard_tickets === 3

    return (
      <div className='rounded-sm shadow bg-white flex relative mb-6'>
        <div className='flex-[0_0_268px] pt-11 pl-11 leading-7 mb-11'>
          <div>Zusammenfassung</div>
          <div className='text-gray-400 text-xs'>
            Schreiben Sie eine detaillierte
            <br />
            Zusammenfassung der Erledigung.
          </div>
        </div>

        <div id={this.model.id} className='flex-auto pt-11 py-8 pr-6'>
          {this.error && (
            <Message className='mb-4' color='danger'>
              {this.error}
            </Message>
          )}
          {!canEdit ? (
            <div>{this.props.summary!.data?.label}</div>
          ) : (
            <InputTextarea className='mb-4' model={this.model} name='summary' rows={5} />
          )}
          {canEdit && (
            <Button
              className='float-right'
              color='primary'
              onClick={this.submit}
              loading={this.loading}
            >
              {!this.props.disabled ? 'Speichern' : 'Abschicken'}
            </Button>
          )}
        </div>
      </div>
    )
  }
}
