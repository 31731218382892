import * as React from 'react'
import styles from './styles.module.scss'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IDocumentationExportEntry } from 'contracts/residents/interfaces/IDocumentationExportEntry'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { dayjs } from 'helpers/dayjs'

interface Props {
  residents: IResident[]
  compound: ICompound
  documentations: IDocumentationExportEntry[]
  instanceId: string
  attachments: { [documentationId: string]: [number, number] } // documentation => [2, 5] -> Attachments 2-5 belong to this documentation.
  readOnly?: boolean
}

export const DokuExport: React.FC<Props> = ({
  residents,
  compound,
  documentations,
  attachments,
  readOnly,
}) => {
  React.useEffect(setReadyFlagWhenRenderingComplete, [])

  return (
    <div
      className={styles.page}
      style={
        readOnly ? { paddingTop: 0, paddingBottom: 0, minHeight: '1060px' } : undefined
      }
    >
      <div className='p-4 text-center text-lg'>
        Export Dokumentation
        <br />
        {compound.label}
        <br />
        {`Stand ${dayjs().format('DD.MM.YYYY')}`}
        <br />
        <br />
      </div>
      <div className='border border-black p-3'>
        <div className='text-xs text-gray-500'>Betroffene Bewohner</div>
        <div className='text-sm'>
          {residents.map((r) => r.firstName + ' ' + r.lastName).join(', ')}
        </div>
      </div>

      <div className='text-sm flex flex-col border-l border-r border-t border-black mt-4'>
        {documentations.map((doku) => (
          <div key={doku.id} className='border-b border-black flex'>
            <div className='pl-3 pt-3 flex-[0_0_90px] text-xs font-bold'>
              {dayjs(doku.date).format('DD.MM.YYYY')}
              <br />
              {attachments[doku.id] && (
                <span className='text-gray-500'>
                  <span className='mr-1'>
                    <i className='fas fa-paperclip' />
                  </span>
                  {attachments[doku.id][0] === attachments[doku.id][1]
                    ? `${attachments[doku.id][0]}`
                    : `${attachments[doku.id][0]}-${attachments[doku.id][1]}`}
                </span>
              )}
            </div>

            <div className='p-3 border-l border-black flex-auto'>
              <div className='text-xs text-gray-500'>
                {doku.type}
                &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                {`Betrifft: ${doku.residents
                  .map((r) => r.firstName + ' ' + r.lastName)
                  .join(', ')}`}
                {doku.topics.length > 0 && (
                  <span>
                    &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                    {doku.topics.length === 1 ? 'Thema: ' : 'Themen: '}
                    {doku.topics.join(', ')}
                  </span>
                )}
              </div>
              <div>{doku.notes}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
