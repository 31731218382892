import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Message } from 'components/Message'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { AppContext } from 'services/connection/models/AppContext'
import { ICostCoverageMeta } from 'contracts/costCoverages/interfaces/ICostCoverageMeta'
import { download } from 'helpers/download'
import axios from 'axios'
import { CircularProgressbar } from 'components/CircularProgressbar'

interface Props {
  costCoverageIds: string[]
  month: string
  onClose: (val?: any) => void
  model: Model<{ selected: Map<string, ICostCoverageMeta | null> }>
}

@observer
export class CreateBatchUknDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private progress: number | null = 0
  @observable private token: string | null = null
  @observable private error: string | null = null
  private timer

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.create()
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  private create = async () => {
    try {
      this.timer = setInterval(
        action(() => {
          this.progress = (this.progress || 0) + 0.05
          if (this.progress > 1) {
            this.progress = 1
          }
        }),
        200,
      )
      const response: any = await axios.post(`/api/${this.context.instance.id}/reports`, {
        options: {
          residentId: '0',
          signatureIds: this.props.costCoverageIds.map(
            (id) => `${id}-${this.props.month}`,
          ),
        },
        type: 'cost-coverage-signature',
      })
      const token = response?.data.token
      if (typeof token !== 'string') {
        throw new Error('Invalid return value')
      }
      clearInterval(this.timer)
      runInAction(() => {
        this.token = token
        this.progress = null
      })
    } catch (_e) {
      clearInterval(this.timer)
      runInAction(() => {
        this.error = 'Das Dokument konnte leider nicht erstellt werden.'
        this.progress = null
      })
    }
  }

  private download = () => {
    if (!this.token) {
      return
    }
    download(
      `/api/${this.context.instance.id}/reports?token=${encodeURIComponent(
        this.token,
      )}&fileName=${encodeURIComponent('Unterkunftsnachweise.pdf')}`,
    )
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Unterkunftsnachweise erstellen
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        {!this.error && (
          <div className='relative mt-4 h-[200px]'>
            {this.progress !== null && (
              <div className='px-40 pt-6'>
                <CircularProgressbar
                  percentage={this.progress === 1 ? undefined : this.progress}
                  strokeWidth={4}
                />
              </div>
            )}
            {this.progress === null && (
              <Message color='primary'>
                Das Sammeldokument wurde erstellt. Klicken Sie auf "Download", um es
                herunterzuladen.
              </Message>
            )}
          </div>
        )}

        <div className='mt-4 text-center'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            {this.progress !== null ? 'Abbrechen' : 'Schließen'}
          </Button>
          {this.progress === null && this.token && (
            <Button color='success' className='ml-3' onClick={this.download}>
              Download <i className='fas fa-download' />
            </Button>
          )}
        </div>
      </>
    )
  }
}
