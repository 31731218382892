import * as React from 'react'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from '../../Model'
import { classNames } from 'helpers/classNames'
import { hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext } from 'services/connection/models/AppContext'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { isStammBuilding } from 'helpers/isStamm'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { Message } from 'components/Message'
import { Spinner } from 'components/Spinner'
import { CompoundMapper } from './components/CompoundMapper'
import { box } from 'services/box'
import { ShortcutCreateDialog } from './components/ShortcutCreateDialog'
import { BuildingsShortcutsValidator } from 'contracts/users/validators/BuildingsShortcutsValidator'

/**
 * Checkbox list of buildings (Grouped by compounds). Multiple buildings can be selected and will be
 * stored in the model as a comma-separated string of building ids.
 */

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  className?: string
  onlyStamm?: boolean // Show only stamm buildings
  filter?: (building: IBuilding) => boolean
  at?: string | null // ISO date. All compounds that are either not archived or archived after .at are shown. If .at is missing, archived compounds are not shown.
}

export interface DisplayCompound {
  id: string
  label: string
  buildings: IBuilding[]
}

@observer
export class InputBuildingListFoldable extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private compounds: DisplayCompound[] | null = null
  @observable private readonly selected = new Set<string>() // Ids of selected buildings
  @observable private error: string | null = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
    this.disposers.push(
      reaction(
        () => this.props.model.values[this.props.name],
        (value: string) => {
          this.selected.clear()
          const buildingIds = value?.split(',').filter(Boolean) ?? []
          for (const id of buildingIds) {
            this.selected.add(id)
          }
        },
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private load = async () => {
    try {
      const rows =
        hermes.indexFromStore<ICompound>(
          `/api/${this.context.instance.id}/accommodations/compounds`,
        ) ?? []
      const compounds: DisplayCompound[] = []
      const compoundMap = new Map<string, DisplayCompound>()
      for (const row of rows) {
        const compound: DisplayCompound = {
          id: row.id,
          label: row.label,
          buildings: [],
        }
        compoundMap.set(compound.id, compound)
        compounds.push(compound)
      }

      const buildings = await hermes.indexOnceNew<IBuilding>(
        `/api/${this.context.instance.id}/accommodations/buildings`,
      )
      for (const building of buildings) {
        if (compoundMap.has(building.compoundId) && this.isIncluded(building)) {
          compoundMap.get(building.compoundId)!.buildings.push(building)
        }
      }
      setTimeout(
        action(() => {
          this.compounds = compounds.filter((c) => c.buildings.length > 0)
          if (this.compounds.length === 0) {
            this.error = 'Es liegen keine passenden Gebäude vor.'
            this.compounds = null
          }
        }),
        200,
      )
    } catch (_e) {
      runInAction(() => (this.error = 'Die Gebäudeliste konnte nicht geladen werden.'))
    }
  }

  private isIncluded = (building: IBuilding): boolean => {
    if (building.deletedAt && (!this.props.at || building.deletedAt <= this.props.at)) {
      return false
    }
    if (this.props.filter && !this.props.filter(building)) {
      return false
    }
    if (
      this.props.onlyStamm &&
      !this.selected.has(building.id) &&
      !isStammBuilding(building.id)
    ) {
      return false
    }
    return true
  }

  @action
  private selectAll = () => {
    if (!this.compounds) {
      return
    }
    const all = new Set<string>()
    for (const compound of this.compounds) {
      for (const building of compound.buildings) {
        all.add(building.id)
      }
    }
    this.props.model.values[this.props.name] = Array.from(all.values()).join(',')
  }

  @action
  private selectNone = () => {
    this.props.model.values[this.props.name] = ''
  }

  private addShortcut = async () => {
    const model: Model<{ label: string }> = new Model({ label: '' })
    const promise = box.custom(
      <ShortcutCreateDialog model={model} onClose={(id) => promise.close(id)} />,
    )
    const label = await promise
    if (label) {
      let parsedShortcuts
      if (this.context.defaults.buildingsShortcuts) {
        parsedShortcuts = JSON.parse(this.context.defaults.buildingsShortcuts)
      }
      const buildingsShortcuts =
        parsedShortcuts && BuildingsShortcutsValidator.safeParse(parsedShortcuts).success
          ? parsedShortcuts
          : []
      await hermes.patch(
        `/api/${this.context.instance.id}/userDefaults/${this.context.user.id}`,
        {
          buildingsShortcuts: JSON.stringify([
            ...buildingsShortcuts,
            { label, buildingIds: Array.from(this.selected.values()) },
          ]),
        },
      )
    }
  }

  @action
  private selectShortcut = (buildingIds: string[]) => {
    this.props.model.values[this.props.name] = buildingIds.join(',')
  }

  private deleteShortcut = async (s: { label: string; buildingIds: string[] }) => {
    const confirmed = await box.alert(
      'Sind Sie sicher?',
      `Möchten Sie die Kurzwahl ${s.label} wirklich löschen?`,
      { cancel: 'Abbrechen', confirm: 'Ja, Kurzwahl löschen' },
    )
    if (!confirmed) {
      return
    }
    let parsedShortcuts
    if (this.context.defaults.buildingsShortcuts) {
      parsedShortcuts = JSON.parse(this.context.defaults.buildingsShortcuts)
    }
    const buildingsShortcuts =
      parsedShortcuts && BuildingsShortcutsValidator.safeParse(parsedShortcuts).success
        ? parsedShortcuts
        : []
    const newShortcuts = buildingsShortcuts.filter(
      (b: { label: string; buildingIds: string[] }) => b.label !== s.label,
    )
    await hermes.patch(
      `/api/${this.context.instance.id}/userDefaults/${this.context.user.id}`,
      { buildingsShortcuts: JSON.stringify(newShortcuts) },
    )
    runInAction(() => (this.props.model.values[this.props.name] = ''))
  }

  render() {
    if (this.error) {
      return <Message color='danger'>{this.error}</Message>
    }

    if (!this.compounds) {
      return (
        <div className='h-[120px]'>
          <Spinner />
        </div>
      )
    }
    let parsedShortcuts: { label: string; buildingIds: string[] }[] | null = null
    if (this.context.defaults.buildingsShortcuts) {
      parsedShortcuts = JSON.parse(this.context.defaults.buildingsShortcuts)
    }
    return (
      <div className={classNames('relative', this.props.className)}>
        <div className='text-gray-400 text-xs mb-1.5 flex flex-wrap gap-1.5'>
          <div className='flex'>
            <span
              onClick={this.selectAll}
              className='pl-2 pr-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100'
              style={{ borderRadius: '4px 0 0 4px' }}
            >
              Alle auswählen
            </span>
            <span
              onClick={this.selectNone}
              className='pl-1 pr-2 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer'
              style={{ borderRadius: '0 4px 4px 0' }}
            >
              Alle abwählen
            </span>
          </div>
          {parsedShortcuts?.map((s, i) => (
            <span
              key={i}
              className='pl-1 pr-2 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer rounded'
              onClick={() => this.selectShortcut(s.buildingIds)}
            >
              {s.label}
              <span
                className='ml-1 hover:text-red-500'
                onClick={() => this.deleteShortcut(s)}
              >
                <i className='fa fa-times' />
              </span>
            </span>
          ))}
          <span
            className='pl-1 pr-2 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer rounded'
            onClick={this.addShortcut}
          >
            Kurzwahl hinzufügen
          </span>
        </div>
        <div className='flex flex-col gap-4 mt-2'>
          {this.compounds &&
            this.compounds.map((c) => (
              <CompoundMapper
                key={c.id}
                compound={c}
                compounds={this.compounds!}
                model={this.props.model}
                name={this.props.name}
                selected={this.selected}
              />
            ))}
        </div>
      </div>
    )
  }
}
