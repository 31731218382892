import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { InputCompoundList } from 'components/Form/components/InputCompoundList'
import { XIcon } from '@heroicons/react/outline'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  model: Model<any>
  name: string
  onClose: () => void
}

export const DashboardResidentStatsCompoundSelectionDialog: React.FC<Props> = observer(
  ({ model, name, onClose }) => {
    const context = React.useContext(AppContext)
    return (
      <>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='sm:flex sm:items-start'>
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-auto'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Gelände auswählen
            </Dialog.Title>
            <div className='mt-2 text-sm text-gray-500'>
              <InputCompoundList
                model={model}
                name={name}
                onlyStamm={context.permissions.dashboard_residentCounts === 1}
              />
            </div>
          </div>
        </div>
        <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
          <button
            type='button'
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500`}
            onClick={onClose}
          >
            OK
          </button>
        </div>
      </>
    )
  },
)
