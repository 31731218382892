/**
 * Format function for big numbers (represented as strings)
 * This function never converts the string into a number
 * because the number could be too big for javascript number type.
 * Instead the formatting is done purely with string formatting rules.
 * Locale is being taken into consideration.
 * de: 125125.3 => 125.125,3 and 125125 => 125.125
 * @param amount
 */
export function toLocaleNumber(amount: string, _locale?: string) {
  if (typeof (amount as any) !== 'string') {
    return ''
  } // for external modules that don't see the typescript types
  const parts = amount.split('.')
  parts[0] = parts[0].length === 0 ? '0' : parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return parts.length === 2 ? parts.join(',') : parts[0]
}
