import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { classNames } from 'helpers/classNames'
import styles from './styles.module.scss'
import { InputSelectOption } from '../InputSelect'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { Fragment } from 'react'
import { Combobox, Transition } from '@headlessui/react'

const nationalities = observable.box<InputSelectOption[] | null>(null, {
  deep: false,
})

const cache = new Map<string, string>() // id => label
export function getNationality(id: string): string {
  if (cache.has(id)) {
    return cache.get(id)!
  }
  const ns = nationalities.get()
  if (!ns) {
    return ''
  }
  for (const n of ns) {
    if (n.value === id) {
      cache.set(n.value, n.label)
      return n.label
    }
  }
  return ''
}

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  children?: Element
  setRef?: (HTMLInputElement) => void
}

const DropdownIndicator = () => {
  return <div className={styles.dropdownIndicator} />
}

@observer
export class InputNationality extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  @observable private query = ''

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.id = props.id || uniqueId('input-')
  }

  componentDidMount() {
    if (!nationalities.get()) {
      void this.fetchNationalities()
    }
  }

  private fetchNationalities = async () => {
    try {
      runInAction(() => nationalities.set([])) // Prevent other fetch requests from parallel mounted components
      const response = await hermes.getOnceNew<any>(
        `/api/${this.context.instance.id}/nationalities`,
      )
      runInAction(() =>
        nationalities.set(
          response.resources.map((r) => ({ value: r.id, label: r.label })),
        ),
      )
    } catch (_e) {
      runInAction(() => nationalities.set(null)) // Allow other try on next mount
    }
  }

  @action
  private onChange = (item: InputSelectOption | null) => {
    this.props.model.values[this.props.name] = item?.value ?? null
  }

  @action
  setQuery = (value: string) => {
    this.query = value
    if (!value) {
      this.props.model.values[this.props.name] = null
    }
  }

  render() {
    const { id, name, model, label, className, children, ...attributes } = this.props
    const options = (nationalities.get() ?? []).filter((n) =>
      n.label.toLowerCase().includes(this.query.toLowerCase()),
    )
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md pr-10'

    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md pr-10'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    if (!nationalities.get()) {
      return (
        <div className={classNames('relative', className)}>
          {label && (
            <label
              htmlFor={id}
              className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
              style={{ left: 9, top: -7 }}
            >
              {label}
            </label>
          )}
          <input
            type='text'
            className='block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
            maxLength={255}
            id={id}
            disabled
          />
          {children}
        </div>
      )
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <Combobox
          value={
            model.values[name] && nationalities.get()
              ? nationalities.get()!.find((o) => o.value === model.values[name])
              : null
          }
          onChange={this.onChange}
          nullable
          disabled={attributes.disabled}
        >
          <div className='relative'>
            <div className='relative w-full'>
              <Combobox.Input
                name={name}
                className={innerClassName}
                displayValue={(option?: InputSelectOption) => option?.label || ''}
                onChange={(event) => this.setQuery(event.target.value)}
              />
              <Combobox.Button className='absolute inset-y-0 right-0 flex items-center'>
                <DropdownIndicator />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave=''
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterLeave={() => undefined}
            >
              <Combobox.Options className='z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {options.length === 0 && this.query !== '' ? (
                  <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                    Keine Ergebnisse
                  </div>
                ) : (
                  options.map((option) => (
                    <Combobox.Option
                      key={option.value}
                      className={({ active, selected }) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          selected
                            ? 'bg-blue-500 text-white'
                            : active
                            ? 'bg-indigo-100 text-black'
                            : 'text-gray-900'
                        }`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {option.label}
                        </span>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    )
  }
}

/*export const InputAutocomplete: React.ComponentClass<Props> = tooltip<Props>(
  observer((props) => {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const { name, model, label, tooltip, className, setRef, children, ...attributes } =
      props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)
    const [id] = React.useState(() => props.id || uniqueId('input-'))

    const onChange = action((event: React.FormEvent<HTMLInputElement>) => {
      if (!(event.target instanceof HTMLInputElement)) {
        return
      }
      const target: HTMLInputElement = event.target
      const value: string = target.value
      model.values[name] = value
      props.onChange?.(event)
    })

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7 }}
          >
            {label}
          </label>
        )}
        <input
          type='text'
          className={innerClassName}
          maxLength={255}
          {...attributes}
          name={name}
          ref={setRef}
          onChange={onChange}
          value={model.values[name]}
          id={id}
        />
        {children}
      </div>
    )
  })
)
*/
