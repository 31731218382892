import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { InputAsyncMultiSelect } from 'components/Form/components/InputAsyncMultiSelect'
import { AppContext } from 'services/connection/models/AppContext'
import { INationality } from 'contracts/residents/interfaces/INationality'
import { useResource } from 'helpers/useResource'

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

@observer
export class SelectNationality extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private reset = () => {
    this.props.model.values.nationalityId = ''
    this.props.model.values.languageIds = ''
    this.props.model.values.onlyKriegsfluchtUkr = null
  }

  @action private setOnlyKriegsfluchtUkr = () => {
    this.props.model.values.onlyKriegsfluchtUkr = this.props.model.values
      .onlyKriegsfluchtUkr
      ? null
      : 'yes'
  }

  render() {
    let label: JSX.Element | string = 'Nationalität'
    let color: string = 'text-gray-500'
    if (this.props.model.values.nationalityId && this.props.model.values.languageIds) {
      label = 'Nationalität und Sprache'
      color = 'text-blue-500'
    } else if (this.props.model.values.nationalityId) {
      label = <NationalityLabel nationalityIds={this.props.model.values.nationalityId} />
      color = 'text-blue-500'
    } else if (this.props.model.values.languageIds) {
      const languages = this.props.model.values.languageIds.split(',')
      if (languages.length === 1) {
        label = 'Sprache ' + languages[0].toUpperCase()
      } else {
        label = languages.length + ' Sprachen'
      }
      color = 'text-blue-500'
    } else if (this.props.model.values.onlyKriegsfluchtUkr) {
      label = 'Kriegsflucht UKR'
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputAsyncMultiSelect
                model={this.props.model}
                name='nationalityId'
                label='Nationalität'
                options={`/api/${this.context.instance.id}/nationalities`}
              />
              <InputAsyncMultiSelect
                model={this.props.model}
                name='languageIds'
                label='Sprachen'
                options={`/api/${this.context.instance.id}/languages`}
              />

              {(this.context.instance.id === '0' ||
                this.context.instance.id === '5' ||
                this.context.instance.id === '6') && (
                <div className='col-span-2'>
                  <input
                    type='checkbox'
                    checked={this.props.model.values.onlyKriegsfluchtUkr === 'yes'}
                    onChange={this.setOnlyKriegsfluchtUkr}
                    className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                  />{' '}
                  &nbsp;Nur Kriegsflucht UKR
                </div>
              )}

              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}

export const NationalityLabel: React.FC<{ nationalityIds: string }> = observer(
  (props) => {
    const ids = props.nationalityIds.split(',')
    const context = React.useContext(AppContext)
    const resource = useResource<INationality>(
      `/api/${context.instance.id}/nationalities/${ids[0]}`,
    )
    return (
      <>
        {resource.data
          ? `${resource.data.label}${ids.length > 1 ? ` +${ids.length - 1}` : ''}`
          : 'Gewählte Nationalität'}
      </>
    )
  },
)
