import { InputMultiSelectOption } from 'components/Form/components/InputMultiSelect'

export function getMultiselectAdded(
  oldIds: Set<string>,
  selected: readonly { value: string; label: string }[],
): InputMultiSelectOption[] {
  const addedOptions: InputMultiSelectOption[] = []
  for (const option of selected) {
    if (!oldIds.has(option.value)) {
      addedOptions.push({ id: option.value, label: option.label })
    }
  }
  return addedOptions
}
