import * as React from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { TitleBar } from '../../../../../../components/TitleBar'
import { TitleBarTab } from '../../../../../../components/TitleBar/TitleBarTab'
import { OverviewTab } from './components/OverviewTab'
import { ProfileTab } from './components/ProfileTab'
import { AccommodationTab } from './components/AccommodationTab'
import { DocumentationTab } from './components/DocumentationTab'
import { IResident } from 'contracts/residents/interfaces/IResident'
import styles from '../../styles.module.scss'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Resource } from '@byll/hermes'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { NotAllowed } from 'components/Callout/components/NotAllowed'
import { HealthTab } from './components/HealthTab'
import { InventoryTab } from './components/InventoryTab'
import { LogTab } from './components/LogTab'
import { Callout } from 'components/Callout'
import { action, makeObservable, observable } from 'mobx'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { isAllowedWithCompounds } from 'modules/Residents/helpers/isAllowedWithCompounds'
import { isMucInstance } from 'contracts/general/helpers/instanceIds'

interface Props {
  resident: IResident
}

@observer
export class CaseRecordBody extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly family: Resource<IFamily>
  private readonly residentSearchResult: Resource<IResidentSearchResult>
  private readonly disposers: Disposer[] = []
  @observable private showArchivedResidentDetails = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.family = new Resource(
      `/api/${context.instance.id}/families/${props.resident.familyId}`,
    )
    this.residentSearchResult = new Resource(
      `/api/${context.instance.id}/residentSearchResults/${props.resident.id}`,
    )
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(this.family.init())
    this.disposers.push(this.residentSearchResult.init({ readOnly: true }))
    // Update last visited profiles
    //void backend.get('Person\\LastVisitedProfiles', { personId: this.props.residentSubscription.params.id })
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action private showAnyways = () => (this.showArchivedResidentDetails = true)

  render() {
    const deleted = !!this.props.resident.deletedAt && !this.showArchivedResidentDetails
    const renderProfile = isAllowedWithCompounds(
      this.context.permissions.resident_viewProfile,
      this.residentSearchResult.data?.recentResponsibilityCompoundIds,
    )

    return (
      <div className={styles.tabs}>
        <div className='flex-content sticky top-14 z-10'>
          <TitleBar>
            <TitleBarTab label='Übersicht' to='overview' />
            {renderProfile && <TitleBarTab label='Profil' to='profile' />}
            {this.context.permissions.resident_documentation > 0 && (
              <TitleBarTab label='Doku' to='documentation' />
            )}
            <TitleBarTab
              label={isMucInstance(this.context.instance.id) ? 'Aufenthalt' : 'Belegung'}
              to='accommodation'
            />
            {this.context.permissions.resident_showHealthTab > 0 && (
              <TitleBarTab label='Gesundheit' to='health' />
            )}
            {this.context.permissions.resident_inventoryTab > 0 && (
              <TitleBarTab label='Inventar' to='inventory' />
            )}
            {this.context.permissions.resident_showChronicles > 0 && (
              <TitleBarTab label='Chronik' to='log' />
            )}
          </TitleBar>
        </div>

        {/* Vertical spacer */}
        <div style={{ flex: '0 0 16px' }} />

        {/* Tab contents */}
        {!deleted && (
          <Routes>
            <Route
              path='overview/*'
              element={<OverviewTab resident={this.props.resident} />}
            />
            {this.family.data && renderProfile && (
              <Route
                path='profile'
                element={
                  <ProfileTab
                    resident={this.props.resident}
                    family={this.family.data}
                    readOnly={
                      !isAllowedWithCompounds(
                        this.context.permissions.resident_editProfile,
                        this.residentSearchResult.data?.recentResponsibilityCompoundIds,
                      )
                    }
                  />
                }
              />
            )}
            <Route
              path='documentation/*'
              element={<RenderDocumentationTab resident={this.props.resident} />}
            />
            <Route
              path='accommodation'
              element={
                <AccommodationTab
                  key={this.props.resident.familyId}
                  resident={this.props.resident}
                />
              }
            />
            <Route path='health' element={<HealthTab resident={this.props.resident} />} />
            <Route
              path='inventory'
              element={<InventoryTab resident={this.props.resident} />}
            />
            {this.context.permissions.resident_showChronicles > 0 && (
              <Route path='log' element={<LogTab resident={this.props.resident} />} />
            )}
            <Route path='*' element={<Navigate replace to='overview' />} />
          </Routes>
        )}

        {deleted && (
          <Callout
            icon='fas fa-archive'
            title='Dieser Bewohner wurde archiviert'
            iconColor='#ef4444'
          >
            {this.context.permissions.resident_showDeleted && (
              <Button onClick={this.showAnyways} color='secondary' outline>
                Trotzdem anzeigen
              </Button>
            )}
          </Callout>
        )}
      </div>
    )
  }
}

export const RenderDocumentationTab: React.FC<{ resident: IResident }> = observer(
  (props) => {
    const context = React.useContext(AppContext)
    const navigate = useNavigate()
    if (context.permissions.resident_documentation === 0) {
      return <NotAllowed />
    }
    return <DocumentationTab resident={props.resident} navigate={navigate} />
  },
)
