import { Collection, hermes } from '@byll/hermes'
import { Callout } from 'components/Callout'
import { Card } from 'components/Card'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { Spinner } from 'components/Spinner'
import { IFullOrder } from 'contracts/inventory/interfaces/IFullOrder'
import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { IOrderItem } from 'contracts/inventory/interfaces/IOrderItem'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InventoryBundleTopBar } from '../../InventoryBundle/components/InventoryBundleTopBar'
import { InventoryOrderForm } from './InventoryOrderForm'

interface Props {
  compoundId: string
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
  navigate: (url: string) => void
  type: 'inventory' | 'order'
}

@observer
export class InventoryOrderNew extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private order: IFullOrder
  @observable private loading = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.order = {
      id: '',
      type: props.type,
      orderNumber: '',
      number: '',
      compoundId: props.compoundId,
      createdAt: new Date().toISOString(),
      createdBy: context.user.id,
      approvedAt: null,
      rejectedAt: null,
      deliveredAt: null,
      notes: '',
      items: [],
      compound: {} as any,
    }
    if (props.type === 'inventory' && props.folders.resources) {
      // Put all items in .items
      for (const f of props.folders.resources) {
        if (!f.data) {
          continue
        }
        for (const i of f.data.items) {
          if (i.deletedAt) {
            continue
          }
          this.order.items.push({
            id: '',
            orderId: this.order.id,
            inventoryItemId: i.id,
            imageId: i.imageId,
            label: i.label,
            oldCount: i.count,
            count: null as any,
            unit: i.unit,
            packageSize: i.packageSize || '1',
            notes: '',
            deletedAt: null,
          })
        }
      }
      this.order.items.sort((a, b) => a.label.localeCompare(b.label))
    }
    if (props.type === 'order') {
      this.loading = true
    }
    makeObservable(this)
  }

  componentDidMount() {
    if (this.props.type === 'order') {
      void this.loadInitialOrderItems()
    }
  }

  private loadInitialOrderItems = async () => {
    try {
      const data = await hermes.getOnceNew<{ items: IOrderItem[] }>(
        `/api/${this.context.instance.id}/inventory/orderSuggestions/${this.props.compoundId}`,
      )
      runInAction(() => {
        this.loading = false
        this.order.items = data.items.filter((i) => !i.deletedAt)
      })
    } catch (e) {
      // Ignore errors -> empty list
      runInAction(() => (this.loading = false))
    }
  }

  render() {
    if (!this.context.permissions.inventory_createOrders) {
      return (
        <Callout
          icon='fas fa-ban'
          iconColor='#ef4444'
          title='Berechtigung liegt nicht vor'
          subtitle='Sie haben keine Berechtigung, neue Bestellungen anzulegen.'
        />
      )
    }
    return (
      <>
        <InventoryBundleTopBar
          bundle={
            {
              label: this.props.type === 'order' ? 'Neue Bestellung' : 'Neue Inventur',
            } as any
          }
        />
        <CardColumn>
          {this.loading && <Spinner delay />}
          {!this.loading && (
            <Card>
              <div className='p-6'>
                <InventoryOrderForm order={this.order} navigate={this.props.navigate} />
              </div>
            </Card>
          )}
        </CardColumn>
      </>
    )
  }
}
