import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Message } from 'components/Message'
import { Button } from 'components/Form/components/Button'
import { AppContext } from 'services/connection/models/AppContext'
import { download } from 'helpers/download'
import axios from 'axios'
import { CircularProgressbar } from 'components/CircularProgressbar'
import { ConflictError } from 'contracts/errors/HermesErrors'

interface Props {
  onClose: (val?: any) => void
  compoundId: string
  buildingGroupId: string
  month: string
}
@observer
export class CreateEinzelrechnungenWohnungsloseDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private step: 'create draft invoices' | 'concatenate' = 'concatenate'
  @observable private progress: number | null = 0
  @observable private token: string | null = null
  @observable private error: string | null = null
  private timer
  private mounted = true
  private invoices: { uuid: string; costCoverageIds: string[] }[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.createDraftInvoices()
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    this.mounted = false
  }

  private createDraftInvoices = async () => {
    try {
      const r = await axios.get(
        `/api/${this.context.instance.id}/pdf/data?type=einzelrechnungen-wohnungslose&compoundId=${this.props.compoundId}&buildingGroupId=${this.props.buildingGroupId}&month=${this.props.month}`,
      )
      let i = 0
      runInAction(() => (this.progress = 0.1))
      for (const draft of r.data.resources) {
        if (!this.mounted) {
          return
        }
        const response: any = await axios.post(
          `/api/${this.context.instance.id}/costCoverages/einzelrechnungWohnungslose`,
          draft,
        )
        this.invoices.push({
          uuid: response.data.id,
          costCoverageIds: draft.costCoverageIds,
        })
        i++
        // eslint-disable-next-line
        runInAction(() => (this.progress = 0.1 + (i / r.data.resources.length) * 0.9))
      }
      runInAction(() => {
        this.progress = 0
        this.step = 'concatenate'
      })
      void this.concat()
    } catch (e: any) {
      runInAction(() => {
        this.error =
          e?.response?.data?.id === ConflictError.id
            ? e.response.data.message
            : 'Das Dokument konnte leider nicht erstellt werden.'
        this.progress = null
      })
    }
  }

  private concat = async () => {
    try {
      this.timer = setInterval(
        action(() => {
          this.progress = (this.progress || 0) + 0.05
          if (this.progress > 1) {
            this.progress = 1
          }
        }),
        200,
      )
      const response: any = await axios.post(
        `/api/${this.context.instance.id}/costCoverages/concatenateWohnungslose`,
        { invoices: this.invoices },
      )
      const token = response?.data.id
      if (typeof token !== 'string') {
        throw new Error('Invalid return value')
      }
      clearInterval(this.timer)
      runInAction(() => {
        this.token = token
        this.progress = null
      })
    } catch (e: any) {
      clearInterval(this.timer)
      runInAction(() => {
        this.error =
          e?.response?.data?.id === ConflictError.id
            ? e.response.data.message
            : 'Das Dokument konnte leider nicht erstellt werden.'
        this.progress = null
      })
    }
  }

  private download = () => {
    if (!this.token) {
      return
    }
    download(
      `/api/${this.context.instance.id}/costCoverages/concatenateWohnungslose/${this.token}`,
    )
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Sammeldokument erstellen
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        {!this.error && this.step === 'concatenate' && (
          <div className='relative mt-4 h-[200px]'>
            {this.progress !== null && (
              <div className='px-40 pt-6'>
                <CircularProgressbar
                  percentage={this.progress === 1 ? undefined : this.progress}
                  strokeWidth={4}
                />
              </div>
            )}
            {this.progress === null && (
              <Message color='primary'>
                Das Sammeldokument wurde erstellt. Klicken Sie auf "Download", um es
                herunterzuladen.
              </Message>
            )}
          </div>
        )}

        {!this.error &&
          this.step === 'create draft invoices' &&
          this.progress !== null && (
            <div className='relative mt-4 h-[200px]'>
              <div className='relative text-center text-indigo-500'>
                <i
                  className='fas fa-drafting-compass'
                  style={{ fontSize: '100px' }}
                  aria-hidden='true'
                />
                <p className={`mt-4 mb-4 font-bold`}>Erzeuge Rechnungsentwürfe...</p>
              </div>
              <div className='border border-gray-300 rounded-md h-10 overflow-hidden'>
                <div
                  className={`bg-green-500 text-white text-sm overflow-hidden whitespace-nowrap transition-width duration-500 ease-in-out ${
                    this.progress > 0 ? 'p-2' : ''
                  }`}
                  style={{ width: `${this.progress * 100}%`, height: '100%' }}
                >
                  {Math.round(this.progress * 100)} %
                </div>
              </div>
            </div>
          )}

        <div className='mt-4 text-center'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            {this.progress !== null ? 'Abbrechen' : 'Schließen'}
          </Button>
          {this.progress === null && this.token && (
            <Button color='success' className='ml-3' onClick={this.download}>
              Download <i className='fas fa-download' />
            </Button>
          )}
        </div>
      </>
    )
  }
}
