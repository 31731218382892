import { uniqueId } from 'helpers/uniqueId'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { tooltip } from '../../helpers/tooltip'

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  label: string
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  children?: Element
  color?: 'danger'
  setRef?: (HTMLInputElement) => void
}

export const InputCheckbox: React.ComponentClass<Props> = tooltip<Props>(
  observer((props) => {
    let inputClassName =
      props.color === 'danger'
        ? 'h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded'
        : 'h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
    const { name, model, label, tooltip, setRef, className, children, ...attributes } =
      props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)
    const [id] = React.useState(() => props.id || uniqueId('input-'))

    const onChange = action((event: React.FormEvent<HTMLInputElement>) => {
      model.values[name] = !model.values[name]
      props.onChange?.(event)
    })

    if (touched && error) {
      inputClassName = 'h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded'
    }

    if (attributes.disabled) {
      inputClassName += ' bg-gray-100'
    }

    return (
      <div className={`relative flex items-center ${className || ''}`}>
        <input
          className={inputClassName}
          maxLength={255}
          {...attributes}
          type='checkbox'
          name={name}
          onChange={onChange}
          checked={model.values[name]}
          id={id}
        />
        <label htmlFor={id} className='ml-2 block text-sm text-gray-900' ref={setRef}>
          {label}
        </label>
        {children}
      </div>
    )
  }),
)
