import { jbpIdAlphabet as alphabet } from './jbpIdAlphabet'

/**
 * @param jbpId
 * @returns null for invalid jbpId string
 */
export function fromJbpId(jbpId: string): number | null {
  if (jbpId.length < 4) {
    return null
  }
  const len = alphabet.length
  let factor = 1
  let id = 0

  for (let pos = jbpId.length - 1; pos >= 0; pos--) {
    const digit = alphabet.indexOf(jbpId.substring(pos, pos + 1).toUpperCase())
    if (digit === -1) {
      return null
    }
    id += digit * factor
    factor *= len
  }

  return id
}
