import axios from 'axios'
import { Callout } from 'components/Callout'
import { Button } from 'components/Form/components/Button'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { Spinner } from 'components/Spinner'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {}

@observer
export class AccountGeneralLogout extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading = true
  @observable private sessionCount: number | null = null // number of sessions (including current one)

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.load()
  }

  private load = async () => {
    try {
      const data = await axios.get(`/api/${this.context.instance.id}/sessionStats`, {
        withCredentials: true,
      })
      if (typeof data.data.sessions !== 'number') {
        throw new Error('Invalid response')
      }
      runInAction(() => {
        this.sessionCount = data.data.sessions
        this.loading = false
      })
    } catch (_e) {
      runInAction(() => (this.loading = false))
    }
  }

  private generalLogout = async () => {
    try {
      runInAction(() => (this.loading = true))
      await axios.delete(`/api/${this.context.instance.id}/sessionStats/all`, {
        withCredentials: true,
      })
      await this.load()
    } catch (_e) {
      runInAction(() => {
        this.sessionCount = null
        this.loading = false
      })
    }
  }

  render() {
    return (
      <CardColumnCard
        title='Geräte'
        subtitle='Allgemeiner Logout aus allen Geräten'
        className='min-h-[380px]'
      >
        {this.loading && <Spinner />}
        {!this.loading && this.sessionCount && (
          <div className='border-2 rounded-md border-green-500 bg-green-50 px-10 md:px-[150px] pb-10'>
            <Callout
              icon='fas fa-check'
              iconColor='#22c55e'
              title={
                this.sessionCount > 1
                  ? `${this.sessionCount} aktive Geräte`
                  : 'Keine weiteren aktiven Geräte'
              }
              subtitle={
                this.sessionCount > 1
                  ? this.sessionCount - 1 === 1
                    ? 'Sie sind neben diesem Gerät in einem weiteren Browser eingeloggt.'
                    : `Sie sind neben diesem Gerät in ${
                        this.sessionCount - 1
                      } weiteren Browsern eingeloggt.`
                  : 'Sie sind derzeit nur mit diesem Gerät eingeloggt.'
              }
            >
              {this.sessionCount > 1 && (
                <Button
                  onClick={this.generalLogout}
                  className='mt-8 mb-2'
                  color='success'
                >
                  <i className='fas fa-sign-out-alt mr-2' />
                  Andere Geräte ausloggen
                </Button>
              )}
            </Callout>
          </div>
        )}
        {!this.loading && !this.sessionCount && (
          <div className='absolute bottom-0 right-0 left-0 top-6 border-2 rounded-md border-red-500 bg-red-50'>
            <Callout
              icon='fas fa-exclamation-triangle'
              iconColor='#ef4444'
              title='Geräte konnten nicht geladen werden'
              subtitle='Bitte wenden Sie sich an den Support.'
            />
          </div>
        )}
      </CardColumnCard>
    )
  }
}
