import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { InventoryFolderPreview } from '../InventoryFolder/components/InventoryFolderPreview'
import { InventoryFolderEdit } from '../InventoryFolder/components/InventoryFolderEdit'

interface Props {
  folder: IInventoryFolder
}

export const InventoryFolder: React.FC<Props> = ({ folder }) => {
  const [edit, setEdit] = React.useState(false)
  const navigate = useNavigate()

  if (!edit) {
    return (
      <InventoryFolderPreview navigate={navigate} folder={folder} setEdit={setEdit} />
    )
  }

  return <InventoryFolderEdit folder={folder} navigate={navigate} setEdit={setEdit} />
}
