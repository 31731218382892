import { Model } from 'components/Form/Model'
import { Tooltip } from 'components/Tooltip'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import * as React from 'react'

interface Props {
  model: Model<ITodoSearchResult>
}

export class TicketWatcher extends React.Component<Props, {}> {
  render() {
    return (
      <span className='text-gray-500 has-tooltip'>
        <i className='fa fa-eye' />
        <Tooltip position='bottom'>
          {this.props.model.values.watchers.map((watcher, i) => (
            <div key={i}>
              {watcher.entity === 'role' ? `Rolle ${watcher.name}` : watcher.name}
            </div>
          ))}
        </Tooltip>
      </span>
    )
  }
}
