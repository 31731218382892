import * as React from 'react'
import { Dialog } from 'components/Dialog'
import { Dialog as UIDialog } from '@headlessui/react'
import { useLocation, useNavigate } from 'react-router'
import { XIcon } from '@heroicons/react/outline'
import { ICostCoverageInvoice } from 'contracts/costCoverages/interfaces/ICostCoverageInvoice'
import { CostCoverageInvoice } from 'modules/Pdf/templates/CostCoverageInvoice'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { RoundIcon } from 'components/RoundIcon'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { PrintIcon } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/PrintIcon'
import { download } from 'helpers/download'
import { observable, reaction, toJS } from 'mobx'
import { box } from 'services/box'
import { Button } from 'components/Form/components/Button'
import { observer } from 'mobx-react'
import { CostCoverageInvoicePositionValidator } from 'contracts/costCoverages/validators/CostCoverageInvoicePositionValidator'
import { PreventRouteChange } from 'components/PreventRouteChange'

interface Props {}

export const CostCoverageInvoiceDialog: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [downloading, setDownloading] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [invoice, setInvoice] = React.useState<ICostCoverageInvoice | null>()
  const [hasChanges, setHasChanges] = React.useState<boolean>(false)
  const id = location.pathname.substring(7)
  function close() {
    navigate('/costs')
  }

  React.useEffect(() => {
    setHasChanges(false)
    if (!id) {
      setInvoice(null)
      return
    }
    let disposer: any
    hermes
      .getOnceNew<ICostCoverageInvoice>(
        `/api/${context.instance.id}/costCoverageInvoices/${id}`,
      )
      .then((inv) => {
        const tmpInv = observable(inv)
        setInvoice(tmpInv)
        disposer = reaction(
          () => toJS(tmpInv.additionalPositions),
          () => setHasChanges(true),
        )
      })

    return () => disposer?.()
  }, [id, context.instance.id])

  async function onDownload() {
    if (hasChanges) {
      box.alert(
        'Ungespeicherte Änderungen',
        'Bitte Speichern Sie Ihre Änderungen, bevor Sie das Dokument herunterladen oder drucken.',
      )
      return
    }
    if (invoice?.invoiceDocumentId) {
      download(`/api/${context.instance.id}/documents/files/${invoice.invoiceDocumentId}`)
      return
    }
    setDownloading(true)
    await createAndDownloadReport(
      'cost-coverage-invoice',
      context.instance.id,
      { invoiceId: id },
      'Rechnung.pdf',
    )
    setDownloading(false)
  }

  async function savePositions() {
    if (!invoice) {
      return
    }
    for (const position of invoice.additionalPositions) {
      if (
        CostCoverageInvoicePositionValidator.omit({ id: true }).safeParse(position)
          .success === false
      ) {
        box.alert(
          'Daten unvollständig',
          'Bitte überprüfen Sie Ihre Eingabe. Alle Felder müssen ausgefüllt sein.',
        )
        return
      }
    }
    const buildingGroupId = invoice.id.split('-')[1]
    const positions: { label: string; amount: string }[] = []
    for (const position of invoice.additionalPositions) {
      positions.push({ label: position.label, amount: position.amount })
    }
    setLoading(true)
    try {
      await hermes.create(
        `/api/${context.instance.id}/costCoverages/additionalPositions`,
        {
          costCoverageId: invoice.costCoverageId,
          month: invoice.month,
          buildingGroupId: buildingGroupId === '0' ? null : buildingGroupId,
          positions,
        },
      )
      setHasChanges(false)
      setTimeout(close, 30)
    } catch (_e) {
      box.alert('Fehler', 'Beim Speichern ist ein Fehler aufgetreten.')
    }
    setLoading(false)
  }

  const showSaveButton = invoice?.id.includes('-') && hasChanges
  return (
    <Dialog
      size='lg'
      open={!!id && context.permissions.resident_abrechnung_invoice}
      setOpen={close}
    >
      {!!id && (
        <div>
          <div className='absolute top-0 right-0 pt-4 pr-6'>
            <button
              type='button'
              className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={close}
            >
              <span className='sr-only'>Close</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>

          <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
            <div className='flex items-start'>
              <div className='-mt-2 text-left'>
                <UIDialog.Title
                  as='h3'
                  className='text-lg leading-6 font-medium text-gray-900'
                >
                  Rechnung für Kostenübernahme&nbsp;&nbsp;&nbsp;
                </UIDialog.Title>
              </div>
            </div>
          </div>

          <div className='p-6 bg-gray-100'>
            {!invoice && <div className='shadow-md relative bg-white h-[1188px]' />}
            {invoice && (
              <div className='shadow-md relative'>
                <CostCoverageInvoice invoice={invoice} instanceId={context.instance.id} />
                <RoundIcon
                  icon={downloading ? 'fas fa-sync fa-spin' : 'fas fa-download'}
                  tooltip='Herunterladen'
                  style={{ position: 'absolute', top: 16, right: 16 }}
                  color='primary'
                  onClick={onDownload}
                />
                {!hasChanges && (
                  <PrintIcon
                    url={
                      invoice?.invoiceDocumentId
                        ? `/api/${context.instance.id}/documents/files/${invoice.invoiceDocumentId}`
                        : `/api/${context.instance.id}/costCoverages/invoicePrint/${id}`
                    }
                    style={{ position: 'absolute', top: 54, right: 16 }}
                  />
                )}
                {hasChanges && (
                  <RoundIcon
                    icon='fas fa-print'
                    tooltip='Drucken'
                    style={{ position: 'absolute', top: 54, right: 16 }}
                    color='primary'
                    onClick={onDownload}
                  />
                )}
              </div>
            )}
          </div>
          {hasChanges && <PreventRouteChange />}
          <div
            className='py-4 px-6 sticky gap-4 justify-end text-right bottom-0 bg-white border-t border-gray-200 flex'
            style={{ borderRadius: '0 0 8px 8px' }}
          >
            <Button color='secondary' outline={showSaveButton} onClick={close}>
              {showSaveButton ? 'Abbrechen' : 'Schließen'}
            </Button>
            {showSaveButton && (
              <Button loading={loading} onClick={savePositions}>
                Speichern
              </Button>
            )}
          </div>
        </div>
      )}
    </Dialog>
  )
})
