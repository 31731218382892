import * as React from 'react'
import { DashboardSupport } from './components/DashboardSupport'
import { DashboardResidentStats } from './components/DashboardResidentStats'
import { DashboardServerStats } from './components/DashboardServerStats'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { DashboardBedStats } from './components/DashboardBedStats'
import { BlogpostList } from './components/DashboardBlogpost/modules/BlogpostList'
import { DashboardAutoArchive } from './components/DashboardAutoArchive'
import { DashboardCheckInsOuts } from './components/DashboardCheckInsOuts'
import { DashboardTodos } from './components/DashboardTodos'
import { Route, Routes, useNavigate, useParams } from 'react-router'
import { DashboardTickets } from './components/DashboardTickets'
import { TicketDialog } from './components/DashboardTickets/components/TicketDialog'
import { isJbpInstance } from 'contracts/general/helpers/instanceIds'
import { useCollection } from 'helpers/useCollection'
import { Blogpost } from './components/DashboardBlogpost/modules/Blogpost'
import { IBlogpost } from 'contracts/dashboard/interfaces/IBlogpost'

interface Props {}

export const Dashboard: React.FC<Props> = observer(() => {
  const navigate = useNavigate()
  const context = React.useContext(AppContext)
  const blogposts = useCollection<IBlogpost>(`/api/${context.instance.id}/blogposts`)
  React.useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])

  const closeTicketDialog = () => {
    navigate('')
  }
  const visibleBlogposts = blogposts.resources?.filter((b) => !!b.data?.isActive)
  return (
    <div className='pt-14 bg-gray-100 min-h-full'>
      <div className='grid auto-rows-fr grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-4 sm:p-6'>
        {isJbpInstance(context.instance.id) &&
          visibleBlogposts &&
          visibleBlogposts.length > 0 && (
            <Blogpost key={visibleBlogposts[0].id} blogpost={visibleBlogposts[0]} />
          )}
        <DashboardTodos navigate={navigate} />
        {visibleBlogposts &&
          visibleBlogposts.length > 0 &&
          visibleBlogposts.map((b, i) => {
            if (isJbpInstance(context.instance.id) && i === 0) {
              return null
            }
            return <Blogpost key={b.id} blogpost={b} />
          })}
        {isJbpInstance(context.instance.id) &&
          context.permissions.dashboard_tickets > 0 && (
            <DashboardTickets navigate={navigate} />
          )}
        {context.permissions.dashboard_residentCounts > 0 && <DashboardResidentStats />}
        {context.permissions.dashboard_checkInsOuts > 0 && <DashboardCheckInsOuts />}
        {context.permissions.dashboard_showSystemInformation && <DashboardServerStats />}
        {context.permissions.dashboard_supportTile && <DashboardSupport />}
        {context.permissions.dashboard_bedStats > 0 && <DashboardBedStats />}
        {context.permissions.resident_toggleAutoArchive && <DashboardAutoArchive />}
        {context.permissions.dashboard_blogposts === 2 && (
          <BlogpostList blogposts={blogposts} />
        )}
      </div>

      {/* Dialog */}
      <Routes>
        <Route
          path='tickets/:listId/:ticketId'
          element={<RenderTicketDialog onClose={closeTicketDialog} />}
        />
      </Routes>
    </div>
  )
})

export const RenderTicketDialog: React.FC<{ onClose: () => void }> = observer((props) => {
  const params = useParams<{ ticketId: string; listId: string }>()
  return (
    <TicketDialog
      key={params.ticketId}
      ticketId={params.ticketId || ''}
      listId={params.listId || ''}
      onClose={props.onClose}
    />
  )
})
