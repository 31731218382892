import { FormColor } from 'components/Form/interfaces/FormColor'

interface Options {
  outline?: boolean
  block?: boolean
  small?: boolean
  className?: string
  disabled?: boolean
}

const buttonStyles = {
  primary:
    'text-white bg-gradient-to-r from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500',
  secondary:
    'text-white bg-gradient-to-r from-gray-500 to-gray-600 hover:bg-gradient-to-r hover:from-gray-500 hover:to-gray-500 focus:ring-gray-500',
  success:
    'text-white bg-gradient-to-r from-green-500 to-green-600 hover:bg-gradient-to-r hover:from-green-500 hover:to-green-500 focus:ring-green-500',
  info: 'text-white bg-gradient-to-r from-cyan-500 to-cyan-600 hover:bg-gradient-to-r hover:from-cyan-500 hover:to-cyan-500 focus:ring-cyan-500',
  warning:
    'text-white bg-gradient-to-r from-yellow-500 to-yellow-600 hover:bg-gradient-to-r hover:from-yellow-500 hover:to-yellow-500 focus:ring-yellow-500',
  danger:
    'text-white bg-gradient-to-r from-red-500 to-red-600 hover:bg-gradient-to-r hover:from-red-500 hover:to-red-500 focus:ring-red-500',
  pink: 'text-white bg-gradient-to-r from-pink-500 to-pink-600 hover:bg-gradient-to-r hover:from-pink-500 hover:to-pink-500 focus:ring-pink-500',
  blue: 'text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500 focus:ring-blue-500',
  purple:
    'text-white bg-gradient-to-r from-purple-500 to-purple-600 hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-500 focus:ring-purple-500',
  primary_outline:
    'border border-indigo-500 text-indigo-500 hover:bg-indigo-500 hover:text-white focus:ring-indigo-500 focus:bg-indigo-500 focus:text-white',
  secondary_outline:
    'border border-gray-300 text-gray-500 hover:border-gray-500 focus:border-gray-500 hover:bg-gray-500 hover:text-white focus:ring-gray-500 focus:bg-gray-500 focus:text-white',
  success_outline:
    'border border-green-500 text-green-500 hover:bg-green-500 hover:text-white focus:ring-green-500 focus:bg-green-500 focus:text-white',
  info_outline:
    'border border-cyan-500 text-cyan-500 hover:bg-cyan-500 hover:text-white focus:ring-cyan-500 focus:bg-cyan-500 focus:text-white',
  warning_outline:
    'border border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white focus:ring-yellow-500 focus:bg-yellow-500 focus:text-white',
  danger_outline:
    'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white focus:ring-red-500 focus:bg-red-500 focus:text-white',
  pink_outline:
    'border border-pink-500 text-pink-500 hover:bg-pink-500 hover:text-white focus:ring-pink-500 focus:bg-pink-500 focus:text-white',
}

export function getButtonStyle(
  color?: FormColor | 'pink' | 'blue' | 'purple',
  props?: Options,
): string {
  return `${props?.className || ''} ${
    buttonStyles[(color || 'primary') + (props?.outline ? '_outline' : '')]
  } ${props?.block ? 'w-full' : ''} ${
    props?.small
      ? 'text-xs px-2.5 py-[2px]'
      : props?.outline
      ? 'py-2 px-4'
      : 'py-[9px] px-[17px]'
  } ${
    props?.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
  } text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2`
}
