import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { Spinner } from 'components/Spinner'
import { useResource } from 'helpers/useResource'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import { AppContext } from 'services/connection/models/AppContext'
import { MigrationGrid } from './components/MigrationGrid'
import { IMigration } from 'contracts/migrations/interfaces/IMigration'
import { MigrationColumnMapping } from './components/MigrationColumnMapping'
import { Button } from 'components/Form/components/Button'
import { box } from 'services/box'
import { hermes } from '@byll/hermes'
import { MigrationProgressBar } from './components/MigrationProgressBar'
import { observable } from 'mobx'

interface Props {}

const statusColors = {
  'Import läuft': 'bg-yellow-500',
  Importiert: 'bg-green-500',
  Papierkorb: 'bg-red-500',
}

export const MigrationDetail: React.FC<Props> = observer(() => {
  const [importing, setImporting] = React.useState(false)
  const [selected] = React.useState(() => observable.set<string>())
  const navigate = useNavigate()
  const context = React.useContext(AppContext)
  const params = useParams<{ id: string }>()
  const migration = useResource<IMigration>(
    `/api/${context.instance.id}/imports/${params.id}`,
    { readOnly: true },
  )

  if (migration.error?.id === RequestPendingError.id) {
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full flex flex-col'>
        <Spinner delay />
      </div>
    )
  }
  if (migration.error || !migration.data) {
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full flex flex-col'>
        <LoadingError title='Der Vorgang konnte nicht geladen werden.' />
      </div>
    )
  }

  const onImport = async () => {
    if (
      !(await box.alert(
        'Bewohnerimport',
        'Möchten Sie diese Bewohner wirklich einlesen?',
        { cancel: 'Abbrechen' },
      ))
    ) {
      return
    }
    setImporting(true)
    try {
      await hermes.create(
        `/api/${context.instance.id}/imports/${migration.id}/data`,
        {},
        { timeout: 60000 },
      )
    } catch (e: any) {
      await box.alert('Fehler', e.message, { color: 'danger' })
    }
    setImporting(false)
  }

  const goBack = () => navigate(`/migrations`)

  return (
    <div className='pt-14 bg-gray-100 min-h-full flex flex-col relative'>
      {/* Filters */}
      <div className='px-4 sm:px-6 pt-6 mb-3 sm:mb-5 pb-2 flex-content bg-gray-100'>
        <div className='relative bg-white sm:rounded-lg shadow p-4 flex items-center gap-3 overflow-hidden'>
          <Button color='primary' onClick={goBack}>
            Importlisten
          </Button>
          <div>
            <i className='fas fa-caret-right' />
          </div>
          <Button color='secondary' outline className='truncate max-w-[200px]'>
            {migration.data.label}
          </Button>
          {migration.data.status === 'Entwurf' && (
            <Button
              onClick={onImport}
              color='blue'
              className='ml-auto'
              disabled={importing}
            >
              Import durchführen
            </Button>
          )}
          {migration.data.status !== 'Entwurf' && (
            <div
              className={`leading-[38px] rounded-md text-white px-4 ml-auto ${
                statusColors[migration.data.status] || ''
              }`}
            >{`Status: ${migration.data.status}`}</div>
          )}
          <MigrationProgressBar migration={migration.data} />
        </div>
      </div>

      {/* Details */}
      <div className='flex-auto relative'>
        {/* Excel-like grid with migration data */}
        <div className='absolute top-0 left-4 sm:left-6 bottom-4 sm:bottom-6 right-4 sm:right-[408px] bg-white shadow sm:rounded-lg overflow-auto'>
          <MigrationGrid
            migration={migration.data}
            key={migration.data.version}
            selected={selected}
            disabled={migration.data.status !== 'Entwurf'}
          />
        </div>

        {/* Column allocation (match migration fields with actual inuv fields) */}
        <div className='hidden sm:block absolute top-0 bottom-6 right-6 w-[360px] sm:rounded-lg overflow-y-auto overflow-x-hidden'>
          <MigrationColumnMapping migration={migration.data} />
        </div>
      </div>
    </div>
  )
})
