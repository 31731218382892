import { IInternalResidenceFormModel } from '../interfaces/IInternalResidenceFormModel'

export function getReason(
  model: IInternalResidenceFormModel,
  showReason: boolean | null = null,
): string {
  console.log(model.reason, showReason)
  if (showReason === false) {
    // Checkbox "Anschließend Hausverbot" ist sichtbar,
    // Grund-Dropdown ist nicht sichtbar.
    return model.hasHouseBan ? 'Hausverbot' : ''
  }
  if (showReason === null && model.hasHouseBan) {
    // Step4 in Dialog (showReason unbekannt)
    // HausBan kann nur gesetzt werden, falls es auswählbar war
    return 'Hausverbot'
  }
  return model.reason
}
