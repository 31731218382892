import * as React from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { InputDate } from 'components/Form/components/InputDate'
import { dayjs } from 'helpers/dayjs'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { Button } from 'components/Form/components/Button'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { box } from 'services/box'
import { isStammBuilding } from 'helpers/isStamm'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { isTime } from 'contracts/general/helpers/isTime'
import { z } from 'zod'
import { ITransferPlanningSearchResult } from 'contracts/transfer/interfaces/ITransferPlanningSearchResult'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { hermes } from '@byll/hermes'

interface Props {
  planning: ITransferPlanningSearchResult
  className?: string
}

interface CheckOutFormProps extends Props {
  onClose: () => void
}

@observer
class CheckOutForm extends React.Component<CheckOutFormProps, { loading: boolean }> {
  static contextType = AppContext
  private readonly model: Model<{
    date: string | null
    time: string
  }>

  constructor(props: CheckOutFormProps, context: AppContextProps) {
    super(props)
    this.state = { loading: false }
    let now = dayjs().add(-2, 'minute')
    const validator = z.object({ date: z.string(), time: z.string().refine(isTime) })
    this.model = new Model(
      {
        date: now.format('YYYY-MM-DD') || null,
        time: now.format('HH:mm') || '',
      },
      validator,
    )
  }

  private checkOut = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }

    try {
      const at = dayjs(`${this.model.values.date} ${this.model.values.time}`)
      const data: Partial<IBooking> = {
        residentId: this.props.planning.resident.id,
        instanceId: this.context.instance.id,
        type: 'responsibility-end',
        compoundId: this.props.planning.resident.accommodation.building?.compoundId || '',
        roomId: null,
        bed: null,
        reason: 'Transfer',
        comment: '',
        beginAt: at.toISOString(),
        endAt: at.add(1, 'minute').toISOString(),
        extra: {},
      }
      this.setState({ loading: true })
      await hermes.create(`/api/${this.context.instance.id}/bookings`, data)
      this.props.onClose()
    } catch (_e) {
      this.setState({ loading: false })
      void box.alert('Fehler', 'Beim Check-out ist ein Fehler aufgetreten.', {
        color: 'danger',
      })
    }
  }

  render() {
    return (
      <div className='p-3' id={this.model.id}>
        <div className='flex gap-3 mb-3'>
          <InputDate
            className='flex-auto'
            model={this.model}
            name='date'
            label='Enddatum'
          />
          <InputText
            className='flex-[0_0_65px]'
            placeholder='hh:mm'
            model={this.model}
            name='time'
            label='Uhrzeit'
          />
        </div>

        <div className='flex mt-3'>
          <Button
            color='secondary'
            className='w-full'
            outline
            onClick={() => this.props.onClose()}
            style={{ borderRadius: '6px 0 0 6px' }}
          >
            Abbrechen
          </Button>
          <Button
            color='primary'
            className='w-full'
            onClick={this.checkOut}
            style={{ borderRadius: '0 6px 6px 0' }}
          >
            OK
          </Button>
        </div>
        {this.state.loading && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}

export const TransferPlanResidentCheckOut: React.FC<Props> = (props) => {
  const context = React.useContext(AppContext)

  function stopPropagation(e) {
    e.stopPropagation()
  }

  function onOpen(e) {
    if (
      context.permissions.resident_accommodation_checkout_transfers === 1 &&
      !isStammBuilding(props.planning.resident.accommodation.building?.id || '')
    ) {
      e.stopPropagation()
      e.preventDefault()
      box.alert(
        'Kein Stammgebäude',
        'Sie haben nicht die nötige Berechtigung für den Check-out, da dieser Bewohner nicht in einem Ihrer Stammgebäude untergebracht ist.',
      )
      return
    }
  }

  return (
    <Popover as='span' onClick={stopPropagation} className='relative'>
      <Popover.Button className={props.className} onClickCapture={onOpen}>
        Check-out
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Popover.Panel className='z-10 origin-top-right absolute bottom-6 -right-10 mt-2 w-56 rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none cursor-default'>
          {({ close }) => <CheckOutForm {...props} onClose={close} />}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
