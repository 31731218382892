import { Collection } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { classNames } from 'helpers/classNames'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { NewResidentDialog } from './components/NewResidentDialog'
import { ResidentsList } from './components/ResidentsList'
import { Dialog } from 'components/Dialog'
import { Model } from 'components/Form/Model'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable } from 'mobx'

interface Props {
  pathname: string
  navigate: (url: string) => void
}

@observer
export class CreateRecord extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly tabs: InputSelectOption[]
  private readonly model: Model<{
    dateBegin: string | null
    dateEnd: string | null
    page: string
  }>
  private readonly residents: Collection<
    IResidentSearchResult,
    { fields: string; sort: string; creationUserId?: string }
  >
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    const now = dayjs()
    this.tabs = [
      { value: null, label: 'Alle' },
      { value: now.format('YYYY-MM-DD'), label: 'Heute' },
      { value: now.add(-1, 'day').format('YYYY-MM-DD'), label: 'Gestern' },
    ]
    this.model = new Model({
      page: '0,50',
      fields: 'accommodation',
      sort: 'createdAt,desc',
      creationUserId: context.user.id,
      dateType: 'createdAt',
      dateState: 'set',
      dateBegin: null,
      dateEnd: null,
    })
    this.residents = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      this.model.values,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.residents.init({ observeQuery: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private setDialogIsOpen = (open: boolean) =>
    this.props.navigate(`/residents/registration${open ? '/new' : ''}`)
  private openDialog = () => this.setDialogIsOpen(true)

  @action
  private setTab = (value: string | null) => {
    console.log(value)
    this.model.values.dateBegin = value || null
    this.model.values.dateEnd = value || null
    this.model.values.page = '0,50'
  }

  render() {
    const isOpen = this.props.pathname.endsWith('/new')
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full'>
        {/* Section heading */}
        <div className='relative my-7 pb-5 sm:pb-0 bg-white sm:rounded-lg shadow p-6'>
          <div className='sm:flex sm:items-center sm:justify-between'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>Neue Bewohner</h3>
            <div className='mt-3 flex md:mt-0 md:absolute md:top-3 md:right-0'>
              <button
                type='button'
                className='inline-flex w-full items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:mr-3'
                onClick={this.openDialog}
              >
                Neu
              </button>
            </div>
          </div>
          <div className='mt-4'>
            <div className='sm:hidden'>
              <label htmlFor='current-tab' className='sr-only'>
                Select a tab
              </label>
              <select
                onChange={(e) => this.setTab(e.target.value)}
                value={this.model.values.dateBegin || ''}
                id='current-tab'
                name='current-tab'
                className='block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
              >
                {this.tabs.map((tab) => (
                  <option key={tab.value} value={tab.value || ''}>
                    {tab.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='hidden sm:block'>
              <nav className='-mb-px flex space-x-8'>
                {this.tabs.map((tab) => (
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault()
                      this.setTab(tab.value)
                    }}
                    key={tab.value}
                    className={classNames(
                      this.model.values.dateBegin === tab.value
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                    )}
                    aria-current={
                      this.model.values.dateEnd === tab.value ? 'page' : undefined
                    }
                  >
                    {tab.label}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {/* Table */}
        <ResidentsList residents={this.residents} />

        {/* Dialog */}
        <Dialog setOpen={this.setDialogIsOpen} open={isOpen}>
          {isOpen && (
            <NewResidentDialog
              open={this.props.pathname.endsWith('/new')}
              setOpen={this.setDialogIsOpen}
            />
          )}
        </Dialog>
      </div>
    )
  }
}
