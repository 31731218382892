import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { IResidentDocumentationSearchResult } from 'contracts/residents/interfaces/IResidentDocumentationSearchResult'
import * as React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'

interface Props {
  model: Model<IResidentDocumentationSearchResult>
  readOnly?: boolean
}

@observer
export class DocumentationDialogText extends React.Component<Props, {}> {
  @action private toggleChecked = (person: any) => {
    person.checked = !person.checked
  }

  render() {
    const model = this.props.model
    return (
      <div className='rounded-sm shadow bg-white flex mt-6'>
        <div className='flex-[0_0_268px] pt-11 pl-11 leading-7'>
          <div>Dokumentation</div>
          <div className='text-gray-400 text-xs'>
            Beschreibung und
            <br />
            betroffene Bewohner
          </div>
        </div>
        <div className='flex-auto py-11 pr-6'>
          <InputTextarea
            disabled={this.props.readOnly}
            className='mb-4'
            model={model}
            name='notes'
            label='Kommentar'
            rows={15}
          />
          <div className='col-span-4 text-sm shadow-sm border border-gray-300 rounded-md py-1 px-2 -mb-2 flex flex-wrap'>
            {model.values.residents.map((r) => (
              <div key={r.id} className='truncate mr-3 p-1'>
                <input
                  disabled={this.props.readOnly}
                  type='checkbox'
                  checked={r.checked}
                  id={`member-check-${r.id}`}
                  onChange={() => this.toggleChecked(r)}
                  className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded${
                    this.props.readOnly ? ' bg-gray-100' : ''
                  }`}
                />
                <label
                  htmlFor={`member-check-${r.id}`}
                  className='cursor-pointer ml-1 text-gray-500'
                >{`${r.lastName.toUpperCase()}, ${r.firstName}`}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
