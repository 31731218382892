import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import { dayjs } from 'contracts/general/helpers/dayjs'
import axios from 'axios'
import { Wait } from 'modules/Pdf/components/Wait'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { IFamilyAccommodationDetails } from 'contracts/residents/interfaces/IFamilyAccommodationDetails'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { IRole } from 'contracts/users/interfaces/IRole'

export const LfgbMietschuldenfreiheitsbestaetigung: React.FC<DocumentTemplateProps> = (
  props,
) => {
  const [data, setData] = React.useState<IFamilyAccommodationDetails | null>(null)
  //const [userDetail, setUserDetail] = React.useState<IUserDetail | null>(null)
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)
  const [roles, setRoles] = React.useState<IRole[] | null>(null)

  React.useEffect(() => {
    const load = async () => {
      const [r, d, roles] = await Promise.all([
        axios.get<any>(
          `/api/${props.resident.instanceId}/residentSearchResults?residentIds=${props.resident.id}&fields=bookings`,
        ),
        axios.get<IFamilyAccommodationDetails>(
          `/api/${props.resident.instanceId}/residents/${props.resident.id}/familyAccommodationDetails/LfGMietschuldenfreiheitsbestaetigung`,
        ),
        axios.get<any>(
          `/api/${props.resident.instanceId}/users/roles?userId=${props.user.id}`,
        ),
        /*axios.get<any>(
          `/api/${props.resident.instanceId}/users/userDetails/${props.user.id}`,
        ),*/
      ])
      if (!Array.isArray(r.data.resources) || r.data.resources.length !== 1) {
        throw new Error('Not found')
      }
      const stay = r.data.resources[0]?.data.bookings?.find(
        (b) => b.timeline === 'present' && b.type === 'internal-residence',
      )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cp = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setCompound(cp.data)
        setBuilding(bl.data)
      }
      //setUserDetail(ud.data)
      setData(d.data)
      setRoles(roles.data.resources.filter((r) => !r.userId && !!r.description))
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!data || !roles) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  const today = dayjs(data.now)
  return (
    <>
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        {/* Header */}
        <div className='absolute top-[40px] left-[100px] text-lg'>
          Landesbetrieb für Gebäudebewirtschaftung Berlin
          <br />
          Betriebsteil B (LfG-B)
          <br />
          {buildingLabel}
        </div>
        <img className={styles.logo} src={logo} width={250} alt='' />

        <div className='text-sm ml-[460px] mt-10'>
          <div>
            {props.user.firstName} {props.user.lastName}
          </div>
          <div>{roles && roles.length > 0 && roles[0].description}</div>
          <div>{compound?.contactPhone}</div>
          <div>{compound?.contactEmail}</div>
          <div>{building?.street}</div>
          <div>
            {building?.zip} {building?.city}
          </div>
          <div>{today.format('DD.MM.YYYY')}</div>
        </div>

        {/* Title */}
        <div className='text-lg font-bold' style={{ marginTop: 40 }}>
          Mietschuldenfreiheitsbestätigung zur Vorlage
        </div>

        {/* Content */}
        <div style={{ fontSize: '16px' }}>
          <div className='mt-12'>Sehr geehrte Damen und Herren,</div>

          {data.familyMembers.length === 1 && (
            <div className='mt-8 leading-8'>
              <span className='inline-block align-middle mr-2'>
                wir bestätigen, dass Herr/Frau/Familie
              </span>
              <br />
              <div className='border-b inline-block mb-10 border-black w-[280px] relative mr-3 mt-6'>
                {`${props.resident.lastName}, ${props.resident.firstName}`}
                <span className='absolute -bottom-4 left-2 text-xs'>Name</span>
              </div>{' '}
              <div className='border-b inline-block mb-4 border-black w-[150px] relative'>
                {props.resident.dateOfBirth
                  ? dayjs(props.resident.dateOfBirth).format('DD.MM.YYYY')
                  : ''}
                &nbsp;
                <span className='absolute -bottom-4 left-10 text-xs'>Geburtsdatum</span>
              </div>{' '}
              <span className='inline-block align-middle'>
                seit dem{' '}
                {data.commonStayBeginDate
                  ? dayjs(data.commonStayBeginDate).format('DD.MM.YYYY')
                  : '____________________'}{' '}
                in unserer {data.commonCompound?.type || 'Gemeinschaftsunterkunft'} wohnt
                und dass bei uns keine Mietschulden bestehen.
                <br /> Die Unterkunftskosten werden vom{' '}
                {data.commonPayer || '________________________________________'} getragen.
              </span>
            </div>
          )}

          {data.familyMembers.length > 1 && (
            <div className='mt-8'>
              wir bestätigen, dass Familie:
              <div className='mt-1'>
                {data.familyMembers.map((member) => (
                  <span
                    key={member.id}
                    className='bg-gray-300 mr-3 rounded-md px-2 py-1 max-w-[500px] truncate inline-block'
                  >
                    {`${member.lastName}, ${member.firstName}`}{' '}
                    {member.dateOfBirth
                      ? ` (${dayjs(member.dateOfBirth).format('DD.MM.YYYY')})`
                      : ''}
                  </span>
                ))}
              </div>
              seit dem{' '}
              {data.commonStayBeginDate
                ? dayjs(data.commonStayBeginDate).format('DD.MM.YYYY')
                : '____________________'}{' '}
              in unserer {data.commonCompound?.type || 'Gemeinschaftsunterkunft'} wohnt
              und dass bei uns keine Mietschulden bestehen. Die Unterkunftskosten werden
              vom {data.commonPayer || '________________________________________'}{' '}
              getragen.
            </div>
          )}

          <div className='mt-8'>Mit freundlichen Grüßen</div>
          <div>Im Auftrag</div>

          <div className='grid gap-16 grid-cols-3 mt-12'>
            <div className='text-center'>
              <div className='border-b border-black'>{dayjs().format('DD.MM.YYYY')}</div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>Datum</div>
            </div>

            <div>
              <div className='border-b border-black truncate'>&nbsp;</div>
              <div
                className='text-center'
                style={{ fontSize: '10px', lineHeight: '14px' }}
              >{`${props.user.lastName}, ${props.user.firstName}`}</div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div
          className='flex justify-between w-[640px] text-xs text-gray-500 absolute'
          style={{ bottom: 40, fontSize: 9 }}
        >
          <div>Stand 09.08.2022</div>
          <div className='text-right'>
            Brunnenstraße 110 C, 13355 Berlin
            <br />
            Tel. +49 30 213 099 101
            <br />
            info@lfg-b.de
          </div>
        </div>
      </div>
    </>
  )
}
