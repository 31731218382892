import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { makeObservable, observable, runInAction } from 'mobx'
import { Button } from 'components/Form/components/Button'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { IInvoiceBatch } from 'contracts/costCoverages/interfaces/IInvoiceBatch'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { months } from 'components/Form/components/InputMonth'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'

interface Props {
  month: string
  compoundId: string
  onClose: (val?: any) => void
}

@observer
export class CostCoverageInvoiceBatchesDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private batches: IInvoiceBatch[] | null = null
  @observable private loading = new Set<string>()

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.load()
  }

  private load = async () => {
    const batches = await hermes.indexOnceNew<IInvoiceBatch>(
      `/api/${this.context.instance.id}/costCoverages/invoiceBatches?month=${this.props.month}&compoundId=${this.props.compoundId}`,
    )
    runInAction(() => (this.batches = batches))
  }

  private downloadReport = async (batch: IInvoiceBatch) => {
    if (this.loading.has(batch.id)) {
      return
    }
    runInAction(() => this.loading.add(batch.id))
    await createAndDownloadReport(
      'invoice-batch-list',
      this.context.instance.id,
      { batchId: batch.id },
      `${batch.label.replace(/[^a-zA-Z0-9 -_]/g, '') || 'Stapel'}.xlsx`,
    )
    runInAction(() => this.loading.delete(batch.id))
  }

  render() {
    const month = dayjs(this.props.month)
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Rechnungsstapel | {months[month.month()]} {month.year()}
            </Dialog.Title>
          </div>
        </div>

        <div className='relative mt-4'>
          <div className='bg-gray-100 border-t border-b border-gray-200 -mx-6 px-6 mb-5'>
            {this.batches &&
              this.batches.map((batch) => (
                <div
                  key={batch.id}
                  className='bg-white shadow-md my-3 p-2 rounded-md flex'
                >
                  <div className='truncate flex-[0_0_120px]'>
                    {dayjs(batch.createdAt).format('DD.MM.YYYY')}
                    {!this.loading.has(batch.id) && (
                      <span
                        className='text-blue-500 ml-2 hover:text-blue-600 cursor-pointer'
                        onClick={() => this.downloadReport(batch)}
                      >
                        <i className='fas fa-download' />
                      </span>
                    )}
                    {this.loading.has(batch.id) && (
                      <span className='text-blue-500 ml-2'>
                        <i className='fas fa-spin fa-sync' />
                      </span>
                    )}
                    <br />
                    <span className='text-sm text-gray-400'>
                      {dayjs(batch.createdAt).format('HH:mm')}
                    </span>
                  </div>
                  <div className='truncate flex-auto'>
                    {batch.label}
                    {!batch.storno && (
                      <span className='ml-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800'>{`${
                        batch.total
                      } ${batch.total === 1 ? 'Rechnung' : 'Rechnungen'}`}</span>
                    )}
                    {batch.storno && (
                      <span className='ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800'>{`${
                        batch.total
                      } ${batch.total === 1 ? 'Storno' : 'Stornos'}`}</span>
                    )}
                    <br />
                    <span className='text-sm text-gray-400'>{`${batch.createdBy.firstName} ${batch.createdBy.lastName}`}</span>
                  </div>
                </div>
              ))}
            {this.batches && this.batches.length === 0 && (
              <div className='col-span-2'>Keine Rechnungsstapel vorhanden</div>
            )}
          </div>

          <div className='mt-4 text-right'>
            <Button color='secondary' outline onClick={this.props.onClose}>
              Schließen
            </Button>
          </div>
        </div>
      </>
    )
  }
}
