import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { Age } from 'components/Age'
import { Link } from 'react-router-dom'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IResidentLetterEntry } from 'contracts/residents/interfaces/IResidentLetterEntry'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { Tooltip } from 'components/Tooltip'
import { RoundIcon } from 'components/RoundIcon'
import { hermes } from '@byll/hermes'
import { observer } from 'mobx-react'
import { box } from 'services/box'
import { AuthorizedListDialog } from './AuthorizedListDialog'
import { InputText } from 'components/Form/components/InputText'

interface Props {
  entry: IResidentLetterEntry
}

@observer
export class LetterEntry extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IResidentLetterEntry>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.entry)
  }

  private deleteItem = async () => {
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/residentLetters/${this.props.entry.id}`,
        { immediately: true },
      )
    } catch (e) {
      window.alert('Leider ist das Löschen fehlgeschlagen')
    }
  }

  private toggleInput = () => {
    const promise = box.custom(
      <AuthorizedListDialog entry={this.props.entry} onClose={() => promise.close()} />,
      { context: this.context },
    )
  }

  render() {
    const resident = this.props.entry.receiver
    const authorizations = this.props.entry.authorizations
    return (
      <tr key={resident.id} className='group'>
        <td className='px-6 py-4 whitespace-nowrap relative'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <img
                className='h-10 w-10 rounded-full'
                src={getResidentImageSrc(
                  this.context.instance.id,
                  resident.imageId,
                  resident.sex,
                  'thumbnail',
                )}
                alt=''
              />
            </div>
            <div className='ml-4'>
              <div className='text-sm font-medium'>
                <Link
                  to={`/residents/${toJbpId(+resident.id).toLowerCase()}/overview`}
                  className='text-gray-900 underline hover:text-blue-500'
                >
                  {`${resident.lastName.toUpperCase()}, ${resident.firstName}`}
                </Link>
              </div>
              <div className='text-sm text-gray-500'>
                <Age sex={resident.sex} dateOfBirth={resident.dateOfBirth} />
              </div>
            </div>
          </div>
          <RoundIcon
            classNameContainer='hidden group-hover:block'
            tooltip={{ text: 'Eintrag löschen', position: 'right' }}
            style={{ position: 'absolute', top: 5, left: 5 }}
            icon='fas fa-trash'
            color='danger'
            onClick={this.deleteItem}
          />
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm w-[170px]'>
          <InputDecimal
            className='max-w-20'
            model={this.model}
            name='letters'
            label='Normale Briefe'
            precision={4}
            scale={0}
            notNegative
            placeholder='Anzahl'
          />
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm w-[170px]'>
          <InputDecimal
            className='max-w-20'
            inputClassName={
              +(this.model.values.yellowLetters || 0) > 0 ? 'bg-yellow-100' : undefined
            }
            model={this.model}
            name='yellowLetters'
            label='Gelbe Briefe'
            precision={4}
            scale={0}
            notNegative
            placeholder='Anzahl'
          />
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>
          <InputText
            className='max-w-[300px]'
            inputClassName='w-[300px]'
            model={this.model}
            name='notes'
            label='Notiz'
          />
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>{this.props.entry.room}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
          <div className='flex -space-x-2 relative z-0 items-center'>
            {authorizations.length > 0 && (
              <>
                {authorizations.map((a) => (
                  <div key={a.id} className='has-tooltip'>
                    <img
                      className={`relative inline-block h-10 w-10 rounded-full ring-2 ring-white`}
                      src={getResidentImageSrc(
                        this.context.instance.id,
                        a.imageId,
                        a.sex,
                        'portrait',
                      )}
                      alt=''
                    />
                    <Tooltip>{`${a.firstName} ${a.lastName}`}</Tooltip>
                  </div>
                ))}
              </>
            )}
            <RoundIcon onClick={this.toggleInput} icon='fas fa-pencil-alt' />
          </div>
        </td>
      </tr>
    )
  }
}
