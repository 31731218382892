import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { getRoomLockReasons } from 'contracts/accommodations/helpers/getRoomLockReasons'
import { IRoomLock } from 'contracts/accommodations/interfaces/IRoomLock'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { hermes } from '@byll/hermes'
import { dayjs } from 'helpers/dayjs'

interface Props {
  room: IRoom
  lock: IRoomLock | null
  onClose: () => void
}

@observer
export class RoomTileLockDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly reasons: any[]
  private readonly model: Model<{ reason: string | null; notes: string }>
  @observable private saving = false
  @observable private message: string | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      reason: props.lock?.reason || null,
      notes: props.lock?.notes || '',
    })
    this.reasons = [null, ...getRoomLockReasons(context.instance.id, context.permissions)]
    makeObservable(this)
  }

  @action
  private setReason = (reason: string | null) => {
    this.model.values.reason = reason
    if (!reason) {
      this.model.values.notes = ''
    }
    this.message = null
  }

  private onSubmit = async () => {
    runInAction(() => (this.saving = true))
    try {
      await hermes.update(
        `/api/${this.context.instance.id}/accommodations/rooms/${this.props.room.id}`,
        {
          lock: !this.model.values.reason
            ? null
            : {
                beginAt: dayjs().startOf('minute').toISOString(),
                beginBy: `${this.context.user.lastName}, ${this.context.user.firstName}`,
                endAt: null,
                endBy: '',
                reason: this.model.values.reason,
                notes: this.model.values.notes.trim(),
              },
        },
      )
      this.props.onClose()
    } catch (_e) {
      runInAction(() => {
        this.saving = false
        this.message = 'Das Sperren oder Entsperren ist fehlgeschlagen.'
      })
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {`Zimmersperre - ${this.props.room.label}`}
            </Dialog.Title>
          </div>
        </div>

        <Message color={!this.message ? 'primary' : 'danger'} className='mt-5'>
          {this.message ||
            (this.model.values.reason
              ? 'Wählen Sie einen anderen Sperrgrund oder entsperren Sie das Zimmer mit der Option "Offen".'
              : 'Wählen Sie einen Sperrgrund und geben Sie optional eine Sperrnotiz an.')}
        </Message>

        <InputText
          disabled={this.model.values.reason === null}
          model={this.model}
          name='notes'
          label='Sperrnotiz'
          className='my-5'
          placeholder='Optional'
        />

        <div className='my-5 flex gap-3 flex-wrap'>
          {this.reasons.map((r) => (
            <div key={r || '-'}>
              <Button
                onClick={() => this.setReason(r)}
                color={
                  this.model.values.reason === r
                    ? this.model.values.reason
                      ? 'danger'
                      : 'success'
                    : 'secondary'
                }
                outline={this.model.values.reason !== r}
              >
                {r ? (
                  <span key='lock' className='mr-1'>
                    <i className='fas fa-lock' />
                  </span>
                ) : (
                  <span key='unlock' className='mr-1'>
                    <i className='fas fa-unlock' />
                  </span>
                )}
                {r || 'Offen'}
              </Button>
            </div>
          ))}
        </div>

        <div className='col-span-4 text-right'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            Abbrechen
          </Button>
          {this.model.values.reason !== (this.props.lock?.reason || null) && (
            <Button color='primary' onClick={this.onSubmit} className='ml-2'>
              {this.model.values.reason ? 'Sperre speichern' : 'Sperre entfernen'}
            </Button>
          )}
        </div>

        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
