import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { RoundIcon } from 'components/RoundIcon'
import { observer } from 'mobx-react'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import { IWorkplanTimeSheet } from 'contracts/workplan/interfaces/IWorkplanTimeSheet'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'

interface Props {
  month: string
  employee: IWorkplanTimeSheet
  onDelete: (employee: IWorkplanTimeSheet) => void
}

export const EmployeeEntry: React.FC<Props> = observer(
  ({ month, employee, onDelete }) => {
    const [downloading, setDownloading] = React.useState(false)
    const context = React.useContext(AppContext)

    async function onDownload() {
      setDownloading(true)
      await createAndDownloadReport(
        'pdfStundenzettel',
        context.instance.id,
        { month, employeeIds: [employee.id] },
        `${employee.lastName} ${employee.firstName}.pdf`.replace(
          /[^a-zA-Z0-9äöüÄÖÜß. ]/g,
          '',
        ),
      )
      setDownloading(false)
    }

    return (
      <tr className='group'>
        <td className='px-6 py-4 whitespace-nowrap relative'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <img
                className='h-10 w-10 rounded-full'
                src={getUserImageSrc(
                  context.instance.id,
                  employee.imageId,
                  employee.sex,
                  'thumbnail',
                )}
                alt=''
              />
            </div>
            <div className='ml-4'>
              <div className='text-sm font-medium text-gray-900'>
                {`${employee.lastName.toUpperCase()}, ${employee.firstName}`}
                {employee.uploadedAt && (
                  <span className='ml-2 inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-white'>
                    Hochgeladen
                    <span className='ml-1'>
                      <i className='fas fa-check' />
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
          <RoundIcon
            classNameContainer='hidden group-hover:block'
            tooltip={{ text: 'Eintrag löschen', position: 'right' }}
            style={{ position: 'absolute', top: 5, left: 5 }}
            icon='fas fa-trash'
            color='danger'
            onClick={() => onDelete(employee)}
          />
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>{employee.company}</td>
        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
          {!downloading && (
            <span className='hover:underline cursor-pointer' onClick={onDownload}>
              Download
            </span>
          )}
          {downloading && (
            <span>
              <i className='fas fa-spin fa-spinner' />
            </span>
          )}
        </td>
      </tr>
    )
  },
)
