import { Menu, Transition } from '@headlessui/react'
import { getButtonStyle } from 'components/Form/components/Button/helpers/getButtonStyle'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

const options: InputSelectOption[] = [
  { label: 'Ticket', value: 'ticket' },
  { label: 'Maßnahme', value: 'action' },
]

interface Props {
  navigate: (url: string) => void
}

export class TicketDropdownButton extends React.Component<Props, {}> {
  static contextType = AppContext

  private onSelect(option: InputSelectOption) {
    if (option.value === 'ticket') {
      this.props.navigate(`/tickets/${this.context.defaults.ticketTodoListId}/newTicket`)
    } else {
      this.props.navigate(`/tickets/${this.context.defaults.actionTodoListId}/newAction`)
    }
  }

  render() {
    return (
      <Menu as='div' className='absolute top-4 right-4 inline-block text-left'>
        <Menu.Button
          className={`${getButtonStyle('secondary', {
            outline: true,
          })} px-2 py-1 text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          <span>
            <i className='fa fa-plus' />
          </span>
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={`right-0 origin-top-left absolute mt-2 py-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
            style={{ width: 224 }}
          >
            {options.map((option) => (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <button
                    onClick={() => this.onSelect(option)}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex items-center w-full px-3 py-2 text-sm`}
                  >
                    {option.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
