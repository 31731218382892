import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { RoundIcon } from 'components/RoundIcon'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { IInventoryLog } from 'contracts/inventory/interfaces/IInventoryLog'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'
import * as React from 'react'

interface Props {
  bundle: IInventoryBundle
  onClick: (bundle: IInventoryBundle) => Promise<IInventoryLog[] | null>
  onUndo: (logs: IInventoryLog[]) => void
  openBundle: (bundle: IInventoryBundle) => void
  check?: boolean
}

export const ResidentInventoryScanBundle: React.FC<Props> = ({
  bundle,
  onClick,
  onUndo,
  openBundle,
  check,
}) => {
  const [undo, setUndo] = React.useState<IInventoryLog[] | null>(null)
  const onIssueBundle = async () => {
    const logs = await onClick(bundle)
    setUndo(logs)
  }

  const onUndoBundle = (event) => {
    event.stopPropagation()
    if (!undo) {
      return
    }
    onUndo(undo)
    setUndo(null)
  }

  return (
    <tr
      className='last:border-0 border-b border-gray-300 hover:bg-gray-300 hover:cursor-pointer'
      onClick={onIssueBundle}
    >
      <td className='py-2 pl-4 pr-3 hidden md:block md:pl-0'>
        <div className='pl-2'>
          <img
            className='h-10 w-10 min-w-[2.5rem] rounded-full'
            src={getInventoryImageSrc(bundle.imageId, bundle.instanceId)}
            alt=''
          />
        </div>
      </td>
      <td className='py-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0'>
        <span className='pt-4 font-medium text-gray-900 pl-2'>
          <span className='max-w-[221px] truncate inline-block mr-2 align-middle'>
            {bundle.label}
          </span>
          {check ? (
            <CheckCircleIcon className='text-green-500 w-6 h-6 inline-block' />
          ) : (
            <XCircleIcon className='text-red-500 w-6 h-6 inline-block' />
          )}
          &nbsp;&nbsp;&nbsp;
        </span>
        {undo && (
          <Button color='primary' className='mr-3' outline onClick={onUndoBundle}>
            Rückgängig
          </Button>
        )}
        <RoundIcon
          classNameContainer='inline-block mr-2 w-4 h-4'
          tooltip={{ text: 'Inhalt ansehen', position: 'right' }}
          icon='fas fa-question'
          color='white'
          onClick={(e) => {
            e.stopPropagation()
            openBundle(bundle)
          }}
        />
      </td>
    </tr>
  )
}
