import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Model } from 'components/Form/Model'
import { action, makeObservable, observable, runInAction } from 'mobx'
import React from 'react'
import { CompoundCheckOutDialogSetDate } from './components/CompoundCheckOutDialogSetDate'
import { observer } from 'mobx-react'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { hermes } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { CompoundCheckOutDialogCheckOut } from './components/CompoundCheckOutDialogCheckOut'

interface Props {
  compoundId: string
  onClose: () => void
}

@observer
export class CompoundCheckOutDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<{ plannedCheckOutDate: string | null }>
  private compound: ICompound
  @observable step: 'setDate' | 'checkOut' = 'setDate'

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.compound = hermes.getFromStore<ICompound>(
      `/api/${context.instance.id}/accommodations/compounds/${props.compoundId}`,
    )!
    this.model = new Model({ plannedCheckOutDate: this.compound.plannedCheckOutDate })
    makeObservable(this)
  }

  @action
  private setStep = () => {
    if (this.step === 'setDate') {
      void hermes.patch(
        `/api/${this.context.instance.id}/accommodations/compounds/${this.props.compoundId}`,
        {
          plannedCheckOutDate: this.model.values.plannedCheckOutDate,
        },
      )
      this.step = 'checkOut'
    } else {
      this.step = 'setDate'
    }
  }

  private resetDate = async () => {
    await hermes.patch(
      `/api/${this.context.instance.id}/accommodations/compounds/${this.props.compoundId}`,
      {
        plannedCheckOutDate: null,
      },
    )
    runInAction(() => {
      this.model.values.plannedCheckOutDate = null
    })
  }

  render() {
    const compoundLabel = this.compound?.label || 'Gelände'
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='text-left'>
          <Dialog.Title
            as='h3'
            className='text-lg leading-6 font-medium text-gray-900 mb-4'
          >
            {compoundLabel} abgeben
          </Dialog.Title>
        </div>

        {this.step === 'setDate' && (
          <CompoundCheckOutDialogSetDate
            model={this.model}
            resetDate={this.resetDate}
            onClose={this.props.onClose}
            onStep={this.setStep}
          />
        )}

        {this.step === 'checkOut' && (
          <CompoundCheckOutDialogCheckOut
            compoundId={this.props.compoundId}
            checkOutDate={this.model.values.plannedCheckOutDate!}
            onClose={this.props.onClose}
            onStep={this.setStep}
          />
        )}
      </div>
    )
  }
}
