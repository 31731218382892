import * as React from 'react'
import styles from './styles.module.scss'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'
import { dayjs } from 'helpers/dayjs'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { ILogChange } from 'contracts/general/interfaces/ILog'

interface Props {
  family: (IResidentSearchResult & { changes: ILogChange[] })[]
  readOnly?: boolean
}

export const ResidentProfileExport: React.FC<Props> = ({ family, readOnly }) => {
  React.useEffect(setReadyFlagWhenRenderingComplete, [])

  function mapResident(resident: IResidentSearchResult & { changes: ILogChange[] }) {
    return (
      <div className='mt-4 relative table w-full border-collapse' key={resident.id}>
        <div
          className='table-row border-black border'
          style={{ pageBreakInside: 'avoid' }}
        >
          <div className='table-cell w-[200px] bg-gray-200 py-3.5 px-3 text-left text-sm font-semibold text-gray-900'>
            {`${resident.firstName} ${resident.lastName}`}
          </div>
          <div className='table-cell bg-gray-200 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
            Profildaten
          </div>
        </div>
        {resident.changes.map((change) => (
          <div
            className='table-row border border-black'
            key={change.field}
            style={{ pageBreakInside: 'avoid' }}
          >
            <div className='table-cell w-[200px] truncate py-4 px-3 text-sm font-medium text-gray-900'>
              {change.field}
            </div>
            <div className='table-cell px-3 py-4 text-sm text-gray-500'>{change.to}</div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div
      className={styles.page}
      style={
        readOnly ? { paddingTop: 0, paddingBottom: 0, minHeight: '1060px' } : undefined
      }
    >
      <div className='p-4 text-center text-lg'>
        Export Bewohnerstammdaten
        <br />
        {`Stand ${dayjs().format('DD.MM.YYYY')}`}
        <br />
        <br />
      </div>
      <div className='border border-black p-3'>
        <div className='text-xs text-gray-500'>
          {family.length > 1 ? 'Familienmitglieder' : 'Bewohner'}
        </div>
        <div className='text-sm'>
          {family.map((r) => r.firstName + ' ' + r.lastName).join(', ')}
        </div>
      </div>

      {family.map(mapResident)}
    </div>
  )
}
