import * as React from 'react'
import { observer } from 'mobx-react'
import { Dialog } from '@headlessui/react'
import { AnnotationIcon } from '@heroicons/react/outline'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { z } from 'zod'
import { action, makeObservable } from 'mobx'

interface Props {
  onClose: (type?: 'typo correction' | 'new name') => void
}

@observer
export class NameChangeDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ type: '' | 'typo correction' | 'new name' }>

  constructor(props: Props) {
    super(props)
    this.model = new Model({ type: '' }, z.object({ type: z.string().min(1) }))
    makeObservable(this)
  }

  private onSubmit = async () => {
    if (this.model.values.type === '') {
      this.model.touchAll()
      return
    }
    this.props.onClose(this.model.values.type)
  }

  @action
  private onChange = (event) => {
    this.model.values.type = event.target.value
  }

  render() {
    return (
      <>
        <div className='sm:flex sm:items-start'>
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-indigo-100`}
          >
            <AnnotationIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />
          </div>
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-auto'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Namensänderung
            </Dialog.Title>

            <div
              className={`text-sm mt-2 mb-4 ${
                this.model.touched.has('type') ? 'text-red-500' : ''
              }`}
            >
              Bitte geben Sie den Grund für die Namensänderung an
            </div>

            <div onChange={this.onChange}>
              <div className='flex items-center'>
                <input
                  id='default-radio-1'
                  type='radio'
                  value='type correction'
                  name='default-radio'
                  className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                />
                <label
                  htmlFor='default-radio-1'
                  className={`ml-2 text-sm font-medium dark:text-gray-300 ${
                    this.model.touched.has('type') ? 'text-red-500' : 'text-gray-900'
                  }`}
                >
                  Korrektur eines Rechtschreibfehlers
                </label>
              </div>
              <div className='text-gray-500 text-sm mb-4 ml-6'>
                (Name wird nicht in die Liste der alten Namen aufgenommen)
              </div>

              <div className='flex items-center'>
                <input
                  id='default-radio-2'
                  type='radio'
                  value='new name'
                  name='default-radio'
                  className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                />
                <label
                  htmlFor='default-radio-2'
                  className={`ml-2 text-sm font-medium dark:text-gray-300 ${
                    this.model.touched.has('type') ? 'text-red-500' : 'text-gray-900'
                  }`}
                >
                  Offizielle Namensänderung
                </label>
              </div>
              <div className='text-gray-500 text-sm mb-4 ml-6'>
                (Alter Name wird in die Liste der alten Namen aufgenommen)
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
          <button
            type='button'
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500`}
            onClick={this.onSubmit}
          >
            Speichern
          </button>
          <button
            type='button'
            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
            onClick={() => this.props.onClose()}
          >
            Abbrechen
          </button>
        </div>
      </>
    )
  }
}
