import { Dayjs } from 'dayjs'
import { IRoomLock } from './../interfaces/IRoomLock'

export function getRoomLockAt(locks: IRoomLock[], at?: Dayjs): IRoomLock | null {
  if (!at) {
    if (locks.length === 0) {
      return null
    }
    const lock = locks[0]
    if (lock.endAt) {
      return null
    }
    return lock
  }

  const now: any = at
  for (const lock of locks) {
    if (now.isSameOrAfter(lock.beginAt) && (!lock.endAt || now.isBefore(lock.endAt))) {
      return lock
    }
  }
  return null
}
