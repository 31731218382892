import { Collection, hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { IInventoryItemScan } from 'contracts/inventory/interfaces/IInventoryItemScan'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { getInventoryItemScanGroups } from '../helpers/getInventoryItemScanGroups'

interface Props {
  compoundId: string
  residentId: string
}

@observer
export class InventoryScanItemLog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly logs: Collection<IInventoryItemScan>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.logs = new Collection(`/api/${context.instance.id}/inventory/logs`, {
      compoundId: props.compoundId,
      residentId: props.residentId,
    })
  }

  componentDidMount() {
    this.disposers.push(this.logs.init({ readOnly: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onDelete = async (log: IInventoryItemScan) => {
    try {
      await hermes.delete(`/api/${this.context.instance.id}/inventory/logs/${log.id}`, {
        immediately: true,
      })
    } catch (e: any) {
      box.alert(
        'Fehler',
        e.id === NotAuthorizedError.id
          ? 'Sie haben nicht die nötige Berechtigung, um diesen Eintrag zu löschen.'
          : 'Der Eintrag konnte nicht gelöscht werden.',
      )
    }
  }

  render() {
    const groups = getInventoryItemScanGroups(this.logs.resources)
    return (
      <div className='absolute w-[280px] top-[120px] bg-white bottom-0 right-0 overflow-y-auto overflow-x-hidden shadow-md p-6'>
        <div className='border-b-2'>
          <span className='text-xl text-gray-500'>Ausgabehistorie</span>
        </div>
        <div className='mt-4'>
          {groups.map((day) => (
            <div key={day.date}>
              <div className='mt-2'>
                <span className='text-gray-500'>
                  {dayjs(day.date).format('DD.MM.YYYY')}
                </span>
              </div>
              {day.logs.map((log) => (
                <div key={log.id}>
                  {`${log.count} x ${log.label}`}
                  {dayjs(log.issuedAt).isSame(dayjs(), 'day') && (
                    <span
                      className='text-gray-400 cursor-pointer ml-4'
                      onClick={() => this.onDelete(log)}
                    >
                      <i className='far fa-trash-alt' />
                    </span>
                  )}
                  {log.warnMessage && (
                    <div className='text-red-600 truncate'>{log.warnMessage}</div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
