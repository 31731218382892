import * as React from 'react'
import { Dialog } from 'components/Dialog'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { CreateResidentMask } from './components/CreateResidentMask'
import { FindResidentMask } from './components/FindResidentMask'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'
import { Collection } from '@byll/hermes'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IFamily } from 'contracts/residents/interfaces/IFamily'

interface Props {
  familyId: string
  open: boolean
  setOpen: (open: boolean) => void
  residents: Collection<IResidentSearchResult> // Used to copy data from hv
  family: IFamily
}

@observer
export class AddMemberDialog extends React.Component<Props, {}> {
  @observable private tab: 'new' | 'find' = 'new'

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setTabNew = () => (this.tab = 'new')
  @action private setTabFind = () => (this.tab = 'find')

  render() {
    return (
      <Dialog open={this.props.open} setOpen={this.props.setOpen} size='md'>
        <div className='p-6'>
          {/* Switch between 'new person' and 'existing person' masks */}
          <span className='relative z-0 inline-flex shadow-sm rounded-md'>
            <button
              type='button'
              className={`${
                this.tab === 'new'
                  ? 'border-indigo-300 bg-indigo-500 text-white hover:bg-indigo-400'
                  : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
              } relative inline-flex items-center px-4 py-2 rounded-l-md border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
              onClick={this.setTabNew}
            >
              Neue Person
            </button>
            <button
              type='button'
              className={`${
                this.tab === 'find'
                  ? 'border-indigo-300 bg-indigo-500 text-white hover:bg-indigo-400'
                  : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
              } relative inline-flex items-center px-4 py-2 rounded-r-md border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
              onClick={this.setTabFind}
            >
              Bereits erfasste Person
            </button>
          </span>

          {this.tab === 'new' && (
            <CreateResidentMaskWithRouteProps
              familyId={this.props.familyId}
              setOpen={this.props.setOpen}
              residents={this.props.residents}
              family={this.props.family}
            />
          )}
          {this.tab === 'find' && (
            <FindResidentMaskWithRouteProps
              familyId={this.props.familyId}
              setOpen={this.props.setOpen}
            />
          )}
        </div>
      </Dialog>
    )
  }
}

export const CreateResidentMaskWithRouteProps: React.FC<{
  familyId
  setOpen
  residents
  family
}> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <CreateResidentMask
      navigate={navigate}
      location={location}
      familyId={props.familyId}
      setOpen={props.setOpen}
      residents={props.residents}
      family={props.family}
    />
  )
}

const FindResidentMaskWithRouteProps: React.FC<{ familyId; setOpen }> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <FindResidentMask
      navigate={navigate}
      location={location}
      familyId={props.familyId}
      setOpen={props.setOpen}
    />
  )
}
