import { z } from 'zod'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isTime } from '../../general/helpers/isTime'
import { isYmdDate } from '../../general/helpers/isYmdDate'

export const DocumentationCreateValidator = z.object({
  compoundId: z.string().refine(isIntegerString),
  date: z.string().refine(isYmdDate),
  time: z.union([z.string().max(0), z.string().refine(isTime)]),
  type: z.string().min(1).max(255),
  topics: z.array(z.string().min(1).max(255)),
  notes: z.string().min(1),
  residentIds: z.array(z.string().refine(isIntegerString)).nonempty(),
  roleIds: z.array(z.string().refine(isIntegerString)),
  todoId: z.string().refine(isIntegerString).nullable(),
})
