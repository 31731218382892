import { Resource } from '@byll/hermes'
import { ResourceOptions } from '@byll/hermes/lib/interfaces/ResourceOptions'
import { useEffect, useState } from 'react'

export function useResource<T extends { id: string }>(
  path: string,
  options?: ResourceOptions<T>,
): Resource<T> {
  const [res] = useState(() => new Resource<T>(path))
  useEffect(() => {
    return res.init(options)
    // eslint-disable-next-line
  }, [res])
  return res
}
