import * as React from 'react'
import { Collection, hermes } from '@byll/hermes'
import { Card } from 'components/Card'
import { Dialog } from 'components/Dialog'
import { RoundIcon } from 'components/RoundIcon'
import { IBlogpost } from 'contracts/dashboard/interfaces/IBlogpost'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { BlogpostEditDialog } from './components/BlogpostEditDialog'
import { dayjs } from 'helpers/dayjs'
import { box } from 'services/box'
import { toast } from 'react-toastify'

interface Props {
  blogposts: Collection<IBlogpost>
}

@observer
export class BlogpostList extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private showEditDialog: boolean = false
  @observable private newBlog: Partial<IBlogpost> = {
    id: undefined,
    title: '',
    subtitle: '',
    text: '',
    imageId: '',
    isActive: true,
    markdown: false,
  }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
  }

  @action
  private openEditBlogpostDialog = (blog?: IBlogpost) => {
    if (blog?.id) {
      this.newBlog = {
        id: blog.id,
        title: blog.title,
        subtitle: blog.subtitle,
        text: blog.text,
        imageId: blog.imageId,
        isActive: blog.isActive,
        markdown: blog.markdown,
      }
    } else {
      this.newBlog = {
        id: undefined,
        title: '',
        subtitle: '',
        text: '',
        imageId: '',
        isActive: true,
        markdown: false,
      }
    }
    this.showEditDialog = !this.showEditDialog
  }

  private deleteBlogpost = async (id: string) => {
    if (
      !(await box.alert(
        'Blogpost löschen',
        'Möchten Sie diesen Blogpost wirklich unwiderruflich löschen?',
        { color: 'danger', cancel: 'Abbrechen', confirm: 'Löschen' },
      ))
    ) {
      return
    }
    try {
      await hermes.delete(`/api/${this.context.instance.id}/blogposts/${id}`)
      toast.success('Blogpost erfolgreich gelöscht.')
    } catch (_e) {
      void box.alert(
        'Löschen fehlgeschlagen',
        'Der Blogpost konnte nicht gelöscht werden.',
        { color: 'danger' },
      )
    }
  }

  render() {
    if (!this.props.blogposts?.resources) {
      return null
    }
    return (
      <>
        {this.props.blogposts.resources.length === 0 && (
          <Card>
            <div className='p-4 relative'>
              <div className='font-bold text-xl mb-3'>Blogartikel</div>
              <RoundIcon
                onClick={this.openEditBlogpostDialog}
                icon='fas fa-plus'
                style={{ position: 'absolute', top: 15, right: 15 }}
              />
              <div className='text-gray-500'>
                Derzeit sind keine Blogartikel vorhanden. Verwenden Sie das Plus oben
                rechts, um Artikel zu erstellen.
              </div>
            </div>
            {this.showEditDialog && (
              <Dialog size='lg' open={this.showEditDialog} setOpen={() => {}}>
                <BlogpostEditDialog
                  blogpost={this.newBlog}
                  onClose={this.openEditBlogpostDialog}
                />
              </Dialog>
            )}
          </Card>
        )}

        {this.props.blogposts.resources.length > 0 && (
          <Card className='relative'>
            <div className='p-4 relative'>
              <div className='font-bold text-xl'>Blogartikel</div>
              <RoundIcon
                onClick={this.openEditBlogpostDialog}
                icon='fas fa-plus'
                style={{ position: 'absolute', top: 15, right: 15 }}
              />
              <ul className='divide-y divide-gray-100 mt-2 -mx-1'>
                {this.props.blogposts.resources?.map((b) => {
                  if (!b.data) {
                    return null
                  }
                  return (
                    <li
                      key={`${b.data.id}-${b.data.isActive}`}
                      className='flex gap-x-4 py-2 group relative cursor-pointer hover:bg-indigo-100 rounded-lg p-1 overflow-hidden'
                      onClick={() => this.openEditBlogpostDialog(b.data!)}
                    >
                      <div className='min-w-0'>
                        <div className='flex items-center'>
                          {b.data.isActive ? (
                            <span className='text-indigo-600'>
                              <i className='fa fa-eye' />
                            </span>
                          ) : (
                            <span className='text-gray-300'>
                              <i className='fa fa-eye-slash' />
                            </span>
                          )}
                          <div className='text-sm font-semibold leading-6 text-gray-800 ml-2 truncate'>
                            {b.data.title}
                          </div>
                        </div>
                        <div className='flex items-center'>
                          <div className='flex-content truncate text-xs leading-5 text-gray-500'>
                            {dayjs(b.data.createdAt).format('DD.MM.YYYY')}
                          </div>
                          <div className='truncate text-xs leading-5 text-gray-500 ml-2'>
                            {b.data.subtitle}
                          </div>
                        </div>
                      </div>
                      <div className='hidden group-hover:block'>
                        <RoundIcon
                          onClick={(event) => {
                            event.stopPropagation()
                            this.deleteBlogpost(b.data!.id)
                          }}
                          icon='fas fa-trash'
                          color='danger'
                          style={{ position: 'absolute', top: 5, right: 5 }}
                          tooltip={{ text: 'Blogpost löschen', position: 'left' }}
                        />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>

            {this.showEditDialog && (
              <Dialog size='lg' open={this.showEditDialog} setOpen={() => {}}>
                <BlogpostEditDialog
                  blogpost={this.newBlog}
                  onClose={this.openEditBlogpostDialog}
                />
              </Dialog>
            )}
          </Card>
        )}
      </>
    )
  }
}
