import * as React from 'react'
import styles from '../../styles.module.scss'
import logo from './logo.png'
import { ICostCoverageInvoice } from 'contracts/costCoverages/interfaces/ICostCoverageInvoice'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { months } from 'components/Form/components/InputMonth'
import { toLocaleNumber } from 'contracts/general/helpers/toLocaleNumber'
import { Button } from 'components/Form/components/Button'
import { allImagesLoaded } from 'modules/Pdf/helpers/allImagesLoaded'
import { hasWaiters } from 'modules/Pdf/helpers/hasWaiters'
import { ICostCoverageInvoicePosition } from 'contracts/costCoverages/interfaces/ICostCoverageInvoicePosition'
import { CostCoverageInvoicePosition } from './components/CostCoverageInvoicePosition'
import { observer } from 'mobx-react'
import { action, makeObservable, runInAction } from 'mobx'
import * as uuid from 'uuid'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  invoice: ICostCoverageInvoice
  readOnly?: boolean
}

@observer
export class CostCoverageInvoiceLfgb extends React.Component<Props, {}> {
  static contextType = AppContext
  private month = dayjs(this.props.invoice.month, 'YYYY-MM')
  private isStorno = !!this.props.invoice.stornoDate

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    this.setReadyFlagWhenRenderingComplete()
  }

  private setReadyFlagWhenRenderingComplete = () => {
    // Wait until none of the children reports a blocker. (Elements with class "wait" are blockers)
    // Blockers are used if a components loads something asynchronously and cannot show the
    // final content yet.
    const timer = setInterval(() => {
      if (hasWaiters() || !allImagesLoaded()) {
        console.log('Waiter detected, try again...')
        return
      }
      console.log('Rendering completed. Final result is displayed.')
      ;(window as any).ready = true
      clearInterval(timer)
    }, 40)
  }

  @action
  private onDelete = async (position: ICostCoverageInvoicePosition) => {
    const index = this.props.invoice.additionalPositions.indexOf(position)
    this.props.invoice.additionalPositions.splice(index, 1)
  }

  @action
  private addPosition = () => {
    runInAction(() => {
      this.props.invoice.additionalPositions.push({
        id: uuid.v4(),
        costCoverageId: this.props.invoice.costCoverageId,
        buildingGroupId: this.props.invoice.id.split('-')[1],
        month: this.props.invoice.month,
        label: '',
        amount: '0.00',
      })
    })
  }

  private getAdditionalPositionSum() {
    let sum: number = 0
    for (const pos of this.props.invoice.additionalPositions) {
      sum += parseFloat(pos.amount || '0')
    }
    return sum
  }

  render() {
    const { readOnly } = this.props
    const isEditable = this.props.invoice.id.includes('-')
    return (
      <div className={styles.page}>
        {/* Header */}
        <div className='text-lg font-bold leading-5 relative'>
          <img
            src={logo}
            className='absolute right-0 top-0'
            alt=''
            style={{ width: 360 }}
          />
        </div>

        {/* Addressfeld */}
        <div className='absolute' style={{ width: 9 * 40, top: 197 }}>
          <div className='border-b border-black' style={{ fontSize: '9px' }}>
            LfG Berlin, Betriebsteil B | Brunnenstr. 110 C | 13355 Berlin
          </div>
          <div className='text-xs leading-5'>
            Landesamt für Flüchtlingsangelegenheiten (LAF)
            <br />
            Abteilung ZS D 4400
            <br />
            Darwinstraße 14-18
            <br />
            10589 Berlin
          </div>
        </div>

        {/* Rechte Leiste */}
        <div className='absolute text-xs text-right leading-5 right-[60px] w-[240px] top-[160px]'>
          Dienstgebäude:
          <br />
          Brunnenstr. 110 C<br />
          13355 Berlin
          <br />
          <br />
          <strong>
            Berlin,{' '}
            {dayjs(
              this.isStorno
                ? this.props.invoice.stornoDate
                : this.props.invoice.invoiceDate,
            ).format('DD.MM.YYYY')}
          </strong>
          <br />
          <br />
          Ihr Ansprechpartner:
          <div className='whitespace-pre truncate'>{this.props.invoice.contact}</div>
          <br />
          Bitte Rechnungsnummer unbedingt
          <br />
          bei der Zahlung mit angeben.
          <br />
        </div>

        {/* Überschrift */}
        <div className='relative text-lg font-bold leading-6' style={{ paddingTop: 374 }}>
          {this.isStorno
            ? `Stornorechnung ${this.props.invoice.stornoNumber}`
            : `Rechnung ${this.props.invoice.invoiceNumber}`}
          <br />
          {this.props.invoice.title}
          <br />
        </div>
        <div className='text-xs pt-2 pb-3'>
          Für die nachfolgend aufgeführte Unterbringung im Monat{' '}
          {`${months[this.month.month()]} ${this.month.year()}`} stellen wir Ihnen wie
          folgt in Rechnung:
        </div>

        {/* Position */}
        <div className='grid grid-cols-[1fr_auto_auto_auto_auto] gap-y-1 gap-x-5 text-xs'>
          {this.props.invoice.positions.map((p, i) => (
            <React.Fragment key={i}>
              {p.summaries.map((s, j) => (
                <React.Fragment key={j}>
                  <div className='truncate'>{`${p.lastName}, ${p.firstName}${
                    i > 0 ? ' *' : ''
                  }`}</div>
                  <div className='truncate'>
                    {p.dateOfBirth ? dayjs(p.dateOfBirth).format('DD.MM.YYYY') : ''}
                  </div>
                  <div className='truncate'>{`${dayjs(s.beginDate).format(
                    'DD.MM.',
                  )} - ${dayjs(s.endDate).format('DD.MM.')}`}</div>
                  <div className='truncate text-right'>{`${s.days} x ${toLocaleNumber(
                    s.dailyRate,
                  )} €`}</div>
                  <div className='text-right'>{`${toLocaleNumber(
                    (s.days * +s.dailyRate * (this.isStorno ? -1 : 1)).toFixed(2),
                  )} €`}</div>
                </React.Fragment>
              ))}
              {p.absences.map((a, j) => (
                <React.Fragment key={j}>
                  <div className='truncate pl-10'>Berücksichtigt:</div>
                  <div className='truncate'>{a.type || 'Taggenau'}</div>
                  <div className='truncate'>{`${dayjs(a.beginDate).format(
                    'DD.MM.',
                  )} - ${dayjs(a.endDate).format('DD.MM.')}`}</div>
                  <div className='truncate text-right'>{`${a.days} ${
                    a.days === 1 ? 'Tag' : 'Tage'
                  }`}</div>
                  <div className='text-right'>&nbsp;</div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          {(!isEditable || readOnly) &&
            this.props.invoice.additionalPositions.map((p, i) => (
              <React.Fragment key={i}>
                <div className='truncate col-span-4'>{p.label}</div>
                <div className='text-right'>{`${toLocaleNumber(
                  (+p.amount * (this.isStorno ? -1 : 1)).toFixed(2),
                )} €`}</div>
              </React.Fragment>
            ))}
          {isEditable &&
            !readOnly &&
            this.props.invoice.additionalPositions.map((p) => (
              <CostCoverageInvoicePosition
                key={p.id}
                position={p}
                onDelete={this.onDelete}
              />
            ))}
          <div className='col-span-2' />
          <div className='pt-4 col-span-2 truncate text-right font-bold border-t border-black'>
            Rechnungsbetrag
          </div>
          <div className='pt-4 truncate text-right font-bold border-t border-black'>
            {toLocaleNumber(
              (
                (+this.props.invoice.grossTotalWithoutAdditionalPositions +
                  this.getAdditionalPositionSum()) *
                (this.isStorno ? -1 : 1)
              ).toFixed(2),
            )}{' '}
            €
          </div>
          {isEditable && !readOnly && (
            <Button onClick={this.addPosition}>Rechnungsposition hinzufügen</Button>
          )}
        </div>

        {/* Summe */}
        <div style={{ height: 22 }} />

        {/* Text */}
        <div className='text-xs pt-1'>
          <span className='italic'>*: Familienangehörige</span>
          <br />
          <br />
          <b>Anlagen:</b>
          <br />
          Kostenübernahme vom{' '}
          {dayjs(this.props.invoice.costCoverageIssueDate).format('DD.MM.YYYY')} (
          {this.props.invoice.aktenzeichen})<br />
          Unterkunftsnachweis
          <br />
          <br />
          {!this.isStorno && (
            <b>
              Der Rechnungsbetrag ist innerhalb von 30 Tagen fällig.
              <br />
              Zahlungen sind auf das unten angegebene Konto zu leisten.
              <br />
            </b>
          )}
          <br />
          Nach bestem Wissen und Gewissen: Wir versichern, dass der Bewohner an den Tagen,
          für welche wir den Tagessatz in voller Höhe
          <br />
          berechnet haben, in der Unterkunft anwesend war und wir diesem gegenüber die
          vereinbarten Leistungen erbracht haben.
          <br />
        </div>

        {/* Footer */}
        <div
          className='grid grid-cols-3 gap-4 text-xs text-gray-500 absolute'
          style={{ bottom: 40, fontSize: 9 }}
        >
          <div>
            LfG Landesbetrieb für Gebäudebewirtschaftung Berlin
            <br />
            Betriebsteil B<br />
            Geschäftsadresse der Verwaltung
            <br />
            Brunnenstraße 110c, 13355 Berlin
          </div>
          <div>
            Rechtsform: Landesbetrieb
            <br />
            Träger: Land Berlin, Senatsverwaltung für Finanzen
            <br />
            Rechtsgrundlage: Geschäftsanweisung vom 6. April 2017
            <br />
            Geschäftsleitung: Bilkay Öney
          </div>
          <div>
            Bankverbindung:
            <br />
            Berliner Sparkasse
            <br />
            IBAN: DE02 1005 0000 0190 6215 08
            <br />
            BIC: BELADEBEXXX
          </div>
        </div>

        {this.props.children}
      </div>
    )
  }
}
