import { IPermissions } from 'contracts/users/interfaces/IPermissions'
import { isStammBuilding } from 'helpers/isStamm'

export interface IEnabledInternalResidenceFormFields {
  error: string | null
  all: boolean
  billingStatus: boolean
  billingCategory: boolean
  documentId: boolean
  billingDateOfNoticeCreation: boolean
  billingAvDate: boolean
}

const dict = {
  'internal-residence': 'Belegung',
  'internal-reservation': 'Reservierung',
  'end-of-billing': 'Meldung',
}

export function getEnabledFields(
  isNew: boolean,
  type: 'internal-residence' | 'internal-reservation' | 'end-of-billing',
  buildingId: string | null,
  checkInPermission: 0 | 1 | 2,
  permissions: IPermissions,
): IEnabledInternalResidenceFormFields {
  const enabled: IEnabledInternalResidenceFormFields = {
    error: null,
    all: true,

    // Those fields are only relevant for editing existing internal residences
    // because the form doesn't show those fields for new (isNew) bookings.
    billingStatus: type !== 'end-of-billing' && permissions.booking_feeStatus === 3,
    billingCategory: type !== 'end-of-billing' && permissions.booking_feeCategory === 3,
    documentId: permissions.booking_feeNotice === 3,
    billingDateOfNoticeCreation: permissions.booking_feeDateOfNoticeCreation === 3,
    billingAvDate: permissions.booking_feeDateOfNoticeCreation === 3,
  }

  if (!isNew) {
    if (permissions.resident_patchBookings === 0) {
      enabled.all = false
    }
    if (
      type === 'internal-residence' &&
      permissions.resident_patchBookings === 1 &&
      !isStammBuilding(buildingId || '')
    ) {
      enabled.all = false
    }
    if (!Object.keys(enabled).some((key) => enabled[key] === true)) {
      // all disabled
      enabled.error = `Sie können diese ${dict[type]} nur ansehen, aber nicht ändern.`
    }
  }

  if (isNew) {
    if (type === 'internal-residence' && !checkInPermission) {
      enabled.all = false
      enabled.error =
        'Sie haben nicht die nötige Berechtigung zur Erstellung einer Buchung. Bitte wenden Sie sich an einen Administrator.'
    }
    if (type === 'internal-reservation' && !permissions.resident_reservationCheckInOut) {
      enabled.all = false
      enabled.error =
        'Sie haben nicht die nötige Berechtigung zur Erstellung einer Reservierung. Bitte wenden Sie sich an einen Administrator.'
    }
  }

  return enabled
}
