import * as React from 'react'
import { Model } from '../../../../../../../../../../components/Form/Model'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { isTime } from 'contracts/general/helpers/isTime'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { z } from 'zod'
import { Collection } from '@byll/hermes'
import { dayjs } from 'helpers/dayjs'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { AppContext } from 'services/connection/models/AppContext'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IInternalResidenceFormModel } from '../InternalResidenceForm/interfaces/IInternalResidenceFormModel'
import { InternalResidenceFormStep1 } from '../InternalResidenceForm/components/InternalResidenceFormStep1'
import { InternalResidenceFormStep2 } from '../InternalResidenceForm/components/InternalResidenceFormStep2'
import { InternalResidenceFormStep4 } from '../InternalResidenceForm/components/InternalResidenceFormStep4'
import { IResident } from 'contracts/residents/interfaces/IResident'

interface Props {
  resident: IResident
  booking: IBooking
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  bookings?: IBooking[]
}

@observer
export class InternalReservationForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IInternalResidenceFormModel> // Label contains the room path + label
  @observable private step: 'form' | 'rooms' | 'done' = 'form'
  @observable private saving = false

  constructor(props: Props) {
    super(props)
    const beginAt = dayjs(props.booking.beginAt)
    const endAt = props.booking.endAt ? dayjs(props.booking.endAt) : null
    const validator = z.object({
      beginDate: z.string(),
      beginTime: z.string().refine(isTime),
      endDate: z
        .union([z.string(), z.null()])
        .refine((val) => !this.model.values.hasEnd || val !== null),
      endTime: z.string().refine((val) => !this.model.values.hasEnd || isTime(val)),
    })
    this.model = new Model(
      {
        isNew: !props.booking.id,
        id: props.booking.id,
        type: props.booking.type,
        beginDate: beginAt.format('YYYY-MM-DD'),
        beginTime: beginAt.format('HH:mm'),
        endDate: endAt?.format('YYYY-MM-DD') || null,
        endTime: endAt?.format('HH:mm') || '',
        hasEnd: !!endAt,
        compoundId: null,
        buildingId: null,
        roomId: props.booking.roomId,
        bed: props.booking.bed,
        comment: props.booking.comment,
        label: props.booking.label,
        reason: '',
        occupancy: null,
      },
      validator,
    )
    makeObservable(this)
  }

  @action
  private setStep = (step: 'form' | 'rooms' | 'beds' | 'done') => {
    if (step === 'beds') {
      step = 'done'
    }
    this.step = step
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Zimmerreservierung
            </Dialog.Title>
          </div>
        </div>

        {/* From / till / edit */}
        {this.step === 'form' && (
          <InternalResidenceFormStep1
            model={this.model}
            setStep={this.setStep}
            onClose={this.props.onClose}
            color='secondary'
            bookings={this.props.bookings}
            checkInPermission={0}
          />
        )}

        {/* Select room */}
        {this.step === 'rooms' && (
          <InternalResidenceFormStep2
            model={this.model}
            setStep={this.setStep}
            onClose={this.props.onClose}
            color='secondary'
            checkInPermission={0}
          />
        )}

        {/* Save => show error or close (only for process: 'create booking') */}
        {this.step === 'done' && (
          <InternalResidenceFormStep4
            model={this.model}
            onClose={this.props.onClose}
            color='secondary'
            booking={this.props.booking}
          />
        )}

        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
