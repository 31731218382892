import { hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputMonth } from 'components/Form/components/InputMonth'
import { Message } from 'components/Message'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import dayjs from 'dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { BuildingShifts } from './components/BuildingShifts'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { box } from 'services/box'
import { IShift } from 'contracts/workplan/interfaces/IShift'

interface Props {}

@observer
export class CapacityEvaluation extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ month: string }>
  @observable private loading: boolean = false
  @observable private error: string | null = null
  @observable private buildings: IBuilding[] | null = null
  @observable private shifts: IShift[] | null = null
  @observable private selectedShifts: Map<string, { id: string; checked: boolean }[]> =
    new Map() // buildingId -> { shiftId, checked: boolean }[]

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({
      month: dayjs().format('YYYY-MM'),
    })
  }

  componentDidMount(): void {
    this.loadBuildings()
    this.loadShifts()
  }

  private loadBuildings = async () => {
    runInAction(() => (this.loading = true))
    try {
      const buildings = await hermes.indexOnceNew<IBuilding>(
        `/api/${this.context.instance.id}/accommodations/buildings`,
      )
      runInAction(() => (this.buildings = buildings))
    } catch (_e) {
      runInAction(
        () => (this.error = 'Beim Laden der Gebäude ist ein Fehler aufgetreten.'),
      )
    }
    runInAction(() => (this.loading = false))
  }

  private loadShifts = async () => {
    runInAction(() => (this.loading = true))
    try {
      const shifts = await hermes.indexOnceNew<IShift>(
        `/api/${this.context.instance.id}/workplan/shifts?month=${this.model.values.month}`,
      )
      runInAction(() => (this.shifts = shifts))
      for (const building of this.buildings ?? []) {
        const checkedArray = shifts.map((s) => ({ id: s.id, checked: true }))
        if (checkedArray.length > 0) {
          this.selectedShifts.set(building.id, checkedArray)
        } else {
          this.selectedShifts.delete(building.id)
        }
      }
    } catch (_e) {
      runInAction(
        () => (this.error = 'Beim Laden der Schichten ist ein Fehler aufgetreten.'),
      )
    }
    runInAction(() => (this.loading = false))
  }

  private downloadReport = async () => {
    const buildings: { buildingId: string; shiftIds: string[] }[] = []
    this.selectedShifts.forEach((shiftsArray, buildingId) => {
      const buildingShifts = this.shifts?.filter((s) => s.buildingId === buildingId)
      if (
        shiftsArray.filter((s) => s.checked).length === 0 ||
        buildingShifts?.length === 0
      ) {
        return
      }
      buildings.push({
        buildingId,
        shiftIds: shiftsArray
          .filter((s) => buildingShifts?.find((s2) => s2.id === s.id) && s.checked)
          .map((s) => s.id),
      })
    })
    if (buildings.length === 0) {
      box.alert('Fehler', 'Bitte wählen Sie mindestens eine Schicht aus.')
      return
    }
    runInAction(() => (this.loading = true))
    try {
      await createAndDownloadReport(
        'capacityEvaluation',
        this.context.instance.id,
        { month: this.model.values.month, buildings },
        'Kapazitätsauswertung.xlsx',
      )
    } catch (_e) {
      runInAction(
        () => (this.error = 'Beim Erstellen des Berichts ist ein Fehler aufgetreten.'),
      )
    }
    runInAction(() => (this.loading = false))
  }

  @action selectAll = () => {
    for (const building of this.buildings ?? []) {
      const checkedArray = this.selectedShifts.get(building.id)
      if (checkedArray) {
        runInAction(() => checkedArray.forEach((s) => (s.checked = true)))
        runInAction(() => this.selectedShifts.set(building.id, checkedArray))
      }
    }
  }

  @action selectNone = () => {
    for (const building of this.buildings ?? []) {
      const checkedArray = this.selectedShifts.get(building.id)
      if (checkedArray) {
        runInAction(() => checkedArray.forEach((s) => (s.checked = false)))
        runInAction(() => this.selectedShifts.set(building.id, checkedArray))
      }
    }
  }

  render() {
    if (!this.context.permissions.report_capacityEvaluation) {
      return <Forbidden />
    }
    return (
      <CardColumnCard
        title='Kapazitätsauswertung'
        subtitle='Wählen Sie die Gebäude/Schichten und einen Monat aus, für die eine Kapazitätsauswertung erstellt werden soll.'
      >
        {this.error && <Message color='danger'>{this.error}</Message>}
        <div>
          <InputMonth model={this.model} name='month' />
        </div>
        <div className='flex flex-col gap-4 my-4'>
          <div className='flex'>
            <span
              onClick={this.selectAll}
              className='pl-2 pr-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100'
              style={{ borderRadius: '4px 0 0 4px' }}
            >
              Alle auswählen
            </span>
            <span
              onClick={this.selectNone}
              className='pl-1 pr-2 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer'
              style={{ borderRadius: '0 4px 4px 0' }}
            >
              Alle abwählen
            </span>
          </div>
          {this.buildings?.map((building) => (
            <BuildingShifts
              key={`${building.id}-${this.model.values.month}`}
              building={building}
              month={this.model.values.month}
              selectedShifts={this.selectedShifts}
              shifts={this.shifts ?? []}
            />
          ))}
        </div>
        <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
