import { Collection, hermes, Resource } from '@byll/hermes'
import { SearchIcon, XCircleIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { Spinner } from 'components/Spinner'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { Age } from 'components/Age'
import { isEmptySearchResultsFilter } from 'contracts/residents/helpers/isEmptySearchResultsFilter'
import { sleep } from 'helpers/sleep'

interface Props {
  setOpen: (open: boolean) => void
  familyId: string
  location
  navigate
}

@observer
export class FindResidentMask extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private saving = false
  @observable private error: string | null = null
  private readonly residents: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly model: Model<{ searchString: string; page: string; fields: string }>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      searchString: '',
      page: '0,5',
      fields: 'nationality,familyId',
      deleted: 'no',
    })
    this.residents = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      this.model.values,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.residents.init({ readOnly: true, observeQuery: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private searchResultMapper = (res: Resource<IResidentSearchResult>) => {
    const resident = res.data
    if (!resident) {
      return null
    }
    const thumbnail = getResidentImageSrc(
      this.context.instance.id,
      resident.imageId,
      resident.sex,
      'thumbnail',
    )
    return (
      <tr key={resident.id}>
        <td className='px-6 py-4 whitespace-nowrap'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <img className='h-10 w-10 rounded-full' src={thumbnail} alt='' />
            </div>
            <div className='ml-4'>
              <div
                className='text-sm font-medium text-gray-900 truncate'
                style={{ maxWidth: 280 }}
              >
                {`${resident.lastName.toUpperCase()}, ${resident.firstName}`}
                {resident.data.nationality && (
                  <span className='text-gray-400'>
                    &nbsp;&nbsp;·&nbsp;&nbsp;{resident.data.nationality || '-'}
                  </span>
                )}
              </div>
              {resident.data.familyId !== this.props.familyId && (
                <a
                  onClick={(e) => {
                    e.preventDefault()
                    this.addToFamily(resident.id)
                  }}
                  className='text-sm text-indigo-500 hover:underline cursor-pointer'
                  href={`/residents/${toJbpId(+resident.id).toLowerCase()}/overview`}
                >
                  Zur Familie hinzufügen
                </a>
              )}
              {resident.data.familyId === this.props.familyId && (
                <span className='px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white'>
                  Familienmitglied
                </span>
              )}
            </div>
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap'>
          <div className='text-sm text-gray-900'>
            <Age sex={resident.sex} dateOfBirth={resident.dateOfBirth} />
          </div>
          <div className='text-sm text-gray-500'>&nbsp;</div>{' '}
          {/* There could be another info */}
        </td>
      </tr>
    )
  }

  private addToFamily = async (residentId: string) => {
    try {
      runInAction(() => (this.saving = true))
      await hermes.patch(`/api/${this.context.instance.id}/residents/${residentId}`, {
        familyId: this.props.familyId,
      })
      const to = this.props.location.pathname.substring(11).split('/')
      to.shift()
      const url = `/residents/${toJbpId(+residentId).toLowerCase()}/${to.join('/')}`
      // Wait for residents collection in menu to be updated, so that new resident is already
      // available on navigation. This way, the dialog closes smoothly without remount
      // (otherwise: loading spinner -> remount of menu -> remount of dialog). Of course
      // this only works statistically, because resource might not have updated within 400ms.
      // But it is only a nice to have that is not necessary for functionality.
      // It only takes care of smooth dialog transition.
      await sleep(400)
      this.props.navigate(url)
      await sleep(100) // Wait for navigation finish before dismiss (smooth dismiss)
      this.dismiss()
    } catch (e) {
      runInAction(() => {
        this.saving = false
        this.error = 'Der Bewohner konnte der Familie nicht hinzugefügt werden'
      })
    }
  }

  private dismiss = () => this.props.setOpen(false)

  render() {
    if (this.saving) {
      return (
        <div className='relative p-4 mb-3 mt-6' style={{ minHeight: 535 }}>
          <Spinner />
        </div>
      )
    }
    const emptySearchRequest = isEmptySearchResultsFilter(this.model.values)

    return (
      <div style={{ minHeight: 535 }}>
        {/* Error message */}
        {this.error && (
          <div className='rounded-md bg-red-50 p-4 mb-3 mt-6'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
              </div>
              <div className='ml-3'>{this.error}</div>
            </div>
          </div>
        )}

        {/* Search bar */}
        <div className='mt-6 mb-3 relative' id={this.model.id}>
          <InputText
            model={this.model}
            name='searchString'
            placeholder='Name oder ID'
            style={{ paddingLeft: 32 }}
          />
          <i
            className='fas fa-search absolute text-gray-500'
            style={{ top: 11, left: 11 }}
          />
        </div>

        {/* Search has not started yet */}
        {emptySearchRequest && (
          <div className='rounded-md bg-indigo-100 p-4 mb-3 mt-6'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <SearchIcon className='h-5 w-5 text-indigo-400' aria-hidden='true' />
              </div>
              <div className='ml-3'>
                Geben Sie den Namen oder die ID des Bewohners in das Suchfeld ein.
                Suchergebnisse können Sie dann auf Wunsch zu dieser Familie hinzufügen.
              </div>
            </div>
          </div>
        )}

        {/* Search results */}
        {!emptySearchRequest &&
          this.residents.resources &&
          this.residents.resources.length > 0 && (
            <div className='flex flex-col mt-6'>
              <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <table className='min-w-full divide-y divide-gray-200'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Name
                          </th>
                          <th
                            scope='col'
                            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                          >
                            Alter
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white divide-y divide-gray-200'>
                        {this.residents.resources.map(this.searchResultMapper)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Cancel & submit buttons */}
        <div className='flex mt-6'>
          <Button color='secondary' outline onClick={this.dismiss} className='mr-2'>
            Abbrechen
          </Button>
        </div>
      </div>
    )
  }
}
