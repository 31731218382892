import * as React from 'react'

import styles from '../styles.module.scss'

export class LogValue extends React.Component<
  { value: string | number | boolean | null | undefined },
  {}
> {
  render() {
    if (typeof this.props.value === 'string') {
      return this.props.value ? (
        <span>{this.props.value}</span>
      ) : (
        <i className={styles.empty}>leer</i>
      )
    }
    if (typeof this.props.value === 'boolean') {
      return this.props.value ? <span>Ja</span> : <span>Nein</span>
    }
    if (this.props.value === undefined || (this.props.value as any) === null) {
      return <i className={styles.empty}>leer</i>
    }
    throw new Error(
      `Value component cannot handle the value ${this.props.value} of type ${typeof this
        .props.value}`,
    )
  }
}
