import { AnnotationIcon } from '@heroicons/react/outline'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { runInAction } from 'mobx'
import { InputForm } from 'components/Form/components/InputForm'

interface Props {
  model: Model<{ label: string }>
  close: (updated?: boolean) => void
}

export const FamilyRelationPrompt: React.FC<Props> = (props) => {
  const onSubmit = async () => {
    runInAction(() => (props.model.values.label = props.model.values.label.trim()))
    if (!props.model.isValid()) {
      props.model.setFocusToLeftTopmostInvalidField()
      return
    }
    props.close(true)
  }
  const onReset = () => {
    runInAction(() => (props.model.values.label = ''))
    props.close(true)
  }

  return (
    <>
      <div className='sm:flex sm:items-start'>
        <div
          className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-indigo-100`}
        >
          <AnnotationIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />
        </div>
        <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-auto'>
          <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
            Beziehung festlegen
          </Dialog.Title>
          <div className='text-sm mt-3'>
            Geben Sie hier an, in welcher Beziehung die verknüpfte Familie zur aktuellen
            Familie steht. So bewahren Sie den Überblick.
          </div>
          <div className='mt-5 text-sm text-gray-500' id={props.model.id}>
            <InputForm onSubmit={onSubmit}>
              <InputText
                model={props.model}
                name='label'
                label='Beziehung'
                placeholder='z. B. Großeltern'
                maxLength={20}
              />
            </InputForm>
          </div>
        </div>
      </div>
      <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
        <button
          type='button'
          className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500`}
          onClick={onSubmit}
        >
          Speichern
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm'
          onClick={onReset}
        >
          Zurücksetzen
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
          onClick={() => props.close()}
        >
          Abbrechen
        </button>
      </div>
    </>
  )
}
