import { dayjs } from '../../general/helpers/dayjs'
import { IBooking } from '../interfaces/IBooking'

export function getCurrentResponsibilityCompoundId(bookings?: IBooking[]): string | null {
  if (!bookings) {
    return null
  }
  const now = dayjs()
  let responsibilityCompoundId: string | null = null
  for (let i = bookings.length - 1; i >= 0; i--) {
    const booking = bookings[i]
    if (now.isBefore(booking.beginAt)) {
      continue
    }
    if (booking.type === 'responsibility-begin') {
      responsibilityCompoundId = booking.compoundId
      continue
    }
    if (booking.type === 'responsibility-end') {
      responsibilityCompoundId = null
      continue
    }
  }
  return responsibilityCompoundId
}
