// Returns a time interval in a suitable format for the UI.
// Only returns significant part (e.g. hours) and skips (e.g. minutes)
export function getDurationLabel(seconds: number) {
  if (seconds >= 3600 * 24) {
    const days = Math.floor(seconds / 3600 / 24)
    return days === 1 ? 'vor einem Tag' : `vor ${days} Tagen`
  }

  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600)
    return hours === 1 ? 'vor einer Stunde' : `vor ${hours} Stunden`
  }

  if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60)
    return minutes === 1 ? 'vor einer Minute' : `vor ${minutes} Minuten`
  }

  return 'gerade eben'
}
