import * as React from 'react'
import dayjs from 'dayjs'
import { Age } from 'components/Age'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IInactiveResident } from 'contracts/inactiveList/interfaces/IInactiveResident'
import { Link } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'
import { box } from 'services/box'
import { hermes } from '@byll/hermes'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { Model } from 'components/Form/Model'
import { IInactiveListFilterModel } from 'contracts/inactiveList/interfaces/IInactiveListFilterModel'
import { Button } from 'components/Form/components/Button'

interface Props {
  resident: IInactiveResident
  model: Model<IInactiveListFilterModel>
}

export class LeaInactiveListItem extends React.Component<Props> {
  static contextType = AppContext

  private checkOut = async () => {
    if (
      !this.props.resident.currentVisitCompoundId ||
      this.props.resident.currentVisitCompoundId !== this.props.model.values.compoundId
    ) {
      return
    }

    if (
      !(await box.alert(
        'Zuständigkeit beenden',
        'Möchten Sie die Zuständigkeit wirklich beenden?',
        { confirm: 'Ja', cancel: 'Abbrechen' },
      ))
    ) {
      return
    }

    try {
      const now = dayjs().startOf('minute')
      const data: Partial<IBooking> = {
        residentId: this.props.resident.id,
        instanceId: this.context.instance.id,
        type: 'responsibility-end',
        compoundId: this.props.resident.currentVisitCompoundId,
        roomId: null,
        bed: null,
        reason: 'Inaktivität',
        comment: '',
        beginAt: now.add(-1, 'minute').toISOString(),
        endAt: now.toISOString(),
        extra: {},
      }
      await hermes.create(`/api/${this.context.instance.id}/bookings`, data)
    } catch (_e) {
      void box.alert('Fehler', 'Beim Check-out ist ein Fehler aufgetreten.', {
        color: 'danger',
      })
    }
  }

  render() {
    const resident = this.props.resident
    const lastScannedAt = resident.lastScannedAt ? dayjs(resident.lastScannedAt) : null

    return (
      <tr>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {toJbpId(+resident.id)}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {resident.migVisId}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          <Link
            className='mr-2'
            to={`/residents/${toJbpId(+resident.id).toLowerCase()}/overview`}
          >
            {resident.lastName.toUpperCase() + ', ' + resident.firstName}
          </Link>
          <Age dateOfBirth={resident.dateOfBirth} sex={resident.sex} />
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {lastScannedAt && <span>{lastScannedAt.format('DD.MM.YYYY')}</span>}
          {lastScannedAt && <span className='ml-2'>{lastScannedAt.format('HH:mm')}</span>}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {resident.residence}{' '}
          {this.props.resident.currentVisitCompoundId ===
            this.props.model.values.compoundId && (
            <Button className='ml-2' color='success' small onClick={this.checkOut}>
              Zuständigkeit beenden
            </Button>
          )}
        </td>
      </tr>
    )
  }
}
