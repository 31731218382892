import { TopBar } from 'components/SideBarLayout/components/TopBar'
import { Spinner } from 'components/Spinner'
import * as React from 'react'

interface Props {}

export const ColumnSpinner: React.FC<Props> = () => (
  <>
    <TopBar />

    <div className='relative mx-4 mt-[72px] flex-auto mb-4 flex flex-col'>
      <div className='my-auto flex-content'>
        <Spinner delay={200} />
      </div>
    </div>
  </>
)
