import { hermes } from '@byll/hermes'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentLetterEntry } from 'contracts/residents/interfaces/IResidentLetterEntry'
import { observable, makeObservable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { LastScans } from './components/LastScans'
import { ShortProfile } from './components/ShortProfile'

interface Props {
  resident: IResident
}

@observer
export class OverviewSummary extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private letters: IResidentLetterEntry[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    if (this.context.permissions.menu_resident_letters > 0) {
      this.loadLetters()
    }
  }

  private loadLetters = async () => {
    try {
      const letters: IResidentLetterEntry[] = await hermes.indexOnceNew(
        `/api/${this.context.instance.id}/residentLetters?residentId=${this.props.resident.id}`,
      )
      runInAction(() => {
        this.letters = letters.filter(
          (l) => +(l.letters || 0) + +(l.yellowLetters || 0) > 0,
        )
      })
    } catch (e) {
      /* ignore */
    }
  }

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Kurzprofil</span>
          <br />
          <span className='text-sm text-gray-400'>Zusammenfassung</span>
        </div>
        <div className='flex-auto min-h-[140px]'>
          <div className='flex gap-4 relative mt-2'>
            <ShortProfile resident={this.props.resident} letters={this.letters} />
            {this.context.permissions.resident_kurzprofil > 0 && (
              <LastScans resident={this.props.resident} />
            )}
          </div>
        </div>
      </div>
    )
  }
}
