import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import * as React from 'react'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { InventoryItemTopBar } from '../../InventoryItem/components/InventoryItemTopBar'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { InventoryFolderForm } from './InventoryFolderForm'

interface Props {
  navigate: (path: string) => void
  compoundId: string
}

@observer
export class InventoryFolderNew extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private folder: IInventoryFolder

  constructor(props: Props) {
    super(props)
    this.folder = {
      id: '',
      label: '',
      compoundId: props.compoundId,
    }
    makeObservable(this)
  }

  render() {
    if (this.context.permissions.inventory_manageFolders === 0) {
      return (
        <>
          <InventoryItemTopBar folder={{ label: 'Neuer Ordner' } as any} />
          <div className='rounded-md shadow-md mx-4 bg-white mt-[72px] flex-auto mb-4 flex flex-col'>
            <Forbidden />
          </div>
        </>
      )
    }

    return (
      <>
        <InventoryItemTopBar folder={{ label: 'Neuer Ordner' } as any} />

        <InventoryFolderForm
          folder={this.folder}
          navigate={this.props.navigate}
          setEdit={() => this.props.navigate('/inventory/items')}
        />
      </>
    )
  }
}
