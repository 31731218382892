export function getNextFocusableDivParent(
  element?: HTMLElement | null,
): HTMLElement | null {
  if (!element) {
    return null
  }
  while (element.parentElement) {
    element = element.parentElement
    if (
      element.hasAttribute('tabindex') &&
      element.tabIndex === -1 &&
      element.tagName === 'DIV'
    ) {
      return element
    }
  }
  return null
}
