import * as React from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'

import styles from './styles.module.scss'

export interface TransferState {
  type: 'pending' | 'uploading' | 'uploaded' | 'uploadError' | 'aborted'
  progress: number
}

interface Props {
  width: number | string // css width of container
  state: TransferState
}

interface State {
  visible: 'yes' | 'fadeOut' | null
  percentage: number
  className: string
}

@observer
export class UploadStripe extends React.Component<Props, State> {
  private dispose
  private uploadedTimeout

  constructor(props) {
    super(props)
    this.state = {
      visible: null,
      percentage: 0,
      className: '',
    }
  }

  componentDidMount() {
    this.dispose = autorun(() => {
      clearTimeout(this.uploadedTimeout)
      if (this.props.state.type === 'uploading') {
        this.setState({
          visible: 'yes',
          percentage: this.props.state.progress || 0,
          className: 'bg-green-500',
        })
      } else if (this.props.state.type === 'uploaded') {
        this.setState({
          visible: 'yes',
          percentage: 1,
          className: 'bg-green-500',
        })
        this.uploadedTimeout = setTimeout(
          () =>
            this.setState({
              visible: 'fadeOut',
              percentage: 1,
              className: 'bg-green-500',
            }),
          2000,
        )
      } else if (this.props.state.type === 'uploadError') {
        this.setState({
          visible: 'yes',
          percentage: 1,
          className: 'bg-red-500',
        })
        this.uploadedTimeout = setTimeout(
          () =>
            this.setState({
              visible: 'fadeOut',
              percentage: 1,
              className: 'bg-red-500',
            }),
          2000,
        )
      }
    })
  }

  componentWillUnmount() {
    this.dispose()
    clearTimeout(this.uploadedTimeout)
  }

  render() {
    if (this.state.visible === null || this.props.state.type === 'pending') {
      return null
    }

    return (
      <div className={styles.container} style={{ width: this.props.width }}>
        <div
          className={`${this.state.className} ${styles.progress} ${
            this.state.visible === 'fadeOut' ? styles.fadeOut : ''
          }`}
          style={{ transform: `scaleX(${this.state.percentage})` }}
        />
      </div>
    )
  }
}
