import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Callout } from 'components/Callout'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ResidentsOccupancyFilter } from './components/ResidentsOccupancyFilter'
import { getOccupancyRowFilter } from './helpers/getOccupancyRowFilter'
import { resetBuildingWhenOtherCompoundIsSelected } from './helpers/resetBuildingWhenOtherCompoundIsSelected'
import { resetFloorsWhenOtherBuildingIsSelected } from './helpers/resetFloorsWhenOtherBuildingIsSelected'
import { IOccupancyTimeModel } from './interfaces/IOccupancyTimeModel'
import { getOccupancyTimeModel } from './helpers/getOccupancyTimeModel'
import { updateOccupancyTime } from './helpers/updateOccupancyTime'
import { saveOccupancyRowFilterInLocalStorage } from './helpers/saveOccupancyRowFilterInLocalStorage'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'
import { IOccupancyMetadata } from 'contracts/occupancies/interfaces/IOccupancyMetadata'
import { OccupancyViews } from './components/OccupancyViews'

interface Props {}

@observer
export class ResidentsOccupancy extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly filter: Model<IOccupancyFilter>
  private readonly time: Model<IOccupancyTimeModel>
  private readonly disposers: Disposer[] = []
  private readonly occupancies: UnsyncedCollection<
    IOccupancy,
    IOccupancyMetadata,
    IOccupancyFilter
  >

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.time = getOccupancyTimeModel()
    this.filter = getOccupancyRowFilter(context, this.time.values.at, 'occupancy view')
    this.occupancies = new UnsyncedCollection(
      `/api/${context.instance.id}/accommodations/occupancies`,
      this.filter.values,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.occupancies.init())
    this.disposers.push(updateOccupancyTime(this.time, this.filter))
    this.disposers.push(resetFloorsWhenOtherBuildingIsSelected(this.filter))
    this.disposers.push(resetBuildingWhenOtherCompoundIsSelected(this.filter))
    this.disposers.push(saveOccupancyRowFilterInLocalStorage(this.filter, this.context))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  render() {
    if (!this.context.permissions.resident_accommodationBookings) {
      return <Forbidden />
    }
    const { compoundId, queryAt: at } = this.filter.values

    return (
      <div className='pt-14 flex flex-col min-h-full'>
        <ResidentsOccupancyFilter filter={this.filter} time={this.time} />

        {at && compoundId && (
          <OccupancyViews
            at={at}
            compoundId={compoundId}
            filter={this.filter}
            occupancies={this.occupancies}
          />
        )}

        {/* No compound selected */}
        {!compoundId && (
          <div className='flex-auto bg-gray-100 relative flex'>
            <Callout
              className='my-auto'
              icon='fas fa-home'
              iconColor='#8b5cf6'
              title='Bitte wählen Sie ein Gelände'
            />
          </div>
        )}

        {/* No date selected */}
        {!at && compoundId && (
          <div className='flex-auto bg-gray-100 relative flex'>
            <Callout
              className='my-auto'
              icon='fas fa-calendar'
              iconColor='#8b5cf6'
              title='Bitte wählen Sie ein Datum'
            />
          </div>
        )}

        {/*queryAt && compoundId && !isTileView && (
          <div className='flex-auto bg-gray-100 relative'>
            {compoundId && this.model.values.buildingId && this.model.values.floorIds ? (
              <BuildingFloorPlan
                key={`${compoundId}/${this.model.values.buildingId}/${
                  this.model.values.floorIds
                }/${queryAt.toISOString()}`}
                compoundId={compoundId}
                buildingId={this.model.values.buildingId}
                floorId={this.model.values.floorIds}
                queryAt={queryAt}
                isNow={isNow}
              />
            ) : (
              <BuildingFloorPlanPlaceholder />
            )}
          </div>
            )*/}
      </div>
    )
  }
}
