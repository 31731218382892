import * as React from 'react'

interface Props {
  rating: number
}

export class TicketRating extends React.Component<Props, {}> {
  render() {
    switch (this.props.rating) {
      case 1: {
        return (
          <div className='flex mr-2'>
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-gray-200' />
            <i className='fa fa-star text-gray-200' />
            <i className='fa fa-star text-gray-200' />
            <i className='fa fa-star text-gray-200' />
          </div>
        )
      }
      case 2: {
        return (
          <div className='flex mr-2'>
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-gray-200' />
            <i className='fa fa-star text-gray-200' />
            <i className='fa fa-star text-gray-200' />
          </div>
        )
      }
      case 3: {
        return (
          <div className='flex mr-2'>
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-gray-200' />
            <i className='fa fa-star text-gray-200' />
          </div>
        )
      }
      case 4: {
        return (
          <div className='flex mr-2'>
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-gray-200' />
          </div>
        )
      }
      case 5: {
        return (
          <div className='flex mr-2'>
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
            <i className='fa fa-star text-yellow-500' />
          </div>
        )
      }
    }
  }
}
