import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { Collection } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ICostCoveragePayer } from 'contracts/costCoverages/interfaces/ICostCoveragePayer'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

let payers: Collection<ICostCoveragePayer>

@observer
export class SelectCostCoverage extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    if (!payers) {
      payers = new Collection(`/api/${context.instance.id}/costCoveragePayers`)
      payers.init({ readOnly: true })
    }
  }

  @action private reset = () => {
    this.props.model.values.costCoveragePayer = ''
  }

  render() {
    const ps: InputMultiSelectOption[] | null = payers.resources
      ? payers.resources.map((p) => ({
          id: p.data?.abbreviation || '',
          label: p.data?.abbreviation || '',
        }))
      : null
    if (!ps) {
      return null
    }
    let label = 'KÜ'
    let color: string = 'text-gray-500'
    if (this.props.model.values.costCoveragePayer) {
      const ids = this.props.model.values.costCoveragePayer.split(',')
      label = `${ids[0]}${ids.length > 1 ? ` +${ids.length - 1}` : ''}`
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputMultiSelect
                model={this.props.model}
                name='costCoveragePayer'
                label='Kostenträger'
                options={ps}
              />
              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
