import { Collection, Resource } from '@byll/hermes'
import { ResourceState } from '@byll/hermes/lib/interfaces/ResourceState'

export type SyncState = 'patchError' | 'patching' | 'noChanges' | 'patched'

interface SyncDetails {
  lastSaved: null | Date
  synced: boolean
  patchError: boolean
}

/**
 * Resources | Collections that are not loaded (data/resources === null) are ignored.
 */
export function getSyncState(
  mountedAt: Date,
  ...args: (Resource<any> | Collection<any> | null | undefined)[]
): SyncState {
  const details: SyncDetails = {
    lastSaved: null,
    synced: true,
    patchError: false,
  }

  for (const arg of args) {
    if (arg instanceof Collection && arg.resources) {
      // arg is of type Collection and resources are loaded
      for (const resource of arg.resources) {
        if (!resource.data || !resource.state) {
          continue
        }
        checkResourceState(resource.state, details)
      }
    } else if (arg instanceof Resource && arg.data && arg.state) {
      checkResourceState(arg.state, details)
    }
  }

  if (details.patchError) {
    return 'patchError'
  }
  if (!details.synced) {
    return 'patching'
  }

  if (!details.lastSaved || mountedAt.valueOf() > details.lastSaved.valueOf()) {
    return 'noChanges'
  }

  return 'patched'
}

function checkResourceState(state: ResourceState<any>, details: SyncDetails) {
  for (const key of Object.keys(state!)) {
    if (state![key]!.lastSaved) {
      if (details.lastSaved === null || state![key]!.lastSaved! > details.lastSaved) {
        details.lastSaved = state![key]!.lastSaved
      }
    }
    if (!state![key]!.synced) {
      details.synced = false
    }
    if (state![key]!.patchError !== null) {
      details.patchError = true
    }
  }
}
