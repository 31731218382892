import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'

interface Props {
  reports: { report: string; label: string }[]
  onSelect: (report: { report: string; label: string }) => void
  width?: number
  style?: any
}

export const CostCoverageDownloadDropdown: React.FC<Props> = (props) => (
  <Menu
    as='span'
    className='relative inline-block text-left'
    onClick={(e) => e.stopPropagation()}
  >
    <div>
      <Menu.Button
        className='text-white bg-gradient-to-r from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500 py-[9px] px-[17px] text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2'
        style={{
          width: 38,
          height: 38,
          padding: 0,
          borderRadius: '0 6px 6px 0',
          ...props.style,
        }}
      >
        <i className='fas fa-download' />
      </Menu.Button>
    </div>

    <Transition
      as={React.Fragment}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <Menu.Items
        className='z-10 origin-top-right absolute -right-20 md:right-0 mt-2 rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none'
        style={{ width: props.width || 160 }}
      >
        <div className='py-1'>
          {props.reports.map((r) => (
            <Menu.Item key={r.report}>
              {({ active }) => (
                <a
                  className={`${
                    active && 'bg-blue-500 text-white'
                  } block px-4 py-2 text-sm`}
                  href='/'
                  onClick={(e) => {
                    e.preventDefault()
                    props.onSelect(r)
                  }}
                >
                  {r.label}
                </a>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
)
