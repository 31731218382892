import { Collection } from '@byll/hermes'
import { IInventoryDelivery } from 'contracts/inventory/interfaces/IInventoryDelivery'

export function getDeliveredCount(
  deliveries: Collection<IInventoryDelivery>,
  inventoryItemId: string,
): number {
  let count = 0
  for (const delivery of deliveries.resources || []) {
    if (!delivery.data) {
      continue
    }
    for (const item of delivery.data.items) {
      if (item.inventoryItemId === inventoryItemId) {
        count += Number(item.count)
      }
    }
  }
  return count
}
