import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import axios from 'axios'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { Wait } from 'modules/Pdf/components/Wait'
import dayjs from 'dayjs'

export const LfgbElterlicheFuersorgepflicht: React.FC<DocumentTemplateProps> = (
  props,
) => {
  const [residentSearchResult, setResidentSearchResult] =
    React.useState<IResidentSearchResult | null>(null)
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)

  React.useEffect(() => {
    const load = async () => {
      const [r] = await Promise.all([
        axios.get<any>(
          `/api/${props.resident.instanceId}/residentSearchResults?residentIds=${props.resident.id}&fields=bookings`,
        ),
      ])
      if (!Array.isArray(r.data.resources) || r.data.resources.length !== 1) {
        throw new Error('Not found')
      }
      const stay = r.data.resources[0]?.data.bookings?.find(
        (b) => b.timeline === 'present' && b.type === 'internal-residence',
      )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cp = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setCompound(cp.data)
        setBuilding(bl.data)
      }
      setResidentSearchResult(r.data.resources[0])
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!residentSearchResult) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  return (
    <>
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        {/* Header */}
        <div className='absolute top-[40px] left-[100px] text-lg'>
          Landesbetrieb für Gebäudebewirtschaftung Berlin
          <br />
          Betriebsteil B (LfG-B)
          <br />
          {buildingLabel}
        </div>
        <img className={styles.logo} src={logo} alt='' />

        {/* Title */}
        <div className=' text-3xl my-12 text-center'>Elterliche Fürsorgepflicht</div>

        {/* Content */}
        <div style={{ fontSize: '14px' }}>
          <p>
            Die elterliche Fürsorgepflicht (§1631 Abs. 1 BGB) umfasst das Recht und die
            Pflicht von Eltern ihre Kinder zu pflegen, zu erziehen und zu beaufsichtigen
            bis diese volljährig (18 Jahre alt) sind. Das heißt, dass Eltern darauf achten
            müssen, dass ihre Kinder keinen Schaden erleiden, anderen keinen Schaden
            zufügen oder gefährden. Die Eltern sollten daher immer wissen, wo sich ihr
            Kind befindet und welcher Tätigkeit es nachgeht. Eine Verletzung der
            Aufsichtspflicht kann sowohl strafrechtlich wie zivilrechtlich weit reichende
            Konsequenzen nach sich ziehen. Verursacht ein Kind einen Schaden oder verletzt
            es sich, müssen die Eltern beweisen, dass sie ihre Aufsichtspflicht korrekt
            erfüllt haben. Hüten die Großeltern, Freunde oder eine Tagesmutter das Kind,
            übernehmen diese damit automatisch auch die Aufsichtspflicht.
          </p>

          <p className='mt-6'>
            Die elterliche Fürsorgepflicht bestimmt auch das Recht auf eine gewaltfreie
            Erziehung der Kinder (§1631 Abs. 2 BGB). Körperliche Strafen, Verletzungen
            jedweder Art oder Handlungen, die die Würde der Kinder verletzen sind nicht
            erlaubt.
          </p>

          <p className='mt-6'>
            Der Sozialdienst der Unterkunft ist dazu berechtigt, bei Verdacht der
            Kindeswohlgefährdung das Jugendamt und die Polizei zum Schutz der Kinder
            einzuschalten.
          </p>
        </div>

        {/* Signature */}
        <div className='grid gap-16 grid-cols-3 mt-28'>
          <div>
            <div className='border-b border-black text-center'>
              {dayjs().format('DD.MM.YYYY')}
            </div>
            <div className='text-sm text-center'>Datum</div>
          </div>

          <div className='w-[230px]'>
            <div className='border-b border-black truncate'>&nbsp;</div>
            <div className='text-sm text-center'>Haushaltsvorstand</div>
          </div>
        </div>

        {/* Footer */}
        <div
          className='flex justify-between w-[640px] text-xs text-gray-500 absolute'
          style={{ bottom: 40, fontSize: 9 }}
        >
          <div>Stand 09.08.2022</div>
          <div className='text-right'>
            Brunnenstraße 110 C, 13355 Berlin
            <br />
            Tel. +49 30 213 099 101
            <br />
            info@lfg-b.de
          </div>
        </div>
      </div>
    </>
  )
}
