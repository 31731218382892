import { Model } from 'components/Form/Model'
import { reaction } from 'mobx'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'

export function resetFloorsWhenOtherBuildingIsSelected(
  model: Model<IOccupancyFilter>,
): Disposer {
  // Reset selected floors from floor filter on compound/building
  // change because every building has different floors.
  return reaction(
    () => `${model.values.compoundId}/${model.values.buildingId}`,
    () => (model.values.floorIds = ''),
  )
}
