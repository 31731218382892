function set(key: string, value: any) {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.log('local storage not enabled')
  }
}

function get(key: string): any | undefined {
  try {
    const value = localStorage.getItem(key)
    return typeof value === 'string' ? JSON.parse(value) : undefined
  } catch (e) {
    console.log('local storage not enabled')
    return undefined
  }
}

function remove(key: string) {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    console.log('local storage not enabled')
  }
}

export const storage = { get, set, remove }
