import * as React from 'react'
import { Card } from 'components/Card'
import { hermes } from '@byll/hermes'
import { observer } from 'mobx-react'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { IBedStats } from 'contracts/dashboard/interfaces/IBedStats'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { InputCompound } from 'components/Form/components/InputCompound'
import { isStammCompound } from 'helpers/isStamm'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Spinner } from 'components/Spinner'
import { box } from 'services/box'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { Button } from 'components/Form/components/Button'

interface Props {}

@observer
export class DashboardBedStats extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private stats: IBedStats | null = null
  private readonly model: Model<{ compoundId: string | null }>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({ compoundId: context.defaults.responsibilityCompoundId })
    if (
      context.permissions.dashboard_bedStats === 1 &&
      !isStammCompound(this.model.values.compoundId || '')
    ) {
      this.model.values.compoundId = null
    }
    if (!this.model.values.compoundId) {
      this.model.values.compoundId = context.defaults.stammCompoundIds[0] || null
    }
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(() => this.model.values.compoundId, this.load, { fireImmediately: true }),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private load = async () => {
    if (!this.model.values.compoundId) {
      return
    }
    const stats = await hermes.getOnceNew<IBedStats>(
      `/api/${this.context.instance.id}/dashboard/bedStats/${this.model.values.compoundId}`,
    )
    runInAction(() => (this.stats = stats))
  }

  private showLocked = () => {
    if (!this.stats) {
      return
    }
    const promise = box.custom(
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => promise.close()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Blockierte Plätze
            </Dialog.Title>
          </div>
        </div>

        <div className='relative mt-4 grid grid-cols-2'>
          {this.stats.locks.map((l) => (
            <React.Fragment key={l.reason}>
              <div>{l.reason}</div>
              <div>{l.count}</div>
            </React.Fragment>
          ))}
        </div>

        {this.stats.locks.length === 0 && (
          <div className='relative mt-4 text-gray-500'>
            Derzeit sind keine Plätze blockiert.
          </div>
        )}

        <div className='mt-4 text-right'>
          <Button color='primary' onClick={() => promise.close()}>
            Schließen
          </Button>
        </div>
      </>,
    )
  }

  render() {
    return (
      <Card>
        <div className='p-4'>
          <div className='font-bold text-xl mb-2'>Zimmerbelegung</div>
          <InputCompound
            className='mb-4'
            model={this.model}
            name='compoundId'
            onlyStamm={this.context.permissions.dashboard_bedStats === 1}
            saveResponsibility
            notNull
          />
          <div className='relative min-h-[200px]'>
            {this.model.values.compoundId &&
              this.stats?.id !== this.model.values.compoundId && <Spinner />}
            {this.model.values.compoundId &&
              this.stats?.id === this.model.values.compoundId && (
                <div className='-mt-6 -mx-6 grid grid-cols-2 overflow-hidden'>
                  <div className='flex flex-col px-8 pt-8'>
                    <dt className='order-2 text-base font-medium text-gray-500 whitespace-nowrap'>
                      Freie Plätze
                    </dt>
                    <dd className='order-1 text-2xl font-extrabold text-indigo-600 sm:text-3xl'>
                      {this.stats.frei}
                    </dd>
                  </div>
                  <div className='flex flex-col px-8 pt-8'>
                    <dt className='order-2 text-base font-medium text-gray-500 whitespace-nowrap'>
                      Nicht verfügbar
                    </dt>
                    <dd className='order-1 text-2xl font-extrabold text-yellow-500 sm:text-3xl'>
                      {this.stats.belegt}
                    </dd>
                  </div>
                  <div className='flex flex-col px-8 pt-8'>
                    <dt className='order-2 text-base font-medium text-gray-500 whitespace-nowrap'>
                      Überbelegung
                    </dt>
                    <dd className='order-1 text-2xl font-extrabold text-red-500 sm:text-3xl'>
                      {this.stats.ueberbelegt}
                    </dd>
                  </div>
                  <div className='flex flex-col px-8 pt-8'>
                    <dt className='order-2 text-base font-medium text-gray-500 whitespace-nowrap'>
                      Blockiert
                    </dt>
                    <dd className='order-1 text-2xl font-extrabold text-red-500 sm:text-3xl'>
                      {this.stats.blockiert}
                      <span
                        className='relative -top-2 text-base ml-1 text-gray-500 hover:text-blue-500 cursor-pointer'
                        onClick={this.showLocked}
                      >
                        <i className='fas fa-question-circle' />
                      </span>
                    </dd>
                  </div>
                  <div className='flex flex-col px-8 pt-8'>
                    <dt className='order-2 text-base font-medium text-gray-500 whitespace-nowrap'>
                      Gesamtplätze
                    </dt>
                    <dd className='order-1 text-2xl font-extrabold text-indigo-600 sm:text-3xl'>
                      {this.stats.gesamt}
                    </dd>
                  </div>
                  <div className='flex flex-col px-8 pt-8'>
                    <dt className='order-2 text-base font-medium text-gray-500 whitespace-nowrap'>
                      Sollplätze
                    </dt>
                    <dd className='order-1 text-2xl font-extrabold text-indigo-600 sm:text-3xl'>
                      {this.stats.soll}
                    </dd>
                  </div>
                </div>
              )}
          </div>
        </div>
      </Card>
    )
  }
}
