import { Collection, hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Button } from 'components/Form/components/Button'
import { InputResident } from 'components/Form/components/InputResident'
import { Model } from 'components/Form/Model'
import { Spinner } from 'components/Spinner'
import { IFederalTransfer } from 'contracts/residents/interfaces/IFederalTransfer'
import { IFederalTransferResponse } from 'contracts/residents/interfaces/IFederalTransferResponse'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { FederalTransferListEntry } from './FederalTransferListEntry'
import { ButtonDropdown } from 'components/ButtonDropdown'
import { ConflictError } from 'contracts/errors/HermesErrors'
import * as uuid from 'uuid'
import { JugendamtDialog } from './JugendamtDialog'

interface Props {
  model: Model<IFederalTransfer>
  lockedAt: string | null | undefined
}

const dropdownOptionsOpen = [
  { value: 'delete', label: 'Aus der Liste entfernen' },
  { value: 'addToTravelGroup', label: 'Zu neuer Fluchtgemeinschaft zusammenführen' },
  { value: 'deleteFromTravelGroup', label: 'Aus Fluchtgemeinschaft entfernen' },
]

const dropdownOptionsClosed = [
  { value: 'editJugendamt', label: 'Aufnehmendes Jugendamt eintragen' },
]

@observer
export class FederalTransfersList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly federalTransfers: Collection<IFederalTransferResponse>
  private readonly disposers: Disposer[] = []
  @observable selectedTransfers: Set<string> = new Set()

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.federalTransfers = new Collection(
      `/api/${context.instance.id}/federalTransfers`,
      this.props.model.values,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.federalTransfers.init({ observeQuery: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private onChoose = async () => {
    if (
      !this.props.model.values.notificationDate ||
      !this.props.model.values.residentId
    ) {
      box.alert('Fehler', 'Wählen Sie ein Meldungsdatum und einen Bewohner aus')
      return
    }
    try {
      await hermes.create(
        `/api/${this.context.instance.id}/federalTransfers`,
        this.props.model.values,
      )
    } catch (e: any) {
      if (e.id === ConflictError.id) {
        box.alert('Fehler', e.message)
      } else {
        box.alert(
          'Fehler',
          'Der Bewohner konnte nicht hinzugefügt werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
        )
      }
    }
    runInAction(() => (this.props.model.values.residentId = ''))
  }

  private mapFederalTransfer = (
    res: Resource<IFederalTransferResponse>,
    colorIndexMap: Map<string, string>,
  ) => {
    if (!res.data) {
      return null
    }
    return (
      <FederalTransferListEntry
        entry={res.data}
        selectedTransfers={this.selectedTransfers}
        color={colorIndexMap.get(res.data.id) || 'white'}
        lockedAt={this.props.lockedAt}
        key={res.id}
      />
    )
  }

  @action
  private checkAllFederalTransfers = () => {
    if (!this.federalTransfers.resources) {
      return
    }
    if (this.selectedTransfers.size < this.federalTransfers.resources.length) {
      for (const f of this.federalTransfers.resources) {
        if (!f.data) {
          return
        }
        this.selectedTransfers.add(f.data.residentId)
      }
    } else {
      for (const f of this.federalTransfers.resources) {
        if (!f.data) {
          return
        }
        this.selectedTransfers.delete(f.data.residentId)
      }
    }
  }

  private onSelectListOpen = async (
    option: 'delete' | 'addToTravelGroup' | 'deleteFromTravelGroup',
  ) => {
    if (this.selectedTransfers.size === 0) {
      return
    }
    if (option === 'delete') {
      try {
        const promises: any[] = []
        runInAction(() => {
          for (const residentId of this.selectedTransfers) {
            const transferId = this.federalTransfers.resources?.find(
              (t) => t.data?.residentId === residentId,
            )?.data?.id
            promises.push(
              hermes.delete(
                `/api/${this.context.instance.id}/federalTransfers/${transferId}`,
              ),
            )
            this.selectedTransfers.delete(residentId)
          }
        })
        await Promise.all(promises)
      } catch (_e) {
        box.alert(
          'Fehler',
          'Die Bewohner konnten nicht gelöscht werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
        )
      }
    }
    if (option === 'addToTravelGroup') {
      if (this.selectedTransfers.size < 2) {
        box.alert(
          'Fehler',
          'Sie müssen mehr als eine*n Bewohner*in auswählen, um eine Fluchtgemeinschaft zu erstellen',
        )
      }
      try {
        const promises: any[] = []
        const travelGroupId = uuid.v4()
        const data = { federalTransfersTravelGroupId: travelGroupId }
        for (const residentId of this.selectedTransfers) {
          const transferId = this.federalTransfers.resources?.find(
            (t) => t.data?.residentId === residentId,
          )?.data?.id
          promises.push(
            hermes.patch(
              `/api/${this.context.instance.id}/federalTransfers/${transferId}`,
              data,
            ),
          )
        }
        await Promise.all(promises)
      } catch (_e) {
        box.alert(
          'Fehler',
          'Die Bewohner konnten nicht zu einer Fluchtgemeinschaft hinzugefügt werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
        )
      }
    }
    if (option === 'deleteFromTravelGroup') {
      try {
        const promises: any[] = []
        for (const residentId of this.selectedTransfers) {
          const transferId = this.federalTransfers.resources?.find(
            (t) => t.data?.residentId === residentId,
          )?.data?.id
          promises.push(
            hermes.patch(
              `/api/${this.context.instance.id}/federalTransfers/${transferId}`,
              { federalTransfersTravelGroupId: null },
            ),
          )
        }
        await Promise.all(promises)
      } catch (_e) {
        box.alert(
          'Fehler',
          'Die Bewohner konnten nicht aus der Fluchtgemeinschaft gelöscht werden. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
        )
      }
    }
  }

  private onSelectListClosed = () => {
    if (this.selectedTransfers.size === 0) {
      return
    }
    const promise = box.custom(
      <JugendamtDialog
        selectedTransfers={this.selectedTransfers}
        onClose={() => promise.close()}
      />,
      { context: this.context },
    )
  }

  private getColorIndexMap = (items) => {
    const travelGroupColors = ['#03A9F4', '#9C27B0']
    const colorIndexMap = new Map<string, string>()

    let colorIndex =
      items.length > 1 && items[1].hasOwnProperty('travelGroupColorIndex')
        ? (+items[1].data?.travelGroupColorIndex + travelGroupColors.length - 2) %
          travelGroupColors.length
        : 0 // old rows keep colors if new row is added
    for (let i = 0; i < items.length; i++) {
      // travel group change (this is the first item in a new travel group)
      if (
        items[i].data?.federalTransfersTravelGroupId === null ||
        i === 0 ||
        items[i].data?.federalTransfersTravelGroupId !==
          items[i - 1].data?.federalTransfersTravelGroupId
      ) {
        colorIndex = (colorIndex + 1) % travelGroupColors.length // alternate color index
      }
      colorIndexMap.set(items[i].id, travelGroupColors[colorIndex])
    }
    return colorIndexMap
  }

  private sortTransfers = (transfers: Resource<IFederalTransferResponse>[]) => {
    const items = transfers.slice().sort((a, b) => {
      if (!a.data || !b.data) {
        return 0
      }

      let idA =
        a.data.federalTransfersTravelGroupId === null
          ? a.data.id
          : a.data?.federalTransfersTravelGroupId
      let idB =
        b.data.federalTransfersTravelGroupId === null
          ? b.data.id
          : b.data?.federalTransfersTravelGroupId

      if (idA === idB) {
        return +b.data.id - +a.data.id
      }

      return +idB - +idA
    })
    return items
  }

  render() {
    if (!this.federalTransfers.resources) {
      return <Spinner />
    }

    const items = this.sortTransfers(this.federalTransfers.resources)
    const colorIndexMap = this.getColorIndexMap(items)

    return (
      <div className='flex flex-col'>
        {!this.props.lockedAt &&
          this.context.permissions.menu_residentFederalTransfers > 1 && (
            <div className='bg-white sm:rounded-lg shadow p-6'>
              <InputResident
                model={this.props.model}
                name='residentId'
                label='Bewohner hinzufügen'
                placeholder='Bewohner auf Verlegungsliste setzen'
                onChoose={this.onChoose}
              />
            </div>
          )}

        <div className='-my-2 mt-6'>
          <div className='py-2 align-middle inline-block min-w-full'>
            <div className='shadow border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Name
                    </th>
                    <th
                      scope='col'
                      className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                    >
                      M-ID
                    </th>
                    <th
                      scope='col'
                      className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                    >
                      Fluchtgemeinschaft
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Besonderheiten
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Aufnehmendes Jugendamt
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {this.federalTransfers.resources.length > 0 &&
                    this.context.permissions.menu_residentFederalTransfers > 1 && (
                      <tr>
                        <td className='py-2 px-6' colSpan={7}>
                          <Button
                            className='mr-4'
                            onClick={this.checkAllFederalTransfers}
                          >
                            {this.selectedTransfers.size <
                            this.federalTransfers.resources.length
                              ? 'Alle markieren'
                              : 'Alle abwählen'}
                          </Button>
                          <ButtonDropdown
                            width={350}
                            align='left'
                            options={
                              this.props.lockedAt
                                ? dropdownOptionsClosed
                                : dropdownOptionsOpen
                            }
                            onSelect={
                              this.props.lockedAt
                                ? () => this.onSelectListClosed()
                                : (option) => this.onSelectListOpen(option.value)
                            }
                          >
                            Markierte
                            <span className='ml-2'>
                              <i className='fas fa-caret-down' />
                            </span>
                          </ButtonDropdown>
                        </td>
                      </tr>
                    )}
                  {this.federalTransfers.resources.length === 0 && (
                    <tr>
                      <td
                        className='px-6 py-4 whitespace-nowrap relative text-gray-600'
                        colSpan={6}
                      >
                        Es befinden sich noch keine Einträge in der Liste für diesen Tag
                      </td>
                    </tr>
                  )}
                  {items.map((item) => this.mapFederalTransfer(item, colorIndexMap))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
