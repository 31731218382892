import { IResidentSearchResultsFilter } from '../interfaces/IResidentSearchResultsFilter'

const rxAgeBracket = /^ *([0-9]{1,2}) *(?:- *([0-9]{1,2}))? *$/

export function isEmptySearchResultsFilter(
  filter: IResidentSearchResultsFilter,
): boolean {
  if (filter.searchEmpty === 'yes') {
    return false
  }
  if (filter.searchString?.trim()) {
    return false
  }
  if (filter.creationUserId) {
    return false
  }
  if (filter.familyId) {
    return false
  }
  if (filter.residentIds) {
    return false
  }
  if (filter.deleted !== 'no') {
    return false
  }

  if (filter.responsibleCompoundId) {
    return false
  }
  if (filter.visit) {
    return false
  }
  if (filter.responsibleScope === 'active') {
    return false
  }
  if (filter.responsibleScope === 'inactive') {
    return false
  }
  if (filter.compoundId) {
    return false
  }
  if (filter.buildingId) {
    return false
  }
  if (filter.sex) {
    return false
  }
  if (filter.nationalityId) {
    return false
  }
  if (filter.languageIds) {
    return false
  }
  if (filter.todoText && filter.todoState) {
    return false
  }
  if (
    filter.dateType &&
    (filter.dateState === 'empty' || filter.dateBegin || filter.dateEnd)
  ) {
    return false
  }
  if (rxAgeBracket.test(filter.ageBracket || '')) {
    return false
  }
  if (filter.lfgSchutzbeduerftig) {
    return false
  }
  if (filter.costCoveragePayer) {
    return false
  }

  return true
}
