import * as React from 'react'
import styles from './styles.module.scss'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { toLocaleNumber } from 'contracts/general/helpers/toLocaleNumber'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'
import { IInvoiceWohnungsloseMeta } from 'contracts/costCoverages/interfaces/IInvoiceWohnungsloseMeta'
import signature from './signature.png'

interface Props {
  invoice: IInvoiceWohnungsloseMeta
}

export const EinzelrechnungWohnungslose: React.FC<Props> = ({ invoice }) => {
  React.useEffect(setReadyFlagWhenRenderingComplete, [])
  return (
    <div className={styles.page} style={{ padding: '80px 0 0 0' }}>
      <div style={{ margin: '0 100px 20px 100px', fontSize: 15 }}>
        <b>Einzelrechnung zur Sofortunterbringung</b>
      </div>

      <div
        style={{
          margin: '0 100px',
          padding: 12,
          border: '1px solid black',
          fontSize: 15,
        }}
      >
        <b style={{ textDecoration: 'underline' }}>Hinweise:</b>
        <br />
        Diese Sofortunterbringung gilt nur für die vorseitig namentlich aufgeführten
        Personen in dem vorseitig genannten Beherbergungsbetrieb und zu dem mit dem Amt
        für Wohnen und Migration vertraglich geregelten Bettplatzentgelt. Sie ist nicht
        übertragbar <b>und nur am Tag der Ausstellung gültig.</b>
        <br />
        <br />
        Für Ihre Abrechnung reichen Sie bitte stets einen Abdruck der Sofortunterbringung
        bzw. deren Verlängerung gemeinsam mit dieser Einzelrechnung ein. Diese müssen bis
        zum 10. des Folgemonats beim Amt für Wohnen und Migration, Zentrale
        Wohnungslosenhilfe, Fachbereich Wirtschaftliche Hilfen, S-III-WP/Z/WH,
        Franziskanerstr. 8, 81669 München vorliegen. Bei Unvollständigkeit ist eine
        Monatsabrechnung nicht möglich. Bei vorzeitigem Auszug ist eine Abrechnung nur für
        den tatsächlichen Aufenthaltszeitraum möglich.
        <br />
        <br />
        <b>
          <span style={{ textDecoration: 'underline' }}>Hinweis:</span> Wenn der/die
          Gast/Gäste nicht zu Beginn der Sofortunterbringung das Zimmer bezieht/beziehen
          bzw. mehr als 2 mal pro Woche einen Tag oder länger als 3 Tage nacheinander
          nicht anwesend ist/sind, verliert diese Sofortunterbringung ihre Gültigkeit.
        </b>
      </div>

      <div
        style={{
          margin: '20px 100px',
          fontSize: 15,
          fontWeight: 'bold',
          textDecoration: 'underline',
        }}
      >
        Kostennachweis zur umseitigen Kostenzusicherung für {invoice.receiver}
      </div>

      <div style={{ margin: '0 100px', fontSize: 15 }}>
        Der Gast / Die Gäste wies/en sich mit Personalausweis/Reisepass/Bescheinigung des
        Kreisverwaltungsreferates aus / nicht aus. Hiermit bestätigen wir ausdrücklich,
        dass der/die umseitig Genannte/n im abgerechneten Zeitraum tatsächlich bei uns
        übernachtet hat/haben.
      </div>

      <div style={{ margin: '0 100px', fontSize: 15 }} className='leading-[25px] pt-5'>
        Bemerkungen:{' '}
        <span
          style={{ textDecoration: 'underline' }}
        >{`Aufenthalt ${invoice.building}`}</span>
        <div className='truncate'>
          Etage <span style={{ textDecoration: 'underline' }}>{invoice.floor}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zimmernummer{' '}
          <span className='underline'>{invoice.room}</span>
        </div>
        Dies ergibt folgenden Kostenbetrag:
        <br />
        <table style={{ width: '100%' }}>
          <tr>
            <td colSpan={2} style={{ width: 280 }}>
              vom{' '}
              <span style={{ textDecoration: 'underline' }}>
                {dayjs(invoice.beginDate).format('DD.MM.YYYY')}
              </span>{' '}
              bis einschließlich{' '}
              <span style={{ textDecoration: 'underline' }}>
                {dayjs(invoice.endDate).format('DD.MM.YYYY')}
              </span>
            </td>
            <td>
              =&nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ textDecoration: 'underline' }}>{invoice.days}</span> Tage
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <span style={{ textDecoration: 'underline' }}>{invoice.days}</span>{' '}
              Übernachtungen x{' '}
              <span style={{ textDecoration: 'underline' }}>
                {toLocaleNumber(invoice.tagessatz.toFixed(2))}
              </span>{' '}
              €&nbsp;&nbsp;Kostenbetrag
            </td>
            <td>
              =&nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ textDecoration: 'underline' }}>
                {toLocaleNumber(invoice.costTotal.toFixed(2))}
              </span>{' '}
              €
            </td>
          </tr>
          <tr>
            <td style={{ width: 220 }}>abzügl. Eigenanteil durch Gast</td>
            <td style={{ textAlign: 'right' }}>
              <span style={{ textDecoration: 'underline' }}>
                {toLocaleNumber(invoice.eigenanteilTotal.toFixed(2))}
              </span>{' '}
              €
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>geforderter Endbetrag</td>
            <td style={{ textAlign: 'right' }}>
              <span style={{ textDecoration: 'underline' }}>
                {toLocaleNumber(
                  (invoice.costTotal - invoice.eigenanteilTotal).toFixed(2),
                )}
              </span>{' '}
              €
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </div>
      <div style={{ margin: '0 100px', fontSize: 15 }} className='pt-5'>
        Für den geforderten Endbetrag wird die Abtretung an die Landeshauptstadt München
        erklärt.
        <br />
        Auf den Zugang der Annahmeerklärung wird verzichtet.
        <table style={{ width: '100%' }}>
          <tr>
            <td
              style={{
                verticalAlign: 'bottom',
                borderBottom: '1px solid black',
                width: 180,
              }}
            >{`München, ${dayjs().format('DD.MM.YYYY')}`}</td>
            <td>&nbsp;</td>
            <td
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                width: 360,
                paddingBottom: 4,
              }}
            >
              <img
                style={{ width: 240 }}
                className='relative top-4'
                src={signature}
                alt='Unterschrift'
              />
              &nbsp;
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 12 }}>Ort, Datum</td>
            <td>&nbsp;</td>
            <td style={{ fontSize: 12 }}>
              Unterschrift / Firmenstempel des Beherbergungsbetriebes
            </td>
          </tr>
        </table>
      </div>

      <div
        style={{
          margin: '20px 100px',
          paddingTop: 12,
          fontSize: 15,
          borderTop: '1px solid black',
        }}
      >
        Jobcenter München
        ZEW&nbsp;&nbsp;&nbsp;&nbsp;S-III-WP/Z/WH&nbsp;&nbsp;&nbsp;&nbsp;S-III-MF/A/WH
      </div>
    </div>
  )
}
