import * as React from 'react'
import { Dialog as CustomDialog } from 'components/Dialog'
import { useNavigate } from 'react-router'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import { Collection } from '@byll/hermes'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { useParams } from 'react-router'
import { observer } from 'mobx-react-lite'
import { FolderPermissions } from './components/FolderPermissions'

interface Props {
  resident: IResident
  folders: Collection<IDocumentFolder>
}

export const FolderPermissionsDialog: React.FC<Props> = observer((props) => {
  const navigate = useNavigate()
  const onClose = () =>
    navigate(`/residents/${toJbpId(+props.resident.id).toLowerCase()}/overview`)
  const params = useParams<{ folderId: string }>()
  const folder = props.folders.resources?.find((f) => f.id === params.folderId)?.data

  return (
    <CustomDialog size='md' open setOpen={onClose}>
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                Zugriffsrechte
              </Dialog.Title>
              <div className='truncate text-gray-400 text-sm mt-1'>
                <i className='far fa-folder' /> {folder?.label || ''}
              </div>
            </div>
          </div>
        </div>
      </div>

      {folder && <FolderPermissions onClose={onClose} folder={folder} />}
    </CustomDialog>
  )
})
