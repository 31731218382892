import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { makeObservable, observable, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext } from 'services/connection/models/AppContext'
import { Message } from 'components/Message'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { ICostCoverageSignature } from 'contracts/costCoverages/interfaces/ICostCoverageSignature'
import { CostCoverageSignature } from 'modules/Pdf/templates/CostCoverageSignature'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { RoundIcon } from 'components/RoundIcon'
import { CostCoverageSignatureDocuments } from './CostCoverageSignatureDocuments'
import { months } from 'components/Form/components/InputMonth'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { PrintIcon } from '../../OverviewDocuments/components/PrintIcon'

interface Props {
  onClose: () => void
  resident: IResident
  signature: ICostCoverageSignature
  changed: { hasUnsavedChanges: boolean }
}

@observer
export class CostCoverageSignatureDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<ICostCoverageSignature>
  private readonly disposers: Disposer[] = []
  @observable private downloading = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    this.model = new Model({ ...toJS(props.signature) })
    makeObservable(this)
  }

  /*componentDidMount () {
    this.disposers.push(reaction(
      () => this.model.values.documentId !== this.props.signature.document.id,
      (hasChanges) => {
        if (hasChanges) { this.props.changed.hasUnsavedChanges = true }
      },
    ))
  }*/

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onDownload = async () => {
    if (this.downloading) {
      return
    }
    runInAction(() => (this.downloading = true))
    try {
      await createAndDownloadReport(
        'cost-coverage-signature',
        this.context.instance.id,
        { residentId: this.props.resident.id, signatureIds: [this.props.signature.id] },
        'Unterschrift_Unterkunftsplatznutzung.pdf',
      )
    } catch (_e) {
      /* */
    }
    runInAction(() => (this.downloading = false))
  }

  render() {
    const month = dayjs(this.props.signature.month, 'YYYY-MM')
    return (
      <div id={this.model.id}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                Unterschrift Unterkunftsplatznutzung&nbsp;&nbsp;&nbsp;
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800'>
                  {`${months[month.month()]} ${month.year()}`}
                </span>
              </Dialog.Title>
            </div>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mx-6 mt-4'>
            {this.error}
          </Message>
        )}

        <div className='p-6 bg-gray-100'>
          <div className='shadow-md relative'>
            <CostCoverageSignature
              signature={this.props.signature}
              instanceId={this.context.instance.id}
            />
            <RoundIcon
              icon={this.downloading ? 'fas fa-sync fa-spin' : 'fas fa-download'}
              tooltip='Herunterladen'
              style={{ position: 'absolute', top: 16, right: 16 }}
              color='primary'
              onClick={this.onDownload}
            />
            <PrintIcon
              url={`/api/${this.context.instance.id}/costCoverages/uknPrint/${this.model.values.id}`}
              style={{ position: 'absolute', top: 54, right: 16 }}
            />
          </div>

          {this.context.permissions.resident_ukns === 2 && (
            <CostCoverageSignatureDocuments
              signature={this.props.signature}
              resident={this.props.resident}
            />
          )}
        </div>

        {/*<div className='py-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200' style={{ borderRadius: '0 0 8px 8px' }}>
          <Button disabled={this.loading} color='secondary' outline onClick={this.props.onClose}>Abbrechen</Button>
          <Button disabled={this.loading} color='primary' className='ml-2' onClick={this.onSubmit}>Speichern</Button>
    </div>*/}
      </div>
    )
  }
}
