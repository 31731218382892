import * as React from 'react'
import styles from './styles.module.scss'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { OtherPreview } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/OtherPreview'
import { Wait } from 'modules/Pdf/components/Wait'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { makeObservable, observable, runInAction } from 'mobx'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { hermes } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { Spinner } from 'components/Spinner'
import { Message } from 'components/Message'
import {
  InputButtonGroup,
  InputButtonGroupOption,
} from 'components/Form/components/InputButtonGroup'
import * as qs from 'qs'

const fristOptions: InputButtonGroupOption[] = [
  { value: '1 month', label: '1 Monat Frist' },
  { value: '2 weeks', label: '2 Wochen Frist' },
]

@observer
export class AwumBescheidAVSW extends React.Component<DocumentTemplateProps, {}> {
  static contextType = AppContext
  @observable.ref private residents: IResidentSearchResult[] | null = null
  @observable error: { message: string | null } = { message: null }

  constructor(props: DocumentTemplateProps, context: AppContextProps) {
    super(props)
    makeObservable(this)
    runInAction(() => {
      props.model.values.postzustellungsCheck = !!props.model.values.postzustellungsCheck
      props.model.values.frist = props.model.values.frist || '1 month' // '1 month' | '2 weeks'
    })
  }

  componentDidMount(): void {
    void this.load()
  }

  private load = async () => {
    const residents = await hermes.indexOnceNew<IResidentSearchResult>(
      `/api/${this.context.instance.id}/residentSearchResults${qs.stringify(
        {
          familyId: this.props.resident.familyId,
          fields: 'bookings',
          sort: 'dateOfBirth,asc',
        },
        { allowDots: true, addQueryPrefix: true, skipNulls: true },
      )}`,
    )
    runInAction(() => (this.residents = residents))
  }

  render() {
    if (!this.residents) {
      return <Spinner />
    }
    return (
      <>
        <CardColumnCard
          title='Einstellungen'
          subtitle='Setzen Sie die Frist, etc.'
          minHeight={1}
        >
          {this.error.message && <Message color='danger'>{this.error.message}</Message>}
          <div className='flex -mt-6'>
            <InputCheckbox
              className='flex-auto'
              model={this.props.model}
              name='postzustellungsCheck'
              label='Gegen Postzustellungsurkunde'
            />
            <div className='flex-auto'>
              <InputButtonGroup
                model={this.props.model}
                name='frist'
                options={fristOptions}
              />
            </div>
          </div>
        </CardColumnCard>
        <div className={styles.page}>
          <OtherPreview id='AwumBescheidAVSW' name='Bescheid AV SW.docx' />
          <Wait />{' '}
          {/* Infinite waiter, because this placeholder must not be used for rendering pdfs */}
        </div>
      </>
    )
  }
}
