import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { TransferState } from './UploadStripe'
import axios, { AxiosProgressEvent } from 'axios'
import { AppContext } from 'services/connection/models/AppContext'
import { dataUriToBlob } from '../helpers/dataUriToBlob'
import { RoundIcon } from '../../../../RoundIcon'
import { IImageSection } from '../helpers/getImageSection'

interface Props {
  scope: 'user' | 'resident' | 'blog' | 'inventory item'
  image: {
    id: string
    src: string
    width: number
    height: number
    section: IImageSection
  }
  transfer: TransferState
  width: number
  height: number
  onFinish: (id: string) => void // new image id
  onCancel: () => void
  onError: (error) => void
}

@observer
export class UploadImage extends React.Component<Props, {}> {
  static contextType = AppContext
  private cancelUpload: (() => void) | null = null

  componentDidMount() {
    void this.upload()
  }

  componentWillUnmount() {
    this.cancelUpload?.()
  }

  private upload = async () => {
    runInAction(() => {
      this.props.transfer.type = 'uploading'
      // start at 5% to visualize pending upload for files that are scheduled to upload
      this.props.transfer.progress = 0.05
    })

    const form = new FormData()
    form.append('id', this.props.image.id)
    form.append('scope', this.props.scope)
    form.append('section', JSON.stringify(this.props.image.section))
    form.append('file', await dataUriToBlob(this.props.image.src))

    const source = axios.CancelToken.source()
    this.cancelUpload = () => source.cancel('Canceled by user')
    const uploadPromise = axios.post(`/api/${this.context.instance.id}/images`, form, {
      cancelToken: source.token,
      timeout: 30000,
      onUploadProgress: action((event: AxiosProgressEvent) => {
        if (event.total && event.loaded / event.total <= 1) {
          // Start at 5% to visualize pending upload for files that are scheduled to upload.
          // Stop at 90% to visualize that there is still wait time until server processed images.
          this.props.transfer.progress = 0.05 + 0.85 * (event.loaded / event.total)
        }
      }),
    })
    try {
      await uploadPromise
      this.cancelUpload = null
      runInAction(() => {
        this.props.transfer.type = 'uploaded'
        this.props.transfer.progress = 1
      })
      this.props.onFinish(this.props.image.id)
    } catch (e) {
      if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
        this.props.onCancel()
      } else {
        this.props.onError(e)
      }
    }
  }

  private onCancel = () => {
    this.cancelUpload?.()
    this.props.onCancel()
  }

  render() {
    const cache = this.props.image
    const style: any = {
      position: 'absolute',
      maxWidth: 'initial',
      left: 0,
      top: 0,
      width: this.props.width,
      height: this.props.height,
    }
    if (cache?.section) {
      const scale = this.props.width / cache.section.width
      style.left = -Math.round(cache.section.x * scale)
      style.top = -Math.round(cache.section.y * scale)
      style.width = Math.ceil(cache.width * scale)
      style.height = Math.ceil(cache.height * scale)
    }

    return (
      <div>
        <img alt='' style={style} src={this.props.image.src} />
        <RoundIcon
          onClick={this.onCancel}
          icon='fas fa-times'
          style={{ position: 'absolute', right: 10, bottom: 10 }}
        />
      </div>
    )
  }
}
