import * as React from 'react'
import { Collection } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IInventoryItemScan } from 'contracts/inventory/interfaces/IInventoryItemScan'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {
  resident: IResidentSearchResult
  compoundId: string
}

@observer
export class ResidentInventoryScanLog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly logs: Collection<IInventoryItemScan>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.logs = new Collection(`/api/${context.instance.id}/inventory/logs`, {
      residentId: props.resident.id,
      compoundId: props.compoundId,
    })
  }

  componentDidMount() {
    this.disposers.push(this.logs.init({ readOnly: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  render() {
    return (
      <div className='bg-gray-100 rounded p-6 overflow-y-scroll max-h-[500px] shadow'>
        <table className='min-w-full divide-y divide-gray-300'>
          <thead>
            <tr>
              <th
                scope='col'
                className='pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0'
              >
                Gegenstand
              </th>
              <th
                scope='col'
                className='hidden pb-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell'
              >
                Datum
              </th>
            </tr>
          </thead>
          <tbody>
            {this.logs.resources?.map((l) => {
              if (!l.data) {
                return null
              }
              return (
                <tr key={l.id} className='last:border-0 border-b border-gray-300'>
                  <td className='py-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0'>
                    <div className='font-medium text-gray-900'>{`${l.data.count} x ${l.data.label}`}</div>
                  </td>
                  <td className='py-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0'>
                    <div className='mt-0.5 text-gray-900 text-right'>
                      {dayjs(l.data.issuedAt).format('DD.MM.YYYY')}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
