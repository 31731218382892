import { InputSelectOption } from 'components/Form/components/InputSelect'

export const familyRelationOptions: InputSelectOption[] = [
  { value: '', label: '' },
  { value: 'Haushaltsvorstand', label: 'Haushaltsvorstand' },
  { value: 'Angehöriger', label: 'Angehöriger' },
  { value: 'Bruder', label: 'Bruder' },
  { value: 'Cousine', label: 'Cousine' },
  { value: 'Cousin', label: 'Cousin' },
  { value: 'Ehefrau', label: 'Ehefrau' },
  { value: 'Ehemann', label: 'Ehemann' },
  { value: 'Enkelin', label: 'Enkelin' },
  { value: 'Enkel', label: 'Enkel' },
  { value: 'Mutter', label: 'Mutter' },
  { value: 'Neffe', label: 'Neffe' },
  { value: 'Nichte', label: 'Nichte' },
  { value: 'Onkel', label: 'Onkel' },
  { value: 'Schwester', label: 'Schwester' },
  { value: 'Sohn', label: 'Sohn' },
  { value: 'Tante', label: 'Tante' },
  { value: 'Tochter', label: 'Tochter' },
  { value: 'Vater', label: 'Vater' },
  { value: 'Sonstige', label: 'Sonstige' },
]
