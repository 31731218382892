import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Callout } from 'components/Callout'
import { Model } from 'components/Form/Model'
import { LoadMoreButton } from 'components/LoadMoreButton'
import { Spinner } from 'components/Spinner'
import { ILogFilter } from 'contracts/general/interfaces/ILogFilter'
import { ILogSearchResult } from 'contracts/general/interfaces/ILogSearchResult'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { LogDetails } from './components/LogDetails'

interface Props {
  resident: IResident
}

@observer
export class LogTab extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<ILogFilter>
  private readonly logs: Collection<
    ILogSearchResult,
    ILogFilter & { count: number },
    ILogFilter
  >
  private readonly disposers: Disposer[] = []
  @observable private openLogId: string | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({ residentId: props.resident.id, page: '0,50' })
    this.logs = new Collection(`/api/${context.instance.id}/logs`, this.model.values)
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.logs.init({ readOnly: true, observeQuery: true }))
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action private openLog = (log: ILogSearchResult) => {
    if (this.openLogId === log.id) {
      this.openLogId = null
    } else {
      this.openLogId = log.id
    }
  }

  private logMapper = ({ data: log }: Resource<ILogSearchResult>, i: number) => {
    if (!log) {
      return null
    }
    const at = dayjs(log.at)
    const thumbnail =
      this.context.permissions.resident_showChronicles <= 1
        ? getResidentImageSrc(this.context.instance.id, null, null, 'thumbnail')
        : getResidentImageSrc(
            this.context.instance.id,
            log.initiator?.imageId || null,
            log.initiator?.sex || null,
            'thumbnail',
          )
    const createdBy = log.initiator
      ? this.context.permissions.resident_showChronicles <= 1
        ? 'Ein Benutzer'
        : `${log.initiator.lastName}, ${log.initiator.firstName}`
      : 'Inuv System'
    return (
      <li
        key={log.id}
        className={`relative group rounded-md cursor-pointer p-3 border-2 ${
          this.openLogId === log.id
            ? 'border-blue-500 bg-gray-100 hover:bg-gray-50'
            : 'border-transparent hover:bg-gray-100'
        } ${i + 1 === this.logs.resources?.length ? 'mb-4' : ''}`}
        onClick={() => this.openLog(log)}
      >
        <div
          className={`relative overflow-hidden ${
            i + 1 === this.logs.resources?.length ? 'pb-4' : 'pb-8'
          }`}
        >
          {i + 1 !== this.logs.resources?.length ? (
            <span
              className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
              aria-hidden='true'
            />
          ) : null}
          <div className='relative flex space-x-3'>
            <div className='relative'>
              <img
                className='h-12 w-12 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white'
                src={thumbnail}
                alt={createdBy}
              />
            </div>
            <div className='flex min-w-0 flex-1 justify-between space-x-4'>
              <div className={this.openLogId === log.id ? 'overflow-hidden' : 'truncate'}>
                <span className='text-indigo-500'>{createdBy}</span>
                &nbsp;<span className='text-black'>{log.label}</span>
                <br />
                <span className='inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800'>
                  {log.type}
                </span>
                {log.compound && (
                  <span className='text-gray-400 text-sm'>
                    &nbsp;&nbsp;&nbsp;&nbsp;{log.compound.label}
                  </span>
                )}
              </div>
              <div className='whitespace-nowrap text-right text-gray-500'>
                {at.format('DD.MM.YYYY')}
                <br />
                {/* Uhrzeit: this.openLogId === log.id && (
                  <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-500 border border-gray-500">
                    <i className='fas fa-clock' />&nbsp;{at.format('HH:mm')}
                  </span>
                )*/}
              </div>
            </div>
          </div>
          {this.openLogId === log.id && (
            <LogDetails view={log.details.view} changes={log.details.changes} />
          )}
        </div>
      </li>
    )
  }

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div
          className='hidden lg:block pr-12 pt-4 text-right'
          style={{ flex: '0 0 200px' }}
        >
          <span className='text-gray-900 text-lg'>Chronik</span>
          <br />
          <span className='text-sm text-gray-400'>Änderungen der Bewohnerdaten</span>
        </div>
        <div className='flex-auto pt-4 relative min-w-0 mr-5'>
          {/* Log entries */}
          {this.logs.resources && this.logs.metadata && (
            <div className='flow-root'>
              <ul>{this.logs.resources.map(this.logMapper)}</ul>
              {this.logs.metadata.count > 2 && (
                <div className='text-center text-sm'>
                  {/* eslint-disable */}
                  <LoadMoreButton
                    collection={this.logs}
                    incrementBy={50}
                    pluralText='Sie sehen alle ${count} Einträge'
                  />
                  {/* eslint-enable */}
                </div>
              )}
            </div>
          )}

          {/* Loader */}
          {!this.logs.resources && (
            <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
              <Spinner delay />
            </div>
          )}

          {/* Empty message */}
          {this.logs.resources?.length === 0 && (
            <div className='absolute rounded-md bg-gray-100 border border-gray-300 bottom-4 right-4 left-0 top-4 flex overflow-hidden'>
              <Callout
                icon='fas fa-search'
                title='Bisher keine Einträge hinterlegt'
                subtitle='Änderungen an der Bewohnerakte erscheinen hier.'
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
