import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Model } from 'components/Form/Model'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { ITransferPlanningsFilter } from 'contracts/transfer/interfaces/ITransferPlanningsFilter'
import { ITransferTarget } from 'contracts/transfer/interfaces/ITransferTarget'
import { ITransferTransportation } from 'contracts/transfer/interfaces/ITransferTransportation'
import dayjs from 'dayjs'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { TransferPlanningDropdown } from './TransferPlanningDropdown'
import { TransferPlanningList } from './TransferPlanningList'
import { TransferPlanningTopBar } from './TransferPlanningTopBar'
import { TransferTargetDialog } from './TransferTargetDialog'
import { TransferTransportationDialog } from './TransferTransportationDialog'

interface Props {
  selectedBuildings: Map<string, boolean>
  model: Model<ITransferPlanningsFilter>
}

const statusFilterOptions = [
  { value: null, label: 'Alle Transfers' },
  { value: 'not complete', label: 'Ausstehende Transfers' },
  { value: 'complete', label: 'Abgeschlossene Transfers' },
]

@observer
export class TransferPlanningContent extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private transportations: ITransferTransportation[] = []
  @observable private targets: ITransferTarget[] = []
  @observable private transportationOptions: InputSelectOption[] = []
  @observable private targetOptions: InputSelectOption[] = []
  private readonly createdByOptions: InputSelectOption[]

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.createdByOptions = [
      { value: null, label: 'Alle Planer' },
      { value: context.user.id, label: 'Meine Planungen' },
    ]
  }

  componentDidMount() {
    this.loadTransportations()
    this.loadTargets()
    reaction(
      () => this.props.selectedBuildings.keys(),
      () => {
        this.props.model.values.transportationId = null
        this.props.model.values.targetId = null
      },
    )
  }

  private loadTransportations = async () => {
    if (this.props.selectedBuildings.size !== 1) {
      return
    }
    try {
      const transportations: ITransferTransportation[] = await hermes.indexOnceNew(
        `/api/${this.context.instance.id}/transfer/transportations?buildingId=${
          Array.from(this.props.selectedBuildings.keys())[0]
        }`,
      )
      runInAction(() => {
        this.transportations = transportations
        this.transportationOptions = transportations.map((t) => ({
          value: t.id,
          label: t.label,
        }))
        this.transportationOptions.unshift({ value: null, label: 'Alle Transportmittel' })
      })
    } catch (_e) {
      return []
    }
  }

  private loadTargets = async () => {
    if (
      this.props.selectedBuildings.size !== 1 ||
      !this.props.selectedBuildings.has('§')
    ) {
      return
    }
    try {
      const targets: ITransferTarget[] = await hermes.indexOnceNew(
        `/api/${this.context.instance.id}/transfer/targets`,
      )
      runInAction(() => {
        this.targets = targets
        this.targetOptions = targets.map((t) => ({
          value: t.id,
          label: t.label,
        }))
        this.targetOptions.unshift({ value: null, label: 'Alle Ziele' })
      })
    } catch (_e) {
      return []
    }
  }

  private createTransportation = async () => {
    const transportation: ITransferTransportation = {
      id: '',
      instanceId: this.context.instance.id,
      label: '',
      departureDate: this.props.model.values.begin,
      departureTime: '',
      arrivalDate: this.props.model.values.begin,
      arrivalTime: '',
      buildingId:
        this.props.selectedBuildings.size === 1 && this.props.selectedBuildings.has('§')
          ? null
          : Array.from(this.props.selectedBuildings.keys())[0],
      comment: '',
    }
    const promise = box.custom(
      <TransferTransportationDialog
        transportation={transportation}
        onClose={(id?: string) => promise.close(id)}
      />,
      { context: this.context },
    )
    const id = await promise
    if (id) {
      this.loadTransportations()
      runInAction(() => (this.props.model.values.transportationId = id))
    } else {
      runInAction(() => (this.props.model.values.transportationId = null))
    }
  }

  private createTarget = async () => {
    const target: ITransferTarget = {
      id: '',
      instanceId: this.context.instance.id,
      label: '',
      street: '',
      zip: '',
      city: '',
      federalState: '',
      comment: '',
      contactPerson: '',
    }
    const promise = box.custom(
      <TransferTargetDialog
        target={target}
        onClose={(id?: string) => promise.close(id)}
      />,
      { context: this.context },
    )
    const id = await promise
    if (id) {
      this.loadTargets()
      runInAction(() => (this.props.model.values.targetId = id))
    } else {
      runInAction(() => (this.props.model.values.targetId = null))
    }
  }

  private downloadReport = async () => {
    try {
      if (!this.props.model.values.targetId) {
        box.alert(
          'Fehler',
          `Bitte wählen Sie ein Ziel aus. Die Liste kann nur für ein einzelnes Ziel exportiert werden.`,
        )
        return
      }
      if (this.props.model.values.begin !== this.props.model.values.end) {
        box.alert(
          'Fehler',
          `Bitte stellen Sie das gleiche Start- und Enddatum ein (Geplante Transfers ab - bis). Die Liste kann nur für einen einzelnen Tag exportiert werden.`,
        )
        return
      }
      const data = {
        buildingIds: Array.from(this.props.selectedBuildings)
          .filter((b) => b[1] === true)
          .map((b) => b[0])
          .join(','),
        begin: this.props.model.values.begin
          ? dayjs(this.props.model.values.begin).toISOString()
          : null,
        end: this.props.model.values.end
          ? dayjs(this.props.model.values.end).toISOString()
          : null,
        transportationId:
          this.props.selectedBuildings.size === 1
            ? this.props.model.values.transportationId
            : null,
        targetId:
          this.props.selectedBuildings.size === 1 && this.props.selectedBuildings.has('§')
            ? this.props.model.values.targetId
            : null,
        createdBy: this.props.model.values.createdBy,
        status: this.props.model.values.status,
      }
      await createAndDownloadReport(
        'transfer-planning',
        this.context.instance.id,
        data,
        'Transferplanung.xlsx',
        { handleErrors: false },
      )
    } catch (e: any) {
      if (e.response?.data?.id === ConflictError.id) {
        box.alert('Fehler', e.response.data.message)
      } else {
        box.alert('Fehler', 'Der Download ist leider fehlgeschlagen.')
      }
    }
  }

  render() {
    return (
      <div className='md:pl-80 flex flex-col flex-1 h-screen'>
        <TransferPlanningTopBar>
          <div className='mx-2 my-4'>
            <InputDate
              label='Geplante Transfers ab'
              model={this.props.model}
              name='begin'
              placeholder='TT.MM.JJJJ'
            />
          </div>
          <div className='mx-2 my-4'>
            <InputDate
              label='bis'
              model={this.props.model}
              name='end'
              placeholder='TT.MM.JJJJ'
            />
          </div>
          {this.props.selectedBuildings.size === 1 &&
            this.transportationOptions.length > 0 && (
              <div className='mx-2 my-4'>
                <TransferPlanningDropdown
                  name='transportationId'
                  label='Transportmittel'
                  options={this.transportationOptions}
                  loadOptions={this.loadTransportations}
                  model={this.props.model}
                  onSelect={this.createTransportation}
                  transportations={this.transportations}
                />
              </div>
            )}
          {this.props.selectedBuildings.size === 1 && this.targetOptions.length > 0 && (
            <div className='mx-2 my-4'>
              <TransferPlanningDropdown
                name='targetId'
                label='Ziel'
                options={this.targetOptions}
                loadOptions={this.loadTargets}
                model={this.props.model}
                onSelect={this.createTarget}
                targets={this.targets}
              />
            </div>
          )}
          <div className='mx-2 my-4'>
            <InputSelect
              label='Planerfilter'
              name='createdBy'
              model={this.props.model}
              options={this.createdByOptions}
            />
          </div>
          <div className='mx-2 my-4'>
            <InputSelect
              label='Statusfilter'
              name='status'
              model={this.props.model}
              options={statusFilterOptions}
            />
          </div>
          {this.props.selectedBuildings.size === 1 &&
            this.props.selectedBuildings.has('§') && (
              <Button className='mx-2 my-4' onClick={this.downloadReport}>
                Download
              </Button>
            )}
        </TransferPlanningTopBar>
        <div className='rounded-md shadow-md mx-4 bg-white flex-auto my-4 flex flex-col'>
          {/* No buildings selected */}
          {this.props.selectedBuildings.size === 0 && (
            <div className='m-6 flex flex-col'>
              <span className='text-gray-600'>
                Wählen Sie ein Gebäude aus, um die Transferplanung zu laden.
              </span>
            </div>
          )}

          {/* List of planned transfers */}
          {this.props.selectedBuildings.size > 0 && (
            <TransferPlanningList
              model={this.props.model}
              selectedBuildings={this.props.selectedBuildings}
            />
          )}
        </div>
      </div>
    )
  }
}
