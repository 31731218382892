import { z } from 'zod'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isISODate } from '../../general/helpers/isISODate'

export const GroupFieldsValidator = z.array(
  z.object({
    id: z.string().uuid(),
    type: z.enum(['text', 'date', 'select', 'checkbox']),
    label: z.string().max(255),
    options: z.array(z.string().max(255)),
  }),
)

export const CreateGroupValidator = z.object({
  compoundId: z.string().refine(isIntegerString),
  label: z.string().min(1).max(255),
  deletedAt: z.string().refine(isISODate).nullable(), // Archive in advance.
  fields: GroupFieldsValidator,
})

export const UpdateGroupValidator = z.object({
  label: z.string().min(1).max(255),
  fields: GroupFieldsValidator,
})
