import * as React from 'react'

interface Props {}

export const CostCoverageSignatureFooter: React.FC<Props> = () => (
  <div
    className='grid grid-cols-3 gap-4 text-xs text-gray-500 absolute break-inside-avoid'
    style={{ bottom: 40, fontSize: 9 }}
  >
    <div>
      LfG Landesbetrieb für Gebäudebewirtschaftung Berlin
      <br />
      Betriebsteil B<br />
      Geschäftsadresse der Verwaltung
      <br />
      Brunnenstraße 110c, 13355 Berlin
    </div>
    <div>
      Rechtsform: Landesbetrieb
      <br />
      Träger: Land Berlin, Senatsverwaltung für Finanzen
      <br />
      Rechtsgrundlage: Geschäftsanweisung vom 6. April 2017
      <br />
      Geschäftsleitung: Bilkay Öney
    </div>
    <div>
      Bankverbindung:
      <br />
      Berliner Sparkasse
      <br />
      IBAN: DE02 1005 0000 0190 6215 08
      <br />
      BIC: BELADEBEXXX
    </div>
  </div>
)
