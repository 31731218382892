import { IOccupancyTimeModel } from '../interfaces/IOccupancyTimeModel'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Model } from 'components/Form/Model'
import { reaction } from 'mobx'
import dayjs from 'dayjs'
import { isTime } from 'contracts/general/helpers/isTime'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'

export function updateOccupancyTime(
  time: Model<IOccupancyTimeModel>,
  filter: Model<IOccupancyFilter>,
): Disposer {
  return reaction(
    () => `${time.values.date}/${time.values.time}`,
    () => {
      // Invalid time or date
      if (!time.values.date) {
        time.values.at = null
        filter.values.queryAt = null
        return
      }

      // Now
      if (
        time.values.date === dayjs().format('YYYY-MM-DD') &&
        !isTime(time.values.time)
      ) {
        time.values.at = 'now'
        filter.values.queryAt = 'now'
        return
      }

      // Past or future
      const t = isTime(time.values.time) ? time.values.time : '23:59'
      const at = dayjs(`${time.values.date} ${t}:00`, 'YYYY-MM-DD HH:mm:ss').toISOString()
      time.values.at = at
      filter.values.queryAt = at
    },
  )
}
