import * as React from 'react'
import { Model } from 'components/Form/Model'
import { AppContext } from 'services/connection/models/AppContext'
import { InputSelect } from 'components/Form/components/InputSelect'
import { aufenthaltsstatusOptions } from 'modules/Residents/components/CaseRecordForm/constants/aufenthaltsstatusOptions'
import { observer } from 'mobx-react'

interface IAufenthaltsstatus {
  id: string
  name: string
  legalAufenthaltsstatus: string
}

interface Props {
  resident: IAufenthaltsstatus
  error: { message: string | null }
}

@observer
export class SelectAufenthaltsstatus extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IAufenthaltsstatus>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.resident)
  }

  /*private onSelect = async () => {
    runInAction(() => (this.props.error.message = null))
    try {
      await hermes.patch(
        `/api/${this.context.instance.id}/residents/${this.props.resident.id}`,
        this.model.values,
      )
    } catch (_e) {
      runInAction(
        () =>
          (this.props.error.message =
            'Der Aufenthaltsstatus konnte nicht gespeichert werden.'),
      )
    }
  }*/

  render() {
    return (
      <div className='grid grid-cols-2 gap-4 my-2 items-center'>
        <div className='truncate'>{this.model.values.name}</div>
        <InputSelect
          model={this.model}
          name='legalAufenthaltsstatus'
          label='Aufenthaltstitel'
          options={aufenthaltsstatusOptions}
        />
      </div>
    )
  }
}
