/**
 * @param {string} id node selector
 * @param {number} timeout in milliseconds
 * @return {Promise<void>}
 */
import { sleep } from './sleep'

export async function waitForElementWithId(id: string, timeout = 200) {
  const limit = Date.now() + timeout
  while (Date.now() < limit) {
    const node = document.getElementById(id)
    if (node) {
      return node
    }
    await sleep(10)
  }
  return null
}
