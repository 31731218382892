import { ITreeNode } from 'contracts/general/interfaces/ITreeNode'
import * as React from 'react'

interface Props {
  node: ITreeNode
  selected: string | null
  onSelect: (key: string) => void
  expanded: Set<string>
  onExpand: (key: string) => void
}

export const TreeNode: React.FC<Props> = (props) => {
  function onExpand() {
    if (props.node.children.length === 0) {
      return
    }
    props.onExpand(props.node.key)
  }
  function onSelect() {
    props.onSelect(props.node.key)
  }
  const expanded = props.expanded.has(props.node.key)

  return (
    <div className='my-1'>
      <span
        className={`whitespace-nowrap px-2 py-1 ${
          props.selected === props.node.key ? 'text-white bg-indigo-500 rounded-md' : ''
        }`}
      >
        <span
          className='mr-1 cursor-pointer w-[9px] inline-block text-right'
          onClick={onExpand}
        >
          {!expanded && props.node.children.length > 0 && (
            <span>
              <i className='fas fa-caret-right' />
            </span>
          )}
          {expanded && props.node.children.length > 0 && (
            <span>
              <i className='fas fa-caret-down' />
            </span>
          )}
        </span>
        <span
          className={
            props.selected === props.node.key
              ? 'text-white mr-1 cursor-pointer'
              : `${props.node.color || 'text-yellow-500'} mr-1 cursor-pointer`
          }
          onClick={onExpand}
        >
          <i className={props.node.icon || 'fas fa-folder'} />
        </span>
        <span
          className={
            props.selected === props.node.key
              ? 'cursor-pointer'
              : 'hover:text-black hover:underline cursor-pointer'
          }
          onClick={onSelect}
        >
          {props.node.title || '(leer)'}
        </span>
      </span>
      {expanded && (
        <div className='pl-3'>
          {props.node.children.map((child) => (
            <TreeNode
              key={child.key}
              node={child}
              selected={props.selected}
              onSelect={props.onSelect}
              expanded={props.expanded}
              onExpand={props.onExpand}
            />
          ))}
        </div>
      )}
    </div>
  )
}
