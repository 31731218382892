// converts a value to a formatted string
export function formatDecimal(
  value: string | null,
  postfix?: string,
  noThousandsSeparators: boolean = false,
): string {
  if (value === null) {
    return ''
  }
  let str = value

  // replace decimal character
  str = str.replace('.', ',')

  // remove minus
  const negative = str.startsWith('-')
  if (negative) {
    str = str.substr(1)
  }

  // add thousands separators
  if (!noThousandsSeparators) {
    let decimalPointIndex = str.indexOf(',')
    if (decimalPointIndex === -1) {
      decimalPointIndex = str.length
    }
    if (decimalPointIndex > 3) {
      let temp: string[] = []
      let i
      let end
      for (i = decimalPointIndex - 3; i > -3; i -= 3) {
        const start = i < 0 ? 0 : i
        temp.unshift(str.substring(start, end))
        end = start
      }
      if (temp.length) {
        str = temp.join('.')
      }
    }
  }

  // add minus
  if (negative) {
    str = `-${str}`
  }

  // add postfix
  if (postfix) {
    str += ` ${postfix}`
  }

  return str
}
