import * as React from 'react'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import { AppContext } from 'services/connection/models/AppContext'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { action, makeObservable } from 'mobx'
import { Link } from 'react-router-dom'

interface Props {
  residents: UnsyncedCollection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  selected: Map<string, IResidentSearchResult>
}

@observer
export class SearchResultList extends React.Component<Props, {}> {
  static contextType = AppContext
  private checked: boolean = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private searchResultMapper = (r: IResidentSearchResult) => {
    const thumbnail = getResidentImageSrc(
      this.context.instance.id,
      r.imageId,
      r.sex,
      'thumbnail',
    )
    return (
      <tr key={r.id}>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-16'>
          <div className='text-gray-900'>
            <input
              type='checkbox'
              className='ml-3 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
              checked={!!this.props.selected.get(r.id)}
              onChange={() => this.selectResident(r)}
            />
          </div>
        </td>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
          <div className='flex items-center'>
            <div className='h-10 w-10 flex-shrink-0'>
              <img className='h-10 w-10 rounded-full' src={thumbnail} alt='' />
            </div>
            <div className='ml-4 font-medium text-blue-500 underline'>
              <Link
                to={`/residents/${toJbpId(+r.id).toLowerCase()}/overview`}
                target='_blank'
              >{`${r.lastName.toUpperCase()}, ${r.firstName}`}</Link>
            </div>
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <div className='text-gray-900'>{toJbpId(+r.id)}</div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <div className='text-gray-900'>{r.data.nationality}</div>
        </td>
      </tr>
    )
  }

  @action
  private selectResident = (r: IResidentSearchResult) => {
    if (this.props.selected.has(r.id)) {
      this.props.selected.delete(r.id)
    } else {
      this.props.selected.set(r.id, r)
    }
  }

  @action
  private selectAll = () => {
    if (!this.checked) {
      this.props.residents.resources?.forEach((r) => {
        this.props.selected.set(r.id, r)
      })
    } else {
      this.props.residents.resources?.forEach((r) => {
        this.props.selected.delete(r.id)
      })
    }
    this.checked = !this.checked
  }

  render() {
    return (
      <div className='inline-flex flex-col w-full'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 relative flex items-center w-16'
                    >
                      <input
                        className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                        type='checkbox'
                        checked={this.checked}
                        onChange={this.selectAll}
                      />
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      ID
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Staatsangehörigkeit
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {(this.props.residents.resources?.length === 0 ||
                    !this.props.residents.resources) && (
                    <tr>
                      <td className='w-16'></td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        <div>Keine Suchergebnisse</div>
                      </td>
                    </tr>
                  )}
                  {this.props.residents.resources &&
                    this.props.residents.resources.map(this.searchResultMapper)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
