import { Collection, hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { classNames } from 'helpers/classNames'
import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { MultiValue, StylesConfig } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Model } from '../../Model'
import { InputSelectOption } from '../InputSelect'
import styles from './styles.module.scss'

export type InputMultiSelectOption = { id: string; label: string }

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any> // model.values[name] can be a string "1,4,2" or an array ["1", "4", "7"]. If it is an array, a new array is created on every change (immutablejs)
  options: string // api route for dynamic fetching. Route index must support filters "searchString" (user input) and ids="1,2,8" (fetch labels for selected options)
  optionParams?: string // more route query params, e.g. "type=building&buildingId=1"
  initParams?: { readOnly: boolean }
  getDisplayString?: (option: any) => string // Convert option to display string, default: use option.label
  label?: string
  className?: string
  placeholder?: string
  children?: Element
  // If onCreate is passed here and the label has no exact match in the suggestions (case insensitive)
  // -> an option "xxx erstellen..." is appended to the end of the list. If this option is selected,
  // onCreate is called with the label as argument. On create can display a modal or whatever and
  // create the option. If onCreate returns a string, this string is used as the id of the newly
  // created option. This string will be added to model.values[name].
  onCreate?: (label: string) => Promise<string | null>
}

interface IOption {
  value: string
  label: string
}

const colourStyles: StylesConfig<any> = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    minHeight: '36px',
    backgroundColor: state.isSelected ? '#6366f1' : state.isFocused ? '#e0e7ff' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: state.isSelected ? undefined : '#e0e7ff',
    },
  }),
  control: (styles, options) => ({
    ...styles,
    'input:focus': {
      boxShadow: 'none',
    },
    borderRadius: '0.375rem',
    cursor: 'text',
    boxShadow: options.isFocused
      ? '0 0 0 1px rgb(99, 102, 241)'
      : '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontSize: '14px',
    border: options.isFocused
      ? '1px solid rgb(99, 102, 241) !important'
      : '1px solid rgb(212, 212, 212) !important',
    color: '#000000',
  }),
  multiValue: (provided) => {
    return { ...provided, lineHeight: '22px', borderRadius: '4px' }
  },
}

const DropdownIndicator = () => {
  return <div className={styles.dropdownIndicator} />
}

@observer
export class InputAsyncMultiSelect extends React.Component<Props, {}> {
  private readonly id: string
  readonly options: Collection<any, { searchString: string; ids: string }>
  readonly filter: { ids: string }
  readonly disposers: Disposer[] = []

  private get value(): IOption[] {
    const ids: string[] =
      typeof this.props.model.values[this.props.name] === 'string'
        ? this.props.model.values[this.props.name].split(',').filter(Boolean)
        : this.props.model.values[this.props.name]
    const selected: IOption[] = []
    for (const id of ids) {
      const option = this.options.resources?.find((r) => r.id === id)?.data
      if (option) {
        selected.push({
          value: option.id,
          label: this.props.getDisplayString
            ? this.props.getDisplayString(option)
            : option.label,
        })
      } else {
        selected.push({
          value: id,
          label: id,
        })
      }
    }
    return selected
  }

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('input-')
    this.filter = observable({
      ids:
        typeof props.model.values[props.name] === 'string'
          ? props.model.values[props.name]
          : props.model.values[props.name].join(','),
    })
    this.options = new Collection(this.props.options, this.filter)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(
      this.options.init({ ...this.props.initParams, observeQuery: true }),
    )
    this.disposers.push(
      reaction(
        () => this.props.model.values[this.props.name],
        (value) =>
          (this.filter.ids = typeof value === 'string' ? value : value.join(',')),
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadOptions = async (inputValue: string): Promise<InputSelectOption[]> => {
    try {
      const label = inputValue.trim().substring(0, 255)
      const values = await hermes.indexOnceNew<any>(
        `${this.props.options}?${
          this.props.optionParams ? `${this.props.optionParams}&` : ''
        }searchString=${encodeURIComponent(label)}`,
      )
      const options = values.map((u) => ({
        value: u.id,
        label: u.label,
      }))
      if (
        this.props.onCreate &&
        !options.find((o) => o.label.toLowerCase() === label.toLowerCase())
      ) {
        options.push({ value: 'new', label: `"${label}" erstellen...` })
      }
      return options
    } catch (_e) {
      return []
    }
  }

  @action
  private onChange = (selected: MultiValue<IOption>) => {
    if (typeof this.props.model.values[this.props.name] === 'string') {
      this.props.model.values[this.props.name] = selected
        .map((o) => o.value)
        .filter((o) => o !== 'new')
        .join(',')
    } else {
      this.props.model.values[this.props.name] = selected
        .map((o) => o.value)
        .filter((o) => o !== 'new')
    }
    const newItem = selected.find((o) => o.value === 'new')
    if (this.props.onCreate && newItem) {
      this.props.onCreate(newItem.label.substring(1, newItem.label.length - 14)).then(
        action((id: string | null) => {
          if (!id) {
            return
          }
          if (typeof this.props.model.values[this.props.name] === 'string') {
            const ids = this.props.model.values[this.props.name]
              .split(',')
              .filter((o) => o !== id)
            ids.push(id)
            this.props.model.values[this.props.name] = ids.join(',')
          } else {
            const ids = this.props.model.values[this.props.name].filter((o) => o !== id)
            ids.push(id)
            this.props.model.values[this.props.name] = ids
          }
        }),
      )
    }
  }

  render() {
    const { name, label, className, placeholder, disabled } = this.props
    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <AsyncSelect
          id={this.id}
          isMulti
          value={this.value}
          onChange={this.onChange}
          name={name}
          styles={colourStyles}
          isClearable={false}
          components={{ DropdownIndicator }}
          noOptionsMessage={() => 'Keine Ergebnisse'}
          loadOptions={this.loadOptions}
          placeholder={placeholder || ''}
          isDisabled={disabled}
        />
      </div>
    )
  }
}
