import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { IInstance } from 'contracts/users/interfaces/IInstance'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'
import * as React from 'react'

interface Props {
  item: IInventoryItem
  instance: IInstance
  onClick: (item: IInventoryItem) => void
}

export const ResidentInventoryScanItem: React.FC<Props> = ({
  item,
  instance,
  onClick,
}) => (
  <tr
    className='last:border-0 border-b border-gray-300 rounded-lg hover:bg-gray-300 hover:cursor-pointer'
    onClick={() => onClick(item)}
  >
    <td className='py-2 pl-4 pr-3 hidden md:block md:pl-0'>
      <div className='pl-2'>
        <img
          className='h-10 w-10 min-w-[2.5rem] rounded-full'
          src={getInventoryImageSrc(item.imageId, instance.id)}
          alt=''
        />
      </div>
    </td>
    <td className='py-2 pl-4 pr-3 text-sm sm:pl-3 md:pl-0'>
      <div className='font-medium text-gray-900 lg:max-w-[155px] md:max-w-[115px] sm:max-w-[160px] truncate'>
        {item.label}
      </div>
    </td>
    <td className='py-2 pl-4 pr-3 text-sm sm:pl-3 md:pl-0'>
      <div className='mt-0.5 text-gray-900 text-right'>{item.count}</div>
    </td>
  </tr>
)
