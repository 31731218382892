import * as React from 'react'
import { observer } from 'mobx-react'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { action, makeObservable } from 'mobx'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { classNames } from 'helpers/classNames'

interface Props {
  selected: Map<string | null, boolean>
  building: IBuilding
}

@observer
export class BuildingSelect extends React.Component<Props, {}> {
  static contextType = AppContext

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private changeHandler = (e, only?: boolean) => {
    if (only) {
      // nur
      e.stopPropagation()
      e.preventDefault()
      this.props.selected.clear()
      this.props.selected.set(this.props.building.id, true)
      return
    }

    if (this.props.selected.get(this.props.building.id)) {
      this.props.selected.delete(this.props.building.id)
    } else {
      this.props.selected.set(this.props.building.id, true)
    }
  }

  render() {
    const compound: ICompound | null = hermes.getFromStore(
      `/api/${this.context.instance.id}/accommodations/compounds/${this.props.building.compoundId}`,
      false,
    )
    if (!compound) {
      return null
    }
    return (
      <label
        className={classNames(
          this.props.selected.has(this.props.building.id)
            ? 'bg-gray-100 text-gray-900 border-indigo-500'
            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 border-transparent',
          'group border-2 relative flex items-center px-2 py-2 text-sm font-medium rounded-md truncate cursor-pointer',
        )}
      >
        <input
          className='h-4 w-4 text-indigo-600 focus:ring-transparent border-gray-300 rounded'
          type='checkbox'
          checked={!!this.props.selected.get(this.props.building.id)}
          onChange={this.changeHandler}
        />
        <span
          className='ml-3 hidden group-hover:inline-block text-blue-500 hover:underline'
          onClick={(e) => this.changeHandler(e, true)}
        >
          nur
        </span>
        <span className='ml-3 group-hover:ml-1'>
          {compound.label === this.props.building.label
            ? compound.label
            : `${compound.label} / ${this.props.building.label}`}
        </span>
      </label>
    )
  }
}
