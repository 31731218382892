import { hermes } from '@byll/hermes'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { templates } from 'modules/Pdf/templates/ResidentDocumentTemplates/templates'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  available: Set<string>
  currentKey: string
}

@observer
export class DocumentTemplateCheckbox extends React.Component<Props, {}> {
  static contextType = AppContext

  private changeHandler = async () => {
    try {
      if (!this.props.available?.has(this.props.currentKey)) {
        const data = {
          component: this.props.currentKey,
          type: templates.get(this.props.currentKey)?.type,
        }
        await hermes.create(`/api/${this.context.instance.id}/documents/templates`, data)
        runInAction(() => this.props.available?.add(this.props.currentKey))
      } else {
        await hermes.delete(
          `/api/${this.context.instance.id}/documents/templates/${this.props.currentKey}`,
        )
        this.props.available.delete(this.props.currentKey)
      }
    } catch (_e) {
      box.alert(
        'Fehler',
        'Beim Hinzufügen/Entfernen des Templates ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal oder kontaktieren Sie den Systemadministrator.',
      )
    }
  }

  render() {
    return (
      <div>
        <input
          className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
          type='checkbox'
          checked={this.props.available?.has(this.props.currentKey)}
          onChange={this.changeHandler}
        />
        <span className='ml-3 text-gray-600'>
          {templates.get(this.props.currentKey)?.label}
        </span>
      </div>
    )
  }
}
