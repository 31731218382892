import { Model } from 'components/Form/Model'
import { IRoomLock } from 'contracts/accommodations/interfaces/IRoomLock'
import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { z } from 'zod'
import { XIcon } from '@heroicons/react/outline'
import { InputText } from 'components/Form/components/InputText'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { getRoomLockReasons } from 'contracts/accommodations/helpers/getRoomLockReasons'
import { runInAction } from 'mobx'

interface Props {
  onClose: (lock: IRoomLock | null) => void
  reason?: string
}

export class RoomLockAlert extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ reason: string; notes: string }>
  private readonly reasonOptions: InputSelectOption[]

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model(
      { reason: props.reason || '', notes: '' },
      z.object({ reason: z.string().min(1) }),
    )
    this.reasonOptions = getRoomLockReasons(context.instance.id, context.permissions).map(
      (r) => ({
        value: r,
        label: r,
      }),
    )
    this.reasonOptions.unshift({ value: '', label: 'Bitte wählen...' })
  }

  private close = () => {
    runInAction(() => (this.model.values.notes = this.model.values.notes.trim()))
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    this.props.onClose({
      beginAt: new Date().toISOString(),
      beginBy: '',
      endAt: null,
      endBy: '',
      reason: this.model.values.reason,
      notes: this.model.values.notes,
    })
  }

  render() {
    return (
      <>
        <div className='block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose(null)}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='flex items-start'>
          <div className='mt-3 mt-0 text-left flex-auto'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Raum sperren
            </Dialog.Title>
            <div className='mt-6 text-sm text-gray-500' id={this.model.id}>
              <InputSelect
                name='reason'
                model={this.model}
                options={this.reasonOptions}
                label='Grund'
              />
              <InputText
                className='mt-4'
                name='notes'
                model={this.model}
                label='Notizen'
                placeholder='Optional'
              />
            </div>
          </div>
        </div>
        <div className='mt-5 mt-4 flex flex-row-reverse'>
          <button
            type='button'
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ml-3 w-auto text-sm bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500`}
            onClick={this.close}
          >
            Sperren
          </button>
          <button
            type='button'
            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-0 w-auto text-sm'
            onClick={() => this.props.onClose(null)}
          >
            Abbrechen
          </button>
        </div>
      </>
    )
  }
}
