const dayjs = require('dayjs')

export const isoDateErrorMessage = { message: 'Not a valid ISO string date' }

export function isISODate(date: string): boolean {
  if (typeof date !== 'string') {
    return false
  }
  const day = dayjs(date)
  if (!day.isValid()) {
    return false
  }
  return day.toISOString() === date
}
