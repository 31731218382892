const lfgbDefaultResidentTodos = [
  'TBC Untersuchung',
  'Masern Untersuchung',
  'Masern-Impfung 1',
  'Masern-Impfung 2',
  'Polizeiliche Meldung',
  'Erstausstattung erhalten',
  'Ausweis erhalten',
  // 'Kaution Schlüsselpfand',
  // 'Schlüsselpfand Betrag',
  'Datenschutz Unterschrift',
  'Hausordnung Unterschrift',
]

const brkDefaultResidentTodos = [
  'TBC Untersuchung',
  'Masern / Röteln / Mumps',
  'Covid 1',
  'Covid 2',
  'Covid 3',
  'Covid 4',
  'Tetanus',
]

const appDefaultTodos = ['Gesundheitsuntersuchung']

export function getDefaultResidentTodos(instanceId: string) {
  switch (instanceId) {
    case '0':
    case '1': // app.inuv.de
    case '2': // testapp.inuv.de
      return appDefaultTodos
    case '7': // inuv.lfg-b.local
    case '8': // testinuv.lfg-b.local
      return lfgbDefaultResidentTodos
    case '10': // brk.inuv.de
      return brkDefaultResidentTodos
    default:
      return []
  }
}
