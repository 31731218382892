import * as React from 'react'
import { Link } from 'react-router-dom'
import background from './sky.jpeg'

interface Props {}

export const NotFound: React.FC<Props> = () => (
  <>
    <main
      className='min-h-full bg-cover bg-top sm:bg-top pt-14'
      style={{
        backgroundImage: `url("${background}")`,
      }}
    >
      <div className='max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48'>
        <p className='text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide'>
          404 error
        </p>
        <h1 className='mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl'>
          Oh! Sie haben sich verlaufen.
        </h1>
        <p className='mt-2 text-lg font-medium text-black text-opacity-50'>
          Die Seite, nach der Sie suchen, gibt es nicht.
        </p>
        <div className='mt-6'>
          <Link
            to='/'
            className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50'
          >
            Zurück zur Übersicht
          </Link>
        </div>
      </div>
    </main>
  </>
)
