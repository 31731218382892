import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputCompound } from 'components/Form/components/InputCompound'
import { Model } from 'components/Form/Model'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { z } from 'zod'
import { isStammCompound } from 'helpers/isStamm'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { InputMonth } from 'components/Form/components/InputMonth'
import { observer } from 'mobx-react'

interface Props {}

@observer
export class Hygienemittelstatistik extends React.Component<Props, { loading: boolean }> {
  static contextType = AppContext
  private readonly model: Model<{ compoundId: string | null; month: string }>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.state = { loading: false }
    const validator = z.object({ compoundId: z.string(), month: z.string() })
    const begin = dayjs().startOf('month')
    this.model = new Model(
      {
        compoundId: context.defaults.responsibilityCompoundId,
        month: begin.format('YYYY-MM'),
      },
      validator,
    )
    if (
      context.permissions.report_hygienemittelstatistik === 1 &&
      !isStammCompound(this.model.values.compoundId || '')
    ) {
      this.model.values.compoundId = null
    }
  }

  private downloadReport = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    this.setState({ loading: true })
    await createAndDownloadReport(
      'hygienemittelstatistik',
      this.context.instance.id,
      { compoundId: this.model.values.compoundId, month: this.model.values.month },
      'Hygienemittelstatistik.xlsx',
    )
    this.setState({ loading: false })
  }

  render() {
    if (!this.context.permissions.report_hygienemittelstatistik) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Hygienemittelstatistik'
        subtitle='Wählen Sie ein Gelände und einen Monat aus, für das die Hygienemittelstatistik erstellt werden soll.'
      >
        <div id={this.model.id} className='flex gap-2 my-3'>
          <InputCompound
            label='Gelände'
            name='compoundId'
            model={this.model}
            onlyStamm={this.context.permissions.report_hygienemittelstatistik === 1}
            saveResponsibility
            at={dayjs(this.model.values.month, 'YYYY-MM').toISOString()}
          />
          <InputMonth name='month' model={this.model} />
        </div>
        <Button
          className='my-2'
          onClick={this.downloadReport}
          loading={this.state.loading}
        >
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
