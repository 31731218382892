import { hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import {
  InputButtonGroup,
  InputButtonGroupOption,
} from 'components/Form/components/InputButtonGroup'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputDate } from 'components/Form/components/InputDate'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Message } from 'components/Message'
import { IMigration } from 'contracts/migrations/interfaces/IMigration'
import { residentFields } from 'contracts/residents/constants/residentFields'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  migration: IMigration
}

const accommodationOptions: InputButtonGroupOption[] = [
  { value: '', label: '-' },
  { value: 'internal residence', label: 'Einbuchen' },
  { value: 'planned transfer', label: 'Transfer planen' },
]

@observer
export class MigrationColumnMapping extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ [key: string]: string }>
  private readonly accommodationModel: Model<IMigration['accommodation']>
  private readonly familyModel: Model<IMigration['family']>
  private readonly residentFieldsSelectOptions: InputSelectOption[]
  private roomColOptions: InputSelectOption[] = []

  constructor(props: Props) {
    super(props)

    // Field mappings
    this.residentFieldsSelectOptions = residentFields.map((f) => ({
      label: f.label,
      value: f.key,
    }))
    this.residentFieldsSelectOptions.sort((a, b) =>
      a.label?.toLowerCase() < b.label?.toLowerCase() ? -1 : 1,
    )
    this.residentFieldsSelectOptions.unshift({ label: 'Bitte wählen...', value: '' })

    // Accommodation options
    this.roomColOptions =
      this.props.migration.columns.map((c) => ({
        value: Object.keys(c)[0],
        label: Object.keys(c)[0],
      })) || []
    this.roomColOptions.sort((a, b) =>
      a.label?.toLowerCase() < b.label?.toLowerCase() ? -1 : 1,
    )
    this.roomColOptions.unshift({ label: 'Bitte wählen...', value: '' })
    this.accommodationModel = new Model(this.props.migration.accommodation)
    this.familyModel = new Model(this.props.migration.family)

    // Column options
    const resultObject: { [key: string]: string } = this.props.migration.columns.reduce(
      (acc, obj) => {
        Object.keys(obj).forEach((key) => {
          acc[key] = obj[key]
        })
        return acc
      },
    )
    this.model = new Model(resultObject)
  }

  private setAllocations = async () => {
    if (!this.model || !this.accommodationModel || !this.familyModel) {
      return
    }
    await hermes.patch(
      `/api/${this.context.instance.id}/imports/${this.props.migration.id}`,
      {
        columns: this.model.values,
        accommodation: this.accommodationModel.values,
        family: this.familyModel.values,
      },
    )
  }

  render() {
    return (
      <>
        {/* Column mapping */}
        <div className='bg-white shadow sm:rounded-lg p-4 text-gray-600'>
          <div className='mb-4'>
            <Message color='primary'>
              Weisen Sie den Spalten die entsprechenden Werte zu.
            </Message>
          </div>
          <ol className='list-inside gap-4'>
            {this.props.migration.columns.map((c, i) => {
              const keys = Object.keys(c)
              return (
                <div className='list-item mb-4' key={i}>
                  {keys.map((k, i) => {
                    return (
                      <div key={i}>
                        <span className='text-xs text-gray-500'>{k}&nbsp;</span>
                        <InputSelect
                          model={this.model as any}
                          name={k}
                          options={this.residentFieldsSelectOptions}
                          onChange={this.setAllocations}
                          disabled={this.props.migration.status !== 'Entwurf'}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </ol>
        </div>

        {/* Family options */}
        <div className='bg-white shadow sm:rounded-lg p-4 text-gray-600 mt-6'>
          <div className='mb-4'>
            <Message color='primary'>
              Aufeinanderfolgende Einträge mit gleichem Wert werden derselben Familie
              zugeordnet. Leere Zellen werden immer einer neuen Familie zugeordnet.
            </Message>
          </div>

          <div className='mb-4'>
            <span className='text-xs text-gray-500'>Familienverbund</span>
            <InputSelect
              model={this.familyModel}
              name='hvColumn'
              options={this.roomColOptions}
              onChange={this.setAllocations}
              disabled={this.props.migration.status !== 'Entwurf'}
            />
          </div>
        </div>

        {/* Accommodation options */}
        <div className='bg-white shadow sm:rounded-lg p-4 text-gray-600 mt-6'>
          <div className='mb-4'>
            <Message color='primary'>
              Hier können Sie festlegen, in welcher Unterkunft die Bewohner eingebucht
              oder als Transfer geplant werden sollen.
            </Message>
          </div>

          <div className='mb-4'>
            <InputButtonGroup
              model={this.accommodationModel}
              name='target'
              disabled={this.props.migration.status !== 'Entwurf'}
              options={accommodationOptions}
              onChange={this.setAllocations}
            />
          </div>

          {this.accommodationModel.values.target !== '' && (
            <div className='mb-4'>
              <span className='text-xs text-gray-500'>
                {this.accommodationModel.values.target === 'internal residence'
                  ? 'Gelände'
                  : 'Zielgelände'}
              </span>
              <InputCompound
                model={this.accommodationModel}
                name='compoundId'
                disabled={this.props.migration.status !== 'Entwurf'}
              />
            </div>
          )}

          {this.accommodationModel.values.target !== '' && (
            <div className='mb-4'>
              <span className='text-xs text-gray-500'>
                {this.accommodationModel.values.target === 'internal residence'
                  ? 'Gebäude'
                  : 'Zielgebäude'}
              </span>
              <InputBuilding
                compoundId={this.accommodationModel.values.compoundId}
                model={this.accommodationModel}
                name='buildingId'
                onChange={this.setAllocations}
                disabled={this.props.migration.status !== 'Entwurf'}
              />
            </div>
          )}

          {this.accommodationModel.values.target === 'internal residence' && (
            <div className='mb-4'>
              <span className='text-xs text-gray-500'>Raum</span>
              <InputSelect
                model={this.accommodationModel}
                name='roomCol'
                options={this.roomColOptions}
                onChange={this.setAllocations}
                disabled={this.props.migration.status !== 'Entwurf'}
              />
            </div>
          )}

          {this.accommodationModel.values.target === 'internal residence' && (
            <div className='mb-4'>
              <span className='text-xs text-gray-500'>Aufenthalt Start</span>
              <InputSelect
                model={this.accommodationModel}
                name='checkInCol'
                options={this.roomColOptions}
                onChange={this.setAllocations}
                disabled={this.props.migration.status !== 'Entwurf'}
              />
            </div>
          )}
          {this.accommodationModel.values.target === 'planned transfer' && (
            <div className='mb-4'>
              <span className='text-xs text-gray-500'>Transferdatum</span>
              <InputDate
                model={this.accommodationModel}
                name='startDate'
                onChange={this.setAllocations}
                disabled={this.props.migration.status !== 'Entwurf'}
              />
            </div>
          )}
        </div>
      </>
    )
  }
}
