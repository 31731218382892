import { Model } from 'components/Form/Model'
import { PaperInputDecimal } from 'components/PaperInputs/PaperInputDecimal'
import { PaperInputText } from 'components/PaperInputs/PaperInputText'
import { RoundIcon } from 'components/RoundIcon'
import { ICostCoverageInvoicePosition } from 'contracts/costCoverages/interfaces/ICostCoverageInvoicePosition'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  position: ICostCoverageInvoicePosition
  onDelete: (position: ICostCoverageInvoicePosition) => void
}

@observer
export class CostCoverageInvoicePosition extends React.Component<Props, {}> {
  private readonly model: Model<ICostCoverageInvoicePosition>
  constructor(props: Props) {
    super(props)
    this.model = new Model(props.position)
  }

  render() {
    return (
      <>
        <PaperInputText
          className='col-span-4'
          name='label'
          model={this.model}
          readOnly={false}
          style={{ fontSize: '12px', paddingLeft: 0 }}
        />
        <div className='max-w-[80px] relative'>
          <PaperInputDecimal
            postfix='€'
            precision={8}
            scale={2}
            name='amount'
            model={this.model}
            readOnly={false}
            style={{ fontSize: '12px', paddingRight: 0 }}
          />
          <RoundIcon
            onClick={() => this.props.onDelete(this.props.position)}
            tooltip={{ text: 'Rechnungsposition löschen', position: 'top' }}
            style={{ position: 'absolute', right: -44, top: -1, fontSize: '16px' }}
            icon='fas fa-trash'
            color='danger'
          />
        </div>
      </>
    )
  }
}
