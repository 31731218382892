import * as React from 'react'
import { DialogOverlayArea } from './components/DialogOverlayArea'
import styles from './styles.module.scss'
import { Spinner } from '../../../Spinner'
import { getNextFocusableDivParent } from '../../../../helpers/getNextFocusableDivParent'

interface Props {
  className?: string
  opaque?: boolean
  sticky?: boolean
  center?: boolean
}

export class DialogOverlaySpinner extends React.Component<Props, {}> {
  private ref: HTMLDivElement | null = null
  private getRef = (ref) => (this.ref = ref)

  componentDidMount(): void {
    getNextFocusableDivParent(this.ref?.parentElement)?.focus()
    document.addEventListener('focus', this.onFocus, true)
  }

  componentWillUnmount(): void {
    document.removeEventListener('focus', this.onFocus, true)
  }

  private onFocus = (e) => {
    if (!this.ref?.parentElement || !e?.target) {
      return
    }
    // Prevent tabbing into this component's parent. This component should
    // overlay the target completely, so that the target cannot be edited.
    if (this.ref.parentElement.contains(e.target)) {
      getNextFocusableDivParent(this.ref.parentElement)?.focus()
    }
  }

  render() {
    let className = ''
    if (this.props.sticky) {
      className = styles.sticky
    } else if (this.props.center) {
      className = styles.center
    }
    return (
      <DialogOverlayArea
        className={this.props.className}
        opaque={this.props.opaque}
        getRef={this.getRef}
      >
        <Spinner className={className} />
        {this.props.children}
      </DialogOverlayArea>
    )
  }
}
