import * as React from 'react'
import { Collection } from '@byll/hermes'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { TodoOverlayList } from 'modules/Todo/components/TodoOverlay/components/TodoOverlayList'
import { DashboardTodosFilter } from './components/DashboardTodosFilter'
import styles from './styles.module.scss'
import { RoundIcon } from 'components/RoundIcon'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { TicketAccommodationFilter } from '../DashboardTickets/components/filters/TicketAccommodationFilter'
import { TicketUserFilter } from '../DashboardTickets/components/filters/TicketUserFilter'
import { TicketProgressFilter } from '../DashboardTickets/components/filters/TicketProgressFilter'
import { TicketSearch } from 'components/TicketSearch'
import { useNavigate } from 'react-router'
import { isJbpInstance } from 'contracts/general/helpers/instanceIds'

interface Props {
  navigate: (url: string) => void
}

const emptyDict = {
  due: 'Alles erledigt',
  scheduled: 'Nichts geplant',
}

@observer
export class DashboardTodos extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<ITodoSearchResultFilter>
  private readonly todos: Collection<
    ITodoSearchResult,
    ITodoSearchResultFilter,
    ITodoSearchResultFilter
  >
  private readonly tickets: Collection<
    ITodoSearchResult,
    ITodoSearchResultFilter,
    ITodoSearchResultFilter
  >
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      page: '0,10',
      status: 'due',
      link: 'assignee',
      listId: '',
      buildingId: null,
      userId: null,
    })
    this.todos = new Collection(
      `/api/${context.instance.id}/todoLists/user-${context.user.id}/todos`,
      this.model.values,
    )
    this.tickets = new Collection(
      `/api/${context.instance.id}/todoLists/${context.defaults.ticketTodoListId}/todos`,
      this.model.values,
    )
  }

  componentDidMount() {
    this.disposers.push(this.todos.init({ readOnly: true, observeQuery: true }))
    if (this.context.permissions.dashboard_tickets > 1) {
      this.disposers.push(this.tickets.init({ observeQuery: true }))
    }
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private downloadReport = async () => {
    await createAndDownloadReport(
      'tickets-report',
      this.context.instance.id,
      {
        ticketsListId: this.context.defaults.ticketTodoListId,
        actionsListId: this.context.defaults.actionTodoListId,
      },
      'Ticketreport.xlsx',
    )
  }

  render() {
    const showExtendedFilter = isJbpInstance(this.context.instance.id)
    const isEmpty =
      !!this.todos.resources &&
      this.todos.resources.length === 0 &&
      this.todos.metadata?.status === this.todos.query.status
    return (
      <div
        className={`${
          isEmpty && this.model.values.listId !== this.context.defaults.ticketTodoListId
            ? styles.bg
            : ''
        } bg-white rounded-md shadow-md overflow-hidden col-span-1 md:col-span-2 min-h-[420px] relative`}
      >
        <div className='absolute top-0 left-0 right-0 bottom-0 overflow-x-hidden overflow-y-auto'>
          <div className='sticky top-0 z-10 bg-white px-4 pt-4'>
            <div className='font-bold text-xl'>Meine Aufgaben</div>
            <DashboardTodosFilter model={this.model} />
            {showExtendedFilter && (
              <div className='flex flex-wrap border-t border-b border-gray-200 bg-gray-100 py-2 px-4 -mx-4 mb-4'>
                <TicketAccommodationFilter model={this.model} />
                <TicketUserFilter model={this.model} />
                <TicketProgressFilter model={this.model} />
              </div>
            )}
            {showExtendedFilter && this.context.permissions.dashboard_tickets > 1 && (
              <div className='absolute top-5 right-5 flex items-center'>
                {this.context.permissions.dashboard_tickets === 3 && (
                  <RenderTicketSearch />
                )}
                <RoundIcon
                  onClick={this.downloadReport}
                  icon='fa fa-download'
                  tooltip={{ position: 'left', text: 'Ticketreport herunterladen' }}
                />
              </div>
            )}
          </div>

          <div className='relative px-4 pb-4'>
            <TodoOverlayList
              navigate={this.props.navigate}
              todos={
                this.model.values.listId === this.context.defaults.ticketTodoListId
                  ? this.tickets
                  : (this.todos as any)
              }
              openInNewTab
            />
            {isEmpty &&
              this.model.values.listId !== this.context.defaults.ticketTodoListId && (
                <div className='flex'>
                  <div className='text-teal-700 text-center mt-12 border-2 border-teal-900 rounded-md p-4 flex-content mx-auto'>
                    {emptyDict[this.todos.metadata?.status || ''] || 'Keine Aufgaben'}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    )
  }
}

const RenderTicketSearch: React.FC<{}> = observer(() => {
  const navigate = useNavigate()

  return (
    <TicketSearch
      className='mr-2 min-w-[150px] max-w-[300px]'
      placeholder='Ticket suchen'
      navigate={navigate}
    />
  )
})
