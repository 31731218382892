import { hermes } from '@byll/hermes'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputDate } from 'components/Form/components/InputDate'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { IResidentDocumentationSearchResult } from 'contracts/residents/interfaces/IResidentDocumentationSearchResult'
import { IRole } from 'contracts/users/interfaces/IRole'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { CATEGORY_KU_ABRECHNUNG, documentationTopicOptions } from '../../..'
import { DocumentationRoleIdDropdown } from './DocumentationRoleIdDropdown'

const typeOptions: InputSelectOption[] = [
  { value: '', label: 'Bitte wählen' },
  { value: 'Beobachtung', label: 'Beobachtung' },
  { value: 'Beratung', label: 'Beratung' },
  { value: 'ohne Bewohnerkontakt', label: 'ohne Bewohnerkontakt' },
]

interface Props {
  model: Model<IResidentDocumentationSearchResult>
  readOnly?: boolean
}

@observer
export class DocumentationDialogTags extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private roles: InputMultiSelectOption[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.loadRoles()
  }

  private loadRoles = async () => {
    const roles = await hermes.indexOnceNew<IRole>(
      `/api/${this.context.instance.id}/users/roles?group=doku`,
    )
    runInAction(() => (this.roles = roles.map((r) => ({ id: r.id, label: r.label }))))
  }

  @action
  private onSelectTopic = (
    added: InputMultiSelectOption[],
    removed: InputMultiSelectOption[],
  ) => {
    if (
      this.context.permissions.host_lfgb &&
      added.find((a) => a.label === CATEGORY_KU_ABRECHNUNG)
    ) {
      // Add role RW
      const roleRw = this.roles?.find((r) => r.label === 'RW')
      if (roleRw && !this.props.model.values.roleIds.includes(roleRw.id)) {
        this.props.model.values.roleIds.push(roleRw.id)
        box.alert(
          'Sichtbarkeit',
          `Sie haben das Schlagwort "${CATEGORY_KU_ABRECHNUNG}" ausgewählt. Damit ist diese Dokumentation auch für das Rechnungswesen "RW" relevant. Inuv hat daher automatisch die Sichtbarkeit für "RW" hinzugefügt. Sollte das nicht gewünscht sein, können Sie diese Sichtbarkeit einfach wieder entfernen.`,
        )
      }
    }
  }

  render() {
    const model = this.props.model
    return (
      <div className='rounded-sm shadow bg-white flex'>
        <div className='flex-[0_0_268px] pt-11 pl-11 leading-7'>
          <div>Schlagwörter</div>
          <div className='text-gray-400 text-xs'>
            Kategorisierung und Freigabe
            <br />
            für andere Benutzergruppen
          </div>
        </div>
        <div className='flex-auto py-11 pr-6 grid gap-4 grid-cols-[100px_80px_140px_1fr]'>
          <InputDate
            disabled={this.props.readOnly}
            model={model}
            name='date'
            label='Datum'
          />
          <InputText
            disabled={this.props.readOnly}
            model={model}
            name='time'
            label='Uhrzeit'
            placeholder='HH:MM'
            maxLength={5}
          />
          <InputSelect
            disabled={this.props.readOnly}
            model={model}
            name='type'
            label='Tätigkeitsart'
            options={typeOptions}
          />
          <InputCompound
            disabled={this.props.readOnly}
            model={model}
            name='compoundId'
            label='Sichtbar in'
            at={model.values.createdAt}
            onlyStamm={this.context.permissions.resident_documentation === 1}
          />
          <DocumentationRoleIdDropdown
            disabled={this.props.readOnly}
            className='col-span-4'
            model={model}
            name='roleIds'
            label='Sichtbar für'
            placeholder='Freigabe auswählen...'
            roles={this.roles}
          />
          <InputMultiSelect
            disabled={this.props.readOnly}
            className='col-span-4'
            options={documentationTopicOptions}
            model={model}
            name='topics'
            label='Themen'
            placeholder='Themen auswählen...'
            onChange={this.onSelectTopic}
          />
        </div>
      </div>
    )
  }
}
