import * as React from 'react'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { Model } from 'components/Form/Model'
import { AppContext } from 'services/connection/models/AppContext'
import { CaseRecordFormLfgb } from './components/CaseRecordFormLfgb'
import { CaseRecordFormJbp } from './components/CaseRecordFormJbp'
import { CaseRecordFormAwum } from './components/CaseRecordFormAwum'

interface Props {
  model: Model<IResident>
  requiredFields?: any
  readOnly: boolean
}

export class CaseRecordForm extends React.Component<Props, {}> {
  static contextType = AppContext

  render() {
    // inuv.lfg-b.local (7) and testinuv.lfg-b.local (8)
    if (this.context.instance.id === '7' || this.context.instance.id === '8') {
      return (
        <CaseRecordFormLfgb
          model={this.props.model}
          requiredFields={this.props.requiredFields}
          readOnly={this.props.readOnly}
        >
          {this.props.children}
        </CaseRecordFormLfgb>
      )
    }

    // awum.inuv.de (5) and testawum.inuv.de (6)
    if (
      this.context.instance.id === '0' ||
      this.context.instance.id === '5' ||
      this.context.instance.id === '6'
    ) {
      return (
        <CaseRecordFormAwum
          model={this.props.model}
          requiredFields={this.props.requiredFields}
          readOnly={this.props.readOnly}
        >
          {this.props.children}
        </CaseRecordFormAwum>
      )
    }

    return (
      <CaseRecordFormJbp
        model={this.props.model}
        requiredFields={this.props.requiredFields}
        readOnly={this.props.readOnly}
      >
        {this.props.children}
      </CaseRecordFormJbp>
    )
  }
}
