import { z } from 'zod'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isTrimmedString } from '../../general/helpers/isTrimmedString'
import { isYmdDate } from '../../general/helpers/isYmdDate'

export const CostCoverageCreateValidator = z.object({
  documentId: z.string().uuid(),
  compoundId: z.string().refine(isIntegerString),
  payerId: z.string().refine(isIntegerString),
  issueDate: z.string().refine(isYmdDate),
  beginDate: z.string().refine(isYmdDate),
  endDate: z.string().refine(isYmdDate),
  factor: z.number().int().min(1).max(2),
  aktenzeichen: z.string().min(1).refine(isTrimmedString),
  betrag: z.union([z.string(), z.null()]).optional(),
  eigenanteil: z.union([z.string(), z.null()]).optional(),
  eigenanteilZyklus: z.union([z.enum(['month', 'day']), z.null()]).optional(),
  ansprechpartner: z.string().max(255),
  verpflegung: z.enum(['Vollverpflegung', 'Selbstverpflegung']),
  email: z.string().max(255),
  fax: z.string().max(255),
  telefon: z.string().max(255),
  notes: z.string(),
  isValid: z.boolean(),
  residents: z.array(
    z.object({
      checked: z.boolean(),
      contact: z.boolean(),
      id: z.string().refine(isIntegerString),
      sex: z.union([z.enum(['male', 'female', 'divers']), z.null()]),
      firstName: z.string(),
      lastName: z.string(),
      dateOfBirth: z.union([z.string(), z.null()]),
    }),
  ),
})
