import { DropzoneState } from 'react-dropzone'

export type DocumentDropzoneViewError =
  | 'FILE_TYPE_NOT_SUPPORTED'
  | 'FILE_SIZE_LIMIT_EXCEEDED'
  | 'MULTIPLE_FILES_NOT_SUPPORTED'
  | 'UNSPECIFIED_ERROR'
type ViewState =
  | 'WAITING_FOR_FILES'
  | 'DRAG_ACCEPT'
  | 'DRAG_REJECT'
  | DocumentDropzoneViewError

export function getDocumentDropzoneViewState(
  state: DropzoneState,
  error: DocumentDropzoneViewError | null,
): ViewState {
  if (state.isDragAccept) {
    return 'DRAG_ACCEPT'
  } else if (state.isDragReject) {
    return 'DRAG_REJECT'
  }

  return error || 'WAITING_FOR_FILES'
}
