import * as React from 'react'
import { InputImage } from 'components/Form/components/InputImage'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { IBlogpost } from 'contracts/dashboard/interfaces/IBlogpost'
import { observer } from 'mobx-react'
import { BlogpostValidator } from 'contracts/dashboard/validators/BlogpostValidator'
import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { AppContext } from 'services/connection/models/AppContext'
import { Message } from 'components/Message'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Tooltip } from 'components/Tooltip'
import { NodeHtmlMarkdown } from 'node-html-markdown'

interface Props {
  blogpost: Partial<IBlogpost>
  onClose: () => void
}

@observer
export class BlogpostForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IBlogpost>
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      {
        ...props.blogpost,
        text: props.blogpost.markdown
          ? NodeHtmlMarkdown.translate(props.blogpost.text!)
          : props.blogpost.text,
      },
      BlogpostValidator.omit({ id: true }),
    )
  }

  private saveBlogpost = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      if (!this.model.values.imageId) {
        runInAction(() => (this.error = 'Es wurde kein Bild hochgeladen'))
      }
      return
    }
    const data = {
      title: this.model.values.title,
      subtitle: this.model.values.subtitle,
      text: this.model.values.text,
      imageId: this.model.values.imageId,
      isActive: this.model.values.isActive,
      markdown: this.model.values.markdown,
    }
    if (!this.model.values.id) {
      await hermes.create(`/api/${this.context.instance.id}/blogposts`, data)
      this.props.onClose()
    } else {
      await hermes.patch(
        `/api/${this.context.instance.id}/blogposts/${this.props.blogpost.id}`,
        data,
      )
      this.props.onClose()
    }
  }

  @action
  private setIsActive = (isActive: boolean) => {
    this.model.values.isActive = isActive
  }

  @action
  private setIsMarkdown = (value: boolean) => {
    this.model.values.markdown = value
  }

  render() {
    const active = this.model.values.isActive
    return (
      <div id={this.model.id}>
        <h3>
          {!this.props.blogpost.id
            ? 'Neuen Blogartikel anlegen'
            : 'Blogartikel bearbeiten'}
        </h3>
        {this.error && (
          <Message className='my-2' color='danger'>
            {this.error}
          </Message>
        )}
        <div className='mt-4'>
          <div>
            <Button
              className='has-tooltip border-r border-transparent hover:border-indigo-700'
              onClick={() => this.setIsActive(true)}
              color={active ? 'primary' : 'secondary'}
              outline={!active}
              style={{ borderRadius: '6px 0 0 6px' }}
            >
              <i className='fa fa-eye' />
              <Tooltip position='top'>Blogpost aktiv setzen</Tooltip>
            </Button>
            <Button
              className='has-tooltip border-r border-transparent hover:border-indigo-700'
              onClick={() => this.setIsActive(false)}
              color={active ? 'secondary' : 'primary'}
              outline={active}
              style={{ borderRadius: '0 6px 6px 0' }}
            >
              <Tooltip position='top'>Blogpost inaktiv setzen</Tooltip>
              <i className='fa fa-eye-slash' />
            </Button>
            <Button
              className='ml-6 has-tooltip border-r border-transparent hover:border-indigo-700'
              onClick={() => this.setIsMarkdown(true)}
              color={this.model.values.markdown ? 'primary' : 'secondary'}
              outline={!this.model.values.markdown}
              style={{ borderRadius: '6px 0 0 6px' }}
            >
              Markdown
              <Tooltip position='top'>Markdown-Text</Tooltip>
            </Button>
            <Button
              className='has-tooltip border-r border-transparent hover:border-indigo-700'
              onClick={() => this.setIsMarkdown(false)}
              color={this.model.values.markdown ? 'secondary' : 'primary'}
              outline={this.model.values.markdown}
              style={{ borderRadius: '0 6px 6px 0' }}
            >
              <Tooltip position='top'>Plain-Text</Tooltip>
              Plaintext
            </Button>
          </div>
          <InputText
            className='mt-4'
            model={this.model}
            name='title'
            label='Überschrift'
          />
          <InputText
            className='mt-4'
            model={this.model}
            name='subtitle'
            label='Unterüberschrift'
          />
          <InputTextarea
            className='mt-4'
            rows={18}
            model={this.model}
            name='text'
            label='Inhalt'
          />
          <div className='mt-4'>
            <InputImage
              model={this.model}
              name='imageId'
              scope='blog'
              width={486}
              height={250}
            />
          </div>
        </div>
        <div className='float-right'>
          <Button color='secondary' outline className='m-4' onClick={this.props.onClose}>
            Abbrechen
          </Button>
          <Button className='my-4' onClick={this.saveBlogpost}>
            Speichern
          </Button>
        </div>
      </div>
    )
  }
}
