import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputCompound } from 'components/Form/components/InputCompound'
import { Model } from 'components/Form/Model'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { z } from 'zod'
import { observer } from 'mobx-react'
import { observable, runInAction } from 'mobx'
import { isYmdDate } from 'contracts/general/helpers/isYmdDate'
import { InputDate } from 'components/Form/components/InputDate'
import { dayjs } from 'helpers/dayjs'

interface Props {}

@observer
export class QuarantineList extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable loading: boolean = false
  private readonly model: Model<{ compoundId: string | null; date: string }>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    const validator = z.object({
      compoundId: z.string(),
      date: z.string().refine(isYmdDate),
    })
    this.model = new Model(
      {
        compoundId: context.defaults.responsibilityCompoundId,
        date: dayjs().format('YYYY-MM-DD'),
      },
      validator,
    )
  }

  private downloadReport = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'quarantaeneliste',
      this.context.instance.id,
      { compoundId: this.model.values.compoundId, date: this.model.values.date },
      'Quarantäneliste.xlsx',
    )
    runInAction(() => (this.loading = false))
  }

  render() {
    if (!this.context.permissions.report_quarantaeneliste) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Quarantäneliste'
        subtitle='Wählen Sie ein Gelände und ein Datum aus, für das die Liste erstellt werden soll.'
      >
        <div id={this.model.id} className='flex gap-2 my-3'>
          <InputCompound
            label='Gelände'
            name='compoundId'
            model={this.model}
            onlyStamm={false}
            at={dayjs(this.model.values.date).toISOString()}
          />
          <InputDate name='date' model={this.model} />
        </div>
        <Button className='my-2' onClick={this.downloadReport} loading={this.loading}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
