import { storage } from 'services/storage'
import { isPlainObject } from 'helpers/isPlainObject'

export function userNavigatedBackToSearchFromCaseRecord(): boolean {
  const caseRecordState = storage.get('residents.record')
  if (!isPlainObject(caseRecordState) || !caseRecordState.fromSearch) {
    return false
  }
  const unmountedAt = Date.parse(caseRecordState.unmountedAt)
  if (isNaN(unmountedAt)) {
    return false
  }
  return +new Date() - unmountedAt < 200 // Less than 200 ms since resident record was unmounted.
}
