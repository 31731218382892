import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { ArbeitsplanPages } from './components/ArbeitsplanPages'
import { IArbeitsplanData } from 'contracts/pdf/interfaces/IArbeitsplanData'
import axios from 'axios'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'

interface Props {
  month: string // YYYY-MM
  userId: string
  instanceId: string
  arbeitsplanDataId?: string
}

@observer
export class Arbeitsplan extends React.Component<Props, {}> {
  @observable.ref private error: string | null = null
  @observable.ref private data: IArbeitsplanData | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    try {
      if (this.props.arbeitsplanDataId) {
        const response = await axios.get(
          `/api/${this.props.instanceId}/storage/${this.props.arbeitsplanDataId}`,
        )
        runInAction(() => (this.data = response.data.value))
      } else {
        const response = await axios.get(
          `/api/${this.props.instanceId}/pdf/data?type=arbeitsplan&month=${this.props.month}&userId=${this.props.userId}`,
        )
        runInAction(() => (this.data = response.data))
      }
    } catch (e) {
      runInAction(() => (this.error = e instanceof Error ? e.message : JSON.stringify(e)))
    }
  }

  render() {
    if (this.error) {
      return <div style={{ color: 'red' }}>{this.error}</div>
    }
    if (!this.data) {
      return null
    }

    return (
      <ArbeitsplanPages
        data={this.data}
        onPagesRendered={setReadyFlagWhenRenderingComplete}
      />
    )
  }
}
