import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Dialog } from 'components/Dialog'
import { CostCoverageSignatureDialog } from './components/CostCoverageSignatureDialog'
import { CostCoverageSignatureProblemAlert } from './components/CostCoverageSignatureProblemAlert'
import { box } from 'services/box'
import { ICostCoverageSignature } from 'contracts/costCoverages/interfaces/ICostCoverageSignature'
import { ICostCoverageSignaturesFilter } from 'contracts/costCoverages/interfaces/ICostCoverageSignaturesFilter'
import { getRangeLabel } from './helpers/getRangeLabel'
import { Tooltip } from 'components/Tooltip'
import { Model } from 'components/Form/Model'
import { InputMonth } from 'components/Form/components/InputMonth'
import { getMonthFromDefaults } from 'helpers/getMonthFromDefaults'

interface Props {
  resident: IResident
}

@observer
export class OverviewCostCoverageSignatures extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly signatures: Collection<
    ICostCoverageSignature,
    ICostCoverageSignaturesFilter,
    ICostCoverageSignaturesFilter
  >
  private readonly disposers: Disposer[] = []
  private readonly filter: Model<ICostCoverageSignaturesFilter>
  @observable private dialog: ICostCoverageSignature | null = null

  @observable private changed = { hasUnsavedChanges: false }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.filter = new Model({ month: getMonthFromDefaults(context.defaults) })
    this.signatures = new Collection(
      `/api/${context.instance.id}/residents/${props.resident.id}/costCoverageSignatures`,
      this.filter.values,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.signatures.init({ readOnly: true, observeQuery: true }))
    this.disposers.push(
      reaction(
        () => this.filter.values.month,
        (m) => (this.context.defaults.month = m),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private signatureMapper = (res: Resource<ICostCoverageSignature>) => {
    const signature = res.data
    if (!signature) {
      return null
    }
    return (
      <div
        key={signature.id}
        className='group hover:bg-indigo-100 bg-gray-100 mt-4 px-3 py-2 rounded-md overflow-hidden relative cursor-pointer truncate'
        onClick={() => this.openSignatureDialog(signature)}
      >
        <input
          type='checkbox'
          checked={signature.attachments.length > 0}
          className={`rounded-full ${
            signature.attachments.length > 0 ? 'border-0' : 'border border-gray-300'
          } h-5 w-5 text-green-500 mr-2`}
          onChange={(event) => {
            event.stopPropagation()
            box.alert(
              'Unterkunftsnachweis',
              'Sie können das Dokument öffnen und den unterschriebenen Unterkunftsnachweis unter der Vorschau als Anhang hochladen. Sobald ein Anhang vorliegt, wird hier ein grünes Häckchen angezeigt.',
            )
          }}
          onClick={(event) => event.stopPropagation()}
        />
        {!signature.coversCurrentResident && (
          <span className='mr-2 text-red-500 has-tooltip'>
            <i className='fas fa-exclamation-triangle' />
            <Tooltip position='right'>
              Der ausgewählte Bewohner ist nicht in dieser Unterschrift eingeschlossen
            </Tooltip>
          </span>
        )}
        <span className='mr-2'>
          {dayjs(signature.costCoverage.issueDate).format('DD.MM.YYYY')}
        </span>
        <span className='mr-2'>{signature.costCoverage.accommodation.name}</span>
        <span className='text-gray-500 text-sm mr-2'>
          {getRangeLabel(signature.beginDate, signature.endDate)}
          &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;{signature.costCoverage.aktenzeichen}
        </span>
        {/*signature.documentId && <span className='ml-2 text-blue-500'><i className='fas fa-comment' /></span>*/}
        {/*<RoundIcon classNameContainer='hidden group-hover:block' tooltip={{ text: 'KÜ löschen', position: 'left' }} style={{ position: 'absolute', top: 5, right: 5 }} icon='fas fa-trash' color='danger' onClick={(event) => { event.stopPropagation(); this.deleteCoverage(signature) }} />*/}
      </div>
    )
  }

  @action
  private openSignatureDialog = (signature: ICostCoverageSignature) => {
    if (signature.occupancyProblems.length > 0) {
      const promise = box.custom(
        <CostCoverageSignatureProblemAlert
          onClose={() => promise.close()}
          signature={signature}
        />,
      )
      return
    }
    this.dialog = signature
  }

  @action
  private closeSignatureDialog = () => {
    if (this.changed.hasUnsavedChanges) {
      const result = window.confirm(
        'Sie haben ungespeicherte Änderungen. Wollen Sie den Dialog wirklich schließen und die Änderungen verwerfen?',
      )
      if (!result) {
        return
      }
    }
    this.changed.hasUnsavedChanges = false
    this.dialog = null
  }

  /*@action
  private addCoverage = () => {
    const residents = this.residentSearchResults.resources ? this.residentSearchResults.resources.filter(r => !!r.data).map(r =>({ checked: r.id === this.props.resident.id, contact: r.id === this.props.resident.id, id: r.id, sex: r.data!.sex, firstName: r.data!.firstName, lastName: r.data!.lastName, dateOfBirth: r.data!.dateOfBirth, factor: 1 })) : []
    if (residents.length === 0) {
      window.alert('Die Bewohnerdaten konnten nicht geladen werden. Bitte wenden Sie sich an den Support.')
      return
    }
    this.changed = { hasUnsavedChanges: false }
    this.dialog = {
      id: '',
      document: { id: '', name: ''},
      accommodation: { id: null as any, type: 'compound', label: '' },
      payerId: null as any,
      issueDate: null as any,
      beginDate: null as any,
      endDate: null as any,
      aktenzeichen: '',
      betrag: null,
      eigenanteil: null,
      eigenanteilZyklus: null,
      ansprechpartner: '',
      email: '',
      fax: '',
      telefon: '',
      notes: '',
      createdBy: { id: this.context.user.id, firstName: this.context.user.firstName, lastName: this.context.user.lastName },
      createAt: new Date().toISOString(),
      coversCurrentResident: true,
      residents,
    }
  }

  private deleteCoverage = async (coverage: ICostCoverageSearchResult) => {
    const confirmed = await box.alert('Kostenübernahme löschen', 'Möchten Sie diese Kostenübernahme wirklich unwiderruflich löschen?', { confirm: 'Ja, jetzt löschen', cancel: 'Abbrechen', color: 'danger' })
    if (!confirmed) { return }
    hermes.delete(`/api/${this.context.instance.id}/residents/${this.props.resident.id}/costCoverageSearchResults/${coverage.id}`, { immediately: true })
      .catch(() => alert('Die Kostenübernahme konnte nicht gelöscht werden.'))
  }*/

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Unterschriften</span>
          <br />
          <span className='text-sm text-gray-400'>Unterkunftsnachweise ausdrucken</span>
        </div>

        {(!this.signatures.resources || !this.signatures.metadata) && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            <Spinner delay />
          </div>
        )}

        {!!this.signatures.resources && !!this.signatures.metadata && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            <div className='pl-2 flex'>
              <InputMonth className='mr-4' model={this.filter} name='month' />
            </div>
            {this.signatures.metadata.month !== this.signatures.query.month && (
              <Spinner delay />
            )}
            {this.signatures.metadata.month === this.signatures.query.month &&
              this.signatures.resources.map(this.signatureMapper)}
            {/*<div className='hover:bg-indigo-100 bg-gray-100 mt-4 px-4 py-2 rounded-md overflow-hidden relative cursor-pointer' onClick={this.addCoverage}>
              <span className='text-lg'><i className='fas fa-plus text-gray-500 mr-2' /></span>
              Kostenübernahme hinzufügen
              </div>*/}

            {/* Dialog */}
            <Dialog size='lg' open={!!this.dialog} setOpen={this.closeSignatureDialog}>
              {this.dialog && (
                <CostCoverageSignatureDialog
                  onClose={this.closeSignatureDialog}
                  signature={this.dialog}
                  changed={this.changed}
                  resident={this.props.resident}
                />
              )}
            </Dialog>
          </div>
        )}
      </div>
    )
  }
}
