import { RoundIcon } from 'components/RoundIcon'
import { IMigration } from 'contracts/migrations/interfaces/IMigration'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  migration: IMigration
  onClick: (migration: IMigration) => void
  onDelete: (migration: IMigration) => void
}

const statusColors = {
  Entwurf: 'text-blue-500',
  'Import läuft': 'text-yellow-500',
  Importiert: 'text-green-500',
  Papierkorb: 'text-red-500',
}

const stopPropagation = (e) => e.stopPropagation()

export const MigrationListRow: React.FC<Props> = observer(
  ({ migration, onClick, onDelete }) => {
    const handleClick = () => onClick(migration)
    const handleDelete = () => onDelete(migration)
    return (
      <>
        <div
          onClick={handleClick}
          className={`leading-[46px] px-3 cursor-pointer bg-white ${
            statusColors[migration.status]
          }`}
          style={{ borderRadius: '6px 0 0 6px' }}
        >
          {migration.status}
        </div>
        <div
          onClick={handleClick}
          className='leading-[46px] px-3 cursor-pointer bg-white'
        >
          {dayjs(migration.createdAt).format('DD.MM.YYYY')}
        </div>
        <div
          onClick={handleClick}
          className='leading-[46px] px-3 cursor-pointer bg-white truncate'
        >
          {migration.label}
        </div>
        <div
          onClick={handleClick}
          className='leading-[46px] px-3 cursor-pointer bg-white text-right'
        >
          {migration.numRows}
        </div>
        <div
          onClick={stopPropagation}
          className='pt-2 pr-3 cursor-pointer bg-white text-right'
          style={{ borderRadius: '0 6px 6px 0' }}
        >
          <RoundIcon icon='fas fa-trash' onClick={handleDelete} />
        </div>
      </>
    )
  },
)
