import { hermes } from '@byll/hermes'
import { IScanningStation } from 'contracts/scan/interfaces/IScanningStation'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { IMealScanResult } from 'contracts/scan/interfaces/IMealScanResult'
import { MealIssueTile } from './MealIssueTile'

interface Props {
  station: IScanningStation
  scans: IMealScanResult[]
}

@observer
export class LastScans extends React.Component<Props, {}> {
  static contextType = AppContext

  componentDidMount() {
    void this.loadLastScans()
  }

  private loadLastScans = async () => {
    const data = await hermes.indexOnceNew<IMealScanResult & { id: string }>(
      `/api/${this.context.instance.id}/scan/meals?scanningStationId=${this.props.station.id}`,
    )
    console.log(data)
    runInAction(() => {
      ;(this.props.scans as any).replace(data)
    })
  }

  private mapScan = (scan: IMealScanResult) => <MealIssueTile key={scan.id} scan={scan} />

  render() {
    return (
      <div className='absolute top-0 left-0 right-0 bottom-0 py-4 overflow-x-hidden overflow-y-auto flex flex-col'>
        {this.props.scans && this.props.scans.length > 0 && (
          <div className='text-base px-4 border-b border-gray-200 pb-3'>
            Zuletzt gescannt
          </div>
        )}
        {this.props.scans.map(this.mapScan)}
        {this.props.scans.length === 0 && (
          <div className='mt-8 px-4 text-slate-500 text-center'>
            <i className='fas fa-id-card' style={{ fontSize: 100 }} />
            <div className='text-xl mt-2'>
              Heute noch keine
              <br />
              Ausweise gescannt
            </div>
          </div>
        )}
      </div>
    )
  }
}
