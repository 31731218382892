import * as React from 'react'
import { TitleBar } from '../../../../../../components/TitleBar'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Collection, Resource } from '@byll/hermes'
import { AddMember } from './components/AddMember'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { MemberItem } from './components/MemberItem'
import { MemberImage } from './components/MemberImage'
import { RoundIcon } from 'components/RoundIcon'
import { Link } from 'react-router-dom'
import { SelectFamilyType } from './components/SelectFamilyType'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { FamilyGroup } from './components/FamilyGroup'

interface Props {
  familyId: string
  resident: IResident
  fromSearch: boolean // true if profile was opened from search
}

@observer
export class CaseRecordMenu extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly family: Resource<IFamily>
  private readonly residentSearchResults: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  // Residents is not needed in this component, but it is subscribed, so that other
  // family members are immediately available on resident change within the same family.
  private readonly residents: Collection<IResident, {}, { familyId: string }>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.family = new Resource(`/api/${context.instance.id}/families/${props.familyId}`)
    this.residentSearchResults = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      {
        familyId: props.familyId,
        fields: 'bookings,accommodation,lastScanAtDe',
        sort: 'dateOfBirth,asc',
      },
    )
    this.residents = new Collection(`/api/${context.instance.id}/residents`, {
      familyId: props.familyId,
    })
  }

  componentDidMount(): void {
    const disposeFamily = this.family.init()
    const disposeResidentSearchResults = this.residentSearchResults.init({
      readOnly: true,
    })
    const disposeResidents = this.residents.init()
    // Dispose delayed, so that resources are still available on resident change
    // within the same family (no resubscribe necessary if loaded within 1 sec)
    this.disposers.push(() => {
      setTimeout(() => {
        disposeFamily()
        disposeResidentSearchResults()
        disposeResidents()
      }, 1000)
    })
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private mapMember = (res: Resource<IResidentSearchResult>) => {
    const member = res.data
    if (!member) {
      return null
    }
    const isActive: boolean = this.props.resident.id === member.id
    const isHv: boolean = member.id === this.family.data?.hv
    const allowRemove: boolean = !isActive

    return (
      <MemberItem
        key={member.id}
        resident={member}
        isActive={isActive}
        isHv={isHv}
        allowRemove={allowRemove}
        showPresence={this.context.permissions.resident_showPresence}
      />
    )
  }

  render() {
    const resident = this.props.resident
    const members = (this.residentSearchResults.resources || []).filter(
      (m) => m.data && !m.data.deletedAt,
    )
    const archived = (this.residentSearchResults.resources || []).filter(
      (m) => m.data && !!m.data.deletedAt,
    )
    return (
      <div className={styles.menu}>
        <div className='sticky top-14'>
          <TitleBar>
            <div className={styles.caption}>
              <div className='truncate ml-10' style={{ flex: '0 1 auto' }}>
                {`${resident.lastName.toUpperCase()}, ${resident.firstName}`}
              </div>
              {!this.context.permissions.host_lfgb && (
                <div className='mr-auto flex-content ml-2'>
                  <span className='inline-flex items-center rounded-full bg-gray-500 px-3 py-0.5 text-sm font-medium text-white'>
                    {toJbpId(+resident.id)}
                  </span>
                </div>
              )}
            </div>
          </TitleBar>
          <Link to='/residents/find'>
            <RoundIcon
              color='primary'
              icon='fas fa-angle-left'
              tooltip={{
                text: this.props.fromSearch ? 'Zurück zur Suche' : 'Zur Suche',
                position: 'right',
              }}
              style={{ position: 'absolute', top: 16, left: 24 }}
            />
          </Link>

          <div
            className='bg-white absolute overflow-hidden shadow-lg rounded-t-md flex flex-col'
            style={{ top: 86, left: 24, width: 300, height: 'calc(100vh - 142px)' }}
          >
            {/* Profile image - Do not include directly, because it needs to be remounted
             * and model needs to be reset on resident change */}
            <MemberImage resident={resident} key={resident.id} />

            {/* Family members */}
            <div className='flex-auto overflow-x-hidden overflow-y-scroll p-4 flex flex-col gap-4'>
              <div className='flex font-bold text-md'>
                <div className='flex-content mr-2'>Familienmitglieder</div>
                {this.family.data && (
                  <AddMember
                    residents={this.residentSearchResults}
                    family={this.family.data}
                  />
                )}
                {this.family.data &&
                  this.context.permissions.family_setFamilyType &&
                  this.residentSearchResults.resources && (
                    <SelectFamilyType
                      family={this.family.data}
                      memberCount={this.residentSearchResults.resources.length}
                    />
                  )}
              </div>
              {/* Show subscribed family member */}
              {members.map(this.mapMember)}

              {/* Archive */}
              {archived.length > 0 && (
                <div className='mt-2 -mb-2 text-gray-500'>Archiv</div>
              )}
              {archived.map(this.mapMember)}

              {this.context.permissions.family_linking > 0 && this.family.data && (
                <FamilyGroup
                  key={`${this.family.id}.${this.family.data.group}`}
                  familyId={this.family.id}
                  familyGroupId={this.family.data.group}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
