import { IHealth } from 'contracts/residents/interfaces/IHealth'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  health?: IHealth | null
}

const bgColorMap = {
  'Corona Kontaktkategorie I': 'from-red-600 to-red-400',
  'Corona Verdacht': 'from-red-600 to-red-400',
  'Corona Positiv': 'from-red-600 to-red-400',
  'Corona Negativ': 'from-green-600 to-green-400',
}

const textColorMap = {
  'Corona Kontaktkategorie I': 'text-red-500',
  'Corona Verdacht': 'text-red-500',
  'Corona Positiv': 'text-red-500',
  'Corona Negativ': 'text-green-500',
}

export const CoronaTile: React.FC<Props> = observer((props) => (
  <div
    className={`${
      props.health
        ? bgColorMap[props.health.label] || 'from-red-600 to-red-400'
        : 'from-gray-600 to-gray-400'
    } min-h-[100px] bg-gradient-to-r text-white rounded-md shadow-md p-4 w-full`}
  >
    <div
      className={`${
        props.health
          ? textColorMap[props.health.label] || 'text-red-500'
          : 'text-gray-500'
      } text-2xl absolute rounded-md bg-white w-12 h-12 text-center pt-2`}
    >
      <i className='fas fa-virus' aria-hidden='true' />
    </div>
    <p className='ml-16 truncate font-bold text-xl text-white'>
      {props.health?.label || 'Corona'}
    </p>
    <p className='ml-16 truncate font-medium text-sm text-white'>
      {props.health
        ? `ab ${dayjs(props.health.date).format('DD.MM.YYYY')}`
        : 'Keine aktuellen Einträge'}
    </p>
  </div>
))
