import * as React from 'react'
import { toDataUrl } from 'helpers/toDataUrl'

const containerWidth = 840 // width in pixels, same as in invoices

interface Props {
  id: string
  name: string
  cache?: Blob
}

export class ImagePreview extends React.Component<Props, { dataUrl: string | null }> {
  constructor(props) {
    super(props)
    this.state = { dataUrl: null }
  }

  componentDidMount() {
    void this.loadImage(this.props)
  }

  componentDidUpdate(prevProps) {
    // only reload if nextProps are different
    if (prevProps.cache === this.props.cache) {
      return
    }
    void this.loadImage(this.props)
  }

  loadImage = async (nextProps) => {
    // convert blob to dataUrl
    const dataUrl = await toDataUrl(nextProps.cache as Blob)
    this.setState({ dataUrl })
  }

  render() {
    const dataUrl = this.state.dataUrl
    if (!dataUrl) {
      return null
    }

    return (
      <div
        className='flex-content m-auto shadow bg-white relative'
        style={{ minWidth: 200, minHeight: 200 }}
      >
        <img src={dataUrl} style={{ maxWidth: containerWidth }} alt='' />
        {this.props.children}
      </div>
    )
  }
}
