import * as React from 'react'
import { Dialog as UiDialog } from '@headlessui/react'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { getEmptyResident } from 'contracts/residents/helpers/getEmptyResident'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InputImage } from 'components/Form/components/InputImage'
import { Model } from 'components/Form/Model'
import { Collection, hermes, Resource } from '@byll/hermes'
import { action, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { CaseRecordForm } from 'modules/Residents/components/CaseRecordForm'
import { ResidentValidator } from 'contracts/residents/validators/ResidentValidator'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import dayjs from 'dayjs'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { Link } from 'react-router-dom'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { isStammCompound } from 'helpers/isStamm'
import { z } from 'zod'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputDate } from 'components/Form/components/InputDate'
import { CaseRecordCaption } from 'modules/Residents/components/CaseRecordForm/components/CaseRecordCaption'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { CornerBadge } from 'modules/Pdf/components/CornerBadge'
import { HouseBan } from 'modules/Residents/helpers/bookingTypes'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { Message } from 'components/Message'
import { XIcon } from '@heroicons/react/outline'
import { isPastDate } from 'modules/Reporting/helpers/isPastDate'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
}

@observer
export class NewResidentDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly existingResidents: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly disposers: Disposer[] = []
  @observable.ref private model: Model<
    IResident & { responsibilityCompoundId: string | null }
  >
  @observable private saving = false
  @observable private error: string | null = null
  @observable private preventRouteChange = false
  private readonly requiredFields: any = {}

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.requiredFields = {
      responsibilityCompoundId: z.string(),
      lfgInitialerCheckIn: z.string().refine(isPastDate),
    }
    // Required fields for LfG-B
    if (context.permissions.host_lfgb) {
      this.requiredFields = {
        responsibilityCompoundId: z.string(),
        lfgInitialerCheckIn: z.string().refine(isPastDate),
        sex: z.string(),
        dateOfBirth: z.string(),
        familyRelation: z.string().min(1),
        nationalityId: z.string(),
        cityOfBirth: z.string().min(1),
      }
    }
    this.model = new Model(
      {
        ...getEmptyResident(context.instance.id),
        lfgInitialerCheckIn: dayjs().format('YYYY-MM-DD'),
        responsibilityCompoundId: context.defaults.responsibilityCompoundId,
      },
      ResidentValidator.extend(this.requiredFields),
    )
    if (
      context.permissions.menu_resident_register === 1 &&
      !isStammCompound(this.model.values.responsibilityCompoundId || '')
    ) {
      this.model.values.responsibilityCompoundId = null
    }
    this.existingResidents = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      { searchString: '', page: '0,3', deleted: 'no' },
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      this.existingResidents.init({ readOnly: true, observeQuery: true }),
    )
    this.disposers.push(
      reaction(
        () =>
          !!this.model.values.firstName.trim() &&
          !!this.model.values.lastName.trim() &&
          !!this.model.values.dateOfBirth
            ? `${this.model.values.firstName} ${this.model.values.lastName} ${dayjs(
                this.model.values.dateOfBirth,
              ).format('DD.MM.YYYY')}`
            : '',
        (searchString) => (this.existingResidents.query.searchString = searchString),
      ),
    )
    const dispose = reaction(
      () => toJS(this.model.values),
      () => {
        this.preventRouteChange = true
        dispose()
      },
    )
    this.disposers.push(dispose)
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open && !this.props.open) {
      runInAction(() => {
        this.model = new Model(
          {
            ...getEmptyResident(this.context.instance.id),
            lfgInitialerCheckIn: dayjs().format('YYYY-MM-DD'),
            responsibilityCompoundId: this.context.defaults.responsibilityCompoundId,
          },
          ResidentValidator.extend({ responsibilityCompoundId: z.string() }),
        )
        if (
          this.context.permissions.menu_resident_register === 1 &&
          !isStammCompound(this.model.values.responsibilityCompoundId || '')
        ) {
          this.model.values.responsibilityCompoundId = null
        }
      })
    }
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onSubmit = async () => {
    runInAction(() => {
      this.model.values.firstName = this.model.values.firstName.trim()
      this.model.values.lastName = this.model.values.lastName.trim()
      this.model.values.creationUserId = this.context.user.id
      this.model.values.familyId = '0'
    })
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }

    try {
      runInAction(() => {
        this.saving = true
        this.preventRouteChange = false
        this.error = null
      })
      const family = await hermes.create(`/api/${this.context.instance.id}/families`, {})
      await hermes.create(`/api/${this.context.instance.id}/residents`, {
        ...this.model.values,
        id: undefined,
        familyId: family.id,
      })
      this.props.setOpen(false)
    } catch (e: any) {
      runInAction(() => {
        this.saving = false
        this.preventRouteChange = true
        this.error =
          e?.id === ConflictError.id
            ? e.message
            : 'Der Bewohner konnte nicht erstellt werden.'
      })
    }
  }

  private onClose = () => this.props.setOpen(false)

  private searchResultTileMapper = (res: Resource<IResidentSearchResult>) => {
    const resident = res.data
    if (!resident) {
      return null
    }
    const thumbnail = getResidentImageSrc(
      this.context.instance.id,
      resident.imageId,
      resident.sex,
      'thumbnail',
    )
    return (
      <Link
        to={`/residents/${toJbpId(+resident.id).toLowerCase()}/overview`}
        target='_blank'
        key={resident.id}
      >
        <div className='rounded-md shadow-lg bg-white overflow-hidden relative'>
          <img
            className='rounded-t-md w-full'
            src={thumbnail}
            alt=''
            width={200}
            height={200}
          />
          {resident.accommodation.reason === 'Hausverbot' && (
            <CornerBadge
              className='bg-red-500 text-white'
              small
              label={
                <div className='flex'>
                  <HouseBan className='w-6 h-6 mx-auto' />
                </div>
              }
            />
          )}
          <div className='px-3 py-4'>
            <div className='text-gray-900 text-xs font-medium truncate'>
              {resident.firstName}
            </div>
            <div className='text-gray-900 text-md font-medium truncate'>
              {resident.lastName.toUpperCase()}
            </div>
          </div>
        </div>
      </Link>
    )
  }

  @action private hideExistingResidents = () =>
    (this.existingResidents.query.searchString = '')

  render() {
    return (
      <div className='m-6' id={this.model.id}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <UiDialog.Title
          as='h3'
          className='text-lg leading-6 font-medium text-gray-900 pt-6'
        >
          Bewohner erstellen
        </UiDialog.Title>

        {this.error && (
          <div className='bg-white -mx-6 px-6 pt-4 sticky top-0 z-10'>
            <Message color='danger' className='border border-red-500'>
              {this.error}
            </Message>
          </div>
        )}

        {/* Image and fields */}
        <div className='flex flex-row mt-6' id={this.model.id}>
          <div style={{ flex: '0 0 320px' }}>
            <InputImage
              scope='resident'
              width={300}
              height={300}
              model={this.model}
              name='imageId'
            />
          </div>
          <div>
            <CaseRecordCaption className='mb-5 -mt-0.5'>Stammdaten</CaseRecordCaption>
            <div className='grid grid-cols-3 gap-5 mb-5'>
              <InputCompound
                className='col-span-2'
                model={this.model}
                name='responsibilityCompoundId'
                label='Zuständigkeit *'
                onlyStamm={this.context.permissions.menu_resident_register === 1}
                saveResponsibility
              />
              <InputDate
                model={this.model}
                name='lfgInitialerCheckIn'
                label='Zuständig ab *'
                maxLength={10}
              />
            </div>
            <CaseRecordForm
              model={this.model}
              requiredFields={this.requiredFields}
              readOnly={false}
            >
              {this.existingResidents.query.searchString !== '' &&
                this.existingResidents.resources &&
                this.existingResidents.resources.length > 0 && (
                  <div
                    className='absolute bg-white bottom-0 -left-1 -right-1 px-1 pt-4 z-10'
                    style={{ top: 100 }}
                  >
                    <div className='text-lg'>
                      Bewohner bereits registriert?
                      <span
                        onClick={this.hideExistingResidents}
                        className='cursor-pointer ml-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800'
                      >
                        Ausblenden
                      </span>
                    </div>
                    <div className='text-gray-500 text-sm leading-6'>
                      Hier werden bereits registrierte Bewohner mit ähnlichem Namen und
                      gleichem Geburtsdatum angezeigt. Sehen Sie sich die Liste an, bevor
                      Sie den Bewohner anlegen, um das doppelte Anlegen derselben Person
                      zu vermeiden.
                    </div>
                    <div className='grid grid-cols-3 gap-6 my-6'>
                      {this.existingResidents.resources?.map(this.searchResultTileMapper)}
                    </div>
                  </div>
                )}
            </CaseRecordForm>
          </div>
        </div>

        {/* Buttons */}
        <div className='mt-4 -mb-5 flex py-5 sticky bottom-0 bg-white border-t border-gray-300 -mx-6 px-6 z-1'>
          <Button onClick={this.onClose} color='secondary' outline className='mr-2'>
            Abbrechen
          </Button>
          <Button onClick={this.onSubmit} color='primary'>
            Erstellen
          </Button>
        </div>

        {this.saving && <DialogOverlaySpinner opaque />}
        {this.preventRouteChange && <PreventRouteChange />}
      </div>
    )
  }
}
