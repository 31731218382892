import { z } from 'zod'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isTime } from '../../general/helpers/isTime'
import { isYmdDate } from '../../general/helpers/isYmdDate'

export const TransferTransportationValidator = z.object({
  id: z.string().refine(isIntegerString),
  instanceId: z.string().refine(isIntegerString),
  label: z.string().min(1).max(256),
  departureDate: z.string().refine(isYmdDate),
  departureTime: z.union([z.string().refine(isTime), z.string().max(0)]),
  arrivalDate: z.string().refine(isYmdDate),
  arrivalTime: z.union([z.string().refine(isTime), z.string().max(0)]),
  buildingId: z.string().refine(isIntegerString).nullable(),
  comment: z.string(),
})
