import { Collection, hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { Spinner } from 'components/Spinner'
import { ConflictError, NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { dayjs } from 'helpers/dayjs'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import {
  IResidentValidationMessage,
  ResidentValidationMessages,
} from 'modules/Residents/components/ResidentValidationMessages'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { ExternalResidenceModel } from '..'

interface Props {
  resident: IResident
  model: Model<ExternalResidenceModel>
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  goBack: () => void
}

@observer
export class ExternalResidenceFormStep2Creating extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private messages: IResidentValidationMessage[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.create()
  }

  private create = async () => {
    const data: Partial<IBooking> = {
      instanceId: this.context.instance.id,
      type: 'external-residence',
      compoundId: null,
      // residentId,
      roomId: null,
      bed: null,
      reason: this.props.model.values.reason,
      comment: this.props.model.values.comment,
      beginAt: dayjs(
        `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
      ).toISOString(),
      endAt: this.props.model.values.hasEnd
        ? dayjs(
            `${this.props.model.values.endDate} ${this.props.model.values.endTime}`,
          ).toISOString()
        : null,
      documentId: this.props.model.values.documentId,
      extra: {},
    }

    const messages: IResidentValidationMessage[] = []

    for (const member of this.props.members.resources || []) {
      if (!member.data || member.data.deletedAt) {
        continue
      }
      try {
        await hermes.create(`/api/${this.context.instance.id}/bookings`, {
          ...data,
          residentId: member.id,
        })
        messages.push({
          resident: member.data,
          message: 'Abwesenheit erfolgreich erstellt.',
          type: 'success',
        })
      } catch (e: any) {
        if (e.id === ConflictError.id) {
          messages.push({
            resident: member.data,
            message: e.message,
            type: e.details?.type || 'error',
          })
        } else if (e.id === NotAuthorizedError.id) {
          messages.push({
            resident: member.data,
            message: 'Sie haben nicht die nötige Berechtigung für diesen Vorgang.',
            type: 'error',
          })
        } else {
          messages.push({
            resident: member.data,
            message: 'Die neue Abwesenheit konnte nicht erstellt werden.',
            type: 'error',
          })
        }
      }
    }

    runInAction(() => (this.messages = messages))
  }

  render() {
    return (
      <div className='min-h-[350px] flex flex-col'>
        {!this.messages && <Spinner />}
        {this.messages && (
          <>
            <ResidentValidationMessages
              messages={this.messages}
              fallback='Beim Erstellen der Abwesenheit ist ein Fehler aufgetreten.'
            />
            <div className='mt-auto border-t border-gray-300 flex-content text-right py-6 -mb-6 z-10 bottom-0 sticky bg-white'>
              {!!this.messages.find((m) => m.type === 'error') && (
                <Button
                  color='secondary'
                  className='mr-2'
                  outline
                  onClick={this.props.goBack}
                >
                  Zurück
                </Button>
              )}
              <Button color='secondary' outline onClick={this.props.onClose}>
                Schließen
              </Button>
            </div>
          </>
        )}
      </div>
    )
  }
}
