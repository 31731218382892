import { hermes } from '@byll/hermes'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { RoundIcon } from 'components/RoundIcon'
import { box } from 'services/box'
import { IGroup } from 'contracts/groups/interfaces/IGroup'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { IGroupWithParticipant } from 'contracts/groups/interfaces/IGroupWithParticipant'
import { isStammCompound } from 'helpers/isStamm'
import { dayjs } from 'helpers/dayjs'
import { Dialog } from 'components/Dialog'
import { GroupParticipantDialog } from './components/GroupParticipantDialog'
import { AddToGroupDialog } from 'modules/Residents/modules/FindRecord/components/FindRecordSearchBar/components/AddToGroupDialog'

interface Props {
  resident: IResident
}

@observer
export class OverviewGroups extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private groups: IGroupWithParticipant[] | null | 'error' = null
  @observable private dialog: IGroupWithParticipant | null = null
  @observable private changed = { hasUnsavedChanges: false }

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.loadGroups()
  }

  private loadGroups = async () => {
    try {
      const groups = await hermes.indexOnceNew<IGroupWithParticipant>(
        `/api/${this.context.instance.id}/groups?residentId=${this.props.resident.id}`,
      )
      runInAction(() => (this.groups = groups))
    } catch (_e) {
      runInAction(() => (this.groups = 'error'))
    }
  }

  private groupMapper = (group: IGroupWithParticipant) => {
    // const compound = hermes.getFromStore<ICompound>(`/api/${this.context.instance.id}/accommodations/compounds/${group.compoundId}`)
    return (
      <div
        key={group.id}
        className='group hover:bg-indigo-100 bg-gray-100 mt-4 px-3 py-2 rounded-md overflow-hidden relative cursor-pointer truncate'
        onClick={() => this.openGroup(group)}
      >
        <span className='mr-2'>{group.label}</span>
        {/*<span className="ml-2 inline-flex items-center px-2 rounded-full text-sm font-medium bg-gray-100 text-gray-800 border border-gray-500">
          {compound?.label || '-'}
        </span>*/}
        {group.fields.map((field) => (
          <span
            key={field.id}
            className='ml-1 inline-flex items-center px-2 rounded-full text-sm font-medium bg-gray-100 text-gray-800 border border-gray-500'
          >
            {field.label}={getValue(field.type, group.participant[field.id])}
          </span>
        ))}
        <RoundIcon
          classNameContainer='hidden group-hover:block'
          tooltip={{ text: 'Bewohner aus Gruppe entfernen', position: 'left' }}
          style={{ position: 'absolute', top: 5, right: 5 }}
          icon='fas fa-times'
          color='danger'
          onClick={(event) => {
            event.stopPropagation()
            this.deleteMember(group)
          }}
        />
      </div>
    )
  }

  @action
  private openGroup = (group: IGroupWithParticipant) => {
    this.dialog = group
  }

  private addToGroup = async () => {
    if (!this.context.permissions.groups_participants) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um Teilnehmer zu Gruppen hinzuzufügen.',
        { color: 'danger' },
      )
      return
    }
    const promise = box.custom(
      <AddToGroupDialog
        onClose={() => promise.close()}
        filter={{ residentIds: this.props.resident.id }}
      />,
      { context: this.context },
    )
    await promise
    void this.loadGroups()
  }

  private deleteMember = async (group: IGroupWithParticipant) => {
    if (
      !this.context.permissions.groups_participants ||
      (this.context.permissions.menu_groups === 1 && !isStammCompound(group.compoundId))
    ) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben nicht die nötige Berechtigung, um Teilnehmer aus dieser Gruppe zu entfernen.',
        { color: 'danger' },
      )
      return
    }
    const confirmed = await box.alert(
      'Teilnehmer entfernen',
      'Möchten Sie den aktuellen Bewohner wirklich aus dieser Gruppe entfernen?',
      { confirm: 'Ja, jetzt entfernen', cancel: 'Abbrechen', color: 'danger' },
    )
    if (!confirmed) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/groups/${group.id}-${group.version}/participants/${group.participant.id}`,
      )
      runInAction(() => {
        if (Array.isArray(this.groups)) {
          this.groups = this.groups!.filter((g) => g.id !== group.id)
        }
      })
    } catch (_e) {
      box.alert(
        'Entfernen fehlgeschlagen',
        'Der aktuelle Bewohner konnte nicht aus der Gruppe entfernt werden.',
      )
    }
  }

  @action
  private closeGroup = () => {
    if (this.changed.hasUnsavedChanges) {
      const result = window.confirm(
        'Sie haben ungespeicherte Änderungen. Wollen Sie den Dialog wirklich schließen und die Änderungen verwerfen?',
      )
      if (!result) {
        return
      }
    }
    this.changed.hasUnsavedChanges = false
    this.dialog = null
    void this.loadGroups()
  }

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Gruppen</span>
          <br />
          <span className='text-sm text-gray-400'>Alle Gruppen des Bewohners</span>
        </div>

        {!this.groups && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            <Spinner delay />
          </div>
        )}

        {this.groups === 'error' && (
          <LoadingError title='Die Gruppen konnten nicht geladen werden' />
        )}

        {this.groups && this.groups !== 'error' && (
          <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
            {this.groups.map(this.groupMapper)}
            <div
              className='hover:bg-indigo-100 bg-gray-100 mt-4 px-4 py-2 rounded-md overflow-hidden relative cursor-pointer'
              onClick={this.addToGroup}
            >
              <span className='text-lg'>
                <i className='fas fa-plus text-gray-500 mr-2' />
              </span>
              Bewohner zu einer Gruppe hinzufügen
            </div>

            {/* Dialog */}
            <Dialog size='md' open={!!this.dialog} setOpen={this.closeGroup}>
              {this.dialog && (
                <GroupParticipantDialog
                  onClose={this.closeGroup}
                  group={this.dialog}
                  changed={this.changed}
                />
              )}
            </Dialog>
          </div>
        )}
      </div>
    )
  }
}

function getValue(
  type: IGroup['fields'][0]['type'],
  value: string | number | boolean | null,
) {
  switch (type) {
    case 'date':
      return value ? dayjs(value).format('DD.MM.YYYY') : ''
    case 'checkbox':
      return value ? 'Ja' : 'Nein'
    default:
      return value
  }
}
