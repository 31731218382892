import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { Tooltip } from 'components/Tooltip'
import { getFamilyType } from 'contracts/residents/helpers/getFamilyType'
import { box } from 'services/box'

interface Props {
  memberCount: number
  family: IFamily
}

const types: IFamily['type'][] = ['A', 'F', 'E', 'P', null]
const typeDict = {
  U: 'Unbekannt',
  A: 'Alleinerziehend',
  F: 'Familie',
  E: 'Einzelperson',
  P: 'Paar',
}

const setType = action((family: IFamily, type: IFamily['type'], memberCount: number) => {
  if (memberCount <= 1 && type !== 'E') {
    box.alert(
      'Ungültige Familienart',
      'Eine Familie mit einem Mitglied muss immer als Einzelperson (E) markiert sein.',
    )
    return
  }
  if (memberCount > 1 && type === 'E') {
    box.alert(
      'Ungültige Familienart',
      'Eine Familie mit mehreren Mitgliedern darf nicht als Einzelperson (E) markiert werden.',
    )
    return
  }
  family.type = type
})

export const SelectFamilyType: React.FC<Props> = observer((props) => {
  const selected = getFamilyType(props.family, props.memberCount)
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='has-tooltip -top-[1px] inline-flex items-center rounded-full bg-gray-500 px-2.5 py-0.5 text-xs font-medium text-white'>
          {selected || 'U'}
          <Tooltip position='left'>Familienart</Tooltip>
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className={`right-0 origin-top-right absolute mt-2 py-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
          style={{ width: 120 }}
        >
          {types.map((type) => (
            <Menu.Item key={type}>
              {({ active }) => (
                <button
                  onClick={() => setType(props.family, type, props.memberCount)}
                  className={`${
                    active || type === selected
                      ? 'bg-blue-500 text-white'
                      : 'text-gray-900'
                  } group flex items-center w-full px-3 py-2 text-sm`}
                >
                  {typeDict[type || 'U']}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
})
