import * as React from 'react'
import { IBlogpost } from 'contracts/dashboard/interfaces/IBlogpost'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { BlogpostForm } from './BlogpostForm'
import { XIcon } from '@heroicons/react/outline'

interface Props {
  blogpost: Partial<IBlogpost>
  onClose: () => void
}

@observer
export class BlogpostEditDialog extends React.Component<Props, {}> {
  static contextType = AppContext

  render() {
    return (
      <div className='flex'>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='flex w-full'>
          <div className='p-4 flex-auto'>
            <BlogpostForm blogpost={this.props.blogpost} onClose={this.props.onClose} />
          </div>
        </div>
      </div>
    )
  }
}
