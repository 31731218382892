import * as React from 'react'
import { observer } from 'mobx-react'
import { LogValue } from './LogValue'
import { ILogChange } from 'contracts/general/interfaces/ILog'
import styles from '../styles.module.scss'

@observer
export class DetailInitial extends React.Component<{ detail: ILogChange }, {}> {
  render() {
    return (
      <tr key={this.props.detail.field}>
        <td className={styles.detailField} colSpan={3}>
          {this.props.detail.field}
        </td>
        <td className={styles.detailTo}>
          <LogValue value={this.props.detail.to} />
        </td>
      </tr>
    )
  }
}
