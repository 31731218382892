import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { Wait } from 'modules/Pdf/components/Wait'
import axios from 'axios'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'

export const LfgbChecklisteErstausstattung: React.FC<DocumentTemplateProps> = (props) => {
  const [family, setFamily] = React.useState<IFamily | null>(null)
  const [members, setMembers] = React.useState<IResidentSearchResult[] | null>(null)
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)

  React.useEffect(() => {
    const load = async () => {
      const [f, m] = await Promise.all([
        axios.get<any>(
          `/api/${props.resident.instanceId}/families/${props.resident.familyId}`,
        ),
        axios.get<any>(
          `/api/${props.resident.instanceId}/residentSearchResults?familyId=${props.resident.familyId}&fields=bookings`,
        ),
      ])
      const stay = m.data.resources
        .find((m) => m.id === props.resident.id)
        ?.data?.bookings?.find(
          (b) => b.timeline === 'present' && b.type === 'internal-residence',
        )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setBuilding(bl.data)
        setCompound(cm.data)
      }
      setFamily(f.data)
      setMembers(m.data.resources)
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!family || !members) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  if (
    props.resident.familyRelation !== 'Haushaltsvorstand' ||
    family.hv !== props.resident.id
  ) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <div className='border-2 border-red-500 p-10 text-red-500 rounded-md text-center'>
          Dieses Dokument kann nur für Haushaltsvorstände erstellt werden.
        </div>
      </div>
    )
  }

  const stay = members
    .find((m) => m.id === props.resident.id)
    ?.data?.bookings?.find(
      (b) => b.timeline === 'present' && b.type === 'internal-residence',
    )
  return (
    <>
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        {/* Header */}
        <div className='absolute top-[40px] left-[40px] text-lg'>
          Landesbetrieb für Gebäudebewirtschaftung Berlin
          <br />
          Betriebsteil B (LfG-B)
          <br />
          {buildingLabel}
        </div>
        <img className={styles.logo} src={logo} alt='' />
        <br />
        <div className='text-2xl text-center'>Empfangsbestätigung Erstausstattung</div>

        <div className='text-base'>
          <div className='flex mt-6'>
            <div className='flex-content'>Name:</div>
            <div className='flex-auto border-b border-black max-w-[400px] min-w-[50px] truncate pl-2'>{`${props.resident.lastName}, ${props.resident.firstName}`}</div>
          </div>
          <div className='flex mt-3 gap-3'>
            <div className='flex-content'>Zimmer:</div>
            <div className='flex-content max-w-[200px] min-w-[50px] border-b border-black truncate pl-2'>
              {(stay?.label || '').split('/').pop()!.trim()}
            </div>
            <div className='flex-content ml-6'>Person(en):</div>
            <div className='flex-[0_0_50px] border-b border-black truncate pl-2 mr-6'>
              {members.length}
            </div>
          </div>

          <div className='mt-6 text-sm'>
            Mit Ihrer Unterschrift bestätigen Sie, dass Sie die folgenden Gegenstände beim
            Einzug in die Unterkunft erhalten haben. Die Gegenstände befinden sich in
            guten Zustand und sind pfleglich zu behandeln und beim Auszug aus der
            Unterkunft an diese zurückzugeben.
          </div>
          <br />
          <div className='grid grid-cols-2 gap-5'>
            <div
              className='grid grid-cols-[225px_auto_auto] border border-black'
              style={{ lineHeight: '20px' }}
            >
              <div className='p-1 pb-0 border-r border-black bg-gray-300'>
                <b>Gegenstand</b>
              </div>
              <div className='p-1 text-bold border-b border-black bg-gray-300 text-center col-span-2'>
                <b>Anzahl bei</b>
              </div>
              <div className='p-1 pt-0 border-r border-b border-black bg-gray-300'>
                - GU und AE -
              </div>
              <div className='p-1 border-b border-black bg-gray-300 text-sm text-center'>
                <b>Einzug</b>
              </div>
              <div className='p-1 border-l border-b border-black bg-gray-300 text-sm text-center'>
                <b>Auszug</b>
              </div>
              <div className='p-1 border-r border-b border-black'>
                Heimausweis <br /> (inkl. Familienmitglieder)
              </div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Willkommensflyer</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Matratze mit Bezug</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Kopfkissen</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Bettdecke</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Handtuch groß</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Handtuch klein</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Bettbezug (2-tlg.)</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Bettlaken</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Kleiderschrank</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Regal</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Tisch</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Stuhl</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Bett</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>
                Wischeimer mit Mopp
              </div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>
                Besenkopf u. Besenstiel
              </div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Kehrgarnitur</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>
                Abfalleimer m. Deckel
              </div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Hygieneeimer</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-black'>Kühlschrank</div>
              <div className='p-1 border-black'></div>
              <div className='p-1 border-l border-black'></div>
            </div>

            <div
              className='grid grid-cols-[225px_auto_auto] border border-black'
              style={{ lineHeight: '20px' }}
            >
              <div className='p-1 pb-0 border-r border-black bg-gray-300'>
                <b>Gegenstand</b>
              </div>
              <div className='p-1 text-bold border-b border-black bg-gray-300 text-center col-span-2'>
                <b>Anzahl bei</b>
              </div>
              <div className='p-1 pt-0 border-r border-b border-black bg-gray-300'>
                - nur GU -
              </div>
              <div className='p-1 border-b border-black bg-gray-300 text-sm text-center'>
                <b>Einzug</b>
              </div>
              <div className='p-1 border-l border-b border-black bg-gray-300 text-sm text-center'>
                <b>Auszug</b>
              </div>
              <div className='p-1 border-r border-b border-black'>Kochtopf - groß</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Kochtopf - klein</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Bratpfanne</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Kaffeebecher</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Glas 20cl</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Kochlöffel</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Schöpfkelle</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Schneidebrett</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>
                kleine Salatschüssel
              </div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Essgabel</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Tafelmesser</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Esslöffel</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Kaffeelöffel</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Kuchengabel</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Küchenmesser 7cm</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Frühstücksteller</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Essteller tief</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Essteller flach</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Dosenöffner</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-b border-black'>Karaffe</div>
              <div className='p-1 border-b border-black'></div>
              <div className='p-1 border-l border-b border-black'></div>
              <div className='p-1 border-r border-black'>&nbsp;</div>
              <div className='p-1 border-black'></div>
              <div className='p-1 border-l border-black'></div>
            </div>
          </div>

          <div className='grid gap-16 grid-cols-3 mt-6 text-center'>
            <div>
              <div className='border-b border-black'>{dayjs().format('DD.MM.YYYY')}</div>
              <div>Datum</div>
            </div>

            <div>
              <div className='border-b border-black truncate'>&nbsp;</div>
              <div>Haushaltsvorstand</div>
            </div>

            <div>
              <div className='border-b border-black truncate'>&nbsp;</div>
              <div>{`${props.user.lastName}, ${props.user.firstName}`}</div>
            </div>
          </div>

          {/* Footer */}
          <div
            className='flex justify-between w-[760px] text-xs text-gray-500 absolute'
            style={{ bottom: 40, fontSize: 9 }}
          >
            <div>Stand 09.08.2022</div>
            <div className='text-right'>
              Brunnenstraße 110 C, 13355 Berlin
              <br />
              Tel. +49 30 213 099 101
              <br />
              info@lfg-b.de
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
