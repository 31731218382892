import { hermes } from '@byll/hermes'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { ILogChange } from 'contracts/general/interfaces/ILog'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { ResidentValidator } from 'contracts/residents/validators/ResidentValidator'
import { sleep } from 'helpers/sleep'
import { action, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { CaseRecordForm } from 'modules/Residents/components/CaseRecordForm'
import { CaseRecordCaption } from 'modules/Residents/components/CaseRecordForm/components/CaseRecordCaption'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import styles from './styles.module.scss'
import { NameChangeDialog } from './NameChangeDialog'

interface Props {
  resident: IResident
  family: IFamily
  readOnly: boolean // allow edit profile
}

@observer
export class ProfileTab extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private model: Model<IResident>
  @observable private saving = false
  @observable private preventRouteChange = false
  private initial: IResident
  private disposer: Disposer | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    // Copy values / no live update in form
    let familyRelation = props.resident.familyRelation
    if (props.family.hv !== props.resident.id && familyRelation === 'Haushaltsvorstand') {
      familyRelation = ''
    }
    this.model = new Model({ ...props.resident, familyRelation }, ResidentValidator)
    this.initial = { ...props.resident, familyRelation }
  }

  componentDidMount() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.disposer = reaction(
      () => toJS(this.model.values),
      () => {
        this.preventRouteChange = true
      },
    )
  }

  componentWillUnmount(): void {
    this.disposer?.()
  }

  @action
  private onReset = () => {
    if (!window.confirm('Möchten Sie Ihre Änderungen am Bewohnerprofil verwerfen?')) {
      return
    }
    let familyRelation = this.props.resident.familyRelation
    if (
      this.props.family.hv !== this.props.resident.id &&
      familyRelation === 'Haushaltsvorstand'
    ) {
      familyRelation = ''
    }
    this.model = new Model({ ...this.props.resident, familyRelation }, ResidentValidator)
    sleep(10).then(action(() => (this.preventRouteChange = false))) // Wait for reaction to set preventRouteChange => true. Then set it back to false.
  }

  private onSave = async () => {
    if (this.props.readOnly) {
      return
    }
    runInAction(() => {
      this.model.values.firstName = this.model.values.firstName.trim()
      this.model.values.lastName = this.model.values.lastName.trim()
      // Todo: remove next two lines
      this.model.values.oldNames = []
      this.model.values.oldDatesOfBirth = []
    })
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }

    try {
      const patchable: any = {}
      for (const key of Object.keys(this.model.values)) {
        if (key === 'oldNames' || key === 'oldDatesOfBirth') {
          continue
        }
        if (this.model.values[key] !== this.initial[key]) {
          patchable[key] = this.model.values[key]
        }
      }

      // Detect parallel changes of values by other users. Only consider values that have also been changed by current user.
      let parallel: ILogChange[] = []
      for (const key of Object.keys(patchable)) {
        if (
          this.props.resident[key] !== this.initial[key] &&
          this.model.values[key] !== this.props.resident[key]
        ) {
          parallel.push({
            field: key,
            from: this.props.resident[key],
            to: this.model.values[key],
          })
        }
      }
      if (parallel.length > 0) {
        parallel = (
          await hermes.create(`/api/${this.context.instance.id}/residentChanges`, {
            changes: parallel,
          })
        ).changes
        const useMyChanges = await box.alert(
          'Parallele Bearbeitung',
          <>
            Die Bewohnerdaten wurden inzwischen von einem anderen Benutzer geändert.
            Möchten Sie die Änderungen des anderen Benutzers oder Ihre Änderungen
            übernehmen:
            <div className='grid grid-cols-3 mt-2 gap-2'>
              <div className='font-bold'>Feld</div>
              <div className='font-bold'>Anderer Benutzer</div>
              <div className='font-bold'>Meine Änderungen</div>
              {parallel.map((p, i) => (
                <React.Fragment key={i}>
                  <div className='truncate'>{p.field}</div>
                  <div className='truncate'>{p.from}</div>
                  <div className='truncate'>{p.to}</div>
                </React.Fragment>
              ))}
            </div>
          </>,
          { cancel: 'Änderungen des anderen Benutzers', confirm: 'Meine Änderungen' },
        )
        if (!useMyChanges) {
          runInAction(() => {
            // Update conflicting changes with changes of other user
            for (const key of Object.keys(patchable)) {
              if (
                this.props.resident[key] !== this.initial[key] &&
                this.model.values[key] !== this.props.resident[key]
              ) {
                patchable[key] = this.props.resident[key]
              }
            }
          })
        }
      }

      // Detect old names
      const current = {
        firstName: this.props.resident.firstName,
        lastName: this.props.resident.lastName,
        aliasName: this.props.resident.aliasName,
      }
      if (
        this.model.values.firstName !== current.firstName ||
        this.model.values.lastName !== current.lastName ||
        (current.aliasName && this.model.values.aliasName !== current.aliasName)
      ) {
        const promise = box.custom(
          <NameChangeDialog onClose={(id) => promise.close(id)} />,
          { context: this.context, closable: true },
        )
        const nameChangeType = await promise
        if (nameChangeType === 'new name') {
          patchable.oldNames = [...this.props.resident.oldNames, current]
        }
      }

      runInAction(() => (this.saving = true))
      const updated: IResident = await hermes.patch(
        `/api/${this.context.instance.id}/residents/${this.model.values.id}`,
        {
          ...patchable,
          id: undefined,
          instanceId: undefined,
          creationUserId: undefined,
          oldDatesOfBirth: undefined,
        },
      )
      // Change model and .initial to current database values
      runInAction(() => {
        let familyRelation = this.props.resident.familyRelation
        if (
          this.props.family.hv !== this.props.resident.id &&
          familyRelation === 'Haushaltsvorstand'
        ) {
          familyRelation = ''
        }
        this.model = new Model({ ...updated, familyRelation }, ResidentValidator)
        this.initial = { ...updated, familyRelation }
        this.saving = false
      })
      await sleep(10) // Wait for reaction to set preventRouteChange => true. Then set it back to false.
      runInAction(() => (this.preventRouteChange = false))
    } catch (e: any) {
      runInAction(() => (this.saving = false))
      box.alert(
        'Speichern fehlgeschlagen',
        e?.id === ConflictError.id
          ? e.message
          : 'Der Bewohner konnte nicht gespeichert werden.',
        { color: 'danger' },
      )
    }
  }

  render() {
    return (
      <div className='relative flex bg-white rounded-md shadow-md px-6 pt-6 mb-6 flex-grow'>
        <div
          className='hidden lg:block pr-12 pt-4 text-right'
          style={{ flex: '0 0 200px' }}
        >
          <span className='text-gray-900 text-lg'>Profildaten</span>
          <br />
          <span className='text-sm text-gray-400'>Akte und allgemeine Bewohnerdaten</span>
        </div>
        <div className='flex-auto pt-4' id={this.model.id}>
          <CaseRecordCaption className='mb-5 -mt-0.5'>Stammdaten</CaseRecordCaption>
          <CaseRecordForm
            model={this.model}
            key={this.model.id}
            readOnly={this.props.readOnly}
          />
          {!this.props.readOnly && (
            <div className='text-right py-5 mt-5 sticky bottom-0 bg-white border-t border-gray-300 -mx-6 px-6 xl:-ml-[224px] z-1'>
              <Button onClick={this.onReset} color='secondary' outline className='mr-3'>
                Zurücksetzen
              </Button>
              <Button onClick={this.onSave} color='primary'>
                Speichern
              </Button>
            </div>
          )}
        </div>
        {this.preventRouteChange && <PreventRouteChange />}
        {this.saving && <DialogOverlaySpinner opaque sticky className={styles.zIndex} />}
      </div>
    )
  }
}
