import * as React from 'react'
import Webcam from 'react-webcam'

interface Props {
  onSelect: (image: string, width: number, height: number) => void
  onClose: () => void
  width: number
  height: number
}

export const WebcamCapture: React.FC<Props> = (props) => {
  const webcamRef = React.useRef(null)
  const [imgSrc, setImgSrc] = React.useState<string | null>(null)
  const [hasError, setHasError] = React.useState<boolean>(false)
  const [hasStream, setHasStream] = React.useState<boolean>(false)

  const [videoConstraints] = React.useState(() => ({
    width: props.width * 2,
    height: props.height * 2,
  }))

  const capture = React.useCallback(() => {
    if (!webcamRef.current) {
      return
    }
    const imageSrc = (webcamRef.current as any).getScreenshot()
    setImgSrc(imageSrc)
  }, [webcamRef, setImgSrc])

  if (hasError) {
    return (
      <div className='absolute top-0 bottom-0 left-0 right-0 flex flex-col'>
        <div className='flex-content my-auto px-6 text-sm text-center text-gray-100'>
          Auf Ihrem Gerät ist entweder keine Webcam vorhanden, oder es liegt keine
          Berechtigung vor, das Bild abzurufen.
          <button
            onClick={() => props.onClose()}
            className='bg-white hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-full text-sm px-2 mt-6'
            style={{ height: 30, lineHeight: '30px' }}
          >
            Schließen
          </button>
        </div>
      </div>
    )
  }

  function onSelect() {
    const img: HTMLImageElement | null =
      (document.getElementById('webcam-img-preview') as any) || null
    if (!img || !imgSrc) {
      return
    }
    props.onSelect(imgSrc, img.naturalWidth, img.naturalHeight)
  }

  return (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        onUserMediaError={() => setHasError(true)}
        onUserMedia={() => setTimeout(setHasStream, 400, true)}
        videoConstraints={videoConstraints}
        minScreenshotHeight={props.height * 2}
        minScreenshotWidth={props.width * 2}
      />
      {/* Waiting for camera animation */}
      {!imgSrc && !hasStream && (
        <div className='bg-indigo-500 absolute top-0 left-0 bottom-0 right-0'>
          <div
            className='animate-bounce absolute rounded-full bg-white text-indigo-500 text-center flex flex-col'
            style={{ width: '50%', height: '50%', margin: '25%', fontSize: '80px' }}
          >
            <div className='flex-content my-auto'>
              <i className='fas fa-camera' />
            </div>
          </div>
        </div>
      )}

      {/* Control buttons - cancel / take picture */}
      {!imgSrc && hasStream && (
        <div
          className='absolute flex justify-end'
          style={{ bottom: 10, height: 30, width: props.width, left: 0 }}
        >
          <button
            onClick={() => props.onClose()}
            className='bg-white hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-full text-sm px-2'
            style={{ height: 30, lineHeight: '30px', marginRight: 10 }}
          >
            Abbrechen
          </button>
          <button
            onClick={capture}
            className='bg-white hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-full text-sm px-2'
            style={{ height: 30, lineHeight: '30px', marginRight: 10 }}
          >
            Foto
          </button>
        </div>
      )}
      {imgSrc && (
        <div>
          <img
            alt=''
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: props.width,
              height: props.height,
            }}
            id='webcam-img-preview'
            src={imgSrc}
          />
          <div
            className='absolute flex justify-end'
            style={{
              position: 'absolute',
              bottom: 10,
              height: 30,
              width: props.width,
              left: 0,
            }}
          >
            <button
              onClick={() => setImgSrc(null)}
              className='bg-white hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-full text-sm px-2'
              style={{ height: 30, lineHeight: '30px', marginRight: 10 }}
            >
              Zurück
            </button>
            <button
              onClick={onSelect}
              className='bg-white hover:bg-indigo-500 hover:text-white text-gray-500 shadow-md rounded-full text-sm px-2'
              style={{ height: 30, lineHeight: '30px', marginRight: 10 }}
            >
              Speichern
            </button>
          </div>
        </div>
      )}
    </>
  )
}
