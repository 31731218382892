import { Collection } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { XIcon } from '@heroicons/react/outline'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { TodoOverlayContent } from './components/TodoOverlayContent'

interface Props {
  onClose: () => void
}

@observer
export class TodoOverlay extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private step: 'animation' | 'content' | null = null
  private readonly disposers: Disposer[] = []
  private readonly lists: Record<
    'overdue' | 'due today' | 'no due date' | 'done',
    Collection<
      ITodoSearchResult,
      any,
      { status: ITodoSearchResultFilter['status']; page: string }
    >
  >

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.lists = {
      overdue: new Collection(
        `/api/${context.instance.id}/todoLists/user-${context.user.id}/todos`,
        { page: '0,10', status: 'overdue', link: 'assignee' },
      ),
      'due today': new Collection(
        `/api/${context.instance.id}/todoLists/user-${context.user.id}/todos`,
        { page: '0,10', status: 'due today', link: 'assignee' },
      ),
      'no due date': new Collection(
        `/api/${context.instance.id}/todoLists/user-${context.user.id}/todos`,
        { page: '0,10', status: 'no due date', link: 'assignee' },
      ),
      done: new Collection(
        `/api/${context.instance.id}/todoLists/user-${context.user.id}/todos`,
        { page: '0,10', status: 'done', link: 'assignee' },
      ),
    }
    makeObservable(this)
  }

  componentDidMount() {
    for (const key of Object.keys(this.lists)) {
      this.disposers.push(this.lists[key].init({ readOnly: true, observeQuery: true }))
    }
    const timer1 = setTimeout(this.setStepAnimation, 20)
    this.disposers.push(() => clearTimeout(timer1))
    const timer2 = setTimeout(this.setStepContent, 550)
    this.disposers.push(() => clearTimeout(timer2))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action private setStepAnimation = () => (this.step = 'animation')
  @action private setStepContent = () => (this.step = 'content')

  render() {
    return (
      <div
        className={`overflow-hidden fixed flex flex-col shadow-2xl w-[300px] xxl:w-[360px] h-[424px] z-30 transition duration-500 right-0 bottom-0 ${
          this.step ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'
        }`}
        style={{ borderRadius: '6px 0 0 0' }}
      >
        <div className='bg-blue-500 text-white p-4'>
          Meine Aufgaben
          <div className='absolute top-0 right-0 pt-4 pr-3'>
            <button
              onClick={this.props.onClose}
              type='button'
              className='rounded-md text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              <span className='sr-only'>Close</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>
        <div className='p-4 flex-auto overflow-y-auto overflow-x-hidden bg-white'>
          {this.step === 'content' && <TodoOverlayContent lists={this.lists} />}
        </div>
      </div>
    )
  }
}
