import { dayjs } from './dayjs'

/**
 * Calculates age from dateOfBirth till now
 * @param dateOfBirth
 * @param now
 */
export function getAge(dateOfBirth: string | null, now = dayjs()): number | null {
  if (typeof dateOfBirth !== 'string') {
    return null
  }
  const birthday = dayjs(dateOfBirth, 'YYYY-M-D')
  if (!birthday.isValid()) {
    return null
  }
  return now.diff(birthday, 'years')
}
