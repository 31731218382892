import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IBed } from 'contracts/residents/interfaces/IOccupancy'
import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { isStammBuilding } from 'helpers/isStamm'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import dayjs from 'dayjs'
import { hermes } from '@byll/hermes'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { z } from 'zod'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { getRoomLockReasons } from 'contracts/accommodations/helpers/getRoomLockReasons'

interface Props {
  room: IRoom
  bed: IBed
  onClose: () => void
}

@observer
export class ResidentRoomActionDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ reason: string; label: string }>
  private readonly reasonOptions: InputSelectOption[]
  @observable private saving = false
  @observable private action: 'reservation' | 'lock' | null = null
  @observable private message: string | null = 'Was möchten Sie tun?'

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model(
      { reason: '', label: '' },
      z.object({ reason: z.string().min(1) }),
    )
    this.reasonOptions = getRoomLockReasons(context.instance.id, context.permissions).map(
      (r) => ({
        value: r,
        label: r,
      }),
    )
    this.reasonOptions.unshift({ value: '', label: 'Bitte wählen...' })
    makeObservable(this)
  }

  private onSubmit = async () => {
    try {
      // Create reservation
      if (this.action === 'reservation' && this.props.bed.type === 'empty-bed') {
        const data: Partial<IBooking> = {
          instanceId: this.context.instance.id,
          type: 'internal-reservation',
          compoundId: null,
          residentId: null,
          roomId: this.props.room.id,
          bed: this.props.bed.bed,
          reason: '',
          comment: this.model.values.label,
          beginAt: dayjs()
            .add(-1, 'minutes')
            .set('seconds', 0)
            .set('milliseconds', 0)
            .toISOString(),
          endAt: null,
          extra: {},
        }

        runInAction(() => (this.saving = true))
        const response = await hermes.create(
          `/api/${this.context.instance.id}/bookings`,
          data,
        )
        runInAction(() => {
          this.props.bed.id = response.id
          this.props.bed.type = 'internal-reservation'
          this.props.bed.beginAt = data.beginAt!
          this.props.bed.endAt = null
          this.props.bed.comment = data.comment!
        })
      }

      // Delete reservation
      else if (
        this.action === 'reservation' &&
        this.props.bed.type === 'internal-reservation'
      ) {
        runInAction(() => (this.saving = true))
        await hermes.delete(
          `/api/${this.context.instance.id}/bookings/${this.props.bed.id}`,
        )
        runInAction(() => {
          this.props.bed.type = 'empty-bed'
          this.props.bed.beginAt = ''
          this.props.bed.endAt = ''
          this.props.bed.comment = ''
        })
      }

      // Create lock
      else if (
        this.action === 'lock' &&
        this.props.bed.lock === null &&
        this.props.bed.type === 'empty-bed'
      ) {
        if (!this.model.isValid()) {
          this.model.setFocusToLeftTopmostInvalidField()
          return
        }
        runInAction(() => (this.saving = true))
        await hermes.update(
          `/api/${this.context.instance.id}/accommodations/roomLocks/${this.props.room.id}`,
          {
            action: 'create lock',
            bed: this.props.bed.bed,
            reason: this.model.values.reason,
            notes: this.model.values.label,
          },
        )
        runInAction(() => {
          this.props.bed.lock = {
            reason: this.model.values.reason,
            notes: this.model.values.label,
          }
        })
      }

      // Delete lock
      else if (this.action === 'lock' && this.props.bed.lock !== null) {
        runInAction(() => (this.saving = true))
        await hermes.update(
          `/api/${this.context.instance.id}/accommodations/roomLocks/${this.props.room.id}`,
          { action: 'delete lock', bed: this.props.bed.bed },
        )
        runInAction(() => {
          this.props.bed.lock = null
        })
      }

      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.saving = false
        this.message =
          e.id === ConflictError.id
            ? e.message
            : 'Der Vorgang konnte nicht durchgeführt werden.'
      })
    }
  }

  @action private setReservation = () => {
    this.action = 'reservation'
    if (this.context.permissions.resident_reservationCheckInOut === 0) {
      this.message =
        'Sie haben nicht die nötige Berechtigung, um Zimmerreservierungen zu bearbeiten.'
    } else if (
      this.context.permissions.resident_reservationCheckInOut === 1 &&
      !isStammBuilding(this.props.room.buildingId)
    ) {
      this.message =
        'Sie haben nicht die nötige Berechtigung, um Zimmerreservierungen zu bearbeiten, da sich das Zimmer in keinem Ihrer Stammgebäude befindet.'
    } else {
      this.message = null
    }

    if (this.props.bed.type === 'internal-residence') {
      this.message = 'Dieses Bett kann nicht reserviert werden, da es bereits belegt ist.'
    }
    if (this.props.bed.lock !== null && this.props.bed.type !== 'internal-reservation') {
      this.message = 'Dieses Bett kann nicht reserviert werden, da es gesperrt ist.'
    }
  }
  @action private setLock = () => {
    this.action = 'lock'
    if (this.context.permissions.asset_roomLock === 0) {
      this.message =
        'Sie haben nicht die nötige Berechtigung, um den Sperrstatus von Betten zu verändern.'
    } else if (
      this.context.permissions.asset_roomLock === 1 &&
      !isStammBuilding(this.props.room.buildingId)
    ) {
      this.message =
        'Sie haben nicht die nötige Berechtigung, um Bettsperren zu bearbeiten, da sich das Zimmer in keinem Ihrer Stammgebäude befindet.'
    } else {
      this.message = null
    }

    if (this.props.bed.lock === null && this.props.bed.type === 'internal-residence') {
      this.message = 'Dieses Bett kann nicht gesperrt werden, da es bereits belegt ist.'
    }

    if (this.props.bed.lock === null && this.props.bed.type === 'internal-reservation') {
      this.message =
        'Dieses Bett kann nicht gesperrt werden, da es bereits reserviert ist. Löschen Sie erst die Reservierung.'
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {`${this.props.room.label} / Bett ${this.props.bed.bed}`}
            </Dialog.Title>
          </div>
        </div>

        {this.message && (
          <Message color='primary' className='mt-5'>
            {this.message}
          </Message>
        )}

        <div className='flex my-5'>
          <Button
            onClick={this.setReservation}
            color={this.action === 'reservation' ? 'primary' : 'secondary'}
            outline={this.action !== 'reservation'}
            className='flex-auto'
            style={{ borderRadius: '6px 0 0 6px', padding: '9px 0', borderRight: 'none' }}
          >
            {this.props.bed.type === 'internal-reservation'
              ? 'Reservierung löschen'
              : 'Bett reservieren'}
          </Button>
          <Button
            onClick={this.setLock}
            color={this.action === 'lock' ? 'primary' : 'secondary'}
            outline={this.action !== 'lock'}
            className='flex-auto'
            style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
          >
            {this.props.bed.lock === null ? 'Bett sperren' : 'Bettsperre löschen'}
          </Button>
        </div>

        {this.action === 'lock' && this.props.bed.lock === null && !this.message && (
          <div id={this.model.id} className='my-5'>
            <InputSelect
              className='mb-5'
              model={this.model}
              name='reason'
              label='Sperrgrund'
              options={this.reasonOptions}
            />
            <InputText
              model={this.model}
              name='label'
              label='Notiz'
              placeholder='Optional'
            />
          </div>
        )}

        {this.action === 'reservation' &&
          this.props.bed.type === 'empty-bed' &&
          !this.message && (
            <InputText
              className='my-5'
              model={this.model}
              name='label'
              label='Notiz'
              placeholder='Optional'
            />
          )}

        <div className='col-span-4 text-right'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            {this.message ? 'Schließen' : 'Abbrechen'}
          </Button>
          {!this.message && (
            <Button color='primary' className='ml-2' onClick={this.onSubmit}>
              OK
            </Button>
          )}
        </div>

        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
