import { Model } from 'components/Form/Model'
import { AppContextProps } from 'services/connection/models/AppContext'
import { storage } from 'services/storage'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { isStammCompound } from 'helpers/isStamm'
import { isPlainObject } from 'helpers/isPlainObject'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'

export function getOccupancyRowFilter(
  context: AppContextProps,
  at: string | 'now' | null,
  module: IOccupancyFilter['module'],
): Model<IOccupancyFilter> {
  const stored = storage.get(`occupancy.${context.user.id}`)
  const model = new Model<IOccupancyFilter>({
    module,
    queryAt: at,
    compoundId:
      isPlainObject(stored) && isIntegerString(stored.compoundId)
        ? stored.compoundId
        : context.defaults.responsibilityCompoundId,
    buildingId: 'none',
    floorIds: '',
    locked: null,
    occupied: null,
    capacity: null,
    equipment: '',
    typeOfUse: '',
    view: 'tile',
  })

  if (context.permissions.resident_accommodationBookings === 1) {
    if (!isStammCompound(context.defaults.responsibilityCompoundId || '')) {
      model.values.compoundId = null
    }
  }
  if (isPlainObject(stored)) {
    if (model.values.compoundId && model.values.compoundId === stored.compoundId) {
      model.values.buildingId = isIntegerString(stored.buildingId)
        ? stored.buildingId
        : 'none'
      model.values.view =
        ['tile', 'tile+transfer', 'plan'].indexOf(stored.view) !== -1
          ? stored.view
          : 'plan'
      model.values.floorIds = String(stored.floorIds || '')
    }
  }

  return model
}
