const dayjs = require('dayjs')

export const ymdDateErrorMessage = { message: 'Not a valid ymd string date' }

export function isYmdDate(date: string): boolean {
  if (typeof date !== 'string') {
    return false
  }
  const day = dayjs(date)
  if (!day.isValid()) {
    return false
  }
  return day.format('YYYY-MM-DD') === date
}
