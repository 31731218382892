import * as React from 'react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { IUserCard } from 'contracts/userCard/interfaces/IUserCard'
import { ICompanyIdCardTemplate } from 'contracts/userCard/interfaces/ICompanyIdCardTemplate'
import styles from '../../styles.module.scss'
import { Model } from 'components/Form/Model'
import { encodeBarcode } from 'modules/Pdf/helpers/encodeBarcode'

interface Props {
  userCard: IUserCard
  companyIdCardTemplate?: Model<ICompanyIdCardTemplate>
  media?: 'screen' | 'print'
}

// Back: Format 8.56 x 5.398 cm, 50 pixel per cm
@observer
export class EmployeeIdCardBack extends React.Component<Props, {}> {
  render() {
    const card = this.props.userCard
    let template: ICompanyIdCardTemplate | null = null
    if (this.props.companyIdCardTemplate) {
      template = this.props.companyIdCardTemplate.values
    }
    return (
      <div
        className={
          styles.card +
          ' ' +
          styles.back +
          (this.props.media !== 'print' ? ` ${styles.screen}` : '')
        }
      >
        {/* Colored stripe on top */}
        <div className={styles.stripe} style={{ background: card.color }}>
          {template && template.title}
        </div>

        {/* Address */}
        <div className={styles.address}>{template && template.text}</div>

        {/* Barcode */}
        <div
          className='absolute top-[65px] left-[225px] w-[250px]'
          style={{
            fontFamily: 'Barcode',
            fontSize: 40,
            marginTop: -11,
            paddingBottom: 0,
            transform: 'scaleY(-2.3)',
          }}
        >
          {encodeBarcode(card.token || 'EXAMPLE')}
        </div>

        {/* Signature employee */}
        <div className={styles.signatureEmployee}>
          _______________________________
          <br />
          Ausweisinhaber/in:
          <br />
          {card.firstName + ' ' + card.lastName}
        </div>

        {/* Signature employer */}
        <div className={styles.signatureEmployer}>
          _______________________________
          <br />
          <div>
            <div>
              {template && template.signatureLabel !== 'companyName'
                ? 'Bevollmächtigter:'
                : 'Aussteller:'}
            </div>
            <div className='truncate'>
              {(template && template.signatureName) || 'Jonas Better Place GmbH'}
            </div>
          </div>
        </div>

        {/* Card number & date */}
        <div className={styles.cardNumberCol1}>
          <div className={styles.alignBottom}>
            {/* Id of this card. (db id) */}
            <span>{`Ausweisnummer: ${card.id}`}</span>
            <br />

            {/* Bewacher ID if available */}
            {card.bewacherId && (
              <span>
                {`Bewacher ID: ${card.bewacherId}`}
                <br />
              </span>
            )}

            {/* Unternehmens ID if available */}
            {template?.companyNumber && (
              <span>
                {`Unternehmens ID: ${template.companyNumber}`}
                <br />
              </span>
            )}
          </div>
        </div>
        <div className={styles.cardNumberCol2}>
          <div className={styles.alignBottom}>
            Ausgestellt am {dayjs(card.dateOfIssue).format('DD.MM.YYYY')}
            <br />
            Gültigkeit des Ausweises:{' '}
            {dayjs(card.dateOfIssue).add(2, 'years').format('DD.MM.YYYY')}
          </div>
        </div>
      </div>
    )
  }
}
