import { ParseChanges } from './parseDecimal'
// do not use g flag here, it will lead numberRegex to store the lastIndex and the calcCaretPosition will no longer
// work as expected.
const numberRegex = /\d/

export type actionType = 'pasted' | 'typed' | 'deletedBackwards' | 'deletedForward'

/**
 * This function was insipred by https://github.com/s-yadav/react-number-format/blob/master/src/number_format.js
 * (but is extended to handle our extended functionality)
 */
export function calcCaretPosition(
  inputValue: string,
  formattedValue: string,
  inputCaretPos: number,
  action: actionType | null,
  parseChanges?: ParseChanges,
): number {
  let formattedCaretPos = 0

  if (parseChanges && parseChanges.resetToPreviousValue) {
    if (action === 'deletedForward') {
      // keep position from beginning
      return inputCaretPos
    } else {
      // keep position from end
      return formattedValue.length - (inputValue.length - inputCaretPos)
    }
  }

  // iterate over the input value until we reach the former caret position
  for (let i = 0; i <= inputCaretPos; i++) {
    // if digits were added at the current position, increase formattedCaretPos by the added digits
    if (parseChanges && parseChanges.addedDigits && parseChanges.addedDigits[i]) {
      formattedCaretPos += parseChanges.addedDigits[i]
    }

    // break if current position equals inputCaretPos (in that case we need to add digits, but do not
    if (i === inputCaretPos) {
      break
    }

    // skip removedDigits
    if (parseChanges && parseChanges.removedDigits && parseChanges.removedDigits[i]) {
      continue
    }

    // if a character is not in the set of allowed numbers (e. g. -), and has been removed in the formatted value
    // (e. g. -- in the beginning has been replaced with -), do not proceed the formattedCaretPos
    if (
      !numberRegex.test(inputValue[i]) &&
      inputValue[i] !== formattedValue[formattedCaretPos]
    ) {
      continue
    }

    // otherwise the character is a) either allowed or is b) in both the input value and in the formatted value
    // (e. g. the decimal point or a minus, which is not allowed to prevent multiple uses to be searched for)
    // if a) it is an allowed character, but it is not at the current position, search for it in the formatted value
    // (e. g., the formatted value might include spaces, thousands separators or blank indicators such as underscores)
    while (
      inputValue[i] !== formattedValue[formattedCaretPos] &&
      formattedCaretPos < formattedValue.length
    ) {
      formattedCaretPos++
    }
    if (formattedCaretPos < formattedValue.length) {
      formattedCaretPos++
    }
  }

  return formattedCaretPos
}
