import { uniqueId } from 'helpers/uniqueId'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { tooltip } from '../../helpers/tooltip'
import { classNames } from 'helpers/classNames'

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  inputClassName?: string
  style?: any
  children?: Element
  hasError?: boolean
  setRef?: (HTMLInputElement) => void
}

export const InputText: React.ComponentClass<Props> = tooltip<Props>(
  observer((props) => {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const {
      name,
      model,
      label,
      tooltip,
      className,
      inputClassName,
      setRef,
      children,
      hasError,
      ...attributes
    } = props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)
    const [id] = React.useState(() => props.id || uniqueId('input-'))

    const onChange = action((event: React.FormEvent<HTMLInputElement>) => {
      if (!(event.target instanceof HTMLInputElement)) {
        return
      }
      const target: HTMLInputElement = event.target
      const value: string = target.value
      model.values[name] = value
      props.onChange?.(event)
    })

    if ((touched && error) || hasError) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    if (inputClassName) {
      innerClassName += ` ${inputClassName}`
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7 }}
          >
            {label}
          </label>
        )}
        <input
          type='text'
          className={innerClassName}
          maxLength={255}
          {...attributes}
          name={name}
          ref={setRef}
          onChange={onChange}
          value={model.values[name]}
          id={id}
        />
        {children}
      </div>
    )
  }),
)
