import { Collection, hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Model } from 'components/Form/Model'
import { TopBar } from 'components/SideBarLayout/components/TopBar'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { IInventoryItemScan } from 'contracts/inventory/interfaces/IInventoryItemScan'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IView } from '..'
import { InventoryScanItemLog } from './InventoryScanItemLog'
import { InventoryScanItems } from './InventoryScanItems'

interface Props {
  view: Model<IView>
}

export interface IInventoryItemScanWithHeader extends IInventoryItemScan {
  header?: boolean
}

@observer
export class InventoryScanContent extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly items: Collection<IInventoryItem> | null = null
  private readonly bundles: Collection<IInventoryBundle> | null = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    if (props.view.values.compoundId) {
      this.items = new Collection(`/api/${context.instance.id}/inventory/items`, {
        compoundId: props.view.values.compoundId,
        page: '0,1000',
      })
      this.bundles = new Collection(`/api/${context.instance.id}/inventory/bundles`, {
        compoundId: props.view.values.compoundId,
      })
    }
  }

  componentDidMount() {
    if (this.items && this.bundles) {
      this.disposers.push(this.items.init())
      this.disposers.push(this.bundles.init())
    }
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  render() {
    const compound = this.props.view.values.compoundId
      ? hermes.getFromStore<ICompound>(
          `/api/${this.context.instance.id}/accommodations/compounds/${this.props.view.values.compoundId}`,
          false,
        )
      : null
    return (
      <div className='md:pl-80 flex flex-col flex-1 h-screen relative'>
        <TopBar>
          <div className='flex items-center text-gray-500'>{compound?.label ?? '-'}</div>
        </TopBar>
        {this.bundles &&
          this.items &&
          this.props.view.values.compoundId &&
          this.props.view.values.residentId &&
          this.props.view.values.compoundId ===
            this.props.view.values.responsibleCompoundId && (
            <React.Fragment
              key={`${this.props.view.values.compoundId}/${this.props.view.values.residentId}`}
            >
              <InventoryScanItems
                view={this.props.view}
                items={this.items}
                bundles={this.bundles}
              />
              <InventoryScanItemLog
                residentId={this.props.view.values.residentId}
                compoundId={this.props.view.values.compoundId}
              />
            </React.Fragment>
          )}
        {!(this.props.view.values.compoundId && this.props.view.values.residentId) && (
          <div className='pt-20 px-6 w-fit mx-auto'>
            <div className='text-gray-500 p-6 bg-white rounded-lg'>
              Bitte wählen Sie ein Gelände und einen Bewohner aus
            </div>
          </div>
        )}
        {this.props.view.values.residentId &&
          ((this.props.view.values.responsibleCompoundId &&
            this.props.view.values.compoundId !==
              this.props.view.values.responsibleCompoundId) ||
            !this.props.view.values.responsibleCompoundId) && (
            <div className='pt-20 px-6 w-fit mx-auto'>
              <div className='text-gray-500 p-6 bg-white rounded-lg'>
                Diese Unterkunft ist nicht für diesen Bewohner zuständig
              </div>
            </div>
          )}
      </div>
    )
  }
}
