import { hermes } from '@byll/hermes'
import { Age } from 'components/Age'
import { Model } from 'components/Form/Model'
import { RoundIcon } from 'components/RoundIcon'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IFederalTransferResponse } from 'contracts/residents/interfaces/IFederalTransferResponse'
import { action, makeObservable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { FederalTransferListEntryInput } from './FederalTransferListEntryInput'

interface Props {
  entry: IFederalTransferResponse
  selectedTransfers: Set<string>
  color: string
  lockedAt: string | null | undefined
}

@observer
export class FederalTransferListEntry extends React.Component<Props, {}> {
  static contextType = AppContext

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private checkboxChangeHandler = () => {
    if (this.context.permissions.menu_residentFederalTransfers < 2) {
      return
    }
    if (this.props.selectedTransfers.has(this.props.entry.residentId)) {
      this.props.selectedTransfers.delete(this.props.entry.residentId)
    } else {
      this.props.selectedTransfers.add(this.props.entry.residentId)
    }
  }

  private openDialog = async (type: 'characteristics' | 'comment', value: string) => {
    const model: Model<{ label: string }> = new Model({ label: value })
    const save = await box.alert(
      'Bearbeiten',
      <FederalTransferListEntryInput model={model} type={type} />,
      { cancel: 'Abbrechen', confirm: 'Speichern' },
    )
    if (save) {
      if (type === 'comment') {
        runInAction(() => (this.props.entry.comment = model.values.label))
      }
      if (type === 'characteristics') {
        await hermes.patch(
          `/api/${this.context.instance.id}/residents/${this.props.entry.residentId}`,
          { federalTransferCharacteristics: model.values.label },
        )
      }
    }
  }

  render() {
    return (
      <tr className='hover:bg-slate-100'>
        <td className='px-6 py-4 whitespace-nowrap relative bg-pu'>
          <div
            className={`w-2 absolute top-0 left-0 bottom-0`}
            style={{ background: `${this.props.color}` }}
          />
          <div className='flex items-center'>
            {this.context.permissions.menu_residentFederalTransfers > 1 && (
              <input
                className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mr-4'
                type='checkbox'
                checked={this.props.selectedTransfers.has(this.props.entry.residentId)}
                onChange={this.checkboxChangeHandler}
              />
            )}
            <div className='flex-shrink-0 h-10 w-10'>
              <img
                className='h-10 w-10 rounded-full'
                src={getResidentImageSrc(
                  this.context.instance.id,
                  this.props.entry.imageId,
                  this.props.entry.sex,
                  'thumbnail',
                )}
                alt=''
              />
            </div>
            <div className='ml-4'>
              <div className='text-sm font-medium text-gray-900'>
                <Link
                  to={`/residents/${toJbpId(
                    +this.props.entry.residentId,
                  ).toLowerCase()}/overview`}
                >
                  {`${this.props.entry.lastName.toUpperCase()}, ${
                    this.props.entry.firstName
                  }`}
                </Link>
              </div>
              <div className='text-sm text-gray-500'>
                <Age
                  sex={this.props.entry.sex}
                  dateOfBirth={this.props.entry.dateOfBirth}
                />
              </div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>{this.props.entry.mId}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>
          <div className='flex gap-2 items-center'>
            {this.props.entry.comment}
            {!this.props.lockedAt &&
              this.context.permissions.menu_residentFederalTransfers > 1 && (
                <RoundIcon
                  onClick={() => this.openDialog('comment', this.props.entry.comment)}
                  icon='fas fa-pencil-alt'
                  tooltip={{ position: 'right', text: 'Bearbeiten' }}
                />
              )}
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>
          <div className='flex gap-2 items-center'>
            {this.props.entry.federalTransferCharacteristics}
            {!this.props.lockedAt &&
              this.context.permissions.menu_residentFederalTransfers > 1 && (
                <RoundIcon
                  onClick={() =>
                    this.openDialog(
                      'characteristics',
                      this.props.entry.federalTransferCharacteristics,
                    )
                  }
                  icon='fas fa-pencil-alt'
                  tooltip={{ position: 'right', text: 'Bearbeiten' }}
                />
              )}
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>
          {this.props.entry.jugendamtLabel || ''}
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm'>
          <div
            className={`py-1 px-2 rounded w-fit text-xs text-white ${
              this.props.entry.status &&
              (this.props.entry.status === 'Interner Aufenthalt'
                ? 'bg-yellow-500'
                : 'bg-blue-500')
            }`}
          >
            <b>{this.props.entry.status}</b>
          </div>
        </td>
      </tr>
    )
  }
}
