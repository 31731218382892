import { ITreeNode } from 'contracts/general/interfaces/ITreeNode'

export function expand(
  children: ITreeNode[],
  key: string,
  expanded: Set<string>,
): boolean {
  for (const child of children) {
    if (child.key === key) {
      return true
    }
    if (expand(child.children, key, expanded)) {
      expanded.add(child.key)
      return true
    }
  }
  return false
}
