import * as React from 'react'
import { observer } from 'mobx-react'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { RoundIcon } from 'components/RoundIcon'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { useLocation } from 'react-router'
import { box } from 'services/box'
import { Tooltip } from 'components/Tooltip'
import { Model } from 'components/Form/Model'
import { FamilyRelationPrompt } from './FamilyRelationPrompt'
import styles from '../../../styles.module.scss'
import { useResource } from 'helpers/useResource'
import { IFamilyGroupRelationship } from 'contracts/residents/interfaces/IFamilyGroupRelationship'
import { runInAction } from 'mobx'
import { z } from 'zod'
import { bookingTypes, HouseBan } from 'modules/Residents/helpers/bookingTypes'
import { SearchIcon } from '@heroicons/react/outline'

const LabelValidator = z.object({ label: z.string().min(1) })

interface Props {
  openFamilyId: string
  resident: IResidentSearchResult
  allowRemove: boolean
}

export const LinkedFamilyItem: React.FC<Props> = observer(
  ({ resident, allowRemove, openFamilyId: currentFamilyId }) => {
    const [removing, setRemoving] = React.useState<boolean>(false)
    const context = React.useContext(AppContext)
    const location = useLocation()
    const resource = useResource<IFamilyGroupRelationship>(
      `/api/${context.instance.id}/familyGroupRelationships/${currentFamilyId}-${resident.data.familyId}`,
    )

    async function onRemove(event) {
      event.preventDefault()
      if (removing) {
        return
      }

      try {
        const confirmed = await box.alert(
          'Sind Sie sicher?',
          `Möchten Sie die Familie von ${resident.firstName} ${resident.lastName} wirklich aus diesem erweiterten Familienverbund herausnehmen?`,
          { cancel: 'Abbrechen', confirm: 'Ja, Verknüpfung lösen' },
        )
        if (!confirmed) {
          return
        }
        setRemoving(true)
        await hermes.create(`/api/${context.instance.id}/familyGroups`, {
          familyIds: [resident.data.familyId],
        })
      } catch (e) {
        setRemoving(false)
        box.alert(
          'Entfernen fehlgeschlagen',
          'Die Verknüpfung konnte nicht gelöst werden. Bitte wenden Sie sich an einen Administrator.',
          { color: 'danger' },
        )
      }
    }

    function onClick(event) {
      if (event.isDefaultPrevented() || removing) {
        return
      }
      const to = location.pathname.substring(11).split('/')
      to.shift()
      const url = `/residents/${toJbpId(+resident.id).toLowerCase()}/${to.join('/')}`
      window.open(url, '_blank')
    }

    async function setRelationship(event) {
      event.preventDefault()
      if (removing) {
        return
      }
      const model = new Model<{ label: string }>(
        { label: resource.data?.label || '' },
        LabelValidator,
      )
      const promise = box.custom(
        <FamilyRelationPrompt
          model={model}
          close={(updated) => promise.close(updated)}
        />,
        { closable: true, context },
      )
      if ((await promise) && resource.data) {
        runInAction(() => (resource.data!.label = model.values.label))
      }
    }

    const relationship = resource.data?.label || null
    const bookingType = bookingTypes[resident.data.accommodation?.type || ''] || {
      label: '',
      color: '',
      icon: SearchIcon,
    }

    return (
      <div onClick={onClick} className={styles.member}>
        <div className='flex'>
          <div className='mr1 flex-auto truncate'>
            {`Familie ${resident.lastName.toUpperCase()}`}
          </div>
          <div className='flex-content'>
            {relationship && (
              <span
                onClick={setRelationship}
                className='inline-flex items-center rounded-full bg-indigo-500 px-2.5 py-0.5 text-xs font-medium text-white'
              >
                {relationship}
              </span>
            )}

            {!relationship && (
              <span
                onClick={setRelationship}
                className='has-tooltip inline-flex items-center rounded-full bg-indigo-500 px-1 py-0.5 text-xs font-medium text-white'
              >
                &nbsp;
                <i className='fas fa-arrows-alt-h' />
                &nbsp;
                <Tooltip position='left'>Beziehung festlegen</Tooltip>
              </span>
            )}
          </div>
        </div>

        {resident.data.accommodation ? (
          <div className={`${styles.residence} flex`}>
            <div className='flex-content mr-1'>
              <bookingType.icon
                className={`h-4 w-4 ${bookingType.color} rounded-full`}
                style={{ padding: 1 }}
                aria-hidden='true'
              />
            </div>
            {resident.data.accommodation.reason === 'Hausverbot' && (
              <div className='flex-content mr-1'>
                <HouseBan />
              </div>
            )}
            <div className='flex-auto overflow-hidden'>
              {resident.data.accommodation.label}
            </div>
          </div>
        ) : (
          <div className={styles.residence}>Nicht eingebucht</div>
        )}

        {allowRemove && (
          <div
            className={removing ? undefined : styles.showOnHover}
            style={{ position: 'absolute', top: -10, right: -10 }}
          >
            <RoundIcon
              color='danger'
              icon={removing ? 'fas fa-times fa-spin' : 'fas fa-times'}
              tooltip={{ text: <>Verknüpfung lösen</>, position: 'left' }}
              onClick={onRemove}
            />
          </div>
        )}
      </div>
    )
  },
)
