import { hermes } from '@byll/hermes'
import { getRoomLockAt } from 'contracts/accommodations/helpers/getRoomLockAt'
import { IBuildingFloor } from 'contracts/accommodations/interfaces/IBuildingFloor'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import { getOccupancyColor } from 'modules/ResidentsOccupancy/helpers/getOccupancyColor'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { RoomDetailsDialog } from './RoomDetailsDialog'
import { EquipmentIcon } from '../EquipmentIcon'

export const FLOOR_PLAN_ROOM_OVERLAY_ID = 'FLOOR_PLAN_ROOM_OVERLAY'

interface Props {
  floor: IBuildingFloor
  occupancies: Map<string, IOccupancy>
  at: Dayjs
}

@observer
export class BuildingFloorRoomOverlay extends React.Component<Props, {}> {
  static contextType = AppContext

  mapRoom = (r: IBuildingFloor['legend']['rooms'][0]) => {
    const room = hermes.getFromStore<IRoom>(
      `/api/${this.context.instance.id}/accommodations/rooms/${r.id}`,
      false,
    )
    const occupancy = this.props.occupancies.get(r?.id)
    if (!occupancy || room?.floorId !== this.props.floor.id) {
      return null
    }
    const lock = getRoomLockAt(room.locks, this.props.at)

    return (
      <div key={r.id} className='absolute' style={{ top: r.y, left: r.x }}>
        <div
          className='flex cursor-pointer'
          onClick={() => this.showRoomDetails(room, occupancy)}
        >
          <div
            className='px-2 py-1 bg-gray-300 text-sm text-gray-600 whitespace-nowrap flex-content'
            style={{ borderRadius: '6px 0 0 6px' }}
          >
            {room.label}
          </div>
          <div
            className={`${getOccupancyColor(
              +occupancy.occupied,
              +occupancy.capacity,
              lock !== null,
            )} px-2 py-1 text-sm text-white whitespace-nowrap flex-content`}
            style={{ borderRadius: '0 6px 6px 0' }}
          >
            {`${occupancy.occupied} / ${room.capacity}`}
          </div>
        </div>
        <div className='text-center pt-1 text-gray-500'>
          {lock && (
            <span className='mr-1 text-red-500'>
              <i className='fas fa-lock' />
            </span>
          )}
          {room.equipment.split(',').map((e) =>
            EquipmentIcon[e] ? (
              <span key={e} className='mr-1'>
                {EquipmentIcon[e]}
              </span>
            ) : null,
          )}
        </div>
      </div>
    )
  }

  private showRoomDetails = (room: IRoom, occupancy: IOccupancy) => {
    const promise = box.custom(
      <RoomDetailsDialog
        room={room}
        occupancy={occupancy}
        at={this.props.at}
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context },
    )
  }

  render() {
    return (
      <div className='absolute top-0 left-0' id={FLOOR_PLAN_ROOM_OVERLAY_ID}>
        {this.props.floor.legend.rooms.map(this.mapRoom)}
      </div>
    )
  }
}
