import { IBooking } from 'contracts/residents/interfaces/IBooking'

type Booking = IBooking & { timeline: 'past' | 'present' | 'future'; buildingId?: string }

export function getEarliestBookingInCurrentResponsibility(
  bookings: Booking[],
): Booking | null {
  let stay: Booking | null = null
  for (const b of bookings) {
    if (b.timeline === 'present' && b.type === 'internal-residence') {
      stay = b
      continue
    }
    if (!stay) {
      continue
    }
    if (b.type === 'responsibility-begin') {
      return stay
    }
    if (b.type === 'internal-residence') {
      stay = b
    }
  }
  return null
}
