import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import * as React from 'react'
import { MigrationList } from './components/MigrationList'

const migrationOptions: InputSelectOption[] = [
  { value: '', label: 'Bitte wählen...' },
  {
    value: 'countryOfBirth->countryOfBirthId',
    label: 'countryOfBirth->countryOfBirthId',
  },
  { value: 'nationality->nationalityId', label: 'nationality->nationalityId' },
  {
    value: 'nativeLanguage->languageIds',
    label: 'nativeLanguage->languageIds (Multiselect)',
  },
]

interface Props {}

@observer
export class ResidentMigration extends React.Component<Props, {}> {
  private readonly model = new Model<{ type: string }>({ type: '' })

  render() {
    return (
      <div className='pt-20 px-6 min-h-screen bg-gray-100'>
        <InputSelect model={this.model} name='type' options={migrationOptions} />
        {this.model.values.type && (
          <MigrationList key={this.model.values.type} type={this.model.values.type} />
        )}
      </div>
    )
  }
}
