import { Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Message } from 'components/Message'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { isStammCompound } from 'helpers/isStamm'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ResidentInventoryScan } from './components/ResidentInventoryScan'
import { ResidentInventoryScanLog } from './components/ResidentInventoryScanLog'

interface Props {
  resident: IResident
}

@observer
export class InventoryTab extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly searchResult: Resource<IResidentSearchResult>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.searchResult = new Resource(
      `/api/${context.instance.id}/residentSearchResults/${props.resident.id}`,
    )
  }

  componentDidMount() {
    this.disposers.push(this.searchResult.init({ readOnly: true }))
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  render() {
    if (!this.searchResult.data) {
      return <Spinner />
    }
    const compoundId =
      this.searchResult.data.data.accommodation?.building?.compoundId ?? null
    const isAllowed =
      this.context.permissions.resident_inventoryTab >= 2 ||
      (this.context.permissions.resident_inventoryTab === 1 &&
        isStammCompound(compoundId || ''))

    if (!compoundId) {
      return (
        <CardColumnCard
          title='Inventar'
          subtitle='Hygienemittelausgabe und Ausgabehistorie'
        >
          <Message color='danger'>
            Für diesen Bewohner ist keine aktuelle Unterkunft eingetragen
          </Message>
        </CardColumnCard>
      )
    } else if (!isAllowed) {
      return (
        <CardColumnCard
          title='Inventar'
          subtitle='Hygienemittelausgabe und Ausgabehistorie'
        >
          <Message color='danger'>
            Sie haben keine Berechtigung für diese Ansicht. Entweder ist dieser Tab ist
            nicht für Sie freigeschaltet oder der Bewohner befindet sich außerhalb Ihrer
            Stammgelände.
          </Message>
        </CardColumnCard>
      )
    } else {
      return (
        <CardColumnCard
          title='Inventar'
          subtitle='Hygienemittelausgabe und Ausgabehistorie'
        >
          <div className='grid grid-flow-row grid-rows-2 gap-6'>
            <ResidentInventoryScan
              resident={this.props.resident}
              compoundId={compoundId}
            />
            <ResidentInventoryScanLog
              resident={this.searchResult.data}
              compoundId={compoundId}
            />
          </div>
        </CardColumnCard>
      )
    }
  }
}
