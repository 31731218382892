import { SortableHandle } from 'react-sortable-hoc'
import * as React from 'react'

export const KeySortableHandle: React.ComponentClass<{}> = SortableHandle(() => {
  return (
    <span className='text-gray-300 hover:text-gray-500 cursor-move'>
      <i className='fa fa-bars fa-sm' />
    </span>
  )
})
