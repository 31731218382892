import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { InputCompound } from 'components/Form/components/InputCompound'
import { action, makeObservable } from 'mobx'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { Message } from 'components/Message'

interface Props {
  model: Model<IResidentSearchResultsFilter>
  readOnly?: boolean
}

@observer
export class SelectResponsibility extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setResponsibilityActive = () => {
    this.props.model.values.responsibleScope = 'active'
  }
  @action private setResponsibilityInactive = () => {
    this.props.model.values.responsibleScope = 'inactive'
  }
  @action private setResponsibilityAll = () => {
    this.props.model.values.responsibleScope = null
  }

  @action private setVisitPresent = () => {
    this.props.model.values.visit = 'present'
  }
  @action private setVisitAbsent = () => {
    this.props.model.values.visit = 'absent'
  }
  @action private setVisitAll = () => {
    this.props.model.values.visit = null
  }

  @action private setDeleted = () => {
    this.props.model.values.deleted = 'yes'
  }
  @action private setNotDeleted = () => {
    this.props.model.values.deleted = 'no'
  }
  @action private setBothDeleted = () => {
    this.props.model.values.deleted = null
  }

  @action private reset = () => {
    this.props.model.values.responsibleCompoundId =
      this.context.permissions.menu_resident_search < 3 ? '0' : ''
    this.props.model.values.responsibleScope = 'active'
    this.props.model.values.visit = null
    this.props.model.values.deleted = this.context.permissions.host_lfgb ? null : 'no'
  }

  render() {
    let label = 'Zuständigkeit'
    let color: string = 'text-gray-500'
    if (this.props.model.values.responsibleCompoundId) {
      label =
        this.props.model.values.responsibleCompoundId === '0'
          ? 'Meine Zuständigkeit'
          : hermes.getFromStore<ICompound>(
              `/api/${this.context.instance.id}/accommodations/compounds/${this.props.model.values.responsibleCompoundId}`,
              false,
            )?.label ?? 'Gewählte Zuständigkeit'
      color = 'text-blue-500'
      if (this.props.model.values.responsibleScope === 'active') {
        label = 'Aktive: ' + label
      } else if (this.props.model.values.responsibleScope === 'inactive') {
        label = 'Inaktive: ' + label
      }
    } else if (this.props.model.values.responsibleScope === 'active') {
      label = 'Aktive'
      color = 'text-blue-500'
    } else if (this.props.model.values.responsibleScope === 'inactive') {
      label = 'Inaktive'
      color = 'text-blue-500'
    }
    if (this.props.model.values.visit === 'present') {
      if (label === 'Zuständigkeit') {
        label = ''
      }
      label = label + ' Anwesend'
      color = 'text-blue-500'
    }
    if (this.props.model.values.visit === 'absent') {
      if (label === 'Zuständigkeit') {
        label = ''
      }
      label = label + ' Abwesend'
      color = 'text-blue-500'
    }
    if (this.props.model.values.deleted === 'yes') {
      if (label === 'Zuständigkeit') {
        label = 'Archiviert'
      }
      color = 'text-blue-500'
    }

    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {this.props.model.values.deleted === 'yes' && (
            <span className='mr-1'>
              <i className='fas fa-archive' />
            </span>
          )}
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='cursor-default origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='grid grid-col-2 gap-4'>
              {this.props.readOnly && (
                <Message className='col-span-2' color='danger'>
                  Diese Einstellung wurde von Ihrem Administrator vorgegeben.
                </Message>
              )}

              <InputCompound
                multiple
                disabled={this.props.readOnly}
                className='col-span-2'
                model={this.props.model}
                name='responsibleCompoundId'
                label='Gelände'
                onlyStamm={this.context.permissions.menu_resident_search < 3}
                stammOption
                notNull={this.context.permissions.menu_resident_search < 3}
              />
              <div className='flex col-span-2'>
                <Button
                  disabled={this.props.readOnly}
                  onClick={this.setResponsibilityActive}
                  color={
                    this.props.model.values.responsibleScope === 'active'
                      ? 'primary'
                      : 'secondary'
                  }
                  outline={this.props.model.values.responsibleScope !== 'active'}
                  className='flex-auto'
                  style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                >
                  Aktive
                </Button>
                <Button
                  disabled={this.props.readOnly}
                  onClick={this.setResponsibilityInactive}
                  color={
                    this.props.model.values.responsibleScope === 'inactive'
                      ? 'primary'
                      : 'secondary'
                  }
                  outline={this.props.model.values.responsibleScope !== 'inactive'}
                  className='flex-auto'
                  style={{
                    borderRadius: 0,
                    padding: '9px 0',
                    borderRight: 'none',
                    borderLeft: 'none',
                  }}
                >
                  Inaktive
                </Button>
                <Button
                  disabled={this.props.readOnly}
                  onClick={this.setResponsibilityAll}
                  color={
                    !this.props.model.values.responsibleScope ? 'primary' : 'secondary'
                  }
                  outline={!!this.props.model.values.responsibleScope}
                  className='flex-auto'
                  style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                >
                  Alle
                </Button>
              </div>
              {this.context.permissions.resident_showPresence && (
                <div className='flex col-span-2'>
                  <Button
                    disabled={this.props.readOnly}
                    onClick={this.setVisitPresent}
                    color={
                      this.props.model.values.visit === 'present'
                        ? 'primary'
                        : 'secondary'
                    }
                    outline={this.props.model.values.visit !== 'present'}
                    className='flex-auto'
                    style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                  >
                    Anwesend
                  </Button>
                  <Button
                    disabled={this.props.readOnly}
                    onClick={this.setVisitAbsent}
                    color={
                      this.props.model.values.visit === 'absent' ? 'primary' : 'secondary'
                    }
                    outline={this.props.model.values.visit !== 'absent'}
                    className='flex-auto'
                    style={{
                      borderRadius: 0,
                      padding: '9px 0',
                      borderRight: 'none',
                      borderLeft: 'none',
                    }}
                  >
                    Abwesend
                  </Button>
                  <Button
                    disabled={this.props.readOnly}
                    onClick={this.setVisitAll}
                    color={!this.props.model.values.visit ? 'primary' : 'secondary'}
                    outline={!!this.props.model.values.visit}
                    className='flex-auto'
                    style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                  >
                    Alle
                  </Button>
                </div>
              )}
              <div className='flex col-span-2'>
                <Button
                  disabled={this.props.readOnly}
                  onClick={this.setNotDeleted}
                  color={
                    this.props.model.values.deleted === 'no' ? 'primary' : 'secondary'
                  }
                  outline={this.props.model.values.deleted !== 'no'}
                  className='flex-auto'
                  style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                >
                  Normal
                </Button>
                <Button
                  disabled={this.props.readOnly}
                  onClick={this.setDeleted}
                  color={
                    this.props.model.values.deleted === 'yes' ? 'primary' : 'secondary'
                  }
                  outline={this.props.model.values.deleted !== 'yes'}
                  className='flex-auto'
                  style={{
                    borderRadius: 0,
                    padding: '9px 0',
                    borderRight: 'none',
                    borderLeft: 'none',
                  }}
                >
                  Archiviert
                </Button>
                <Button
                  disabled={this.props.readOnly}
                  onClick={this.setBothDeleted}
                  color={!this.props.model.values.deleted ? 'primary' : 'secondary'}
                  outline={!!this.props.model.values.deleted}
                  className='flex-auto'
                  style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                >
                  Alle
                </Button>
              </div>

              {!this.props.readOnly && (
                <Menu.Item>
                  <div className='col-span-2 flex'>
                    <Button
                      color='secondary'
                      outline
                      className='flex-auto'
                      onClick={this.reset}
                      style={{ borderRadius: '6px 0 0 6px' }}
                    >
                      Zurücksetzen
                    </Button>
                    <Button
                      color='primary'
                      className='flex-content'
                      style={{ borderRadius: '0 6px 6px 0' }}
                    >
                      OK
                    </Button>
                  </div>
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
