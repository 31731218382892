import { Model } from 'components/Form/Model'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  model: Model<ITodoSearchResultFilter>
}

@observer
export class DashboardTodosFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setDue = () => {
    this.props.model.values.status = 'due'
    this.props.model.values.link = 'assignee'
    this.props.model.values.page = '0,10'
    this.props.model.values.listId = 'user-' + this.context.user.id
  }

  @action private setScheduled = () => {
    this.props.model.values.status = 'scheduled'
    this.props.model.values.link = 'assignee'
    this.props.model.values.page = '0,10'
    this.props.model.values.listId = 'user-' + this.context.user.id
  }
  @action private setDone = () => {
    this.props.model.values.status = 'done'
    this.props.model.values.link = 'assignee'
    this.props.model.values.page = '0,10'
    this.props.model.values.listId = 'user-' + this.context.user.id
  }
  @action private setWatcher = () => {
    this.props.model.values.status = null
    this.props.model.values.link = 'watcher'
    this.props.model.values.page = '0,10'
    this.props.model.values.listId = 'user-' + this.context.user.id
  }
  @action private setAssignable = () => {
    this.props.model.values.status = 'all'
    this.props.model.values.listId = this.context.defaults.ticketTodoListId
    this.props.model.values.link = null
    this.props.model.values.page = '0,10'
  }

  render() {
    return (
      <div className={`text-gray-500 mt-2 pb-4`}>
        <span
          onClick={this.setDue}
          className={
            this.props.model.values.status === 'due'
              ? 'text-blue-500 cursor-pointer'
              : 'hover:text-black cursor-pointer'
          }
        >
          Fällig
        </span>
        &nbsp;|&nbsp;
        <span
          onClick={this.setScheduled}
          className={
            this.props.model.values.status === 'scheduled'
              ? 'text-blue-500 cursor-pointer'
              : 'hover:text-black cursor-pointer'
          }
        >
          Geplant
        </span>
        &nbsp;|&nbsp;
        <span
          onClick={this.setDone}
          className={
            this.props.model.values.status === 'done'
              ? 'text-blue-500 cursor-pointer'
              : 'hover:text-black cursor-pointer'
          }
        >
          Erledigt
        </span>
        &nbsp;|&nbsp;
        <span
          onClick={this.setWatcher}
          className={
            this.props.model.values.status === null
              ? 'text-blue-500 cursor-pointer'
              : 'hover:text-black cursor-pointer'
          }
        >
          Beobachtet
        </span>
        {this.context.permissions.dashboard_tickets > 1 && <span>&nbsp;|&nbsp;</span>}
        {this.context.permissions.dashboard_tickets > 1 && (
          <span
            onClick={this.setAssignable}
            className={
              this.props.model.values.listId === this.context.defaults.ticketTodoListId
                ? 'text-blue-500 cursor-pointer'
                : 'hover:text-black cursor-pointer'
            }
          >
            Tickets
          </span>
        )}
      </div>
    )
  }
}
