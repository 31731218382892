import * as React from 'react'
import styles from '../styles.module.scss'

interface Props {
  width?: number
  height?: number
}

export const PdfDocumentError: React.FC<Props> = (props) => (
  <div
    className={`${styles.page} ${!props.width ? styles.empty : ''}`}
    style={
      props.width
        ? { width: props.width, height: props.height, padding: 5 }
        : { padding: 5 }
    }
  >
    <div className='mt-6 text-center'>
      <strong>Ohje, wir können das PDF leider nicht darstellen.</strong>
      <br />
      <br />
      Wenden Sie sich an einen Administrator,
      <br />
      falls Sie Hilfe benötigen.
    </div>
    {props.children}
  </div>
)
