import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Button } from 'components/Form/components/Button'
import {
  IBooking,
  INFO_BOOKING_REASON_END_OF_STAY,
} from 'contracts/residents/interfaces/IBooking'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ResidentAccomodationList } from './components/ResidentAccomodationList'
import { accommodationForms } from './helpers/accommodationForms'
import { Callout } from 'components/Callout'
import { ButtonDropdown } from 'components/ButtonDropdown'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { dayjs } from 'helpers/dayjs'
import { Tooltip } from 'components/Tooltip'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { isStammCompound } from 'helpers/isStamm'
import { ABRECHNUNGSMODUS_AWUM } from 'contracts/costCoverages/interfaces/abrechnungsmodus'
import { ChangeBillingStatusForm } from './components/ChangeBillingStatusForm'
import { isAwumInstance } from 'contracts/general/helpers/instanceIds'

const responsibilityOptions: InputSelectOption[] = [
  { value: 'responsibility-begin', label: 'Zuständigkeit festlegen' },
  { value: 'responsibility-end', label: 'Zuständigkeit beenden' },
]
interface Props {
  resident: IResident
}

@observer
export class AccommodationTab extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly members: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly searchResult: Resource<IResidentSearchResult>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.members = new Collection(`/api/${context.instance.id}/residentSearchResults`, {
      familyId: props.resident.familyId,
      fields: 'bookings,accommodation,lastScanAtDe',
      sort: 'dateOfBirth,asc',
    })
    this.searchResult = new Resource(
      `/api/${context.instance.id}/residentSearchResults/${props.resident.id}`,
    )
  }

  componentDidMount() {
    this.disposers.push(this.members.init({ readOnly: true }))
    this.disposers.push(this.searchResult.init({ readOnly: true }))
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onCreateBooking = async (
    type:
      | 'internal-residence'
      | 'internal-reservation'
      | 'external-residence'
      | 'responsibility-begin'
      | 'responsibility-end'
      | 'info',
    reason?: string,
  ) => {
    const now = dayjs()
    const booking: IBooking = {
      id: '',
      instanceId: this.context.instance.id,
      type,
      compoundId: null,
      residentId: this.props.resident.id,
      roomId: null,
      bed: null,
      reason: reason || '',
      comment: '',
      beginAt: now.add(-1, 'minute').toISOString(),
      endAt: null,
      label: '',
      extra: {},
    }
    if (type === 'responsibility-begin') {
      booking.beginAt = now.add(-2, 'minute').toISOString()
      if (
        this.context.permissions.resident_setResponsibility === 1 &&
        isStammCompound(this.context.defaults.responsibilityCompoundId || '')
      ) {
        booking.compoundId = this.context.defaults.responsibilityCompoundId
      } else if (this.context.permissions.resident_setResponsibility === 2) {
        booking.compoundId = this.context.defaults.responsibilityCompoundId
      }
    }
    if (type === 'responsibility-end') {
      // User ends responsibility (-3 mins), so that he can begin a new
      // responsibility immediately (-2 mins) and than a new booking (-1 min)
      booking.beginAt = now.add(-3, 'minute').toISOString()
    }
    const Form = accommodationForms[type + (type === 'info' ? `-${reason}` : '')]
    const promise = box.custom(
      <Form
        booking={booking}
        members={this.members}
        resident={this.props.resident}
        onClose={(val?: any) => promise.close(val)}
        bookings={this.searchResult.data?.data.bookings || undefined}
        checkInPermission={this.context.permissions.resident_internalCheckInOut}
      />,
      { context: this.context },
    )
    await promise
  }

  private showBillingStatusDialog = () => {
    if (this.context.permissions.booking_feeStatus < 2) {
      box.alert(
        'Keine Berechtigung',
        'Ein Administrator kann diesen Dialog mit der Berechtigung "AWUM: Gebührenstatus von Buchungen" => "Anzeigen und ändern" aktivieren.',
        { color: 'danger' },
      )
      return
    }
    if (!this.searchResult.data) {
      box.alert(
        'Ledefehler',
        'Beim Laden der Bewohnerdaten ist ein Fehler aufgetreten.',
        { color: 'danger' },
      )
      return
    }
    const promise = box.custom(
      <ChangeBillingStatusForm
        resident={this.searchResult.data}
        onClose={(val?: any) => promise.close(val)}
      />,
      { context: this.context },
    )
  }

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div
          className='hidden lg:block pr-12 pt-4 text-right'
          style={{ flex: '0 0 200px' }}
        >
          <span className='text-gray-900 text-lg'>Belegung</span>
          <br />
          <span className='text-sm text-gray-400'>
            Unterbringung und Wohnstatus des Bewohners
          </span>
        </div>
        <div className='flex-auto pt-4 relative min-w-0'>
          <div className='-mr-4 flex gap-2'>
            {(!isAwumInstance(this.context.instance.id) ||
              this.context.permissions.resident_internalCheckInOut > 0) && (
              <Button
                color='warning'
                className='has-tooltip'
                onClick={() => this.onCreateBooking('internal-residence')}
              >
                <i className='fas fa-plus' /> Belegung
                <Tooltip>
                  In einen Raum einbuchen
                  <br />
                  oder verlegen.
                </Tooltip>
              </Button>
            )}
            <Button
              color='primary'
              className='has-tooltip'
              onClick={() => this.onCreateBooking('external-residence')}
            >
              <i className='fas fa-plus' /> Abwesenheit
              <Tooltip>
                Krankenhausaufenthalt, Urlaub oder
                <br />
                andere Abwesenheiten dokumentieren.
              </Tooltip>
            </Button>
            {this.context.permissions.abrechnungsmodus !== ABRECHNUNGSMODUS_AWUM && (
              <Button
                color='secondary'
                className='has-tooltip'
                onClick={() => this.onCreateBooking('internal-reservation')}
              >
                <i className='fas fa-plus' /> Reservierung
                <Tooltip>
                  Zimmer reservieren. Bewohner wird noch
                  <br />
                  nicht eingebucht, aber das Zimmer wird blockiert,
                  <br />
                  damit es nicht anderweitig belegt wird.
                </Tooltip>
              </Button>
            )}
            {!this.context.permissions.resident_automatic_setResponsibility && (
              <ButtonDropdown
                onSelect={(option) => this.onCreateBooking(option.value)}
                options={responsibilityOptions}
                className='has-tooltip'
                color='success'
              >
                Zuständigkeit <i className='fas fa-caret-down' />
                <Tooltip>
                  Festlegen, welche Unterkunft für
                  <br />
                  diesen Bewohner zuständig ist.
                </Tooltip>
              </ButtonDropdown>
            )}
            {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_AWUM && (
              <Button color='purple' onClick={this.showBillingStatusDialog}>
                <i className='fas fa-wrench' /> Gebührenstatus
              </Button>
            )}
            {this.context.permissions.resident_automatic_setResponsibility && (
              <Button
                color='success'
                onClick={() =>
                  this.onCreateBooking('info', INFO_BOOKING_REASON_END_OF_STAY)
                }
              >
                Aufenthalt beenden
              </Button>
            )}
          </div>

          {/* List of bookings */}
          {this.searchResult.data?.data.bookings && (
            <ResidentAccomodationList
              bookings={this.searchResult.data.data.bookings!}
              resident={this.props.resident}
              members={this.members}
            />
          )}

          {/* Empty message */}
          {this.searchResult.data?.data.bookings &&
            this.searchResult.data.data.bookings.length === 0 && (
              <div
                className='absolute rounded-md bg-gray-100 border border-gray-300 bottom-4 right-4 left-0 flex overflow-hidden'
                style={{ top: 94 }}
              >
                <Callout
                  icon='fas fa-search'
                  title='Bisher keine Aufenthalte hinterlegt'
                  subtitle='Hinterlegen Sie den ersten Aufenthalt mit den Buttons oben'
                />
              </div>
            )}
        </div>
      </div>
    )
  }
}
