export function getResidentSexDe(sex: string | null | undefined) {
  if (sex === 'male') {
    return 'männlich'
  } else if (sex === 'female') {
    return 'weiblich'
  } else if (sex === 'divers') {
    return 'divers'
  } else {
    return ''
  }
}
