import { ICostCoverageSignature } from 'contracts/costCoverages/interfaces/ICostCoverageSignature'
import * as React from 'react'
import styles from './styles.module.scss'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { DocumentCreator, IPage } from 'modules/Pdf/helpers/DocumentCreator'
import { Wait } from 'modules/Pdf/components/Wait'
import { CostCoverageSignatureHeader } from './components/CostCoverageSignatureHeader'
import { CostCoverageSignatureFooter } from './components/CostCoverageSignatureFooter'

const A4_WIDTH = 840
const PADDING_X = 80

const A4_HEIGHT = 1188
const PADDING_TOP = 60
const HEADER_HEIGHT = 84
const FOOTER_HEIGHT = 64
const PADDING_BOTTOM = 40
const SAFETY = 10

interface Props {
  signature: ICostCoverageSignature
  instanceId: string
  readOnly?: boolean // true: for pdf generation/print
}

export class CostCoverageSignature extends React.Component<
  Props,
  { pages: IPage[] | null }
> {
  private readonly doc = new DocumentCreator({
    width: A4_WIDTH - 2 * PADDING_X,
    height:
      A4_HEIGHT - PADDING_TOP - HEADER_HEIGHT - FOOTER_HEIGHT - PADDING_BOTTOM - SAFETY,
  })

  constructor(props: Props) {
    super(props)
    this.state = { pages: null }
    const signature = props.signature

    // Check-out dates
    const checkOutDates: (string | null)[] = []
    for (const r of signature.residents) {
      if (r.sections.find((s) => !!s.internal)) {
        checkOutDates.push(
          r.sections.find((s) => !!s.internal?.hasCheckOut)?.endDate ?? null,
        )
      }
    }
    let checkOutText = ''
    if (checkOutDates.every((d) => d !== null)) {
      checkOutDates.sort()
      checkOutText = ` (Auszug: ${dayjs(checkOutDates[checkOutDates.length - 1]).format(
        'DD.MM.YYYY',
      )})`
    }

    /* Caption */
    this.doc.add(
      <div className='font-bold text-center pt-8' style={{ fontSize: '22px' }}>
        Bestätigung der Unterkunftsplatznutzung
        <br />
        {!!signature.costCoverage.aktenzeichen && (
          <span className='text-sm'>
            Geschäftszeichen KÜ: {signature.costCoverage.aktenzeichen}
          </span>
        )}
      </div>,
    )

    /* Haushaltsvorstend */
    this.doc.add(
      <>
        <div className='flex gap-4 pt-8 text-sm'>
          <div className='flex-[0_0_120px]'>Haushaltsvorstand</div>
          <div className='flex-[1_1_1px]'>Name</div>
          <div className='flex-[1_1_1px]'>Vorname</div>
          <div className='flex-[0_0_80px] text-right'>Geb.-Datum</div>
        </div>
        <div className='flex gap-4 text-sm'>
          <div className='flex-[0_0_120px]' />
          <div className='flex-[1_1_1px] font-bold truncate'>
            {signature.residents[0]?.lastName || ''}
          </div>
          <div className='flex-[1_1_1px] font-bold truncate'>
            {signature.residents[0]?.firstName || ''}
          </div>
          <div className='flex-[0_0_80px] font-bold text-right'>
            {signature.residents[0]?.dateOfBirth
              ? dayjs(signature.residents[0].dateOfBirth).format('DD.MM.YYYY')
              : '-'}
          </div>
        </div>
      </>,
    )

    /* Haushaltsangehörige */
    for (let i = 1; i < signature.residents.length; i++) {
      this.doc.add(
        <>
          {i === 1 && signature.residents.length > 1 && (
            <div className='col-span-4 pt-4 text-sm'>
              Ich habe mit meinen Haushaltsangehörigen
            </div>
          )}
          <div className='flex gap-4 text-sm'>
            <div className='flex-[0_0_120px]' />
            <div className='flex-[1_1_1px] font-bold truncate'>
              {signature.residents[i]?.lastName || ''}
            </div>
            <div className='flex-[1_1_1px] font-bold truncate'>
              {signature.residents[i]?.firstName || ''}
            </div>
            <div className='flex-[0_0_80px] font-bold text-right'>
              {signature.residents[i]?.dateOfBirth
                ? dayjs(signature.residents[i].dateOfBirth).format('DD.MM.YYYY')
                : '-'}
            </div>
          </div>
        </>,
      )
    }

    /* Zeitraum / Gelände */
    this.doc.add(
      <>
        <div className='text-sm pt-6'>
          {signature.residents.length <= 1 && (
            <>Ich habe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
          )}
          vom {dayjs(signature.beginDate).format('DD.MM.YYYY')}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; bis{' '}
          {dayjs(signature.endDate).format('DD.MM.YYYY')}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{checkOutText}
          den Unterkunftsplatz/die Unterkunftsplätze in der Unterkunft/Einrichtung{' '}
          {signature.costCoverage.accommodation.name},{' '}
          {signature.costCoverage.accommodation.address} in vollem Umfang genutzt.
        </div>
      </>,
    )

    /* Abwesenheiten */
    let i = 0
    for (const r of signature.residents) {
      for (let j = 0; j < r.sections.length; j++) {
        const s = r.sections[j]
        if (!s.external) {
          continue
        }
        const endDate = dayjs(s.endDate)
        i++
        this.doc.add(
          <>
            {i === 1 && (
              <div className='flex gap-4 pt-6 text-sm'>
                <div className='flex-[0_0_160px] font-bold'>Abwesenheiten</div>
                <div className='flex-[0_0_140px]'>Name</div>
                <div className='flex-auto'>Vorname</div>
                <div className='flex-auto text-right'>Grund</div>
              </div>
            )}
            <div className='flex gap-4 text-sm'>
              <div className='flex-[0_0_160px] '>
                {dayjs(s.beginDate).format('DD.MM.YYYY')} - {endDate.format('DD.MM.YYYY')}
              </div>
              <div className='flex-[0_0_140px] max-w-[140px] font-bold truncate'>
                {r.lastName}
              </div>
              <div className='flex-auto max-w-[180px] font-bold truncate'>
                {r.firstName}
              </div>
              <div className='flex-auto truncate text-right'>
                {s.external.reason || 'Taggenau'}{' '}
                {r.sections[j - 1]?.internal?.hasCheckOut
                  ? `(Auszug: ${dayjs(r.sections[j - 1].endDate).format('DD.MM.YYYY')})`
                  : ''}
              </div>
            </div>
          </>,
        )
      }
    }

    /* Signature */
    this.doc.add(
      <div className='flex text-sm pt-16 break-inside-avoid'>
        <div className='flex-auto text-right mr-4'>Datum, Unterschrift</div>
        <div className='flex-[0_0_330px] border-b-2 border-black'>&nbsp;</div>
      </div>,
    )

    /* Stamp */
    this.doc.add(
      <>
        <div className='text-sm pt-8 break-inside-avoid'>
          Nach bestem Wissen und Gewissen: Wir versichern, dass der / die Bewohnenden an
          den Tagen, für den wir den Tagessatz in voller Höhe berechnet haben anwesend war
          und wir die vereinbarte Leistung erbracht haben.
        </div>
        <div className='flex text-sm mt-6 relative pb-[88px] break-inside-avoid'>
          <div className='flex-auto rounded-lg mr-8 border-2 border-black h-24'>
            <div className='absolute text-center bottom-[60px]' style={{ width: 266 }}>
              Unterkunftsstempel
            </div>
          </div>
          <div className='flex-[0_0_330px]'>
            <div className='border-b-2 border-black h-16'>&nbsp;</div>
            <div className='pt-2'>Datum, Unterschrift des Unterkunftsgebers</div>
          </div>
        </div>
      </>,
    )
  }

  componentDidMount(): void {
    if (this.props.readOnly) {
      this.doc.detectHeights().then(() => {
        this.setState({ pages: this.doc.getPages() })
      })
    }
  }

  render() {
    const { signature, instanceId, readOnly } = this.props
    if (readOnly && !this.state.pages) {
      return <Wait />
    }

    if (signature.occupancyProblems.length > 0) {
      return (
        <div className={styles.page}>
          {/* Header */}
          <CostCoverageSignatureHeader
            signature={signature}
            instanceId={instanceId}
            pageNumber={1}
            totalNumberOfPages={1}
          />

          {/* Caption */}
          <div className='font-bold text-center mt-9' style={{ fontSize: '22px' }}>
            Bestätigung der Unterkunftsplatznutzung
            <br />
            {!!signature.costCoverage.aktenzeichen && (
              <span className='text-sm'>
                Geschäftszeichen KÜ: {signature.costCoverage.aktenzeichen}
              </span>
            )}
          </div>

          {/* Error */}
          <div className='border-2 border-red-500 p-10 text-red-500 rounded-md mt-10 text-center'>
            Dieser UKN kann nicht erstellt werden, weil keine gültige Belegung vorliegt.
          </div>
        </div>
      )
    }

    if (!readOnly) {
      return (
        <div className={styles.page}>
          {/* Header */}
          <CostCoverageSignatureHeader
            signature={signature}
            instanceId={instanceId}
            pageNumber={1}
            totalNumberOfPages={1}
          />

          {/* Content */}
          {this.doc.render()}

          {/* Footer */}
          <CostCoverageSignatureFooter />
        </div>
      )
    }

    return (
      <>
        {this.state.pages!.map((page, p) => (
          <div className={styles.page} key={p}>
            {/* Header */}
            <CostCoverageSignatureHeader
              signature={signature}
              instanceId={instanceId}
              pageNumber={p + 1}
              totalNumberOfPages={this.state.pages!.length}
              readOnly={readOnly}
            />

            {/* Content */}
            {this.doc.render(page.elements)}

            {/* Footer */}
            <CostCoverageSignatureFooter />
          </div>
        ))}
      </>
    )
  }
}
