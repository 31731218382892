import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { InputDate } from 'components/Form/components/InputDate'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { InputText } from 'components/Form/components/InputText'
import { z } from 'zod'
import { isTime } from 'contracts/general/helpers/isTime'
import { dayjs } from 'helpers/dayjs'
import { isPastDate } from '../helpers/isPastDate'
import { box } from 'services/box'
import { InputBuildingListFoldable } from 'components/Form/components/InputBuildingListFoldable'

interface Props {}

@observer
export class BelegungsplanRob extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading: boolean = false
  private readonly model = new Model<{
    oldVersion: boolean
    date: string
    time: string
    buildingIds: string
  }>(
    { oldVersion: false, date: null, time: '', buildingIds: null },
    z.object({ date: z.string(), time: z.string().refine(isTime) }),
  )

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private downloadReport = async () => {
    if (this.model.values.oldVersion) {
      if (!this.model.isValid()) {
        this.model.setFocusToLeftTopmostInvalidField()
        return
      }
      if (!isPastDate(this.model.values.date)) {
        box.alert('Datum ungültig', 'Das Datum darf nicht in der Zukunft liegen.')
        return
      }
    }
    if (!this.model.values.buildingIds) {
      box.alert(
        'Keine Gebäude ausgewählt',
        'Bitte wählen Sie mindestens ein Gebäude aus.',
      )
      return
    }
    runInAction(() => (this.loading = true))
    const time = this.model.values.time.split(':')
    let timestamp
    this.model.values.oldVersion && !!this.model.values.date
      ? (timestamp = dayjs(this.model.values.date).add(+time[0], 'h').add(+time[1], 'm'))
      : (timestamp = dayjs(new Date()))
    await createAndDownloadReport(
      'belegungsplan-rob',
      this.context.instance.id,
      {
        buildingIds: this.model.values.buildingIds,
        at: timestamp,
      },
      'Belegungsplan.xlsx',
    )
    runInAction(() => (this.loading = false))
  }

  @action
  private toggleVersion = () => {
    this.model.values.oldVersion = !this.model.values.oldVersion
  }

  render() {
    if (!this.context.permissions.report_belegungsplan_rob) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Belegungsplan'
        subtitle='Wählen Sie die Gebäude aus, für die der Belegungsplan erstellt werden soll.'
      >
        <div id={this.model.id}>
          <InputBuildingListFoldable
            className='max-h-[800px] overflow-auto'
            name='buildingIds'
            model={this.model}
          />
          <div className='mt-6'>
            <button
              className={`text-sm ${
                this.model.values.oldVersion ? 'text-blue-500 underline' : 'text-gray-500'
              } hover:underline hover:text-blue-500`}
              onClick={this.toggleVersion}
            >
              {this.model.values.oldVersion
                ? 'Aktuelle Version herunterladen'
                : 'Vergangene Version herunterladen'}
            </button>
            {this.model.values.oldVersion && (
              <div className='mt-4 w-36'>
                <InputDate
                  label='Datum'
                  name='date'
                  model={this.model}
                  tooltip={(e) =>
                    e ? 'Das Datum darf nicht in der Zukunft liegen' : null
                  }
                />
                <InputText
                  className='mt-2'
                  label='Uhrzeit'
                  name='time'
                  model={this.model}
                  placeholder='HH:mm'
                />
              </div>
            )}
          </div>
        </div>
        <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
