import * as React from 'react'
import { InputText } from 'components/Form/components/InputText'
import { InputDate } from 'components/Form/components/InputDate'
import { InputNationality } from 'components/Form/components/InputNationality'
import { InputUser } from 'components/Form/components/InputUser'
import { InputSelect } from 'components/Form/components/InputSelect'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { Model } from 'components/Form/Model'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { sexOptions } from '../constants/sexOptions'
import { familyRelationOptions } from '../constants/familyRelationOptions'
import { lfgSchutzbeduerftigOptions } from '../constants/lfgSchutzbeduerftigOptions'
import { lfgAufenthaltsstatusOptions } from '../constants/lfgAufenthaltsstatusOptions'
import { bevorzugterKanalOptions } from '../constants/bevorzugterKanalOptions'
import { lfgUnterbringungsgrundOptions } from 'contracts/residents/constants/lfgUnterbringungsgrundOptions'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { InputAsyncMultiSelect } from 'components/Form/components/InputAsyncMultiSelect'

export const vulnerableGruppenOptions: InputMultiSelectOption[] = [
  { id: 'Masern 1', label: 'Masern 1' },
  { id: 'Masern 2', label: 'Masern 2' },
  { id: 'COVID-19', label: 'COVID-19' },
]
interface Props {
  model: Model<IResident>
  requiredFields?: any
  readOnly: boolean
}

export class CaseRecordFormLfgb extends React.Component<Props, {}> {
  private readonly jbpIdModel: Model<{ jbpId: string }>
  constructor(props: Props) {
    super(props)
    this.jbpIdModel = new Model({ jbpId: toJbpId(+props.model.values.id) })
  }

  render() {
    return (
      <div className='grid grid-cols-3 gap-5 flex-initial relative'>
        {/* Row 1 */}
        <InputSelect
          model={this.props.model}
          name='sex'
          label={this.props.requiredFields?.sex ? 'Anrede *' : 'Anrede'}
          options={sexOptions}
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='title'
          label='Titel'
          disabled={this.props.readOnly}
        />
        <InputText model={this.jbpIdModel} name='jbpId' label='ID' disabled />

        {/* Row 2 */}
        <InputText
          model={this.props.model}
          name='firstName'
          label='Vorname *'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='lastName'
          label='Nachname *'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='aliasName'
          label='Alias'
          disabled={this.props.readOnly}
        />

        {/* Row 3 */}
        <InputDate
          model={this.props.model}
          name='dateOfBirth'
          label={
            this.props.requiredFields?.dateOfBirth ? 'Geburtsdatum *' : 'Geburtsdatum'
          }
          disabled={this.props.readOnly}
        />
        <InputNationality
          model={this.props.model}
          name='nationalityId'
          label={
            this.props.requiredFields?.nationalityId ? 'Nationalität *' : 'Nationalität'
          }
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='cityOfBirth'
          label={this.props.requiredFields?.cityOfBirth ? 'Geburtsort *' : 'Geburtsort'}
          disabled={this.props.readOnly}
        />

        {/* Row 4 */}
        <InputUser
          model={this.props.model}
          name='lfgSozialarbeiter'
          label='Sozialarbeiter'
          disabled={this.props.readOnly}
        />
        <InputSelect
          model={this.props.model}
          name='familyRelation'
          label={
            this.props.requiredFields?.familyRelation
              ? 'Relation zum HV *'
              : 'Relation zum HV'
          }
          options={familyRelationOptions}
          disabled={this.props.readOnly}
        />
        <InputDecimal
          model={this.props.model}
          name='briefkasten'
          label='Briefkasten'
          precision={5}
          scale={0}
          noThousandsSeparators
          notNegative
          disabled={this.props.readOnly}
        />

        {/* Row 5 */}
        <InputSelect
          model={this.props.model}
          name='lfgAufenthaltsstatus'
          label='Aufenthaltsstatus'
          options={lfgAufenthaltsstatusOptions}
          disabled={this.props.readOnly}
        />
        <InputDate
          model={this.props.model}
          name='lfgAufenthaltsstatusValidTill'
          label='Status gültig bis'
          disabled={this.props.readOnly}
        />
        <InputSelect
          model={this.props.model}
          name='lfgUnterbringungsgrund'
          label='Unterbringungsgrund'
          options={lfgUnterbringungsgrundOptions}
          disabled={this.props.readOnly}
        />

        {/* Row 6 */}
        <InputText
          model={this.props.model}
          name='email'
          label='E-Mail'
          tooltip='E-Mail Adresse ungültig'
          disabled={this.props.readOnly}
        />
        <InputText
          model={this.props.model}
          name='phone'
          label='Telefonnummer'
          disabled={this.props.readOnly}
        />
        <InputSelect
          model={this.props.model}
          name='contactBevorzugterKanal'
          label='Bevorzugter Kanal'
          options={bevorzugterKanalOptions}
          disabled={this.props.readOnly}
        />

        {/* Row 7 */}
        <InputMultiSelect
          className='col-span-3'
          model={this.props.model}
          name='lfgSchutzbeduerftig'
          label='Schutzbedürftig'
          options={lfgSchutzbeduerftigOptions}
          disabled={this.props.readOnly}
        />

        {/* Row 8 */}
        <InputText
          className='col-span-3'
          model={this.props.model}
          name='lfgDetailSchutzbedarf'
          label='Detail zum Schutzbedarf'
          disabled={this.props.readOnly}
        />

        {/* Row 9 */}
        <InputAsyncMultiSelect
          className='col-span-3'
          model={this.props.model}
          name='languageIds'
          label='Sprachen'
          options={`/api/${this.props.model.values.instanceId}/languages`}
          disabled={this.props.readOnly}
        />

        {/* Row 10 */}
        <InputMultiSelect
          className='col-span-3'
          model={this.props.model}
          name='vulnerableGruppen'
          label='Vulnerable Gruppen'
          options={vulnerableGruppenOptions}
          disabled={this.props.readOnly}
        />

        {/* Row 11 */}
        <InputTextarea
          rows={4}
          className='col-span-3'
          model={this.props.model}
          name='comment'
          label='Notiz'
          disabled={this.props.readOnly}
        />

        {this.props.children}
      </div>
    )
  }
}
