import * as React from 'react'
import { Pdf } from 'components/Pdf'
import { PdfLoading } from 'components/Pdf/components/PdfLoading'
import axios from 'axios'

interface Props {
  url?: string
  cache?: Blob
}

export class PdfPreview extends React.Component<Props, { file: Blob | null }> {
  private cancelDownload: (() => void) | null = null

  constructor(props: Props) {
    super(props)
    this.state = { file: null }
  }

  componentDidMount() {
    if (!this.props.cache && this.props.url) {
      void this.loadFile(this.props.url)
    }
  }

  componentWillUnmount() {
    this.cancelDownload?.()
  }

  private loadFile = async (url: string) => {
    try {
      const source = axios.CancelToken.source()
      this.cancelDownload = () => source.cancel('Canceled by user')
      const response = await axios.get(url, {
        cancelToken: source.token,
        responseType: 'arraybuffer',
      })
      this.cancelDownload = null
      this.setState({ file: new Blob([response.data]) })
    } catch (_e) {
      /* */
    }
  }

  render() {
    const file = this.props.cache || this.state.file
    if (!this.props.cache && !this.props.url) {
      return null
    }
    if (!file) {
      return <PdfLoading percentage={1} />
    }

    return (
      <div className='-mb-4 relative'>
        <Pdf file={file} />
        {this.props.children}
      </div>
    )
  }
}
