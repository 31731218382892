import { Collection, hermes } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IView } from '..'
import { box } from 'services/box'
import { InventoryBundleDialog } from './InventoryBundleDialog'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { Dialog } from 'components/Dialog'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { InventoryScanItemGrid } from './InventoryScanItemGrid'
import { InventoryScanBundleGrid } from './InventoryScanBundleGrid'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { ILogCreate } from 'contracts/inventory/interfaces/ILogCreate'
import { storage } from 'services/storage'
import { IInventoryItemScan } from 'contracts/inventory/interfaces/IInventoryItemScan'
import { IInventoryLogMetadata } from 'contracts/inventory/interfaces/IInventoryLogMetadata'

interface Props {
  view: Model<IView>
  items: Collection<IInventoryItem>
  bundles: Collection<IInventoryBundle>
}

@observer
export class InventoryScanItems extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private lastIssuedItems: IInventoryItem[] = []
  @observable private showBundleDialog: IInventoryBundle | null = null // null if closed, selected bundle if opened
  private readonly logs: Collection<IInventoryItemScan, IInventoryLogMetadata>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.logs = new Collection(`/api/${context.instance.id}/inventory/logs`, {
      compoundId: props.view.values.compoundId,
      residentId: props.view.values.residentId,
    })
    // Get saved list of last issued items and sync with subscribed items
    const lastIssuedItemIds = storage.get(
      `${context.user.id}.inventory-scan.last-issued-items`,
    )
    if (Array.isArray(lastIssuedItemIds)) {
      for (const id of lastIssuedItemIds) {
        const item = props.items.resources?.find((i) => i.id === id)
        if (item?.data) {
          this.lastIssuedItems.push(item.data)
        }
      }
    }
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.logs.init({ readOnly: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private addItem = async (item: IInventoryItem, enforce = false) => {
    try {
      const data: ILogCreate = {
        residentId: this.props.view.values.residentId!,
        compoundId: this.props.view.values.compoundId!,
        items: [{ id: item.id, count: item.issueCount || '1' }],
        enforce,
      }
      await hermes.create(`/api/${this.context.instance.id}/inventory/logs`, data)
      toast.success(`${item.label} wurde ausgegeben.`)
      runInAction(() => {
        // Remove from last issued items list to avoid duplicates (since it is re-added in the beginning right afterwards)
        for (let i = this.lastIssuedItems.length - 1; i >= 0; i--) {
          if (this.lastIssuedItems[i].id === item.id) {
            this.lastIssuedItems.splice(i, 1)
          }
        }
        // Re-add item and ensure that list is no longer than 6 entries.
        this.lastIssuedItems.unshift(item)
        if (this.lastIssuedItems.length > 6) {
          this.lastIssuedItems = this.lastIssuedItems.slice(0, 6)
        }
        storage.set(
          `${this.context.user.id}.inventory-scan.last-issued-items`,
          this.lastIssuedItems.map((i) => i.id),
        )
      })
    } catch (e: any) {
      if (e.id === ConflictError.id && e.details?.canEnforce) {
        if (
          await box.alert(
            'Ausgabe fehlgeschlagen',
            e.message + ' Möchten Sie den Gegenstand trotzdem ausgeben?',
            { confirm: 'Ja', cancel: 'Nein' },
          )
        ) {
          this.addItem(item, true)
        }
      } else {
        await box.alert(
          'Ausgabe fehlgeschlagen',
          e.id === ConflictError.id
            ? e.message
            : 'Der Gegenstand konnte nicht ausgegeben werden. Bitte wenden Sie sich an einen Administrator.',
          { color: 'danger' },
        )
      }
    }
  }

  private addBundleItems = async (bundle: IInventoryBundle, enforce = false) => {
    try {
      const data: ILogCreate = {
        residentId: this.props.view.values.residentId!,
        compoundId: this.props.view.values.compoundId!,
        items:
          this.props.items.resources
            ?.filter((i) => i.data?.bundleIds.includes(bundle.id) && i.data.isDispensable)
            .map((i) => ({ id: i.id, count: i.data?.issueCount || '1' })) ?? [],
        enforce,
      }
      if (data.items.length === 0) {
        box.alert('Leeres Paket', 'Fügen Sie dem Paket Gegenstände hinzu.')
        return
      }
      await hermes.create(`/api/${this.context.instance.id}/inventory/logs`, data)
      toast.success(`${bundle.label} wurde ausgegeben.`)
    } catch (e: any) {
      if (e.id === ConflictError.id && e.details?.canEnforce) {
        if (
          await box.alert(
            'Ausgabe fehlgeschlagen',
            e.message + ' Möchten Sie das Paket trotzdem ausgeben?',
            { confirm: 'Ja', cancel: 'Nein' },
          )
        ) {
          this.addBundleItems(bundle, true)
        }
      } else {
        await box.alert(
          'Ausgabe fehlgeschlagen',
          e.id === ConflictError.id
            ? `${e.message} Es werden entweder alle Gegenstände des Paketes ausgegeben oder keiner. Deshalb wurde das Paket als Ganzes nicht ausgegeben.`
            : 'Das Paket konnte nicht ausgegeben werden. Bitte wenden Sie sich an einen Administrator.',
          { color: 'danger' },
        )
      }
    }
  }

  @action
  private openBundleDialog = (bundle: IInventoryBundle) =>
    (this.showBundleDialog = bundle)

  @action
  private closeBundleDialog = () => (this.showBundleDialog = null)

  @action
  private showMore = () => (this.props.view.values.scope = null)

  render() {
    const bundles: IInventoryBundle[] =
      this.props.bundles.resources?.map((bundle) => bundle.data!).filter(Boolean) ?? []
    const items: IInventoryItem[] =
      this.props.items.resources
        ?.map((item) => item.data!)
        .filter((item) => {
          if (!item) {
            return false
          }
          return (
            !this.props.view.values.scope ||
            `,${item.scope},`.includes(`,${this.props.view.values.scope},`) ||
            (item.scope === '' && this.props.view.values.scope !== 'child')
          )
        }) ?? []
    const lastIssuedItems = this.lastIssuedItems.filter((item) => {
      return (
        !this.props.view.values.scope ||
        `,${item.scope},`.includes(`,${this.props.view.values.scope},`) ||
        (item.scope === '' && this.props.view.values.scope !== 'child')
      )
    })

    return (
      <div className='absolute left-0 md:left-80 top-[120px] bg-gray-100 bottom-0 right-[280px] overflow-y-auto overflow-x-hidden p-6'>
        {bundles.length > 0 && (
          <InventoryScanBundleGrid
            bundles={bundles}
            onSelect={this.addBundleItems}
            onOpen={this.openBundleDialog}
            instance={this.context.instance}
            caption='Pakete'
          />
        )}
        {lastIssuedItems.length > 0 && (
          <InventoryScanItemGrid
            items={lastIssuedItems}
            meta={this.logs.metadata}
            onSelect={this.addItem}
            instance={this.context.instance}
            caption='Zuletzt ausgegeben'
          />
        )}
        <InventoryScanItemGrid
          items={items}
          meta={this.logs.metadata}
          onSelect={this.addItem}
          instance={this.context.instance}
          caption='Hygienemittel'
        />
        {this.props.view.values.scope !== null && (
          <Button color='primary' outline onClick={this.showMore}>
            Mehr anzeigen
          </Button>
        )}

        {/* Bundle details */}
        {this.showBundleDialog && (
          <Dialog size='xl' open setOpen={this.closeBundleDialog}>
            <InventoryBundleDialog
              items={this.props.items}
              bundle={this.showBundleDialog}
              onClose={this.closeBundleDialog}
            />
          </Dialog>
        )}
      </div>
    )
  }
}
