import * as React from 'react'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'
import { AppStatus } from 'services/connection/helpers/getAppStatus'

interface Props {
  appStatus: AppStatus
}

const goodwillSeconds = 2

@observer
export class MaintenanceOverlay extends React.Component<Props, {}> {
  render() {
    const remainingSecs = this.props.appStatus.maintenance
    if (typeof remainingSecs !== 'number') {
      return null
    }

    let animate = ''
    if (remainingSecs > 20) {
      animate = styles.tada
    }
    if (remainingSecs < 5) {
      animate = styles.flash
    }

    return (
      <div
        className={`bg-red-500 p-5 mr-6 text-white rounded-md fixed left-6 pa-3 shadow-lg top-6 ${animate} ${styles.overlay}`}
      >
        <h1 className='mt-0'>
          <i className='fas fa-exclamation-triangle mr-3' aria-hidden='true' />
          Bitte speichern Sie Ihre Arbeit!
        </h1>
        {remainingSecs >= goodwillSeconds && (
          <span>
            {`In ${
              remainingSecs - goodwillSeconds
            } Sekunden wird Inuv für ein Systemupdate kurzzeitig in den Wartungsmodus geschaltet.`}
          </span>
        )}
        {remainingSecs < goodwillSeconds && (
          <span>
            Inuv wird jetzt für ein Systemupdate kurzzeitig in den Wartungsmodus
            geschaltet.
          </span>
        )}
      </div>
    )
  }
}
