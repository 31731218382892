import * as React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { IFamily } from 'contracts/residents/interfaces/IFamily'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { Collection } from '@byll/hermes'
import { AddMemberDialog } from '../AddMemberDialog'
import { Tooltip } from 'components/Tooltip'

interface Props {
  family: IFamily
  residents: Collection<IResidentSearchResult> // Used to display "Resident already in family"
}

@observer
export class AddMember extends React.Component<Props, {}> {
  @observable dialogIsOpen = false
  @action private setOpen = (open: boolean) => (this.dialogIsOpen = open)
  private openDialog = () => this.setOpen(true)

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  render() {
    return (
      <div className='flex-content mr-auto font-normal'>
        <span onClick={this.openDialog} className='cursor-pointer has-tooltip'>
          <i className='fa fa-plus font-bold' />
          <Tooltip position='bottom'>
            Neues Familienmitglied
            <br />
            anlegen oder bereits
            <br />
            erfasste Person mit dieser
            <br />
            Familie verknüpfen
          </Tooltip>
        </span>

        <AddMemberDialog
          open={this.dialogIsOpen}
          setOpen={this.setOpen}
          familyId={this.props.family.id}
          residents={this.props.residents}
          family={this.props.family}
        />
      </div>
    )
  }
}
