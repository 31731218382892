import * as React from 'react'
import axios from 'axios'
import { types } from './types'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'
import { ICardResidentSearchResult } from 'contracts/residents/interfaces/ICardResidentSearchResult'

interface Props {
  instanceId: string
  cardIds: string // comma separated list
  type: string
}

export class ResidentIdCards extends React.Component<
  Props,
  { cards: ICardResidentSearchResult[] | null }
> {
  constructor(props: Props) {
    super(props)
    this.state = { cards: [] }
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    try {
      // Load required data
      const response = await axios.get<{ resources: ICardResidentSearchResult[] }>(
        `/api/${this.props.instanceId}/pdf/data?type=card-resident-search-results&cardIds=${this.props.cardIds}`,
      )
      this.setState({ cards: response.data.resources })
      if (response.data.resources.length > 0) {
        setReadyFlagWhenRenderingComplete()
      }
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const Template = types[this.props.type]
    if (!Template || !this.state.cards) {
      return null
    }
    return (
      <div>
        {this.state.cards.map((r) => (
          <Template key={r.id} resident={r} instanceId={this.props.instanceId} />
        ))}
      </div>
    )
  }
}
