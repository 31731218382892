import * as React from 'react'
import styles from './styles.module.scss'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { PaperInputText } from 'components/PaperInputs/PaperInputText'
import { runInAction } from 'mobx'

export class LfgbWohnungsgeberbestaetigungFuerPolizeilicheAnmeldung extends React.Component<
  DocumentTemplateProps,
  {}
> {
  constructor(props: DocumentTemplateProps) {
    super(props)
    runInAction(() => {
      props.model.values.date =
        typeof props.model.values.date === 'string' ? props.model.values.date : ''
      props.model.values.name =
        typeof props.model.values.name === 'string' ? props.model.values.name : ''
      props.model.values.street =
        typeof props.model.values.street === 'string' ? props.model.values.street : ''
      props.model.values.details =
        typeof props.model.values.details === 'string' ? props.model.values.details : ''
      props.model.values.zip =
        typeof props.model.values.zip === 'string' ? props.model.values.zip : ''
      props.model.values.city =
        typeof props.model.values.city === 'string' ? props.model.values.city : ''
      props.model.values.lastName0 =
        typeof props.model.values.lastName0 === 'string'
          ? props.model.values.lastName0
          : ''
      props.model.values.firstName0 =
        typeof props.model.values.firstName0 === 'string'
          ? props.model.values.firstName0
          : ''
      props.model.values.lastName1 =
        typeof props.model.values.lastName1 === 'string'
          ? props.model.values.lastName1
          : ''
      props.model.values.firstName1 =
        typeof props.model.values.firstName1 === 'string'
          ? props.model.values.firstName1
          : ''
      props.model.values.lastName2 =
        typeof props.model.values.lastName2 === 'string'
          ? props.model.values.lastName2
          : ''
      props.model.values.firstName2 =
        typeof props.model.values.firstName2 === 'string'
          ? props.model.values.firstName2
          : ''
      props.model.values.lastName3 =
        typeof props.model.values.lastName3 === 'string'
          ? props.model.values.lastName3
          : ''
      props.model.values.firstName3 =
        typeof props.model.values.firstName3 === 'string'
          ? props.model.values.firstName3
          : ''
      props.model.values.lastName4 =
        typeof props.model.values.lastName4 === 'string'
          ? props.model.values.lastName4
          : ''
      props.model.values.firstName4 =
        typeof props.model.values.firstName4 === 'string'
          ? props.model.values.firstName4
          : ''
      props.model.values.lastName5 =
        typeof props.model.values.lastName5 === 'string'
          ? props.model.values.lastName5
          : ''
      props.model.values.firstName5 =
        typeof props.model.values.firstName5 === 'string'
          ? props.model.values.firstName5
          : ''
      props.model.values.lastName6 =
        typeof props.model.values.lastName6 === 'string'
          ? props.model.values.lastName6
          : ''
      props.model.values.firstName6 =
        typeof props.model.values.firstName6 === 'string'
          ? props.model.values.firstName6
          : ''
      props.model.values.lastName7 =
        typeof props.model.values.lastName7 === 'string'
          ? props.model.values.lastName7
          : ''
      props.model.values.firstName7 =
        typeof props.model.values.firstName7 === 'string'
          ? props.model.values.firstName7
          : ''
      props.model.values.lastName8 =
        typeof props.model.values.lastName8 === 'string'
          ? props.model.values.lastName8
          : ''
      props.model.values.firstName8 =
        typeof props.model.values.firstName8 === 'string'
          ? props.model.values.firstName8
          : ''
      props.model.values.lastName9 =
        typeof props.model.values.lastName9 === 'string'
          ? props.model.values.lastName9
          : ''
      props.model.values.firstName9 =
        typeof props.model.values.firstName9 === 'string'
          ? props.model.values.firstName9
          : ''
    })
  }

  private mapGridEntries = () => {
    const entries: any[] = []
    for (let i = 0; i < 9; i++) {
      if (i === 8) {
        entries.push(
          <div className='border-r-[1px] border-black' key={`lastName${i}`}>
            <PaperInputText
              model={this.props.model}
              name={`lastName${i}`}
              readOnly={!!this.props.readOnly}
            />
          </div>,
          <div className='border-black' key={`firstName${i}`}>
            <PaperInputText
              model={this.props.model}
              name={`firstName${i}`}
              readOnly={!!this.props.readOnly}
            />
          </div>,
        )
      } else {
        entries.push(
          <div
            className='border-b-[1px] border-r-[1px] border-black'
            key={`lastName${i}`}
          >
            <PaperInputText
              model={this.props.model}
              name={`lastName${i}`}
              readOnly={!!this.props.readOnly}
            />
          </div>,
          <div className='border-b-[1px] border-black' key={`firstName${i}`}>
            <PaperInputText
              model={this.props.model}
              name={`firstName${i}`}
              readOnly={!!this.props.readOnly}
            />
          </div>,
        )
      }
    }
    return entries
  }

  render() {
    return (
      <>
        {/* Page 1/3 */}
        <div className={`${styles.page} ${this.props.readOnly ? '' : 'shadow-sm mb-3'}`}>
          <div className='text-xl font-bold'>
            Wohnungsgeberbestätigung{' '}
            <span className='text-base'>gemäß § 19 Abs. 3 Bundesmeldegesetz (BMG)</span>
          </div>
          <div className='text-base italic'>- zur Vorlage bei der Meldebehörde</div>
          <div className='pt-5 text-justify text-base'>
            <div>
              <b>Angaben zum Wohnungsgeber</b> oder zu der vom Wohnungsgeber{' '}
              <b>beauftragten Person:</b>
              <div className='mt-1'>
                <span className='pl-1 text-xs'>
                  Familienname, Vorname bzw. Bezeichnung der juristischen Person
                </span>
                <div className='border-black border-l-[1px] border-b-[1px] w-full h-8 -mt-2 relative'>
                  <span className='pl-1 bottom-0 absolute'>
                    Landesbetrieb für Gebäudebewirtschaftung - Betriebsteil B
                  </span>
                </div>
              </div>
              <div>
                <span className='pl-1 text-xs'>Straße, Haus-Nr.</span>
                <div className='border-black border-l-[1px] border-b-[1px] w-full h-8 -mt-2 relative'>
                  <span className='pl-1 bottom-0 absolute'>Brunnenstraße 110 C</span>
                </div>
              </div>
              <div className='flex'>
                <div className='flex-content'>
                  <span className='pl-1 text-xs'>PLZ</span>
                  <div className='border-black border-l-[1px] border-b-[1px] w-36 h-8 -mt-2 relative'>
                    <span className='pl-1 bottom-0 absolute'>13355</span>
                  </div>
                </div>
                <div className='flex-auto'>
                  <span className='pl-1 text-xs'>Ort</span>
                  <div className='border-black border-l-[1px] border-b-[1px] h-8 -mt-2 relative'>
                    <span className='pl-1 bottom-0 absolute'>Berlin</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-3'>
              <b>Angaben zum Eigentümer der Wohnung:</b>
              <p className='italic text-xs'>
                (nur auszufüllen, wenn dieser nicht selbst Wohnungsgeber ist oder die
                Immobilie vom Eigentümer selbst bezogen wird)
              </p>
              <div className='mt-2'>
                <span className='pl-1 text-xs'>
                  Familienname, Vorname bzw. Bezeichnung der juristischen Person
                </span>
                <div className='border-black border-l-[1px] border-b-[1px] w-full h-8 -mt-2 relative'>
                  <PaperInputText
                    className='absolute bottom-0 w-full'
                    model={this.props.model}
                    name='name'
                    readOnly={!!this.props.readOnly}
                  />
                </div>
              </div>
            </div>

            <div className='mt-4 flex'>
              <p>
                Hiermit wird ein <b>Einzug</b> zu folgendem Datum bestätigt:
              </p>
              <div className='ml-2'>
                <div className='border-black border-l-[1px] border-b-[1px] w-36 h-6 relative'>
                  <PaperInputText
                    className='absolute bottom-0 w-full'
                    model={this.props.model}
                    name='date'
                    readOnly={!!this.props.readOnly}
                  />
                </div>
              </div>
            </div>

            <div className='mt-3'>
              Der Einzug bezieht sich auf folgende <b>Wohnung:</b>
              <div className='mt-1'>
                <span className='pl-1 text-xs'>Straße, Haus-Nr.</span>
                <div className='border-black border-l-[1px] border-b-[1px] w-full h-8 -mt-2 relative'>
                  <PaperInputText
                    className='absolute bottom-0 w-full'
                    model={this.props.model}
                    name='street'
                    readOnly={!!this.props.readOnly}
                  />
                </div>
              </div>
              <div>
                <span className='pl-1 text-xs'>
                  Zusatzangaben (z.B. Wohnungsnummer, Wohnungs-ID)
                </span>
                <div className='border-black border-l-[1px] border-b-[1px] w-full h-8 -mt-2 relative'>
                  <PaperInputText
                    className='absolute bottom-0 w-full'
                    model={this.props.model}
                    name='details'
                    readOnly={!!this.props.readOnly}
                  />
                </div>
              </div>
              <div className='flex'>
                <div className='flex-content'>
                  <span className='pl-1 text-xs'>PLZ</span>
                  <div className='border-black border-l-[1px] border-b-[1px] w-36 h-8 -mt-2 relative'>
                    <PaperInputText
                      className='absolute bottom-0 w-full'
                      model={this.props.model}
                      name='zip'
                      readOnly={!!this.props.readOnly}
                    />
                  </div>
                </div>
                <div className='flex-auto'>
                  <span className='pl-1 text-xs'>Ort</span>
                  <div className='border-black border-l-[1px] border-b-[1px] h-8 -mt-2 relative'>
                    <PaperInputText
                      className='absolute bottom-0 w-full'
                      model={this.props.model}
                      name='city'
                      readOnly={!!this.props.readOnly}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-5'>
              Folgende <b>Person/en</b> ist/sind in die angegebene Wohnung eingezogen:
              <div className='grid grid-cols-2 text-center border-[1px] border-black mt-2'>
                <div className='border-b-[1px] border-r-[1px] border-black'>
                  Familienname
                </div>
                <div className='border-b-[1px] border-black'>Vorname</div>
                {this.mapGridEntries()}
              </div>
            </div>

            <div className='mt-3 w-96'>
              <div className='border-b-[1px] border-black truncate'>&nbsp;</div>
              <div className='text-xs text-center'>
                Datum, Unterschrift Wohnungsgeber bzw. Wohnungseigentümer
              </div>
            </div>

            <div className='text-xs mt-4'>
              <b>Hinweis:</b> Es ist verboten, eine Wohnanschrift für eine Anmeldung einem
              Dritten anzubieten oder zur Verfügung zu stellen, obwohl ein tatsächlicher
              Bezug der Wohnung durch diesen weder stattfindet noch beabsichtigt ist. Ein
              Verstoß gegen das Verbot stellt eine Ordnungswidrigkeit dar, ebenso wie die
              Ausstellung dieser Bestätigung ohne die dafür erforderliche Berechtigung (§
              54 i. V. m. § 19 BMG). Die Meldebehörde ist berechtigt, die Angaben zum
              Eigentümer der Wohnung zu prüfen.
            </div>
          </div>
        </div>
      </>
    )
  }
}
