import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { RoundIcon } from 'components/RoundIcon'
import { observer } from 'mobx-react'
import { IOrderItem } from 'contracts/inventory/interfaces/IOrderItem'
import { toLocaleNumber } from 'contracts/general/helpers/toLocaleNumber'
import { InputText } from 'components/Form/components/InputText'
import { IFullOrder } from 'contracts/inventory/interfaces/IFullOrder'
import { getInventoryImageSrc } from 'modules/Inventory/helpers/getInventoryImageSrc'

interface Props {
  item: IOrderItem
  order: IFullOrder
  onDelete: (item: IOrderItem) => void
}

@observer
export class InventoryOrderItem extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IOrderItem>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.item)
  }

  private onDelete = () => this.props.onDelete(this.props.item)

  render() {
    const item = this.props.item
    return (
      <tr key={item.id} className='group'>
        <td className='px-3 py-4 whitespace-nowrap relative'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <img
                className='h-10 w-10 rounded-full'
                src={getInventoryImageSrc(
                  item.imageId,
                  this.context.instance.id,
                  'thumbnail',
                )}
                alt=''
              />
            </div>
            <div className='ml-4'>
              <div className='text-sm font-medium text-gray-900 truncate max-w-[200px]'>
                {item.label}
              </div>
            </div>
          </div>
          <RoundIcon
            classNameContainer='hidden group-hover:block'
            tooltip={{ text: 'Eintrag löschen', position: 'right' }}
            style={{ position: 'absolute', top: 5, left: 5 }}
            icon='fas fa-trash'
            color='danger'
            onClick={this.onDelete}
          />
        </td>
        <td className='px-3 py-4 whitespace-nowrap text-sm'>
          <InputText
            className='max-w-20'
            model={this.model}
            name='notes'
            label='Bemerkung'
          />
        </td>
        <td className='px-3 py-4 whitespace-nowrap text-sm w-[120px]'>
          <InputDecimal
            className='max-w-20'
            model={this.model}
            name='count'
            label={this.props.order.type === 'order' ? 'Anzahl' : 'Anzahl neu'}
            precision={6}
            scale={0}
            notNegative
            placeholder={item.unit || undefined}
            postfix={item.unit || undefined}
          />
        </td>
        {this.props.order.type === 'inventory' && (
          <td className='px-3 py-4 whitespace-nowrap text-sm w-[120px]'>
            <InputDecimal
              className='max-w-20'
              disabled
              model={this.model}
              name='oldCount'
              label='Anzahl bisher'
              precision={6}
              scale={0}
              notNegative
              placeholder={item.unit || undefined}
              postfix={item.unit || undefined}
            />
          </td>
        )}
        {this.context.permissions.inventory_acceptOrders &&
          this.props.order.type === 'order' && (
            <td className='px-3 py-4 whitespace-nowrap text-sm w-[120px]'>
              <InputDecimal
                className='max-w-20'
                model={this.model}
                name='packageSize'
                label='Packung'
                precision={6}
                scale={0}
                notNegative
                placeholder={item.unit || undefined}
                postfix={item.unit || undefined}
              />
            </td>
          )}
        {this.context.permissions.inventory_acceptOrders &&
          this.props.order.type === 'order' && (
            <td className='px-3 py-4 whitespace-nowrap text-sm text-gray-500'>
              {item.count && item.packageSize && item.packageSize !== '0'
                ? toLocaleNumber(String(Math.ceil(+item.count / +item.packageSize)))
                : '-'}
            </td>
          )}
      </tr>
    )
  }
}
