import { Button } from 'components/Form/components/Button'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { CostCoverageDownloadDropdown } from 'modules/CostCoverages/components/CostCoverageDownloadDropdown'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  compoundId: string
}

const reports: { report: string; label: string; permission?: string }[] = [
  {
    report: 'scan-protocol',
    label: 'Scanprotokoll',
    permission: 'resident_downloadVisitScanProtocol',
  },
  { report: 'browser:current-visitors', label: 'Aktuell anwesend (Browser)' },
  { report: 'current-visitors', label: 'Aktuell anwesend (Excel)' },
]

export const DownloadDropdown: React.FC<Props> = ({ compoundId }) => {
  const context = React.useContext(AppContext)
  const [downloading, setDownloading] = React.useState(false)
  const download = async (report: { report: string; label: string }) => {
    if (report.report === 'browser:current-visitors') {
      window.open('/visitors/' + compoundId, '_blank')
      return
    }
    setDownloading(true)
    try {
      await createAndDownloadReport(
        report.report,
        context.instance.id,
        { compoundId },
        `${report.label}.xlsx`,
      )
    } catch (_e) {
      /* */
    }
    setDownloading(false)
  }

  return (
    <div className='flex-content'>
      {downloading && (
        <Button color='primary' style={{ width: 38, height: 38, padding: 0 }}>
          <i className='fas fa-sync fa-spin' />
        </Button>
      )}
      {!downloading && (
        <CostCoverageDownloadDropdown
          reports={reports.filter(
            (r) => !r.permission || context.permissions[r.permission],
          )}
          onSelect={download}
          style={{ borderRadius: 6 }}
          width={220}
        />
      )}
    </div>
  )
}
