import { Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {
  compoundId: string
}

@observer
export class CompoundVisitorCount extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disposers: Disposer[] = []
  private readonly visitorCount: Resource<{ id: string; count: number }>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.visitorCount = new Resource(
      `/api/${context.instance.id}/visitorCount/${this.props.compoundId}`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.visitorCount.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  render() {
    if (!this.visitorCount.data) {
      return null
    }
    return (
      <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'>
        {this.visitorCount.data.count} anwesend
      </span>
    )
  }
}
