import { Model } from 'components/Form/Model'
import { periods } from 'contracts/report/helpers/periods'
import * as React from 'react'
import { Button } from '../Button'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { IPeriod } from 'contracts/report/interfaces/IPeriod'

interface Props {
  model: Model<any>
  name: string
  className?: string
}

@observer
export class InputPeriod extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private onChange = (value: IPeriod) =>
    (this.props.model.values[this.props.name] = value)

  render() {
    return (
      <div className={`flex ${this.props.className || ''}`}>
        {periods.map((period, i) => (
          <Button
            key={period.value}
            onClick={() => this.onChange(period.value)}
            color={
              this.props.model.values[this.props.name] === period.value
                ? 'primary'
                : 'secondary'
            }
            outline={this.props.model.values[this.props.name] !== period.value}
            className='flex-auto'
            style={{
              borderRadius:
                i === 0 ? '6px 0 0 6px' : i === periods.length - 1 ? '0 6px 6px 0' : '0',
              padding: '9px 0',
            }}
          >
            {period.label}
          </Button>
        ))}
      </div>
    )
  }
}
