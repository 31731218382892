import { isStammCompound } from 'helpers/isStamm'

/**
 * Helper function that works with all three-step permissions:
 * permission = 0 -> false
 * permission = 1 -> true if compoundIds is either empty or at least one belongs to stamm compounds
 * permission = 2 -> true
 * @param permission
 * @param compoundIds
 * @returns
 */
export function isAllowedWithCompounds(
  permission: number,
  compoundIds?: string[],
  allowIfNoResponsibility = true,
): boolean {
  if (permission === 2) {
    return true
  } // allowed
  if (permission === 1) {
    if (!compoundIds) {
      return false
    }
    if (compoundIds.length === 0) {
      return allowIfNoResponsibility
    } // no responsibility during last 30 days
    for (const id of compoundIds) {
      if (isStammCompound(id)) {
        return true
      }
    }
  }
  return false // not allowed (0) or unknown (other)
}
