import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { dayjs } from 'helpers/dayjs'
import { getDurationLabel } from 'helpers/getDurationLabel'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  resident: IResidentSearchResult & { lastSeen: string }
}

export class SearchHistoryEntry extends React.Component<Props, {}> {
  static contextType = AppContext

  render() {
    const thumbnail = getResidentImageSrc(
      this.context.instance.id,
      this.props.resident.imageId,
      this.props.resident.sex,
      'thumbnail',
    )
    const diffInSeconds = dayjs().diff(this.props.resident.lastSeen, 'second')
    const diffLabel = getDurationLabel(diffInSeconds)

    return (
      <div className='flex m-1 gap-2'>
        <img className='rounded-full w-10 h-10' src={thumbnail} alt='' />
        <div>
          <Link
            to={`/residents/${toJbpId(+this.props.resident.id).toLowerCase()}/overview`}
            state={{ fromSearch: true }}
            className='text-blue-500 hover:underline'
          >
            {this.props.resident.lastName.toUpperCase()}, {this.props.resident.firstName}
          </Link>
          <div className='text-xs text-gray-400'>{diffLabel}</div>
        </div>
      </div>
    )
  }
}
