import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { AppContext } from 'services/connection/models/AppContext'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Message } from 'components/Message'

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

const options: (InputSelectOption & { description?: string })[] = [
  { value: null, label: 'Alle' },
  {
    value: 'SW/SZ/GpfE/Geplant',
    label: 'Nur SW/SZ/GpfE oder geplante Gpf',
    description:
      'Nur aktuell eingecheckte SW/SZ oder letzter Aufenthalt mit GpfE beendet.',
  },
  {
    value: 'SW/SZ/GpfE/Geplant ohne Datum',
    label: 'Nur SW/SZ/GpfE ohne Bescheiddatum',
    description:
      'Alle Bewohner mit min- destens einer ent- sprechenden Belegung ohne Bescheiddatum. Ausgecheckte Bewoh- ner sind in die Suche eingeschlossen.',
  },
  {
    value: 'AV fehlt',
    label: 'Nur AV fehlt ohne AV-Datum',
    description:
      'Alle Bewohner mit min- destens einer Belegung mit Status "AV fehlt" (AV). Ausgecheckte Bewohner sind in die Suche eingeschlossen.',
  },
]

@observer
export class SelectAwumBillingStatus extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private reset = () => {
    this.props.model.values.billingStatus = null
  }

  render() {
    let label = this.props.model.values.billingStatus || 'Gebührenstatus'
    const description =
      options.find((o) => o.value === this.props.model.values.billingStatus)
        ?.description || null
    let color: string = this.props.model.values.billingStatus
      ? 'text-blue-500'
      : 'text-gray-500'

    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='flex flex-col gap-4'>
              <InputSelect
                model={this.props.model}
                name='billingStatus'
                label='Gebührenstatus'
                options={options}
              />

              {description && <Message color='primary'>{description}</Message>}

              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
