import * as React from 'react'
import img from './images/support.jpg'
import { Card } from 'components/Card'

interface Props {}

export const DashboardSupport: React.FC<Props> = (props) => (
  <Card>
    <img className='w-full' src={img} alt='Support' />
    <div className='p-4'>
      <div className='font-bold text-xl mb-2'>Support</div>
      <p className='text-gray-700 text-base'>
        Hier finden Sie Unterstützung:{' '}
        <a
          target='_blank'
          rel='noreferrer'
          className='text-blue-500 hover:underline'
          href='mailto:support@jonasbetterplace.com'
        >
          support@jonasbetterplace.com
        </a>
      </p>
    </div>
    <div className='px-4 pb-4'>
      <span className='inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-normal text-green-700 mr-2 mb-2'>
        ISO 9001:2015 <i className='fa fa-check' />
      </span>
    </div>
  </Card>
)
