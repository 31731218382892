import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { RoundIcon } from 'components/RoundIcon'
import { IBuildingFloor } from 'contracts/accommodations/interfaces/IBuildingFloor'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  floor: IBuildingFloor & { isDeleted: boolean }
}

@observer
export class EditBuildingFloorRow extends React.Component<Props, {}> {
  private readonly model: Model<IBuildingFloor & { isDeleted: boolean }>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.floor)
    makeObservable(this)
  }

  @action private onDelete = () => {
    this.model.values.isDeleted = true
    this.model.values.label = this.props.floor.label
  }

  @action private onRestore = () => {
    this.model.values.isDeleted = false
    this.model.values.label = this.props.floor.label
  }

  render() {
    return (
      <div className='relative'>
        <InputText
          disabled={this.model.values.isDeleted}
          style={
            this.model.values.isDeleted
              ? { color: 'red', textDecoration: 'line-through' }
              : undefined
          }
          model={this.model}
          name='label'
          label='Stockwerksbezeichnung'
          maxLength={10}
        />
        {!this.model.values.isDeleted && (
          <RoundIcon
            style={{ position: 'absolute', right: 4, top: 4 }}
            tooltip={{ text: 'Zum Löschen vormerken', position: 'left' }}
            icon='fas fa-trash'
            color='danger'
            onClick={this.onDelete}
          />
        )}
        {this.model.values.isDeleted && (
          <RoundIcon
            style={{ position: 'absolute', right: 4, top: 4 }}
            tooltip={{ text: 'Rückgängig', position: 'left' }}
            icon='fas fa-undo'
            color='white'
            onClick={this.onRestore}
          />
        )}
      </div>
    )
  }
}
