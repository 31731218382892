import * as React from 'react'
import { DisplayCompound } from '..'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'

interface Props {
  model: any
  name: string
  compound: DisplayCompound
  compounds: DisplayCompound[]
  selected: Set<string>
}

@observer
export class CompoundMapper extends React.Component<Props, {}> {
  @observable showBuildings: boolean = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private toggleShowBuildings = () => {
    this.showBuildings = !this.showBuildings
  }

  @action
  private toggle = (building: IBuilding) => {
    const buildingIds: string[] =
      this.props.model.values[this.props.name]?.split(',').filter(Boolean) ?? []
    const index = buildingIds.indexOf(building.id)
    if (index === -1) {
      buildingIds.push(building.id)
    } else {
      buildingIds.splice(index, 1)
    }
    this.props.model.values[this.props.name] = buildingIds.join(',')
  }

  @action
  private toggleCompound = (compound: DisplayCompound) => () => {
    if (!this.props.compounds) {
      return
    }
    const buildingIds = new Set<string>(
      this.props.model.values[this.props.name]?.split(',').filter(Boolean) ?? [],
    )
    const allOfCompound = new Set<string>()
    let unselect = true
    for (const c of this.props.compounds) {
      if (c.id !== compound.id) {
        continue
      }
      for (const building of c.buildings) {
        if (!buildingIds.has(building.id)) {
          unselect = false
        }
        buildingIds.add(building.id)
        allOfCompound.add(building.id)
      }
    }
    if (unselect) {
      for (const id of allOfCompound) {
        buildingIds.delete(id)
      }
    }
    this.props.model.values[this.props.name] = Array.from(buildingIds.values()).join(',')
  }

  render() {
    const selected = Array.from(this.props.selected).filter((b) =>
      this.props.compound.buildings.map((b) => b.id).includes(b),
    ).length
    return (
      <div key={this.props.compound.id} className='py-4 shadow-md bg-gray-100 rounded-md'>
        <div className='font-bold mb-2 px-4 truncate max-w-[432px]'>
          <span
            key={`${this.showBuildings}`}
            className='cursor-pointer text-gray-400 mr-2'
            onClick={this.toggleShowBuildings}
          >
            {this.showBuildings ? (
              <span>
                <i className='fa fa-caret-down' />
              </span>
            ) : (
              <span>
                <i className='fa fa-caret-right' />
              </span>
            )}
          </span>
          {this.props.compound.label}
          <span
            onClick={this.toggleCompound(this.props.compound)}
            className='px-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100 rounded-md ml-2'
          >
            Alle
          </span>
          <span
            className={`px-1 py-0.5 text-xs font-medium text-white ${
              selected === 0 ? 'bg-gray-400' : 'bg-blue-500'
            } border-r border-gray-100 rounded-md ml-2`}
          >
            {selected}
          </span>
        </div>
        {this.showBuildings &&
          this.props.compound.buildings.map((building) => (
            <div key={building.id} className='px-4 relative flex items-start mb-0.5'>
              <input
                type='checkbox'
                className='mt-0.5 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                checked={!!this.props.selected.has(building.id)}
                onChange={() => this.toggle(building)}
              />
              <label className='truncate max-w-[400px] ml-2 block text-sm text-gray-900'>
                {building.label}
              </label>
            </div>
          ))}
      </div>
    )
  }
}
