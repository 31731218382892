export function getLastXLetters(str: string, x: number): string {
  const parts = str.split(' / ')
  if (parts.length > 2) {
    str = parts[0] + ' / ' + parts[1]
  }
  const result = str.slice(-x)
  if (result !== str) {
    return `...${result}`
  }
  return str
}
