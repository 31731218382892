import * as React from 'react'
import { types } from '../ResidentIdCards/types'
import styles from './styles.module.scss'
import { ICardResidentSearchResult } from 'contracts/residents/interfaces/ICardResidentSearchResult'
import { ICardStorageData } from 'contracts/residents/interfaces/ICardStorageData'
import { setReadyFlagWhenRenderingComplete } from 'modules/Pdf/helpers/setReadyFlagWhenRenderingComplete'

interface Props {
  storageData: ICardStorageData
  type: string
  instanceId: string
}

export class ResidentCards extends React.Component<Props, {}> {
  private readonly pages: ICardResidentSearchResult[][] = []

  constructor(props: Props) {
    super(props)
    this.preparePages()
  }

  componentDidMount() {
    setReadyFlagWhenRenderingComplete()
  }

  preparePages() {
    const residents: ICardResidentSearchResult[] = []
    const numberOfCards = this.props.storageData.quantity
    for (let i = 0; i < this.props.storageData.cards.length; i++) {
      for (let k = 0; k < numberOfCards; k++) {
        residents.push(this.props.storageData.cards[i])
      }
    }
    const residentsPerPage = this.calculateCardsPerPage()
    const numberOfPages = Math.ceil(residents.length / residentsPerPage)
    let j = 0
    for (let p = 0; p < numberOfPages; p++) {
      if (j < residents.length) {
        this.pages[p] = residents.slice(j, j + residentsPerPage)
        j += residentsPerPage
      }
    }
  }

  calculateCardsPerPage() {
    const pageWidth = 29.7
    const pageHeight = 21
    const cardHeight = +this.props.storageData.height + 0.2
    const cardWidth = +this.props.storageData.width + 0.2

    const columns = Math.floor(pageHeight / cardHeight)
    const rows = Math.floor(pageWidth / cardWidth)

    return rows * columns
  }

  render() {
    const Template = types[this.props.type]
    if (!Template || !this.props.storageData) {
      return null
    }
    return (
      <>
        {this.pages.map((p, i) => (
          <div key={i} className={`${styles.page} p-10`}>
            {p.map((r, k) => (
              <div key={k} className='m-1 inline-block'>
                <Template resident={r} instanceId={this.props.instanceId} batch />
              </div>
            ))}
          </div>
        ))}
      </>
    )
  }
}
