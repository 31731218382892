import { Callout } from 'components/Callout'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { CostCoverageFilter } from './components/CostCoverageFilter'
import { CostCoverageListByResident } from './components/CostCoverageListByResident'
import { IResidentCostsFilterValidator } from 'contracts/costCoverages/interfaces/IResidentCostsFilterValidator'
import { getMonthFromDefaults } from 'helpers/getMonthFromDefaults'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { reaction } from 'mobx'
import { isStammCompound } from 'helpers/isStamm'
import { CostCoverageListByCoverage } from './components/CostCoverageListByCoverage'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { ICostCoverageMeta } from 'contracts/costCoverages/interfaces/ICostCoverageMeta'
import { ABRECHNUNGSMODUS_LFGB } from 'contracts/costCoverages/interfaces/abrechnungsmodus'
import { CostCoverageListByFamily } from './components/CostCoverageListByFamily'
import { hermes } from '@byll/hermes'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { dayjs } from 'helpers/dayjs'

interface Props {}

@observer
export class CostCoverages extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<
    IResidentCostsFilterValidator & {
      clear: number
      payerId: string | null
      payers: InputSelectOption[]
      selected: Map<string, ICostCoverageMeta | null>
      coverageIds: string[]
    }
  >
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      compoundId: context.defaults.responsibilityCompoundId,
      month: getMonthFromDefaults(context.defaults),
      view: 'families',
      residentScope: 'all',
      costCoverageScope: 'all',
      buildingGroupId: null,
      payerId: null,
      payers: [],
      selected: new Map(),
      coverageIds: [],
      clear: 1,
    })
    if (
      context.permissions.menu_resident_abrechnung === 1 &&
      !isStammCompound(this.model.values.compoundId || '')
    ) {
      this.model.values.compoundId = null as any
    }
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.model.values.month,
        (m) => {
          this.context.defaults.month = m
          this.model.values.buildingGroupId = null
        },
      ),
    )
    this.disposers.push(
      reaction(
        () => this.model.values.compoundId,
        () => (this.model.values.buildingGroupId = null),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  render() {
    if (!this.context.permissions.menu_resident_abrechnung) {
      return <Forbidden />
    }
    const compound = hermes.getFromStore<ICompound>(
      `/api/${this.context.instance.id}/accommodations/compounds/${this.model.values.compoundId}`,
      false,
    )

    return (
      <div className='pt-14 flex flex-col min-h-full bg-gray-100'>
        <CostCoverageFilter model={this.model} />

        {!compound && (
          <div className='flex-content flex px-6 py-3 bg-gray-100 gap-3 text-sm flex-wrap'>
            <div className='whitespace-nowrap bg-yellow-500 rounded-md text-white py-1 px-2'>
              Belegung ohne KÜ
            </div>
            {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
              <div className='whitespace-nowrap bg-indigo-500 rounded-md text-white py-1 px-2'>
                <i className='fas fa-ambulance' /> Krankenhaus ohne KÜ
              </div>
            )}
            {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
              <div className='whitespace-nowrap bg-indigo-500 rounded-md text-white py-1 px-2'>
                Abwesenheit
              </div>
            )}
            <div className='whitespace-nowrap bg-green-500 rounded-md text-white py-1 px-2'>
              Abrechenbar
            </div>
            {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
              <div className='whitespace-nowrap bg-red-500 rounded-md text-white py-1 px-2'>
                KÜ liegt vor, aber UKN/LB fehlt
              </div>
            )}
            <div className='whitespace-nowrap bg-gray-500 rounded-md text-white py-1 px-2'>
              <i className='fas fa-stop' /> Belegungsende
            </div>
          </div>
        )}

        {!compound && (
          <div className='flex-auto bg-gray-100 flex'>
            <Callout
              icon='fas fa-home'
              iconColor='#8b5cf6'
              title='Bitte wählen Sie ein Gelände'
            />
          </div>
        )}

        {compound?.plannedCheckOutDate && (
          <div className='bg-red-500 text-white rounded-md p-6 mt-4 mb-1 mx-6'>
            <span className='mr-1'>
              <i className='fas fa-info-circle' />
            </span>
            <span>{`Diese Unterkunft ${
              dayjs().isAfter(compound.plannedCheckOutDate, 'day') ? 'wurde' : 'wird'
            } am ${dayjs(compound.plannedCheckOutDate).format(
              'DD.MM.YYYY',
            )} abgegeben.`}</span>
          </div>
        )}

        {compound && this.model.values.view === 'residents' && (
          <CostCoverageListByResident
            key={`${this.model.values.clear}/${compound.id}/${this.model.values.month}/${this.model.values.residentScope}/${this.model.values.buildingGroupId}`}
            compoundId={compound.id}
            month={this.model.values.month}
            scope={this.model.values.residentScope || 'all'}
            buildingGroupId={this.model.values.buildingGroupId || null}
          />
        )}

        {compound && this.model.values.view === 'families' && (
          <CostCoverageListByFamily
            key={`${this.model.values.clear}/${compound.id}/${this.model.values.month}/${this.model.values.residentScope}/${this.model.values.buildingGroupId}`}
            compoundId={compound.id}
            month={this.model.values.month}
            scope={this.model.values.residentScope || 'all'}
            buildingGroupId={this.model.values.buildingGroupId || null}
          />
        )}

        {compound && this.model.values.view === 'cost coverages' && (
          <CostCoverageListByCoverage
            key={`${this.model.values.clear}/${compound.id}/${this.model.values.month}/${this.model.values.costCoverageScope}/${this.model.values.buildingGroupId}`}
            compoundId={compound.id}
            month={this.model.values.month}
            scope={this.model.values.costCoverageScope || 'all'}
            model={this.model}
            buildingGroupId={this.model.values.buildingGroupId || null}
          />
        )}
      </div>
    )
  }
}
