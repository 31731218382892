import { Collection } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ITransferPlanningsFilter } from 'contracts/transfer/interfaces/ITransferPlanningsFilter'
import { Model } from 'components/Form/Model'
import { ITransferPlanningSearchResult } from 'contracts/transfer/interfaces/ITransferPlanningSearchResult'
import { TransferPlanFilter } from '../types/TransferPlanFilter'
import { TransferPlanResidentListItem } from './TransferPlanResidentListItem'
import { transferStatus } from 'contracts/transfer/interfaces/transferStatusEnum'
import { TransferPlanResidentListItemDnd } from './TransferPlanResidentListItemDnd'
import { getLastXLetters } from '../helpers/getLastXLetters'
import { box } from 'services/box'
import { ResidentInfoDialog } from '../../ResidentInfoDialog'

interface Props {
  buildingId: string
  period: TransferPlanFilter['period']
  type: TransferPlanFilter['type']
}

@observer
export class TransferPlanResidentList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly transferPlannings: Collection<ITransferPlanningSearchResult>
  private readonly disposers: Disposer[] = []
  private filter: Model<ITransferPlanningsFilter>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.filter = new Model({
      direction: props.type.endsWith('Ankünfte') ? 'incoming' : 'outgoing',
      status: props.type.startsWith('Geplante') ? 'not complete' : 'complete',
      buildingIds: props.buildingId,
      begin: dayjs()
        .add(
          props.period === 'Letzte 2 Wochen'
            ? -13
            : props.period === 'Nächste 7 Tage'
            ? 1
            : -3650,
          'days',
        )
        .startOf('day')
        .format('YYYY-MM-DD'),
      end: dayjs()
        .add(
          props.period === 'Letzte 2 Wochen'
            ? 0
            : props.period === 'Nächste 7 Tage'
            ? 7
            : 3650,
          'days',
        )
        .startOf('day')
        .format('YYYY-MM-DD'),
    })
    this.transferPlannings = new Collection(
      `/api/${context.instance.id}/transfer/plannings`,
      this.filter.values,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.transferPlannings.init())
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private showInfo = (planning: ITransferPlanningSearchResult) => {
    box.alert('Bewohnerdetails', <ResidentInfoDialog resident={planning.resident} />)
  }

  render() {
    return (
      <div className='absolute top-0 right-0 left-0 bottom-0 overflow-x-hidden overflow-y-auto pl-3 pr-6 pb-6 pt-12'>
        {this.transferPlannings.resources?.length === 0 && (
          <div className='text-gray-500 leading-6 p-3 text-sm bg-white shadow absolute top-16 left-3 right-6 bottom-6 rounded-md'>
            Für den aktuellen Filter liegen keine Transfers vor.
          </div>
        )}

        {this.transferPlannings.resources?.map((t) => {
          const planning = t.data
          if (!planning) {
            return null
          }
          const isDnd =
            this.context.permissions.resident_accommodation_checkin_transfers &&
            planning.status === transferStatus.pending &&
            this.props.type === 'Geplante Ankünfte'
          const tooltip = this.props.type.endsWith('Ankünfte')
            ? `Aktuell: ${getLastXLetters(planning.resident.accommodation.label, 18)}`
            : `Ziel: ${getLastXLetters(planning.target.label, 18)}`
          return (
            <div key={t.id} className='mt-2'>
              {planning.section !== '' && (
                <div className='text-gray-500 text-sm mt-4 mb-1'>{planning.section}</div>
              )}
              <div className='group relative'>
                {!isDnd && (
                  <TransferPlanResidentListItem
                    planning={planning}
                    offerCheckOut={
                      this.context.permissions.resident_accommodation_checkout_transfers >
                        0 &&
                      this.props.type === 'Geplante Abgänge' &&
                      planning.status === transferStatus.ready
                    }
                    tooltip={tooltip}
                  />
                )}
                {isDnd && (
                  <TransferPlanResidentListItemDnd
                    planning={planning}
                    tooltip={tooltip}
                  />
                )}
                <div
                  onClick={() => this.showInfo(planning)}
                  className='cursor-pointer hidden group-hover:inline-flex w-5 text-center pt-1 h-5 pl-2 absolute top-2 left-[30px] rounded-full text-xs font-medium bg-blue-500 hover:bg-indigo-500 text-white ml-1'
                >
                  <i className='fas fa-info' />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
