import { Resource, hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Message } from 'components/Message'
import { ITodoMessage } from 'contracts/todos/interfaces/ITodoMessage'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { z } from 'zod'

interface Props {
  ticket: ITodoSearchResult
  review: Resource<ITodoMessage> | undefined
  disabled: boolean
}

@observer
export class TicketDialogReview extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<{ review: string; rating: 1 | 2 | 3 | 4 | 5 | null }>
  @observable private loading: boolean = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      {
        review: this.props.review?.data?.label || '',
        rating: this.props.ticket.rating || null,
      },
      z.object({ review: z.string().min(1) }),
    )
  }

  @action
  private rate = async (rating: 1 | 2 | 3 | 4 | 5 | null) => {
    if (this.props.disabled) {
      return
    }
    this.model.values.rating = rating
  }

  private submit = async () => {
    if (this.props.disabled) {
      return
    }
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    try {
      if (this.props.review) {
        await hermes.patch(
          `/api/${this.context.instance.id}/todos/${this.props.ticket.id}/todoMessages/${this.props.review.id}`,
          {
            label: this.model.values.review,
          },
        )
        runInAction(() => (this.props.ticket.rating = this.model.values.rating))
      } else {
        await hermes.create(
          `/api/${this.context.instance.id}/todos/${this.props.ticket.id}/todoMessages`,
          {
            label: this.model.values.review,
            type: 'review',
          },
        )
        runInAction(() => (this.props.ticket.rating = this.model.values.rating))
      }
      runInAction(() => (this.loading = false))
    } catch (_e) {
      runInAction(() => {
        this.error = 'Beim Speichern der Zusammenfassung ist ein Fehler aufgetreten.'
        this.loading = false
      })
    }
  }

  render() {
    const canEdit =
      (this.context.permissions.dashboard_tickets === 3 ||
        this.props.ticket.ratingBy === this.context.user.id) &&
      !this.props.disabled
    if (!canEdit && !this.props.review) {
      return null
    }
    return (
      <div className='rounded-sm shadow bg-white flex relative mb-6'>
        <div className='flex-[0_0_268px] pt-11 pl-11 leading-7 pb-10'>
          <div>Bewerten</div>
          <div className='text-gray-400 text-xs'>Bewerten Sie die erledigte Aufgabe.</div>
        </div>
        <div className='flex-auto pt-11 py-8 pr-6'>
          {this.error && (
            <Message className='mb-4' color='danger'>
              {this.error}
            </Message>
          )}
          {!canEdit ? (
            <div>{this.props.review?.data?.label}</div>
          ) : (
            <InputTextarea className='mb-4' model={this.model} name='review' rows={5} />
          )}
          {canEdit && (
            <div className='flex float-right justify-between w-full items-center gap-4'>
              <div className='flex flex-row-reverse text-xl border border-gray-200 p-2 rounded'>
                <span
                  onClick={() => this.rate(5)}
                  className={`${
                    this.model.values.rating === 5 ? 'text-yellow-500' : 'text-gray-200'
                  } peer peer-hover:text-yellow-500 hover:text-yellow-500 cursor-pointer h-7`}
                >
                  <i className='fas fa-star w-8' />
                </span>{' '}
                <span
                  onClick={() => this.rate(4)}
                  className={`${
                    this.model.values.rating && this.model.values.rating >= 4
                      ? 'text-yellow-500'
                      : 'text-gray-200'
                  } peer peer-hover:text-yellow-500 hover:text-yellow-500 cursor-pointer h-7`}
                >
                  <i className='fas fa-star w-8' />
                </span>{' '}
                <span
                  onClick={() => this.rate(3)}
                  className={`${
                    this.model.values.rating && this.model.values.rating >= 3
                      ? 'text-yellow-500'
                      : 'text-gray-200'
                  } peer peer-hover:text-yellow-500 hover:text-yellow-500 cursor-pointer h-7`}
                >
                  <i className='fas fa-star w-8' />
                </span>{' '}
                <span
                  onClick={() => this.rate(2)}
                  className={`${
                    this.model.values.rating && this.model.values.rating >= 2
                      ? 'text-yellow-500'
                      : 'text-gray-200'
                  } peer peer-hover:text-yellow-500 hover:text-yellow-500 cursor-pointer h-7`}
                >
                  <i className='fas fa-star w-8' />
                </span>{' '}
                <span
                  onClick={() => this.rate(1)}
                  className={`${
                    this.model.values.rating && this.model.values.rating >= 1
                      ? 'text-yellow-500'
                      : 'text-gray-200'
                  } peer peer-hover:text-yellow-500 hover:text-yellow-500 cursor-pointer h-7`}
                >
                  <i className='fas fa-star w-8' />
                </span>{' '}
              </div>
              <Button loading={this.loading} onClick={this.submit}>
                Speichern
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}
