import { IImageSection } from './getImageSection'

export function getStyleFromSection(
  section: IImageSection,
  original: { width: number; height: number },
  target: { width: number; height: number },
): any {
  const style = {
    position: 'absolute',
    maxWidth: 'initial',
    userSelect: 'none',
    cursor: 'move',
    left: 0,
    top: 0,
    width: target.width,
    height: target.height,
  }
  const scale = target.width / section.width

  style.left = -section.x * scale
  style.top = -section.y * scale
  style.width = original.width * scale
  style.height = original.height * scale
  return style
}
