import * as React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { Tooltip } from 'components/Tooltip'
import { Collection } from '@byll/hermes'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { AddFamilyDialog } from '../AddFamilyDialog'
import { LinkedFamilyItem } from './components/LinkedFamilyItem'

interface Props {
  familyId: string
  familyGroupId: string | null
}

@observer
export class FamilyGroup extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private dialogIsOpen: Set<string> | null = null
  private readonly residentSearchResults: Collection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private readonly disposers: Disposer[] = []

  @action private setOpen = (open: boolean) => {
    if (!open) {
      this.dialogIsOpen = null
    } else {
      const familyIds =
        this.residentSearchResults.resources
          ?.map((res) => res.data?.data.familyId)
          .filter((id) => !!id) || ([] as any)
      this.dialogIsOpen = new Set([...familyIds, this.props.familyId])
    }
  }

  private openDialog = () => this.setOpen(true)

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.residentSearchResults = new Collection(
      `/api/${context.instance.id}/residentSearchResults`,
      {
        familyGroupId: props.familyGroupId || '',
        fields: 'bookings,accommodation,lastScanAtDe',
        sort: 'dateOfBirth,asc',
      },
    )
    makeObservable(this)
  }

  componentDidMount(): void {
    if (this.props.familyGroupId) {
      this.disposers.push(this.residentSearchResults.init({ readOnly: true }))
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private mapMember = (member: IResidentSearchResult) => {
    return (
      <LinkedFamilyItem
        key={member.id}
        openFamilyId={this.props.familyId}
        resident={member}
        allowRemove={this.context.permissions.family_linking >= 2}
      />
    )
  }

  render() {
    if (this.props.familyGroupId && !this.residentSearchResults.resources) {
      return null
    }
    const families: IResidentSearchResult[] =
      this.residentSearchResults.resources
        ?.map((res) => res.data)
        .filter((res) => !!res && res.data.familyId !== this.props.familyId) ||
      ([] as any)
    if (this.context.permissions.family_linking < 2 && families.length === 0) {
      return null
    }

    return (
      <div
        className={
          families.length === 0
            ? 'mt-auto bg-white border-t border-gray-300 -mx-4 -mb-4'
            : '-mx-4'
        }
      >
        <div className='flex text-md px-4 pt-2 -mb-2 text-gray-500'>
          <div className='flex-content mr-2'>Verknüpfte Familien</div>
          {this.context.permissions.family_linking >= 2 && (
            <div className='flex-content mr-auto font-normal'>
              <span onClick={this.openDialog} className='cursor-pointer has-tooltip'>
                <i className='fa fa-plus font-bold' />
                <Tooltip position='top'>
                  Verwandte Familien verknüpfen
                  <br />
                  (Erweiterter Familienverbund)
                </Tooltip>
              </span>
            </div>
          )}
        </div>

        {/* Show family heads of linked families */}
        <div className='px-4 mt-4 flex flex-col gap-4'>
          {families.map(this.mapMember)}
        </div>

        {!!this.dialogIsOpen && (
          <AddFamilyDialog onClose={this.setOpen} familyIds={this.dialogIsOpen} />
        )}
      </div>
    )
  }
}
