import * as React from 'react'
import { SideBarLayout } from 'components/SideBarLayout'
import { RastNotificationsContent } from './components/RastNotificationsContent'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import dayjs from 'dayjs'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { isStammBuilding } from 'helpers/isStamm'
import { BuildingSelectSidebar } from 'components/BuildingSelectSideBar/index'

interface Props {}

@observer
export class RastNotifications extends React.Component<Props, {}> {
  @observable selectedBuildings = new Map<string, boolean>() //key: buildingId, value: selected/not selected
  private model: Model<{ begin: string; end: string; type: string; status: string }> =
    new Model({
      begin: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
      end: dayjs().format('YYYY-MM-DD'),
      type: '',
      status: '',
    })

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private buildingFilter = (b: IBuilding): boolean => {
    return isStammBuilding(b.id)
  }

  render() {
    return (
      <SideBarLayout>
        <BuildingSelectSidebar
          label='RASt Meldungen'
          icon='far fa-folder-open'
          filter={this.buildingFilter}
          selectedBuildings={this.selectedBuildings}
        />
        <RastNotificationsContent
          key={`${this.selectedBuildings.size}-${
            this.selectedBuildings.size === 1
              ? Array.from(this.selectedBuildings.keys())[0]
              : 'all'
          }`}
          model={this.model}
          selectedBuildings={this.selectedBuildings}
        />
      </SideBarLayout>
    )
  }
}
