import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { z } from 'zod'
import { isPastDate } from '../helpers/isPastDate'
import { makeObservable, observable, runInAction } from 'mobx'
import { box } from 'services/box'

interface Props {}

@observer
export class Belegungsauswertung extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model = new Model<{ begin: string; end: string }>(
    { begin: null, end: null },
    z.object({ begin: z.string(), end: z.string() }),
  )
  @observable private loading: boolean = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private downloadReport = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (!isPastDate(this.model.values.begin) || !isPastDate(this.model.values.end)) {
      box.alert(
        'Datum ungültig',
        'Start- und Enddatum dürfen nicht in der Zukunft liegen.',
      )
      return
    }
    if (this.model.values.begin > this.model.values.end) {
      box.alert('Datum ungültig', 'Das Enddatum darf nicht vor dem Startdatum liegen.')
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'belegungsauswertung',
      this.context.instance.id,
      { begin: this.model.values.begin, end: this.model.values.end },
      'Belegungsauswertung.xlsx',
    )
    runInAction(() => (this.loading = false))
  }

  render() {
    if (!this.context.permissions.report_belegungsauswertung) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Belegungsauswertung'
        subtitle='In dieser Tabelle sind alle Personen enthalten, die zu irgendeinem Zeitpunkt
      im gewählten Zeitraum in einer Unterkunft waren.'
      >
        <div className='flex gap-2' id={this.model.id}>
          <div>
            <InputDate label='von' name='begin' model={this.model} />
          </div>
          <div>
            <InputDate label='bis' name='end' model={this.model} />
          </div>
        </div>
        <Button className='my-4' loading={this.loading} onClick={this.downloadReport}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
