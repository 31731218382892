import * as React from 'react'
import { BlogpostDialog } from './components/BlogpostDialog'
import { Dialog } from 'components/Dialog'
import { IBlogpost } from 'contracts/dashboard/interfaces/IBlogpost'
import { Card } from 'components/Card'
import { observable, action, makeObservable } from 'mobx'
import { Resource } from '@byll/hermes'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import styles from './styles.module.scss'

interface Props {
  blogpost: Resource<IBlogpost>
}

@observer
export class Blogpost extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private showBlogpostDialog: boolean = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private toggleBlogpostDialog = () => {
    this.showBlogpostDialog = !this.showBlogpostDialog
  }

  render() {
    if (!this.props.blogpost.data || !this.props.blogpost.data.isActive) {
      return null
    }
    return (
      <Card className='relative'>
        <div
          className='h-full cursor-pointer group flex flex-col'
          onClick={this.toggleBlogpostDialog}
        >
          <div className='p-4 flex-content'>
            <div className='font-bold text-xl mb-2 truncate'>
              {this.props.blogpost.data.title}
            </div>
            <p className='text-gray-700 text-base truncate'>
              {this.props.blogpost.data.subtitle}
            </p>
          </div>
          <img
            className='w-full flex-content'
            src={`/api/${this.context.instance.id}/images/p${this.props.blogpost.data.imageId}`}
            alt=''
          />
          <div className='p-4 overflow-hidden relative flex-[0_1_200px]'>
            {this.props.blogpost.data.markdown && (
              <div
                className={`text-gray-500 text-base ${styles.markdown}`}
                dangerouslySetInnerHTML={{ __html: this.props.blogpost.data.text! }}
              />
            )}
            {!this.props.blogpost.data.markdown && (
              <p className='text-gray-500 text-base whitespace-pre-line'>
                {this.props.blogpost.data.text}
              </p>
            )}
            <div className='h-[200px] absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-white' />
          </div>
          <div className='text-gray-500 flex-content px-4 pb-2 mt-auto group-hover:underline'>
            Mehr anzeigen
          </div>
        </div>
        {this.showBlogpostDialog && (
          <Dialog
            size='lg'
            open={this.showBlogpostDialog}
            setOpen={this.toggleBlogpostDialog}
          >
            <BlogpostDialog
              blogpost={this.props.blogpost.data}
              onClose={this.toggleBlogpostDialog}
            />
          </Dialog>
        )}
      </Card>
    )
  }
}
