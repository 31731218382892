import { env } from './../../../env'
import { observable } from 'mobx'

export interface AppStatus {
  connected: boolean
  maintenance: boolean | number // true: In maintenance mode. number: seconds till maintenance mode. false: no maintenance mode
  instanceId: string
  sessionId: string | null
  userId: string | null
}
export function getAppStatus(): AppStatus {
  const status: AppStatus = {
    connected: false,
    maintenance: false,
    instanceId: getInstanceId(),
    sessionId: getSessionId(),
    userId: null,
  }
  const match = (status.sessionId || '').match(/^([0-9]+)u([0-9]+)[ps].+$/)
  if (match) {
    if (match[1] !== status.instanceId) {
      status.sessionId = null
    } else {
      status.userId = match[2]
    }
  } else {
    status.sessionId = null
  }
  return observable(status)
}

function getInstanceId(): string {
  const instanceId = (window as any).inuvInstanceId
  if (typeof instanceId === 'string') {
    return instanceId
  }
  if (env.NODE_ENV === 'development') {
    return '0'
  }
  return ''
}

function getSessionId(): string | null {
  // Session id is injected into html in prod
  const sessionId = (window as any).inuvSessionId
  if (typeof sessionId === 'string') {
    return sessionId
  }

  if (env.NODE_ENV === 'development') {
    // Read session cookie from javascript. Only possible in dev environment
    // because in prod httpOnly cookies are used for security reasons.
    const value = `; ${document.cookie}`
    const parts = value.split('; isession=')
    if (parts.length === 2) {
      const fromCookie = parts.pop()?.split(';').shift()
      if (typeof fromCookie === 'string' && fromCookie.length > 0) {
        return fromCookie
      }
    }
  }
  return null
}
