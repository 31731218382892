export function getFileExtension(name: string): string {
  const parts = name.split('.')
  let ext = ''
  if (parts.length > 1) {
    ext = parts[parts.length - 1].toLowerCase()
  }

  if (ext === 'jpeg') {
    ext = 'jpg'
  }
  if (ext === 'tiff') {
    ext = 'tif'
  }

  return ext
}
