import { Message } from 'components/Message'
import * as React from 'react'

const types = {
  success: {
    parent: 'bg-green-50 text-green-600 border border-green-500 mt-6 rounded-md p-3',
    message: 'text-green-500 text-sm ml-2',
  },
  warning: {
    parent: 'bg-yellow-50 text-yellow-500 border border-yellow-500 mt-6 rounded-md p-3',
    message: 'text-yellow-400 text-sm ml-2',
  },
  error: {
    parent: 'bg-red-50 text-red-500 border border-red-500 mt-6 rounded-md p-3',
    message: 'text-red-400 text-sm ml-2',
  },
}

export interface IResidentValidationMessage {
  resident?: { id: string; firstName: string; lastName: string }
  message: string
  type: 'success' | 'warning' | 'error'
}

const errorMap = {
  success: 'success',
  warning: 'primary',
  error: 'danger',
}

interface Props {
  fallback: string // if messages are empty
  messages: IResidentValidationMessage[]
}

export const ResidentValidationMessages: React.FC<Props> = ({ messages, fallback }) => (
  <>
    {messages.length === 0 && (
      <Message color='danger' className='mt-6'>
        {fallback}
      </Message>
    )}
    {messages.map((m) => {
      if (!m.resident) {
        return (
          <Message color={(errorMap[m.type] as any) || 'primary'} className='mt-6'>
            {m.message}
          </Message>
        )
      }
      return (
        <div key={m.resident.id} className={types[m.type]?.parent}>
          <span className='text-md'>{`${m.resident.lastName.toUpperCase()}, ${
            m.resident.firstName
          }`}</span>
          <span className={types[m.type]?.message}>{m.message}</span>
        </div>
      )
    })}
  </>
)
