const units = ['B', 'kB', 'MB', 'GB', 'TB']
export const byteMagnitude = 1000 // 1024 is technically not correct, that would be kiB, etc.

export function getDiskSizeLabel(size: string | number) {
  const bytes = typeof size === 'string' ? parseInt(size, 10) : size
  for (let i = 0; i < units.length; i++) {
    // convert to unit units[i]
    const converted = Math.round(bytes / Math.pow(byteMagnitude, i))
    if (converted < byteMagnitude) {
      return `${converted} ${units[i]}`
    }
  }
  return `${Math.round(bytes / Math.pow(byteMagnitude, units.length - 1))} ${
    units[units.length - 1]
  }`
}
