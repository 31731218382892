import { toLocaleNumber } from 'contracts/general/helpers/toLocaleNumber'
import { IOccupancyFilter } from 'contracts/occupancies/interfaces/IOccupancyFilter'
import { IOccupancyMetadata } from 'contracts/occupancies/interfaces/IOccupancyMetadata'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import * as React from 'react'

interface Props {
  results: UnsyncedCollection<IOccupancy, IOccupancyMetadata, IOccupancyFilter>
}

export const ResidentOccupancySearchStats: React.FC<Props> = ({ results }) => (
  <div className='flex-auto md:text-right'>
    {results.metadata && results.metadata.count !== null && results.resources ? (
      <>
        <span className='text-gray-500 text-sm'>{`${toLocaleNumber(
          String(results.metadata.count),
        )} Zimmer`}</span>{' '}
        <span className='text-blue-500 text-sm ml-3'>
          <i className='far fa-hourglass' aria-hidden='true' />{' '}
          {results.metadata.duration} ms
        </span>
      </>
    ) : (
      <span>&nbsp;</span>
    )}
  </div>
)
