import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { ITransferPlanningSearchResult } from 'contracts/transfer/interfaces/ITransferPlanningSearchResult'
import { transferStatusMap } from '../helpers/transferStatusMap'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { Age } from 'components/Age'
import { Tooltip } from 'components/Tooltip'
import { transferStatus } from 'contracts/transfer/interfaces/transferStatusEnum'
import { TransferPlanResidentCheckOut } from './TransferPlanResidentCheckOut'
import { dayjs } from 'helpers/dayjs'

interface Props {
  planning: ITransferPlanningSearchResult
  offerCheckOut: boolean
  tooltip: string
}

export const TransferPlanResidentListItem: React.FC<Props> = observer(
  ({ planning, offerCheckOut, tooltip }) => {
    const context = React.useContext(AppContext)
    const thumbnail = getResidentImageSrc(
      context.instance.id,
      planning.resident.imageId,
      planning.resident.sex,
      'thumbnail',
    )

    const openLink = () => {
      window.open(
        `/residents/${toJbpId(+planning.resident.id).toLowerCase()}/overview`,
        '_blank',
      )
    }

    return (
      <div className='flex bg-white shadow rounded-md p-2'>
        <img
          className='rounded-full w-10 h-10'
          src={thumbnail}
          alt=''
          width={40}
          height={40}
        />
        <div>
          <div className='flex ml-2'>
            <div
              onClick={openLink}
              className='cursor-pointer text-gray-600 flex-auto truncate max-w-[200px] underline hover:text-blue'
            >
              {`${planning.resident.lastName.toUpperCase()}, ${
                planning.resident.firstName
              }`}
              <Age
                className='ml-1 relative -top-[1px]'
                sex={planning.resident.sex}
                dateOfBirth={planning.resident.dateOfBirth}
              />
            </div>
          </div>
          <div className='text-sm flex'>
            <span className='has-tooltip'>
              <img
                src={transferStatusMap.get(planning.status)}
                alt=''
                className='ml-2 h-5'
              />
              {planning.status !== transferStatus.timeout && (
                <Tooltip position='right'>{tooltip}</Tooltip>
              )}
            </span>
            {offerCheckOut &&
              planning.resident.accommodation.type === 'internal-residence' &&
              !planning.resident.accommodation.endAt && (
                <TransferPlanResidentCheckOut
                  className='relative ml-2 cursor pointer inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gradient-to-r from-yellow-500 to-yellow-600 hover:bg-gradient-to-r hover:from-yellow-500 hover:to-yellow-500 focus:ring-yellow-500'
                  planning={planning}
                />
              )}
            {planning.resident.accommodation.type === 'internal-residence' &&
              planning.resident.accommodation.endAt && (
                <span className='ml-2 text-gray-600'>{`Belegungsende: ${dayjs(
                  planning.resident.accommodation.endAt,
                ).format('DD.MM.')}`}</span>
              )}
          </div>
        </div>
      </div>
    )
  },
)
