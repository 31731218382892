import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { InventoryBundlePreview } from './components/InventoryBundlePreview'
import { InventoryBundleForm } from './components/InventoryBundleForm'

interface Props {
  bundle: IInventoryBundle
  compoundId: string
}

export const InventoryBundle: React.FC<Props> = ({ bundle, compoundId }) => {
  const [edit, setEdit] = React.useState(false)
  const navigate = useNavigate()
  if (!edit) {
    return <InventoryBundlePreview bundle={bundle} setEdit={setEdit} />
  }

  return (
    <InventoryBundleForm
      compoundId={compoundId}
      bundle={bundle}
      navigate={navigate}
      setEdit={setEdit}
    />
  )
}
