import * as React from 'react'
import { getAge } from 'helpers/getAge'

interface Props {
  dateOfBirth: string | null
  sex: 'female' | 'male' | 'divers' | null
  className?: string
}

export class Age extends React.Component<Props, {}> {
  render() {
    const age = getAge(this.props.dateOfBirth)

    let className =
      'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white'
    let sex = '-'
    if (this.props.sex === 'male') {
      sex = 'm'
      className =
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-500 text-white'
    } else if (this.props.sex === 'female') {
      sex = 'w'
      className =
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-500 text-white'
    }
    if (this.props.className) {
      className += ' ' + this.props.className
    }

    return (
      <span className={className}>
        {sex + ' / ' + (typeof age === 'number' ? age : '-')}
      </span>
    )
  }
}
