import { hermes } from '@byll/hermes'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentLetterEntry } from 'contracts/residents/interfaces/IResidentLetterEntry'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { OverviewLetters } from './OverviewLetters'

interface Props {
  resident: IResident
  letters: IResidentLetterEntry[] | null
}

function mapIdTag(tag: string | null, i) {
  if (!tag) {
    return null
  }
  return (
    <span
      key={i}
      className='mr-2 inline-flex items-center rounded-full bg-gray-500 px-2.5 py-0.5 text-xs font-medium text-white'
    >
      {tag}
    </span>
  )
}

export const ShortProfile: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  const [nationality, setNationality] = React.useState(null)
  React.useEffect(() => {
    if (!props.resident.nationalityId) {
      return
    }
    hermes
      .getOnceNew<any>(
        `/api/${context.instance.id}/nationalities/${props.resident.nationalityId}`,
      )
      .then((data) => {
        setNationality(data.label)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className={`bg-gray-100 text-blick rounded-md p-4 flex-auto w-1/2 overflow-hidden`}
    >
      <div>
        Name: <b>{`${props.resident.lastName}, ${props.resident.firstName}`}</b>
      </div>
      <div>
        ID:{' '}
        {[
          toJbpId(+props.resident.id),
          props.resident.mId ? `MID ${props.resident.mId}` : null,
        ].map(mapIdTag)}
      </div>
      <div>
        Geburtsdatum:{' '}
        <b>
          {props.resident.dateOfBirth
            ? dayjs(props.resident.dateOfBirth).format('DD.MM.YYYY')
            : '-'}
        </b>
      </div>
      <div>
        Staatsangehörigkeit: <b>{nationality}</b>
      </div>
      <div>
        Muttersprache: <b>{props.resident.nativeLanguage}</b>
      </div>
      {props.resident.oldNames.length > 0 && (
        <div>
          Frühere Namen:{' '}
          <b>
            {props.resident.oldNames.map((o, i) => (
              <div key={i}>{`${o.lastName.toLocaleUpperCase()}, ${o.firstName}${
                o.aliasName ? ` | Alias: ${o.aliasName}` : ''
              }`}</div>
            ))}
          </b>
        </div>
      )}
      {props.letters && props.letters.length > 0 && (
        <OverviewLetters letters={props.letters} />
      )}
    </div>
  )
})
