import * as React from 'react'

interface Props {
  label: string
}

export class TransferPlanningListColumns extends React.Component<Props, {}> {
  render() {
    return (
      <tr>
        <th scope='col' className='py-3.5 px-3 text-left text-sm text-gray-900 bold'>
          Für {this.props.label} geplant
        </th>
        <th scope='col' className='px-3 py-3.5 text-left text-sm text-gray-900'>
          Bewohner
        </th>
        <th scope='col' className='px-3 py-3.5 text-left text-sm text-gray-900'>
          Geplant von
        </th>
        <th scope='col' className='px-3 py-3.5 text-left text-sm text-gray-900'>
          Derzeitige Unterkunft
        </th>
        <th scope='col' className='px-3 py-3.5 text-left text-sm text-gray-900'>
          Transportmittel
        </th>
        <th scope='col' className='px-3 py-3.5 text-left text-sm text-gray-900'>
          Ziel
        </th>
        <th scope='col' className='px-3 py-3.5 text-left text-sm text-gray-900'>
          Status
        </th>
        <th></th>
      </tr>
    )
  }
}
