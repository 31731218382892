import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { observer } from 'mobx-react'
import { ToggleContext } from '../models/ToggleContext'

interface Props {}

@observer
export class SideBar extends React.Component<Props, {}> {
  static contextType = ToggleContext

  render() {
    return (
      <>
        <Transition.Root show={this.context.isOpen} as={Fragment}>
          <Dialog
            as='div'
            className='fixed inset-0 flex z-50 md:hidden'
            onClose={this.context.toggle}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <div className='relative flex-1 flex flex-col max-w-xs w-full bg-white'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute top-0 right-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={this.context.toggle}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                    </button>
                  </div>
                </Transition.Child>
                {this.context.isOpen && (
                  <div className='flex-1 h-0 overflow-y-auto flex flex-col relative'>
                    {this.props.children}
                  </div>
                )}
              </div>
            </Transition.Child>
            <div className='flex-shrink-0 w-14' aria-hidden='true'>
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden md:flex md:w-80 md:flex-col md:fixed md:inset-y-0 z-20 shadow-md'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          {!this.context.isOpen && (
            <div className='flex-grow border-r border-gray-200 bg-white h-full relative'>
              <div className='flex flex-col overflow-y-auto absolute top-14 left-0 right-0 bottom-0'>
                {this.props.children}
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}
