import { Model } from 'components/Form/Model'
import { dayjs } from 'helpers/dayjs'
import { IOccupancyTimeModel } from '../interfaces/IOccupancyTimeModel'

export function getOccupancyTimeModel(): Model<IOccupancyTimeModel> {
  const now = dayjs()
  return new Model<IOccupancyTimeModel>({
    date: now.format('YYYY-MM-DD'),
    time: '',
    at: 'now',
  })
}
