import { hermes } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { toast } from 'react-toastify'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { InputCompound } from 'components/Form/components/InputCompound'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'

interface Props {
  onClose: () => void
  targetCompoundId: string
}

@observer
export class InventoryCopyDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading = false
  @observable.ref private error: string | JSX.Element | null = null
  private readonly model = new Model<{ sourceCompoundId: string | null }>({
    sourceCompoundId: null,
  })

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private compoundFilter = (compound: ICompound) =>
    compound.hasInventory && compound.id !== this.props.targetCompoundId

  private copy = async () => {
    if (!this.model.values.sourceCompoundId) {
      runInAction(
        () =>
          (this.error =
            'Bitte wählen Sie die Quellunterkunft aus, von der das Inventar kopiert werden soll.'),
      )
      return
    }

    try {
      runInAction(() => (this.loading = true))
      await hermes.create(`/api/${this.context.instance.id}/inventory/copyItems`, {
        sourceCompoundId: this.model.values.sourceCompoundId,
        targetCompoundId: this.props.targetCompoundId,
      })
      toast.success(`Das Inventar wurde erfolgreich kopiert.`)
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.loading = false
        this.error =
          e?.id === ConflictError.id
            ? e.message
            : 'Das Inventar konnte nicht kopiert werden.'
      })
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Inventar kopieren
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-6'>
            {this.error}
          </Message>
        )}

        {!this.error && (
          <Message color='primary' className='mt-6'>
            Mit diesem Dialog können Sie das gesamte Inventar, einschließlich der Pakete,
            aus einer anderen Unterkunft vollständig in die aktuelle Unterkunft kopieren.
          </Message>
        )}

        <div className='relative mt-4'>
          <InputCompound
            label='Quellunterkunft'
            model={this.model}
            name='sourceCompoundId'
            filter={this.compoundFilter}
          />

          <div className='mt-4 text-right'>
            <Button
              disabled={this.loading}
              color='secondary'
              outline
              onClick={this.props.onClose}
            >
              Abbrechen
            </Button>
            <Button
              disabled={this.loading}
              color='primary'
              className='ml-3'
              onClick={this.copy}
            >
              Inventar kopieren
            </Button>
          </div>
        </div>
        {this.loading && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
