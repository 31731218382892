import { jbpIdAlphabet as alphabet } from './jbpIdAlphabet'

export function toJbpId(id: number): string {
  if (id < 0) {
    throw new Error('ID darf nicht negativ sein.')
  }
  const len = alphabet.length
  let jbpId = ''

  for (let i = 0; id > 0 || i < 4; i++) {
    jbpId = alphabet[id % len] + jbpId
    id = Math.floor(id / len)
  }

  return jbpId
}
