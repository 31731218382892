import { hermes } from '@byll/hermes'
import axios from 'axios'
import { Button } from 'components/Form/components/Button'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { Spinner } from 'components/Spinner'
import { Tooltip } from 'components/Tooltip'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { IDocumentationExportEntry } from 'contracts/residents/interfaces/IDocumentationExportEntry'
import { IDokuExportData } from 'contracts/residents/interfaces/IDokuExportData'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

const sexColors = {
  male: 'bg-blue-500 text-white border-blue-500',
  female: 'bg-pink-500 text-white border-pink-500',
}

interface Props {
  residentId: string
  compoundId: string
  family: 'yes' | 'no'
  onClose: () => void
  setStepDone: (token: string) => void
}

@observer
export class DocumentationExportDocuments extends React.Component<Props, {}> {
  static contextType = AppContext

  private readonly model: Model<{ exportAttachments: boolean }>
  @observable private loading = true
  @observable private error: string | null = null
  @observable.ref dokus: IDocumentationExportEntry[] | null = null
  @observable private readonly documents = new Set<string>() // Document ids that should be included

  constructor(props: Props) {
    super(props)
    this.model = new Model({ exportAttachments: false })
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    try {
      const response = await axios.get<IDokuExportData>(
        `/api/${this.context.instance.id}/residents/${this.props.residentId}/documentationExport?compoundId=${this.props.compoundId}&family=${this.props.family}&onlyExportableAttachments=yes`,
      )
      runInAction(() => {
        this.loading = false
        this.dokus = response.data.resources
        if (!response.data.metadata.hasDocumentations) {
          this.error =
            'Der Bewohner hat in dieser Einrichtung keine Dokumentationen. Ein Export ist daher leider nicht möglich. Wählen Sie eine andere Einrichtung aus, um einen Export zu erstellen.'
        }
      })
    } catch (_e) {
      runInAction(() => {
        this.loading = false
        this.error = 'Die Verfügbaren Anhänge konnten nicht geladen werden.'
      })
    }
  }

  private dokuMapper = (doku: IDocumentationExportEntry) => {
    return (
      <div key={doku.id} className='bg-white shadow rounded-md p-4'>
        <div className='truncate'>
          <span>{dayjs(doku.date).format('DD.MM.YYYY')}</span>
          <span className='font-medium text-gray-500 ml-3'>
            ·&nbsp;&nbsp;&nbsp;{`${doku.createdBy.firstName} ${doku.createdBy.lastName}`}
          </span>
        </div>
        <div
          className='mb-0.5 text-sm text-gray-500 truncate -mx-1'
          style={{ lineHeight: '26px' }}
        >
          {doku.topics.map((t) => (
            <span
              key={t}
              className='mx-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 border border-gray-500'
            >
              {t}
            </span>
          ))}
          {doku.residents.map((r) => (
            <span
              key={r.id}
              className={`mx-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${
                sexColors[r.sex || ''] || 'bg-gray-500 border-gray-500 text-white'
              }`}
            >
              {`${r.lastName.toUpperCase()}, ${r.firstName}`}
            </span>
          ))}
        </div>
        {doku.documents.map(this.attachmentMapper)}
      </div>
    )
  }

  private attachmentMapper = (attachment: IDocumentMetadata) => {
    return (
      <div key={attachment.id} className='truncate -ml-4 pl-4 text-sm py-0.5'>
        <input
          type='checkbox'
          checked={this.documents.has(attachment.id)}
          onChange={() => this.toggleDocument(attachment.id)}
          className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mr-2'
        />
        <a
          href={`/documents/${attachment.id}`}
          className='underline hover:text-blue-500'
          target='_blank'
          rel='noopener noreferrer'
        >
          {attachment.name}{' '}
          <span className='text-blue-500'>
            <i className='fas fa-external-link-alt' />
          </span>
        </a>
      </div>
    )
  }

  @action private toggleDocument = (id: string) => {
    if (this.documents.has(id)) {
      this.documents.delete(id)
    } else {
      this.documents.add(id)
    }
  }

  @action private selectAll = () => {
    for (const doku of this.dokus || []) {
      for (const attachment of doku.documents) {
        this.documents.add(attachment.id)
      }
    }
  }

  @action private selectNone = () => {
    this.documents.clear()
  }

  private onSubmit = async () => {
    try {
      runInAction(() => {
        this.error = null
        this.loading = true
      })
      const response = await hermes.create(
        `/api/${this.context.instance.id}/residents/${this.props.residentId}/documentationExport`,
        {
          compoundId: this.props.compoundId,
          family: this.props.family,
          documentIds: this.model.values.exportAttachments
            ? Array.from(this.documents.values())
            : [],
        },
        { timeout: 60 * 1000 },
      )
      this.props.setStepDone(response.batchId)
    } catch (e) {
      runInAction(() => {
        this.loading = false
        this.error = 'Der Export konnte leider nicht erstellt werden.'
      })
    }
  }

  render() {
    if (this.loading || this.error || !this.dokus) {
      return (
        <>
          <div className='min-h-[200px] py-4'>
            {this.loading && <Spinner />}
            {this.error && <Message color='danger'>{this.error}</Message>}
          </div>

          <div className='-mb-4 -mx-6 px-6 text-right sticky bottom-0 z-1 py-4 bg-white border-t border-gray-200'>
            <Button color='secondary' outline onClick={this.props.onClose}>
              Schließen
            </Button>
          </div>
        </>
      )
    }

    return (
      <>
        <div className='min-h-[200px] pb-4 bg-gray-100 border-t border-gray-200 -mx-6 px-6 mt-4 flex flex-col gap-4'>
          <Message
            color='primary'
            className='border border-indigo-300 mt-4'
          >{`Der Export beinhaltet die vollständige Dokumentation ${
            this.props.family === 'yes' ? 'dieser Familie' : 'dieses Bewohners'
          } aus der gewählten Einrichtung.`}</Message>
          <div className='flex'>
            <InputCheckbox
              className='flex-content'
              model={this.model}
              name='exportAttachments'
              onChange={this.selectAll}
              label='PDF Anhänge einschließen'
            />
            {this.dokus.length === 0 && this.model.values.exportAttachments && (
              <span className='ml-2 inline-flex items-center rounded-full bg-red-500 px-2.5 py-0.5 text-xs font-medium text-white'>
                Keine Anhänge vorhanden
              </span>
            )}
            {this.dokus.length > 0 && this.model.values.exportAttachments && (
              <div className='text-gray-400 text-xs mt-0.5 ml-2'>
                <span
                  onClick={this.selectAll}
                  className='has-tooltip pl-2 pr-1 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100'
                  style={{ borderRadius: '9999px 0 0 9999px' }}
                >
                  <Tooltip>Alle Anhänge auswählen</Tooltip>
                  <span>Alle auswählen</span>
                </span>
                <span
                  onClick={this.selectNone}
                  className='has-tooltip pl-1 pr-2 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white hover:bg-blue-500 cursor-pointer'
                  style={{ borderRadius: '0 9999px 9999px 0' }}
                >
                  <Tooltip>Alle Anhänge abwählen</Tooltip>
                  <span>Alle abwählen</span>
                </span>
              </div>
            )}
          </div>

          {this.dokus.length > 0 &&
            this.model.values.exportAttachments &&
            this.dokus.map(this.dokuMapper)}
        </div>

        <div className='-mb-4 -mx-6 px-6 text-right sticky bottom-0 z-1 py-4 bg-white border-t border-gray-200'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            Abbrechen
          </Button>
          <Button color='primary' className='ml-3' onClick={this.onSubmit}>
            Export erstellen
          </Button>
        </div>
      </>
    )
  }
}
