import * as React from 'react'
import styles from './styles.module.scss'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { OtherPreview } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/OtherPreview'
import { Wait } from 'modules/Pdf/components/Wait'

export const StjaBerichtigungPersonendatenP42a: React.FC<DocumentTemplateProps> = () => {
  return (
    <div className={styles.page}>
      <OtherPreview
        id='StjaBerichtigungPersonendatenP42a'
        name='Berichtigung Personendaten gem. § 42 a.docx'
      />
      <Wait />{' '}
      {/* Infinite waiter, because this placeholder must not be used for rendering pdfs */}
    </div>
  )
}
