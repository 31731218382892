import { IPermissions } from 'contracts/users/interfaces/IPermissions'
import { IUser } from 'contracts/users/interfaces/IUser'
import { IUserDefaults } from 'contracts/users/interfaces/IUserDefaults'
import * as React from 'react'

export interface AppContextProps {
  session: { id: string }
  instance: { id: string }
  user: IUser
  permissions: IPermissions
  defaults: IUserDefaults
}

// This object implements BusinessEntityContextProps, but it throws
// an error instead of returning requested props. This mechanism helps
// to detect forbidden context access outside of provider tree.
const warning: AppContextProps = {
  get session(): { id: string } {
    console.warn('Accessed context.session without context provider.')
    throw new Error('Accessed context.session without context provider.')
  },
  get instance(): { id: string } {
    console.warn('Accessed context.instance without context provider.')
    throw new Error('Accessed context.instance without context provider.')
  },
  get user(): IUser {
    console.warn('Accessed context.user without context provider.')
    throw new Error('Accessed context.user without context provider.')
  },
  get permissions(): IPermissions {
    console.warn('Accessed context.permissions without context provider.')
    throw new Error('Accessed context.permissions without context provider.')
  },
  get defaults(): IUserDefaults {
    console.warn('Accessed context.defaults without context provider.')
    throw new Error('Accessed context.defaults without context provider.')
  },
}

export const AppContext = React.createContext<AppContextProps>(warning)
