import * as React from 'react'

interface Props {
  error: string
  meta: string
}

export const UploadTimeSheetDialogPageTileConcatError: React.FC<Props> = (props) => (
  <div className='group relative flex bg-white shadow-md h-[70px] mt-4'>
    <div className='flex-[0_0_70px] bg-center relative'>
      <div
        className='bg-yellow-500 rounded-full text-white text-center'
        style={{ height: 50, width: 50, margin: 10, fontSize: 26, paddingTop: 7 }}
      >
        <i className='fas fa-clone' />
      </div>
    </div>

    <div className='flex-auto truncate'>
      <div className='flex'>
        <div style={{ flex: '0 1 auto', margin: '13px 15px 0 5px' }} className='truncate'>
          Stundenzettel unvollständig. {props.error}
        </div>
      </div>
      <div
        className={`text-gray-400 text-sm truncate`}
        style={{ margin: '0 15px 0 5px' }}
      >
        {props.meta}
      </div>
    </div>
  </div>
)
