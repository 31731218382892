import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Message } from 'components/Message'
import { Button } from 'components/Form/components/Button'
import { AppContext } from 'services/connection/models/AppContext'
import { DocumentDropzone } from 'modules/Documents/components/DocumentDropzone'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { SmallDropzoneView } from 'modules/Documents/components/SmallDropzoneView'
import axios, { AxiosProgressEvent } from 'axios'
import { sleep } from 'helpers/sleep'
import { DialogOverlayArea } from 'components/Dialog/components/DialogOverlaySpinner/components/DialogOverlayArea'
import { CircularUploadProgress } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/CircularUploadProgress'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { download } from 'helpers/download'
import {
  ITimeSheetPage,
  ITimeSheetPageDone,
  ITimeSheetPageError,
} from 'contracts/workplan/interfaces/ITimeSheetPage'
import { UploadTimeSheetDialogPageTile } from './components/UploadTimeSheetDialogPageTile'
import { ICreateTimeSheetDocumentFromPages } from 'contracts/workplan/interfaces/ICreateTimeSheetDocumentFromPages'
import { getTimeSheetDocumentCreatables } from './helpers/getTimeSheetDocumentCreatables'
import { UploadTimeSheetDialogPageTileConcatError } from './components/UploadTimeSheetDialogPageTileConcatError'
import { Spinner } from 'components/Spinner'

interface Props {
  onClose: (val?: any) => void
}

@observer
export class UploadTimeSheetsDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  // upload (batch pdf), processing (detect qr code on every page and assign to resident), done (show result)
  @observable private step: 'upload' | 'processing' | 'saving' | 'done' = 'upload'
  @observable private zip: string | 'processing' | null = null // string: uuid for download, processing: creating concat, null: not applicable
  @observable private upload: { percentage: number } | null = null
  @observable private pages: ITimeSheetPage[] = []
  @observable.ref private message: JSX.Element | string | null = null
  @observable.ref private docs: (ICreateTimeSheetDocumentFromPages & {
    error?: string
    meta?: string
  })[] = []
  private mounted = true
  private dialogRef: any = null
  private cancelUpload: (() => void) | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancelUpload?.()
  }

  private onUpload = async (doc: IDocumentMetadata & { file: Blob }) => {
    if (this.upload) {
      return
    }
    const form = new FormData()
    form.append('file', doc.file)

    const source = axios.CancelToken.source()
    this.cancelUpload = () => source.cancel('Canceled by user')
    const uploadPromise = axios.post(`/api/${this.context.instance.id}/pdf/split`, form, {
      cancelToken: source.token,
      onUploadProgress: action((event: AxiosProgressEvent) => {
        if (event.total && event.loaded / event.total <= 1 && this.upload) {
          // Start at 5% to visualize pending upload for files that are scheduled to upload.
          this.upload.percentage = 0.05 + 0.95 * (event.loaded / event.total)
        }
      }),
    })
    try {
      runInAction(() => {
        this.upload = { percentage: 0.05 }
        this.message = null
      })
      const response = (await uploadPromise) as any
      this.cancelUpload = null
      runInAction(() => {
        if (this.upload) {
          this.upload.percentage = 1
        }
      })
      await sleep(300)

      // Next steps
      runInAction(() => {
        this.pages = response.data.pages.map((page, i) => ({
          id: page,
          batchPageNumber: i + 1,
          status: 'pending',
        }))
        this.step = 'processing'
        this.upload = null
        this.message = null
      })
      for (let p = 0; p < this.pages.length && p < 5; p++) {
        this.onProcess()
      }
    } catch (e: any) {
      if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
        // Already handeled
      } else {
        runInAction(() => {
          this.upload = null
          this.message =
            e?.response?.data?.id === ConflictError.id
              ? e.response.data.message
              : 'Beim Upload ist ein Fehler aufgetreten'
        })
      }
    }
  }

  private onProcess = async () => {
    if (!this.mounted || this.step === 'saving') {
      return
    }
    // Get next pending page for processing
    const page = this.pages.find((p) => p.status === 'pending')
    if (!page) {
      if (!this.pages.find((p) => p.status === 'processing')) {
        runInAction(() => (this.step = 'saving'))
        this.onSave()
      }
      return
    }
    runInAction(() => (page.status = 'processing'))
    this.scrollToBottom()
    try {
      const response: any = await axios.post<ITimeSheetPageDone>(
        `/api/${this.context.instance.id}/pdf/qr`,
        { id: page.id, type: 'employee time sheet' },
        { timeout: 20000 },
      )
      const p = page as ITimeSheetPageDone
      runInAction(() => {
        p.status = 'done'
        p.employeeId = response.data.employeeId
        p.employeeName = response.data.employeeName
        p.month = response.data.month
        p.qrCodeId = response.data.qrCodeId
        p.pageNumber = response.data.pageNumber
        p.numberOfPages = response.data.numberOfPages
      })
    } catch (e: any) {
      runInAction(() => {
        const p = page as ITimeSheetPageError
        p.status = 'error'
        p.message =
          e?.response?.data?.id === ConflictError.id
            ? e.response.data.message
            : 'Leider wurde kein gültiger QR Code erkannt.'
      })
    }
    this.onProcess()
  }

  private onSave = async () => {
    runInAction(() => (this.docs = getTimeSheetDocumentCreatables(this.pages)))
    if (!this.pages.find((p) => p.status === 'done')) {
      runInAction(() => {
        this.step = 'done' // Show close button instead of cancel
        this.message = (
          <div className={`mt-6 p-6 bg-red-500 text-white rounded-md shadow-md`}>
            In diesem Sammeldokument konnten leider keine QR Codes erkannt werden. Bitte
            beachten Sie, dass PDF Seiten nicht gedreht sein dürfen. Sie dürfen also nicht
            im Querformat vorliegen und auch nicht auf dem Kopf stehen. Zusätzlich muss
            jedes Blatt Papier so gescannt werden, dass es auf einer eigenen PDF Seite
            dargestellt wird. Eine Zusammenfassung mehrerer Papierblätter auf einer PDF
            Seite ist nicht zulässig. Bitte wiederholen Sie den Vorgang, sobald Ihr
            Sammel-PDF den Vorgaben entspricht.
          </div>
        )
      })
      setTimeout(() => this.scrollToBottom(), 400)
      return
    }

    if (!this.docs.find((d) => !d.error)) {
      runInAction(() => {
        this.step = 'done' // Show close button instead of cancel
        this.message = (
          <div className={`mt-6 p-6 bg-red-500 text-white rounded-md shadow-md`}>
            Leider wurden in diesem Dokument keine vollständigen Stundenzettel gefunden.
            Das kann passieren, wenn der Stundenzettel z. B. zwei Seiten hat, aber nur die
            erste Seite davon richtig erkannt wurde. Oben sind alle Seiten des
            Sammeldokuments aufgelistet. Sie können jede der Seiten mit dem Pfeil auf der
            rechten Seite in einem neuen Tab öffnen und überprüfen, ob der QR Code gut
            sichtbar ist.
          </div>
        )
      })
      setTimeout(() => this.scrollToBottom(), 400)
      return
    }

    try {
      runInAction(() => {
        this.message = (
          <div
            className={`mt-4 p-6 bg-white rounded-md shadow-md min-h-[168px] flex flex-col`}
          >
            <div className='text-gray-500 text-center mb-2 flex-content'>
              Stundenzettel speichern...
            </div>
            <div className='relative flex-auto'>
              <Spinner />
            </div>
          </div>
        )
      })
      setTimeout(() => this.scrollToBottom(), 400)
      const timesheets = this.docs.filter((d) => !d.error)
      await axios.post(
        `/api/${this.context.instance.id}/workplan/signatures/docs/timesheetPdfConcat`,
        { timesheets },
      )
      runInAction(() => {
        this.step = 'done' // Show close button instead of cancel
        if (
          !this.pages.find((p) => p.status !== 'done') &&
          !this.docs.find((d) => !!d.error)
        ) {
          this.message = (
            <div className={`mt-6 p-6 bg-green-500 text-white rounded-md shadow-md`}>
              Alle Seiten wurden erfolgreich extrahiert, zugeordnet und automatisch in den
              jeweils richtigen Benutzerprofilen hinterlegt. Sie können den Dialog jetzt
              schließen.
            </div>
          )
        } else {
          this.message = (
            <div className={`mt-6 p-6 bg-yellow-500 text-white rounded-md shadow-md`}>
              {timesheets.length === 1
                ? 'Ein Stundenzettel konnte erfolgreich extrahiert, zugeordnet und automatisch im richtigen Benutzerprofil hinterlegt werden. '
                : `Es konnten ${timesheets.length} Stundenzettel erfolgreich extrahiert, zugeordnet und automatisch in den jeweils richtigen Benutzerprofilen hinterlegt werden. `}
              Leider gibt es zusätzliche Seiten, die nicht richtig erkannt wurden oder
              nicht vollständig im Sammeldokument vorliegen.
              {this.pages.find((p) => p.status === 'error') && (
                <>
                  &nbsp; Diese Seiten sind oben mit einem roten Kreuz gekennzeichnet. Sie
                  können jede dieser Seiten mit dem Pfeil auf der rechten Seite in einem
                  neuen Tab öffnen und überprüfen, ob der QR Code gut sichtbar ist.
                </>
              )}
            </div>
          )
        }
      })
      setTimeout(() => this.scrollToBottom(), 400)
    } catch (_e) {
      runInAction(() => {
        this.step = 'done' // Show close button instead of cancel
        this.message = (
          <div className={`mt-6 p-6 bg-red-500 text-white rounded-md shadow-md`}>
            Beim Speichern ist ein Fehler aufgetreten. Versuchen Sie es erneut oder wenden
            Sie sich an einen Administrator.
          </div>
        )
      })
      setTimeout(() => this.scrollToBottom(), 400)
    }
  }

  private onZip = async (e) => {
    e.preventDefault()
    runInAction(() => (this.zip = 'processing'))
    try {
      const files = this.pages
        .filter((p) => p.status === 'error')
        .map((p) => ({
          id: p.id,
          name: `Seite_${String(p.batchPageNumber).padStart(4, '0')}.pdf`,
        }))
      const response: any = await axios.post(
        `/api/${this.context.instance.id}/download/zip`,
        { files },
      )
      runInAction(() => (this.zip = response.data.id))
    } catch (_e) {
      runInAction(() => (this.zip = null))
      alert('Das ZIP Archiv konnte leider nicht erstellt werden.')
    }
  }

  private onDownload = () => {
    download(`/api/${this.context.instance.id}/download/files/${this.zip}`)
  }

  private setRef = (ref: HTMLDivElement | null) => {
    if (!ref) {
      return
    }
    this.dialogRef = ref.parentElement?.parentElement?.parentElement ?? null
  }

  private scrollToBottom = () => {
    if (this.dialogRef && this.mounted) {
      try {
        this.dialogRef.scrollTo({ top: this.dialogRef.scrollHeight, behavior: 'smooth' }) // .scrollTop = this.dialogRef.scrollHeight
      } catch (_e) {
        /* */
      }
    }
  }

  render() {
    return (
      <>
        <div
          className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'
          ref={this.setRef}
        >
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Stundenzettel Sammeldokument hochladen
            </Dialog.Title>
          </div>
        </div>

        {this.message && this.step === 'upload' && (
          <Message color='danger' className='mt-6'>
            {this.message}
          </Message>
        )}

        {this.step === 'upload' && (
          <div className='relative mt-12'>
            <DocumentDropzone
              scope='resident'
              onSelect={this.onUpload}
              view={SmallDropzoneView}
              accept='application/pdf,.pdf'
              maxMb={90}
            />
            <div className='text-center mb-12'>
              <Button
                color='secondary'
                outline
                onClick={this.props.onClose}
                style={{ width: 162 }}
              >
                Abbrechen
              </Button>
            </div>
          </div>
        )}

        {(this.step === 'processing' ||
          this.step === 'saving' ||
          this.step === 'done') && (
          <div className='mt-4 -mx-4 sm:-mx-6'>
            <div className='bg-gray-100 px-6 pb-6 pt-2 border-t border-gray-200'>
              {/* Processing status of individual pages */}
              {this.pages.map((page, i) => (
                <UploadTimeSheetDialogPageTile key={i} page={page} />
              ))}

              {/* Warnings for incomplete documents */}
              {this.docs
                .filter((p) => !!p.error)
                .map((page, i) => (
                  <UploadTimeSheetDialogPageTileConcatError
                    key={i}
                    error={page.error!}
                    meta={page.meta || ''}
                  />
                ))}

              {this.message}
            </div>

            <div
              className='py-4 -mb-4 px-6 sticky text-right bottom-0 bg-white border-t border-gray-200'
              style={{ borderRadius: '0 0 8px 8px' }}
            >
              <Button color='secondary' outline onClick={this.props.onClose}>
                {this.step === 'done' ? 'Schließen' : 'Abbrechen'}
              </Button>
            </div>
          </div>
        )}

        {this.upload && !this.message && (
          <DialogOverlayArea opaque>
            <CircularUploadProgress onCancel={this.props.onClose} upload={this.upload} />
          </DialogOverlayArea>
        )}
      </>
    )
  }
}
