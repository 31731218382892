import React from 'react'
import { Model } from 'components/Form/Model'
import { InputDate } from 'components/Form/components/InputDate'
import { Message } from 'components/Message'
import { RoundIcon } from 'components/RoundIcon'
import { observer } from 'mobx-react'
import { Button } from 'components/Form/components/Button'

interface Props {
  model: Model<{ plannedCheckOutDate: string | null }>
  resetDate: () => void
  onStep: (step: string) => void
  onClose: () => void
}

@observer
export class CompoundCheckOutDialogSetDate extends React.Component<Props, {}> {
  render() {
    return (
      <div className='mt-2'>
        <Message color='primary'>
          Sie können ein geplantes Abgabedatum für das Gelände festlegen und im nächsten
          Schritt alle Bewohner auschecken.
        </Message>
        <div id={this.props.model.id} className='mt-6 flex'>
          <InputDate
            className='flex-auto'
            model={this.props.model}
            name='plannedCheckOutDate'
            label='Geplantes Abgabedatum'
          />
          <RoundIcon
            classNameContainer='flex-content my-auto ml-2'
            color='warning'
            icon='fa fa-undo'
            tooltip={{ position: 'right', text: 'Zurücksetzen' }}
            onClick={this.props.resetDate}
          />
        </div>

        <div className='mt-6 flex gap-2 justify-between'>
          <Button
            color='secondary'
            className='ml-auto'
            outline
            onClick={this.props.onClose}
          >
            Schließen
          </Button>
          {this.props.model.values.plannedCheckOutDate && (
            <Button onClick={() => this.props.onStep('checkOut')}>Weiter</Button>
          )}
        </div>
      </div>
    )
  }
}
