import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { z } from 'zod'
import { isStammCompound } from 'helpers/isStamm'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { hermes } from '@byll/hermes'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { InputCompound } from 'components/Form/components/InputCompound'

interface Props {}

@observer
export class Hygienemittelbestand extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{
    compoundIds: string
  }>
  @observable private loading: boolean = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    const validator = z.object({
      compoundIds: z.string().min(1),
    })
    this.model = new Model(
      {
        compoundIds: context.defaults.responsibilityCompoundId,
      },
      validator,
    )

    if (
      context.permissions.report_hygienemittelbestand === 1 &&
      !isStammCompound(this.model.values.compoundIds || '')
    ) {
      this.model.values.compoundIds = ''
    }
  }

  private downloadReport = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'hygienemittelbestand',
      this.context.instance.id,
      {
        compoundIds: this.model.values.compoundIds.split(','),
      },
      'hygienemittelbestand.xlsx',
    )
    runInAction(() => (this.loading = false))
  }

  @action
  private selectAll = () => {
    const compounds = hermes.indexFromStore<ICompound>(
      `/api/${this.context.instance.id}/accommodations/compounds`,
    )
    this.model.values.compoundIds =
      compounds
        ?.map((c) => c.id)
        .filter((id) =>
          this.context.permissions.report_hygienemittelbestand > 1
            ? true
            : isStammCompound(id),
        )
        .join(',') || ''
  }

  @action
  private selectNone = () => {
    this.model.values.compoundIds = ''
  }

  render() {
    if (!this.context.permissions.report_hygienemittelbestand) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Hygienemittelbestand'
        subtitle='Wählen Sie einen Monat und mindestens ein Gelände aus.'
      >
        <div className='max-w-xl'>
          <div className='text-gray-400 text-xs mb-4'>
            <span
              onClick={this.selectAll}
              className='pl-2 pr-1 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer border-r border-gray-100'
              style={{ borderRadius: '4px 0 0 4px' }}
            >
              Alle auswählen
            </span>
            <span
              onClick={this.selectNone}
              className='pl-1 pr-2 py-0.5 text-xs font-medium bg-gray-400 text-white hover:bg-blue-500 cursor-pointer'
              style={{ borderRadius: '0 4px 4px 0' }}
            >
              Alle abwählen
            </span>
          </div>

          <div
            id={this.model.id}
            className='grid grid-cols-1 sm:grid-cols-[110px_110px_1fr] gap-4'
          >
            <InputCompound
              label='Gelände'
              name='compoundIds'
              className='sm:col-span-3'
              multiple
              model={this.model}
              onlyStamm={this.context.permissions.report_hygienemittelbestand === 1}
            />
          </div>
          <Button loading={this.loading} className='my-4' onClick={this.downloadReport}>
            Download
          </Button>
        </div>
      </CardColumnCard>
    )
  }
}
