import { IStundenzettelData } from 'contracts/workplan/interfaces/IStundenzettelData'
import * as React from 'react'
import { dayjs } from 'helpers/dayjs'
import styles from '../styles.module.scss'
import { MONTHS } from 'contracts/general/helpers/months'

interface Props {
  data: IStundenzettelData
}

export class StundenzettelTitle extends React.Component<Props, {}> {
  render() {
    const data = this.props.data
    const month = dayjs(data.month, 'YYYY-MM')
    const vacationTotal =
      data.vacationDaysNewForCurrentYear + data.vacationCarryFromLastYear

    return (
      <div style={{ padding: '40px 0 0 0' }}>
        <div className={styles.row}>
          <div className={`bg-gray-200 ${styles.rowKey}`}>Mitarbeiter</div>
          <div className={`bg-gray-200 font-bold ${styles.rowValue}`}>
            {data.contractPersonnelNumber ? (
              <>{`PN ${data.contractPersonnelNumber}`}&nbsp;&nbsp;&nbsp;&nbsp;</>
            ) : (
              ''
            )}
            {data.employeeName}
          </div>
          <div className='bg-gray-200 flex-auto' />
        </div>

        <div className={styles.row}>
          <div className={styles.rowKey}>Firma</div>
          <div className={styles.rowValue}>{data.contractCompany}</div>
        </div>

        {vacationTotal !== 0 && (
          <div className={styles.row}>
            <div className={styles.rowKey}>Urlaubsanspruch</div>
            <div className={styles.rowValue}>{`${
              data.vacationDaysNewForCurrentYear
            } Tage${
              data.vacationCarryFromLastYear
                ? `, ${data.vacationCarryFromLastYear} Tage aus Vorjahr übernommen, gesamt ${vacationTotal} Tage`
                : ''
            }`}</div>
          </div>
        )}

        {vacationTotal !== 0 && (
          <div className={styles.row}>
            <div className={styles.rowKey}>Resturlaub</div>
            <div
              className={styles.rowValue}
            >{`${data.vacationDaysLeft} Tage noch nicht genommen / geplant`}</div>
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.rowKey}>Erstellt von</div>
          <div className={styles.rowValue}>{data.nameOfPdfCreator}</div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowKey}>Erstellt am</div>
          <div className={styles.rowValue}>
            {dayjs(data.createdAt).format('DD.MM.YYYY HH:mm')}, betrifft{' '}
            {`${MONTHS[month.month()]} ${month.year()}`}
          </div>
        </div>
      </div>
    )
  }
}
