import { Age } from 'components/Age'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IDisplayField } from 'contracts/residents/interfaces/IDisplayField'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IUserDefaults } from 'contracts/users/interfaces/IUserDefaults'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'
import styles from '../styles.module.scss'
import { SearchCaption } from '../../../components/SearchCaption'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { UnsyncedCollection } from '../models/UnsyncedCollection'
import { getKUColor } from '../helpers/getKUColor'
import { bookingTypes, HouseBan } from 'modules/Residents/helpers/bookingTypes'
import { CornerBadge } from 'modules/Pdf/components/CornerBadge'
import { dayjs } from 'helpers/dayjs'
import { reaction } from 'mobx'

interface Props {
  residents: UnsyncedCollection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  config: IUserDefaults
}

@observer
export class FindRecordResults extends React.Component<Props, {}> {
  static contextType = AppContext
  private fields: IDisplayField[]

  constructor(props: Props) {
    super(props)
    const fields = props.config.residentSearch.order
    const enabled = new Set<string>()
    const vars = props.config.residentSearch.fields.split(',')
    for (const field of vars) {
      enabled.add(field)
    }
    this.fields = fields.filter((f) => enabled.has(f.variable))
  }

  componentDidMount(): void {
    reaction(
      () => this.props.config.residentSearch.order,
      (order) => {
        const enabled = new Set<string>()
        const vars = this.props.config.residentSearch.fields.split(',')
        for (const field of vars) {
          enabled.add(field)
        }
        this.fields = order.filter((f) => enabled.has(f.variable))
      },
    )
  }

  private getGridLayout = (
    order: { label: string; variable: string; width?: number | undefined }[],
  ) => {
    const cols = this.fields.length - 1
    const currentFields = order.filter((f) =>
      this.fields.find((f2) => f2.variable === f.variable),
    )
    const columns = currentFields.map((f) => {
      if (!f.width) {
        return 'minmax(120px, auto)'
      }
      return `${f.width}px`
    })
    let gridTemplateColumns = `394px ${columns.join(' ')}`
    if (cols === -1) {
      gridTemplateColumns = `minmax(min-content, 1fr)`
    } else if (cols === 0) {
      gridTemplateColumns = `394px minmax(min-content, 1fr)`
    }
    return gridTemplateColumns
  }

  private searchResultTileMapper = (resident: IResidentSearchResult) => {
    const thumbnail = getResidentImageSrc(
      this.context.instance.id,
      resident.imageId,
      resident.sex,
      'thumbnail',
    )
    return (
      <Link
        to={`/residents/${toJbpId(+resident.id).toLowerCase()}/overview`}
        state={{ fromSearch: true }}
        key={resident.id}
      >
        <div className='rounded-md shadow-lg bg-white relative overflow-hidden'>
          <img
            className='rounded-t-md w-full'
            src={thumbnail}
            alt=''
            width={200}
            height={200}
          />
          <div className='px-3 pt-4 pb-3'>
            <div className='text-gray-900 text-xs font-medium truncate'>
              {resident.firstName}
            </div>
            <div className='text-gray-900 text-md font-medium truncate'>
              {resident.lastName.toUpperCase()}
            </div>
            <div className='truncate'>
              <span className='inline-flex items-center rounded-full bg-indigo-500 px-2.5 py-0.5 text-xs font-medium text-white'>
                {resident.dateOfBirth
                  ? `Geb. ${dayjs(resident.dateOfBirth).format('DD.MM.YYYY')}`
                  : 'Geb. unbekannt'}
              </span>
            </div>
          </div>
          {resident.deletedAt && (
            <CornerBadge className='bg-red-500' label='Archiv' small />
          )}
        </div>
      </Link>
    )
  }

  private searchResultRowMapper = (resident: IResidentSearchResult, i: number) => {
    const thumbnail = getResidentImageSrc(
      this.context.instance.id,
      resident.imageId,
      resident.sex,
      'thumbnail',
    )
    const bookingType = bookingTypes[resident.accommodation.type || '']

    return (
      <React.Fragment key={resident.id}>
        <div className={`${styles.block} ${styles.nameCol} flex`}>
          <div
            className={`${
              resident.deletedAt
                ? 'bg-red-500 text-white text-center'
                : 'bg-gray-200 text-center'
            } flex-[0_0_24px] px-1`}
            style={{ fontSize: 10, textOverflow: 'initial' }}
          >
            {resident.deletedAt ? (
              <span>
                <i className='fas fa-archive' />
              </span>
            ) : (
              `${i + 1}`
            )}
          </div>
          <div className='flex-[0_0_44px] pl-1 relative'>
            <img
              className='rounded-full w-10 h-10'
              src={thumbnail}
              alt=''
              width={40}
              height={40}
            />
            {this.context.permissions.resident_showPresence && (
              <div
                className={`h-4 w-4 absolute top-0 left-0 rounded-full ${
                  resident.currentVisitCompoundId ? 'bg-green-500' : 'bg-red-500'
                }`}
              />
            )}
          </div>
          <div className='flex-auto truncate px-1'>
            <Link
              to={`/residents/${toJbpId(+resident.id).toLowerCase()}/overview`}
              state={{ fromSearch: true }}
              className='text-blue-500 hover:underline'
            >
              {resident.lastName.toUpperCase()}, {resident.firstName}
            </Link>
            &nbsp;
            <Age dateOfBirth={resident.dateOfBirth} sex={resident.sex} />
            &nbsp;
            {bookingType && (
              <span className='inline-block relative top-1.5'>
                <bookingType.icon
                  className={`h-5 w-5 ${bookingType.color} rounded-full`}
                  style={{ padding: 2 }}
                  aria-hidden='true'
                />
              </span>
            )}
            {resident.accommodation.reason === 'Hausverbot' && (
              <span className='inline-block relative top-1.5 ml-1'>
                <HouseBan className='h-5 w-5' />
              </span>
            )}
          </div>
        </div>
        {this.fields.map((f, i) => {
          if (f.variable === 'latestCostCoverageDaysTillEndDate') {
            return (
              <div key={f.variable} className={styles.block}>
                {resident.data.latestCostCoverageDaysTillEndDate ? (
                  <span
                    className={
                      'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white ' +
                      getKUColor(+resident.data.latestCostCoverageDaysTillEndDate)
                    }
                  >
                    {resident.data.latestCostCoverageDaysTillEndDate}
                  </span>
                ) : (
                  ''
                )}
              </div>
            )
          } else if (f.variable === 'accommodation') {
            return (
              <div key={f.variable} className={styles.block}>
                {resident.data.accommodation?.label || ''}
              </div>
            )
          }
          return (
            <div key={f.variable} className={styles.block}>
              {resident.data[f.variable] || ''}
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  render() {
    if (!this.props.residents.resources) {
      return null
    }
    if (this.props.config.residentSearch.view === 'tile') {
      return (
        <div className='px-4 md:px-6 pb-3 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 xxl:grid-cols-8 gap-4 sm:gap-6'>
          {this.props.residents.resources.map(this.searchResultTileMapper)}
        </div>
      )
    }
    let gridTemplateColumns = this.getGridLayout(this.props.config.residentSearch.order)
    return (
      <div
        className='relative grid mb-2 text-gray-500'
        style={{ gridGap: '1px', gridTemplateColumns }}
      >
        {this.props.residents.resources.length > 0 && (
          <React.Fragment>
            <div key='name' className={`${styles.caption} ml-[68px]`}>
              <SearchCaption orderBy='name' residents={this.props.residents}>
                Name
              </SearchCaption>
            </div>
            {this.fields.map((f) => {
              return (
                <div
                  id={`field-${f.variable}`}
                  key={f.variable}
                  className={styles.caption}
                >
                  <SearchCaption orderBy={f.orderBy} residents={this.props.residents}>
                    {f.label}
                  </SearchCaption>
                </div>
              )
            })}
          </React.Fragment>
        )}
        {this.props.residents.resources.map(this.searchResultRowMapper)}
      </div>
    )
  }
}
