import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
// import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
// import { getBuildingAddress } from 'contracts/accommodations/helpers/getBuildingAddress'
import logo from './logo.svg'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'

export class Lfgb2 extends React.Component<ResidentCardProps, {}> {
  constructor(props: ResidentCardProps) {
    super(props)
    this.state = { accommodation: null }
  }

  render() {
    return (
      <div
        className={styles.page}
        style={
          this.props.batch
            ? {}
            : {
                pageBreakAfter: 'always',
                backgroundSize: 'contain',
              }
        }
      >
        {/* Marker }
        <div className='absolute top-0 right-0 w-1 h-1 bg-red-500' />
        <div className='absolute top-0 left-0 w-1 h-1 bg-red-500' />
        <div className='absolute bottom-0 right-0 w-1 h-1 bg-red-500' />
        <div className='absolute bottom-0 left-0 w-1 h-1 bg-red-500' />*/}

        {/* Header */}
        <div className='flex pt-[6px] pl-3'>
          <div className='flex-auto' style={{ fontSize: '23px' }}>
            Bewohnerausweis
          </div>
          <div className='flex-[0_0_118px] pt-[3px] relative mr-[10px]'>
            <img src={logo} alt='Logo' style={{ width: '100%', height: 'auto' }} />
          </div>
        </div>

        {/* Content */}
        <div className='flex pl-[13px] h-[160px] gap-[6px]'>
          {/* Resident image */}
          <div className='flex-[0_0_120px] overflow-hidden bg-gray-200 relative'>
            <img
              src={getResidentImageSrc(
                this.props.instanceId,
                this.props.resident.imageId,
                this.props.resident.sex,
                'portrait',
              )}
              width='160px'
              height='160px'
              alt='Bewohnerbild'
              style={{
                position: 'absolute',
                top: 0,
                left: -20,
                width: 160,
                height: 160,
                maxWidth: 160,
              }}
            />
          </div>

          {/* Resident data */}
          <div className='flex-auto pt-1.5 max-w-[197px] overflow-hidden'>
            {/* Vorname */}
            <div className='font-bold whitespace-nowrap max-w-[197px]'>
              {this.props.resident.firstName}
            </div>
            <div className={styles.label}>Vorname</div>

            {/* Nachname */}
            <div className='font-bold -mt-[4px] whitespace-nowrap max-w-[197px]'>
              {this.props.resident.lastName}
            </div>
            <div className={styles.label}>Nachname</div>

            <div className='flex mt-[9px]'>
              {/* Geburtsdatum */}
              <div className='flex-[0_0_78px]'>
                <div className={styles.info}>
                  {this.props.resident.dateOfBirth
                    ? dayjs(this.props.resident.dateOfBirth).format('DD.MM.YYYY')
                    : '-'}
                </div>
                <div className={styles.label}>Geburtsdatum</div>
              </div>

              {/* Staatsangehörigkeit */}
              <div>
                <div className={styles.info}>
                  {this.props.resident.data.nationality || '-'}
                </div>
                <div className={styles.label}>Staatsangehörigkeit</div>
              </div>
            </div>

            <div className='flex mt-[4px]'>
              {/* Check-In */}
              <div className='flex-[0_0_78px]'>
                <div className={styles.info}>
                  {this.props.resident.accommodation.type === 'internal-residence' &&
                  this.props.resident.accommodation.room &&
                  this.props.resident.accommodation.beginAt
                    ? dayjs(this.props.resident.accommodation.beginAt).format(
                        'DD.MM.YYYY',
                      )
                    : '-'}
                </div>
                <div className={styles.label}>Check-In</div>
              </div>

              {/* Zimmer */}
              <div>
                {this.props.resident.accommodation.type === 'internal-residence' &&
                this.props.resident.accommodation.room ? (
                  <div
                    className={`${styles.info} font-bold -mt-[2px]`}
                    style={{ fontSize: '15.4px' }}
                  >
                    {this.props.resident.accommodation.room.label}
                  </div>
                ) : (
                  <div className={`${styles.info}`}>-</div>
                )}
                <div className={styles.label}>Zimmer</div>
              </div>
            </div>

            {/* Unterkunft */}
            <div className='mt-[6px] leading-3 font-bold'>
              <div className={styles.info}>
                {this.props.resident.accommodation.type === 'internal-residence' &&
                  (this.props.resident.accommodation.building?.street ?? '-')}
              </div>
              {this.props.resident.accommodation.type === 'internal-residence' &&
                this.props.resident.accommodation.building?.zip &&
                this.props.resident.accommodation.building.city && (
                  <div className={styles.info}>
                    {this.props.resident.accommodation.building.zip}{' '}
                    {this.props.resident.accommodation.building.city}
                  </div>
                )}
            </div>
          </div>
        </div>

        {/*<div className='bg-white font-bold'>Max Engelmann der Dritte</div>
        <div className='bg-white'>Max Engelmann der Dritte</div>*/}
      </div>
    )
  }
}
